import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  DesktopOutlined,
  DownloadOutlined,
  EnvironmentOutlined,
  LeftOutlined,
  MailOutlined
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Image,
  Input,
  message,
  Modal,
  Progress,
  Rate,
  Row,
  Select,
  Slider,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";

import {
  AimOutlined,
  CameraOutlined,
  CopyOutlined,
  ExpandOutlined,
  IdcardOutlined,
  LineChartOutlined
} from "@ant-design/icons";

import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAssessmentCandidateDetailsAction,
  updateInviteAssessmentDetailAction
} from "../../../redux/actions/assessment";
import { formatDate } from "../../../Utils/Common";
import { ASSESSMENT_STAGE_OPTION } from "../../Assessment/data";
import AntiCheatingMeasuresModal from "./Modal.jsx/AntiCheatingMeasureModal";
import { multipleAction } from "../../../redux/actions/common";
import ImagePreviewAssessment from "./Modal.jsx/ImagePreviewModal";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;
const { Text, Title } = Typography;
const { confirm } = Modal;

const AssessmentReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const assessment_invite_id = queryParams.get("assessment_invite_id");

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [antiCheatingModal, setAntiCheatingModal] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [rateValue, setRateValue] = useState(0);
  const {
    loading: loadingReducer,
    data: reducerData,
    error
  } = useSelector((state) => state.getAssessmentCandidateDetailsReducer);

  const {
    loading: actionLoading,
    data: actionData,
    error: actionError,
    request: actionRequest
  } = useSelector((state) => state.multipleActionReducer);

  const {
    loading: stageLoading,
    request: stageRequest,
    data: stageData,
    error: stageError
  } = useSelector((state) => state.updateInviteAssessmentDetailsReducer);
  useEffect(() => {
    if (!stageLoading && !stageError && stageData) {
      if (stageData?.data?.stage === "rejected") {
        message.success("Rejected!");
      }
    }
    if (!stageLoading && stageError) {
      message.error(stageError);
    }
  }, [stageLoading, stageError, stageData]);

  useEffect(() => {
    if (!actionLoading && !actionError && actionData) {
      message.success(actionData?.message || "Result sent!");
    }
    if (!actionLoading && actionError) {
      console.error(actionError);
      message.error(actionError);
    }
  }, [actionLoading, actionError, actionData]);

  useEffect(() => {
    if (assessment_invite_id) {
      dispatch(
        getAssessmentCandidateDetailsAction({
          assessment_invite_id: assessment_invite_id
        })
      );
    }
    return () => {
      dispatch({ type: "GET_ASSESSMENT_CANDIDATE_DETAILS_RESET" });
    };
  }, [assessment_invite_id]);

  useEffect(() => {
    setLoading(loadingReducer);
    if (!loadingReducer && reducerData && !error) {
      setData(reducerData.data);
      setTextValue(reducerData.data?.recruiter_feedback?.explanation);
      setRateValue(reducerData.data?.recruiter_feedback?.rate);
    } else if (!loadingReducer && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [loadingReducer, reducerData, error]);

  const handleRateChange = (value) => {
    setRateValue(value);
    dispatch(
      updateInviteAssessmentDetailAction({
        recruiter_feedback: {
          rate: value,
          explanation: textValue
        },
        assessment_invite_id: assessment_invite_id
      })
    );
  };

  const handleTextChange = (e) => {
    const inputValue = e.target.value;
    setTextValue(inputValue);

    if (inputValue.trim().length > 0 && inputValue.endsWith(" ")) {
      dispatch(
        updateInviteAssessmentDetailAction({
          recruiter_feedback: {
            rate: rateValue,
            explanation: textValue.trim()
          },
          assessment_invite_id: assessment_invite_id
        })
      );
    }
  };

  const proctoringDetails = [
    {
      icon: <DesktopOutlined />,
      label: "Device used",
      value:
        data?.evaluation?.length === 0
          ? "N/A"
          : data?.proctoring_details?.deviceData?.device_used || "N/A"
    },
    {
      icon: <EnvironmentOutlined />,
      label: "Location",
      value:
        data?.evaluation?.length === 0
          ? "N/A"
          : data?.proctoring_details?.deviceData?.location || "N/A"
    },
    {
      icon: <IdcardOutlined />,
      label: "Filled out only once from IP address?",
      value:
        data?.evaluation?.length === 0
          ? "N/A"
          : data?.proctoring_details?.activity?.is_one_ip
          ? "Yes"
          : "No"
    },
    {
      icon: <CameraOutlined />,
      label: "Webcam enabled?",
      value:
        data?.evaluation?.length === 0
          ? "N/A"
          : data?.proctoring_details?.activity?.is_webcam_active
          ? "Yes"
          : "No"
    },
    {
      icon: <ExpandOutlined />,
      label: "Full-screen mode always active?",
      value:
        data?.evaluation?.length === 0
          ? "N/A"
          : data?.proctoring_details?.activity?.is_full_screen_always_active
          ? "Yes"
          : "No"
    },
    {
      icon: <AimOutlined />,
      label: "Mouse always in assessment window?",
      value:
        data?.evaluation?.length === 0
          ? "N/A"
          : data?.proctoring_details?.activity?.is_mouse_in_assessment
          ? "Yes"
          : "No"
    }
  ];

  const actions = data?.overall_scoring?.is_overall_complete
    ? [
        {
          Icon: DownloadOutlined,
          label: "Download Result",
          action_type: "DOWNLOAD_RESULT",
          onClick: async () => {
            const input = document.getElementById("contentToPrint");
            const canvas = await html2canvas(input);
            const imgData = canvas.toDataURL("image/png");

            const pdf = new jsPDF("p", "mm", "a4");

            const imgWidth = 210; // A4 width in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

            const firstName =
              data?.candidate_details?.firstName?.toLowerCase() || "unknown";
            const lastName =
              data?.candidate_details?.lastName?.toLowerCase() || "unknown";
            const assessmentId =
              assessment_invite_id?.toLowerCase() || "unknown";
            const fileName = `${firstName}_${lastName}_assessment_result_${assessmentId}.pdf`;

            // Save the PDF with the formatted file name
            pdf.save(fileName);
          },
          confirmMessage: "Are you sure you want to download the result?"
        },
        {
          Icon: MailOutlined,
          label: "Send Result to Candidate",
          action_type: "ASSESSMENT_RESULT_SENDING",

          onClick: () => {
            dispatch(
              multipleAction({
                action_type: "ASSESSMENT_RESULT_SENDING",
                data: {
                  assessment_invite_id: assessment_invite_id,
                  candidate_id: data?.candidate_details?.candidate_id
                }
              })
            );
          },
          confirmMessage:
            "Are you sure you want to send the result to the candidate?"
        },
        {
          Icon: CloseCircleOutlined,
          label: "Reject Candidate",
          action_type: "REJECT_CANDIDATE",

          onClick: () => {
            dispatch(
              updateInviteAssessmentDetailAction({
                stage: "rejected",
                action_type: "REJECT_CANDIDATE",

                assessment_invite_id: assessment_invite_id
              })
            );
          },
          confirmMessage: "Are you sure you want to reject the candidate?"
        }
      ]
    : [
        {
          Icon: CloseCircleOutlined,
          label: "Reject Candidate",
          onClick: () => {
            dispatch(
              updateInviteAssessmentDetailAction({
                stage: "rejected",
                assessment_invite_id: assessment_invite_id
              })
            );
          },
          confirmMessage: "Are you sure you want to reject the candidate?"
        }
      ];

  // Function to show the confirmation modal
  const showConfirm = (action) => {
    confirm({
      title: action.confirmMessage,
      okText: "Yes",
      cancelText: "No",
      onOk: action.onClick,
      onCancel() {
        message.info("Action canceled");
      }
    });
  };
  const [tooltipText, setTooltipText] = useState("Click to copy");

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(data?.candidate_details?.email);
    setTooltipText("Copied!");
    message.success("Email copied to clipboard");
    setTimeout(() => setTooltipText("Click to copy"), 2000); // Reset tooltip text after 2 seconds
  };
  return (
    <div id="contentToPrint">
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <div className="main-header bg-white" data-ui="header">
            <div className="container d-flex p-3 border-bottom">
              <Button
                onClick={() =>
                  navigate(
                    `/dashboard/assessment/details?assessment_id=${data?.assessment_details?.assessment_id}`
                  )
                }
                size="large"
                shape="circle"
                icon={<LeftOutlined />}
                style={{ width: "50px", height: "50px" }}
              />
              <Avatar
                style={{
                  backgroundColor: "#87d068",
                  width: "50px",
                  height: "50px"
                }}
                size="large"
                className="mx-3 fs-4"
              >
                {`${data?.candidate_details?.firstName
                  .charAt(0)
                  .toUpperCase()}${data?.candidate_details?.lastName
                  .charAt(0)
                  .toUpperCase()}`}
              </Avatar>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <h5 className="m-0 fs-4 fw-bold">
                    {`${data?.candidate_details?.firstName} ${data?.candidate_details?.lastName}`}
                  </h5>
                  <Tooltip title={tooltipText}>
                    <a
                      href={`mailto:${data?.candidate_details?.email}`}
                      className="text-dark"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCopyEmail();
                      }}
                    >
                      {data?.candidate_details?.email}
                    </a>
                  </Tooltip>
                </div>
                <Tooltip title="Click to copy">
                  <CopyOutlined
                    style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      marginLeft: "10px"
                    }}
                    onClick={handleCopyEmail}
                  />
                </Tooltip>
              </div>
            </div>
          </div>

          <div
            className="container my-4 py-4 px-3"
            style={{
              border: "1px groove",
              borderRadius: "18px"
            }}
          >
            <Row>
              <Col span={24}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="h4">
                    <strong className="fw-bold ml-3">Assessment</strong>{" "}
                    {data?.assessment_details?.title}
                  </h2>
                  <div>
                    {actions?.map(
                      (
                        { Icon, label, action_type, onClick, confirmMessage },
                        idx
                      ) => (
                        <Tooltip title={label} key={idx}>
                          <Button
                            loading={
                              action_type ===
                              (actionRequest?.action_type ||
                                stageRequest?.action_type)
                                ? actionLoading || stageLoading
                                : false
                            }
                            shape="circle"
                            size="large"
                            icon={<Icon />}
                            className="me-2"
                            style={{ width: "50px", height: "50px" }}
                            onClick={() =>
                              showConfirm({ onClick, confirmMessage })
                            }
                          />
                        </Tooltip>
                      )
                    )}
                  </div>
                </div>
                <Rate
                  style={{ marginLeft: "1%" }}
                  value={data?.recruiter_feedback?.rate}
                  disabled={true}
                />
              </Col>
            </Row>
            <Divider />

            <Row gutter={16}>
              <Col xs={24} sm={12} md={6} lg={6}>
                {" "}
                {[
                  {
                    label: "Invited",
                    value: formatDate(data?.candidate_details?.createdAt)
                  },
                  {
                    label: "Completed",
                    value: data?.more?.completion_date
                      ? formatDate(data?.more?.completion_date)
                      : data?.candidate_details?.status
                  },
                  { label: "Source", value: "Invitation by email" }
                ].map(({ label, value }, idx) => (
                  <div key={idx} className="mb-3">
                    <p className="fw-bold py-2">{label}</p>
                    <p className="py-2"> {value}</p>
                  </div>
                ))}
                <div>
                  <span className="fw-bold">Hiring stage</span>
                  <Select
                    className="mt-2"
                    loading={stageLoading}
                    defaultValue={data?.candidate_details?.stage}
                    style={{ width: "100%" }}
                    size="large"
                    onChange={(value) => {
                      dispatch(
                        updateInviteAssessmentDetailAction({
                          stage: value,
                          assessment_invite_id: assessment_invite_id
                        })
                      );
                    }}
                  >
                    {ASSESSMENT_STAGE_OPTION?.map((options) => (
                      <Option key={options?.value} value={options?.value}>
                        {options?.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={10}>
                {" "}
                {data?.overall_scoring?.is_overall_complete && (
                  <Card className="container-score" style={{ borderRadius: 8 }}>
                    <Row align="middle" justify="space-between">
                      <Col xs={24} md={18}>
                        <div
                          className="top-score"
                          style={{ position: "relative" }}
                        >
                          <span
                            className="candidate-name"
                            style={{ fontWeight: 700 }}
                          >
                            {`${data?.candidate_details?.firstName}
                ${data?.candidate_details?.lastName}`}
                          </span>
                        </div>
                        <div
                          style={{ position: "relative", marginTop: "20px" }}
                        >
                          <Progress
                            percent={data?.overall_scoring?.overall_percentage}
                            showInfo={false}
                            strokeColor="black"
                            trailColor="#f0f0f0"
                            style={{ height: "8px", borderRadius: "4px" }}
                          />
                          <div
                            className="progress-labels"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "8px"
                            }}
                          >
                            <span style={{ fontWeight: 700 }}>0%</span>
                            <span style={{ fontWeight: 700 }}>100%</span>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} md={6} style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "40px", fontWeight: 700 }}>
                          {data?.overall_scoring?.overall_percentage.toFixed(0)}
                          %
                        </span>
                        <p style={{ fontWeight: 700, marginBottom: 0 }}>
                          Average score
                        </p>
                      </Col>
                    </Row>
                  </Card>
                )}
                {data?.evaluation?.length === 0 ? (
                  <>
                    <Card className="my-3 border-0 text-center bg-light">
                      <div className="d-flex flex-column align-items-center">
                        <ClockCircleOutlined
                          style={{ fontSize: "3rem", color: "#6c757d" }}
                        />
                        <h6 className="mt-3">
                          This candidate has not started their assessment yet
                        </h6>
                        <div className="h6 mt-2">
                          Therefore, there are no test results to show. In the
                          meantime, you can learn how to interpret the results.
                        </div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <Card className="bg-light my-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6>Scoring method</h6>
                    </div>
                    <div className="h6 mt-2">Percentage of correct answers</div>
                    <Space direction="vertical" className="bg-white w-100 mt-3">
                      {data?.evaluation.map((item, id) => (
                        <Collapse
                          key={id}
                          defaultActiveKey={["1"]}
                          className="bg-white"
                        >
                          <Panel
                            header={
                              <div className="d-flex justify-content-between align-items-center">
                                <p>{item?.test_title}</p>
                                <p>
                                  {item?.evaluation_test
                                    ? `${item?.total_percent.toFixed(0)}%`
                                    : "In progress"}
                                </p>
                              </div>
                            }
                            key={id + 1}
                          >
                            {!item?.evaluation_test ? (
                              <>
                                <p className="fw-bold text-center">
                                  In Progress
                                </p>
                              </>
                            ) : (
                              <>
                                <div className="d-flex align-items-center mb-2">
                                  <LineChartOutlined />
                                  <span className="ml-2">
                                    {item?.test_level}
                                  </span>
                                  <ClockCircleOutlined className="ml-4" />
                                  <span className="ml-2">
                                    Finished in {item?.total_completion_time}{" "}
                                    {item?.time_unit} out of{" "}
                                    {item?.test_duration} {item?.time_unit}
                                  </span>
                                </div>
                                <Divider />
                                <p className="fw-bold">Metrics</p>
                                <ul>
                                  <li>
                                    Grade: <a href="#">{item?.grade}</a>
                                  </li>
                                  <li>
                                    Score Obtained:{" "}
                                    <a href="#">
                                      {item?.total_percent.toFixed(2)}%
                                    </a>
                                  </li>
                                </ul>

                                <Divider />
                                <a href="#" className="text-primary">
                                  Learn more
                                </a>
                              </>
                            )}
                          </Panel>
                        </Collapse>
                      ))}
                    </Space>
                  </Card>
                )}
              </Col>

              <Col xs={24} sm={12} md={6} lg={8}>
                {" "}
                <div className="anti-cheating-monitor bg-white  rounded">
                  <Row justify="space-between" align="middle" className="mb-3">
                    <Col>
                      <Text className="fw-bold">Anti-cheating monitor</Text>
                    </Col>
                    <Col>
                      <Button
                        type="link"
                        onClick={() => setAntiCheatingModal(true)}
                        className="learn-more-btn"
                      >
                        Learn more
                      </Button>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    {proctoringDetails
                      .slice(0, 2)
                      .map(({ icon, label, value }, idx) => (
                        <Col span={24} key={idx}>
                          <div className="d-flex align-items-center">
                            <div style={{ marginRight: 8 }}>{icon}</div>
                            <Text className="fw-bold">{label}</Text>
                            <Text style={{ marginLeft: "auto" }}>
                              <strong> {value}</strong>
                            </Text>
                          </div>
                        </Col>
                      ))}
                    {proctoringDetails
                      .slice(2)
                      .map(({ icon, label, value }, idx) => (
                        <Col span={24} key={idx}>
                          <div className="d-flex align-items-center">
                            <div style={{ marginRight: 8 }}>{icon}</div>
                            <Text className="fw-bold">{label}</Text>
                            <Text style={{ marginLeft: "auto" }}>
                              {value === "Yes" ? (
                                <Tag color="green">{value}</Tag>
                              ) : (
                                <Tag color="red">{value}</Tag>
                              )}
                            </Text>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
                <ImagePreviewAssessment data={data} />
              </Col>
            </Row>

            <Divider />
            <Row>
              <Col xs={24} sm={12} md={6}>
                <h4>Your rating</h4>
                <div>Give your personal overall rating of this candidate.</div>

                <Rate value={rateValue} onChange={handleRateChange} />
              </Col>
              <Col xs={24} sm={12} md={16}>
                <TextArea
                  rows={6}
                  value={textValue}
                  placeholder="Add your private notes here..."
                  onChange={handleTextChange}
                />
              </Col>
            </Row>
          </div>
        </>
      )}

      {antiCheatingModal && (
        <AntiCheatingMeasuresModal
          antiCheatingModal={antiCheatingModal}
          setAntiCheatingModal={setAntiCheatingModal}
        />
      )}
    </div>
  );
};

export default AssessmentReport;
