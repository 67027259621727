import {
  createUserConstants,
  getUserConstants,
  getSubUserDetailsConstants,
  createClientUserConstants,
  getAllClientUserConstants
} from "../../constants";

export function createRoleUserAction(data) {
  return { type: createUserConstants.ADD_USER_REQUEST, data };
}

export function getAllRoleUserAction(data) {
  return { type: getUserConstants.GET_USER_REQUEST, data };
}
export function getRoleUSerDetailsAction(data) {
  return { type: getSubUserDetailsConstants.USER_DETAILS_REQUEST, data };
}


export function createClientRoleUserAction(data) {
  return { type: createClientUserConstants.ADD_CLIENT_USER_REQUEST, data };
}
