import { takeLatest, all } from "redux-saga/effects";
import {
  addCandidateConstants,
  addClientConstants,
  applyJobConstants,
  createJobConstants,
  createUserConstants,
  enquiryConstants,
  forgetPasswordLinkConstants,
  getAllCandidateActions,
  getAllScheduleInterviewListConstants,
  getCandidateProfileConstants,
  getClientDetailsConstants,
  getClientJobsConstants,
  getClientListConstants,
  getClientReportConstants,
  getEnquiryConstants,
  getInterviewDetailsConstants,
  getInterviewerListConstants,
  getInterviewerReportConstants,
  getJobApplicationConstants,
  getJobConstants,
  getJobDetailConstants,
  getUserConstants,
  getuserDetailsConstants,
  interviewFeedbackConstants,
  loginUserConstants,
  postFeedbackConstants,
  resumeParserConstants,
  scheduleInterviewConstants,
  sendOtpConstants,
  signupUserConstants,
  updateCandidateConstants,
  updateClientDetailsConstants,
  updateEnquiryConstants,
  updateInterviewDetailsConstants,
  updateJobApplicationConstants,
  updateJobConstants,
  updatePasswordConstants,
  updateUserDetailsConstants,
  verifyEmailConstants,
  verifyOtpConstants,
  getTeamReportConstants,
  matchingAlgoConstants,
  createQuestionConstants,
  updateQuestionConstants,
  getQuestionDetailsConstants,
  getAllQuestionListConstants,
  createTestConstants,
  updateTestConstants,
  getTestDetailsConstants,
  getAllTestListConstants,
  createAssessmentConstants,
  updateAssessmentConstants,
  getAssessmentDetailsConstants,
  getAllAssessmentListConstants,
  getInviteAssessmentDetailsConstants,
  updateInviteAssessmentDetailsConstants,
  assessmentLoginConstants,
  getAllAssessmentCandidateConstants,
  getAssessmentCandidateDetailsConstants,
  inviteAssessmentConstants,
  getAsCandidateDetailsConstants,
  candidateAssessmentSubmissionConstants,
  candidateAssessmentFeedbackConstants,
  assessmentImageProctorConstants,
  candidateLoginConstants,
  candidateStatsReportConstants,
  multipleActionConstants,
  getSubUserDetailsConstants,
  getAllClientUserConstants,
  createClientUserConstants,
  getCandidateDetailsDashboardConstants,
  getInvitationDetailsConstants,
  getJobAllAppliedCandidateConstants,
  getFeedbackListConstant,
  updateFeedbackConstant,
  assignToRecruiterConstants,
  emailSubscribeConstants,
  getTimeLineReportsConstants,
  getPresignedUrlConstants,
  uploadImageFileConstants,
  getEmailTemplatesConstants,
  updateEmailTemplatesConstants,
  getInterviewCalendarConstants,
  logoutConstants,
  getUserStatsConstants
} from "../../constants";
import {
  addCandidateSaga,
  addClientSaga,
  applyJobSaga,
  getAllCandidateSaga,
  getClientDetailsSaga,
  getClientJobSaga,
  getClientListSaga,
  getJobDetailsSaga,
  postFeedbackSaga,
  resumeParserSaga,
  updateJobApplicationSaga,
  updateJobDetailsSaga,
  updateclientDetailsSaga,
  uploadImgaeFileSaga,
  uploadImgaeSaga,
  candidateProfileSaga,
  createJobSaga,
  getJobSaga,
  getJobApplicationSaga,
  enquirySaga,
  getEnquirySaga,
  updateEnquirySaga,
  verifyOtpSaga,
  sendOtpSaga,
  verifyEmailSaga,
  updateCandidateSaga,
  matchingAlgoSaga,
  multipleActionSaga,
  getJobAllAppliedCandidateSaga,
  getFeedbackListSaga,
  updateFeedbackSaga,
  assignToRecruiterSaga,
  emailSubscribeSaga,
  getEmailTemplatesSaga,
  updateEmailTemplatesSaga
} from "./common";
import {
  UpdateUserDetailsSaga,
  forgetPasswordLinkSaga,
  loginUserSaga,
  singupUserSaga,
  updatePasswordSaga,
  getUserDetailsSaga,
  logoutUserSaga,
  getUserStatsSaga
} from "./user";

import {
  getAllScheduleInterviewListSaga,
  getInterviewCalendarSaga,
  getInterviewDetailsSaga,
  getInterviewerListSaga,
  getInterviewerReportSaga,
  interviewFeedbackSaga,
  scheduleInterviewSaga,
  updateInterviewDetailsSaga
} from "./interviewer";
import {
  getTeamReportSaga,
  getClientReportSaga,
  getTimeLineReportsSaga
} from "./reports&Activity";
import {
  createTestSaga,
  createQuestionSaga,
  createAssessmentSaga,
  getAllTestListSaga,
  getAllQuestionListSaga,
  getTestDetailsSaga,
  getQuestionDetailsSaga,
  updateTestSaga,
  updateQuestionSaga,
  updateAssessmentSaga,
  getAssessmentDetailsSaga,
  getAllAssessmentListSaga,
  getInviteAssessmentDetailsSaga,
  updateInviteAssessmentDetailsSaga,
  assessmentLoginSaga,
  getAllAssessmentCandidateSaga,
  getAssessmentCandidateDetailsSaga,
  inviteAssessmentSaga,
  getCandidateDetailsSaga,
  candidateAssessmentSubmissionSaga,
  candidateAssessmentFeedbackSaga,
  assessmentImageProctorSaga,
  getInvitationDetailSaga
} from "./assessment";
import {
  candidateLoginSaga,
  candidateStatsReportSaga,
  getCandidateDetailsDashboardSaga
} from "./candidate";
import {
  createClientRoleUserSaga,
  createRoleUserSaga,
  getAllClientRoleUserSaga,
  getAllRoleUserSaga,
  getRoleUSerDetailsSaga
} from "./Roles&Management";

function* actionWatcher() {
  yield takeLatest(
    getPresignedUrlConstants.UPLOAD_IMAGE_REQUEST,
    uploadImgaeSaga
  );
  yield takeLatest(
    uploadImageFileConstants.UPLOAD_IMAGE_FILE_REQUEST,
    uploadImgaeFileSaga
  );

  yield takeLatest(
    resumeParserConstants.RESUME_PARSER_REQUEST,
    resumeParserSaga
  );
  yield takeLatest(createJobConstants.CREATE_JOB_REQUEST, createJobSaga);
  yield takeLatest(getJobConstants.GET_JOB_REQUEST, getJobSaga);
  yield takeLatest(
    getJobDetailConstants.GET_JOB_DETAIL_REQUEST,
    getJobDetailsSaga
  );
  yield takeLatest(applyJobConstants.APPLY_JOB_REQUEST, applyJobSaga);
  yield takeLatest(addClientConstants.ADD_CLIENT_REQUEST, addClientSaga);
  yield takeLatest(
    getClientListConstants.GET_CLIENT_LIST_REQUEST,
    getClientListSaga
  );
  yield takeLatest(
    getClientDetailsConstants.GET_CLIENT_DETAIL_REQUEST,
    getClientDetailsSaga
  );
  yield takeLatest(
    updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_REQUEST,
    updateclientDetailsSaga
  );
  yield takeLatest(
    getAllCandidateActions.GET_ALL_CANDIDATE_REQUEST,
    getAllCandidateSaga
  );
  yield takeLatest(
    getCandidateProfileConstants.CANDIDATE_PROFILE_REQUEST,
    candidateProfileSaga
  );
  yield takeLatest(
    getClientJobsConstants.GET_CLIENT_JOBS_REQUEST,
    getClientJobSaga
  );
  yield takeLatest(updateJobConstants.UPDATE_JOB_REQUEST, updateJobDetailsSaga);
  yield takeLatest(loginUserConstants.LOGIN_USER_REQUEST, loginUserSaga);
  yield takeLatest(signupUserConstants.SIGNUP_USER_REQUEST, singupUserSaga);
  yield takeLatest(
    getuserDetailsConstants.GET_USER_DETAILS_REQUEST,
    getUserDetailsSaga
  );
  yield takeLatest(
    updateUserDetailsConstants.UPDATE_USER_DETAILS_REQUEST,
    UpdateUserDetailsSaga
  );
  yield takeLatest(
    addCandidateConstants.ADD_CANDIDATE_REQUEST,
    addCandidateSaga
  );
  yield takeLatest(
    updateJobApplicationConstants.UPDATE_APPLICATION_REQUEST,
    updateJobApplicationSaga
  );

  yield takeLatest(createUserConstants.ADD_USER_REQUEST, createRoleUserSaga);
  yield takeLatest(getUserConstants.GET_USER_REQUEST, getAllRoleUserSaga);
  yield takeLatest(
    postFeedbackConstants.POST_FEEDBACK_REQUEST,
    postFeedbackSaga
  );
  yield takeLatest(
    getInterviewerListConstants.GET_INTERVIEWER_LIST_REQUEST,
    getInterviewerListSaga
  );
  yield takeLatest(
    getInterviewDetailsConstants.GET_INTERVIEW_DETAILS_REQUEST,
    getInterviewDetailsSaga
  );
  yield takeLatest(
    getAllScheduleInterviewListConstants.GET_SCHEDULE_INTERVIEW_LIST_REQUEST,
    getAllScheduleInterviewListSaga
  );
  yield takeLatest(
    scheduleInterviewConstants.SCHEDULE_INTERVIEW_REQUEST,
    scheduleInterviewSaga
  );
  yield takeLatest(
    getJobApplicationConstants.GET_JOB_APPLICATION_REQUEST,
    getJobApplicationSaga
  );
  yield takeLatest(
    interviewFeedbackConstants.INTERVIEW_FEEDBACK_REQUEST,
    interviewFeedbackSaga
  );
  yield takeLatest(
    forgetPasswordLinkConstants.FORGET_PASSWORD_LINK_REQUEST,
    forgetPasswordLinkSaga
  );
  yield takeLatest(
    updatePasswordConstants.UPDATE_PASSWORD_REQUEST,
    updatePasswordSaga
  );
  yield takeLatest(enquiryConstants.ENQUIRY_REQUEST, enquirySaga);
  yield takeLatest(
    getInterviewerReportConstants.GET_INTERVIEWER_REPORT_REQUEST,
    getInterviewerReportSaga
  );
  yield takeLatest(
    updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_REQUEST,
    updateInterviewDetailsSaga
  );
  yield takeLatest(
    updateCandidateConstants.UPDATE_CANDIDATE_REQUEST,
    updateCandidateSaga
  );
  yield takeLatest(getEnquiryConstants.GET_ENQUIRY_REQUEST, getEnquirySaga);
  yield takeLatest(
    updateEnquiryConstants.UPDATE_ENQUIRY_REQUEST,
    updateEnquirySaga
  );
  yield takeLatest(sendOtpConstants.SEND_OTP_REQUEST, sendOtpSaga);
  yield takeLatest(verifyOtpConstants.VERIFY_OTP_REQUEST, verifyOtpSaga);
  yield takeLatest(verifyEmailConstants.VERIFY_EMAIL_REQUEST, verifyEmailSaga);
  yield takeLatest(
    getClientReportConstants.GET_CLIENT_REPORT_REQUEST,
    getClientReportSaga
  );
  yield takeLatest(
    getTeamReportConstants.GET_TEAM_REPORT_REQUEST,
    getTeamReportSaga
  );
  yield takeLatest(
    matchingAlgoConstants.MATCHING_ALGO_REQUEST,
    matchingAlgoSaga
  );
  yield takeLatest(
    assessmentLoginConstants.ASSESSMENT_LOGIN_REQUEST,
    assessmentLoginSaga
  );
  yield takeLatest(
    createQuestionConstants.CREATE_QUESTION_REQUEST,
    createQuestionSaga
  );
  yield takeLatest(
    updateQuestionConstants.UPDATE_QUESTION_REQUEST,
    updateQuestionSaga
  );
  yield takeLatest(
    getQuestionDetailsConstants.GET_QUESTION_REQUEST,
    getQuestionDetailsSaga
  );
  yield takeLatest(
    getAllQuestionListConstants.GET_LIST_QUESTION_REQUEST,
    getAllQuestionListSaga
  );
  yield takeLatest(createTestConstants.CREATE_TEST_REQUEST, createTestSaga);
  yield takeLatest(updateTestConstants.UPDATE_TEST_REQUEST, updateTestSaga);
  yield takeLatest(
    getTestDetailsConstants.GET_TEST_REQUEST,
    getTestDetailsSaga
  );
  yield takeLatest(
    getAllTestListConstants.GET_LIST_TEST_REQUEST,
    getAllTestListSaga
  );
  yield takeLatest(
    createAssessmentConstants.CREATE_ASSESSMENT_REQUEST,
    createAssessmentSaga
  );
  yield takeLatest(
    updateAssessmentConstants.UPDATE_ASSESSMENT_REQUEST,
    updateAssessmentSaga
  );
  yield takeLatest(
    getAssessmentDetailsConstants.GET_ASSESSMENT_REQUEST,
    getAssessmentDetailsSaga
  );
  yield takeLatest(
    getAllAssessmentListConstants.GET_LIST_ASSESSMENT_REQUEST,
    getAllAssessmentListSaga
  );
  yield takeLatest(
    getInviteAssessmentDetailsConstants.GET_INVITE_ASSESSMENT_DETAILS_REQUEST,
    getInviteAssessmentDetailsSaga
  );
  yield takeLatest(
    updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_REQUEST,
    updateInviteAssessmentDetailsSaga
  );
  yield takeLatest(
    getAllAssessmentCandidateConstants.GET_ALL_ASSESSMENT_CANDIDATE_REQUEST,
    getAllAssessmentCandidateSaga
  );
  yield takeLatest(
    getAssessmentCandidateDetailsConstants.GET_ASSESSMENT_CANDIDATE_DETAILS_REQUEST,
    getAssessmentCandidateDetailsSaga
  );
  yield takeLatest(
    inviteAssessmentConstants.INVITE_ASSESSMENT_REQUEST,
    inviteAssessmentSaga
  );

  yield takeLatest(
    getAsCandidateDetailsConstants.GET_NEW_CANDIDATE_DETAILS_REQUEST,
    getCandidateDetailsSaga
  );
  yield takeLatest(
    candidateAssessmentSubmissionConstants.CANDIDATE_ASSESSMENT_SUBMISSION_REQUEST,
    candidateAssessmentSubmissionSaga
  );
  yield takeLatest(
    candidateAssessmentFeedbackConstants.CANDIDATE_ASSESSMENT_FEEDBACK_REQUEST,
    candidateAssessmentFeedbackSaga
  );
  yield takeLatest(
    assessmentImageProctorConstants.ASSESSMENT_PROCTOR_REQUEST,
    assessmentImageProctorSaga
  );
  yield takeLatest(
    candidateLoginConstants.CANDIDATE_LOGIN_REQUEST,
    candidateLoginSaga
  );
  yield takeLatest(
    candidateStatsReportConstants.CANDIDATE_STATS_REPORT_REQUEST,
    candidateStatsReportSaga
  );
  yield takeLatest(
    multipleActionConstants.MULTIPLE_ACTION_REQUEST,
    multipleActionSaga
  );
  yield takeLatest(
    getSubUserDetailsConstants.USER_DETAILS_REQUEST,
    getRoleUSerDetailsSaga
  );
  yield takeLatest(
    createClientUserConstants.ADD_CLIENT_USER_REQUEST,
    createClientRoleUserSaga
  );
  yield takeLatest(
    getAllClientUserConstants.GET_ALL_CLIENT_USER_REQUEST,
    getAllClientRoleUserSaga
  );
  yield takeLatest(
    getCandidateDetailsDashboardConstants.GET_CANDIDATE_DETAILS_REQUEST,
    getCandidateDetailsDashboardSaga
  );
  yield takeLatest(
    getInvitationDetailsConstants.GET_INVITE_DETAILS_REQUEST,
    getInvitationDetailSaga
  );
  yield takeLatest(
    getJobAllAppliedCandidateConstants.GET_JOB_ALL_APPLIED_CANDIDATE_REQUEST,
    getJobAllAppliedCandidateSaga
  );
  yield takeLatest(
    getFeedbackListConstant.GET_FEEDBACK_LIST_REQUEST,
    getFeedbackListSaga
  );
  yield takeLatest(
    updateFeedbackConstant.UPDATE_FEEDBACK_LIST_REQUEST,
    updateFeedbackSaga
  );
  yield takeLatest(
    assignToRecruiterConstants.ASSIGN_TO_RECRUITER_REQUEST,
    assignToRecruiterSaga
  );

  yield takeLatest(
    emailSubscribeConstants.EMAIL_SUBSCRIBE_REQUEST,
    emailSubscribeSaga
  );
  yield takeLatest(
    getTimeLineReportsConstants.GET_TIMELINE_REPORTS_REQUEST,
    getTimeLineReportsSaga
  );
  yield takeLatest(
    getEmailTemplatesConstants.GET_EMAIL_TEMPLATES_REQUEST,
    getEmailTemplatesSaga
  );
  yield takeLatest(
    updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_REQUEST,
    updateEmailTemplatesSaga
  );
  yield takeLatest(
    getInterviewCalendarConstants.GET_INTERVIEW_CALENDAR_REQUEST,
    getInterviewCalendarSaga
  );
  yield takeLatest(logoutConstants.LOGOUT_REQUEST, logoutUserSaga);
  yield takeLatest(
    getUserStatsConstants.GET_USER_STATS_REQUEST,
    getUserStatsSaga
  );
}
export default function* rootSaga() {
  yield all([actionWatcher()]);
}
