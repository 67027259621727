import { useState, useEffect, useRef } from "react";
import { message } from "antd";
import html2canvas from "html2canvas";
import { REACT_USER_ID } from "../../Auth/tokenProvider";
import { useDispatch } from "react-redux";
import { assessmentImageProctorAction } from "../../../redux/actions/assessment";

const useProctoring = () => {
  const dispatch = useDispatch();
  const [fullScreenExitCount, setFullScreenExitCount] = useState(0);
  const [mouseFocusLossCount, setMouseFocusLossCount] = useState(0);
  const [isFullscreenActive, setIsFullscreenActive] = useState(false);
  const [userData, setUserData] = useState({});
  const [webcamActive, setWebcamActive] = useState(false);
  const [mouseActive, setMouseActive] = useState(true);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const setupEventListeners = () => {
      document.addEventListener("fullscreenchange", handleFullscreenChange);
      window.addEventListener("blur", handleBlur);
      window.addEventListener("focus", handleFocus);
    };

    const removeEventListeners = () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };

    setupEventListeners();
    fetchUserData();
    getWebcamPermission();

    const intervalId = setInterval(
      captureWebcamAndScreen,
      process.env.REACT_APP_PROCTOR_CAPTURE
    );

    return () => {
      removeEventListeners();
      stopVideoStream();
      clearInterval(intervalId);
    };
  }, []);

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullscreenActive(true);
    } else {
      setIsFullscreenActive(false);
      setFullScreenExitCount((prev) => prev + 1);
      message.warning("Please stay in fullscreen mode");
      enterFullscreen();
    }
  };

  const handleBlur = () => {
    setMouseFocusLossCount((prev) => prev + 1);
    setMouseActive(false);
    message.warning("Please keep the mouse in the window");
  };

  const handleFocus = () => {
    setMouseActive(true);
  };

  const fetchUserData = async () => {
    try {
      const ipRes = await fetch("https://api.ipify.org?format=json");
      const { ip } = await ipRes.json();
      localStorage.setItem("REACT_DEVICE_IP", ip);

      // Use HTTPS instead of HTTP
      const locationRes = await fetch(`https://ipwhois.app/json/${ip}`);
      const locationData = await locationRes.json();

      const deviceData = getDeviceDetails();
      const networkData = getNetworkDetails();

      setUserData({ ip, ...locationData, ...deviceData, ...networkData });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getDeviceDetails = () => {
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const deviceType = /mobile/i.test(userAgent)
      ? "Mobile"
      : /tablet/i.test(userAgent) || /iPad/i.test(userAgent)
      ? "Tablet"
      : "Desktop";
    return { userAgent, platform, deviceType, browser: getBrowserInfo() };
  };

  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("Firefox")) return "Mozilla Firefox";
    if (userAgent.includes("Opera") || userAgent.includes("OPR"))
      return "Opera";
    if (userAgent.includes("Chrome")) return "Google Chrome";
    if (userAgent.includes("Safari")) return "Safari";
    if (userAgent.includes("MSIE") || userAgent.includes("Trident/"))
      return "Microsoft Internet Explorer";
    return "Unknown";
  };

  const getNetworkDetails = () => {
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection ||
      {};
    return {
      networkType: connection.effectiveType || "Unknown",
      downlink: connection.downlink || "Unknown",
      rtt: connection.rtt || "Unknown"
    };
  };

  const getWebcamPermission = async () => {
    try {
      const constraints = { video: { facingMode: "user" } };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      videoRef.current.onloadedmetadata = () => videoRef.current.play();
      setWebcamActive(true);
    } catch (error) {
      setWebcamActive(false);
      console.error("Unable to access webcam:", error);
    }
  };

  const stopVideoStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
  };

  const enterFullscreen = () => {
    const elem = document.documentElement;
    const requestFullscreen =
      elem.requestFullscreen ||
      elem.mozRequestFullScreen ||
      elem.webkitRequestFullscreen ||
      elem.msRequestFullscreen;
    if (requestFullscreen) {
      requestFullscreen
        .call(elem)
        .catch((err) => console.error("Fullscreen request failed:", err));
    } else {
      throw new Error("Fullscreen API is not supported by this browser.");
    }
  };

  const captureWebcamImage = async () => {
    try {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d");

      if (!canvas || !context) {
        console.error("Canvas or canvas context is not available");
        return;
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true
      });
      const video = document.createElement("video");
      video.srcObject = stream;
      video.autoplay = true;

      await new Promise((resolve) => {
        video.onloadedmetadata = () => resolve();
      });

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const webcamImage = canvas.toDataURL("image/png");

      dispatch(
        assessmentImageProctorAction({
          capture_ip: localStorage.getItem("REACT_DEVICE_IP"),
          assessment_invite_id: REACT_USER_ID(),
          capture_image: webcamImage,
          capture_type: "webcam"
        })
      );
    } catch (error) {
      console.error("Error capturing webcam image:", error);
    }
  };

  const captureScreenImage = async () => {
    const testArea = document.getElementById("test-area");
    if (!testArea) return;

    try {
      const canvas = await html2canvas(testArea, {
        allowTaint: true,
        useCORS: true,
        ignoreElements: (element) =>
          ["IFRAME", "VIDEO", "SCRIPT"].includes(element.tagName)
      });

      const screenImage = canvas.toDataURL("image/png");
      dispatch(
        assessmentImageProctorAction({
          capture_ip: localStorage.getItem("REACT_DEVICE_IP"),
          capture_image: screenImage,
          capture_type: "screen",
          assessment_invite_id: REACT_USER_ID()
        })
      );
    } catch (error) {
      console.error("Error capturing screen image:", error);
    }
  };

  const captureWebcamAndScreen = async () => {
    try {
      await captureWebcamImage();
      await captureScreenImage();
    } catch (error) {
      console.error("Error capturing images:", error);
    }
  };

  return {
    videoRef,
    canvasRef,
    startTest: () => enterFullscreen(),
    userData,
    webcamActive,
    mouseActive,
    fullScreenExitCount,
    mouseFocusLossCount,
    getWebcamPermission,
    isFullscreenActive
  };
};

export default useProctoring;
