import { call, delay, put } from "redux-saga/effects";
import {
  getClientReportConstants,
  getTeamReportConstants,
  getTimeLineReportsConstants
} from "../../constants";
import {
  getClientReportCall,
  getTeamReportCall,
  getTimeLineReportsCall
} from "../../network";

export function* getClientReportSaga(action) {
  try {
    const response = yield call(getClientReportCall, action.data);
    yield put({
      type: getClientReportConstants.GET_CLIENT_REPORT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getClientReportConstants.GET_CLIENT_REPORT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getClientReportConstants.GET_CLIENT_REPORT_RESET
    });
  }
}
export function* getTeamReportSaga(action) {
  try {
    const response = yield call(getTeamReportCall, action.data);
    yield put({
      type: getTeamReportConstants.GET_TEAM_REPORT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getTeamReportConstants.GET_TEAM_REPORT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getTeamReportConstants.GET_TEAM_REPORT_RESET
    });
  }
}
export function* getTimeLineReportsSaga(action) {
  try {
    const response = yield call(getTimeLineReportsCall, action.data);
    yield put({
      type: getTimeLineReportsConstants.GET_TIMELINE_REPORTS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getTimeLineReportsConstants.GET_TIMELINE_REPORTS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getTimeLineReportsConstants.GET_TIMELINE_REPORTS_RESET
    });
  }
}
