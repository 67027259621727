import React, { useEffect, useState } from "react";
import { Avatar, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoleUserAction } from "../../redux/actions/Roles&Management";
import { UserOutlined } from "@ant-design/icons";
import { UserTypeFind } from "./userSelect";

const { Option } = Select;

const ReportToUserSelection = ({
  form,
  fieldName,
  multiple = false,
  filter
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [allUsers, setAllUsers] = useState([]);

  const { data = [], loading } = useSelector(
    (state) => state.getAllRoleUserReducer || {}
  );

  const users = Array.isArray(data?.data) ? data.data : [];

  useEffect(() => {
    dispatch(
      getAllRoleUserAction({
        page,
        limit: 50,
        ...(filter?.selective
          ? { client_id: filter?.client_id, selective: filter?.selective }
          : { client_id: filter?.client_id })
      })
    );
    return () => {
      dispatch({ type: "GET_USER_RESET" });
    };
  }, [dispatch, page, filter]);

  useEffect(() => {
   
    setAllUsers(users);
  }, [users]);



  const handleChange = (selectedValues) => {
    form.setFieldsValue({
      [fieldName]: multiple ? selectedValues : selectedValues
    });
  };

  const selectedValues = form?.getFieldValue(fieldName) ?? [];
  const selectedCount = Array.isArray(selectedValues)
    ? selectedValues.length
    : 0;

  return (
    <Select
      size="large"
      className="w-100"
      mode={multiple ? "multiple" : undefined} // Enable multiple mode if true
      value={multiple ? selectedValues : selectedValues ?? ""}
      onChange={handleChange}
      placeholder={`Select a Recruitment Manager${
        multiple ? ` (${selectedCount} selected)` : ""
      }`}
      showSearch
      notFoundContent={loading ? <Spin size="small" /> : "No Recruiter found"}
      allowClear
      onClear={() => handleChange([])}
      filterOption={(input, option) => {
        const user = allUsers.find((user) => user.user_id === option.value);
        if (user) {
          const fullName = `${user.first_name} ${user.last_name}`;
          return fullName.toLowerCase().includes(input.toLowerCase());
        }
        return false;
      }}
    >
      {allUsers.length > 0 ? (
        allUsers.map((user) => (
          <Option key={user.user_id} value={user.user_id}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: "50%",
                  marginRight: 8
                }}
                src={
                  user?.photo
                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${user.photo}`
                    : null
                }
                icon={!user?.photo && <UserOutlined />}
              />
              <div style={{ flex: 1 }}>
                <strong style={{ display: "block", fontSize: "14px" }}>
                  {`${user.first_name} ${user.last_name} (${UserTypeFind(
                    user?.user_type
                  )})`}
                </strong>
              </div>
            </div>
          </Option>
        ))
      ) : (
        <Option disabled>No Recruiter available</Option>
      )}
    </Select>
  );
};

export default ReportToUserSelection;
