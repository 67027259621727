import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Divider, message } from "antd";
import { resumeParserAction } from "../../redux/actions/common";
import ImageUploadComponent from "../Upload";

const ApplyByResumeOnly = ({ data }) => {
  const { job_id } = data?.job;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [resumeFileURL, setResumeFileURL] = useState(null);
  const [uploadFileData, setUploadFileData] = useState({
    fileData: {}
  });
  const { uploadImageReducer: resumeFileURLReqData, resumeParserReducer } =
    useSelector((state) => state);

  const uploadImageFileReducer = useSelector(
    (state) => state.uploadImageFileReducer
  );
  useEffect(() => {
    const { request } = uploadImageFileReducer;

    if (request?.fileData) {
      setUploadFileData((prevState) => ({
        ...prevState,
        fileData: {
          ...prevState.fileData,
          name: request.fileData.name,
          size: request.fileData.size,
          type: request.fileData.type,
          uid: request.fileData.uid
        }
      }));
    }
  }, [uploadImageFileReducer]);
  useEffect(() => {
    const { loading, data, error, status, headers, request } =
      resumeParserReducer;
    setLoading(loading);

    if (!loading) {
      if (data && !error && status === 201) {
        message.success("Job Applied successfully!");

        form.resetFields();
        setResumeFileURL(null);
      }
      if (
        request?.job_id === job_id &&
        headers &&
        error &&
        [500, 201, 409, 400, 404].includes(status)
      ) {
        message.error(error);
      }
    }
  }, [resumeParserReducer, job_id, form]);

  const handleResumeUpload = (url) => {
    setResumeFileURL(url);
  };

  const handleApply = async () => {
    const filterUrl = resumeFileURLReqData?.data?.url.split("?")[0];
    await dispatch(
      resumeParserAction({
        parser_type: "application",
        pdfUrl: filterUrl,
        job_id,
        stage: "applied",
        ...uploadFileData
      })
    );
  };

  return (
    <>
      <Divider>Apply By Resume Only</Divider>
      <Form form={form} name="applyResumeForm">
        <Form.Item
          name="resume"
          className="candidate-db-1L916 candidate-db-ZvWLm candidate-db-uBZyw"
          rules={[
            {
              required: !resumeFileURL,
              message: "Please upload your resume"
            }
          ]}
        >
          <ImageUploadComponent
            uploadPath="hire_talent/resume/"
            acceptedFileTypes="application/pdf, .pdf"
            uploadType="PDF Resume"
            onFileUpload={handleResumeUpload}
          />
        </Form.Item>
        {resumeFileURLReqData?.data?.url && resumeFileURL && (
          <Button
            disabled={loading}
            loading={loading}
            onClick={handleApply}
            className="button--2de5X button--14TuV button--FDW79 primary--25RCR main_border_radius"
            style={{ marginRight: 8, backgroundColor: "#005c53" }}
            type="submit"
          >
            Apply
          </Button>
        )}
      </Form>
    </>
  );
};

export default ApplyByResumeOnly;
