import React, { useEffect, useState } from "react";
import { Layout, Form, Input, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../../Utils/Common";
import { candidateLoginAction } from "../../../redux/actions/candidate";

const { Content } = Layout;

const CandidateLogin = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [form] = Form.useForm();

  const {
    data: otpData,
    loading: otpLoading,
    error: otpError,
    request: otpRequest
  } = useSelector((state) => state.candidateLoginReducer);

  useEffect(() => {
    setLoading(otpLoading);

    if (otpError) {
      message.error(otpError);
    }
    if (!otpLoading && otpData && !otpError) {
      setOtpSent(true);

      message.success(otpData?.message);
    }
  }, [otpData, otpLoading, otpError, otpSent]);

  const handleOtpRequest = async (values) => {
    const requestType = otpSent ? "otp_verification" : "otp_sending";
    const payload = {
      email: values.email,
      api_type: requestType
    };

    if (otpSent) {
      payload.otp = otp;
    }

    try {
      await dispatch(candidateLoginAction(payload));
    } catch (error) {
      message.error(
        `Failed to ${otpSent ? "verify OTP" : "send OTP"}. Please try again.`
      );
    }
  };

  const onFinish = (values) => {
    if (!otpSent || (otpSent && otp)) {
      handleOtpRequest(values);
    } else {
      message.error("Please enter the OTP.");
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#fdf7f4" }}>
      <Content
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100%", padding: "16px" }}
      >
        <div
          className="bg-white shadow"
          style={{
            maxWidth: "672px",
            width: "100%",
            padding: "40px",
            border: "1px solid #d3d3d3",
            borderRadius: "18px",
            boxSizing: "border-box",
          }}
        >
          <div className="mb-4 d-flex justify-content-center">
            <img
              src="https://static.mentorpal.ai/prod/static_assests/email_assets/logo-long.png"
              alt="MentorPal Logo"
              className="img-fluid"
              style={{ width: "100%", maxWidth: "400px" }}
            />
          </div>
          <h2
            className="text-center mb-4"
            style={{ fontWeight: 700, fontSize: "24px", lineHeight: "32px" }}
          >
            Log in with Email
          </h2>
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!"
                },
                {
                  validator: validateEmail
                }
              ]}
            >
              <Input className="p-2" size="large" disabled={otpSent} />
            </Form.Item>

            {otpSent && (
              <Form.Item
                label="OTP"
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Please input the OTP sent to your email"
                  }
                ]}
              >
                <Input
                  className="p-2"
                  size="large"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Item>
            )}

            <Form.Item>
              <Button
                type="primary"
                block
                style={{
                  backgroundColor: "#005C53",
                  minHeight: "48px",
                  padding: "5px",
                  lineHeight: "22px",
                  borderRadius: "18px",
                  color: "white"
                }}
                htmlType="submit"
                loading={loading}
              >
                {loading
                  ? otpSent
                    ? "Verifying OTP..."
                    : "Sending OTP..."
                  : otpSent
                  ? "Verify OTP"
                  : "Login"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default CandidateLogin;
