import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, message } from "antd";
import UserCustomSelect from "../../../Utils/ClientSelect/userSelect";
import { assignToRecruiterAction } from "../../../redux/actions/common";

const AssignModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const assignToRecruiterReducer = useSelector(
    (state) => state.assignToRecruiterReducer
  );

  useEffect(() => {
    if (visible?.data) {
      form.setFieldsValue({
        assign_data: visible.data
      });
    }
  }, [visible?.data, form]);

  useEffect(() => {
    const { loading, data, status, error } = assignToRecruiterReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        message.success(data?.message || "Successfully assigned!");
        setVisible((prev) => ({
          ...prev,
          show: false,
          data: {}
        }));
      } else if (error) {
        message.error(`Error: ${error}`);
        console.warn("Assign error:", error);
      }
    }
  }, [assignToRecruiterReducer, setVisible]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const data = values.assign_data;

      if (!data || !data.userIds || !data.userIds.length) {
        form.setFields([
          { name: "assign_data", errors: ["Please select a recruiter!"] }
        ]);
        return;
      }

      Modal.confirm({
        title: "Confirm Assignment",
        content: "Are you sure you want to assign this recruiter?",
        onOk: () => {
          dispatch(assignToRecruiterAction(data));
        },
        onCancel() {
          console.log("Assignment canceled.");
        }
      });
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  return (
    <Modal
      title="Update Assignee"
      visible={visible?.show}
      onCancel={() => {
        setVisible((prev) => ({
          ...prev,
          show: false,
          data: {}
        }));
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setVisible((prev) => ({
              ...prev,
              show: false,
              data: {}
            }));
          }}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          style={{ backgroundColor: "#00756a" }}
          onClick={handleSave}
          loading={loading}
        >
          Save
        </Button>
      ]}
      centered
      width={500}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="assign_data"
          label="Select Recruiter"
          rules={[{ required: true, message: "Please select a recruiter!" }]}
        >
          <UserCustomSelect
            form={form}
            multiple={true}
            filter={{
              ...(visible?.client_id
                ? { selective: true, client_id: visible?.client_id }
                : null)
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignModal;
