import React, { useState, useEffect } from "react";
import { Alert, Input, Button, Space, message, Tooltip, Switch } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const MCQOptionsInput = ({ form, data }) => {
  const [options, setOptions] = useState([]);

  const addOption = () => {
    setOptions((prevOptions) => [
      ...prevOptions,
      { id: Date.now(), value: "", correct_answer: false }
    ]);
  };

  const removeOption = (id) => {
    if (options.length <= 2) {
      message.error("At least two options are required.");
      return;
    }
    setOptions((prevOptions) =>
      prevOptions.filter((option) => option.id !== id)
    );
  };

  const handleInputChange = (id, value) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id ? { ...option, value } : option
      )
    );
  };

  const handleSwitchChange = (id) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id
          ? { ...option, correct_answer: !option.correct_answer }
          : { ...option, correct_answer: false }
      )
    );
  };

  useEffect(() => {
    setOptions(data?.mcqChoices || []);
  }, [data]);

  useEffect(() => {
    form.setFieldValue("mcqChoices", options);
  }, [form,options]);

  return (
    <>
      <Alert
        message="MCQ Instructions"
        description={
          <>
            <p>
              Please follow these instructions to create and manage MCQ options:
            </p>
            <ol>
              <li>Use the input fields to specify the option text.</li>
              <li>
                Toggle the switch to mark the option as the correct answer.
              </li>
              <li>
                To add a new option, click the <strong>'Add option'</strong>{" "}
                button.
              </li>
              <li>
                To remove an option, click the <strong>minus icon</strong> next
                to it. Note: At least two options must remain.
              </li>
            </ol>
          </>
        }
        type="info"
        showIcon
      />
      <div className="py-4">
        {options.map((option, index) => (
          <Space
            key={option.id}
            className="option-container"
            align="baseline"
            style={{ width: "100%" }}
          >
            <Input
              placeholder={`Option ${index + 1}`}
              value={option.value}
              onChange={(e) => handleInputChange(option.id, e.target.value)}
              size="large"
              style={{ width: "100%" }}
            />
            <Tooltip title="Correct Answer">
              <Switch
                checked={option.correct_answer}
                onChange={() => handleSwitchChange(option.id)}
                size="large"
              />
            </Tooltip>
            {options.length > 2 && (
              <MinusCircleOutlined
                className="minus-icon"
                onClick={() => removeOption(option.id)}
              />
            )}
          </Space>
        ))}
        <Button
          type="dashed"
          onClick={addOption}
          icon={<PlusOutlined />}
          size="large"
          style={{ width: "100%", marginTop: "16px" }}
        >
          Add option
        </Button>
      </div>
    </>
  );
};

export default MCQOptionsInput;
