export const getPresignedUrlConstants = {
  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_REQUEST_SUCCESS: "UPLOAD_IMAGE_REQUEST_SUCCESS",
  UPLOAD_IMAGE_REQUEST_FAILURE: "UPLOAD_IMAGE_REQUEST_FAILURE",
  UPLOAD_IMAGE_RESET: "UPLOAD_IMAGE_RESET"
};
export const uploadImageFileConstants = {
  UPLOAD_IMAGE_FILE_REQUEST: "UPLOAD_IMAGE_FILE_REQUEST",
  UPLOAD_IMAGE_FILE_REQUEST_SUCCESS: "UPLOAD_IMAGE_FILE_REQUEST_SUCCESS",
  UPLOAD_IMAGE_FILE_REQUEST_FAILURE: "UPLOAD_IMAGE_FILE_REQUEST_FAILURE",
  UPLOAD_IMAGE_FILE_RESET: "UPLOAD_IMAGE_FILE_RESET"
};

export const resumeParserConstants = {
  RESUME_PARSER_REQUEST: "RESUME_PARSER_REQUEST",
  RESUME_PARSER_SUCCESS: "RESUME_PARSER_SUCCESS",
  RESUME_PARSER_FAILURE: "RESUME_PARSER_FAILURE",
  RESUME_PARSER_RESET: "RESUME_PARSER_RESET"
};

export const matchingAlgoConstants = {
  MATCHING_ALGO_REQUEST: "MATCHING_ALGO_REQUEST",
  MATCHING_ALGO_SUCCESS: "MATCHING_ALGO_SUCCESS",
  MATCHING_ALGO_FAILURE: "MATCHING_ALGO_FAILURE",
  MATCHING_ALGO_RESET: "MATCHING_ALGO_RESET"
};

export const createJobConstants = {
  CREATE_JOB_REQUEST: "CREATE_JOB_REQUEST",
  CREATE_JOB_REQUEST_SUCCESS: "CREATE_JOB_REQUEST_SUCCESS",
  CREATE_JOB_REQUEST_FAILURE: "CREATE_JOB_REQUEST_FAILURE",
  CREATE_JOB_RESET: "CREATE_JOB_RESET"
};

export const updateJobConstants = {
  UPDATE_JOB_REQUEST: "UPDATE_JOB_REQUEST",
  UPDATE_JOB_SUCCESS: "UPDATE_JOB_SUCCESS",
  UPDATE_JOB_FAILURE: "UPDATE_JOB_FAILURE",
  UPDATE_JOB_RESET: "UPDATE_JOB_RESET"
};

export const updateJobApplicationConstants = {
  UPDATE_APPLICATION_REQUEST: "UPDATE_APPLICATION_REQUEST",
  UPDATE_APPLICATION_SUCCESS: "UPDATE_APPLICATION_SUCCESS",
  UPDATE_APPLICATION_FAILURE: "UPDATE_APPLICATION_FAILURE",
  UPDATE_APPLICATION_RESET: "UPDATE_APPLICATION_RESET"
};

export const addCandidateConstants = {
  ADD_CANDIDATE_REQUEST: "ADD_CANDIDATE_REQUEST",
  ADD_CANDIDATE_SUCCESS: "ADD_CANDIDATE_SUCCESS",
  ADD_CANDIDATE_FAILURE: "ADD_CANDIDATE_FAILURE",
  ADD_CANDIDATE_RESET: "ADD_CANDIDATE_RESET"
};

export const getJobConstants = {
  GET_JOB_REQUEST: "GET_JOB_REQUEST",
  GET_JOB_REQUEST_SUCCESS: "GET_JOB_REQUEST_SUCCESS",
  GET_JOB_REQUEST_FAILURE: "GET_JOB_REQUEST_FAILURE",
  GET_JOB_RESET: "GET_JOB_RESET"
};

export const getJobDetailConstants = {
  GET_JOB_DETAIL_REQUEST: "GET_JOB_DETAIL_REQUEST",
  GET_JOB_DETAIL_SUCCESS: "GET_JOB_DETAIL_SUCCESS",
  GET_JOB_DETAIL_FAILURE: "GET_JOB_DETAIL_FAILURE",
  GET_JOB_DETAIL_RESET: "GET_JOB_DETAIL_RESET"
};

export const logoutConstants = {
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOGOUT_RESET: "LOGOUT_RESET"
};

export const applyJobConstants = {
  APPLY_JOB_REQUEST: "APPLY_JOB_REQUEST",
  APPLY_JOB_SUCCESS: "APPLY_JOB_SUCCESS",
  APPLY_JOB_FAILURE: "APPLY_JOB_FAILURE",
  APPLY_JOB_RESET: "APPLY_JOB_RESET" // RESET already added
};

export const getAllCandidateActions = {
  GET_ALL_CANDIDATE_REQUEST: "GET_ALL_CANDIDATE_REQUEST",
  GET_ALL_CANDIDATE_SUCCESS: "GET_ALL_CANDIDATE_SUCCESS",
  GET_ALL_CANDIDATE_FAILURE: "GET_ALL_CANDIDATE_FAILURE",
  GET_ALL_CANDIDATE_RESET: "GET_ALL_CANDIDATE_RESET"
};

export const addClientConstants = {
  ADD_CLIENT_REQUEST: "ADD_CLIENT_REQUEST",
  ADD_CLIENT_SUCCESS: "ADD_CLIENT_SUCCESS",
  ADD_CLIENT_FAILURE: "ADD_CLIENT_FAILURE",
  ADD_CLIENT_RESET: "ADD_CLIENT_RESET"
};

export const getClientJobsConstants = {
  GET_CLIENT_JOBS_REQUEST: "GET_CLIENT_JOBS_REQUEST",
  GET_CLIENT_JOBS_SUCCESS: "GET_CLIENT_JOBS_SUCCESS",
  GET_CLIENT_JOBS_FAILURE: "GET_CLIENT_JOBS_FAILURE",
  GET_CLIENT_JOBS_RESET: "GET_CLIENT_JOBS_RESET"
};

export const getClientListConstants = {
  GET_CLIENT_LIST_REQUEST: "GET_CLIENT_LIST_REQUEST",
  GET_CLIENT_LIST_SUCCESS: "GET_CLIENT_LIST_SUCCESS",
  GET_CLIENT_LIST_FAILURE: "GET_CLIENT_LIST_FAILURE",
  GET_CLIENT_LIST_RESET: "GET_CLIENT_LIST_RESET"
};

export const getClientDetailsConstants = {
  GET_CLIENT_DETAIL_REQUEST: "GET_CLIENT_DETAIL_REQUEST",
  GET_CLIENT_DETAIL_SUCCESS: "GET_CLIENT_DETAIL_SUCCESS",
  GET_CLIENT_DETAIL_FAILURE: "GET_CLIENT_DETAIL_FAILURE",
  GET_CLIENT_DETAIL_RESET: "GET_CLIENT_DETAIL_RESET"
};

export const updateClientDetailsConstants = {
  UPDATE_CLIENT_DETAIL_REQUEST: "UPDATE_CLIENT_DETAIL_REQUEST",
  UPDATE_CLIENT_DETAIL_SUCCESS: "UPDATE_CLIENT_DETAIL_SUCCESS",
  UPDATE_CLIENT_DETAIL_FAILURE: "UPDATE_CLIENT_DETAIL_FAILURE",
  UPDATE_CLIENT_DETAIL_RESET: "UPDATE_CLIENT_DETAIL_RESET"
};

export const getCandidateProfileConstants = {
  CANDIDATE_PROFILE_REQUEST: "CANDIDATE_PROFILE_REQUEST",
  CANDIDATE_PROFILE_SUCCESS: "CANDIDATE_PROFILE_SUCCESS",
  CANDIDATE_PROFILE_FAILURE: "CANDIDATE_PROFILE_FAILURE",
  CANDIDATE_PROFILE_RESET: "CANDIDATE_PROFILE_RESET"
};

export const signupUserConstants = {
  SIGNUP_USER_REQUEST: "SIGNUP_USER_REQUEST",
  SIGNUP_USER_SUCCESS: "SIGNUP_USER_SUCCESS",
  SIGNUP_USER_FAILURE: "SIGNUP_USER_FAILURE",
  SIGNUP_USER_RESET: "SIGNUP_USER_RESET"
};

export const getuserDetailsConstants = {
  GET_USER_DETAILS_REQUEST: "GET_USER_DETAILS_REQUEST",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",
  GET_USER_DETAILS_RESET: "GET_USER_DETAILS_RESET"
};

export const updateUserDetailsConstants = {
  UPDATE_USER_DETAILS_REQUEST: "UPDATE_USER_DETAILS_REQUEST",
  UPDATE_USER_DETAILS_SUCCESS: "UPDATE_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_FAILURE: "UPDATE_USER_DETAILS_FAILURE",
  UPDATE_USER_DETAILS_RESET: "UPDATE_USER_DETAILS_RESET"
};

export const loginUserConstants = {
  LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  LOGIN_USER_RESET: "LOGIN_USER_RESET"
};

export const createPermissionConstants = {
  ADD_PERMISSIONS_REQUEST: "ADD_PERMISSIONS_REQUEST",
  ADD_PERMISSIONS_SUCCESS: "ADD_PERMISSIONS_SUCCESS",
  ADD_PERMISSIONS_FAILURE: "ADD_PERMISSIONS_FAILURE",
  ADD_PERMISSIONS_RESET: "ADD_PERMISSIONS_RESET"
};

export const getPermissionConstants = {
  GET_PERMISSIONS_REQUEST: "GET_PERMISSIONS_REQUEST",
  GET_PERMISSIONS_SUCCESS: "GET_PERMISSIONS_SUCCESS",
  GET_PERMISSIONS_FAILURE: "GET_PERMISSIONS_FAILURE",
  GET_PERMISSIONS_RESET: "GET_PERMISSIONS_RESET"
};

export const updatePermissionConstants = {
  UPDATE_PERMISSIONS_REQUEST: "UPDATE_PERMISSIONS_REQUEST",
  UPDATE_PERMISSIONS_SUCCESS: "UPDATE_PERMISSIONS_SUCCESS",
  UPDATE_PERMISSIONS_FAILURE: "UPDATE_PERMISSIONS_FAILURE",
  UPDATE_PERMISSIONS_RESET: "UPDATE_PERMISSIONS_RESET"
};

export const createUserConstants = {
  ADD_USER_REQUEST: "ADD_USER_REQUEST",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
  ADD_USER_RESET: "ADD_USER_RESET"
};

export const getUserConstants = {
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  GET_USER_RESET: "GET_USER_RESET"
};

export const createRoleConstants = {
  ADD_ROLE_REQUEST: "ADD_ROLE_REQUEST",
  ADD_ROLE_SUCCESS: "ADD_ROLE_SUCCESS",
  ADD_ROLE_FAILURE: "ADD_ROLE_FAILURE",
  ADD_ROLE_RESET: "ADD_ROLE_RESET"
};

export const getRoleConstants = {
  GET_ROLE_REQUEST: "GET_ROLE_REQUEST",
  GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
  GET_ROLE_FAILURE: "GET_ROLE_FAILURE",
  GET_ROLE_RESET: "GET_ROLE_RESET"
};

export const getJobApplicationConstants = {
  GET_JOB_APPLICATION_REQUEST: "GET_JOB_APPLICATION_REQUEST",
  GET_JOB_APPLICATION_SUCCESS: "GET_JOB_APPLICATION_SUCCESS",
  GET_JOB_APPLICATION_FAILURE: "GET_JOB_APPLICATION_FAILURE",
  GET_JOB_APPLICATION_RESET: "GET_JOB_APPLICATION_RESET"
};

export const updateRoleConstants = {
  UPDATE_ROLE_REQUEST: "UPDATE_ROLE_REQUEST",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAILURE: "UPDATE_ROLE_FAILURE",
  UPDATE_ROLE_RESET: "UPDATE_ROLE_RESET"
};

export const postFeedbackConstants = {
  POST_FEEDBACK_REQUEST: "POST_FEEDBACK_REQUEST",
  POST_FEEDBACK_SUCCESS: "POST_FEEDBACK_SUCCESS",
  POST_FEEDBACK_FAILURE: "POST_FEEDBACK_FAILURE",
  POST_FEEDBACK_RESET: "POST_FEEDBACK_RESET"
};

export const getUserPermissionConstants = {
  GET_USER_PERMISSIONS_REQUEST: "GET_USER_PERMISSIONS_REQUEST",
  GET_USER_PERMISSIONS_SUCCESS: "GET_USER_PERMISSIONS_SUCCESS",
  GET_USER_PERMISSIONS_FAILURE: "GET_USER_PERMISSIONS_FAILURE",
  GET_USER_PERMISSIONS_RESET: "GET_USER_PERMISSIONS_RESET"
};

export const getInterviewerListConstants = {
  GET_INTERVIEWER_LIST_REQUEST: "GET_INTERVIEWER_LIST_REQUEST",
  GET_INTERVIEWER_LIST_SUCCESS: "GET_INTERVIEWER_LIST_SUCCESS",
  GET_INTERVIEWER_LIST_FAILURE: "GET_INTERVIEWER_LIST_FAILURE",
  GET_INTERVIEWER_LIST_RESET: "GET_INTERVIEWER_LIST_RESET"
};

export const getInterviewerReportConstants = {
  GET_INTERVIEWER_REPORT_REQUEST: "GET_INTERVIEWER_REPORT_REQUEST",
  GET_INTERVIEWER_REPORT_SUCCESS: "GET_INTERVIEWER_REPORT_SUCCESS",
  GET_INTERVIEWER_REPORT_FAILURE: "GET_INTERVIEWER_REPORT_FAILURE",
  GET_INTERVIEWER_REPORT_RESET: "GET_INTERVIEWER_REPORT_RESET"
};

export const getInterviewDetailsConstants = {
  GET_INTERVIEW_DETAILS_REQUEST: "GET_INTERVIEW_DETAILS_REQUEST",
  GET_INTERVIEW_DETAILS_SUCCESS: "GET_INTERVIEW_DETAILS_SUCCESS",
  GET_INTERVIEW_DETAILS_FAILURE: "GET_INTERVIEW_DETAILS_FAILURE",
  GET_INTERVIEW_DETAILS_RESET: "GET_INTERVIEW_DETAILS_RESET"
};

export const getAllScheduleInterviewListConstants = {
  GET_SCHEDULE_INTERVIEW_LIST_REQUEST: "GET_SCHEDULE_INTERVIEW_LIST_REQUEST",
  GET_SCHEDULE_INTERVIEW_LIST_SUCCESS: "GET_SCHEDULE_INTERVIEW_LIST_SUCCESS",
  GET_SCHEDULE_INTERVIEW_LIST_FAILURE: "GET_SCHEDULE_INTERVIEW_LIST_FAILURE",
  GET_SCHEDULE_INTERVIEW_LIST_RESET: "GET_SCHEDULE_INTERVIEW_LIST_RESET"
};
export const scheduleInterviewConstants = {
  SCHEDULE_INTERVIEW_REQUEST: "SCHEDULE_INTERVIEW_REQUEST",
  SCHEDULE_INTERVIEW_SUCCESS: "SCHEDULE_INTERVIEW_SUCCESS",
  SCHEDULE_INTERVIEW_FAILURE: "SCHEDULE_INTERVIEW_FAILURE",
  SCHEDULE_INTERVIEW_RESET: "SCHEDULE_INTERVIEW_RESET"
};

export const interviewFeedbackConstants = {
  INTERVIEW_FEEDBACK_REQUEST: "INTERVIEW_FEEDBACK_REQUEST",
  INTERVIEW_FEEDBACK_SUCCESS: "INTERVIEW_FEEDBACK_SUCCESS",
  INTERVIEW_FEEDBACK_FAILURE: "INTERVIEW_FEEDBACK_FAILURE",
  INTERVIEW_FEEDBACK_RESET: "INTERVIEW_FEEDBACK_RESET"
};

export const forgetPasswordLinkConstants = {
  FORGET_PASSWORD_LINK_REQUEST: "FORGET_PASSWORD_LINK_REQUEST",
  FORGET_PASSWORD_LINK_SUCCESS: "FORGET_PASSWORD_LINK_SUCCESS",
  FORGET_PASSWORD_LINK_FAILURE: "FORGET_PASSWORD_LINK_FAILURE",
  FORGET_PASSWORD_LINK_RESET: "FORGET_PASSWORD_LINK_RESET"
};

export const updatePasswordConstants = {
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
  UPDATE_PASSWORD_RESET: "UPDATE_PASSWORD_RESET"
};

export const enquiryConstants = {
  ENQUIRY_REQUEST: "ENQUIRY_REQUEST",
  ENQUIRY_SUCCESS: "ENQUIRY_SUCCESS",
  ENQUIRY_FAILURE: "ENQUIRY_FAILURE",
  ENQUIRY_RESET: "ENQUIRY_RESET"
};

export const getEnquiryConstants = {
  GET_ENQUIRY_REQUEST: "GET_ENQUIRY_REQUEST",
  GET_ENQUIRY_SUCCESS: "GET_ENQUIRY_SUCCESS",
  GET_ENQUIRY_FAILURE: "GET_ENQUIRY_FAILURE",
  GET_ENQUIRY_RESET: "GET_ENQUIRY_RESET"
};

export const updateEnquiryConstants = {
  UPDATE_ENQUIRY_REQUEST: "UPDATE_ENQUIRY_REQUEST",
  UPDATE_ENQUIRY_SUCCESS: "UPDATE_ENQUIRY_SUCCESS",
  UPDATE_ENQUIRY_FAILURE: "UPDATE_ENQUIRY_FAILURE",
  UPDATE_ENQUIRY_RESET: "UPDATE_ENQUIRY_RESET"
};

export const updateInterviewDetailsConstants = {
  UPDATE_INTERVIEW_DETAILS_REQUEST: "UPDATE_INTERVIEW_DETAILS_REQUEST",
  UPDATE_INTERVIEW_DETAILS_SUCCESS: "UPDATE_INTERVIEW_DETAILS_SUCCESS",
  UPDATE_INTERVIEW_DETAILS_FAILURE: "UPDATE_INTERVIEW_DETAILS_FAILURE",
  UPDATE_INTERVIEW_DETAILS_RESET: "UPDATE_INTERVIEW_DETAILS_RESET"
};

export const sendOtpConstants = {
  SEND_OTP_REQUEST: "SEND_OTP_REQUEST",
  SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
  SEND_OTP_FAILURE: "SEND_OTP_FAILURE",
  SEND_OTP_RESET: "SEND_OTP_RESET"
};

export const verifyOtpConstants = {
  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
  VERIFY_OTP_RESET: "VERIFY_OTP_RESET"
};

export const verifyEmailConstants = {
  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILURE: "VERIFY_EMAIL_FAILURE",
  VERIFY_EMAIL_RESET: "VERIFY_EMAIL_RESET"
};

export const updateCandidateConstants = {
  UPDATE_CANDIDATE_REQUEST: "UPDATE_CANDIDATE_REQUEST",
  UPDATE_CANDIDATE_SUCCESS: "UPDATE_CANDIDATE_SUCCESS",
  UPDATE_CANDIDATE_FAILURE: "UPDATE_CANDIDATE_FAILURE",
  UPDATE_CANDIDATE_RESET: "UPDATE_CANDIDATE_RESET"
};

export const getClientReportConstants = {
  GET_CLIENT_REPORT_REQUEST: "GET_CLIENT_REPORT_REQUEST",
  GET_CLIENT_REPORT_SUCCESS: "GET_CLIENT_REPORT_SUCCESS",
  GET_CLIENT_REPORT_FAILURE: "GET_CLIENT_REPORT_FAILURE",
  GET_CLIENT_REPORT_RESET: "GET_CLIENT_REPORT_RESET"
};

export const getTeamReportConstants = {
  GET_TEAM_REPORT_REQUEST: "GET_TEAM_REPORT_REQUEST",
  GET_TEAM_REPORT_SUCCESS: "GET_TEAM_REPORT_SUCCESS",
  GET_TEAM_REPORT_FAILURE: "GET_TEAM_REPORT_FAILURE",
  GET_TEAM_REPORT_RESET: "GET_TEAM_REPORT_RESET"
};

export const createQuestionConstants = {
  CREATE_QUESTION_REQUEST: "CREATE_QUESTION_REQUEST",
  CREATE_QUESTION_SUCCESS: "CREATE_QUESTION_SUCCESS",
  CREATE_QUESTION_FAILURE: "CREATE_QUESTION_FAILURE",
  CREATE_QUESTION_RESET: "CREATE_QUESTION_RESET"
};

export const updateQuestionConstants = {
  UPDATE_QUESTION_REQUEST: "UPDATE_QUESTION_REQUEST",
  UPDATE_QUESTION_SUCCESS: "UPDATE_QUESTION_SUCCESS",
  UPDATE_QUESTION_FAILURE: "UPDATE_QUESTION_FAILURE",
  UPDATE_QUESTION_RESET: "UPDATE_QUESTION_RESET"
};

export const getQuestionDetailsConstants = {
  GET_QUESTION_REQUEST: "GET_QUESTION_REQUEST",
  GET_QUESTION_SUCCESS: "GET_QUESTION_SUCCESS",
  GET_QUESTION_FAILURE: "GET_QUESTION_FAILURE",
  GET_QUESTION_RESET: "GET_QUESTION_RESET"
};

export const getAllQuestionListConstants = {
  GET_LIST_QUESTION_REQUEST: "GET_LIST_QUESTION_REQUEST",
  GET_LIST_QUESTION_SUCCESS: "GET_LIST_QUESTION_SUCCESS",
  GET_LIST_QUESTION_FAILURE: "GET_LIST_QUESTION_FAILURE",
  GET_LIST_QUESTION_RESET: "GET_LIST_QUESTION_RESET"
};

export const createTestConstants = {
  CREATE_TEST_REQUEST: "CREATE_TEST_REQUEST",
  CREATE_TEST_SUCCESS: "CREATE_TEST_SUCCESS",
  CREATE_TEST_FAILURE: "CREATE_TEST_FAILURE",
  CREATE_TEST_RESET: "CREATE_TEST_RESET"
};

export const updateTestConstants = {
  UPDATE_TEST_REQUEST: "UPDATE_TEST_REQUEST",
  UPDATE_TEST_SUCCESS: "UPDATE_TEST_SUCCESS",
  UPDATE_TEST_FAILURE: "UPDATE_TEST_FAILURE",
  UPDATE_TEST_RESET: "UPDATE_TEST_RESET"
};

export const getTestDetailsConstants = {
  GET_TEST_REQUEST: "GET_TEST_REQUEST",
  GET_TEST_SUCCESS: "GET_TEST_SUCCESS",
  GET_TEST_FAILURE: "GET_TEST_FAILURE",
  GET_TEST_RESET: "GET_TEST_RESET"
};

export const getAllTestListConstants = {
  GET_LIST_TEST_REQUEST: "GET_LIST_TEST_REQUEST",
  GET_LIST_TEST_SUCCESS: "GET_LIST_TEST_SUCCESS",
  GET_LIST_TEST_FAILURE: "GET_LIST_TEST_FAILURE",
  GET_LIST_TEST_RESET: "GET_LIST_TEST_RESET"
};

export const createAssessmentConstants = {
  CREATE_ASSESSMENT_REQUEST: "CREATE_ASSESSMENT_REQUEST",
  CREATE_ASSESSMENT_SUCCESS: "CREATE_ASSESSMENT_SUCCESS",
  CREATE_ASSESSMENT_FAILURE: "CREATE_ASSESSMENT_FAILURE",
  CREATE_ASSESSMENT_RESET: "CREATE_ASSESSMENT_RESET"
};

export const updateAssessmentConstants = {
  UPDATE_ASSESSMENT_REQUEST: "UPDATE_ASSESSMENT_REQUEST",
  UPDATE_ASSESSMENT_SUCCESS: "UPDATE_ASSESSMENT_SUCCESS",
  UPDATE_ASSESSMENT_FAILURE: "UPDATE_ASSESSMENT_FAILURE",
  UPDATE_ASSESSMENT_RESET: "UPDATE_ASSESSMENT_RESET"
};

export const inviteAssessmentConstants = {
  INVITE_ASSESSMENT_REQUEST: "INVITE_ASSESSMENT_REQUEST",
  INVITE_ASSESSMENT_SUCCESS: "INVITE_ASSESSMENT_SUCCESS",
  INVITE_ASSESSMENT_FAILURE: "INVITE_ASSESSMENT_FAILURE",
  INVITE_ASSESSMENT_RESET: "INVITE_ASSESSMENT_RESET"
};

export const getAssessmentDetailsConstants = {
  GET_ASSESSMENT_REQUEST: "GET_ASSESSMENT_REQUEST",
  GET_ASSESSMENT_SUCCESS: "GET_ASSESSMENT_SUCCESS",
  GET_ASSESSMENT_FAILURE: "GET_ASSESSMENT_FAILURE",
  GET_ASSESSMENT_RESET: "GET_ASSESSMENT_RESET"
};

export const getAllAssessmentListConstants = {
  GET_LIST_ASSESSMENT_REQUEST: "GET_LIST_ASSESSMENT_REQUEST",
  GET_LIST_ASSESSMENT_SUCCESS: "GET_LIST_ASSESSMENT_SUCCESS",
  GET_LIST_ASSESSMENT_FAILURE: "GET_LIST_ASSESSMENT_FAILURE",
  GET_LIST_ASSESSMENT_RESET: "GET_LIST_ASSESSMENT_RESET"
};

export const assessmentLoginConstants = {
  ASSESSMENT_LOGIN_REQUEST: "ASSESSMENT_LOGIN_REQUEST",
  ASSESSMENT_LOGIN_SUCCESS: "ASSESSMENT_LOGIN_SUCCESS",
  ASSESSMENT_LOGIN_FAILURE: "ASSESSMENT_LOGIN_FAILURE",
  ASSESSMENT_LOGIN_RESET: "ASSESSMENT_LOGIN_RESET"
};

export const getInviteAssessmentDetailsConstants = {
  GET_INVITE_ASSESSMENT_DETAILS_REQUEST:
    "GET_INVITE_ASSESSMENT_DETAILS_REQUEST",
  GET_INVITE_ASSESSMENT_DETAILS_SUCCESS:
    "GET_INVITE_ASSESSMENT_DETAILS_SUCCESS",
  GET_INVITE_ASSESSMENT_DETAILS_FAILURE:
    "GET_INVITE_ASSESSMENT_DETAILS_FAILURE",
  GET_INVITE_ASSESSMENT_DETAILS_RESET: "GET_INVITE_ASSESSMENT_DETAILS_RESET"
};

export const updateInviteAssessmentDetailsConstants = {
  UPDATE_INVITE_ASSESSMENT_DETAILS_REQUEST:
    "UPDATE_INVITE_ASSESSMENT_DETAILS_REQUEST",
  UPDATE_INVITE_ASSESSMENT_DETAILS_SUCCESS:
    "UPDATE_INVITE_ASSESSMENT_DETAILS_SUCCESS",
  UPDATE_INVITE_ASSESSMENT_DETAILS_FAILURE:
    "UPDATE_INVITE_ASSESSMENT_DETAILS_FAILURE",
  UPDATE_INVITE_ASSESSMENT_DETAILS_RESET:
    "UPDATE_INVITE_ASSESSMENT_DETAILS_RESET"
};

export const getAllAssessmentCandidateConstants = {
  GET_ALL_ASSESSMENT_CANDIDATE_REQUEST: "GET_ALL_ASSESSMENT_CANDIDATE_REQUEST",
  GET_ALL_ASSESSMENT_CANDIDATE_SUCCESS: "GET_ALL_ASSESSMENT_CANDIDATE_SUCCESS",
  GET_ALL_ASSESSMENT_CANDIDATE_FAILURE: "GET_ALL_ASSESSMENT_CANDIDATE_FAILURE",
  GET_ALL_ASSESSMENT_CANDIDATE_RESET: "GET_ALL_ASSESSMENT_CANDIDATE_RESET"
};

export const getAssessmentCandidateDetailsConstants = {
  GET_ASSESSMENT_CANDIDATE_DETAILS_REQUEST:
    "GET_ASSESSMENT_CANDIDATE_DETAILS_REQUEST",
  GET_ASSESSMENT_CANDIDATE_DETAILS_SUCCESS:
    "GET_ASSESSMENT_CANDIDATE_DETAILS_SUCCESS",
  GET_ASSESSMENT_CANDIDATE_DETAILS_FAILURE:
    "GET_ASSESSMENT_CANDIDATE_DETAILS_FAILURE",
  GET_ASSESSMENT_CANDIDATE_DETAILS_RESET:
    "GET_ASSESSMENT_CANDIDATE_DETAILS_RESET"
};

export const getAsCandidateDetailsConstants = {
  GET_NEW_CANDIDATE_DETAILS_REQUEST: "GET_NEW_CANDIDATE_DETAILS_REQUEST",
  GET_NEW_CANDIDATE_DETAILS_SUCCESS: "GET_NEW_CANDIDATE_DETAILS_SUCCESS",
  GET_NEW_CANDIDATE_DETAILS_FAILURE: "GET_NEW_CANDIDATE_DETAILS_FAILURE",
  GET_NEW_CANDIDATE_DETAILS_RESET: "GET_NEW_CANDIDATE_DETAILS_RESET"
};

export const candidateAssessmentSubmissionConstants = {
  CANDIDATE_ASSESSMENT_SUBMISSION_REQUEST:
    "CANDIDATE_ASSESSMENT_SUBMISSION_REQUEST",
  CANDIDATE_ASSESSMENT_SUBMISSION_SUCCESS:
    "CANDIDATE_ASSESSMENT_SUBMISSION_SUCCESS",
  CANDIDATE_ASSESSMENT_SUBMISSION_FAILURE:
    "CANDIDATE_ASSESSMENT_SUBMISSION_FAILURE",
  CANDIDATE_ASSESSMENT_SUBMISSION_RESET: "CANDIDATE_ASSESSMENT_SUBMISSION_RESET"
};

export const candidateAssessmentFeedbackConstants = {
  CANDIDATE_ASSESSMENT_FEEDBACK_REQUEST:
    "CANDIDATE_ASSESSMENT_FEEDBACK_REQUEST",
  CANDIDATE_ASSESSMENT_FEEDBACK_SUCCESS:
    "CANDIDATE_ASSESSMENT_FEEDBACK_SUCCESS",
  CANDIDATE_ASSESSMENT_FEEDBACK_FAILURE:
    "CANDIDATE_ASSESSMENT_FEEDBACK_FAILURE",
  CANDIDATE_ASSESSMENT_FEEDBACK_RESET: "CANDIDATE_ASSESSMENT_FEEDBACK_RESET"
};

export const assessmentImageProctorConstants = {
  ASSESSMENT_PROCTOR_REQUEST: "ASSESSMENT_PROCTOR_REQUEST",
  ASSESSMENT_PROCTOR_SUCCESS: "ASSESSMENT_PROCTOR_SUCCESS",
  ASSESSMENT_PROCTOR_FAILURE: "ASSESSMENT_PROCTOR_FAILURE",
  ASSESSMENT_PROCTOR_RESET: "ASSESSMENT_PROCTOR_RESET"
};

export const candidateLoginConstants = {
  CANDIDATE_LOGIN_REQUEST: "CANDIDATE_LOGIN_REQUEST",
  CANDIDATE_LOGIN_SUCCESS: "CANDIDATE_LOGIN_SUCCESS",
  CANDIDATE_LOGIN_FAILURE: "CANDIDATE_LOGIN_FAILURE",
  CANDIDATE_LOGIN_RESET: "CANDIDATE_LOGIN_RESET"
};

export const candidateStatsReportConstants = {
  CANDIDATE_STATS_REPORT_REQUEST: "CANDIDATE_STATS_REPORT_REQUEST",
  CANDIDATE_STATS_REPORT_SUCCESS: "CANDIDATE_STATS_REPORT_SUCCESS",
  CANDIDATE_STATS_REPORT_FAILURE: "CANDIDATE_STATS_REPORT_FAILURE",
  CANDIDATE_STATS_REPORT_RESET: "CANDIDATE_STATS_REPORT_RESET"
};

export const multipleActionConstants = {
  MULTIPLE_ACTION_REQUEST: "MULTIPLE_ACTION_REQUEST",
  MULTIPLE_ACTION_SUCCESS: "MULTIPLE_ACTION_SUCCESS",
  MULTIPLE_ACTION_FAILURE: "MULTIPLE_ACTION_FAILURE",
  MULTIPLE_ACTION_RESET: "MULTIPLE_ACTION_RESET"
};

export const getSubUserDetailsConstants = {
  USER_DETAILS_REQUEST: "USER_DETAILS_REQUEST",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_FAILURE: "USER_DETAILS_FAILURE",
  USER_DETAILS_RESET: "USER_DETAILS_RESET"
};

export const createClientUserConstants = {
  ADD_CLIENT_USER_REQUEST: "ADD_CLIENT_USER_REQUEST",
  ADD_CLIENT_USER_SUCCESS: "ADD_CLIENT_USER_SUCCESS",
  ADD_CLIENT_USER_FAILURE: "ADD_CLIENT_USER_FAILURE",
  ADD_CLIENT_USER_RESET: "ADD_CLIENT_USER_RESET"
};

export const getAllClientUserConstants = {
  GET_ALL_CLIENT_USER_REQUEST: "GET_ALL_CLIENT_USER_REQUEST",
  GET_ALL_CLIENT_USER_SUCCESS: "GET_ALL_CLIENT_USER_SUCCESS",
  GET_ALL_CLIENT_USER_FAILURE: "GET_ALL_CLIENT_USER_FAILURE",
  GET_ALL_CLIENT_USER_RESET: "GET_ALL_CLIENT_USER_RESET"
};

export const getCandidateDetailsDashboardConstants = {
  GET_CANDIDATE_DETAILS_REQUEST: "GET_CANDIDATE_DETAILS_REQUEST",
  GET_CANDIDATE_DETAILS_SUCCESS: "GET_CANDIDATE_DETAILS_SUCCESS",
  GET_CANDIDATE_DETAILS_FAILURE: "GET_CANDIDATE_DETAILS_FAILURE",
  GET_CANDIDATE_DETAILS_RESET: "GET_CANDIDATE_DETAILS_RESET"
};

export const getInvitationDetailsConstants = {
  GET_INVITE_DETAILS_REQUEST: "GET_INVITE_DETAILS_REQUEST",
  GET_INVITE_DETAILS_SUCCESS: "GET_INVITE_DETAILS_SUCCESS",
  GET_INVITE_DETAILS_FAILURE: "GET_INVITE_DETAILS_FAILURE",
  GET_INVITE_DETAILS_RESET: "GET_INVITE_DETAILS_RESET"
};
export const getJobAllAppliedCandidateConstants = {
  GET_JOB_ALL_APPLIED_CANDIDATE_REQUEST:
    "GET_JOB_ALL_APPLIED_CANDIDATE_REQUEST",
  GET_JOB_ALL_APPLIED_CANDIDATE_SUCCESS:
    "GET_JOB_ALL_APPLIED_CANDIDATE_SUCCESS",
  GET_JOB_ALL_APPLIED_CANDIDATE_FAILURE:
    "GET_JOB_ALL_APPLIED_CANDIDATE_FAILURE",
  GET_JOB_ALL_APPLIED_CANDIDATE_RESET: "GET_JOB_ALL_APPLIED_CANDIDATE_RESET"
};

export const getFeedbackListConstant = {
  GET_FEEDBACK_LIST_REQUEST: "GET_FEEDBACK_LIST_REQUEST",
  GET_FEEDBACK_LIST_SUCCESS: "GET_FEEDBACK_LIST_SUCCESS",
  GET_FEEDBACK_LIST_FAILURE: "GET_FEEDBACK_LIST_FAILURE",
  GET_FEEDBACK_LIST_RESET: "GET_FEEDBACK_LIST_RESET"
};
export const updateFeedbackConstant = {
  UPDATE_FEEDBACK_LIST_REQUEST: "UPDATE_FEEDBACK_LIST_REQUEST",
  UPDATE_FEEDBACK_LIST_SUCCESS: "UPDATE_FEEDBACK_LIST_SUCCESS",
  UPDATE_FEEDBACK_LIST_FAILURE: "UPDATE_FEEDBACK_LIST_FAILURE",
  UPDATE_FEEDBACK_LIST_RESET: "UPDATE_FEEDBACK_LIST_RESET"
};
export const assignToRecruiterConstants = {
  ASSIGN_TO_RECRUITER_REQUEST: "ASSIGN_TO_RECRUITER_REQUEST",
  ASSIGN_TO_RECRUITER_SUCCESS: "ASSIGN_TO_RECRUITER_SUCCESS",
  ASSIGN_TO_RECRUITER_FAILURE: "ASSIGN_TO_RECRUITER_FAILURE",
  ASSIGN_TO_RECRUITER_RESET: "ASSIGN_TO_RECRUITER_RESET"
};

export const emailSubscribeConstants = {
  EMAIL_SUBSCRIBE_REQUEST: "EMAIL_SUBSCRIBE_REQUEST",
  EMAIL_SUBSCRIBE_SUCCESS: "EMAIL_SUBSCRIBE_SUCCESS",
  EMAIL_SUBSCRIBE_FAILURE: "EMAIL_SUBSCRIBE_FAILURE",
  EMAIL_SUBSCRIBE_RESET: "EMAIL_SUBSCRIBE_RESET"
};
export const getTimeLineReportsConstants = {
  GET_TIMELINE_REPORTS_REQUEST: "GET_TIMELINE_REPORTS_REQUEST",
  GET_TIMELINE_REPORTS_SUCCESS: "GET_TIMELINE_REPORTS_SUCCESS",
  GET_TIMELINE_REPORTS_FAILURE: "GET_TIMELINE_REPORTS_FAILURE",
  GET_TIMELINE_REPORTS_RESET: "GET_TIMELINE_REPORTS_RESET"
};
export const getEmailTemplatesConstants = {
  GET_EMAIL_TEMPLATES_REQUEST: "GET_EMAIL_TEMPLATES_REQUEST",
  GET_EMAIL_TEMPLATES_SUCCESS: "GET_EMAIL_TEMPLATES_SUCCESS",
  GET_EMAIL_TEMPLATES_FAILURE: "GET_EMAIL_TEMPLATES_FAILURE",
  GET_EMAIL_TEMPLATES_RESET: "GET_EMAIL_TEMPLATES_RESET"
};
export const updateEmailTemplatesConstants = {
  UPDATE_EMAIL_TEMPLATES_REQUEST: "UPDATE_EMAIL_TEMPLATES_REQUEST",
  UPDATE_EMAIL_TEMPLATES_SUCCESS: "UPDATE_EMAIL_TEMPLATES_SUCCESS",
  UPDATE_EMAIL_TEMPLATES_FAILURE: "UPDATE_EMAIL_TEMPLATES_FAILURE",
  UPDATE_EMAIL_TEMPLATES_RESET: "UPDATE_EMAIL_TEMPLATES_RESET"
};
export const getInterviewCalendarConstants = {
  GET_INTERVIEW_CALENDAR_REQUEST: "GET_INTERVIEW_CALENDAR_REQUEST",
  GET_INTERVIEW_CALENDAR_SUCCESS: "GET_INTERVIEW_CALENDAR_SUCCESS",
  GET_INTERVIEW_CALENDAR_FAILURE: "GET_INTERVIEW_CALENDAR_FAILURE",
  GET_INTERVIEW_CALENDAR_RESET: "GET_INTERVIEW_CALENDAR_RESET"
};
export const getUserStatsConstants = {
  GET_USER_STATS_REQUEST: "GET_USER_STATS_REQUEST",
  GET_USER_STATS_SUCCESS: "GET_USER_STATS_SUCCESS",
  GET_USER_STATS_FAILURE: "GET_USER_STATS_FAILURE",
  GET_USER_STATS_RESET: "GET_USER_STATS_RESET"
};
