import React from "react";
import { Layout, Table } from "antd";

const { Content } = Layout;

const Dpa = () => {
  const columns = [
    {
      title: "Subprocessor",
      dataIndex: "subprocessor",
      key: "subprocessor",
      render: (text) => <span>{text}</span>
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      render: (text) => <span>{text}</span>
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (text) => <span>{text}</span>
    }
  ];

  const data = [
    {
      key: "1",
      subprocessor: "Generic Cloud Service Provider Inc.",
      purpose:
        "Cloud and Data Infrastructure service provider to store customer and candidate data",
      country: "Generic Country"
    },
    {
      key: "2",
      subprocessor: "Generic CRM Tool",
      purpose:
        "Customer data for communication. Data is limited to name, email, address, and interactions.",
      country: "Generic Country"
    },
    {
      key: "3",
      subprocessor: "Generic Support Platform",
      purpose:
        "Customer support infrastructure. Personal data is only transmitted to a limited extent.",
      country: "Generic Country"
    },
    {
      key: "4",
      subprocessor: "Generic Analytics Tool",
      purpose:
        "Customer support infrastructure with limited personal data transmission.",
      country: "Generic Country"
    },
    {
      key: "5",
      subprocessor: "Generic Email Service",
      purpose: "Email address and name data only for communication purposes.",
      country: "Generic Country"
    },
    {
      key: "6",
      subprocessor: "Generic Video Service",
      purpose: "Data for video services with limited personal data.",
      country: "Generic Country"
    }
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "white" }}>
      <Content
        className="container mt-5"
        style={{
          fontFamily: "'Inter', sans-serif",
          fontWeight: 400,
          color: "#262626",
          paddingLeft: "10%",
          paddingRight: "10%"
        }}
      >
        <div className="text-start">
          <h2
            className="fw-bold mb-3"
            style={{
              fontSize: "2rem",
              color: "#262626",
              fontWeight: 700
            }}
          >
            Customer Data Processing Agreement
          </h2>
          <p style={{ fontSize: "1rem", color: "#888", fontWeight: 400 }}>
            Version v1 – March 2024
          </p>
        </div>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#666666",
            lineHeight: "1.8"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontSize: "20px",
              fontWeight: 600
            }}
          >
            Introduction
          </h4>
          <p className="mb-4">
            This DPA between Customer, and if applicable, Customer’s Affiliates,
            and the Service Provider contains the legal terms and conditions
            that apply to the processing of personal data of the Customer, by
            any of the Services.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            1. Scope
          </h4>
          <p className="mb-4">
            This DPA between Customer, and if applicable, Customer’s Affiliates,
            and the Service Provider contains the legal terms and conditions
            that apply to the processing of End User Data, which may include
            personal data, by any of the Services.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            2. Definitions
          </h4>
          <p className="mb-2">
            Insofar as not already defined in the Agreement, the following
            definitions apply throughout this DPA:
          </p>
          <p className="mb-2">
            <strong>“Agreement”</strong> means the Service Provider’s Terms of
            Service, the applicable service level agreement, other instructions
            and policies and applicable ordering documents, unless a separate
            agreement governing (the use of the) Services exists between the
            parties.
          </p>
          <p className="mb-2">
            <strong>“Data Protection Laws”</strong> means data protection laws
            applicable to the Service Provider in its processing of Customer
            Personal Data under this DPA, including, where applicable, the GDPR
            and other relevant laws.
          </p>
          <p className="mb-2">
            <strong>“DPA”</strong> means this Customer Data Processing
            Agreement.
          </p>
          <p className="mb-2">
            <strong>“Customer Personal Data”</strong> means Customer data that
            may be accessed or collected by the Services during the relationship
            governed by the Agreement, including confidential data and personal
            data.
          </p>
          <p className="mb-2">
            <strong>“GDPR”</strong> means the General Data Protection Regulation
            2016/679 of the European Parliament and of the Council on the
            protection of natural persons with regard to the processing of
            personal data and the free movement of such data.
          </p>
          <p className="mb-2">
            <strong>“Information Security Measures”</strong> the technical and
            organizational measures for ensuring the security of the processing,
            as described in the Service Provider's Security measures.
          </p>
          <p className="mb-2">
            <strong>“Security Incident”</strong> means any unauthorized access
            to Customer Personal Data stored on the Service Provider’s equipment
            or in the Service Provider’s facilities, or unauthorized access to
            such equipment or facilities resulting in loss, disclosure, or
            alteration of Customer Personal Data.
          </p>
          <p className="mb-4">
            Terms used in this DPA that are specifically defined in the GDPR
            shall have the same meaning as set forth in the GDPR. Terms used in
            this DPA that are not specifically defined in the GDPR shall have
            the same meaning as set forth in the Agreement.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            3. Responsibilities of processing personal data as a processor
          </h4>
          <p className="mb-4">
            <strong>3.1.</strong> To the extent the Service Provider processes
            personal data on behalf of Customer, it shall do so only on
            documented instructions from Customer pursuant to this DPA and the
            Agreement, to operate the Services, and as permitted or required by
            applicable law. The Service Provider shall immediately inform
            Customer if, in its opinion, an instruction infringes applicable
            Data Protection Laws.
          </p>
          <p className="mb-4">
            <strong>3.2.</strong> Insofar as the Service Provider processes
            personal data as defined by applicable Data Protection Laws, the
            following shall apply:
          </p>
          <div className="mb-4 py-2 px-4">
            <ol style={{ listStyleType: "disc" }}>
              <li>
                <strong>Processing Required by Law:</strong> In the event the
                Service Provider is required by applicable law to process
                Customer Personal Data, it will carry out such processing and
                notify Customer of such legal requirement, unless prohibited by
                law.
              </li>
              <li>
                <strong>
                  Compliance with Applicable Data Protection Laws:
                </strong>
                The Service Provider will process Customer Personal Data in
                accordance with applicable Data Protection Laws and will make
                available to Customers upon request the information necessary to
                demonstrate compliance.
              </li>
              <li>
                <strong>Data Subject Requests:</strong> The Service Provider
                shall provide reasonable assistance to Customer to comply with
                data subject rights under applicable Data Protection Laws.
              </li>
              <li>
                <strong>Data Protection Impact Assessments:</strong> The Service
                Provider shall provide reasonable assistance to Customer with
                any data protection impact assessments and prior consultations
                with regulatory authorities, if required by Data Protection
                Laws.
              </li>
              <li>
                <strong>Return or Destruction of Data:</strong> Upon termination
                of the Agreement, the Service Provider will, at Customer’s
                request, either return or destroy all Customer Personal Data,
                except as required by applicable law to retain such data.
              </li>
            </ol>
          </div>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            4. Sub-processors
          </h4>
          <p className="mb-4">
            <strong>4.1.</strong> The Service Provider may engage Sub-processors
            to perform specific processing activities on its behalf, provided
            that it enters into a written agreement with each Sub-processor
            which includes terms equivalent to those in this DPA.
          </p>
          <p className="mb-4">
            <strong>4.2.</strong> A list of Sub-processors used by the Service
            Provider can be found in the table below. This list includes details
            regarding the Sub-processors, the purposes for which they process
            data, and the countries where they operate.
          </p>

          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
          />
        </section>
      </Content>
    </Layout>
  );
};

export default Dpa;
