import React, { useState, useEffect } from "react";
import { Alert, Input, InputNumber, Button, Space, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const QuestionersInput = ({ form, data }) => {
  const [optionsQuestionare, setOptionsQuestionare] = useState([]);

  useEffect(() => {
    if (data?.questionnaireChoices) {
      setOptionsQuestionare(data.questionnaireChoices || []);
    }
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({ questionnaireChoices: optionsQuestionare });
  }, [optionsQuestionare, form]);

  const addOptionQuestionare = () => {
    setOptionsQuestionare((prevOptions) => [
      ...prevOptions,
      {
        id: Date.now(),
        value: "",
        score: 0
      }
    ]);
  };

  const removeOptionQuestionare = (id) => {
    if (optionsQuestionare.length <= 2) {
      message.error("At least two options are required.");
      return;
    }

    const newOptions = optionsQuestionare.filter(
      (option, index) => index !== id
    );
    setOptionsQuestionare(newOptions);
  };

  const handleInputChangeQuestionare = (id, field, value) => {
    const newOptions = optionsQuestionare.map((option, index) =>
      index === id ? { ...option, [field]: value } : option
    );

    setOptionsQuestionare(newOptions);
  };

  return (
    <>
      <Alert
        className="pb-4"
        message="Questionnaire Instructions"
        description={
          <>
            <p>
              Please fill out each option with the relevant information,
              ensuring the total score across all options sums to exactly 100%.
            </p>
            <ol>
              <li>Use the input field to specify the option text.</li>
              <li>
                Assign a score in percentage to each option using the score
                input field. Ensure the total score for all options equals
                exactly 100%.
              </li>
              <li>
                To add a new option, click the <strong>'Add option'</strong>{" "}
                button. Note that you can only add new options if the total
                score is less than 100%.
              </li>
              <li>
                To remove an option, click the <strong>minus icon</strong> next
                to it. At least two options must remain, and removing an option
                cannot cause the total score to drop below 100%.
              </li>
              <li>
                If the total score exceeds 100% or is less than 100%, an error
                message will be displayed. Please adjust the scores accordingly.
              </li>
            </ol>
          </>
        }
        type="info"
        showIcon
      />

      <div className="py-4">
        {optionsQuestionare.map((option, index) => (
          <Space key={index} className="questionnaire-space" align="baseline">
            <Input
              placeholder="Option text"
              value={option.value}
              onChange={(e) =>
                handleInputChangeQuestionare(index, "value", e.target.value)
              }
              size="large"
              className="input-style"
              style={{ width: "100%" }}
            />
            <InputNumber
              min={-10} // Adjusting min score to 0
              max={10} // Adjusting max score to 100
              placeholder="Score (percentage)"
              value={option.score}
              onChange={(value) =>
                handleInputChangeQuestionare(index, "score", value)
              }
              size="large"
              className="input-number-style"
              style={{ width: "50%" }}
            />
            {optionsQuestionare.length > 2 && (
              <MinusCircleOutlined
                onClick={() => removeOptionQuestionare(index)}
                style={{ fontSize: "24px" }}
              />
            )}
          </Space>
        ))}
        <Button
          type="dashed"
          onClick={addOptionQuestionare}
          className="button-style"
          icon={<PlusOutlined />}
          size="large"
        >
          Add option
        </Button>
      </div>
    </>
  );
};

export default QuestionersInput;
