import React, { useCallback } from "react";
import { Drawer, Avatar, Typography, Tag, Button } from "antd";
import {
  ClockCircleOutlined,
  UserOutlined,
  InboxOutlined
} from "@ant-design/icons";
import { useIsMobileView } from "../../Utils/CustomHooks/windowSize";

const TimeLineDetails = ({ visible, setVisible }) => {
  const timelineData = visible?.data?.timeline_data;
  const actorDetails = visible?.data?.actor;

  const onClose = useCallback(() => {
    setVisible((prev) => ({ ...prev, show: false, data: {} }));
  }, [setVisible]);

  return (
    <Drawer
      placement="right"
      width={useIsMobileView() ? "100%" : "65%"}
      open={visible?.show}
      onClose={onClose}
      title={
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Avatar
              src={
                actorDetails.photo
                  ? `${process.env.REACT_APP_IMAGES_BASE_URL}${actorDetails.photo}`
                  : undefined
              }
              icon={!actorDetails.photo && <UserOutlined />}
              alt={actorDetails.name || "User"}
              className="mr-2"
            />
            <span className="text-lg font-semibold text-[#00756a]">
              {actorDetails.name}
            </span>
          </div>
        </div>
      }
    >
      <>
        {timelineData && timelineData.length > 0 ? (
          timelineData.map((emailData, index) => (
            <div
              key={index}
              className="mb-4 p-4 border rounded-lg shadow-sm bg-white hover:shadow-md transition-shadow duration-200"
            >
              <h3 className="text-md font-bold flex justify-between items-center">
                {emailData?.subject}
                <Tag color="blue" className="ml-2">
                  New
                </Tag>
              </h3>
              <p className="text-sm">
                <strong>From:</strong> {emailData.from.name} (
                {emailData.from.email})
              </p>
              <p className="text-sm">
                <strong>To:</strong> {emailData.to}
              </p>
              <p className="text-sm flex items-center">
                <ClockCircleOutlined className="mr-1" />
                {visible?.data?.created_at}
              </p>
              <Button
                type="link"
                className="absolute top-4 right-4"
                icon={<InboxOutlined />}
              />
              <div className="mt-2">
                <strong>Email Preview:</strong>
                <iframe
                  className="border border-gray-300 w-full mt-2 rounded-lg"
                  style={{ height: "100vh" }}
                  srcDoc={emailData?.html}
                  title="Email Preview"
                />
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">No email data available</p>
        )}
      </>
    </Drawer>
  );
};

export default TimeLineDetails;
