import { call, delay, put } from "redux-saga/effects";
import {
  forgetPasswordLinkConstants,
  getuserDetailsConstants,
  getUserStatsConstants,
  loginUserConstants,
  logoutConstants,
  signupUserConstants,
  updatePasswordConstants,
  updateUserDetailsConstants
} from "../../constants";
import {
  forgetPasswordLinkCall,
  getUserDetailCall,
  getUserStatsCall,
  loginUserCall,
  logoutUserCall,
  singupUserCall,
  updatePasswordCall,
  updateUserDetailCall
} from "../../network";

export function* loginUserSaga(action) {
  try {
    const response = yield call(loginUserCall, action.data);

    yield put({
      type: loginUserConstants.LOGIN_USER_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
      message: response.message,
      headers: response.headers
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: loginUserConstants.LOGIN_USER_RESET });
  } catch (e) {
    yield put({
      type: loginUserConstants.LOGIN_USER_FAILURE,
      status: e.status,
      message: e.message,
      error: e.error,
      headers: e.response
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: loginUserConstants.LOGIN_USER_RESET });
  }
}
export function* singupUserSaga(action) {
  try {
    const response = yield call(singupUserCall, action.data);
    yield put({
      type: signupUserConstants.SIGNUP_USER_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
      message: response.message,
      headers: response.headers
    });
  } catch (e) {
    yield put({
      type: signupUserConstants.SIGNUP_USER_FAILURE,
      status: e.status,
      message: e.message,
      error: e.error,
      headers: e.response
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: signupUserConstants.SIGNUP_USER_RESET
    });
  }
}
export function* getUserDetailsSaga(action) {
  try {
    const response = yield call(getUserDetailCall, action.data);
    yield put({
      type: getuserDetailsConstants.GET_USER_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getuserDetailsConstants.GET_USER_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getuserDetailsConstants.GET_USER_DETAILS_RESET
    });
  }
}
export function* UpdateUserDetailsSaga(action) {
  try {
    const response = yield call(updateUserDetailCall, action.data);
    yield put({
      type: updateUserDetailsConstants.UPDATE_USER_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateUserDetailsConstants.UPDATE_USER_DETAILS_RESET });
  } catch (e) {
    yield put({
      type: updateUserDetailsConstants.UPDATE_USER_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateUserDetailsConstants.UPDATE_USER_DETAILS_RESET });
  }
}
export function* forgetPasswordLinkSaga(action) {
  try {
    const response = yield call(forgetPasswordLinkCall, action.data);
    yield put({
      type: forgetPasswordLinkConstants.FORGET_PASSWORD_LINK_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: forgetPasswordLinkConstants.FORGET_PASSWORD_LINK_RESET });
  } catch (e) {
    yield put({
      type: forgetPasswordLinkConstants.FORGET_PASSWORD_LINK_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: forgetPasswordLinkConstants.FORGET_PASSWORD_LINK_RESET });
  }
}
export function* updatePasswordSaga(action) {
  try {
    const response = yield call(updatePasswordCall, action.data);
    yield put({
      type: updatePasswordConstants.UPDATE_PASSWORD_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updatePasswordConstants.UPDATE_PASSWORD_RESET });
  } catch (e) {
    yield put({
      type: updatePasswordConstants.UPDATE_PASSWORD_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updatePasswordConstants.UPDATE_PASSWORD_RESET });
  }
}
export function* logoutUserSaga(action) {
  try {
    const response = yield call(logoutUserCall, action.data);
    yield put({
      type: logoutConstants.LOGOUT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: logoutConstants.LOGOUT_RESET });
  } catch (e) {
    yield put({
      type: logoutConstants.LOGOUT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: logoutConstants.LOGOUT_RESET });
  }
}
export function* getUserStatsSaga(action) {
  try {
    const response = yield call(getUserStatsCall, action.data);
    yield put({
      type: getUserStatsConstants.GET_USER_STATS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getUserStatsConstants.GET_USER_STATS_RESET });
  } catch (e) {
    yield put({
      type: getUserStatsConstants.GET_USER_STATS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getUserStatsConstants.GET_USER_STATS_RESET });
  }
}
