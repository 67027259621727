import React, { useEffect, useState } from "react";
import "../../../Assets/css/candidate.profile.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { candidateProfileAction } from "../../../redux/actions/common";
import { CandidateProfile } from "./CandidateProfile";
import { message } from "antd";

const CandidateMainProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState(null);
  const [loading, setLoading] = useState(true);
  const applyJobReducer = useSelector((state) => state.applyJobReducer);
  const candidateProfileReducer = useSelector(
    (state) => state?.candidateProfileReducer
  );

  useEffect(() => {
    dispatch(candidateProfileAction(id));
  }, [dispatch]);

  useEffect(() => {
    const { data, error, loading, status } = candidateProfileReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setdata(data);
    }
    if (!data && !loading && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [candidateProfileReducer, applyJobReducer]);

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
       <section className="px-8">

       <CandidateProfile candidate_data={data} />
       </section>
      )}
    </>
  );
};

export default CandidateMainProfile;
