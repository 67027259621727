import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Button,
  Space,
  Table,
  message,
  Select,
  Layout,
  Form,
  Collapse,
  Pagination,
  Modal,
  Input,
  Menu,
  Dropdown,
  Avatar,
  Tooltip,
  Drawer,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "antd";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { updateUserDetailsAction } from "../../redux/actions/user";
import { getAllRoleUserAction } from "../../redux/actions/Roles&Management";
import { All_ROLES } from "../../Assets/data/common";
import {
  ReloadOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  CopyOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import ClientCustomSelect from "../../Utils/ClientSelect/clientSelect";
import { REACT_USER_TYPE } from "../Auth/tokenProvider";
import { UserOutlined } from "@ant-design/icons";
import { isMentorpalTeamUser } from "../../Utils/Common";
import ("../../Assets/css/filter.css");

const { Option } = Select;
const { Sider, Content } = Layout;
const { Panel } = Collapse;
const { confirm } = Modal;

const FilterForm = ({ filters, handleFilterChange, handleReset }) => (
  <Form
    layout="vertical"
    onValuesChange={(changedValues) => {
      Object.keys(changedValues).forEach((key) =>
        handleFilterChange(key, changedValues[key])
      );
    }}
    initialValues={filters}
    className="pt-3"
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className="d-flex align-items-center">
        <h2 className="m-0 ml-2 ">Filters</h2>
      </span>
      <Button type="" className="cursor-pointer " onClick={handleReset}>
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3"]}
      expandIconPosition="right"
      style={{ background: "#fff", border: "none" }}
    >
      {isMentorpalTeamUser() && (
        <Panel header="Client" key="1"  style={{ background: "#ffff", border: "none" }}>
          <ClientCustomSelect
            type={"filter"}
            value={filters.client_id}
            onChange={(value) => handleFilterChange("client_id", value)}
          />
        </Panel>
      )}

      <Panel header="User Type" key="2"  style={{ background: "#ffff", border: "none" }}>
        <Select
          value={filters?.user_type}
          onChange={(value) => handleFilterChange("user_type", value)}
          showSearch
          placeholder="Select a user type"
          style={{ width: "100%" }}
          size="large"
        >
          <Select.Option value="">All</Select.Option>
          {All_ROLES?.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Panel>

      <Panel header="User Status" key="3"  style={{ background: "#ffff", border: "none" }}>
        <Select
          value={filters?.status}
          onChange={(value) => handleFilterChange("status", value)}
          showSearch
          placeholder="Select a user status"
          style={{ width: "100%" }}
          size="large"
        >
          <Select.Option value="">All</Select.Option>
          <Select.Option value="signup">Signup</Select.Option>
          <Select.Option value="registered">Registered</Select.Option>
          <Select.Option value="hold">Hold</Select.Option>
          <Select.Option value="suspended">Suspended</Select.Option>
          <Select.Option value="deleted">Deleted</Select.Option>
        </Select>
      </Panel>
    </Collapse>
  </Form>
);

const MasterAdminUserManagementMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filters, setFilters] = useState({
    status: "",
    user_type: "",
    client_id: "",
    first_name: "",
  });

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const debouncedHandleSearch = useMemo(
    () =>
      debounce(
        (value) => setFilters((prev) => ({ ...prev, status: value })),
        300
      ),
    []
  );
  const updateUserDetailReducer = useSelector(
    (state) => state.updateUserDetailReducer
  );
  useEffect(() => {
    const { loading, data, error } = updateUserDetailReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        if (data?.updatedUser?.status === "delete") {
          message.success("user deleted!");
        }
      } else if (error) {
        console.warn("create user error:", error);
        message.error(error);
      }
    }
  }, [updateUserDetailReducer]);

  useEffect(() => {
    dispatch(
      getAllRoleUserAction({
        ...filters,
        page: currentPage,
        limit: pageSize,
      })
    );
    return () => {
      dispatch({ type: "GET_USER_RESET" });
    };
  }, [dispatch, filters, currentPage, pageSize, updateUserDetailReducer]);

  const handleRefresh = () => {
    dispatch(
      getAllRoleUserAction({
        ...filters,
        page: currentPage,
        limit: pageSize,
      })
    );
  };

  const { data: userData, loading: userLoading } = useSelector(
    (state) => state.getAllRoleUserReducer
  );

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    setCurrentPage(1);
  };

  useEffect(() => {
    if (userData) {
      setTotalUsers(userData.totalUsers || 0);
    }
  }, [userData]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleMenuClick = (record) => async (e) => {
    const user_id = record?.user_id;

    const showDeleteConfirm = () => {
      confirm({
        title: "Are you sure you want to delete this user?",
        icon: <ExclamationCircleOutlined />,
        content: "This action cannot be undone.",
        okText: "Yes, Delete",
        okType: "danger",
        cancelText: "No, Cancel",
        onOk() {
          handleDelete(user_id);
        },
      });
    };

    const showUpdateConfirm = () => {
      navigate(`/dashboard/update-user?user_id=${user_id}`);
    };

    const handleDelete = async (user_id) => {
      try {
        await dispatch(updateUserDetailsAction({ user_id, status: "delete" }));
        message.success("User deleted successfully.");
      } catch (error) {
        message.error("Something went wrong while deleting the user.");
      }
    };

    switch (e.key) {
      case "update":
        showUpdateConfirm();
        break;
      case "remove":
        showDeleteConfirm();
        break;
      default:
        message.error("Action not found.");
    }
  };

  const actionMenu = (record) => (
    <Menu onClick={handleMenuClick(record)} className="actions-menu">
      <Menu.Item key="update">Update Details</Menu.Item>
      <Menu.Item key="remove">Remove User</Menu.Item>
    </Menu>
  );

  const handleReset = useCallback(() => {
    setFilters({
      status: "",
      user_type: "",
      client_id: "",
      first_name: "",
    });
    dispatch(getAllRoleUserAction({ page: 1, pageSize: 10 }));
  }, [dispatch]);

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      fixed: !isMobile ? "left" : undefined,
      width: 70,
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      fixed: !isMobile ? "left" : undefined,
      render: (text, record) => (
        <span className="d-flex">
          <div>
            <Avatar
              src={
                record?.photo
                  ? `${process.env.REACT_APP_IMAGES_BASE_URL}${record?.photo}`
                  : undefined
              }
              icon={!record?.photo ? <UserOutlined /> : null}
              alt={text || "User"}
              style={{ marginRight: 8 }}
            />
          </div>
          <div>
            <a>{`${record?.firstName} ${record?.lastName}`}</a>
          </div>
        </span>
      ),

      width: 250,
      sorter: (a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Name`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const fullName = `${record.firstName} ${record.lastName}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 300,
      ellipsis: true,
      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Email`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.email.toLowerCase().includes(value.toLowerCase()),
      render: (email) => {
        const handleCopy = () => {
          navigator.clipboard.writeText(email);
          message.success("Email copied to clipboard!");
        };

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{email}</span>
            <Tooltip title="Copy Email">
              <CopyOutlined
                onClick={handleCopy}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "user_type",
      key: "user_type",
      render: (user_type) => {
        const role = All_ROLES.find((option) => option.value === user_type);
        return role ? role.label : user_type;
      },
      width: 200,
      filters: All_ROLES.map((role) => ({
        text: role.label,
        value: role.value,
      })),
      onFilter: (value, record) => record.user_type === value,
    },
    {
      title: "Reports To",
      dataIndex: "report_to",
      key: "report_to",
      render: (text, record) =>
        text ? (
          <span className="d-flex">
            <div>
              <Avatar
                src={
                  text?.photo
                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${text.photo}`
                    : undefined
                }
                icon={!text?.photo ? <UserOutlined /> : null}
                alt={text?.first_name || "User"}
                style={{ marginRight: 8 }}
              />
            </div>
            <div>
              <a>{`${text?.first_name} ${text?.last_name}`}</a>
            </div>
          </span>
        ) : (
          <span>-</span>
        ),
      width: 200,
    },
    {
      title: "Client Name",
      dataIndex: "client",
      key: "client",
      render: (text, record) => (
        <span className="d-flex">
          <div>
            <Avatar
              src={
                text?.company_photo
                  ? `${process.env.REACT_APP_IMAGES_BASE_URL}${text?.company_photo}`
                  : undefined
              }
              icon={!text?.company_photo ? <UserOutlined /> : null}
              alt={text || "User"}
              style={{ marginRight: 8 }}
            />
          </div>
          <div>
            <a
              className="text-decoration-underline cursor-pointer"
              style={{ cursor: "pointer", color: "#005c53" }}
              onClick={() =>
                navigate(
                  `/dashboard/client-dashboard/details?client_id=${text?.client_id}`
                )
              }
            >
              {text?.company_name || "N/A"}
            </a>
          </div>
        </span>
      ),
      width: 200,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (createdAt) => {
        const date = new Date(createdAt);
        return date
          .toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(",", "");
      },
      width: 200,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="large">
          <Dropdown overlay={actionMenu(record)}>
            <Button
              onClick={(e) => e.preventDefault()}
              shape="circle"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Space>
      ),
      width: 90,
      fixed: !isMobile ? "right" : undefined,
    },
  ];

  const users = userData?.data?.map((item) => ({
    user_id: item.user_id,
    firstName: item.first_name,
    photo: item.photo,
    lastName: item.last_name,
    email: item.email,
    user_type: item.user_type,
    client_id: item.client?.client_id,
    client: item.client,
    createdAt: item.createdAt,
    report_to: item.report_to,
  }));
  const user_type = useMemo(() => REACT_USER_TYPE(), []);

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <div className="main-header" data-ui="header">
            <div className="px-4">
              <div className="flag">
                <div className="flag__section">
                  <a className="external incognito-link inline-block mb-m mt-m">
                    <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                      Dashboard
                    </h1>
                  </a>
                </div>
                <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
                  <Button
                    type="button"
                    className="btn btn--primary mt-m text-white pull-right main_border_radius"
                    onClick={() => navigate("/dashboard/create-user")}
                  >
                    Create User
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Layout style={{ background: "#fff", height: "100vh" }}>
            {!isMobile && (
              <Sider width={300} className="site-layout-background bg-light">
                <div className="px-3 bg-light" style={{ height: "100vh" }}>
                  <FilterForm
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    debouncedHandleSearch={debouncedHandleSearch}
                    handleReset={handleReset}
                  />
                </div>
              </Sider>
            )}
            <Layout>
              <Button
                className="filter-toggle-button"
                onClick={toggleFilters}
                type="primary"
                style={{ backgroundColor: "#00756a" }}
              >
                Apply Filters
              </Button>
              {userLoading ? (
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                  }}
                >
                  <TailSpin ariaLabel="Loading..." color="#005c53" />
                </div>
              ) : (
                <Content className="px-4">
                  <div className="cl-section-header row justify-content-between align-items-end py-3">
                    <div className="col d-flex align-items-center"></div>
                    <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
                      <Button
                        className="ms-2"
                        onClick={handleRefresh} 
                        icon={<ReloadOutlined />}
                        type="primary"
                        style={{ backgroundColor: "#00756a" }}
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>
                  <Table
                    columns={columns}
                    dataSource={users}
                    pagination={false}
                    rowKey="user_id"
                    scroll={{
                      x: 1300,
                    }}
                    bordered
                    footer={() => (
                      <div style={{ textAlign: "center" }}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/dashboard/create-user`);
                          }}
                          style={{
                            color: "#00756a",
                            textDecoration: "underline",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Create User
                        </a>
                      </div>
                    )}
                  />
                  <Pagination
                    className="text-center align-content-center p-3"
                    total={totalUsers}
                    current={currentPage}
                    pageSize={pageSize}
                    showSizeChanger
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    showTotal={(total) => `Total ${total} users`}
                    onChange={handlePageChange}
                    scroll={{
                      x: 1300, 
                    }}
                  />
                </Content>
              )}
            </Layout>
          </Layout>
          <Drawer
            visible={isMobileFilterDrawerVisible}
            onClose={closeDrawer}
            placement="left"
            width="100%"
            className="filter-drawer "
            bodyStyle={{ paddingTop: 0 }} 
          >
            <FilterForm
              filters={filters}
              handleFilterChange={handleFilterChange}
              debouncedHandleSearch={debouncedHandleSearch}
              handleReset={handleReset}
            />
          </Drawer>
        </>
      )}
      </>
  );
};

export default MasterAdminUserManagementMain;
