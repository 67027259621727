import {
  getClientReportConstants,
  getTeamReportConstants,
  getTimeLineReportsConstants
} from "../../constants";

export const getClientReportReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getClientReportConstants.GET_CLIENT_REPORT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getClientReportConstants.GET_CLIENT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getClientReportConstants.GET_CLIENT_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getClientReportConstants.GET_CLIENT_REPORT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getTeamReportReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getTeamReportConstants.GET_TEAM_REPORT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getTeamReportConstants.GET_TEAM_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getTeamReportConstants.GET_TEAM_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getTeamReportConstants.GET_TEAM_REPORT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getTimeLineReportsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getTimeLineReportsConstants.GET_TIMELINE_REPORTS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getTimeLineReportsConstants.GET_TIMELINE_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getTimeLineReportsConstants.GET_TIMELINE_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getTimeLineReportsConstants.GET_TIMELINE_REPORTS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
