import {
  assessmentImageProctorConstants,
  assessmentLoginConstants,
  candidateAssessmentFeedbackConstants,
  candidateAssessmentSubmissionConstants,
  createAssessmentConstants,
  createQuestionConstants,
  createTestConstants,
  getAllAssessmentCandidateConstants,
  getAllAssessmentListConstants,
  getAllQuestionListConstants,
  getAllTestListConstants,
  getAssessmentCandidateDetailsConstants,
  getAssessmentDetailsConstants,
  getInvitationDetailsConstants,
  getInviteAssessmentDetailsConstants,
  getQuestionDetailsConstants,
  getTestDetailsConstants,
  inviteAssessmentConstants,
  updateAssessmentConstants,
  updateInviteAssessmentDetailsConstants,
  updateQuestionConstants,
  updateTestConstants
} from "../../constants";

export function createQuestionAction(data) {
  return { type: createQuestionConstants.CREATE_QUESTION_REQUEST, data };
}

export function updateQuestionAction(data) {
  return { type: updateQuestionConstants.UPDATE_QUESTION_REQUEST, data };
}

export function getQuestionDetailAction(data) {
  return { type: getQuestionDetailsConstants.GET_QUESTION_REQUEST, data };
}

export function getAllQuestionListAction(data) {
  return { type: getAllQuestionListConstants.GET_LIST_QUESTION_REQUEST, data };
}

export function createTestAction(data) {
  return { type: createTestConstants.CREATE_TEST_REQUEST, data };
}

export function updateTestAction(data) {
  return { type: updateTestConstants.UPDATE_TEST_REQUEST, data };
}

export function getTestDetailAction(data) {
  return { type: getTestDetailsConstants.GET_TEST_REQUEST, data };
}

export function getAllTestListAction(data) {
  return { type: getAllTestListConstants.GET_LIST_TEST_REQUEST, data };
}
export function createAssessmentAction(data) {
  return { type: createAssessmentConstants.CREATE_ASSESSMENT_REQUEST, data };
}

export function updateAssessmentAction(data) {
  return { type: updateAssessmentConstants.UPDATE_ASSESSMENT_REQUEST, data };
}

export function getAssessmentDetailaction(data) {
  return { type: getAssessmentDetailsConstants.GET_ASSESSMENT_REQUEST, data };
}

export function getAllAssessmentListAction(data) {
  return {
    type: getAllAssessmentListConstants.GET_LIST_ASSESSMENT_REQUEST,
    data
  };
}
export function getInviteAssessmentDetailAction(data) {
  return {
    type: getInviteAssessmentDetailsConstants.GET_INVITE_ASSESSMENT_DETAILS_REQUEST,
    data
  };
}
export function updateInviteAssessmentDetailAction(data) {
  return {
    type: updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_REQUEST,
    data
  };
}
export function assessmentLoginAction(data) {
  return { type: assessmentLoginConstants.ASSESSMENT_LOGIN_REQUEST, data };
}
export function getAllAssessmentCandidateAction(data) {
  return {
    type: getAllAssessmentCandidateConstants.GET_ALL_ASSESSMENT_CANDIDATE_REQUEST,
    data
  };
}
export function getAssessmentCandidateDetailsAction(data) {
  return {
    type: getAssessmentCandidateDetailsConstants.GET_ASSESSMENT_CANDIDATE_DETAILS_REQUEST,
    data
  };
}
export function inviteAssessmentAction(data) {
  return {
    type: inviteAssessmentConstants.INVITE_ASSESSMENT_REQUEST,
    data
  };
}

export function candidateAssessmentSubmissionAction(data) {
  return {
    type: candidateAssessmentSubmissionConstants.CANDIDATE_ASSESSMENT_SUBMISSION_REQUEST,
    data
  };
}
export function candidateAssessmentFeedbackAction(data) {
  return {
    type: candidateAssessmentFeedbackConstants.CANDIDATE_ASSESSMENT_FEEDBACK_REQUEST,
    data
  };
}
export function assessmentImageProctorAction(data) {
  return {
    type: assessmentImageProctorConstants.ASSESSMENT_PROCTOR_REQUEST,
    data
  };
}


export function getInvitationDetailAction(data) {
  return {
    type: getInvitationDetailsConstants.GET_INVITE_DETAILS_REQUEST,
    data
  };
}