import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { Table, Button, message, Select, Modal, Input } from "antd";
import { convertUTCtoLocal } from "../../Utils/getDuration";
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import {
  getFeedbackListAction,
  updateFeedbackAction
} from "../../redux/actions/common";

const { Option } = Select;
const { confirm } = Modal;

const FeedbackListing = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const getFeedbackListReducer = useSelector(
    (state) => state.getFeedbackListReducer
  );
  const updateFeedbackReducer = useSelector(
    (state) => state.updateFeedbackReducer
  );

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
    return () => {
      dispatch({ type: "GET_FEEDBACK_LIST_RESET" });
    };
  }, [pagination.current, pagination.pageSize, updateFeedbackReducer]);

  const fetchData = (page, limit) => {
    setLoading(true);
    dispatch(getFeedbackListAction({ page, limit }));
  };

  const handleRefresh = () => {
    fetchData(pagination.current, pagination.pageSize);
  };

  useEffect(() => {
    const { loading, error, data } = updateFeedbackReducer;
    setLoading(loading);

    if (!loading && data && !error) {
      message.success("status updated!");
      fetchData(pagination.current, pagination.pageSize);
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [updateFeedbackReducer]);

  useEffect(() => {
    const { loading, error, data, total } = getFeedbackListReducer;
    setLoading(loading);

    if (!loading && data && !error) {
      const enrichedData = data?.data?.map((item, index) => ({
        ...item,
        sno: index + 1 + (pagination.current - 1) * pagination.pageSize,
        createdAt: convertUTCtoLocal(item?.createdAt)
      }));
      setData(enrichedData);
      setPagination((prev) => ({
        ...prev,
        total: total || enrichedData.length
      }));
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getFeedbackListReducer]);

  const showConfirmationModal = (feedbackId, status) => {
    confirm({
      title: "Are you sure you want to update the status?",
      content: `This action will change the status to "${status}".`,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        dispatch(
          updateFeedbackAction({
            feedback_id: feedbackId,
            status: status
          })
        );
      },
      onCancel() {
        console.log("Update canceled");
      }
    });
  };

  const handleUpdateStatus = (feedbackId, status) => {
    showConfirmationModal(feedbackId, status);
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize
    });
  };
  
  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: 100,
      fixed: !isMobile ? "left" : undefined,
      sorter: (a, b) => a.sno - b.sno
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: 150,
      fixed: !isMobile ? "left" : undefined,
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button
            onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
            size="small"
            style={{ backgroundColor: "#28a745", color: "white" }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toLowerCase())
    },

    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: 300,
      sorter: (a, b) => a.message.localeCompare(b.message)
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Email"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button
            onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
            size="small"
            style={{ backgroundColor: "#28a745", color: "white" }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.email.toLowerCase().includes(value.toLowerCase())
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      fixed: !isMobile ? "right" : undefined,
      render: (text, record) => (
        <Select
          value={record.status}
          onChange={(value) => handleUpdateStatus(record.feedback_id, value)}
          style={{ width: 120 }}
        >
          <Option value="pending">Pending</Option>
          <Option value="completed">Completed</Option>
        </Select>
      )
    }
  ];

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <div className="px-3">
          <div className="cl-section-header row justify-content-between align-items-end py-3">
            <div className="col d-flex align-items-center"></div>
            <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
              <Button
                className="ms-2"
                onClick={() => handleRefresh()}
                icon={<ReloadOutlined />}
                type="primary"
                style={{ backgroundColor: "#00756a" }}
              >
                Refresh
              </Button>
            </div>
          </div>
          <Table
          bordered
            columns={columns}
            dataSource={data}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50"]
            }}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 1300, y: 500 }}
          />
        </div>
      )}
    </>
  );
};

export default FeedbackListing;
