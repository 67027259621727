import React, { useState } from "react";
import { Badge, Tag, Avatar, Tooltip, Button } from "antd";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import AssignModal from "./AssigningModal"; // Adjust the path to your AssignModal

// AssignBadge component
const AssignBadge = ({ data, client_id }) => {
  const [openAssignModal, setOpenAssignModal] = useState({
    show: false,
    data: {}
  });

  const isValidArray =
    Array.isArray(data?.userIdsDetails) && data?.userIdsDetails.length > 0;

  const handleEditClick = () => {
    setOpenAssignModal({
      show: true,
      data: data,
      client_id: client_id
    });
  };

  return (
    <>
      <div style={{ position: "relative", display: "inline-block" }}>
        <Badge
          count={
            <Button
              icon={<EditOutlined />}
              shape="circle"
              size="small"
              style={{
                position: "absolute",
                bottom: 0, // Adjust as needed
                right: "5px", // Adjust as needed
                top: "39px", // Adjust as needed
                zIndex: 1,
                border: "none",
                background: "transparent",
                color: "#000"
              }}
              onClick={handleEditClick}
            />
          }
        >
          {isValidArray ? (
            <Tag
              className="ml-auto"
              color="yellow"
              style={{ color: "#000", borderRadius: "8px" }}
            >
              <Avatar.Group
                // size="large"
                max={{
                  count: 3,
                  style: {
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    cursor: "pointer"
                  },
                  popover: {
                    trigger: "click"
                  }
                }}
              >
                {data.userIdsDetails.map((item) => (
                  <Tooltip
                    title={item.name || "User"}
                    placement="top"
                    key={item.user_id}
                  >
                    <Avatar
                      src={
                        item.photo
                          ? `${process.env.REACT_APP_IMAGES_BASE_URL}${item.photo}`
                          : undefined
                      }
                      icon={!item.photo ? <UserOutlined /> : null}
                      style={{ marginRight: 8 }}
                    />
                  </Tooltip>
                ))}
              </Avatar.Group>
            </Tag>
          ) : (
            <Tag
              className="ml-auto"
              color="yellow"
              style={{ color: "#000", borderRadius: "8px" }}
            >
              Unassigned
            </Tag>
          )}
        </Badge>

        {openAssignModal.show && (
          <AssignModal
            setVisible={setOpenAssignModal}
            visible={openAssignModal}
          />
        )}
      </div>
    </>
  );
};

export default AssignBadge;
