import React, { useEffect, useState } from "react";
import "../../Assets/css/jobApply.main.css";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  message,
  Checkbox,
  DatePicker,
  Modal
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { applyJobAction } from "../../redux/actions/common";
import ApplyByResumeOnly from "./ApplyByResumeOnly";
import ImageUploadComponent from "../Upload";

export const ApplyJobForm = ({ data }) => {
  const { job_id, user_id } = data?.job;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [educationFormVisible, setEducationFormVisible] = useState(false);
  const [experienceFormVisible, setExperienceFormVisible] = useState(false);
  const [educationData, setEducationData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [uploadFileData, setuploadFileData] = useState({
    resume: "",
    photo: ""
  });

  const onFinish = (values) => {
    const data = {
      ...values,
      ...uploadFileData,
      job_id: job_id,
      user_id: user_id,
      education: educationData,
      experience: experienceData,
      stage: "applied"
    };
    dispatch(applyJobAction(data));
  };
  const applyJobReducer = useSelector((state) => state.applyJobReducer);

  useEffect(() => {
    const { data, loading, error, status } = applyJobReducer;

    setloading(loading);

    if (!loading && data && !error) {
      message.success("Your application has been submitted!");
      form.resetFields();
      setEducationData([]);
      setExperienceData([]);
      setuploadFileData({
        resume: "",
        photo: ""
      });
    }

    if (!loading && !data && error && (status === 409 || status === 400)) {
      message.error(error);
    }
  }, [applyJobReducer]);

  const handleEducationSave = (values) => {
    setEducationData((prev) => [...prev, values]);
    setEducationFormVisible(false);
  };

  const handleExperienceSave = (values) => {
    setExperienceData((prev) => [...prev, values]);
    setExperienceFormVisible(false);
  };

  const handleDelete = (id, type) => {
    if (type === "education") {
      setEducationData((prev) => prev.filter((_, index) => index !== id));
    } else if (type === "experience") {
      setExperienceData((prev) => prev.filter((_, index) => index !== id));
    }
  };
  return (
    <>
      <ApplyByResumeOnly data={data} />
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onFinishFailed={(errorInfo) => console.log("Failed:", errorInfo)}
      >
        <Divider>OR</Divider>

        <Divider>Personal Information</Divider>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                { required: true, message: "Please enter your first name" }
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                { required: true, message: "Please enter your last name" }
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" }
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="jobRole" label="Job Role(Optional)">
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ required: true, message: "Please enter your Phone" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Please enter your Address" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="photo"
              label="Photo"
              rules={[
                {
                  required: uploadFileData?.photo ? false : true,
                  message: "Please upload your photo"
                }
              ]}
            >
              <ImageUploadComponent
               
                uploadPath="hire_talent/profile/"
                acceptedFileTypes="image/*"
                uploadType="Photo"
                onFileUpload={(profileImageUrl) =>
                  setuploadFileData((prevState) => ({
                    ...prevState,
                    photo: profileImageUrl
                  }))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Resume</Divider>
        <Form.Item
          name="resume"
          label="Resume"
          rules={[
            {
              required: uploadFileData?.resume ? false : true,
              message: "Please enter your Resume"
            }
          ]}
        >
          <ImageUploadComponent
            
            uploadPath="hire_talent/resume/"
            acceptedFileTypes="application/pdf, .pdf"
            uploadType="PDF Resume"
            onFileUpload={(resumeURL) =>
              setuploadFileData((prevState) => ({
                ...prevState,
                resume: resumeURL
              }))
            }
          />
        </Form.Item>
        <Divider>Education & Experience</Divider>

        <EducationMap
          data={educationData}
          onAdd={() => setEducationFormVisible(true)}
          onDelete={handleDelete}
          onEdit={() => setEducationFormVisible(true)}
        />
        <ExperienceMap
          data={experienceData}
          onAdd={() => setExperienceFormVisible(true)}
          onDelete={handleDelete}
          onEdit={() => setExperienceFormVisible(true)}
        />

        <Divider>Summary</Divider>
        <Form.Item name="summary" label="Summary (Optional)">
          <Input.TextArea rows={5} />
        </Form.Item>

        <Divider>Cover Letter</Divider>
        <Form.Item name="coverLetter" label="Cover Letter(Optional)">
          <Input.TextArea rows={5} />
        </Form.Item>

        {/* Buttons */}
        <Form.Item>
          <Button
            type="primary"
            className="button--2de5X button--14TuV button--FDW79 primary--25RCR main_border_radius"
            style={{ marginRight: 8, backgroundColor: "#005c53" }}
            htmlType="submit"
            loading={loading}
          >
            Apply
          </Button>
        </Form.Item>
      </Form>

      <EducationModal
        visible={educationFormVisible}
        onCancel={() => setEducationFormVisible(false)}
        onSave={handleEducationSave}
      />
      <ExperienceModal
        visible={experienceFormVisible}
        onCancel={() => setExperienceFormVisible(false)}
        onSave={handleExperienceSave}
      />
    </>
  );
};

const EducationModal = ({ visible, onCancel, onSave }) => (
  <Modal
    title="Add Education"
    visible={visible}
    onCancel={onCancel}
    footer={null}
    centered
  >
    <EducationForm onSave={onSave} onCancel={onCancel} />
  </Modal>
);

const ExperienceModal = ({ visible, onCancel, onSave }) => (
  <Modal
    title="Add Experience"
    visible={visible}
    onCancel={onCancel}
    footer={null}
    centered
  >
    <ExperienceForm onSave={onSave} onCancel={onCancel} />
  </Modal>
);

const EducationMap = ({ data, onAdd, onDelete, onEdit }) => (
  <div data-ui="education">
    <div
      className="styles--36XiB"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <p style={{ fontSize: "18px", fontWeight: "bold" }}>Education</p>
      <Button
        type="button"
        onClick={onAdd}
        className="button--2de5X"
        style={{ backgroundColor: "#005c53", color: "#fff" }}
      >
        + Add
      </Button>
    </div>
    {data.length > 0 ? (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {data.map((item, index) => (
          <li
            key={index}
            style={{ borderBottom: "1px solid #d9d9d9", padding: "10px 0" }}
          >
            <div
              data-ui="group"
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <dl>
                <dt style={{ fontWeight: "bold" }}>School:</dt>
                <dd>{item.school}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Field of Study:</dt>
                <dd>{item.fieldOfStudy}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Degree:</dt>
                <dd>{item.degree}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Start Date:</dt>
                <dd>
                  {item.startDate ? item.startDate.format("YYYY-MM-DD") : "N/A"}
                </dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>End Date:</dt>
                <dd>
                  {item.endDate ? item.endDate.format("YYYY-MM-DD") : "N/A"}
                </dd>
              </dl>

              <div
                className="styles--3RCU1"
                style={{ display: "flex", gap: "10px" }}
              >
                <Button
                  type="link"
                  onClick={() => onEdit(index)}
                  icon={<EditOutlined />}
                  style={{ color: "#005c53" }}
                />
                <Button
                  type="link"
                  onClick={() => onDelete(index, "education")}
                  icon={<DeleteOutlined />}
                  style={{ color: "#d9534f" }}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          border: "2px dashed #d9d9d9",
          borderRadius: "5px"
        }}
      >
        No education data available. Please add your education details.
      </div>
    )}
  </div>
);

const ExperienceMap = ({ data, onAdd, onDelete, onEdit }) => (
  <div data-ui="experience">
    <div
      className="styles--36XiB"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <p style={{ fontSize: "18px", fontWeight: "bold" }}>Experience</p>
      <Button
        type="button"
        onClick={onAdd}
        className="button--2de5X"
        style={{ backgroundColor: "#005c53", color: "#fff" }}
      >
        + Add
      </Button>
    </div>
    {data.length > 0 ? (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {data.map((item, index) => (
          <li
            key={index}
            style={{ borderBottom: "1px solid #d9d9d9", padding: "10px 0" }}
          >
            <div
              data-ui="group"
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <dl>
                <dt style={{ fontWeight: "bold" }}>Title:</dt>
                <dd>{item.title}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Company:</dt>
                <dd>{item.company}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Summary:</dt>
                <dd>{item.summary}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Start Date:</dt>
                <dd>
                  {item.startDate ? item.startDate.format("YYYY-MM-DD") : "N/A"}
                </dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>End Date:</dt>
                <dd>
                  {item.EndDate
                    ? item.endDate.format("YYYY-MM-DD")
                    : "Currently Working Here!"}
                </dd>
              </dl>

              <div
                className="styles--3RCU1"
                style={{ display: "flex", gap: "10px" }}
              >
                <Button
                  type="link"
                  onClick={() => onEdit(index)}
                  icon={<EditOutlined />}
                  style={{ color: "#005c53" }}
                />
                <Button
                  type="link"
                  onClick={() => onDelete(index, "experience")}
                  icon={<DeleteOutlined />}
                  style={{ color: "#d9534f" }}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          border: "2px dashed #d9d9d9",
          borderRadius: "5px"
        }}
      >
        No experience data available. Please add your experience details.
      </div>
    )}
  </div>
);
const EducationForm = ({ onSave, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSave(values);
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        name="school"
        label="School"
        rules={[{ required: true, message: "Please enter your school name" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="fieldOfStudy"
        label="Field of Study"
        rules={[
          { required: true, message: "Please enter your field of study" }
        ]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="degree"
        label="Degree"
        rules={[{ required: true, message: "Please enter your degree" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item label="Date Range">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[
                { required: true, message: "Please select the start date" }
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                disabledDate={(current) => current && current > Date.now()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="endDate" label="End Date">
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                disabledDate={(current) =>
                  current &&
                  current <=
                    (form.getFieldValue("StartDate")
                      ? form.getFieldValue("StartDate").endOf("day")
                      : Date.now())
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Row gutter={16}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#005c53" }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const ExperienceForm = ({ onSave, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSave(values);
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Please enter your title" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="company"
        label="Company"
        rules={[{ required: true, message: "Please enter your company name" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="industry"
        label="Industry"
        rules={[{ required: true, message: "Please enter the industry" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item name="summary" label="Summary">
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Date Range">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[
                { required: true, message: "Please select the start date" }
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                disabledDate={(current) => current && current > Date.now()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="endDate" label="End Date">
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                disabledDate={(current) =>
                  current &&
                  current <=
                    (form.getFieldValue("StartDate")
                      ? form.getFieldValue("StartDate").endOf("day")
                      : Date.now())
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item name="currentlyWorking" valuePropName="checked">
        <Checkbox>Currently Working Here</Checkbox>
      </Form.Item>
      <Row gutter={16}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#005c53" }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
