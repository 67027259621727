import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const CookiePolicy = () => {
  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "white" }}>
      <Content
        className="container mt-5"
        style={{
          fontFamily: "'Inter', sans-serif",
          fontWeight: 400,
          color: "#262626",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <div className="text-start">
          <h2
            className="fw-bold mb-3"
            style={{
              fontSize: "2rem",
              color: "#262626",
              fontWeight: 700,
            }}
          >
            Cookie Policy
          </h2>
          <p style={{ fontSize: "1rem", color: "#888", fontWeight: 400 }}>
            Version v2 – March 2023
          </p>
        </div>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            1. Application
          </h4>
          <p className="mb-4">
            This policy applies to all employees, contractors, and vendors while
            doing business with MentorPal and others who have access to
            European Union (EU) and the European Economic Area (EEA) data
            subject information (“<strong>personal data</strong>”) in connection
            with MentorPal’s operating activities.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            2. Policy
          </h4>
          <p className="mb-3">
            Thank you for visiting MentorPal.ai (“<strong>Websites</strong>”). This cookie notice applies to the
            Websites, any third-party website (e.g., YouTube and Facebook), and
            applications accessed or used through such websites or platforms
            operated by or on behalf of MentorPal (“<strong>MentorPal Sites</strong>”).
          </p>
          <p className="mb-3">
            By using MentorPal Sites, you consent to our use of cookies and other tracking technologies in
            accordance with this notice. If you do not agree to our use of cookies and other tracking technologies
            in this way, you should set your browser settings accordingly or not use the MentorPal Sites. Disabling
            cookies may impact your user experience while on the MentorPal Sites.
          </p>
          <p className="mb-3">
            When using a mobile device to connect to the internet, please refer to the privacy notice of the specific
            app you are using to understand its specific data collection practices.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            What is a cookie?
          </h4>
          <p className="mb-3">
            Cookies are small text files placed on your computer by websites you visit. They are widely used to make
            websites work, or work more efficiently, and to provide information to site owners.
          </p>
          <p className="mb-4">
            For details on the information collected by cookies and how we use it, see below. For more information about
            the kind of data we collect, please read our Privacy Policy.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            Does MentorPal use cookies?
          </h4>
          <p className="mb-4">
            Yes. MentorPal uses cookies and similar technologies like single-pixel gifs and web beacons. We use both
            session-based and persistent cookies. MentorPal sets and accesses cookies on the domains operated by MentorPal
            and its corporate affiliates (collectively, the “<strong>Sites</strong>”). Additionally, we use third-party
            cookies, such as Google Analytics.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            How and why does MentorPal use cookies?
          </h4>
          <p className="mb-3">
            MentorPal uses cookies to understand how visitors use our websites. Cookies help us tailor our websites to
            your personal needs, improve user-friendliness, gather customer satisfaction feedback (through designated
            partners), and communicate with you elsewhere on the web. Some cookies are applied when you enter our sites.
          </p>
          <p className="mb-4">
            The information collected from cookies is kept in a format that is not personally identifiable. Our cookies
            do not retain your name or IP address.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            Necessary cookies
          </h4>
          <p className="mb-4">
            These cookies are strictly necessary for the operation of a website. Without them, the website won’t function
            properly. We do not ask for your specific consent for these cookies. For all other cookies, your informed
            consent is required.
          </p>
        </section>
      </Content>
    </Layout>
  );
};

export default CookiePolicy;
