import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spin, Tooltip, Layout, Typography, Row, Col } from "antd";
import Timer from "./Timer";
import Countdown from "./CountDown";
import QuestionRoot from "./QuestionRoot";
import TestCompletion from "./TestCompletion";
import {
  candidateAssessmentSubmissionAction,
  updateInviteAssessmentDetailAction
} from "../../../redux/actions/assessment";
import { useNavigate } from "react-router-dom";
import "../../../Assets/css/test.css";
import useProctoring from "../ProctorHook/useProctoring";
import TestProgress from "./Progress/QuestionProgress";
import { TailSpin } from "react-loader-spinner";
const { Title } = Typography;

const AssessmentTestDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    videoRef,
    canvasRef,
    fullScreenExitCount,
    mouseFocusLossCount,
    captureWebcamAndScreen
  } = useProctoring();

  const {
    loading: loadingData,
    data,
    error
  } = useSelector((state) => state.getInviteAssessmentDetailsReducer);
  const {
    loading: loadingSubmission,
    data: dataSubmission,
    error: errorSubmission
  } = useSelector((state) => state.candidateAssessmentSubmissionReducer);

  const {
    loading: loadingUpdateInvite,
    data: dataUpdateInvite,
    error: errorUpdateInvite
  } = useSelector((state) => state.updateInviteAssessmentDetailsReducer);

  const [testsData, setTestsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTestIndex, setCurrentTestIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [countdown, setCountdown] = useState(10);
  const [showCountdown, setShowCountdown] = useState(true);
  const [showCompletion, setShowCompletion] = useState(false);
  const [showTestFeedback, setShowTestFeedback] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());

  useEffect(() => {
    setQuestionStartTime(Date.now());
  }, [currentQuestionIndex, currentTestIndex]);

  useEffect(() => {
    setLoading(loadingData);
    if (!loadingData && data && !error) {
      const assessmentDetails = data?.assessment_details?.tests || [];
      const submissionData = data?.submission_data?.answers || [];
      const progressData = data?.submission_data?.progress || [];

      setTestsData(assessmentDetails);
      setAnswers(submissionData);

      if (submissionData.length === 0) {
        if (assessmentDetails.length > 0) {
          setCurrentTestIndex(0);
          setCurrentQuestionIndex(0);
        } else {
          setShowTestFeedback(true);
        }
      } else {
        const unfinishedTestIndex = progressData.findIndex(
          (test) => !test.is_completed
        );
        if (unfinishedTestIndex !== -1) {
          setCurrentTestIndex(unfinishedTestIndex);
          const firstUnansweredQuestionIndex =
            progressData[unfinishedTestIndex]?.completed_questions || 0;
          setCurrentQuestionIndex(firstUnansweredQuestionIndex);
        } else {
          setShowTestFeedback(true);
        }
      }
    } else if (error) {
      console.warn("Error:", error);
    }
  }, [loadingData, data, error]);

  useEffect(() => {
    if (showCountdown && countdown > 0) {
      const timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    } else if (countdown === 0 && showCountdown) {
      setShowCountdown(false);
      setCountdown(10);
    }
  }, [countdown, showCountdown]);

  const currentTest = testsData[currentTestIndex];
  const currentQuestion = currentTest?.questionData?.[currentQuestionIndex];
  const currentAnswer =
    answers
      .find((ans) => ans.test_id === currentTest?.test_id)
      ?.questions.find((q) => q.question_id === currentQuestion?.question_id)
      ?.answer || "";

  const questionProgress =
    ((currentQuestionIndex + 1) / (currentTest?.questionData?.length || 1)) *
    100;

  const handleAnswer = useCallback(
    (questionId, answer) => {
      setAnswers((prevAnswers) => {
        const completionTime = Date.now() - questionStartTime; //times in millisecond
        const updatedAnswers = [...prevAnswers];
        const testId = testsData[currentTestIndex]?.test_id;
        const testIndex = updatedAnswers.findIndex(
          (test) => test.test_id === testId
        );

        if (testIndex > -1) {
          const questionIndex = updatedAnswers[testIndex].questions.findIndex(
            (q) => q.question_id === questionId
          );
          if (questionIndex > -1) {
            updatedAnswers[testIndex].questions[questionIndex] = {
              ...updatedAnswers[testIndex].questions[questionIndex],
              answer,
              answer_mode: "attempted",
              completion_time: completionTime
            };
          } else {
            updatedAnswers[testIndex].questions.push({
              question_id: questionId,
              answer,
              answer_mode: "attempted",
              completion_time: completionTime
            });
          }
        } else {
          updatedAnswers.push({
            test_id: testId,
            questions: [
              {
                question_id: questionId,
                answer,
                answer_mode: "attempted",
                completion_time: completionTime
              }
            ]
          });
        }

        return updatedAnswers;
      });
    },
    [currentTestIndex, testsData]
  );

  const handleSaveAndMove = useCallback(async () => {
    try {
      const assessmentInviteId = data?.invite_data?.assessment_invite_id;
      dispatch(
        candidateAssessmentSubmissionAction({
          assessment_invite_id: assessmentInviteId,
          answers
        })
      );
    } catch (error) {
      console.error("Error saving answers:", error);
    }
  }, [answers, dispatch, data]);

  const handleNext = useCallback(() => {
    const assessmentInviteId = data?.invite_data?.assessment_invite_id;
    handleSaveAndMove();

    if (currentQuestionIndex < (currentTest?.questionData?.length || 0) - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else if (currentTestIndex < testsData.length - 1) {
      setShowCompletion(true);
    } else {
      dispatch(
        updateInviteAssessmentDetailAction({
          assessment_invite_id: assessmentInviteId,
          full_screen_exit_count: fullScreenExitCount,
          mouse_focus_loss_count: mouseFocusLossCount
        })
      );
      setTimeout(() => {
        window.location.assign(
          `/finalEvaluation?assessment_invite_id=${assessmentInviteId}`
        );
        localStorage.clear();
      }, 2000);
    }
  }, [
    currentQuestionIndex,
    currentTest?.questionData?.length,
    currentTestIndex,
    testsData.length,
    handleSaveAndMove,
    navigate,
    data
  ]);

  const handleSkip = useCallback(() => {
    setAnswers((prevAnswers) => {
      const completionTime = Date.now() - questionStartTime;
      const updatedAnswers = [...prevAnswers];
      const testId = testsData[currentTestIndex]?.test_id;
      const testIndex = updatedAnswers.findIndex(
        (test) => test.test_id === testId
      );

      if (testIndex > -1) {
        const questionIndex = updatedAnswers[testIndex].questions.findIndex(
          (q) => q.question_id === currentQuestion?.question_id
        );
        if (questionIndex === -1) {
          updatedAnswers[testIndex].questions.push({
            question_id: currentQuestion?.question_id,
            answer: "",
            answer_mode: "skipped",
            completion_time: completionTime
          });
        } else {
          updatedAnswers[testIndex].questions[questionIndex] = {
            ...updatedAnswers[testIndex].questions[questionIndex],
            answer: "",
            answer_mode: "skipped",
            completion_time: completionTime
          };
        }
      } else {
        updatedAnswers.push({
          test_id: testId,
          questions: [
            {
              question_id: currentQuestion?.question_id,
              answer: "",
              answer_mode: "skipped",
              completion_time: completionTime
            }
          ]
        });
      }

      return updatedAnswers;
    });

    handleSaveAndMove();
    handleNext();
  }, [
    currentQuestion,
    currentTestIndex,
    testsData,
    handleSaveAndMove,
    handleNext
  ]);

  const handleCompletionSubmit = useCallback(() => {
    setShowCompletion(false);
    if (currentTestIndex < testsData.length - 1) {
      setCurrentTestIndex((prevIndex) => prevIndex + 1);
      setCurrentQuestionIndex(0);
      setCountdown(10);
      setShowCountdown(true);
    }
  }, [currentTestIndex, testsData.length]);

  if (loading)
    return (
      <div className="loading-spinner">
        <Spin size="large" />
      </div>
    );

  if (showCountdown)
    return (
      <Countdown
        seconds={countdown}
        currentTestIndex={currentTestIndex}
        currentTest={currentTest}
      />
    );

  if (showCompletion)
    return (
      <TestCompletion
        currentTest={currentTest}
        handleCompletionSubmit={handleCompletionSubmit}
      />
    );

  if (showTestFeedback) {
    const assessmentInviteId = data?.invite_data?.assessment_invite_id;
    dispatch(
      updateInviteAssessmentDetailAction({
        assessment_invite_id: assessmentInviteId,
        full_screen_exit_count: fullScreenExitCount,
        mouse_focus_loss_count: mouseFocusLossCount
      })
    );
    setTimeout(() => {
      window.location.assign(
        `/finalEvaluation?assessment_invite_id=${assessmentInviteId}`
      );
      localStorage.clear();
    }, [2000]);

    return null;
  }

  return (
    <>
      {loadingSubmission || loadingUpdateInvite || loadingData ? (
        <div className="loading-spinner">
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : null}
      <Layout className="layout--fullscreen test-layout" id="test-area">
        <div
          className="main-header"
          style={{ borderBottom: "3px solid #e8e8e8" }}
        >
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={6} className="text-center">
              <Tooltip
                title={`Test ${currentTestIndex + 1}: ${
                  currentTest?.test_title || "Untitled"
                }`}
              >
                <Title className="ml-3" level={4}>
                  {`Test ${currentTestIndex + 1} - ${
                    currentTest?.test_title || "Untitled"
                  }`}
                </Title>
              </Tooltip>
            </Col>

            <Col xs={24} sm={11} className="text-center">
              <a className="external incognito-link inline-block mb-m mt-m">
                <Timer
                  key={currentQuestionIndex}
                  initialTime={currentQuestion?.recommendedTime * 60}
                  onTimeUp={handleSkip}
                />
                <TestProgress
                  currentQuestionIndex={currentQuestionIndex}
                  currentTest={currentTest}
                  questionProgress={questionProgress}
                />
              </a>
            </Col>

            <Col xs={24} sm={7} className=" text-center">
              <Tooltip title="Skip this question and move to the next one">
                <Button
                  className="custom-button"
                  onClick={handleSkip}
                  disabled={loadingSubmission}
                >
                  Skip
                </Button>
              </Tooltip>
              <Tooltip title="Save the answer and move to the next question">
                <Button
                  className="custom-button"
                  onClick={handleNext}
                  disabled={!currentAnswer || loadingSubmission}
                >
                  {currentTestIndex === testsData.length - 1 &&
                  currentQuestionIndex ===
                    (currentTest?.questionData?.length || 0) - 1
                    ? "Submit"
                    : "Next"}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </div>
        <video
          ref={videoRef}
          style={{ display: "none" }}
          autoPlay
          playsInline
        />
        <canvas ref={canvasRef} style={{ display: "none" }} />
        <div className="test-root-container">
          <QuestionRoot
            currentQuestion={currentQuestion}
            handleAnswer={handleAnswer}
            currentAnswer={currentAnswer}
          />
        </div>
      </Layout>
    </>
  );
};

export default AssessmentTestDashboard;
