import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  Layout,
  Pagination,
  Table,
  Tooltip,
  Tabs,
  message
} from "antd";
import { StarFilled, StarOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  getAllAssessmentListAction,
  updateAssessmentAction
} from "../../../redux/actions/assessment";
import { SearchOutlined } from "@ant-design/icons";
const { Content } = Layout;
const { TabPane } = Tabs;

const AssessmentCards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateAssessmentReducer = useSelector(
    (state) => state.updateAssessmentReducer
  );
  const getAllAssessmentListReducer = useSelector(
    (state) => state.getAllAssessmentListReducer
  );
  const createAssessmentReducer = useSelector(
    (state) => state.createAssessmentReducer
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalAssessment, setTotalAssessment] = useState(0);
  const [data, setData] = useState([]);
  const [archivedData, setArchivedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(
      getAllAssessmentListAction({
        page: currentPage,
        limit: pageSize
      })
    );
    return () => {
      dispatch({ type: "GET_LIST_ASSESSMENT_RESET" });
    };
  }, [
    dispatch,
    currentPage,
    pageSize,
    updateAssessmentReducer,
    createAssessmentReducer
  ]);

  useEffect(() => {
    const { loading, status, error, data } = getAllAssessmentListReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      const archived = data?.data?.filter(
        (assessment) => assessment.is_archived
      );
      const active = data?.data?.filter(
        (assessment) => !assessment.is_archived
      );
      setData(active);
      setArchivedData(archived);
      setTotalAssessment(data?.totalAssessment);
      setFilteredData(active);
    }
    if (!loading && !data && error) {
      message.error(error);
    }
  }, [getAllAssessmentListReducer]);

  useEffect(() => {
    if (searchText) {
      const searchData = data.filter((item) =>
        item.title.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(searchData);
    } else {
      setFilteredData(data);
    }
  }, [searchText, data]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleStar = (record) => {
    dispatch(
      updateAssessmentAction({
        assessment_id: record?.assessment_id,
        is_starred: !record?.is_starred
      })
    );
  };

  const handleRefresh = () => {
    dispatch(
      getAllAssessmentListAction({
        page: currentPage,
        limit: pageSize
      })
    );
    setSearchText("");
  };

  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "",
      dataIndex: "star",
      key: "star",
      width: 50,
      fixed: !isMobile ? "left" : undefined,
      ellipsis: true,
      render: (_, record) => (
        <Tooltip title={`star assessment ${record.title}`}>
          <Button
            icon={record?.is_starred ? <StarFilled /> : <StarOutlined />}
            type="text"
            onClick={() => handleStar(record)}
            className={record.is_starred ? "starred" : ""}
          />
        </Tooltip>
      )
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      fixed: !isMobile ? "left" : undefined,
      width: 250,
      render: (text, record) => (
        <div>
          <a
            className="text-decoration-underline"
            style={{ cursor: "pointer", color: "#005c53" }}
            onClick={() =>
              navigate(
                `/dashboard/assessment/details?assessment_id=${record?.assessment_id}`
              )
            }
          >
            {text}
          </a>
        </div>
      )
    },
    {
      title: "Progress",
      key: "progress",
      width: 300, // Increased width for a better view
      render: (text, record) => {
        const { total_candidates, assessment_completed, invited, evaluated } =
          record;

        const CompletedTestPercentage =
          total_candidates > 0
            ? (assessment_completed / total_candidates) * 100
            : 0;
        const invitedPercentage =
          total_candidates > 0 ? (invited / total_candidates) * 100 : 0;
        const evaluatedPercentage =
          total_candidates > 0 ? (evaluated / total_candidates) * 100 : 0;

        const remainingPercentage =
          100 -
          (CompletedTestPercentage + invitedPercentage + evaluatedPercentage);

        const progressTooltip = (
          <div>
            <p className="text-white">Total Candidates: {total_candidates}</p>
            <p className="text-white">Invited: {invited}</p>
            <p className="text-white">Completed: {assessment_completed}</p>
            <p className="text-white">Evaluated: {evaluated}</p>
          </div>
        );

        return (
          <Tooltip title={progressTooltip}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                height: "12px",
                width: "100%",
                border: "1px solid whitesmoke",
                borderRadius: "4px"
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "4px",
                  overflow: "hidden"
                }}
              >
                <div
                  style={{
                    width: `${invitedPercentage}%`,
                    backgroundColor: "#108ee9",
                    height: "100%",
                    float: "left"
                  }}
                ></div>
                <div
                  style={{
                    width: `${CompletedTestPercentage}%`,
                    backgroundColor: "#52c41a",
                    height: "100%",
                    float: "left"
                  }}
                ></div>
                <div
                  style={{
                    width: `${evaluatedPercentage}%`,
                    backgroundColor: "#fadb14",
                    height: "100%",
                    float: "left"
                  }}
                ></div>
                <div
                  style={{
                    width: `${remainingPercentage}%`,
                    backgroundColor: "#ffffff",
                    height: "100%",
                    float: "left"
                  }}
                ></div>
              </div>
            </div>
          </Tooltip>
        );
      }
    },
    {
      title: "Total Candidate",
      dataIndex: "total_candidates",
      key: "total_candidates",
      width: 150,
      sorter: (a, b) => a.total_candidates - b.total_candidates
    },
    {
      title: "Invited",
      dataIndex: "invited",
      key: "invited",
      width: 150,
      sorter: (a, b) => a.invited - b.invited
    },
    {
      title: "Completed",
      dataIndex: "assessment_completed",
      key: "assessment_completed",
      width: 150,
      sorter: (a, b) => a.assessment_completed - b.assessment_completed
    },
    {
      title: "Evaluated",
      dataIndex: "evaluated",
      key: "evaluated",
      width: 150,
      sorter: (a, b) => a.evaluated - b.evaluated
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        const date = new Date(createdAt);
        return date
          .toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
          })
          .replace(",", "");
      },
      width: 200
    }
  ];
  const handleSearch = (value) => {
    setSearchText(value);
  };
  return (
    <>
      <Content className="px-3" style={{ minHeight: 280 }}>
        <div className="data-preview">
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <>
              <section className="candidate-listing-grid__row-section">
                <div className="cl-section-header row justify-content-between align-items-end py-3 mt-2">
                  <div className="col d-flex align-items-center">
                    <form className="candidate-search-input-form-theme-3">
                      <div className="form-group">
                        <label htmlFor="cl-search-input" className="d-none">
                          Search Candidate
                        </label>
                        <div className="input-group">
                          <Input
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            value={searchText}
                            onChange={(e) => handleSearch(e.target.value)}
                            style={{ width: 300 }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center p-2">
                    <Button
                      className="ms-2"
                      onClick={handleRefresh}
                      icon={<ReloadOutlined />}
                      type="primary"
                      style={{ backgroundColor: "#00756a" }}
                    >
                      Refresh
                    </Button>
                  </div>
                </div>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Assessments" key="1">
                    <>
                      <Table
                        bordered
                        columns={columns}
                        dataSource={filteredData}
                        pagination={false}
                        scroll={{
                          x: 1300
                        }}
                        className="w-100"
                      />
                      <Pagination
                        className="text-center align-content-center mt-3"
                        current={currentPage}
                        total={totalAssessment}
                        pageSize={pageSize}
                        showSizeChanger
                        onChange={handlePageChange}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        showTotal={(total) => `Total ${total} assessments`}
                      />
                    </>
                  </TabPane>
                  <TabPane tab="Archive" key="2">
                    <>
                      <Table
                        bordered
                        columns={columns}
                        dataSource={archivedData}
                        pagination={false}
                        scroll={{
                          x: 1300
                        }}
                        className="w-100"
                      />
                      <Pagination
                        className="text-center align-content-center mt-3"
                        current={currentPage}
                        total={totalAssessment}
                        pageSize={pageSize}
                        showSizeChanger
                        onChange={handlePageChange}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        showTotal={(total) => `Total ${total} assessments`}
                      />
                    </>
                  </TabPane>
                </Tabs>
              </section>
            </>
          )}
        </div>
      </Content>
    </>
  );
};

export default AssessmentCards;
