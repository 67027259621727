import React, { useState, useEffect } from "react";
import {
  Collapse,
  Button,
  Radio,
  Slider,
  Input,
  DatePicker,
  Table,
  Select,
  Dropdown,
  Space,
  Rate,
  message,
  Modal,
  Form,
  Drawer
} from "antd";
import { Row, Col } from "antd";
import {
  SearchOutlined,
  ReloadOutlined,
  DownloadOutlined,
  MoreOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAssessmentCandidateAction,
  updateInviteAssessmentDetailAction
} from "../../../../redux/actions/assessment";
import { TailSpin } from "react-loader-spinner";
import { ASSESSMENT_STAGE_OPTION, ASSESSMENT_STATUS_OPTION } from "../../data";
import { multipleAction } from "../../../../redux/actions/common";
import { exportToCSV, exportToExcel } from "../../../../Utils/JSONTOFILE";
import ("../../../../Assets/css/filter.css");

const { confirm } = Modal;
const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const FilterForm = ({
  filters,
  handleFilterChange,
  handleReset,
  handleChange,
  totalCandidates
}) => (
  <Form
    layout="vertical"
    onValuesChange={handleFilterChange}
    initialValues={filters}
    style={{ paddingTop: "20px" }}
  >
<div
                className="p-3 bg-light"
                style={{ width: "100%", background: "#fff" }}
              >
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Button type="ghost">
                    <span className="d-flex align-items-center">
                      <h2 className="m-0 ml-2">Filters</h2>
                    </span>
                  </Button>
                  <Button type="ghost" onClick={handleReset}>
                    Reset all
                  </Button>
                </div>
                <Collapse
                  defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
                  expandIconPosition="right"
                  style={{ background: "#fff", border: "none" }}
                >
                  <Panel header="Candidate status" key="1">
                    <Radio.Group
                      value={filters?.status}
                      onChange={(e) => handleChange("status", e.target.value)}
                    >
                      <div className="d-flex justify-content-between mb-2">
                        <Radio value="">All Candidates</Radio>
                        <span className="text-muted">{totalCandidates}</span>
                      </div>
                      {ASSESSMENT_STATUS_OPTION?.map((item) => (
                        <div
                          className="d-flex justify-content-between mb-2"
                          key={item.value}
                        >
                          <Radio value={item?.value}>{item?.label}</Radio>
                        </div>
                      ))}
                    </Radio.Group>
                  </Panel>
                  <Panel header="Candidate stage" key="2">
                    <Radio.Group
                      value={filters?.stage}
                      onChange={(e) => handleChange("stage", e.target.value)}
                    >
                      <div className="d-flex justify-content-between mb-2">
                        <Radio value="">All </Radio>
                        <span className="text-muted">{totalCandidates}</span>
                      </div>
                      {ASSESSMENT_STAGE_OPTION?.map((item) => (
                        <div
                          className="d-flex justify-content-between mb-2"
                          key={item.value}
                        >
                          <Radio value={item?.value}>{item?.label}</Radio>
                        </div>
                      ))}
                    </Radio.Group>
                  </Panel>

                  <Panel header="Invited on" key="3">
                    <RangePicker
                      style={{ width: "100%" }}
                      value={filters?.invitedOn}
                      onChange={(dates) => handleChange("invitedOn", dates)}
                    />
                  </Panel>
                </Collapse>
              </div>
  </Form>
);

const CandidatesComponent = ({ data }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    status: "",
    totalScore: [],
    invitedOn: [],
    expiryDate: [],
    stage: ""
  });

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const getAllAssessmentCandidateReducer = useSelector(
    (state) => state.getAllAssessmentCandidateReducer
  );
  const updateInviteAssessmentDetailsReducer = useSelector(
    (state) => state.updateInviteAssessmentDetailsReducer
  );
  const multipleActionReducer = useSelector(
    (state) => state.multipleActionReducer
  );

  useEffect(() => {
    dispatch(
      getAllAssessmentCandidateAction({
        assessment_id: data?.assessment_id,
        page: 1,
        limit: 10,
        ...filters
      })
    );
    return () => {
      dispatch({ type: "GET_ALL_ASSESSMENT_CANDIDATE_RESET" });
    };
  }, [dispatch, data, filters]);

  useEffect(() => {
    const { loading, data, error } = getAllAssessmentCandidateReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setCandidateData(data.data);
      setTotalCandidates(data.totalInvites);
      setFilteredData(data.data);
    }
    if (!loading && !data && error) {
      message.error(error);
    }
  }, [getAllAssessmentCandidateReducer]);

  useEffect(() => {
    const { loading, data, error, request } =
      updateInviteAssessmentDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      const action_type = request?.action_type;
      switch (action_type) {
        case "REJECT":
          message.success("Candidate Reject Successfully!");
          break;
        case "DELETE_CANDIDATE":
          message.success("Candidate Delete Successfully!");

          break;

        default:
          message.info("Action Performed successfully!");
          break;
      }
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [updateInviteAssessmentDetailsReducer]);

  useEffect(() => {
    const { loading, data, error } = multipleActionReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      message.success(data?.message);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [multipleActionReducer]);

  useEffect(() => {
    const filtered = candidateData.filter((item) => {
      return (
        item.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.email.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredData(filtered);
  }, [searchText, candidateData]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
      // const selectedCandidates = candidateData.filter((candidate) =>
      //   newSelectedRowKeys.includes(candidate._id)
      // );
      // selectedCandidates.forEach((candidate) => {
      //   console.log("Selected assessment_id:", candidate.assessment_id);
      //   console.log(
      //     "Selected assessment_invite_id:",
      //     candidate.assessment_invite_id
      //   );
      // });
    }
  };

  const handleAction = (type, record) => {
    switch (type) {
      case "REJECT":
        dispatch(
          updateInviteAssessmentDetailAction({
            action_type: "REJECT",

            stage: "rejected",
            assessment_invite_id: record?.assessment_invite_id
          })
        );
        break;
      case "DELETE_CANDIDATE":
        dispatch(
          updateInviteAssessmentDetailAction({
            action_type: "DELETE_CANDIDATE",

            status: "delete",
            assessment_invite_id: record?.assessment_invite_id
          })
        );

        break;
      case "DOWNLOAD_CSV":
        downloadCSV();
        break;
      case "DOWNLOAD_EXCEL":
        downloadExcel();
        break;
      case "COPY_INVITATION_LINK":
        const invitationLink = record?.invitation_link;
        if (invitationLink) {
          navigator.clipboard
            .writeText(invitationLink)
            .then(() => {
              message.success("Invitation link copied to clipboard!");
            })
            .catch((err) => {
              message.error(
                "Failed to copy invitation link. Please try again."
              );
            });
        } else {
          message.error("Invitation link is not available.");
        }
        break;
      case "SEND_RE_INVITATION":
        dispatch(
          multipleAction({
            action_type: "RE_ASSESSMENT_INVITATION",
            data: {
              assessment_invite_id: record?.assessment_invite_id,
              candidate_id: record?.candidate_id
            }
          })
        );

        break;
      case "SEND_REMINDER":
        dispatch(
          multipleAction({
            action_type: "REMINDER_ASSESSMENT_INVITATION",
            data: {
              assessment_invite_id: record?.assessment_invite_id,
              candidate_id: record?.candidate_id
            }
          })
        );

        break;
      case "SEND_RESULTS":
        dispatch(
          multipleAction({
            action_type: "ASSESSMENT_RESULT_SENDING",
            data: {
              assessment_invite_id: record?.assessment_invite_id,
              candidate_id: record?.candidate_id
            }
          })
        );

        break;
      default:
        message.info(`Feature coming soon: ${type}`);
    }
  };

  const showConfirm = (type, record) => {
    const actionMessages = {
      SEND_RESULTS:
        "Are you sure you want to send results to this candidate? This action cannot be undone.",
      REJECT:
        "Are you sure you want to reject this candidate? They will be marked as rejected and cannot proceed further.",
      DELETE_CANDIDATE:
        "Are you sure you want to delete this candidate? This action is irreversible and will remove all their data.",
      SEND_RE_INVITATION:
        "Are you sure you want to send a re-invitation to this candidate? They will receive a new invitation email.",
      SEND_REMINDER:
        "Are you sure you want to send a reminder to this candidate? They will receive a reminder email to complete their assessment.",
      COPY_INVITATION_LINK:
        "Are you sure you want to copy the invitation link to your clipboard?"
    };

    confirm({
      title: actionMessages[type],
      okText: "Yes",
      cancelText: "No",
      onOk: () => handleAction(type, record),
      onCancel() {
        message.info("Action canceled");
      }
    });
  };

  const moreAction = (record) => [
    {
      key: "1",
      label: (
        <a onClick={() => showConfirm("SEND_RESULTS", record)}>Send Results</a>
      )
    },
    {
      key: "2",
      label: <a onClick={() => showConfirm("REJECT", record)}>Reject</a>
    },
    {
      key: "3",
      label: (
        <a onClick={() => showConfirm("DELETE_CANDIDATE", record)}>
          Delete Candidate
        </a>
      )
    },
    {
      key: "4",
      label: (
        <a onClick={() => showConfirm("SEND_RE_INVITATION", record)}>
          Send Re-Invitation
        </a>
      )
    },
    {
      key: "5",
      label: (
        <a onClick={() => showConfirm("SEND_REMINDER", record)}>
          Send Reminder
        </a>
      )
    },
    {
      key: "6",
      label: (
        <a onClick={() => handleAction("COPY_INVITATION_LINK", record)}>
          Copy Invitation Link
        </a>
      )
    }
  ];

  const downloadAction = [
    {
      key: "1",
      label: <a onClick={() => handleAction("DOWNLOAD_CSV")}>CSV</a>
    },
    {
      key: "2",
      label: <a onClick={() => handleAction("DOWNLOAD_EXCEL")}>Excel</a>
    }
  ];

  const downloadCSV = () => {
    const downloadData = filteredData.map((candidate) => ({
      Name: `${candidate.firstName} ${candidate.lastName}`,
      Email: candidate.email,
      Status:
        ASSESSMENT_STATUS_OPTION.find((opt) => opt.value === candidate.status)
          ?.label || candidate.status,
      Stage: candidate.stage,
      InvitedOn: new Date(candidate.createdAt).toLocaleDateString(),
      OverallPercentage:
        candidate.overallData?.overall_percentage?.toFixed(2) || "-",
      OverallRating: candidate.recruiter_feedback?.rate || 0
    }));

    const getProperFilename = (title) =>
      title?.replace(/\s+/g, "-") || "candidates.csv";

    const filename = getProperFilename(data?.title);
    exportToCSV(
      downloadData,
      `${filename}-${data?.assessment_id}-candidates.csv`
    );
    message.success("Report Download successfully!");
  };

  const downloadExcel = () => {
    const downloadData = filteredData.map((candidate) => ({
      Name: `${candidate.firstName} ${candidate.lastName}`,
      Email: candidate.email,
      Status:
        ASSESSMENT_STATUS_OPTION.find((opt) => opt.value === candidate.status)
          ?.label || candidate.status,
      Stage: candidate.stage,
      InvitedOn: new Date(candidate.createdAt).toLocaleDateString(),
      OverallPercentage:
        candidate.overallData?.overall_percentage?.toFixed(2) || "-",
      OverallRating: candidate.recruiter_feedback?.rate || 0
    }));

    const getProperFilename = (title) =>
      title?.replace(/\s+/g, "-") || "candidates.xlsx";

    const filename = getProperFilename(data?.title);

    exportToExcel(
      downloadData,
      `${filename}-${data?.assessment_id}-candidates.xlsx`
    );
    message.success("Report Download successfully!");
  };

  useEffect(() => {
    const baseColumns = [
      {
        title: "Name",
        fixed: !isMobile ? "left" : undefined,
        width: 200,
        dataIndex: "firstName",
        key: "name",
        ellipsis:true,
        sorter: (a, b) =>
          `${a.firstName} ${a.lastName}`.localeCompare(
            `${b.firstName} ${b.lastName}`
          ),
        render: (text, record) => (
          <Link
            className="text-decoration-underline"
            style={{ cursor: "pointer", color: "#005c53" }}
            to={`/dashboard/candidates-assessment-report?assessment_invite_id=${record.assessment_invite_id}`}
          >
            {`${record.firstName} ${record.lastName}`}
          </Link>
        )
      },

      {
        title: "Overall Percentage",
        dataIndex: "overallData",
        key: "overallPercentage",
        width: 150,
        render: (overallData) =>
          overallData && overallData.overall_percentage
            ? `${overallData.overall_percentage.toFixed(2)}%`
            : "-"
      }
    ];

    const afterDynamicColumns = [
      {
        title: "Stage",
        dataIndex: "stage",
        key: "stage",
        width: 250,
        render: (stage, record) => (
          <Select
            defaultValue={stage}
            style={{ width: "100%" }}
            onChange={(value) => {
              dispatch(
                updateInviteAssessmentDetailAction({
                  stage: value,
                  assessment_invite_id: record?.assessment_invite_id
                })
              );
            }}
          >
            {ASSESSMENT_STAGE_OPTION?.map((options) => (
              <Option key={options?.value} value={options?.value}>
                {options?.label}
              </Option>
            ))}
          </Select>
        )
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 250,
        render: (status) => {
          const statusOption = ASSESSMENT_STATUS_OPTION.find(
            (option) => option.value === status
          );
          return statusOption ? statusOption.label : status;
        }
      },
      {
        title: "Invited On",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),

        render: (createdAt) => {
          const date = new Date(createdAt);
          return date
            .toLocaleString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true
            })
            .replace(",", "");
        },
        width: 200
      },
      {
        title: "Overall Rating",
        dataIndex: "recruiter_feedback",
        key: "recruiter_feedback",
        width: 200,
        render: (record) => <Rate disabled="true" value={record?.rate || 0} />
      },
      {
        title: "Action",
        dataIndex: "actions",
        fixed: !isMobile ? "right" : undefined,
        width: 100,
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              menu={{ items: moreAction(record) }}
              placement="bottomRight"
            >
              <Button shape="circle" icon={<MoreOutlined />} />
            </Dropdown>
          </Space>
        )
      }
    ];

    const testColumns = data?.testIds?.map((testId) => ({
      title: testId?.title,
      dataIndex: testId?.test_id,
      key: testId?.test_id,
      width: 150,
      render: (text, record) => {
        const evaluation = record.testEvaluations?.find(
          (evalTest) => evalTest?.test_id === testId?.test_id
        );
        return evaluation?.evaluation_test
          ? `${evaluation?.test_percent?.toFixed(2)}%`
          : "-";
      }
    }));

    setDynamicColumns([...baseColumns, ...testColumns, ...afterDynamicColumns]);
  }, [candidateData, data]);

  const handleReset = () => {
    setFilters({
      status: "",
      totalScore: [],
      invitedOn: [],
      expiryDate: [],
      stage: ""
    });
    setSearchText("");
  };

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleRefresh = () => {
    setSearchText("");
    dispatch(
      getAllAssessmentCandidateAction({
        assessment_id: data?.assessment_id,
        page: 1,
        limit: 10000,
        ...filters
      })
    );
  };

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={4}>
          <div style={{ maxWidth: "300px", margin: "0 auto" }}>
          {!isMobile && (
            <aside
              style={{ top: "calc(-1025px + min(-32px + 100vh, 1121px))" }}
            >
              <div className="px-3 bg-light" style={{ height: "100vh" }}>
              <FilterForm
                filters={filters}
                handleReset={handleReset}
                handleChange={handleChange}
                
              />
            </div>
            </aside>
            )}
          </div>

        </Col>
        <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
        <Col xs={24} md={20}>
          <div style={{ padding: "20px" }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                  style={{ width: 200 }}
                />
              </div>
              <div>
                <Button
                  style={{ background: "#00756a" }}
                  icon={<ReloadOutlined />}
                  onClick={handleRefresh}
                  className="me-2"
                >
                  Refresh
                </Button>

                <Dropdown
                  menu={{
                    items: downloadAction
                  }}
                  placement="bottomRight"
                >
                  <Button icon={<DownloadOutlined />} />
                </Dropdown>
              </div>
            </div>

            <div style={{ overflowX: "auto" }}>
              <Table
                bordered
                rowSelection={rowSelection}
                columns={dynamicColumns}
                dataSource={filteredData.map((candidate) => ({
                  key: candidate._id,
                  ...candidate
                }))}
                loading={{
                  spinning: loading,
                  indicator: <TailSpin height="30" width="30" color="#1890ff" />
                }}
                pagination={{ pageSize: 10 }}
                scroll={{ x: 1500 }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="100%"
        title="Filters"
        className="filter-drawer"
      >
        <FilterForm
          filters={filters}
          handleChange={handleChange}
          handleReset={handleReset}
          
        />
      </Drawer>
    </>
  );
};

export default CandidatesComponent;
