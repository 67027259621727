import React, { useCallback, useEffect, useState } from "react";
import {
  Drawer,
  Button,
  Form,
  Select,
  Typography,
  Row,
  Col,
  message,
  Spin
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getInterviewDetailsAction,
  updateInterviewDetailsAction
} from "../../redux/actions/interviewer";
import { useIsMobileView } from "../../Utils/CustomHooks/windowSize";

const { Title } = Typography;
const { Option } = Select;

const TrackingDrawer = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);


  const getInterviewDetailsReducer = useSelector(
    (state) => state.getInterviewDetailsReducer
  );

  const updateInterviewDetailsReducer = useSelector(
    (state) => state.updateInterviewDetailsReducer
  );

  useEffect(() => {
    if (visible?.interview_id) {
      dispatch(
        getInterviewDetailsAction({ interview_id: visible.interview_id })
      );
    }
    return () => {
      dispatch({ type: "GET_INTERVIEW_DETAILS_RESET" });
    };
  }, [dispatch, visible]);

  useEffect(() => {
    const { loading, data, error } = getInterviewDetailsReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        form.setFieldsValue({
          initialConfirmationStatus:
            data.interview.tracking.initialConfirmationStatus,
          finalConfirmationStatus:
            data.interview.tracking.finalConfirmationStatus
        });
      } else if (error) {
        message.error("Failed to fetch interview details.");
      }
    }
  }, [getInterviewDetailsReducer, form]);
  const onClose = useCallback(() => {
    dispatch({ type: "GET_INTERVIEW_DETAILS_RESET" });
    setVisible({ show: false, interview_id: null });
  }, [setVisible, dispatch]);

  useEffect(() => {
    const { loading, data, error } = updateInterviewDetailsReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        message.success("Tracking updated successfully!");
        onClose();
      } else if (error) {
        message.error("Failed to update interview tracking. Please try again.");
      }
    }
  }, [updateInterviewDetailsReducer]);

  const handleDone = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        dispatch(
          updateInterviewDetailsAction({
            interview_id: visible.interview_id,
            tracking: values
          })
        );
        message.success("Tracking details updated successfully!");
        onClose();
      })
      .catch((error) => {
        console.error("Form validation failed:", error);
      });
  }, [form, dispatch, visible.interview_id, onClose]);

  return (
    <Drawer
      placement="right"
      closable={false}
      open={visible.show}
      width={useIsMobileView() ? "100%" : "20%"}
      onClose={onClose}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Title level={4} style={{ margin: 0 }}>
            Update Tracking Status
          </Title>
          <CloseOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
      }
      footer={
        <Row justify="space-between">
          <Col>
            <Button className="custom-button" type="button" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              loading={updateInterviewDetailsReducer?.loading}
              className="custom-button"
              onClick={handleDone}
            >
              Save
            </Button>
          </Col>
        </Row>
      }
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spin size="large" />
        </div>
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            label="On Track Morning Status?"
            name="initialConfirmationStatus"
          >
            <Select>
              <Option value="pending">Pending</Option>
              <Option value="on track">On Track</Option>
              <Option value="off track">Off Track</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="On Track status before 30 minutes of meeting?"
            name="finalConfirmationStatus"
          >
            <Select>
              <Option value="pending">Pending</Option>
              <Option value="on track">On Track</Option>
              <Option value="off track">Off Track</Option>
            </Select>
          </Form.Item>
        </Form>
      )}
    </Drawer>
  );
};

export default TrackingDrawer;
