import React from "react";
import { useNavigate } from "react-router-dom";
import { calculateRemainingTime } from "../../Utils/Common";

const PublicJobCard = ({ job }) => {
  const navigate = useNavigate();

  return (
    <li
      className="list-group-item"
      style={{
        padding: "20px",
        margin: "20px 0",
        border: "1px solid #e0e0e0",
        borderRadius: "18px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        transition: "transform 0.3s, box-shadow 0.3s",
        backgroundColor: "#ffffff"
      }}
      onClick={() => navigate(`/apply-job/${job?.job_id}`)}
    >
      <div
        className="d-flex justify-content-between align-items-center mb-4"
        style={{ borderBottom: "1px solid #f0f0f0", paddingBottom: "15px" }}
      >
        <h2 className="h5 mb-0" style={{ color: "#333", fontWeight: "bold" }}>
          {job.job_title}
        </h2>
        <button
          className="btn btn-primary btn-sm"
          style={{
            backgroundColor: "#006d62",
            border: "none",
            padding: "8px 20px",
            borderRadius: "18px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)"
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/apply-job/${job?.job_id}`);
          }}
        >
          Apply Now
        </button>
      </div>

      <div className="d-flex justify-content-between mb-4">
        <div className="text-center">
          <strong
            className="d-block"
            style={{ fontSize: "1.1rem", color: "#006d62" }}
          >
            {job?.job_type}
          </strong>
          <span className="text-muted" style={{ fontSize: "0.9rem" }}>
            Job Type
          </span>
        </div>
        <div className="text-center">
          <strong
            className="d-block"
            style={{ fontSize: "1.1rem", color: "#006d62" }}
          >
            {job?.job_location}
          </strong>
          <span className="text-muted" style={{ fontSize: "0.9rem" }}>
            Location
          </span>
        </div>
      </div>

      <div
        className="text-end text-muted"
        style={{
          fontSize: "0.9rem",
          borderTop: "1px solid #f0f0f0",
          paddingTop: "15px"
        }}
      >
        <i className="bi bi-clock" style={{ marginRight: "5px" }}></i>
        Application Deadline:{" "}
        <span style={{ color: "#d9534f" }}>
          {calculateRemainingTime(job?.application_deadline).days >= 0
            ? `${
                calculateRemainingTime(job?.application_deadline).days
              } days remains`
            : "Job deadline is over"}
        </span>
      </div>
    </li>
  );
};

export default PublicJobCard;
