import React, { useEffect, useState } from "react";
import {
  Layout,
  Pagination,
  Table,
  Tag,
  Button,
  Typography,
  Input,
  message
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { getAllTestListAction } from "../../../redux/actions/assessment";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { DOCUMENT_STATUS, QUESTION_TYPE, TEST_LEVELS } from "../data";

const { Content } = Layout;

export const TestTable = ({ question }) => {
  const dispatch = useDispatch();
  const getAllTestListReducer = useSelector(
    (state) => state.getAllTestListReducer
  );
  const createTestReducer = useSelector((state) => state.createTestReducer);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalTest, setTotalTest] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(
      getAllTestListAction({
        page: currentPage,
        limit: pageSize
      })
    );
    return () => {
      dispatch({ type: "GET_LIST_TEST_RESET" });
    };
  }, [dispatch, currentPage, pageSize, createTestReducer]);

  useEffect(() => {
    const { loading, status, error, data } = getAllTestListReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.data);
      setTotalTest(data?.totalTest);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getAllTestListReducer]);

  const navigate = useNavigate();

  const handleRowClick = (testId) => {
    navigate(`details?test_id=${testId}`);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      fixed: !isMobile ? "left" : undefined,
      width: 70,
      ellipsis: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width:200,
      render: (text, record) => (
        <a
          className="text-decoration-underline"
          style={{ cursor: "pointer", color: "#005c53" }}
          onClick={() => handleRowClick(record.test_id)}
        >
          {text}
        </a>
      ),
      fixed: !isMobile ? "left" : undefined,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 250,
      render: (status) => {
        const statusOption = DOCUMENT_STATUS.find(
          (option) => option.value === status
        );
        return statusOption ? statusOption.label : status;
      }
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width:200,
      render: (level) => {
        const levelOptions = TEST_LEVELS.find(
          (option) => option.value === level
        );
        return levelOptions ? levelOptions.label : level;
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width:200,
      render: (type) => {
        const questionType = QUESTION_TYPE.find(
          (option) => option.value === type
        );
        return questionType ? questionType.label : type;
      }
    },

    {
      title: "Time",
      dataIndex: "test_duration",
      key: "test_duration",
      width:150,
      render: (test_duration) => `${test_duration} mins`
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRows(newSelectedRows);
    }
  };
  const handleRefresh = () => {
    dispatch(
      getAllTestListAction({
        page: currentPage,
        limit: pageSize
      })
    );
    setSearchText(""); 
  };
  useEffect(() => {
    if (searchText) {
      const searchData = data.filter((item) =>
        item.title.toLowerCase().includes(searchText.toLowerCase())
      );
      setData(searchData);
    } else {
      setData(data);
    }
  }, [searchText, data]);

  const handleSearch = (value) => {
    setSearchText(value);
  };
  return (
    <Layout style={{ padding: "0px", minHeight: 280 }}>
      <Content className="px-3">
        <div className="data-preview">
          {loading ? (
            <div className="loading-spinner">
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <>
              <div className="cl-section-header row justify-content-between align-items-end py-3">
                <div className="col d-flex align-items-center">
                  <form className="candidate-search-input-form-theme-3">
                    <div className="form-group">
                      <label htmlFor="cl-search-input" className="d-none">
                        Search Questions
                      </label>
                      <div className="input-group">
                        <Input
                          placeholder="Search"
                          prefix={<SearchOutlined />}
                          value={searchText}
                          onChange={(e) => handleSearch(e.target.value)}
                          style={{ width: 300 }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center p-2">
                  <Button
                    className="ms-2"
                    onClick={handleRefresh}
                    icon={<ReloadOutlined />}
                    type="primary"
                    style={{ backgroundColor: "#00756a" }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
              <>
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey="test_id"
                  pagination={false}
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record.test_id)
                  })}
                  className="custom-table"
                  scroll={{
                    x: 1300
                  }}
                  bordered
                />
                <Pagination
                  className="text-center align-content-center mt-3"
                  current={currentPage}
                  total={totalTest}
                  pageSize={pageSize}
                  onChange={handlePageChange}
                  showSizeChanger
                  pageSizeOptions={[5, 10, 20, 50, 100]}
                  showTotal={(total) => `Total ${total} tests`}
                />
              </>
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
};
