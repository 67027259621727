import React from "react";
import AssignBadge from "../modal/AssignBadge";

const GetAssignBadgeData = ({ jobDetails }) => {
  const defaultData = {
    job_id: jobDetails?.job?.job_id,
    assign_type: "job"
  };

  const isErrorResponse =
    jobDetails?.assign_to &&
    typeof jobDetails?.assign_to === "object" &&
    jobDetails?.assign_to.success === false &&
    jobDetails?.assign_to.message &&
    jobDetails?.assign_to.statusCode === 404;

  const dataToPass = isErrorResponse ? defaultData : jobDetails?.assign_to;

  return (
    <AssignBadge data={dataToPass} client_id={jobDetails?.client?.client_id} />
  );
};

export default GetAssignBadgeData;
