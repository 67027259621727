import {
  getClientReportConstants,
  getTeamReportConstants,
  getTimeLineReportsConstants,
} from "../../constants";

export function getClientReportAction(data) {
  return {
    type: getClientReportConstants.GET_CLIENT_REPORT_REQUEST,
    data,
  };
}
export function getTeamReportAction(data) {
  return {
    type: getTeamReportConstants.GET_TEAM_REPORT_REQUEST,
    data,
  };
}
export function getTimeLineReportsAction(data) {
  return {
    type: getTimeLineReportsConstants.GET_TIMELINE_REPORTS_REQUEST,
    data,
  };
}
