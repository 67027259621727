import React from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { experienced, job_type } from "../../../../../Assets/data/Job";
import ClientCustomSelect from "../../../../../Utils/ClientSelect/clientSelect";
import { isMentorpalTeamUser } from "../../../../../Utils/Common";

const { Option } = Select;

const RoleDetailsStep = ({ form, initialData }) => {
  return (
    <div className="container">
      <h2>Step 1: Assessment Details</h2>
      <Form
        form={form}
        layout="vertical"
        name="RoleDetailsStep"
        initialValues={initialData}
      >
        {/* Responsive row for title and job role */}
        <Row gutter={[16, 16]} className="my-16">
          <Col xs={24} md={12}>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please enter a title" }]}
            >
              <Input size="large" placeholder="Enter a assessment title" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Job role"
              name="assessment_role"
              rules={[{ required: true, message: "Please enter a job role" }]}
            >
              <Input size="large" placeholder="Enter job role" />
            </Form.Item>
          </Col>
        </Row>

        {/* Responsive row for work arrangement and experience */}
        <Row gutter={[16, 16]} className="my-16">
          <Col xs={24} md={12}>
            <Form.Item
              label="Work arrangement"
              name="work_arrangement"
              rules={[{ required: true, message: "Please select a work arrangement" }]}
            >
              <Select size="large" placeholder="Select a work arrangement">
                {job_type?.map((item) => (
                  <Option key={item.label} value={item.label}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Work Experience"
              name="work_experience"
              rules={[{ required: true, message: "Please select a work experience" }]}
            >
              <Select size="large" mode="multiple" placeholder="Select work experience">
                {experienced?.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Conditional rendering for Mentorpal team users */}
        {isMentorpalTeamUser() && (
          <Row gutter={[16, 16]} className="my-16">
            <Col xs={24} md={12}>
              <Form.Item
                label="Select Client"
                name="client_id"
                rules={[{ required: true, message: "Please select a Client" }]}
              >
                <ClientCustomSelect
                  type="form"
                  value={form.getFieldValue("client_id") || ""}
                  onChange={(value) => form.setFieldValue("client_id", value)}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      <div className="py-40" />
    </div>
  );
};

export default RoleDetailsStep;
