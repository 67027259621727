import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Divider,
  Input,
  Dropdown,
  Table,
  message,
  Select,
  Modal,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, MoreOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionDetailAction,
  updateQuestionAction,
} from "../../../redux/actions/assessment";
import { TailSpin } from "react-loader-spinner";
import { QuillTextPreview } from "../../../Utils/TextEditor";
import { Editor } from "@monaco-editor/react";
import { DOCUMENT_STATUS } from "../data";

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const DetailedQuestionPage = () => {
  const editorRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const question_id = queryParams.get("question_id");

  useEffect(() => {
    dispatch(getQuestionDetailAction({ question_id }));
    return () => {
      dispatch({ type: "GET_QUESTION_RESET" });
    };
  }, [dispatch, question_id]);

  const getQuestionDetailsReducer = useSelector(
    (state) => state.getQuestionDetailsReducer
  );
  const updateQuestionReducer = useSelector(
    (state) => state.updateQuestionReducer
  );

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { loading, data, error } = getQuestionDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data.data);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getQuestionDetailsReducer]);

  useEffect(() => {
    const { loading, data, error } = updateQuestionReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      if (data?.data?.status === "delete") {
        message.success("Question deleted!");
        navigate("/dashboard/questions");
      } else {
        message.success(data?.message);
      }
    }
    if (!loading && !data && error) {
      console.warn("error", error);
      message.error(error);
    }
  }, [updateQuestionReducer]);

  const renderField = (type) => {
    switch (type) {
      case "code":
      case "approx":
        return (
          <>
            <Title level={4}>Languages</Title>
            <Text>{data?.languages}</Text>

            <Divider />
            <Title level={4}>Code Stubs</Title>
            <Editor
              height="50vh"
              theme="vs-dark"
              language={data?.languages}
              onMount={(editor) => {
                editorRef.current = editor;
                editor.focus();
              }}
              value={data?.codeStubs}
              options={{
                minimap: {
                  enabled: false,
                },
              }}
            />
            <Divider />
          </>
        );
      case "design":
        return (
          <>
            <Title level={4}>HTML</Title>
            <Editor
              height="50vh"
              theme="vs-dark"
              language={data.language}
              onMount={(editor) => {
                editorRef.current = editor;
                editor.focus();
              }}
              options={{
                minimap: {
                  enabled: false,
                },
              }}
              value={data?.codeStubs?.html}
            />
            <Divider />
            <Title level={4}>CSS</Title>
            <Editor
              height="50vh"
              theme="vs-dark"
              language={data.language || "javascript"}
              onMount={(editor) => {
                editorRef.current = editor;
                editor.focus();
              }}
              options={{
                minimap: {
                  enabled: false,
                },
              }}
              value={data?.codeStubs?.css}
            />
            <Divider />
            <Title level={4}>JavaScript</Title>
            <Editor
              height="50vh"
              theme="vs-dark"
              language={data.language || "javascript"}
              onMount={(editor) => {
                editorRef.current = editor;
                editor.focus();
              }}
              options={{
                minimap: {
                  enabled: false,
                },
              }}
              value={data?.codeStubs?.js}
            />
          </>
        );
      case "database":
        return (
          <>
            <Title level={4}>Database Setup</Title>
            <Text>
              <strong>Type:</strong> {data?.databaseSetup.databaseType}
            </Text>
            <Divider />
            <Text>
              <strong>Table IDE:</strong> {data?.databaseSetup.tableIDE}
            </Text>
            <Divider />
            <Text>
              <strong>Expected Output:</strong>{" "}
              {data?.databaseSetup.expectedOutput}
            </Text>
          </>
        );
      case "mcq":
        return (
          <>
            <Title level={4}>Choices</Title>
            <Table
              columns={[
                {
                  title: "Choices",
                  dataIndex: "index",
                  key: "index",
                  render: (text, record, index) => `Choice- ${index + 1}`,
                },
                {
                  title: "Value",
                  dataIndex: "value",
                  key: "value",
                },
              ]}
              dataSource={data?.mcqChoices.map((choice, index) => ({
                ...choice,
                index,
              }))}
              pagination={false}
              bordered
            />
          </>
        );
        case "questionnaire":
        return (
          <>
            <Title level={4}>Choices</Title>
            <Table
              columns={[
                {
                  title: "Choices",
                  dataIndex: "index",
                  key: "index",
                  render: (text, record, index) => `Choice- ${index}`,
                },
                {
                  title: "Value",
                  dataIndex: "value",
                  key: "value",
                },
                {
                  title: "Marks",
                  dataIndex: "score",
                  key: "score",
                },
              ]}
              dataSource={data?.questionnaireChoices.map((choice, index) => ({
                ...choice,
                key: index,
              }))}
              pagination={false}
              bordered
            />
          </>
        );
      case "textAns":
        return <TextArea rows={4} value={data?.problemDescription} readOnly />;
      case "whiteboard":
        return <TextArea rows={4} value={data?.whiteboard} readOnly />;
      case "diagram":
        return <TextArea rows={4} value={data?.diagram} readOnly />;
      case "complete":
        return <TextArea rows={4} value={data?.completionString} readOnly />;
      case "file_upload":
        return (
          <Text>
            {data?.file_upload
              ? "File upload required"
              : "No file upload required"}
          </Text>
        );
      default:
        return null;
    }
  };

  const [statusValue, setStatusValue] = useState(data?.status);

  const handleChangeStatus = (value) => {
    setStatusValue(value);

    Modal.confirm({
      title: "Confirm Status Change",
      content: `Are you sure you want to change the status to "${value}"?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(
          updateQuestionAction({
            question_id: question_id,
            status: value,
          })
        );
      },
      onCancel: () => {
        setStatusValue(null);
      },
    });
  };

  const onItemFunction = (type) => {
    switch (type) {
      case "edit":
        navigate(
          `/dashboard/question?question_id=${question_id}&questionType=${data?.questionType}`
        );
        break;
      case "view":
        message.info("Features Coming soon");
        break;
      case "delete":
        Modal.confirm({
          title: "Confirm Status Change",
          content: `Are you sure you want to change the status to delete?`,
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            dispatch(
              updateQuestionAction({
                question_id: question_id,
                status: "delete",
              })
            );
          },
          onCancel: () => {
            message.info("action cancel!");
          },
        });
        break;
      default:
        message.error("event not found");
        break;
    }
  };

  const items = [
    {
      key: "1",
      label: <a onClick={() => onItemFunction("edit")}>Edit</a>,
    },
    {
      key: "2",
      label: <a onClick={() => onItemFunction("view")}>View</a>,
    },
    {
      key: "3",
      label: <a onClick={() => onItemFunction("delete")}>Delete</a>,
    },
  ];

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <Card
          className="m-2"
          title={
            <Row justify="space-between" align="middle">
              <Col>
                <ArrowLeftOutlined
                  onClick={() => navigate("/dashboard/questions")}
                  style={{ cursor: "pointer", marginRight: 10 }}
                />
                Question: {data?.questionName}
              </Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <Select
                    size="large"
                    value={statusValue || data?.status}
                    onChange={handleChangeStatus}
                    placeholder="Update status"
                    style={{ width: "200px" }} // Fixed width for the Select component
                  >
                    {DOCUMENT_STATUS?.map((item, index) => (
                      <Option value={item?.value} key={index}>
                        {item?.label}
                      </Option>
                    ))}
                  </Select>

                  <Dropdown menu={{ items }} placement="bottomRight">
                    <Button shape="circle" icon={<MoreOutlined />} />
                  </Dropdown>
                </div>
              </Col>
            </Row>
          }
          bordered={false}
          hoverable={true}
          style={{
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            marginBottom: "20px",
            borderRadius: 8,
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Title level={4}>Problem Description</Title>
              <QuillTextPreview content={data?.problemDescription} />
              <Divider />
              <Title level={4}>Recommended Time</Title>
              <Text>{data?.recommendedTime} mins</Text>
              {data?.tags && data?.tags.length > 0 && (
                <>
                  <Divider />
                  <Title level={4}>Tags</Title>
                  {data?.tags?.map((item) => (
                    <Text key={item}>{item}, </Text>
                  ))}
                </>
              )}
              <Divider />

              {data?.questionType === "code" && (
                <>
                  <Divider />
                  <Title level={4}>Test Cases</Title>

                  <Table
                    columns={[
                      {
                        title: "S.No",
                        dataIndex: "index",
                        key: "index",
                        render: (text, record, index) => index + 1,
                      },
                      {
                        title: "Input",
                        dataIndex: "input",
                        key: "input",
                      },
                      {
                        title: "Output",
                        dataIndex: "output",
                        key: "output",
                      },
                    ]}
                    dataSource={data?.testCases.map((item, index) => ({
                      ...item,
                      index,
                    }))}
                    pagination={false}
                    bordered
                  />
                </>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              {renderField(data?.questionType)}
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default DetailedQuestionPage;
