import React from "react";
import { Card, Button, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

const TestCompletion = ({ currentTest, handleCompletionSubmit }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "20px",
        background: "#f0f2f5",
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: "600px",
          margin: "0 auto",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <Paragraph>
          <b> You have completed the test. </b>
        </Paragraph>
        <Paragraph>Take a moment to relax before you continue.</Paragraph>
        <Button
          type="primary"
          onClick={() => handleCompletionSubmit()}
          className="custom-button"
          size="large"
        >
          Start next test
        </Button>
      </Card>
    </div>
  );
};

export default TestCompletion;
