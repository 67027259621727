import React from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { QUESTION_TYPE, TagsAssessment, TEST_LEVELS } from "../../data";
import { QuillTextEditor } from "../../../../Utils/TextEditor";

const { Option } = Select;
const { TextArea } = Input;

const BasicDetails = ({ form, initialData }) => {
  return (
    <div style={{ padding: "16px" }}>
      <h2 style={{ textAlign: "center" }}>Step 1: Test Details</h2>
      <Form
        form={form}
        layout="vertical"
        name="BasicDetailsStep"
        initialValues={initialData}
      >
        <Row gutter={[16, 16]} className="mb-5">
          <Col xs={24} sm={24} md={12} >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please enter a test title" }]}
            >
              <Input size="large" placeholder="Enter a test title" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true, message: "Please select a test type" }]}
            >
              <Select size="large" placeholder="Select a test type">
                {QUESTION_TYPE?.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mb-5">
          <Col xs={24} sm={24} md={12} >
            <Form.Item
              label="Level"
              name="level"
              rules={[{ required: true, message: "Please select a test level" }]}
            >
              <Select size="large" placeholder="Select a test level">
                {TEST_LEVELS?.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Tags" name="tags">
              <Select
                mode="tags"
                size="large"
                placeholder="Select or add tags"
                style={{ width: "100%" }}
              >
                {TagsAssessment?.map((tag) => (
                  <Option key={tag} value={tag}>
                    {tag}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Please enter a description!" }]}
            >
              <QuillTextEditor
                form={form}
                field={{ name: "description" }}
                style={{ maxHeight: "200px" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="py-40" />
    </div>
  );
};

export default BasicDetails;
