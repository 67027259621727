import React, { useState, useEffect } from "react";
import { Alert, Input, Button, Space, message, Card } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const TestCaseInput = ({ form ,data}) => {
  const [testCases, setTestCases] = useState([]);

  const handleAddTestCase = () => {
    setTestCases([...testCases, { id: Date.now(), input: "", output: "" }]);
  };

  const handleRemoveTestCase = (id) => {
    if (testCases.length > 1) {
      setTestCases(testCases.filter((tc) => tc.id !== id));
    } else {
      message.error("At least one test case is required.");
    }
  };

  const handleTestCaseInputChange = (id, field, value) => {
    setTestCases(
      testCases.map((tc) => (tc.id === id ? { ...tc, [field]: value } : tc))
    );
  };

  useEffect(() => {
    setTestCases(data?.testCases);
  }, [data]);

  useEffect(() => {
    const existingTestCases = form.getFieldValue("testCases") || [];
    setTestCases(existingTestCases);
  }, [form]);

  return (
    <>
      <Alert
        message="Test Case Instructions"
        description={
          <>
            <p>Please follow these instructions to create and manage test cases:</p>
            <ol>
              <li>Enter the input and expected output for each test case.</li>
              <li>To add a new test case, click the <strong>'Add Test Case'</strong> button.</li>
              <li>To remove a test case, click the <strong>minus icon</strong> next to it. At least one test case must remain.</li>
            </ol>
          </>
        }
        type="info"
        showIcon
      />
      <div className="py-4">
        {testCases.map((testCase, index) => (
          <Card
            key={testCase.id}
            title={`Test Case ${index + 1}`}
            bordered={false}
            className="card"
          >
            <Space className="space" direction="vertical" style={{ width: '100%' }}>
              <Input
                placeholder="Input"
                value={testCase.input}
                onChange={(e) =>
                  handleTestCaseInputChange(testCase.id, "input", e.target.value)
                }
                className="input"
                size="large"
              />
              <Input
                placeholder="Output"
                value={testCase.output}
                onChange={(e) =>
                  handleTestCaseInputChange(testCase.id, "output", e.target.value)
                }
                size="large"
              />
              {testCases.length > 1 && (
                <MinusCircleOutlined
                  onClick={() => handleRemoveTestCase(testCase.id)}
                  className="minus-icon"
                  style={{ fontSize: '24px', color: 'red' }}
                />
              )}
            </Space>
          </Card>
        ))}
        <Button
          className="add-test-case-button"
          type="dashed"
          onClick={handleAddTestCase}
          style={{ width: "100%", marginTop: '16px' }}
          icon={<PlusOutlined />}
        >
          Add Test Case
        </Button>
      </div>
    </>
  );
};

export default TestCaseInput;
