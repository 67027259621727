import {
  getAllScheduleInterviewListConstants,
  getInterviewCalendarConstants,
  getInterviewDetailsConstants,
  getInterviewerListConstants,
  getInterviewerReportConstants,
  interviewFeedbackConstants,
  scheduleInterviewConstants,
  updateInterviewDetailsConstants
} from "../../constants";

export const getInterviewerListReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getInterviewerListConstants.GET_INTERVIEWER_LIST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getInterviewerListConstants.GET_INTERVIEWER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getInterviewerListConstants.GET_INTERVIEWER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getInterviewerListConstants.GET_INTERVIEWER_LIST_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getInterviewDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getInterviewDetailsConstants.GET_INTERVIEW_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getInterviewDetailsConstants.GET_INTERVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getInterviewDetailsConstants.GET_INTERVIEW_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getInterviewDetailsConstants.GET_INTERVIEW_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getAllScheduleInterviewListReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAllScheduleInterviewListConstants.GET_SCHEDULE_INTERVIEW_LIST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAllScheduleInterviewListConstants.GET_SCHEDULE_INTERVIEW_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getAllScheduleInterviewListConstants.GET_SCHEDULE_INTERVIEW_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getAllScheduleInterviewListConstants.GET_SCHEDULE_INTERVIEW_LIST_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const scheduleInterviewReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case scheduleInterviewConstants.SCHEDULE_INTERVIEW_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case scheduleInterviewConstants.SCHEDULE_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case scheduleInterviewConstants.SCHEDULE_INTERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case scheduleInterviewConstants.SCHEDULE_INTERVIEW_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const interviewFeedbackReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case interviewFeedbackConstants.INTERVIEW_FEEDBACK_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case interviewFeedbackConstants.INTERVIEW_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case interviewFeedbackConstants.INTERVIEW_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case interviewFeedbackConstants.INTERVIEW_FEEDBACK_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getInterviewerReportReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getInterviewerReportConstants.GET_INTERVIEWER_REPORT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getInterviewerReportConstants.GET_INTERVIEWER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getInterviewerReportConstants.GET_INTERVIEWER_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getInterviewerReportConstants.GET_INTERVIEWER_REPORT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateInterviewDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getInterviewCalendarReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getInterviewCalendarConstants.GET_INTERVIEW_CALENDAR_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getInterviewCalendarConstants.GET_INTERVIEW_CALENDAR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getInterviewCalendarConstants.GET_INTERVIEW_CALENDAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getInterviewCalendarConstants.GET_INTERVIEW_CALENDAR_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
