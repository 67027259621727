import React from "react";
import { Button, Form, Input, Modal } from "antd";

const TitleUpdateModal = ({ titleEditModal, setTitleEditModal, form }) => {
  const handleOk = () => {
    form
      .validateFields(["title"])
      .then((values) => {
        form.setFieldsValue(values);

        setTitleEditModal(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setTitleEditModal(false);
  };

  return (
    <Modal
      title="Edit Title"
      visible={titleEditModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{ backgroundColor: "#00756a" }}
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please input the title!" }]}
        >
          <Input size="large" placeholder="Enter title" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TitleUpdateModal;
