import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const TermsOfUse = () => {
  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "white" }}>
      <Content
        className="container mt-5 "
        style={{
          fontFamily: "'Inter', sans-serif",
          fontWeight: 400,
          color: "#262626",
          paddingLeft: "10%",
          paddingRight: "10%"
        }}
      >
        <div className="text-start">
          <h2
            className="fw-bold mb-3"
            style={{
              fontSize: "2rem",
              color: "#262626",
              fontWeight: 700
            }}
          >
            Customer Terms of Use
          </h2>
          <p style={{ fontSize: "1rem", color: "#888", fontWeight: 400 }}>
            Version v3 – January 2024
          </p>
        </div>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#666666",
            lineHeight: "1.8"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontSize: "20px",
              fontWeight: 600
            }}
          >
            Introduction
          </h4>
          <p className="mb-4">
            These MentorPal Terms of Service (hereafter the{" "}
            <strong>“Terms”</strong>), outline the terms regarding your use of
            the Services and are an integral part of the agreement between you
            (“<strong>you</strong>”, “<strong>your</strong>”, or “
            <strong>Customer</strong>”) and MentorPal (“
            <strong>MentorPal</strong>”, “<strong>us</strong>”, “
            <strong>we</strong>”, or “<strong>our</strong>”). You or MentorPal
            may also be referred to individually as a “<strong>Party</strong>”
            and together as “<strong>Parties</strong>” in these Terms. An “
            <strong>Affiliate</strong>” means any company or other entity,
            whether or not a legal person, which directly or indirectly
            controls, is controlled by, or is under joint control with that
            person. For the purpose of this definition, “
            <strong>control</strong>” means (a) any direct or indirect ownership
            of over 50% of the ownership, or (b) in the absence of such
            ownership interest, the power to direct or cause the direction of
            the management and set the policies of such company or entity.
          </p>
          <p className="mb-4">
            These Terms are a legally binding contract between you and
            MentorPal. If you do not agree with these Terms, do not register for
            a MentorPal account, or purchase or use Services. By using or
            accessing the Services, or registering for a MentorPal account, you
            agree to be bound by these Terms. If you use the Services on behalf
            of an entity, you agree to these Terms for that entity and represent
            and warrant to MentorPal that you have the authority to bind that
            entity to these Terms, unless that entity has a separate paid
            contract in effect with MentorPal, in which event the separate paid
            contract governs your use of the Services.
          </p>
          <p className="mb-4">
            In these Terms, we refer collectively to these Terms, the Data
            Processing Agreement (attached as a separate annex to these Terms),
            the Service Level Agreement (<strong>SLA</strong>), the Security
            Measures, and the Cookie Policy (jointly the{" "}
            <strong>“Documentation”</strong>) available on our website (“
            <strong>Website</strong>”) and applicable ordering documents (“
            <strong>Order Form</strong>”, as defined below) as the “
            <strong>Agreement</strong>.”
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            1. Grant of Access and Use
          </h4>
          <p className="mb-4">
            <strong>1.1.</strong> Subject to the terms and conditions of the
            Agreement (including our receipt of applicable fees), MentorPal
            hereby grants to you and you accept a limited, personal,
            non-exclusive, non-sublicensable, non-transferable, and
            non-assignable license to access and use the Services solely for
            your own internal assessment and (direct) recruitment purposes
            (unless explicitly agreed in writing otherwise).
          </p>
          <p className="mb-4">
            <strong>1.2.</strong> Access and use of the Platform and Services is
            provided to any number of individuals taking the assessments or
            tests as provided as part of the Services pursuant to the Agreement
            in accordance with the applicable subscription plan (“
            <strong>Candidates</strong>”).
          </p>
          <p className="mb-4">
            <strong>1.3.</strong> Subject to prior written approval by
            MentorPal, your Affiliates may use the Services without entering
            into a separate Order Form by providing such Affiliate(s) a login ID
            and password to access and use the Services. The Agreement shall
            apply to each Affiliate with access to your account, and you are
            directly and primarily responsible for all access to and use of the
            Services by your Affiliates. References in these Terms to you, shall
            include a reference to your Affiliates.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            2. The Services
          </h4>
          <p className="mb-4">
            <strong>2.1.</strong> The “Services” include the MentorPal online
            talent assessment platform (“Platform”) and any other products and
            services provided by MentorPal that (a) have been applied for by the
            Customer under any applicable subscription plan (either via the
            Website or otherwise) that specifies pricing and other commercial
            terms (“Order Form”); or (b) are used by you. Our Services provided
            under this Agreement are not intended for personal or private
            individual use but are designed and intended for commercial use
            only.
          </p>
          <p className="mb-4">
            <strong>2.2.</strong> The Services provided under this Agreement
            shall be strictly used for internal or direct recruiting and job
            application procedures only. Any use of the Services by search
            agencies, recruiting agencies, or sourcing platforms for third-party
            screening purposes or search assignments on behalf of third parties
            is strictly forbidden unless the Parties have explicitly agreed
            otherwise in the Order Form. We reserve the right to immediately
            terminate your access to the Services and your account upon
            violation by you of this prohibited use of the Services under these
            Terms.
          </p>
          <p className="mb-4">
            <strong>2.3.</strong> MentorPal will provide the Services in
            accordance with (a) the terms of the Agreement; and (b) in
            accordance with the applicable laws; and (c) the Information
            Security Measures.
          </p>
          <p className="mb-4">
            <strong>2.4.</strong> We may provide the Services, or a portion
            thereof, to you through our Affiliates in accordance with these
            Terms and any applicable Order Form(s). MentorPal shall always
            remain responsible for any Services or part thereof provided by our
            Affiliates.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            3. Your Account
          </h4>
          <p className="mb-4">
            <strong>3.1.</strong> In order to use our Services, it is required
            to create an account. To create an account, you (i) must select the
            applicable subscription plan based on your company size (e.g., the
            number of employees employed by your company including any
            Affiliates using the Services, if applicable); and (ii) complete the
            online registration procedure by providing the requested details.
            You represent and warrant that all information provided during the
            registration process and related to your account is accurate,
            current, and complete. You agree to update such information promptly
            if it changes.
          </p>
          <p className="mb-4">
            <strong>3.2.</strong> You are responsible for maintaining the
            confidentiality of your login credentials and other account details
            and for all activities that occur under your account. You agree to
            notify us immediately if you become aware of any unauthorized use of
            your account or any other breach of security.
          </p>
          <p className="mb-4">
            <strong>3.3.</strong> MentorPal reserves the right to suspend or
            terminate your account and access to the Services if you breach any
            of these Terms or if we reasonably believe that your use of the
            Services violates any applicable laws or regulations.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            4. Intellectual Property
          </h4>
          <p className="mb-4">
            <strong>4.1.</strong> All intellectual property rights in the
            Services and the Platform, including but not limited to trademarks,
            copyrights, and patents, are owned by MentorPal or its licensors.
            You acknowledge that you do not acquire any ownership rights in the
            Services or the Platform by using them.
          </p>
          <p className="mb-4">
            <strong>4.2.</strong> You may not use, copy, reproduce, modify,
            distribute, transmit, display, perform, or create derivative works
            based on the Services or the Platform without our prior written
            consent, except as expressly permitted in these Terms.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            5. Limitation of Liability
          </h4>
          <p className="mb-4">
            <strong>5.1.</strong> To the fullest extent permitted by applicable
            law, MentorPal shall not be liable for any indirect, incidental,
            special, consequential, or punitive damages arising out of or in
            connection with your use of the Services, including but not limited
            to loss of profits, data, or goodwill.
          </p>
          <p className="mb-4">
            <strong>5.2.</strong> MentorPal’s total liability to you for any
            claim arising out of or relating to the Agreement shall not exceed
            the amount paid by you for the Services during the twelve (12)
            months preceding the date of the claim.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            6. Governing Law and Dispute Resolution
          </h4>
          <p className="mb-4">
            <strong>6.1.</strong> These Terms shall be governed by and construed
            in accordance with the laws of the State of New York, United States
            of America, without regard to its conflict of laws principles.
          </p>
          <p className="mb-4">
            <strong>6.2.</strong> Any disputes arising out of or relating to
            these Terms or the Services shall be resolved exclusively in the
            courts located in New York County, New York, United States of
            America.
          </p>
        </section>
        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            7. Free Plan and Beta Products
          </h4>
          <p className="mb-4">
            <strong>7.1.</strong> We may invite you to test out new or
            additional products or features of our Services that are not
            generally available to all customers (“Beta Products”) or use our
            Services free of charge (“Free Plan Products”).
          </p>
          <p className="mb-4">
            <strong>7.2.</strong> Beta Products and Free Plan Products may
            contain errors and are provided for limited evaluation only.
          </p>
          <p className="mb-4">
            <strong>7.3.</strong> If you have access to Free Plan Products, we
            will make the Services available to you on a trial basis, free of
            charge, until the earlier of (a) the end of the free trial period,
            or (b) the start date of any purchased Service subscriptions ordered
            by you for such Services.
          </p>
          <p className="mb-4">
            <strong>7.4.</strong> Beta Products and Free Plan Products are
            provided “AS IS” without warranty of any kind, whether express,
            implied, statutory, or otherwise. MentorPal specifically disclaims
            all implied warranties of merchantability, noninfringement, and
            fitness for a particular purpose in relation to Beta Products and
            Free Plan Products.
          </p>
          <p className="mb-4">
            <strong>7.5.</strong> MentorPal does not have any obligation to
            provide Beta Products and Free Plan Products to any customer or to
            our general customer base. MentorPal is entitled to terminate or
            discontinue a Beta Product or Free Plan Product at any moment.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            8. Fees and Payment Terms
          </h4>
          <p className="mb-4">
            <strong>8.1.</strong> Unless agreed otherwise in writing by the
            Parties in the Order Form, you shall pay MentorPal all fees in
            accordance with the applicable subscription plan as published on the
            Website or as agreed in the Order Form (the "Fees"). The
            subscription plans are based on different bandwidths measured by the
            number of employees of your company (including any Affiliates that
            will be using the Services).
          </p>
          <p className="mb-4">
            <strong>8.2.</strong> Subscription plans have a standard duration of
            either twelve (12) months or monthly (‘lite plan’), unless
            explicitly agreed otherwise, and are paid in advance annually or
            monthly, depending on your selection in the Order Form or the
            Website either for annual or monthly payment.
          </p>
          <p className="mb-4">
            <strong>8.3.</strong> All payment obligations are non-cancelable,
            and Fees and taxes, once paid, are non-refundable. Except as
            otherwise set forth in the applicable Order Form(s) and subject to
            Section 8.9 (payment disputes), you will pay the Fees due under
            these Terms in accordance with the following applicable payment
            method: (a) if you elect to remit the Fees using a credit card or
            PayPal, you represent and warrant that you are authorized to use
            that credit card or PayPal account, that any and all Fees may be
            billed to that credit card or PayPal account, and that payment of
            such Fees will not be declined; or (b) if you elect to receive
            invoices and MentorPal approves you for the same, invoices will be
            sent to you at the frequency set forth in the applicable Order Form
            and you will pay the Fees due within twenty (20) days of the date of
            the invoice.
          </p>
          <p className="mb-4">
            <strong>8.4.</strong> If you have selected payment by credit card
            with payment in monthly installments, your credit card will be
            charged once a month. If your credit card cannot be charged,
            MentorPal will notify you and you will need to update your payment
            information. In the event you do not update your payment information
            within twenty (20) days of MentorPal’s notice, your access to the
            Service may be suspended and you will need to update your card
            information in order to resume use of the Services. There will be no
            refunds or credits for partial months of service.
          </p>
          <p className="mb-4">
            <strong>8.5.</strong> You shall provide MentorPal with accurate and
            complete billing information including full name, address, state,
            zip code, country, telephone number, and a valid VAT or applicable
            tax registration number and notify us of any changes to such
            information. By submitting the payment information, you
            automatically authorize MentorPal to charge all Fees incurred
            through your account to any such selected payment instruments.
          </p>
          <p className="mb-4">
            <strong>8.6.</strong> Your use of the Services is based on your
            company size which determines the applicable subscription plan
            listed in the Order Form unless another basis of the cost has been
            explicitly agreed. MentorPal is entitled to verify at any time
            whether you have submitted the correct information regarding the
            selected subscription plan. If in MentorPal’s reasonable opinion it
            appears you have submitted incorrect information of the number of
            employees employed by you and your company size is in excess of the
            selected subscription plan, MentorPal reserves the right, without
            prior consultation and at its sole discretion, to disable your
            access to the Services or terminate your account with immediate
            effect, or upgrade your subscription plan in accordance with your
            company size. Any additional or increased Fees in connection with an
            upgrade in subscription plans will be due in accordance with this
            Section 8 (Fees and Payment Terms) of these Terms and billed in
            accordance with the next payment cycle.
          </p>
          <p className="mb-4">
            <strong>8.7.</strong> All Fees and other amounts payable by you
            under the Agreement are exclusive of taxes, duties, levies and
            similar assessments as well as any other costs including transaction
            costs or bank transfer fees. Without limiting the foregoing, you are
            responsible for all sales, use, and excise taxes, and any other
            similar taxes, duties, and charges of any kind imposed by any
            governmental or regulatory authority on any amounts payable by you
            hereunder, other than any corporate income taxes imposed on
            MentorPal's income.
          </p>
          <p className="mb-4">
            <strong>8.8.</strong> MentorPal is entitled to invoice you if for
            any reason the appropriate taxing authorities determine that you are
            not exempt from any taxes and MentorPal is required to pay such
            taxes. Any applicable penalties or interest that might be imposed
            will be added to such invoices. The Fees shall in no event be
            decreased by any taxes and/or fees of any nature owed by you in
            connection with your purchase of the Services.
          </p>
          <p className="mb-4">
            <strong>8.9.</strong> If you fail to make any payment when due then,
            in addition to all other remedies that may be available: (a)
            MentorPal may charge interest on the past due amount at the rate of
            1.5% per month of the value of the applicable Fees, calculated daily
            and compounded monthly or, if higher, the maximum rate permitted
            under applicable law; (b) You shall reimburse us for all costs
            incurred by MentorPal in collecting any late payments or interest,
            including attorneys' fees, court costs, and collection agency fees;
            and (c) If such failure continues for more than thirty (30) days
            following written notice thereof, we may suspend performance of the
            Services until all past due amounts, and interest thereon, have been
            paid, without incurring any obligation or liability to you or any
            other person by reason of such suspension.
          </p>
          <p className="mb-4">
            <strong>8.10.</strong> If you do not agree with any invoice for
            Fees, you must notify us in writing within ten (10) days of the date
            of the respective invoice, failure of which shall result in
            acceptance of the invoice and forfeiture of your right to dispute
            the invoice. All undisputed fees remain due according to schedule.
          </p>
          <p className="mb-4">
            <strong>8.11.</strong> All amounts payable to MentorPal under the
            Agreement shall be paid by you in full without any setoff,
            recoupment, counterclaim, deduction, debit, or withholding for any
            reason.
          </p>
          <p className="mb-4">
            <strong>8.12.</strong> We are entitled to increase the Fees annually
            after the Initial Term for any Renewal Term (as defined below) with
            a notice period of thirty (30) days before taking effect. If you do
            not agree to any change in the Fees, you are entitled to terminate
            the Agreement before the start of the Renewal Term. Your continued
            use of the Service after the modification of the Fees comes into
            effect constitutes your acceptance and agreement to pay the updated
            Fees.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            9. Term and Termination
          </h4>
          <p className="mb-4">
            <strong>9.1.</strong> The Agreement begins on the Effective Date and
            continues for the period specified in the Order Form (the "Initial
            Term") unless terminated earlier in accordance with the terms of the
            Agreement. Upon expiration of the Initial Term, the Agreement will
            automatically renew for successive periods of the same duration as
            the Initial Term (each a “Renewal Term”), unless either Party
            provides written notice of non-renewal at least thirty (30) days
            prior to the end of the Initial Term or then-current Renewal Term.
          </p>
          <p className="mb-4">
            <strong>9.2.</strong> Either Party may terminate the Agreement for
            convenience at any time by providing at least thirty (30) days
            written notice to the other Party. If the Agreement is terminated
            for convenience, you will be responsible for payment of all Fees for
            the remainder of the Initial Term or any applicable Renewal Term, as
            well as any other amounts due under the Agreement.
          </p>
          <p className="mb-4">
            <strong>9.3.</strong> Either Party may terminate the Agreement
            immediately upon written notice if (a) the other Party materially
            breaches any provision of the Agreement and fails to cure such
            breach within fifteen (15) days after receipt of written notice of
            such breach, or (b) the other Party becomes insolvent, makes an
            assignment for the benefit of creditors, or is the subject of a
            bankruptcy or similar proceeding.
          </p>
          <p className="mb-4">
            <strong>9.4.</strong> Upon termination or expiration of the
            Agreement for any reason, you will promptly cease all use of the
            Services, return or destroy all Confidential Information, and
            certify to MentorPal in writing that you have done so. MentorPal may
            immediately disable your access to the Services upon termination or
            expiration.
          </p>
          <p className="mb-4">
            <strong>9.5.</strong> Termination of the Agreement will not relieve
            you of your obligation to pay any Fees or other amounts due under
            the Agreement.
          </p>
          <p className="mb-4">
            <strong>9.6.</strong> Any provision of the Agreement that by its
            nature should survive termination or expiration of the Agreement
            shall so survive, including but not limited to Sections 4
            (Intellectual Property), 5 (Confidentiality), 8 (Fees and Payment
            Terms), 9 (Term and Termination), and 10 (Limitation of Liability).
          </p>
        </section>
        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            12. Limitation of Liability
          </h4>
          <p className="mb-4">
            <strong>12.1.</strong> To the fullest extent permitted by applicable
            law, each Party’s total liability for any direct loss, damages,
            costs, or expenses arising from this Agreement, whether based on
            strict liability, negligence, contract, or otherwise, shall be
            limited to the total aggregate fees paid by Customer to MentorPal
            during the twelve-month period immediately preceding the event
            giving rise to the claim.
          </p>
          <p className="mb-4">
            <strong>12.2.</strong> Neither Party shall be liable to the other
            for any consequential damages, including but not limited to loss of
            profits, sales, or data, or for any incidental, special, punitive,
            or contingent damages, regardless of the theory of liability, even
            if the Party was advised of the possibility of such damages.
          </p>
          <p className="mb-4">
            <strong>12.3.</strong> MentorPal shall not be liable for any damages
            or losses arising from misrepresentation or fraud by any Candidate
            using the Platform. Additionally, MentorPal is not liable for Beta
            Products or Free Plan Products, nor for any damage, destruction, or
            loss of data or documents (including Customer Data) resulting from
            the use of the Services.
          </p>
          <p className="mb-4">
            <strong>12.4.</strong> The limitations of liability in this Section
            do not apply to: (a) breaches of Section 4 (User Rights and
            Responsibilities); (b) breaches of Section 8 (Fees and Payment
            Terms); (c) indemnification obligations under Section 11
            (Indemnification); or (d) cases of gross negligence or willful
            misconduct.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            13. Free Plan and Beta Products
          </h4>
          <p className="mb-4">
            <strong>13.1.</strong> Each Party may have access to or be exposed
            to confidential information of the other Party, such as software,
            performance metrics, evaluations, customer ratings, product plans,
            pricing, and trade secrets, which may be designated as confidential
            or should be treated as confidential under the circumstances
            (collectively, “Confidential Information”).
          </p>
          <p className="mb-4">
            <strong>13.2.</strong> Confidential Information may only be shared
            with third parties if necessary for the performance of the
            Agreement, provided that such third parties agree in writing to
            maintain the confidentiality of such information. Each Party must
            take reasonable precautions to protect the Confidential Information
            of the other Party.
          </p>
          <p className="mb-4">
            <strong>13.3.</strong> The obligations regarding Confidential
            Information do not apply to information that: (a) was already known
            to the receiving party before disclosure; (b) becomes publicly
            available through no fault of the receiving party; or (c) is
            rightfully obtained from a third party without a confidentiality
            obligation. If disclosure is required by law, the receiving party
            must notify the disclosing party in advance to seek a protective
            order or remedy.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            14. Term and Termination
          </h4>
          <p className="mb-4">
            <strong>14.1.</strong> The Agreement begins on the date of
            acceptance or as specified in the Order Form and continues until all
            Order Forms or Services have expired or been terminated in
            accordance with these Terms.
          </p>
          <p className="mb-4">
            <strong>14.2.</strong> The initial subscription term (“Initial
            Term”) starts from when the Services are activated through the
            Website or as indicated in the applicable Order Form.
          </p>
          <p className="mb-4">
            <strong>14.3.</strong> Unless otherwise specified, your subscription
            will automatically renew for additional periods equal to the Initial
            Term (each, a “Renewal Term”) unless canceled by you or MentorPal.
            If you do not agree to the renewal, you may terminate the Agreement
            through the Website or by contacting the help center before the end
            of the Term. Continuing use of the Services after the renewal
            constitutes acceptance of the Renewal Term. Termination during the
            Term is only possible as outlined in this Section.
          </p>
          <p className="mb-4">
            <strong>14.4.</strong> Fees for any Renewal Term will be based on
            the current list price for the applicable subscription plan on the
            Website, unless a different renewal pricing is specified in the
            Order Form.
          </p>
          <p className="mb-4">
            <strong>14.5.</strong> Either Party may terminate the Agreement and
            any Order Form (in whole or in part) with at least ten (10) days'
            prior written notice if the other Party materially breaches the
            Agreement and fails to cure the breach within the ten (10) day
            notice period. MentorPal may also suspend services, close accounts,
            or prohibit creating new accounts in the event of a material breach
            by the Customer.
          </p>
          <p className="mb-4">
            <strong>14.6.</strong> Termination of the Agreement or Order Form
            does not relieve the Customer of payment obligations for Fees due
            before the termination date.
          </p>
          <p className="mb-4">
            <strong>14.7.</strong> Either Party may terminate this Agreement
            immediately if the other Party becomes insolvent or seeks relief
            under bankruptcy or similar proceedings.
          </p>
          <p className="mb-4">
            <strong>14.8.</strong> Upon expiration or termination: (a) MentorPal
            will invoice for any accrued but unbilled amounts; (b) Customer must
            cease use of the Services and return or destroy all related
            materials and data; (c) MentorPal will suspend access to Services;
            and (d) MentorPal will delete Customer Data and Confidential
            Information within sixty (60) days, except as required by law or for
            legal claims.
          </p>
          <p className="mb-4">
            <strong>14.9.</strong> Sections 8 (Fees and Payment Terms), 9
            (Intellectual Property and Data), 13 (Confidentiality), 10.7
            (Disclaimer), 11 (Indemnification), 12 (Limitation of Liability), 16
            (Miscellaneous), and the Data Processing Agreement shall survive
            termination or expiration.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            15. Amendments
          </h4>
          <p className="mb-4">
            <strong>15.1.</strong> MentorPal may amend these Terms periodically.
            We will attempt to notify you of significant changes via a website
            announcement or email. New Terms take effect immediately, and
            continued use of the Services constitutes acceptance of the updated
            Terms.
          </p>
          <p className="mb-4">
            <strong>15.2.</strong> If additional notice is required by law,
            changes will take effect either after the notice period expires or
            upon earlier acceptance. You may terminate the Agreement without a
            termination fee under applicable law, but any previously paid fees
            are non-refundable, and any outstanding fees remain due.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            16. Miscellaneous
          </h4>
          <p className="text-decoration-underline">
            <strong>16.1. Compliance with Law Assurances.</strong>
          </p>
          <p className="mb-4">
            Both Parties warrant compliance with applicable anti-corruption,
            anti-money laundering, sanctions, export controls, and international
            trade laws of the European Union, the United Kingdom, the United
            States, the United Nations, or other relevant authorities. Each
            Party will promptly notify the other of any actual or potential
            violations and take appropriate actions to resolve them.
          </p>

          <p className="text-decoration-underline">
            <strong>16.2. No Class Actions. </strong>
          </p>
          <p className="mb-4">
            All claims must be brought individually, and neither Party may
            participate in class actions or representative actions. Claims
            related to the Agreement must be brought in an individual capacity,
            and the Parties agree to seek to resolve disputes through individual
            legal proceedings only.
          </p>

          <p className="text-decoration-underline">
            <strong>16.3. Notices.</strong>
          </p>
          <p className="mb-4">
            Notices under this Agreement should be in writing and sent to the
            address specified in the Order Form or as otherwise notified.
            Notices to MentorPal should be sent to the address specified on the
            Website or as otherwise instructed.
          </p>

          <p className="text-decoration-underline">
            <strong>16.4. Assignment.</strong>
          </p>
          <p className="mb-4">
            Neither Party may assign its rights or obligations under this
            Agreement without prior written consent from the other Party, except
            in the case of a merger, acquisition, or sale of all or
            substantially all of its assets. Any purported assignment without
            consent is void.
          </p>

          <p className="text-decoration-underline">
            <strong>16.5. Severability.</strong>
          </p>
          <p className="mb-4">
            If any provision of this Agreement is found to be invalid or
            unenforceable, the remaining provisions will continue to be valid
            and enforceable. The invalid or unenforceable provision will be
            modified to reflect the Parties' original intent as closely as
            possible.
          </p>

          <p className="text-decoration-underline">
            <strong>16.6. Waiver.</strong>
          </p>
          <p className="mb-4">
            The failure to enforce any provision of this Agreement does not
            constitute a waiver of that provision or any other provision. Any
            waiver must be in writing and signed by the Party granting the
            waiver.
          </p>

          <p className="text-decoration-underline">
            <strong>16.7. Governing Law and Jurisdiction.</strong>
          </p>
          <p className="mb-4">
            This Agreement is governed by the laws of the State of Delaware,
            without regard to its conflict of laws principles. Any disputes
            arising from this Agreement shall be exclusively resolved in the
            courts of the State of Delaware, and each Party consents to the
            jurisdiction of those courts.
          </p>

          <p className="text-decoration-underline">
            <strong>16.8. Entire Agreement.</strong>
          </p>
          <p className="mb-4">
            This Agreement, including any Order Forms, constitutes the entire
            agreement between the Parties concerning the subject matter and
            supersedes all prior agreements and understandings, whether written
            or oral, relating to the subject matter.
          </p>

          <p className="text-decoration-underline">
            <strong>16.9. Execution.</strong>
          </p>
          <p className="mb-4">
            This Agreement may be executed electronically or in counterpart
            copies, all of which together constitute a single instrument.
          </p>

          <p className="text-decoration-underline">
            <strong>16.10. Headings.</strong>
          </p>
          <p className="mb-4">
            Headings in this Agreement are for convenience only and do not
            affect the interpretation of the Agreement.
          </p>

          <p className="text-decoration-underline">
            <strong>16.11. Force Majeure.</strong>
          </p>
          <p className="mb-4">
            Neither Party is liable for delays or failures in performance due to
            causes beyond its reasonable control, including acts of God, natural
            disasters, pandemics, or government actions. The affected Party must
            promptly notify the other Party of such conditions and use
            reasonable efforts to mitigate the impact.
          </p>
        </section>
      </Content>
    </Layout>
  );
};

export default TermsOfUse;
