import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientDetailsAction,
  updateClientDetailsAction
} from "../../redux/actions/common";
import {
  Avatar,
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Tabs,
  Typography,
  Divider,
  Card,
  Row,
  Col,
  Table,
  Input,
  Space
} from "antd";
import {
  MoreOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import JobsListing from "../Job/JobsListing";

const { confirm } = Modal;
const { Title, Text, Link } = Typography;

export const ClientProfile = ({ selectedClient }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const client_id = queryParams.get("client_id");

  const [data, setData] = useState(null);
  const [jobsData, setJobsData] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateClientDetailsReducer = useSelector(
    (state) => state.updateClientDetailsReducer
  );
  const getClientDetailsReducer = useSelector(
    (state) => state.getClientDetailsReducer
  );

  useEffect(() => {
    if (client_id) {
      dispatch(getClientDetailsAction(client_id));
    }
    return () => {
      dispatch({ type: "GET_CLIENT_DETAIL_RESET" });
    };
  }, [dispatch, client_id]);

  useEffect(() => {
    const { loading, data, error } = getClientDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.client);
      setJobsData(data?.job_stats || []);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getClientDetailsReducer]);

  useEffect(() => {
    const { loading, data, error } = updateClientDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      if (data?.client?.status === "delete") {
        message.success("Client deleted!");
        navigate("/dashboard/client-dashboard");
      }
    }
    if (!loading && !data && error) {
      message.error("error", error);
    }
  }, [updateClientDetailsReducer]);

  const handleAction = (type) => {
    switch (type) {
      case "CARRIER_PAGE":
        navigate(`/carrier-page/${client_id}`);
        break;
      case "REMOVE_CLIENT":
        Modal.confirm({
          title: "Are you sure you want to delete this client?",
          icon: <ExclamationCircleOutlined />,
          content: "This action will apply changes immediately.",
          okText: "Yes, Update",
          cancelText: "No, Cancel",
          onOk() {
            dispatch(
              updateClientDetailsAction({
                client_id: client_id,
                status: "delete"
              })
            );
          }
        });

        break;
      case "UPDATE_CLIENT":
        navigate(`/dashboard/update-client?client_id=${client_id}`);
        break;
      default:
        message.info(`Feature coming soon: ${type}`);
    }
  };

  const menuItems = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleAction("CARRIER_PAGE")}>
        Carrier Page
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleAction("UPDATE_CLIENT")}>
        Update Client
      </Menu.Item>
    </Menu>
  );

  const isMobile = window.innerWidth <= 768;

  const columns = [
    {
      title: "S.No",
      dataIndex: "key",
      key: "key",
      sorter: (a, b) => a.key - b.key,
      fixed: !isMobile ? "left" : undefined,
      width: 70
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      render: (text, record) => (
        <a
          className="text-decoration-underline"
          style={{ cursor: "pointer", color: "#005c53" }}
          onClick={() => navigate(`/dashboard/job-detail/${record.job_id}`)}
        >
          {text}
        </a>
      ),
      fixed: !isMobile ? "left" : undefined,
      width: 250,
      sorter: (a, b) => a.job_title.localeCompare(b.job_title),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Job Title"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#28a745", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.job_title.toLowerCase().includes(value.toLowerCase())
    },
    {
      title: "Total Candidates",
      dataIndex: "totalCandidates",
      key: "totalCandidates",
      sorter: (a, b) => a.totalCandidates - b.totalCandidates,
      width: 150
    },
    {
      title: "Sourced",
      dataIndex: "sourced",
      key: "sourced",
      sorter: (a, b) => a.sourced - b.sourced,
      width: 150
    },
    {
      title: "Applied",
      dataIndex: "applied",
      key: "applied",
      sorter: (a, b) => a.applied - b.applied,
      width: 150
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 150,

      sorter: (a, b) => a.phone.localeCompare(b.phone),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Phone"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#28a745", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => record.phone.includes(value)
    },
    {
      title: "Assessment",
      dataIndex: "assessment",
      key: "assessment",
      sorter: (a, b) => a.assessment - b.assessment,
      width: 150
    },
    {
      title: "Interview",
      dataIndex: "interview",
      key: "interview",
      sorter: (a, b) => a.interview - b.interview,
      width: 150
    },
    {
      title: "Offer",
      dataIndex: "offer",
      key: "offer",
      sorter: (a, b) => a.offer - b.offer,
      width: 150
    },
    {
      title: "Hired",
      dataIndex: "hired",
      key: "hired",
      sorter: (a, b) => a.hired - b.hired,
      width: 150
    },
    {
      title: "Disqualified",
      dataIndex: "disqualified",
      key: "disqualified",
      sorter: (a, b) => a.disqualified - b.disqualified,
      width: 150
    }
  ];

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin
            height="100"
            width="100"
            color="#005c53"
            ariaLabel="loading"
          />
        </div>
      ) : (
        <>
          <Card
            className="mt-3 mx-2 my-2"
            title={
              <>
                <Row justify="space-between" align="middle">
                  <Col>
                    <ArrowLeftOutlined
                      onClick={() => {
                        dispatch({ type: "GET_CLIENT_DETAIL_RESET" });
                        navigate("/dashboard/client-dashboard");
                      }}
                      style={{ cursor: "pointer", marginRight: 10 }}
                    />
                    {data?.company_name}
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px"
                      }}
                    >
                      <Dropdown
                        overlay={menuItems}
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <Button shape="circle" icon={<MoreOutlined />} />
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </>
            }
            bordered={false}
            hoverable={true}
            style={{
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              marginBottom: "5px",
              borderRadius: 8
            }}
          >
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Details" key="1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px"
                  }}
                >
                  <Avatar
                    size={80}
                    src={
                      data?.company_photo &&
                      `${process.env.REACT_APP_IMAGES_BASE_URL}${data?.company_photo}`
                    }
                    icon={!data?.company_photo && <UserOutlined />}
                    alt={`${data?.company_name} Company Icon`}
                    style={{ marginRight: "16px" }}
                  />
                  <div>
                    <Title level={3} style={{ margin: 0 }}>
                      {data?.company_name}
                    </Title>
                    <Text type="secondary">{data?.company_location}</Text>
                    <br />
                    <Link
                      href={`mailto:${data?.company_email}`}
                      style={{ color: "#1890ff" }}
                    >
                      {data?.company_email}
                    </Link>
                  </div>
                </div>

                <Divider />

                <section style={{ marginBottom: "30px" }}>
                  <Title level={4}>Description</Title>
                  <Text>{data?.company_description}</Text>
                </section>

                <Divider />

                <section style={{ marginBottom: "20px" }}>
                  <Title level={4}>Basic Details</Title>
                  <div style={{ marginBottom: "15px" }}>
                    <Text strong>Location: </Text>
                    <Text>{data?.company_location}</Text>
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <Text strong>Company Type: </Text>
                    <Text>{data?.company_type}</Text>
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <Text strong>Website: </Text>
                    <Link href={data?.company_website} target="_blank">
                      {data?.company_website}
                    </Link>
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <Text strong>Member Size: </Text>
                    <Text>{data?.company_size}</Text>
                  </div>
                </section>
              </Tabs.TabPane>
              <Tabs.TabPane tab="All Jobs" key="2">
                <JobsListing client_id={client_id} />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </>
      )}
    </>
  );
};
