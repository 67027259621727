import React, { useEffect, useState } from "react";
import {
  Layout,
  Pagination,
  Table,
  Button,
  message,
  Space,
  Input,
  Avatar,
  Tooltip,
  Badge,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  ReloadOutlined,
  SearchOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { getClientListAction } from "../../redux/actions/common";
import { UserOutlined } from "@ant-design/icons";
import { statusColor } from "../../Assets/data/common";

const { Content } = Layout;

export const ClientTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getClientListReducer = useSelector(
    (state) => state.getClientListReducer
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalClients, setTotalClients] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchClientData();
    return () => {
      dispatch({ type: "GET_CLIENT_LIST_RESET" });
    };
  }, [dispatch, currentPage, pageSize, searchText]);

  const fetchClientData = () => {
    dispatch(
      getClientListAction({
        page: currentPage,
        limit: pageSize,
        search: searchText,
      })
    );
  };

  useEffect(() => {
    const { loading, data, error } = getClientListReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data.data || []);
      setTotalClients(data.total || 0);
    }
    if (!loading && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getClientListReducer]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,      
      width: 70,
      ellipsis: true,
    },
    {
      title: "Client Name",
      dataIndex: "company_name",
      key: "company_name",
      width: 200,
      render: (text, record) => (
        <span
          onClick={() => {
            navigate(`details?client_id=${record?.client_id}`);
          }}
          className="d-flex"
        >
          <Avatar
            src={
              record?.company_photo
                ? `${process.env.REACT_APP_IMAGES_BASE_URL}${record?.company_photo}`
                : undefined
            }
            icon={!record?.company_photo ? <UserOutlined /> : null}
            alt={text || "User"}
            style={{ marginRight: 8 }}
          />
          <a
            className="text-decoration-underline cursor-pointer"
            style={{ color: "#005c53", cursor: "pointer" }}
          >
            {`${record?.company_name}`}
          </a>
        </span>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Client Name`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#28a745", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.company_name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Email",
      dataIndex: "company_email",
      key: "company_email",
      width: 200,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Email`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#28a745", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.company_email.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Client Id",
      dataIndex: "client_id",
      key: "client_id",
      width: 100,
      render: (client_id) => {
        const handleCopy = () => {
          navigator.clipboard.writeText(client_id);
          message.success("Copied to clipboard!");
        };

        return (
          <>
            <span>{client_id}</span>
            <Tooltip title="Copy Client ID">
              <CopyOutlined
                onClick={handleCopy}
                style={{ marginLeft: 8, cursor: "pointer" }}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status) => {
        return (
          <Badge
            count={
              <span
                style={{
                  backgroundColor: statusColor[status]?.backgroundColor,
                  color: statusColor[status]?.color,
                  borderRadius: "18px",
                  padding: "5px 15px",
                }}
              >
                {status?.toUpperCase()}
              </span>
            }
          />
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (createdAt) => {
        const date = new Date(createdAt);
        return date
          .toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(",", "");
      },
      width: 200,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      render: (updatedAt) => {
        const date = new Date(updatedAt);
        return date
          .toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(",", "");
      },
      width: 200,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleRefresh = () => {
    setSearchText("");
    fetchClientData();
  };

  return (
    <Layout style={{ padding: "0px", minHeight: "280px" }}>
      <Content style={{ padding: "16px" }}>
        
          {loading ? (
            <div style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}>
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <>
              <div
                className="cl-section-header mb-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                 }}
              >
                <div className="col d-flex align-items-center"></div>
                <div
                  className="cl-section-header__action-buttons-wrapper"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="ms-2"
                    onClick={handleRefresh}
                    icon={<ReloadOutlined />}
                    type="primary"
                    style={{ backgroundColor: "#00756a" }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>

              <>
                <Table
                  bordered
                  columns={columns}
                  dataSource={data}
                  rowKey="client_id"
                  pagination={false}
                  scroll={{
                    x: 1300,
                  }}
                  footer={() => (
                    <div style={{ textAlign: "center" }}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/dashboard/create-client`);
                        }}
                        style={{
                          color: "#00756a",
                          textDecoration: "underline",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        Create Client
                      </a>
                    </div>
                  )}
                />
                <Pagination
                  className="text-center align-content-center mt-3"
                  current={currentPage}
                  total={totalClients}
                  pageSize={pageSize}
                  showSizeChanger
                  pageSizeOptions={[5, 10, 20, 50, 100]}
                  scroll={{
                    x: 1300, 
                  }}
                  showTotal={(total) => `Total ${total} Clients`}
                  onChange={(page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                  }}
                  style={{ textAlign: "center" }} 
                />
              </>
            </>
          )}
          <style jsx>{`
        .dashboard-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
        }
        @media (max-width: 768px) {
          .site-layout-background {
            display: none !important;
          }
          .filter-toggle-button {
            display: block;
            margin: 10px;
          }
          .dashboard-container {
            grid-template-columns: 1fr;
          }
        }
        @media (min-width: 786px) {
          .dashboard-container {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        @media (min-width: 769px) {
          .filter-toggle-button {
            display: none;
          }
        }
      `}</style>
              </Content>
    </Layout>
    
  );
};
