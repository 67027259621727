import {
  assessmentImageProctorConstants,
  assessmentLoginConstants,
  candidateAssessmentFeedbackConstants,
  candidateAssessmentSubmissionConstants,
  createAssessmentConstants,
  createQuestionConstants,
  createTestConstants,
  getAllAssessmentCandidateConstants,
  getAllAssessmentListConstants,
  getAllQuestionListConstants,
  getAllTestListConstants,
  getAsCandidateDetailsConstants,
  getAssessmentCandidateDetailsConstants,
  getAssessmentDetailsConstants,
  getInvitationDetailsConstants,
  getInviteAssessmentDetailsConstants,
  getQuestionDetailsConstants,
  getTestDetailsConstants,
  inviteAssessmentConstants,
  updateAssessmentConstants,
  updateInviteAssessmentDetailsConstants,
  updateQuestionConstants,
  updateTestConstants
} from "../../constants";

export const createQuestionReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case createQuestionConstants.CREATE_QUESTION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case createQuestionConstants.CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case createQuestionConstants.CREATE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case createQuestionConstants.CREATE_QUESTION_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateQuestionReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateQuestionConstants.UPDATE_QUESTION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateQuestionConstants.UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateQuestionConstants.UPDATE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateQuestionConstants.UPDATE_QUESTION_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getQuestionDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getQuestionDetailsConstants.GET_QUESTION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getQuestionDetailsConstants.GET_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getQuestionDetailsConstants.GET_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getQuestionDetailsConstants.GET_QUESTION_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getAllQuestionListReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAllQuestionListConstants.GET_LIST_QUESTION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAllQuestionListConstants.GET_LIST_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getAllQuestionListConstants.GET_LIST_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getAllQuestionListConstants.GET_LIST_QUESTION_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const createTestReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case createTestConstants.CREATE_TEST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case createTestConstants.CREATE_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case createTestConstants.CREATE_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case createTestConstants.CREATE_TEST_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateTestReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateTestConstants.UPDATE_TEST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateTestConstants.UPDATE_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateTestConstants.UPDATE_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateTestConstants.UPDATE_TEST_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getTestDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getTestDetailsConstants.GET_TEST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getTestDetailsConstants.GET_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getTestDetailsConstants.GET_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getTestDetailsConstants.GET_TEST_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getAllTestListReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAllTestListConstants.GET_LIST_TEST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAllTestListConstants.GET_LIST_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getAllTestListConstants.GET_LIST_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getAllTestListConstants.GET_LIST_TEST_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const createAssessmentReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case createAssessmentConstants.CREATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case createAssessmentConstants.CREATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case createAssessmentConstants.CREATE_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case createAssessmentConstants.CREATE_ASSESSMENT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateAssessmentReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateAssessmentConstants.UPDATE_ASSESSMENT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateAssessmentConstants.UPDATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateAssessmentConstants.UPDATE_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateAssessmentConstants.UPDATE_ASSESSMENT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getAssessmentDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAssessmentDetailsConstants.GET_ASSESSMENT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAssessmentDetailsConstants.GET_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getAssessmentDetailsConstants.GET_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getAssessmentDetailsConstants.GET_ASSESSMENT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getAllAssessmentListReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAllAssessmentListConstants.GET_LIST_ASSESSMENT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAllAssessmentListConstants.GET_LIST_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getAllAssessmentListConstants.GET_LIST_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getAllAssessmentListConstants.GET_LIST_ASSESSMENT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getInviteAssessmentDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getInviteAssessmentDetailsConstants.GET_INVITE_ASSESSMENT_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getInviteAssessmentDetailsConstants.GET_INVITE_ASSESSMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getInviteAssessmentDetailsConstants.GET_INVITE_ASSESSMENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getInviteAssessmentDetailsConstants.GET_INVITE_ASSESSMENT_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const updateInviteAssessmentDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const assessmentLoginReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case assessmentLoginConstants.ASSESSMENT_LOGIN_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case assessmentLoginConstants.ASSESSMENT_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case assessmentLoginConstants.ASSESSMENT_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case assessmentLoginConstants.ASSESSMENT_LOGIN_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getAllAssessmentCandidateReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAllAssessmentCandidateConstants.GET_ALL_ASSESSMENT_CANDIDATE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAllAssessmentCandidateConstants.GET_ALL_ASSESSMENT_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getAllAssessmentCandidateConstants.GET_ALL_ASSESSMENT_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getAllAssessmentCandidateConstants.GET_ALL_ASSESSMENT_CANDIDATE_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getAssessmentCandidateDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAssessmentCandidateDetailsConstants.GET_ASSESSMENT_CANDIDATE_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAssessmentCandidateDetailsConstants.GET_ASSESSMENT_CANDIDATE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getAssessmentCandidateDetailsConstants.GET_ASSESSMENT_CANDIDATE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getAssessmentCandidateDetailsConstants.GET_ASSESSMENT_CANDIDATE_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const inviteAssessmentReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case inviteAssessmentConstants.INVITE_ASSESSMENT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case inviteAssessmentConstants.INVITE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case inviteAssessmentConstants.INVITE_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case inviteAssessmentConstants.INVITE_ASSESSMENT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const getCandidateDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAsCandidateDetailsConstants.GET_NEW_CANDIDATE_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAsCandidateDetailsConstants.GET_NEW_CANDIDATE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case inviteAssessmentConstants.GET_NEW_CANDIDATE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case inviteAssessmentConstants.INVITE_ASSESSMENT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const candidateAssessmentSubmissionReducer = (state = {}, action) => {
  switch (action.type) {
    case candidateAssessmentSubmissionConstants.CANDIDATE_ASSESSMENT_SUBMISSION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case candidateAssessmentSubmissionConstants.CANDIDATE_ASSESSMENT_SUBMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case candidateAssessmentSubmissionConstants.CANDIDATE_ASSESSMENT_SUBMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case candidateAssessmentSubmissionConstants.CANDIDATE_ASSESSMENT_SUBMISSION_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const candidateAssessmentFeedbackReducer = (state = {}, action) => {
  switch (action.type) {
    case candidateAssessmentFeedbackConstants.CANDIDATE_ASSESSMENT_FEEDBACK_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case candidateAssessmentFeedbackConstants.CANDIDATE_ASSESSMENT_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case candidateAssessmentFeedbackConstants.CANDIDATE_ASSESSMENT_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case candidateAssessmentFeedbackConstants.CANDIDATE_ASSESSMENT_FEEDBACK_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const assessmentImageProctorReducer = (state = {}, action) => {
  switch (action.type) {
    case assessmentImageProctorConstants.ASSESSMENT_PROCTOR_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case assessmentImageProctorConstants.ASSESSMENT_PROCTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case assessmentImageProctorConstants.ASSESSMENT_PROCTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case assessmentImageProctorConstants.ASSESSMENT_PROCTOR_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getInvitationDetailReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getInvitationDetailsConstants.GET_INVITE_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getInvitationDetailsConstants.GET_INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getInvitationDetailsConstants.GET_INVITE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getInvitationDetailsConstants.GET_INVITE_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
