import React from "react";
import { QuillTextPreview } from "../../../../Utils/TextEditor";
import { getCurrecnySymbol } from "../../../../Utils/Common";
import { Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useIsMobileView } from "../../../../Utils/CustomHooks/windowSize";

const JobDetailsDrawer = ({ visible, setVisible }) => {
  const data = visible?.data;
  const renderSection = (title, content) =>
    content && (
      <section className="styles--3vx-H" aria-labelledby={`${title}-title`}>
        <h2 id={`${title}-title`}>{title}</h2>
        <div>{content}</div>
      </section>
    );

  return (
    <>
      <Drawer
        placement="right"
        closable={true}
        onClose={() =>
          setVisible((prev) => ({ ...prev, show: false, data: {} }))
        }
        open={visible.show}
        width={useIsMobileView() ? "100%" : "40%"}

        title={null}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "20px", marginRight: "10px" }}
            onClick={() =>
              setVisible((prev) => ({ ...prev, show: false, data: {} }))
            }
          />
        }
      >
        {renderSection(
          "Description",
          <>
            {data?.job?.job_description && (
              <p>
                <QuillTextPreview content={data?.job?.job_description} />
              </p>
            )}
          </>
        )}
        {renderSection(
          "Vacancies",
          data?.job?.vacancies && <p>{data.job.vacancies}</p>
        )}
        {renderSection(
          "Experience Required",
          data?.job?.job_experience_range &&
            data.job.job_experience_range.map((item, index) => (
              <p key={index}>
                {index + 1}. {item}
              </p>
            ))
        )}

        {renderSection(
          "Salary",
          data?.job?.salary && (
            <>
              {data.job.salary?.min !== undefined &&
                data.job.salary?.max !== undefined && (
                  <>
                    {data.job.salary?.currency && (
                      <>&nbsp;{getCurrecnySymbol(data.job.salary.currency)}</>
                    )}
                    {data.job.salary.min}
                    {data?.job.salary?.unit} -{" "}
                    {data.job.salary?.currency && (
                      <>&nbsp;{getCurrecnySymbol(data.job.salary.currency)}</>
                    )}
                    {data.job.salary.max}
                    {data?.job.salary?.unit}{" "}
                    {data.job.salary?.tenure && (
                      <>&nbsp;({data.job.salary.tenure})</>
                    )}
                  </>
                )}
            </>
          )
        )}
      </Drawer>
    </>
  );
};

export default JobDetailsDrawer;
