import React, { useCallback, useMemo, useState } from "react";
import { Col, Radio, Row, Table, Typography } from "antd";
import CodeEditor from "../../Compiler/CodeEditor";

const { Title } = Typography;

const QuestionRoot = React.memo(({ currentQuestion, handleAnswer, currentAnswer }) => {
  const handleChange = useCallback(
    (e) => handleAnswer(currentQuestion?.question_id, e.target.value),
    [currentQuestion?.question_id, handleAnswer]
  );

  const renderMCQ = useMemo(() => (
    <>
      <div style={{ padding: "20px", fontSize: "16px" }}>
        <strong>Choose Only One</strong>
      </div>
      <Radio.Group onChange={handleChange} value={currentAnswer}>
        {currentQuestion?.mcqChoices?.map((choice) => (
          <div className="radio-container" key={choice?.id}>
            <div
              className="radio-box"
              onClick={() => handleAnswer(currentQuestion?.question_id, choice?.value)}
            >
              <Radio value={choice?.value}>{choice?.value}</Radio>
            </div>
            <br />
          </div>
        ))}
      </Radio.Group>
    </>
  ), [currentQuestion?.mcqChoices, currentAnswer, handleChange]);

  const renderCode = useMemo(() => (
    <div className="editor-compiler-container">
      <CodeEditor
        data={currentQuestion}
        onSave={(data) => handleAnswer(currentQuestion?.question_id, data)}
      />
    </div>
  ), [currentQuestion, handleAnswer]);

  const renderQuestionnaire = useMemo(() => (
    <>
      <div style={{ padding: "20px", fontSize: "16px" }}>
        <strong>Choose Only One</strong>
      </div>
      <Radio.Group onChange={handleChange} value={currentAnswer}>
        {currentQuestion?.questionnaireChoices?.map((choice) => (
          <div className="radio-container" key={choice?.id}>
            <div
              className="radio-box"
              onClick={() => handleAnswer(currentQuestion?.question_id, choice?.value)}
            >
              <Radio value={choice?.value}>{choice?.value}</Radio>
            </div>
            <br />
          </div>
        ))}
      </Radio.Group>
    </>
  ), [currentQuestion?.questionnaireChoices, currentAnswer, handleChange]);

  const renderQuestionCard = useMemo(() => {
    switch (currentQuestion?.questionType) {
      case "mcq":
        return renderMCQ;
      case "code":
        return renderCode;
      case "questionnaire":
        return renderQuestionnaire;
      default:
        return null;
    }
  }, [currentQuestion, renderMCQ, renderCode, renderQuestionnaire]);

  return (
    <Row className="full-screen-row">
      <Col span={8} className="left-col" style={{ padding: "16px" }}>
        <div style={{ padding: "20px" }}>
          <strong>Question:</strong>
          <Title level={4}>{currentQuestion?.questionName}</Title>
        </div>
        <div style={{ padding: "20px" }}>
          <strong>Description:</strong>
          <div
            dangerouslySetInnerHTML={{
              __html: currentQuestion?.problemDescription,
            }}
          />
        </div>
        {currentQuestion?.questionType === "code" && (
          <>
            <div style={{ padding: "20px" }}>
              <strong>Test Case:</strong>
              <Table
              bordered
                dataSource={currentQuestion?.testCases}
                columns={[
                  { title: "Input", dataIndex: "input", key: "input" },
                  { title: "Output", dataIndex: "output", key: "output" },
                ]}
                pagination={false}
                rowKey="input"
              />
            </div>
            <div style={{ padding: "20px" }}>
              <strong>Language:</strong>
              <p>{currentQuestion?.languages}</p>
            </div>
          </>
        )}
      </Col>
      <Col span={16} className="right-col" style={{ padding: "16px" }}>
        {renderQuestionCard}
      </Col>
    </Row>
  );
});

export default QuestionRoot;
