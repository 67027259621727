import { call, delay, put } from "redux-saga/effects";
import { v4 as uuidv4 } from 'uuid';
import {
  addCandidateConstants,
  addClientConstants,
  applyJobConstants,
  getAllCandidateActions,
  getClientDetailsConstants,
  getClientJobsConstants,
  getClientListConstants,
  getJobDetailConstants,
  postFeedbackConstants,
  resumeParserConstants,
  updateClientDetailsConstants,
  updateJobApplicationConstants,
  updateJobConstants,
  getCandidateProfileConstants,
  createJobConstants,
  getJobConstants,
  getJobApplicationConstants,
  enquiryConstants,
  getEnquiryConstants,
  updateEnquiryConstants,
  sendOtpConstants,
  verifyOtpConstants,
  verifyEmailConstants,
  updateCandidateConstants,
  matchingAlgoConstants,
  multipleActionConstants,
  getJobAllAppliedCandidateConstants,
  getFeedbackListConstant,
  updateFeedbackConstant,
  assignToRecruiterConstants,
  emailSubscribeConstants,
  getPresignedUrlConstants,
  uploadImageFileConstants,
  getEmailTemplatesConstants,
  updateEmailTemplatesConstants
} from "../../constants";
import {
  addCandidateCall,
  addClientCall,
  applyJobCall,
  createJobReq,
  getAllCandidateCall,
  getCandidateProfileCall,
  getClientDetailsCall,
  getClientJobCall,
  getClientListCall,
  getJobDetailsCall,
  postFeedbackCall,
  resumeParserCall,
  updateClientDetailsCall,
  updateJobApplicationCall,
  updateJobDetailsCall,
  uploadImageFileReq,
  uploadImageReq,
  getJobReq,
  getJobApplicationCall,
  enquiryCall,
  getEnquiryCall,
  updateEnquiryCall,
  verifyOtpCall,
  sendOtpCall,
  verifyEmailCall,
  updateCandidateCall,
  matchingAlgoCall,
  multipleActionCall,
  getJobAllAppliedCandidateCall,
  getFeedbackListCall,
  updateFeedbackCall,
  assignToRecruiterCall,
  emailSubscribeCall,
  getEmailTemplatesCall,
  updateEmailTemplatesCall
} from "../../network";

export function* uploadImgaeSaga(action) {
  try {
    const response = yield call(uploadImageReq, action.data);
    yield put({
      type: getPresignedUrlConstants.UPLOAD_IMAGE_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getPresignedUrlConstants.UPLOAD_IMAGE_REQUEST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getPresignedUrlConstants.UPLOAD_IMAGE_RESET });
  }
}

export function* uploadImgaeFileSaga(action) {
  try {
    const response = yield call(uploadImageFileReq, action.data);
    yield put({
      type: uploadImageFileConstants.UPLOAD_IMAGE_FILE_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: uploadImageFileConstants.UPLOAD_IMAGE_FILE_RESET });
  } catch (e) {
    yield put({
      type: uploadImageFileConstants.UPLOAD_IMAGE_FILE_REQUEST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: uploadImageFileConstants.UPLOAD_IMAGE_FILE_RESET });
  }
}

export function* resumeParserSaga(action) {
  try {
    const response = yield call(resumeParserCall, action.data);
    yield put({
      type: resumeParserConstants.RESUME_PARSER_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: resumeParserConstants.RESUME_PARSER_RESET });
  } catch (e) {
    yield put({
      type: resumeParserConstants.RESUME_PARSER_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: resumeParserConstants.RESUME_PARSER_RESET });
  }
}



export function* getJobDetailsSaga(action) {
  try {
    const response = yield call(getJobDetailsCall, action.data);
    yield put({
      type: getJobDetailConstants.GET_JOB_DETAIL_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getJobDetailConstants.GET_JOB_DETAIL_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getJobDetailConstants.GET_JOB_DETAIL_RESET });
  }
}
export function* applyJobSaga(action) {
  try {
    const response = yield call(applyJobCall, action.data);
    yield put({
      type: applyJobConstants.APPLY_JOB_SUCCESS,
      data: response.data,
      headers: response.headers,
      status: response.status,
      error: response.error
    });

    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: applyJobConstants.APPLY_JOB_RESET });
  } catch (e) {
    yield put({
      type: applyJobConstants.APPLY_JOB_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: applyJobConstants.APPLY_JOB_RESET });
  }
}
export function* addClientSaga(action) {
  try {
    const response = yield call(addClientCall, action.data);
    yield put({
      type: addClientConstants.ADD_CLIENT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: addClientConstants.ADD_CLIENT_RESET });
  } catch (e) {
    yield put({
      type: addClientConstants.ADD_CLIENT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: addClientConstants.ADD_CLIENT_RESET });
  }
}
export function* getClientListSaga(action) {
  try {
    const response = yield call(getClientListCall, action.data);
    yield put({
      type: getClientListConstants.GET_CLIENT_LIST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getClientListConstants.GET_CLIENT_LIST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getClientListConstants.GET_CLIENT_LIST_RESET });
  }
}
export function* getClientDetailsSaga(action) {
  try {
    const response = yield call(getClientDetailsCall, action.data);
    yield put({
      type: getClientDetailsConstants.GET_CLIENT_DETAIL_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getClientDetailsConstants.GET_CLIENT_DETAIL_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getClientDetailsConstants.GET_CLIENT_DETAIL_RESET });
  }
}
export function* updateclientDetailsSaga(action) {
  try {
    const response = yield call(updateClientDetailsCall, action.data);
    yield put({
      type: updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_RESET
    });
  } catch (e) {
    yield put({
      type: updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_RESET
    });
  }
}
export function* getAllCandidateSaga(action) {
  try {
    const response = yield call(getAllCandidateCall, action.data);
    yield put({
      type: getAllCandidateActions.GET_ALL_CANDIDATE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getAllCandidateActions.GET_ALL_CANDIDATE_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getAllCandidateActions.GET_ALL_CANDIDATE_RESET
    });
  }
}
export function* getClientJobSaga(action) {
  try {
    const response = yield call(getClientJobCall, action.data);
    yield put({
      type: getClientJobsConstants.GET_CLIENT_JOBS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getClientJobsConstants.GET_CLIENT_JOBS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getClientJobsConstants.GET_CLIENT_JOBS_RESET
    });
  }
}
export function* updateJobDetailsSaga(action) {
  try {
    const response = yield call(updateJobDetailsCall, action.data);
    yield put({
      type: updateJobConstants.UPDATE_JOB_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateJobConstants.UPDATE_JOB_RESET });
  } catch (e) {
    yield put({
      type: updateJobConstants.UPDATE_JOB_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateJobConstants.UPDATE_JOB_RESET });
  }
}
export function* addCandidateSaga(action) {
  try {
    const response = yield call(addCandidateCall, action.data);
    yield put({
      type: addCandidateConstants.ADD_CANDIDATE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: addCandidateConstants.ADD_CANDIDATE_RESET });
  } catch (e) {
    yield put({
      type: addCandidateConstants.ADD_CANDIDATE_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: addCandidateConstants.ADD_CANDIDATE_RESET });
  }
}
export function* updateJobApplicationSaga(action) {
  try {
    const response = yield call(updateJobApplicationCall, action.data);
    yield put({
      type: updateJobApplicationConstants.UPDATE_APPLICATION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateJobApplicationConstants.UPDATE_APPLICATION_RESET });
  } catch (e) {
    yield put({
      type: updateJobApplicationConstants.UPDATE_APPLICATION_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateJobApplicationConstants.UPDATE_APPLICATION_RESET });
  }
}
export function* postFeedbackSaga(action) {
  try {
    const response = yield call(postFeedbackCall, action.data);
    yield put({
      type: postFeedbackConstants.POST_FEEDBACK_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: postFeedbackConstants.POST_FEEDBACK_RESET });
  } catch (e) {
    yield put({
      type: postFeedbackConstants.POST_FEEDBACK_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: postFeedbackConstants.POST_FEEDBACK_RESET });
  }
}

export function* candidateProfileSaga(action) {
  try {
    const response = yield call(getCandidateProfileCall, action.data);
    yield put({
      type: getCandidateProfileConstants.CANDIDATE_PROFILE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getCandidateProfileConstants.CANDIDATE_PROFILE_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getCandidateProfileConstants.CANDIDATE_PROFILE_RESET
    });
  }
}
export function* createJobSaga(action) {
  try {
    const response = yield call(createJobReq, action.data);
    yield put({
      type: createJobConstants.CREATE_JOB_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createJobConstants.CREATE_JOB_RESET });
  } catch (e) {
    yield put({
      type: createJobConstants.CREATE_JOB_REQUEST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createJobConstants.CREATE_JOB_RESET });
  }
}
export function* getJobSaga(action) {
  try {
    const response = yield call(getJobReq, action.data);
    yield put({
      type: getJobConstants.GET_JOB_REQUEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getJobConstants.GET_JOB_REQUEST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getJobConstants.GET_JOB_RESET
    });
  }
}
export function* getJobApplicationSaga(action) {
  try {
    const response = yield call(getJobApplicationCall, action.data);
    yield put({
      type: getJobApplicationConstants.GET_JOB_APPLICATION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getJobApplicationConstants.GET_JOB_APPLICATION_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getJobApplicationConstants.GET_JOB_APPLICATION_RESET
    });
  }
}
export function* enquirySaga(action) {
  try {
    const response = yield call(enquiryCall, action.data);
    yield put({
      type: enquiryConstants.ENQUIRY_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: enquiryConstants.ENQUIRY_RESET });
  } catch (e) {
    yield put({
      type: enquiryConstants.ENQUIRY_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: enquiryConstants.ENQUIRY_RESET });
  }
}
export function* getEnquirySaga(action) {
  try {
    const response = yield call(getEnquiryCall, action.data);
    yield put({
      type: getEnquiryConstants.GET_ENQUIRY_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getEnquiryConstants.GET_ENQUIRY_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getEnquiryConstants.GET_ENQUIRY_RESET
    });
  }
}
export function* updateEnquirySaga(action) {
  try {
    const response = yield call(updateEnquiryCall, action.data);
    yield put({
      type: updateEnquiryConstants.UPDATE_ENQUIRY_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateEnquiryConstants.UPDATE_ENQUIRY_RESET });
  } catch (e) {
    yield put({
      type: updateEnquiryConstants.UPDATE_ENQUIRY_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateEnquiryConstants.UPDATE_ENQUIRY_RESET });
  }
}
export function* sendOtpSaga(action) {
  try {
    const response = yield call(sendOtpCall, action.data);
    yield put({
      type: sendOtpConstants.SEND_OTP_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: sendOtpConstants.SEND_OTP_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: sendOtpConstants.SEND_OTP_RESET
    });
  }
}
export function* verifyOtpSaga(action) {
  try {
    const response = yield call(verifyOtpCall, action.data);
    yield put({
      type: verifyOtpConstants.VERIFY_OTP_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: verifyOtpConstants.VERIFY_OTP_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: verifyOtpConstants.VERIFY_OTP_RESET
    });
  }
}
export function* verifyEmailSaga(action) {
  try {
    const response = yield call(verifyEmailCall, action.data);
    yield put({
      type: verifyEmailConstants.VERIFY_EMAIL_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: verifyEmailConstants.VERIFY_EMAIL_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: verifyEmailConstants.VERIFY_EMAIL_RESET
    });
  }
}

export function* updateCandidateSaga(action) {
  try {
    const response = yield call(updateCandidateCall, action.data);
    yield put({
      type: updateCandidateConstants.UPDATE_CANDIDATE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateCandidateConstants.UPDATE_CANDIDATE_RESET });
  } catch (e) {
    yield put({
      type: updateCandidateConstants.UPDATE_CANDIDATE_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateCandidateConstants.UPDATE_CANDIDATE_RESET });
  }
}
export function* matchingAlgoSaga(action) {
  try {
    const response = yield call(matchingAlgoCall, action.data);
    yield put({
      type: matchingAlgoConstants.MATCHING_ALGO_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: matchingAlgoConstants.MATCHING_ALGO_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: matchingAlgoConstants.MATCHING_ALGO_RESET
    });
  }
}

export function* multipleActionSaga(action) {
  try {
    const response = yield call(multipleActionCall, action.data);
    yield put({
      type: multipleActionConstants.MULTIPLE_ACTION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: multipleActionConstants.MULTIPLE_ACTION_RESET });
  } catch (e) {
    yield put({
      type: multipleActionConstants.MULTIPLE_ACTION_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: multipleActionConstants.MULTIPLE_ACTION_RESET });
  }
}

export function* getJobAllAppliedCandidateSaga(action) {
  try {
    const response = yield call(getJobAllAppliedCandidateCall, action.data);
    yield put({
      type: getJobAllAppliedCandidateConstants.GET_JOB_ALL_APPLIED_CANDIDATE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getJobAllAppliedCandidateConstants.GET_JOB_ALL_APPLIED_CANDIDATE_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getJobAllAppliedCandidateConstants.ADD_CANDIDATE_RESET
    });
  }
}
export function* getFeedbackListSaga(action) {
  try {
    const response = yield call(getFeedbackListCall, action.data);
    yield put({
      type: getFeedbackListConstant.GET_FEEDBACK_LIST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getFeedbackListConstant.GET_FEEDBACK_LIST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getFeedbackListConstant.GET_FEEDBACK_LIST_RESET
    });
  }
}
export function* updateFeedbackSaga(action) {
  try {
    const response = yield call(updateFeedbackCall, action.data);
    yield put({
      type: updateFeedbackConstant.UPDATE_FEEDBACK_LIST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateFeedbackConstant.UPDATE_FEEDBACK_LIST_RESET });
  } catch (e) {
    yield put({
      type: updateFeedbackConstant.UPDATE_FEEDBACK_LIST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateFeedbackConstant.UPDATE_FEEDBACK_LIST_RESET });
  }
}
export function* assignToRecruiterSaga(action) {
  try {
    const response = yield call(assignToRecruiterCall, action.data);
    yield put({
      type: assignToRecruiterConstants.ASSIGN_TO_RECRUITER_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: assignToRecruiterConstants.ASSIGN_TO_RECRUITER_RESET });
  } catch (e) {
    yield put({
      type: assignToRecruiterConstants.ASSIGN_TO_RECRUITER_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: assignToRecruiterConstants.ASSIGN_TO_RECRUITER_RESET });
  }
}

export function* emailSubscribeSaga(action) {
  try {
    const response = yield call(emailSubscribeCall, action.data);
    yield put({
      type: emailSubscribeConstants.EMAIL_SUBSCRIBE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: emailSubscribeConstants.EMAIL_SUBSCRIBE_RESET });
  } catch (e) {
    yield put({
      type: emailSubscribeConstants.EMAIL_SUBSCRIBE_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: emailSubscribeConstants.EMAIL_SUBSCRIBE_RESET });
  }
}
export function* getEmailTemplatesSaga(action) {
  try {
    const response = yield call(getEmailTemplatesCall, action.data);
    yield put({
      type: getEmailTemplatesConstants.GET_EMAIL_TEMPLATES_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getEmailTemplatesConstants.GET_EMAIL_TEMPLATES_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getEmailTemplatesConstants.GET_EMAIL_TEMPLATES_RESET });
  }
}
export function* updateEmailTemplatesSaga(action) {
  try {
    const response = yield call(updateEmailTemplatesCall, action.data);
    yield put({
      type: updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_RESET
    });
  } catch (e) {
    yield put({
      type: updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_RESET
    });
  }
}
