import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  message,
  Select,
  Layout,
  Form,
  Collapse,
  Divider,
  Pagination,
  Modal,
  Radio,
  Input,
  Dropdown,
  Menu,
  Avatar,
  Tooltip
} from "antd";
import {
  ReloadOutlined,
  SearchOutlined,
  MoreOutlined,
  CopyOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { getAllRoleUserAction } from "../../../redux/actions/Roles&Management";
import { updateUserDetailsAction } from "../../../redux/actions/user";
import { REACT_USER_TYPE } from "../../Auth/tokenProvider";
import {
  All_ROLES,
  ASSESSMENT_OWNER_ROLES,
  ATS_OWNER_ROLES
} from "../../../Assets/data/common";
import { UserOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Sider, Content } = Layout;
const { Panel } = Collapse;

const ClientAdminUserManagementMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);

  const [filters, setFilters] = useState({
    status: "",
    user_type: "",
    client_id: ""
  });
  const updateUserDetailReducer = useSelector(
    (state) => state.updateUserDetailReducer
  );
  useEffect(() => {
    const { loading, data, error } = updateUserDetailReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        if (data?.updatedUser?.status === "delete") {
          message.success("user deleted!");
        }
      } else if (error) {
        console.warn("create user error:", error);
        message.error(error);
      }
    }
  }, [updateUserDetailReducer]);
  useEffect(() => {
    dispatch(
      getAllRoleUserAction({
        ...filters,
        page: currentPage,
        limit: pageSize
      })
    );
    return () => {
      dispatch({ type: "GET_USER_RESET" });
    };
  }, [dispatch, filters, currentPage, pageSize, updateUserDetailReducer]);

  const handleRefresh = () => {
    dispatch(
      getAllRoleUserAction({
        ...filters,
        page: currentPage,
        limit: pageSize
      })
    );
  };
  const { data: userData, loading: userLoading } = useSelector(
    (state) => state.getAllRoleUserReducer
  );

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setFilters({
      status: "",
      user_type: "",
      client_id: ""
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    if (userData) {
      setTotalUsers(userData.totalUsers || 0);
    }
  }, [userData]);

  const [userType, setUserType] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  useEffect(() => {
    const USER_TYPE = REACT_USER_TYPE();
    setUserType(USER_TYPE);
  }, []);

  useEffect(() => {
    switch (userType) {
      case "ats-owner":
      case "ats-admin":
        setRoleOptions(ATS_OWNER_ROLES);
        break;
      case "assessment-owner":
      case "assessment-admin":
        setRoleOptions(ASSESSMENT_OWNER_ROLES);
        break;
      default:
        setRoleOptions([]);
        break;
    }
  }, [userType]);
  const handleMenuClick = (record) => async (e) => {
    const user_id = record?.user_id; // Extract user_id from the record object

    const showDeleteConfirm = () => {
      Modal.confirm({
        title: "Are you sure you want to delete this user?",
        icon: <ExclamationCircleOutlined />,
        content: "This action cannot be undone.",
        okText: "Yes, Delete",
        okType: "danger",
        cancelText: "No, Cancel",
        onOk() {
          handleDelete(user_id); // Call delete function with user_id
        }
      });
    };

    const showUpdateConfirm = () => {
      navigate(`/dashboard/update-user?user_id=${user_id}`); // Navigate to update user page
    };

    const handleDelete = async (user_id) => {
      try {
        await dispatch(updateUserDetailsAction({ user_id, status: "delete" }));
        message.success("User deleted successfully.");
      } catch (error) {
        message.error("Something went wrong while deleting the user.");
      }
    };

    switch (e.key) {
      case "update":
        showUpdateConfirm();
        break;
      case "remove":
        showDeleteConfirm();
        break;
      default:
        message.error("Action not found.");
    }
  };

  const actionMenu = (record) => (
    <Menu onClick={handleMenuClick(record)} className="actions-menu">
      <Menu.Item key="update">Update Details</Menu.Item>
      <Menu.Item key="remove">Remove User</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      fixed: "left",
      width: 70
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span>
          <Avatar
            src={
              record?.photo
                ? `${process.env.REACT_APP_IMAGES_BASE_URL}${record?.photo}`
                : undefined
            }
            icon={!record?.photo ? <UserOutlined /> : null}
            alt={text || "User"}
            style={{ marginRight: 8 }}
          />
          <a>{`${record?.firstName} ${record?.lastName}`}</a>
        </span>
      ),
      fixed: "left",
      width: 300,
      sorter: (a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Name`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const fullName = `${record.firstName} ${record.lastName}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Email`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.email.toLowerCase().includes(value.toLowerCase()),
      render: (email) => {
        const handleCopy = () => {
          navigator.clipboard.writeText(email);
          message.success("Email copied to clipboard!");
        };

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <span>{email}</span>
            <Tooltip title="Copy Email">
              <CopyOutlined
                onClick={handleCopy}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: "Role",
      dataIndex: "user_type",
      key: "user_type",
      render: (user_type) => {
        const role = All_ROLES.find((option) => option.value === user_type);
        return role ? role.label : user_type;
      },
      width: 200,
      filters: All_ROLES.map((role) => ({
        text: role.label,
        value: role.value
      })),
      onFilter: (value, record) => record.user_type === value
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (createdAt) => {
        const date = new Date(createdAt);
        return date
          .toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
          })
          .replace(",", "");
      },
      width: 200
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Space size="large">
          <Dropdown overlay={actionMenu(record)}>
            <Button
              onClick={(e) => e.preventDefault()}
              shape="circle"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Space>
      ),
      width: 90
    }
  ];

  const users = userData?.data?.map((item) => ({
    user_id: item.user_id,
    firstName: item.first_name,
    lastName: item.last_name,
    email: item.email,
    user_type: item.user_type,
    createdAt: item.createdAt,
    photo: item.photo
  }));

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <div className="main-header" data-ui="header">
            <div className="px-4">
              <div className="flag">
                <div className="flag__section">
                  <a className="external incognito-link inline-block mb-m mt-m">
                    <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                      Dashboard
                    </h1>
                  </a>
                </div>

                <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
                  <Button
                    type="button"
                    className="btn btn--primary mt-m text-white pull-right main_border_radius"
                    onClick={() => navigate("/dashboard/create-user")}
                  >
                    Create User
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Layout style={{ background: "#fff", height: "100vh" }}>
            <Sider width={300} className="site-layout-background bg-light">
              <div className="p-3 bg-light">
                <Form
                  layout="vertical"
                  onValuesChange={(changedValues) => {
                    Object.keys(changedValues).forEach((key) =>
                      handleFilterChange(key, changedValues[key])
                    );
                  }}
                  initialValues={filters}
                >
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <span className="d-flex align-items-center">
                      <h2 className="m-0 ml-2">Filters</h2>
                    </span>
                    <Button
                      type=""
                      className="cursor-pointer"
                      onClick={handleClearFilters}
                    >
                      Reset all
                    </Button>
                  </div>
                  <Collapse
                    defaultActiveKey={["1", "2", "3"]}
                    expandIconPosition="right"
                    style={{ background: "#fff", border: "none" }}
                  >
                    <Panel header="User Type" key="1">
                      <Radio.Group
                        value={filters?.user_type}
                        onChange={(e) =>
                          handleFilterChange("user_type", e.target.value)
                        }
                      >
                        <div className="d-flex justify-content-between mb-2">
                          <Radio value="">All</Radio>
                        </div>
                        {roleOptions?.map((item) => (
                          <div
                            className="d-flex justify-content-between mb-2"
                            key={item.value}
                          >
                            <Radio value={item?.value}>{item?.label}</Radio>
                          </div>
                        ))}
                      </Radio.Group>
                    </Panel>
                    <Panel header="User Status" key="2">
                      <Radio.Group
                        value={filters?.status}
                        onChange={(e) =>
                          handleFilterChange("status", e.target.value)
                        }
                      >
                        <div className="d-flex justify-content-between mb-2">
                          <Radio value="">All</Radio>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <Radio value="signup">Signup</Radio>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <Radio value="registered">Registered</Radio>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <Radio value="hold">Hold</Radio>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <Radio value="suspended">Suspended</Radio>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <Radio value="delete">Deleted</Radio>
                        </div>
                      </Radio.Group>
                    </Panel>
                  </Collapse>
                </Form>
              </div>
            </Sider>
            <Divider type="vertical" style={{ height: "100vh" }} />
            <Layout style={{ padding: "0 24px 24px" }}>
              {userLoading ? (
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999
                  }}
                >
                  <TailSpin ariaLabel="Loading..." color="#005c53" />
                </div>
              ) : (
                <Content>
                  <div className="cl-section-header row justify-content-between align-items-end py-3">
                    <div className="col d-flex align-items-center"></div>
                    <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
                      <Button
                        className="ms-2"
                        onClick={() => handleRefresh()} // To reset search
                        icon={<ReloadOutlined />}
                        type="primary"
                        style={{ backgroundColor: "#00756a" }}
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>
                  <Table
                    columns={columns}
                    dataSource={users}
                    pagination={false}
                    rowKey="user_id"
                    scroll={{
                      x: 1300
                    }}
                    bordered
                  />
                  <div className="pagination-container mt-3">
                    <Pagination
                      className="text-center align-content-center mt-3"
                      current={currentPage}
                      pageSize={pageSize}
                      total={totalUsers}
                      showSizeChanger
                      onChange={handlePageChange}
                      pageSizeOptions={[5, 10, 20, 50, 100]}
                      showTotal={(total) => `Total ${total} users`}
                    />
                  </div>
                </Content>
              )}
            </Layout>
          </Layout>
        </>
      )}
    </>
  );
};

export default ClientAdminUserManagementMain;
