import { v4 as uuidv4 } from 'uuid';
import {
  addCandidateConstants,
  addClientConstants,
  applyJobConstants,
  getAllCandidateActions,
  getClientDetailsConstants,
  getClientJobsConstants,
  getClientListConstants,
  getJobDetailConstants, postFeedbackConstants,
  resumeParserConstants,
  updateClientDetailsConstants,
  updateJobApplicationConstants,
  updateJobConstants,
  getCandidateProfileConstants,
  createJobConstants,
  getJobConstants,
  getJobApplicationConstants,
  enquiryConstants,
  getEnquiryConstants,
  updateEnquiryConstants,
  sendOtpConstants,
  verifyOtpConstants,
  verifyEmailConstants,
  updateCandidateConstants,
  matchingAlgoConstants, multipleActionConstants,
  getJobAllAppliedCandidateConstants,
  getFeedbackListConstant,
  updateFeedbackConstant,
  assignToRecruiterConstants,
  emailSubscribeConstants,
  getPresignedUrlConstants,
  uploadImageFileConstants,
  getEmailTemplatesConstants,
  updateEmailTemplatesConstants
} from "../../constants";


export function uploadImageAction(data) {
  return { type: getPresignedUrlConstants.UPLOAD_IMAGE_REQUEST, data };
}

export function uploadImageFileAction(data) {
  return { type: uploadImageFileConstants.UPLOAD_IMAGE_FILE_REQUEST, data };
}

export function resumeParserAction(data) {
  return { 
    type: resumeParserConstants.RESUME_PARSER_REQUEST, 
    data 
  };
}
export function getJobDetailAction(data) {
  return { type: getJobDetailConstants.GET_JOB_DETAIL_REQUEST, data };
}
export function applyJobAction(data) {
  return { type: applyJobConstants.APPLY_JOB_REQUEST, data };
}
export function addClientAction(data) {
  return { type: addClientConstants.ADD_CLIENT_REQUEST, data };
}
export function getClientListAction(data) {
  return { type: getClientListConstants.GET_CLIENT_LIST_REQUEST, data };
}
export function getClientDetailsAction(data) {
  return { type: getClientDetailsConstants.GET_CLIENT_DETAIL_REQUEST, data };
}
export function updateClientDetailsAction(data) {
  return {
    type: updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_REQUEST,
    data
  };
}
export function getAllCandidateAction(data) {
  return { type: getAllCandidateActions.GET_ALL_CANDIDATE_REQUEST, data };
}
export function getClientJobAction(data) {
  return { type: getClientJobsConstants.GET_CLIENT_JOBS_REQUEST, data };
}
export function updateJobDetailAction(data) {
  return { type: updateJobConstants.UPDATE_JOB_REQUEST, data };
}
export function addCandidateAction(data) {
  return { type: addCandidateConstants.ADD_CANDIDATE_REQUEST, data };
}
export function updateJobApplicationAction(data) {
  return {
    type: updateJobApplicationConstants.UPDATE_APPLICATION_REQUEST,
    data
  };
}

export function postFeedbackAction(data) {
  return {
    type: postFeedbackConstants.POST_FEEDBACK_REQUEST,
    data
  };
}

export const candidateProfileAction = (data) => {
  return { type: getCandidateProfileConstants.CANDIDATE_PROFILE_REQUEST, data };
};
export function createJobAction(data) {
  return { type: createJobConstants.CREATE_JOB_REQUEST, data };
}
export function getJobAction(data) {
  return { type: getJobConstants.GET_JOB_REQUEST, data };
}
export function getJobApplicationAction(data) {
  return { type: getJobApplicationConstants.GET_JOB_APPLICATION_REQUEST, data };
}
export function enquiryAction(data) {
  return { type: enquiryConstants.ENQUIRY_REQUEST, data };
}
export function getEnquiryAction(data) {
  return { type: getEnquiryConstants.GET_ENQUIRY_REQUEST, data };
}
export function updateEnquiryAction(data) {
  return { type: updateEnquiryConstants.UPDATE_ENQUIRY_REQUEST, data };
}
export function sendOtpAction(data) {
  return { type: sendOtpConstants.SEND_OTP_REQUEST, data };
}
export function verifyOtpAction(data) {
  return { type: verifyOtpConstants.VERIFY_OTP_REQUEST, data };
}
export function verifyEmailAction(data) {
  return { type: verifyEmailConstants.VERIFY_EMAIL_REQUEST, data };
}

export function updateCandidateAction(data) {
  return { type: updateCandidateConstants.UPDATE_CANDIDATE_REQUEST, data };
}
export function matchingAlgoAction(data) {
  return { type: matchingAlgoConstants.MATCHING_ALGO_REQUEST, data };
}

export function multipleAction(data) {
  return { type: multipleActionConstants.MULTIPLE_ACTION_REQUEST, data };
}
export function getJobAllAppliedCandidateAction(data) {
  return {
    type: getJobAllAppliedCandidateConstants.GET_JOB_ALL_APPLIED_CANDIDATE_REQUEST,
    data
  };
}
export function getFeedbackListAction(data) {
  return {
    type: getFeedbackListConstant.GET_FEEDBACK_LIST_REQUEST,
    data
  };
}
export function updateFeedbackAction(data) {
  return {
    type: updateFeedbackConstant.UPDATE_FEEDBACK_LIST_REQUEST,
    data
  };
}
export function assignToRecruiterAction(data) {
  return {
    type: assignToRecruiterConstants.ASSIGN_TO_RECRUITER_REQUEST,
    data
  };
}

export function emailSubscribeAction(data) {
  return { type: emailSubscribeConstants.EMAIL_SUBSCRIBE_REQUEST, data };
}
export function getEmailTemplatesAction(data) {
  return { type: getEmailTemplatesConstants.GET_EMAIL_TEMPLATES_REQUEST, data };
}
export function updateEmailTemplatesAction(data) {
  return { type: updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_REQUEST, data };
}
