import React from 'react'
import { Card } from "antd";
import { useEffect, useState } from "react";

const Countdown = ({ seconds, currentTestIndex, currentTest }) => {
    const [countdown, setCountdown] = useState(seconds);
  
    useEffect(() => {
      const timerId = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
  
      return () => clearInterval(timerId);
    }, []);
  
    return (
      <div className="d-flex justify-content-center align-items-center vh-100" style={{backgroundColor:"#f5f5f5"}}>
        <Card
          style={{
            width: "40%",
            maxWidth: "1800px",
            margin: "0 auto",
  
            padding: "20px",
            height: "auto",
          }}
        >
          <div className="row">
            <div className="col" style={{ marginLeft: "10%" }}>
              <p>This is the {currentTestIndex + 1}  test:</p>
              <br />
              <h2>
                <strong>{currentTest?.test_title}</strong>
              </h2>
              <br />
              <br />
              <p>The test starts now.</p>
              <p>Good luck!</p>
              <br />
            </div>
            <div className="col d-flex flex-column"></div>
            <div className=" justify-content-center mt-4">
              <p style={{ marginLeft: "33%" }}>
                <strong>The test will start in </strong>
                <strong style={{ color: "#AE1EA4" }}>{countdown} seconds</strong>
              </p>
              <br />
              <p style={{ marginLeft: "10%", marginRight: "10%" }}>
                Please stay on this screen. the timer for your next test has
                started and cannot be paused. The timmer continues if you close
                the browser.
              </p>
            </div>
          </div>
        </Card>
      </div>
    );
  };
  export default Countdown
