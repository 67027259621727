import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Collapse,
  Button,
  Form,
  Input,
  Radio,
  Pagination,
  Table,
  Checkbox,
  Typography,
  message,
  Space,
  Drawer,
} from "antd";
import { TailSpin } from "react-loader-spinner";
import { getAllQuestionListAction } from "../../../../redux/actions/assessment";
import debounce from "lodash/debounce";
import { SearchOutlined } from "@ant-design/icons";
import QuestionDrawer from "../Modal/QuestionDrawer";
import { QUESTION_TYPE } from "../../data";
import "../../../../Assets/css/filter.css";

const { Panel } = Collapse;
const { Sider } = Layout;

const FilterForm = ({
  filters,
  handleFilterChange,
  handleClearFilters,
  handleSearch,
  searchText,
}) => (
  <Form layout="vertical" initialValues={filters}>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h2 className="m-0">Filters</h2>
      <Button type="ghost" onClick={handleClearFilters}>
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2"]}
      expandIconPosition="right"
      style={{ background: "#ffff", border: "none" }}
    >
      <Panel header="Search by question name" key="1">
        <Input
          placeholder="Type here..."
          value={searchText}
          onChange={handleSearch}
        />
      </Panel>
      <Panel header="Select Question Type" key="2">
        <Radio.Group
          onChange={(e) => handleFilterChange({ questionType: e.target.value })}
          value={filters.questionType}
        >
          <div className="d-flex flex-column">
            <Radio value="">All</Radio>
            <Radio value="mcq">Multiple Choice</Radio>
            <Radio value="code">Coding</Radio>
            <Radio value="questionnaire">Questionnaire</Radio>
          </div>
        </Radio.Group>
      </Panel>
    </Collapse>
  </Form>
);

const ChooseQuestionStep = ({ form, initialData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({
    questionType: "",
    search: "",
  });

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const dispatch = useDispatch();
  const getAllQuestionListReducer = useSelector(
    (state) => state.getAllQuestionListReducer
  );

  const fetchQuestionList = useCallback(() => {
    setLoading(true);
    dispatch(
      getAllQuestionListAction({
        page: currentPage,
        limit: pageSize,
        ...filters,
      })
    );
  }, [dispatch, currentPage, pageSize, filters]);

  useEffect(() => {
    fetchQuestionList();
    return () => {
      dispatch({ type: "GET_LIST_QUESTION_RESET" });
    };
  }, [fetchQuestionList]);

  useEffect(() => {
    const { loading, data, error } = getAllQuestionListReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.data);
      setTotalQuestions(data?.totalQuestions);

      // Pre-select questions if initialData is passed
      if (initialData?.questionIds) {
        setSelectedQuestions(initialData.questionIds);
        form.setFieldsValue({ questionIds: initialData.questionIds });
      }
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getAllQuestionListReducer, form, initialData]);

  // Debounce search input
  const debouncedSearch = useCallback(
    debounce((value) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: value,
      }));
    }, 500),
    []
  );

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleFilterChange = (changedFields) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...changedFields,
    }));
  };

  const handleClearFilters = () => {
    setFilters({
      questionType: "",
      search: "",
    });
    setSearchText("");
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleQuestionSelect = (e, question) => {
    const { question_id } = question;
    const updatedSelectedQuestions = e.target.checked
      ? [...selectedQuestions, question_id]
      : selectedQuestions.filter((id) => id !== question_id);

    setSelectedQuestions(updatedSelectedQuestions);
    form.setFieldsValue({ questionIds: updatedSelectedQuestions });
  };

  const handleViewDetails = (record) => {
    setSelectedQuestion(record);
    setDrawerVisible(true);
  };

  const columns = [
    {
      title: "Select",
      dataIndex: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedQuestions.includes(record.question_id)}
          onChange={(e) => handleQuestionSelect(e, record)}
        />
      ),
      width: 100,
      ellipsis: true,
    },
    {
      title: "Question Name",
      dataIndex: "questionName",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Question Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.questionName.toLowerCase().includes(value.toLowerCase()),
      render: (text) => (
        <Typography.Title level={5} style={{ margin: 0 }}>
          {text}
        </Typography.Title>
      ),
      width: 200,
    },
    {
      title: "Type",
      dataIndex: "questionType",
      width: 150,
      filters: QUESTION_TYPE.map((option) => ({
        text: option.label,
        value: option.value,
      })),
      onFilter: (value, record) => record.questionType === value,
      render: (type) => {
        const questionType = QUESTION_TYPE.find(
          (option) => option.value === type
        );
        return questionType ? questionType.label : type;
      },
    },
    {
      title: "Time",
      dataIndex: "recommendedTime",
      sorter: (a, b) => a.recommendedTime - b.recommendedTime,
      render: (time) => `${time} mins`,
      width: 100,
    },
    {
      title: "Details",
      dataIndex: "tags",
      render: (_, record) => (
        <Button type="link" onClick={() => handleViewDetails(record)}>
          View Details
        </Button>
      ),
      width: 150,
    },
  ];

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <section className="mx-16" style={{ paddingBottom: "25%" }}>
      <h2>Step 2: Choose Questions</h2>
      <Form form={form} layout="vertical">
        <Form.Item
          name="questionIds"
          rules={[
            { required: true, message: "Please select at least one question!" },
          ]}
          initialValue={selectedQuestions}
        />
      </Form>

      <Layout
        style={{ background: "#fff", minHeight: "100vh", borderRadius: "18px" }}
      >
        {!isMobile && (
          <Sider width={300} style={{ background: "#fff" }}>
            <div className="p-3 bg-light" style={{ height: "100vh"}}>
              <FilterForm
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleClearFilters={handleClearFilters}
                handleSearch={handleSearch}
                form={form}
              />
            </div>
          </Sider>
        )}
        <Layout style={{ padding: "0 5px 5px", background: "white" }}>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <>
              <div style={{ overflowX: "auto" }}> {/* Added horizontal scroll */}
                <Table
                  bordered
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  rowKey="question_id"
                  style={{
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    borderRadius: 8,
                    marginBottom: "20px",
                  }}
                />
              </div>
              <Pagination
                className="text-center mt-3"
                current={currentPage}
                total={totalQuestions}
                pageSize={pageSize}
                onChange={handlePageChange}
                showSizeChanger
                pageSizeOptions={[5, 10, 20, 50, 100]}
                showTotal={(total) => `Total ${total} Questions`}
              />
            </>
          )}
        </Layout>
        {drawerVisible && (
          <QuestionDrawer
            question_id={selectedQuestion?.question_id}
            drawerVisible={drawerVisible}
            setDrawerVisible={setDrawerVisible}
          />
        )}
      </Layout>
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="100%"
        className="filter-drawer"
      >
        <FilterForm
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handleSearch={handleSearch}
          form={form}
        />
      </Drawer>
    </section>
  );
};

export default ChooseQuestionStep;
