import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Divider,
  Row,
  Col,
  Modal,
  message,
  Checkbox
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { TailSpin } from "react-loader-spinner";
import PdfViewer from "../../PdfViewer";
import {
  candidateProfileAction,
  updateCandidateAction
} from "../../../redux/actions/common";
import { REACT_USER_ID } from "../../Auth/tokenProvider";
import moment from "moment";
import ImageUploadComponent from "../../Upload";

const CandidateProfile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [educationFormVisible, setEducationFormVisible] = useState(false);
  const [experienceFormVisible, setExperienceFormVisible] = useState(false);

  const [educationData, setEducationData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [photoModalVisible, setPhotoModalVisible] = useState(false);
  const [resumeModalVisible, setResumeModalVisible] = useState(false);
  const [editingEducationIndex, setEditingEducationIndex] = useState(null);
  const [editingExperienceIndex, setEditingExperienceIndex] = useState(null);
  const candidateProfileReducer = useSelector(
    (state) => state.candidateProfileReducer
  );
  const updateCandidateReducer = useSelector(
    (state) => state.updateCandidateReducer
  );

  useEffect(() => {
    const candidate_id = REACT_USER_ID();
    if (candidate_id) {
      dispatch(candidateProfileAction(candidate_id));
    }
    return () => {
      dispatch({ type: "CANDIDATE_PROFILE_RESET" });
    }
  }, [dispatch, updateCandidateReducer]);

  useEffect(() => {
    const { loading, data, error } = candidateProfileReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      form.setFieldsValue(data);
      setEducationData(data.education || []);
      setExperienceData(data.experience || []);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [candidateProfileReducer, form]);
  useEffect(() => {
    const { loading, data, error } = updateCandidateReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      message.success("Updated!");
      localStorage.setItem("REACT_USER_PHOTO", data?.candidate?.photo);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [updateCandidateReducer, form]);

  const handleEducationEdit = (index) => {
    setEditingEducationIndex(index);
    setEducationFormVisible(true);
  };

  const handleExperienceEdit = (index) => {
    setEditingExperienceIndex(index);
    setExperienceFormVisible(true);
  };

  const handleEducationSave = (values) => {
    if (editingEducationIndex !== null) {
      setEducationData((prev) => {
        const updated = [...prev];
        updated[editingEducationIndex] = values;
        return updated;
      });
      setEditingEducationIndex(null);
    } else {
      setEducationData((prev) => [...prev, values]);
    }
    setEducationFormVisible(false);
  };

  const handleExperienceSave = (values) => {
    if (editingExperienceIndex !== null) {
      setExperienceData((prev) => {
        const updated = [...prev];
        updated[editingExperienceIndex] = values;
        return updated;
      });
      setEditingExperienceIndex(null);
    } else {
      setExperienceData((prev) => [...prev, values]);
    }
    setExperienceFormVisible(false);
  };

  const handleDelete = (id, type) => {
    if (type === "education") {
      setEducationData((prev) => prev.filter((_, index) => index !== id));
    } else if (type === "experience") {
      setExperienceData((prev) => prev.filter((_, index) => index !== id));
    }
  };

  const onFinish = (values) => {
    console.log("Form Values:", {
      ...values,
      education: educationData,
      experience: experienceData
    });
    const resume = form.getFieldValue("resume");
    const photo = form.getFieldValue("photo");
    const candidate_id = REACT_USER_ID();

    dispatch(
      updateCandidateAction({
        ...values,
        candidate_id: candidate_id,
        education: educationData,
        experience: experienceData,
        photo,
        resume
      })
    );
  };

  if (loading) {
    return (
      <div style={spinnerStyle}>
        <TailSpin ariaLabel="Loading..." color="#005c53" />
      </div>
    );
  }

  return (
    <div className="container">
      <Divider>Personal Informations </Divider>
      <Form form={form} onFinish={onFinish} layout="vertical">
        {/* Photo Section */}
        <PhotoSection
          photo={form.getFieldValue("photo")}
          onPhotoChange={() => setPhotoModalVisible(true)}
        />
        <PhotoModal
          visible={photoModalVisible}
          onCancel={() => setPhotoModalVisible(false)}
          form={form}
        />
        <ProfileForm form={form} />

        <Divider>Education & Experience </Divider>

        {/* Education and Experience Sections */}
        <EducationMap
          data={educationData}
          onAdd={() => setEducationFormVisible(true)}
          onDelete={handleDelete}
          onEdit={handleEducationEdit}
        />

        <EducationModal
          visible={educationFormVisible}
          onCancel={() => {
            setEducationFormVisible(false);
            setEditingEducationIndex(null);
          }}
          onSave={handleEducationSave}
          initialData={
            editingEducationIndex !== null
              ? educationData[editingEducationIndex]
              : null
          }
        />
        <ExperienceMap
          data={experienceData}
          onAdd={() => setExperienceFormVisible(true)}
          onDelete={handleDelete}
          onEdit={handleExperienceEdit}
        />

        <ExperienceModal
          visible={experienceFormVisible}
          onCancel={() => {
            setExperienceFormVisible(false);
            setEditingExperienceIndex(null);
          }}
          onSave={handleExperienceSave}
          initialData={
            editingExperienceIndex !== null
              ? experienceData[editingExperienceIndex]
              : null
          }
        />

        {/* Resume Section */}
        <ResumeSection
          resume={form.getFieldValue("resume")}
          onResumeChange={() => setResumeModalVisible(true)}
        />

        <Form.Item label="Comment (optional)" name="summary">
          <Input.TextArea rows={4} />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          style={{ backgroundColor: "#005c53" }}
        >
          Save Profile
        </Button>
      </Form>

      {/* Modals */}
      <EducationModal
        visible={educationFormVisible}
        onCancel={() => setEducationFormVisible(false)}
        onSave={handleEducationSave}
      />
      <ExperienceModal
        visible={experienceFormVisible}
        onCancel={() => setExperienceFormVisible(false)}
        onSave={handleExperienceSave}
      />

      <ResumeModal
        visible={resumeModalVisible}
        onCancel={() => setResumeModalVisible(false)}
        form={form}
      />
    </div>
  );
};

const PhotoSection = ({ photo, onPhotoChange }) => (
  <>
    <div data-ui="photo">
      <div className="styles--36XiB">
        <p>Photo</p>
        <Button
          type="link"
          onClick={onPhotoChange}
          style={{ backgroundColor: "#005c53", color: "#fff" }}
        >
          Change
        </Button>
      </div>
    </div>
    <div>
      {photo ? (
        <img
          className="m-auto"
          src={`${process.env.REACT_APP_IMAGES_BASE_URL}${photo}`}
          alt="Profile"
          style={profileImageStyle}
        />
      ) : (
        <>
          <p style={{ color: "#888", marginBottom: "10px" }}>
            You don't have a photo uploaded. Upload here:
          </p>
          <Button onClick={onPhotoChange}>Upload Photo</Button>
        </>
      )}
    </div>
  </>
);

const ProfileForm = ({ form }) => (
  <>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          label="First name"
          name="firstName"
          rules={[{ required: true, message: "Please input your first name!" }]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[{ required: true, message: "Please input your last name!" }]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>
    </Row>

    <Form.Item
      label="Phone number"
      name="phone"
      rules={[{ required: true, message: "Please input your phone number!" }]}
    >
      <Input size="large" />
    </Form.Item>
    <Form.Item
      label="Address"
      name="address"
      rules={[{ required: true, message: "Please enter your address" }]}
    >
      <Input.TextArea size="large" rows={4} />
    </Form.Item>
  </>
);

const ResumeSection = ({ resume, onResumeChange }) => (
  <>
    <Divider>Resume</Divider>
    <div data-ui="resume">
      <div className="styles--36XiB">
        <p>Resume</p>
        <Button
          type="link"
          onClick={onResumeChange}
          style={{ backgroundColor: "#005c53", color: "#fff" }}
        >
          Change
        </Button>
      </div>
    </div>
    <div style={dashedBoxStyle}>
      {resume ? (
        <PdfViewer
          resumeSrc={`${process.env.REACT_APP_IMAGES_BASE_URL}${resume}`}
        />
      ) : (
        <>
          <p style={{ color: "#888", marginBottom: "10px" }}>
            You don't have a resume uploaded. Upload here:
          </p>
          <Button onClick={onResumeChange}>Upload Resume</Button>
        </>
      )}
    </div>
  </>
);

const EducationModal = ({ visible, onCancel, onSave, initialData }) => (
  <Modal
    title={initialData ? "Edit Education" : "Add Education"}
    visible={visible}
    onCancel={onCancel}
    footer={null}
    centered
  >
    <EducationForm
      onSave={onSave}
      onCancel={onCancel}
      initialValues={{
        ...initialData,
        startDate: moment(initialData?.startDate),
        endDate: moment(initialData?.endDate)
      }}
    />
  </Modal>
);

const ExperienceModal = ({ visible, onCancel, onSave, initialData }) => (
  <Modal
    title={initialData ? "Edit Experience" : "Add Experience"}
    visible={visible}
    onCancel={onCancel}
    footer={null}
    centered
  >
    <ExperienceForm
      onSave={onSave}
      onCancel={onCancel}
      initialValues={{
        ...initialData,
        startDate: moment(initialData?.startDate),
        endDate: moment(initialData?.endDate)
      }}
    />
  </Modal>
);

const PhotoModal = ({ visible, onCancel, form }) => {
  const dispatch = useDispatch();
  const candidate_id = REACT_USER_ID();
  const [loading, setLoading] = useState(false);
  const handlePhotoUpload = (profileImageUrl) => {
    setLoading(true);
    form.setFieldsValue({ ["photo"]: profileImageUrl });
    dispatch(
      updateCandidateAction({
        candidate_id,
        photo: profileImageUrl
      })
    );
    setLoading(false);
    onCancel();
  };

  return (
    <Modal
      title="Upload Photo"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <ImageUploadComponent
       
        uploadPath="hire_talent/profile/"
        acceptedFileTypes="image/*"
        uploadType="Photo"
        onFileUpload={handlePhotoUpload}
        existingFileUrl={form.getFieldValue("photo")}
      />
    </Modal>
  );
};

const ResumeModal = ({ visible, onCancel, form }) => {
  const dispatch = useDispatch();
  const candidate_id = REACT_USER_ID();
  const [loading, setLoading] = useState(false);
  const handleResumeUpload = (resumeUrl) => {
    setLoading(true);
    form.setFieldsValue({ ["resume"]: resumeUrl });
    dispatch(
      updateCandidateAction({
        candidate_id,
        resume: resumeUrl
      })
    );
    setLoading(true);
    onCancel();
  };

  return (
    <Modal
      title="Upload Resume"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <ImageUploadComponent
        
        uploadPath="hire_talent/resume/"
        acceptedFileTypes="application/pdf, .pdf"
        uploadType="PDF Resume"
        onFileUpload={handleResumeUpload}
        existingFileUrl={form.getFieldValue("resume")}
      />
    </Modal>
  );
};

const EducationMap = ({ data, onAdd, onDelete, onEdit }) => (
  <div data-ui="education">
    <div
      className="styles--36XiB"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <p style={{ fontSize: "18px", fontWeight: "bold" }}>Education</p>
      <Button
        type="button"
        onClick={onAdd}
        className="button--2de5X"
        style={{ backgroundColor: "#005c53", color: "#fff" }}
      >
        + Add
      </Button>
    </div>
    {data.length > 0 ? (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {data.map((item, index) => (
          <li
            key={index}
            style={{ borderBottom: "1px solid #d9d9d9", padding: "10px 0" }}
          >
            <div
              data-ui="group"
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <dl>
                <dt style={{ fontWeight: "bold" }}>School:</dt>
                <dd>{item.school}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Field of Study:</dt>
                <dd>{item.fieldOfStudy}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Degree:</dt>
                <dd>{item.degree}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Start Date:</dt>
                <dd>
                  <dd>{moment(item.startDate).format("YYYY-MM-DD")}</dd>
                </dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>End Date:</dt>
                <dd>
                  <dd>{moment(item.endDate).format("YYYY-MM-DD")}</dd>
                </dd>
              </dl>

              <div
                className="styles--3RCU1"
                style={{ display: "flex", gap: "10px" }}
              >
                <Button
                  type="link"
                  onClick={() => onEdit(index)}
                  icon={<EditOutlined />}
                  style={{ color: "#005c53" }}
                />
                <Button
                  type="link"
                  onClick={() => onDelete(index, "education")}
                  icon={<DeleteOutlined />}
                  style={{ color: "#d9534f" }}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          border: "2px dashed #d9d9d9",
          borderRadius: "5px"
        }}
      >
        No education data available. Please add your education details.
      </div>
    )}
  </div>
);

const ExperienceMap = ({ data, onAdd, onDelete, onEdit }) => (
  <div data-ui="experience">
    <div
      className="styles--36XiB"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <p style={{ fontSize: "18px", fontWeight: "bold" }}>Experience</p>
      <Button
        type="button"
        onClick={onAdd}
        className="button--2de5X"
        style={{ backgroundColor: "#005c53", color: "#fff" }}
      >
        + Add
      </Button>
    </div>
    {data.length > 0 ? (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {data.map((item, index) => (
          <li
            key={index}
            style={{ borderBottom: "1px solid #d9d9d9", padding: "10px 0" }}
          >
            <div
              data-ui="group"
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <dl>
                <dt style={{ fontWeight: "bold" }}>Title:</dt>
                <dd>{item.title}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Company:</dt>
                <dd>{item.company}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Summary:</dt>
                <dd>{item.summary}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>Start Date:</dt>
                <dd>{moment(item.startDate).format("YYYY-MM-DD")}</dd>
              </dl>
              <dl>
                <dt style={{ fontWeight: "bold" }}>End Date:</dt>

                <dd>{moment(item.endDate).format("YYYY-MM-DD")}</dd>
              </dl>

              <div
                className="styles--3RCU1"
                style={{ display: "flex", gap: "10px" }}
              >
                <Button
                  type="link"
                  onClick={() => onEdit(index)}
                  icon={<EditOutlined />}
                  style={{ color: "#005c53" }}
                />
                <Button
                  type="link"
                  onClick={() => onDelete(index, "experience")}
                  icon={<DeleteOutlined />}
                  style={{ color: "#d9534f" }}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          border: "2px dashed #d9d9d9",
          borderRadius: "5px"
        }}
      >
        No experience data available. Please add your experience details.
      </div>
    )}
  </div>
);
const EducationForm = ({ onSave, onCancel, initialValues }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  const onFinish = (values) => {
    onSave(values);
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        name="school"
        label="School"
        rules={[{ required: true, message: "Please enter your school name" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="fieldOfStudy"
        label="Field of Study"
        rules={[
          { required: true, message: "Please enter your field of study" }
        ]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="degree"
        label="Degree"
        rules={[{ required: true, message: "Please enter your degree" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[
                { required: true, message: "Please select the start date" }
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                //disabledDate={(current) => current && current > Date.now()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="endDate" label="End Date">
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                /* disabledDate={(current) =>
                current &&
                current <=
                  (form.getFieldValue("startDate")
                    ? form.getFieldValue("startDate").endOf("day")
                    : Date.now())
              }*/
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Row gutter={16}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#005c53" }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const ExperienceForm = ({ onSave, onCancel, initialValues }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  const onFinish = (values) => {
    onSave(values);
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Please enter your title" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="company"
        label="Company"
        rules={[{ required: true, message: "Please enter your company name" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="industry"
        label="Industry"
        rules={[{ required: true, message: "Please enter the industry" }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item name="summary" label="Summary">
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[
                { required: true, message: "Please select the start date" }
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                disabledDate={(current) => current && current > Date.now()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="endDate" label="End Date">
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                disabledDate={(current) =>
                  current &&
                  current <=
                    (form.getFieldValue("startDate")
                      ? form.getFieldValue("startDate").endOf("day")
                      : Date.now())
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item name="currentlyWorking" valuePropName="checked">
        <Checkbox>Currently Working Here</Checkbox>
      </Form.Item>
      <Row gutter={16}>
        <Col>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#005c53" }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const spinnerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "60vh"
};

const dashedBoxStyle = {
  border: "1px dashed #ddd",
  padding: "20px",
  textAlign: "center",
  borderRadius: "5px"
};

const profileImageStyle = {
  maxWidth: "100%",
  borderRadius: "50%",
  width: "150px",
  height: "150px",
  objectFit: "cover",
  border: "2px solid #005c53"
};
export default CandidateProfile;
