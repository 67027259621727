import React, { useEffect, useState } from "react";
import { Card, Button, Typography, Divider, Row, Col } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const AssessmentCompletionCard = () => {
  const [redirectTime, setRedirectTime] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setRedirectTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    if (redirectTime === 0) {
      window.location.assign(`/candidate-login`);
    }

    return () => clearInterval(interval);
  }, [redirectTime]);

  return (
    <Row justify="center" style={{ minHeight: "100vh", alignItems: "center" }}>
      <Col xs={22} sm={18} md={16} lg={12} xl={10}>
        <Card
          style={{
            borderRadius: "8px",
            padding: "20px",
            textAlign: "center",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Title level={3}>
            <strong>Great job! You've completed your assessment</strong>
          </Title>
          <Divider style={{ borderColor: "#00756a" }} />
          <Paragraph>
            You're being redirected in {redirectTime} seconds. Would you like to
            see your results now? We will automatically redirect you to{" "}
            <strong style={{ color: "#00756a" }}>
              create your MentorPal Profile
            </strong>{" "}
            and view your assessment outcome.
          </Paragraph>
          <Divider />
          <Row justify="space-between" align="middle">
            <Col>
              <Paragraph>
                Redirecting in{" "}
                <strong style={{ color: "#00756a" }}>
                  {redirectTime} seconds
                </strong>
              </Paragraph>
            </Col>
            <Col>
              <Button
                type="primary"
                className="custom-button"
                onClick={() => {
                  window.location.assign(`/candidate-login`);
                }}
                icon={<ArrowRightOutlined />}
              >
                Go there now
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default AssessmentCompletionCard;
