import { jwtDecode } from "jwt-decode";

export const getAccessToken = () => {
  return localStorage.getItem("REACT_TOKEN_AUTH");
};

const getDecodedTokenClaim = (claim) => {
  const token = getAccessToken();
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken[claim] || null;
    } catch (error) {
      console.error("Token is invalid:", error);
      return null;
    }
  }
  return null;
};

export const REACT_USER_ID = () => getDecodedTokenClaim("user_id");
export const REACT_USER_EMAIL_ID = () => getDecodedTokenClaim("email");
export const REACT_USER_TYPE = () => getDecodedTokenClaim("user_type");
export const REACT_CLIENT_ID = () => getDecodedTokenClaim("client_id");

export const isLogged = !!getAccessToken();
