import { call, delay, put } from "redux-saga/effects";
import {
  createTestCall,
  createQuestionCall,
  createAssessmentCall,
  getAllTestCall,
  getAllQuestionCall,
  getAllAssessmentCall,
  getInviteAssessmentDetailsCall,
  getTestDetailsCall,
  getQuestionDetailsCall,
  getAssessmentDetailsCall,
  updateInviteAssessmentDetailsCall,
  updateTestCall,
  updateQuestionCall,
  updateAssessmentCall,
  assessmentLoginCall,
  getAllAssessmentCandidateCall,
  getAssessmentCandidateDetailsCall,
  inviteAssessmentCall,
  candidateDetailsCall,
  candidateAssessmentSubmissionCall,
  candidateAssessmentFeedbackCall,
  assessmentImageProctorCall,
  getInvitationDetailsCall,
} from "../../network";
import {
  assessmentImageProctorConstants,
  assessmentLoginConstants,
  candidateAssessmentFeedbackConstants,
  candidateAssessmentSubmissionConstants,
  createAssessmentConstants,
  createQuestionConstants,
  createTestConstants,
  getAllAssessmentCandidateConstants,
  getAllAssessmentListConstants,
  getAllQuestionListConstants,
  getAllTestListConstants,
  getAsCandidateDetailsConstants,
  getAssessmentCandidateDetailsConstants,
  getAssessmentDetailsConstants,
  getInvitationDetailsConstants,
  getInviteAssessmentDetailsConstants,
  getQuestionDetailsConstants,
  getTestDetailsConstants,
  inviteAssessmentConstants,
  updateAssessmentConstants,
  updateInviteAssessmentDetailsConstants,
  updateQuestionConstants,
  updateTestConstants,
} from "../../constants";

export function* createQuestionSaga(action) {
  try {
    const response = yield call(createQuestionCall, action.data);
    yield put({
      type: createQuestionConstants.CREATE_QUESTION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createQuestionConstants.CREATE_QUESTION_RESET });

  } catch (e) {
    yield put({
      type: createQuestionConstants.CREATE_QUESTION_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createQuestionConstants.CREATE_QUESTION_RESET });

  }
}
export function* updateQuestionSaga(action) {
  try {
    const response = yield call(updateQuestionCall, action.data);
    yield put({
      type: updateQuestionConstants.UPDATE_QUESTION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateQuestionConstants.UPDATE_QUESTION_RESET });

  } catch (e) {
    yield put({
      type: updateQuestionConstants.UPDATE_QUESTION_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateQuestionConstants.UPDATE_QUESTION_RESET });

  }
}
export function* getQuestionDetailsSaga(action) {
  try {
    const response = yield call(getQuestionDetailsCall, action.data);
    yield put({
      type: getQuestionDetailsConstants.GET_QUESTION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getQuestionDetailsConstants.GET_QUESTION_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getQuestionDetailsConstants.GET_QUESTION_RESET });

  }
}
export function* getAllQuestionListSaga(action) {
  try {
    const response = yield call(getAllQuestionCall, action.data);
    yield put({
      type: getAllQuestionListConstants.GET_LIST_QUESTION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getAllQuestionListConstants.GET_LIST_QUESTION_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getAllQuestionListConstants.GET_LIST_QUESTION_RESET });

  }
}

export function* createTestSaga(action) {
  try {
    const response = yield call(createTestCall, action.data);
    yield put({
      type: createTestConstants.CREATE_TEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createTestConstants.CREATE_TEST_RESET });

  } catch (e) {
    yield put({
      type: createTestConstants.CREATE_TEST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createTestConstants.CREATE_TEST_RESET });

  }
}
export function* updateTestSaga(action) {
  try {
    const response = yield call(updateTestCall, action.data);
    yield put({
      type: updateTestConstants.UPDATE_TEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateTestConstants.UPDATE_TEST_RESET });

  } catch (e) {
    yield put({
      type: updateTestConstants.UPDATE_TEST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateTestConstants.UPDATE_TEST_RESET });

  }
}
export function* getTestDetailsSaga(action) {
  try {
    const response = yield call(getTestDetailsCall, action.data);
    yield put({
      type: getTestDetailsConstants.GET_TEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getTestDetailsConstants.GET_TEST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getTestDetailsConstants.GET_TEST_REQUEST });

  }
}
export function* getAllTestListSaga(action) {
  try {
    const response = yield call(getAllTestCall, action.data);
    yield put({
      type: getAllTestListConstants.GET_LIST_TEST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getAllTestListConstants.GET_LIST_TEST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getAllTestListConstants.GET_LIST_TEST_RESET });

  }
}
export function* createAssessmentSaga(action) {
  try {
    const response = yield call(createAssessmentCall, action.data);
    yield put({
      type: createAssessmentConstants.CREATE_ASSESSMENT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createAssessmentConstants.CREATE_ASSESSMENT_RESET });

  } catch (e) {
    yield put({
      type: createAssessmentConstants.CREATE_ASSESSMENT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createAssessmentConstants.CREATE_ASSESSMENT_RESET });

  }
}
export function* updateAssessmentSaga(action) {
  try {
    const response = yield call(updateAssessmentCall, action.data);
    yield put({
      type: updateAssessmentConstants.UPDATE_ASSESSMENT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateAssessmentConstants.UPDATE_ASSESSMENT_RESET });

  } catch (e) {
    yield put({
      type: updateAssessmentConstants.UPDATE_ASSESSMENT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateAssessmentConstants.UPDATE_ASSESSMENT_RESET });

  }
}
export function* getAssessmentDetailsSaga(action) {
  try {
    const response = yield call(getAssessmentDetailsCall, action.data);
    yield put({
      type: getAssessmentDetailsConstants.GET_ASSESSMENT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getAssessmentDetailsConstants.GET_ASSESSMENT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getAssessmentDetailsConstants.GET_ASSESSMENT_RESET });

  }
}
export function* getAllAssessmentListSaga(action) {
  try {
    const response = yield call(getAllAssessmentCall, action.data);
    yield put({
      type: getAllAssessmentListConstants.GET_LIST_ASSESSMENT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getAllAssessmentListConstants.GET_LIST_ASSESSMENT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getAllAssessmentListConstants.GET_LIST_ASSESSMENT_RESET });

  }
}
export function* getInviteAssessmentDetailsSaga(action) {
  try {
    const response = yield call(getInviteAssessmentDetailsCall, action.data);
    yield put({
      type: getInviteAssessmentDetailsConstants.GET_INVITE_ASSESSMENT_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getInviteAssessmentDetailsConstants.GET_INVITE_ASSESSMENT_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getInviteAssessmentDetailsConstants.GET_INVITE_ASSESSMENT_DETAILS_RESET });

  }
}
export function* getInvitationDetailSaga(action) {
  try {
    const response = yield call(getInvitationDetailsCall, action.data);
    yield put({
      type: getInvitationDetailsConstants.GET_INVITE_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getInvitationDetailsConstants.ASSESSMENT_LOGIN_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getInvitationDetailsConstants.GET_INVITE_DETAILS_RESET });

  }
}
export function* updateInviteAssessmentDetailsSaga(action) {
  try {
    const response = yield call(updateInviteAssessmentDetailsCall, action.data);
    yield put({
      type: updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_RESET });

  } catch (e) {
    yield put({
      type: updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: updateInviteAssessmentDetailsConstants.UPDATE_INVITE_ASSESSMENT_DETAILS_RESET });

  }
}
export function* assessmentLoginSaga(action) {
  try {
    const response = yield call(assessmentLoginCall, action.data);
    yield put({
      type: assessmentLoginConstants.ASSESSMENT_LOGIN_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: assessmentLoginConstants.ASSESSMENT_LOGIN_RESET });

  } catch (e) {
    yield put({
      type: assessmentLoginConstants.ASSESSMENT_LOGIN_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: assessmentLoginConstants.ASSESSMENT_LOGIN_RESET });

  }
}
export function* getAllAssessmentCandidateSaga(action) {
  try {
    const response = yield call(getAllAssessmentCandidateCall, action.data);
    yield put({
      type: getAllAssessmentCandidateConstants.GET_ALL_ASSESSMENT_CANDIDATE_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getAllAssessmentCandidateConstants.GET_ALL_ASSESSMENT_CANDIDATE_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getAllAssessmentCandidateConstants.GET_ALL_ASSESSMENT_CANDIDATE_RESET });

  }
}
export function* getAssessmentCandidateDetailsSaga(action) {
  try {
    const response = yield call(getAssessmentCandidateDetailsCall, action.data);
    yield put({
      type: getAssessmentCandidateDetailsConstants.GET_ASSESSMENT_CANDIDATE_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getAssessmentCandidateDetailsConstants.GET_ASSESSMENT_CANDIDATE_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getAssessmentCandidateDetailsConstants.GET_ASSESSMENT_CANDIDATE_DETAILS_RESET });

  }
}
export function* inviteAssessmentSaga(action) {
  try {
    const response = yield call(inviteAssessmentCall, action.data);
    yield put({
      type: inviteAssessmentConstants.INVITE_ASSESSMENT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: inviteAssessmentConstants.INVITE_ASSESSMENT_RESET });

  } catch (e) {
    yield put({
      type: inviteAssessmentConstants.INVITE_ASSESSMENT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: inviteAssessmentConstants.INVITE_ASSESSMENT_RESET });

  }
}

export function* getCandidateDetailsSaga(action) {
  try {
    const response = yield call(candidateDetailsCall, action.data);
    yield put({
      type: getAsCandidateDetailsConstants.GET_NEW_CANDIDATE_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: getAsCandidateDetailsConstants.GET_NEW_CANDIDATE_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getAsCandidateDetailsConstants.GET_NEW_CANDIDATE_DETAILS_RESET });

  }
}
export function* candidateAssessmentSubmissionSaga(action) {
  try {
    const response = yield call(candidateAssessmentSubmissionCall, action.data);
    yield put({
      type: candidateAssessmentSubmissionConstants.CANDIDATE_ASSESSMENT_SUBMISSION_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: candidateAssessmentSubmissionConstants.CANDIDATE_ASSESSMENT_SUBMISSION_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: candidateAssessmentSubmissionConstants.CANDIDATE_ASSESSMENT_SUBMISSION_RESET });

  }
}
export function* candidateAssessmentFeedbackSaga(action) {
  try {
    const response = yield call(candidateAssessmentFeedbackCall, action.data);
    yield put({
      type: candidateAssessmentFeedbackConstants.CANDIDATE_ASSESSMENT_FEEDBACK_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: candidateAssessmentFeedbackConstants.CANDIDATE_ASSESSMENT_FEEDBACK_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: candidateAssessmentFeedbackConstants.CANDIDATE_ASSESSMENT_FEEDBACK_RESET });

  }
}
export function* assessmentImageProctorSaga(action) {
  try {
    const response = yield call(assessmentImageProctorCall, action.data);
    yield put({
      type: assessmentImageProctorConstants.ASSESSMENT_PROCTOR_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error,
    });
  } catch (e) {
    yield put({
      type: assessmentImageProctorConstants.ASSESSMENT_PROCTOR_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data,
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: assessmentImageProctorConstants.ASSESSMENT_PROCTOR_RESET });

  }
}
