import React, { useEffect, useState } from "react";
import "../../Assets/css/auth.main.css";
import { Button, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import { validateEmail } from "../../Utils/Common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import {
  forgetPasswordLinkAction,
  loginUserAction
} from "../../redux/actions/user";
import mainimg from "../../../src/Assets/img/bdk23qfm.png";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [forgetPasswordVisible, setForgetPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [accountId, setAccountId] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const loginUserReducer = useSelector((state) => state.loginUserReducer);
  const forgetPasswordLinkReducer = useSelector(
    (state) => state.forgetPasswordLinkReducer
  );

  useEffect(() => {
    const { data, loading, error, status, headers } = loginUserReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error && data?.status === true) {
        message.success("Login success!");
        navigate("/dashboard"); // Navigate to dashboard after login
      } else if (headers?.status !== 200 && headers?.data?.error) {
        message.error(headers?.data?.error);
        console.warn("error", error);
      }
    }
  }, [loginUserReducer, navigate]);

  useEffect(() => {
    const { data, loading, error } = forgetPasswordLinkReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error && data?.status === 200) {
        message.success(data?.message);
        setForgetPasswordVisible(false);
      } else if (error) {
        message.error(error);
        console.warn("error", error);
      }
    }
  }, [forgetPasswordLinkReducer]);

  const onFinish = (values) => {
    setLoading(true);
    dispatch(loginUserAction(values));
  };

  const handleForgetPasswordSubmit = () => {
    dispatch(forgetPasswordLinkAction({ email, client_id: accountId }));
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content">
          <div className="box-container mt-16">
            <div className="box-helper">
              <div className="box">
                <div className="flex justify-center">
                  <a href="/" className="-m-1.5 p-1.5">
                    <span className="sr-only">Mentorpal</span>
                    <img className="h-8 w-auto" src={mainimg} alt="Logo" />
                  </a>
                </div>
                <h2 className="centered mb-2 mt-4">
                  {forgetPasswordVisible ? "Forget Password" : "Sign In"}
                </h2>
                {forgetPasswordVisible ? (
                  <Form onFinish={handleForgetPasswordSubmit} layout="vertical">
                    <Form.Item
                      label="E-mail"
                      name="email"
                      rules={[
                        { required: true, message: "Please input your email!" },
                        { validator: validateEmail }
                      ]}
                    >
                      <Input
                        className="p-2"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Account Id"
                      name="client_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input your account id!"
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: "Only numbers are allowed"
                        },
                        {
                          len: 6,
                          message: "Numbers must be exactly 6 digits long"
                        }
                      ]}
                    >
                      <Input
                        className="p-2"
                        maxLength={6}
                        placeholder="Enter 6 digits account Id"
                        value={accountId}
                        onChange={(e) => setAccountId(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        loading={loading}
                        className="text-white"
                        style={{ marginRight: 8, backgroundColor: "#005c53" }}
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                ) : (
                  <Form onFinish={onFinish} layout="vertical">
                    <Form.Item
                      label="Account Id"
                      name="client_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input your account id!"
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: "Only numbers are allowed"
                        },
                        {
                          len: 6,
                          message: "Numbers must be exactly 6 digits long"
                        }
                      ]}
                    >
                      <Input
                        className="p-2"
                        value={accountId}
                        maxLength={6}
                        placeholder="Enter 6 digits account Id"
                        onChange={(e) => setAccountId(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="E-mail"
                      name="email"
                      rules={[
                        { required: true, message: "Please input your email!" },
                        { validator: validateEmail }
                      ]}
                    >
                      <Input
                        className="p-2"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!"
                        }
                      ]}
                    >
                      <Input
                        type={passwordVisible ? "text" : "password"}
                        className="p-2"
                        suffix={
                          passwordVisible ? (
                            <EyeOutlined
                              onClick={() => setPasswordVisible(false)}
                            />
                          ) : (
                            <EyeInvisibleOutlined
                              onClick={() => setPasswordVisible(true)}
                            />
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        loading={loading}
                        className="text-white"
                        style={{ marginRight: 8, backgroundColor: "#005c53" }}
                        htmlType="submit"
                      >
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                )}
                <p className="text-center mt-2">
                  {forgetPasswordVisible ? (
                    <Link
                      onClick={() => setForgetPasswordVisible(false)}
                      style={{ textDecoration: "underline", color: "#005c53" }}
                    >
                      <ArrowLeftOutlined /> Go Back to Login
                    </Link>
                  ) : (
                    <Link
                      onClick={() => setForgetPasswordVisible(true)}
                      style={{ textDecoration: "underline", color: "#005c53" }}
                    >
                      Forget Password
                    </Link>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
