import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, message } from "antd";
import headerLogo from "../../Assets/img/e7s7pbbz.png";
import { logoutUserAction } from "../../redux/actions/user";
import { REACT_USER_TYPE } from "../Auth/tokenProvider";
import { getNavigationByUserType, getTargetLocation, userNavigation } from "./headerConstFile";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProtectedHeader() {
  const [active, setActive] = useState(0);
  const [navigation, setNavigation] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getRoleUSerDetailsReducer = useSelector(
    (state) => state.getRoleUSerDetailsReducer
  );
  const logoutUserReducer = useSelector((state) => state.logoutUserReducer);

  useEffect(() => {
    setNavigation(getNavigationByUserType(REACT_USER_TYPE()));
  }, []);

  useEffect(() => {
    const { loading, data, error } = getRoleUSerDetailsReducer;
    if (!loading && data && !error) {
      setActive(
        navigation.findIndex((nav) => location.pathname.startsWith(nav.href))
      );
    } else if (error) {
      message.error(error);
    }
  }, [getRoleUSerDetailsReducer, location.pathname, navigation]);

  useEffect(() => {
    const { loading, data, error } = logoutUserReducer;
    if (!loading && data && !error) {
      localStorage.clear();
      window.location.assign("/");
    } else if (error) {
      message.error(error);
    }
  }, [logoutUserReducer]);

  const handleNavigationClick = (href, closeDisclosure) => {
    navigate(href);
    setOpenDropdown(null); // Close dropdown when navigating
    if (closeDisclosure) closeDisclosure();
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleLogOut = () => {
    dispatch(logoutUserAction());
  };

  const handleLogo = () => {
    navigate(getTargetLocation(REACT_USER_TYPE()));
  };

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open, close: closeDisclosure }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    style={{ cursor: "pointer" }}
                    className="h-8 w-auto"
                    src={headerLogo}
                    alt="Your Company"
                    onClick={handleLogo}
                  />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((section, index) => (
                      <div key={section.title} className="relative">
                        <span
                          className={classNames(
                            active === index ? "text-white" : "text-gray-300",
                            "px-3 py-2 rounded-md text-sm font-medium cursor-pointer hover:bg-gray-700 hover:text-white"
                          )}
                          onClick={() => {
                            !section.dropdown
                              ? handleNavigationClick(section.href)
                              : handleDropdownToggle(index);
                          }}
                        >
                          {section.title}
                        </span>
                        {section.dropdown && openDropdown === index && (
                          <div className="absolute z-10 mt-2 w-48 origin-top-left bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                            {section.dropdown.map((item) => (
                              <a
                                key={item.title}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleNavigationClick(item.href);
                                }}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                {item.title}
                              </a>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <Menu as="div" className="relative ml-3">
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open user menu</span>
                    <Avatar
                      src={
                        localStorage.getItem("REACT_USER_PHOTO")
                          ? `${process.env.REACT_APP_IMAGES_BASE_URL
                          }${localStorage.getItem("REACT_USER_PHOTO")}`
                          : undefined
                      }
                      icon={<UserOutlined />}
                      size="large"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          <a
                            href={item.href}
                            onClick={(e) => {
                              if (item.name === "Sign out") {
                                handleLogOut();
                              } else {
                                e.preventDefault();
                                navigate(item.href);
                              }
                            }}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            {item.name}
                          </a>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex md:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
              {navigation.map((section, index) => (
                <div key={section.title} className="relative">
                  <span
                    className={classNames(
                      active === index ? "text-white" : "text-gray-300",
                      "block px-3 py-2 rounded-md text-base font-medium cursor-pointer hover:bg-gray-700 hover:text-white"
                    )}
                    onClick={() => {
                      !section.dropdown
                        ? handleNavigationClick(section.href, closeDisclosure)
                        : handleDropdownToggle(index);
                    }}
                  >
                    {section.title}
                  </span>
                  {section.dropdown && openDropdown === index && (
                    <div className="absolute z-10 mt-2 w-48 origin-top-left bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                      {section.dropdown.map((item) => (
                        <a
                          key={item.title}
                          onClick={(e) => {
                            e.preventDefault();
                            handleNavigationClick(item.href, closeDisclosure);
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          {item.title}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              <div className="mt-1 border-t border-gray-700 pt-2 block">
                {userNavigation.map((item) => (
                  <span
                    key={item.name}
                    onClick={(e) => {
                      if (item.name === "Sign out") {
                        handleLogOut();
                      } else {
                        e.preventDefault();
                        handleNavigationClick(item.href, closeDisclosure);
                      }
                    }}
                    className=" text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer hover:bg-gray-700 hover:text-white"
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
