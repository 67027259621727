import {
  addCandidateConstants,
  addClientConstants,
  applyJobConstants,
  getAllCandidateActions,
  getClientDetailsConstants,
  getClientJobsConstants,
  getClientListConstants,
  getJobDetailConstants,
  postFeedbackConstants,
  resumeParserConstants,
  updateClientDetailsConstants,
  updateJobApplicationConstants,
  updateJobConstants,
  getCandidateProfileConstants,
  createJobConstants,
  getJobConstants,
  getJobApplicationConstants,
  enquiryConstants,
  getEnquiryConstants,
  updateEnquiryConstants,
  verifyOtpConstants,
  sendOtpConstants,
  verifyEmailConstants,
  updateCandidateConstants,
  matchingAlgoConstants,
  testLoginConstants,
  multipleActionConstants,
  getJobAllAppliedCandidateConstants,
  getFeedbackListConstant,
  updateFeedbackConstant,
  assignToRecruiterConstants,
  emailSubscribeConstants,
  getPresignedUrlConstants,
  uploadImageFileConstants,
  getEmailTemplatesConstants,
  updateEmailTemplatesConstants
} from "../../constants";

export const uploadImageReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getPresignedUrlConstants.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getPresignedUrlConstants.UPLOAD_IMAGE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getPresignedUrlConstants.UPLOAD_IMAGE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getPresignedUrlConstants.UPLOAD_IMAGE_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const uploadImageFileReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case uploadImageFileConstants.UPLOAD_IMAGE_FILE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case uploadImageFileConstants.UPLOAD_IMAGE_FILE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        uploaded: true,
        error: action.error
      };
    case uploadImageFileConstants.UPLOAD_IMAGE_FILE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case uploadImageFileConstants.UPLOAD_IMAGE_FILE_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const resumeParserReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case resumeParserConstants.RESUME_PARSER_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case resumeParserConstants.RESUME_PARSER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case resumeParserConstants.RESUME_PARSER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case resumeParserConstants.RESUME_PARSER_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const getJobDetailReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getJobDetailConstants.GET_JOB_DETAIL_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getJobDetailConstants.GET_JOB_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        uploaded: true,
        error: action.error
      };
    case getJobDetailConstants.GET_JOB_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getJobDetailConstants.GET_JOB_DETAIL_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const applyJobReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case applyJobConstants.APPLY_JOB_REQUEST:
      return {
        ...state,
        loading: true,
        data: null,
        error: null
      };
    case applyJobConstants.APPLY_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: null
      };
    case applyJobConstants.APPLY_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status
      };
    case applyJobConstants.APPLY_JOB_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const addClientReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case addClientConstants.ADD_CLIENT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case addClientConstants.ADD_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        uploaded: true,
        error: action.error
      };
    case addClientConstants.ADD_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case addClientConstants.ADD_CLIENT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getClientListReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getClientListConstants.GET_CLIENT_LIST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getClientListConstants.GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        uploaded: true,
        error: action.error
      };
    case getClientListConstants.GET_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getClientListConstants.GET_CLIENT_LIST_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getClientDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getClientDetailsConstants.GET_CLIENT_DETAIL_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getClientDetailsConstants.GET_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        uploaded: true,
        error: action.error
      };
    case getClientDetailsConstants.GET_CLIENT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getClientDetailsConstants.GET_CLIENT_DETAIL_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateClientDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        uploaded: true,
        error: action.error
      };
    case updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateClientDetailsConstants.UPDATE_CLIENT_DETAIL_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getAllCandidateReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAllCandidateActions.GET_ALL_CANDIDATE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAllCandidateActions.GET_ALL_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        uploaded: true,
        error: action.error
      };
    case getAllCandidateActions.GET_ALL_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getAllCandidateActions.GET_ALL_CANDIDATE_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getClientJobReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getClientJobsConstants.GET_CLIENT_JOBS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getClientJobsConstants.GET_CLIENT_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        uploaded: true,
        error: action.error
      };
    case getClientJobsConstants.GET_CLIENT_JOBS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getClientJobsConstants.GET_CLIENT_JOBS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateJobDetailReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateJobConstants.UPDATE_JOB_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateJobConstants.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateJobConstants.UPDATE_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateJobConstants.UPDATE_JOB_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const addCandidateReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case addCandidateConstants.ADD_CANDIDATE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case addCandidateConstants.ADD_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
        headers: action.headers
      };
    case addCandidateConstants.ADD_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case addCandidateConstants.ADD_CANDIDATE_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateJobApplicationReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateJobApplicationConstants.UPDATE_APPLICATION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateJobApplicationConstants.UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
        headers: action.headers
      };
    case updateJobApplicationConstants.UPDATE_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateJobApplicationConstants.UPDATE_APPLICATION_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const postFeedbackReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case postFeedbackConstants.POST_FEEDBACK_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case postFeedbackConstants.POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error,
        headers: action.headers
      };
    case postFeedbackConstants.POST_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case postFeedbackConstants.POST_FEEDBACK_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const candidateProfileReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getCandidateProfileConstants.CANDIDATE_PROFILE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getCandidateProfileConstants.CANDIDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getCandidateProfileConstants.CANDIDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getCandidateProfileConstants.CANDIDATE_PROFILE_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const createJobReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case createJobConstants.CREATE_JOB_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case createJobConstants.CREATE_JOB_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case createJobConstants.CREATE_JOB_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case createJobConstants.CREATE_JOB_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getJobReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getJobConstants.GET_JOB_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getJobConstants.GET_JOB_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getJobConstants.GET_JOB_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getJobConstants.GET_JOB_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getJobApplicationReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getJobApplicationConstants.GET_JOB_APPLICATION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getJobApplicationConstants.GET_JOB_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getJobApplicationConstants.GET_JOB_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getJobApplicationConstants.GET_JOB_APPLICATION_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const enquiryReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case enquiryConstants.ENQUIRY_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case enquiryConstants.ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case enquiryConstants.ENQUIRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case enquiryConstants.ENQUIRY_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getEnquiryReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getEnquiryConstants.GET_ENQUIRY_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getEnquiryConstants.GET_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getEnquiryConstants.GET_ENQUIRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getEnquiryConstants.GET_ENQUIRY_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateEnquiryReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateEnquiryConstants.UPDATE_ENQUIRY_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateEnquiryConstants.UPDATE_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateEnquiryConstants.UPDATE_ENQUIRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateEnquiryConstants.UPDATE_ENQUIRY_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const verifyOtpReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case verifyOtpConstants.VERIFY_OTP_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case verifyOtpConstants.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case verifyOtpConstants.VERIFY_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case verifyOtpConstants.VERIFY_OTP_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const sendOtpReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case sendOtpConstants.SEND_OTP_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case sendOtpConstants.SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case sendOtpConstants.SEND_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case sendOtpConstants.SEND_OTP_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const verifyEmailReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case verifyEmailConstants.VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case verifyEmailConstants.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case verifyEmailConstants.VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case verifyEmailConstants.VERIFY_EMAIL_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const updateCandidateReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateCandidateConstants.UPDATE_CANDIDATE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateCandidateConstants.UPDATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateCandidateConstants.UPDATE_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateCandidateConstants.UPDATE_CANDIDATE_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const matchingAlgoReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case matchingAlgoConstants.MATCHING_ALGO_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case matchingAlgoConstants.MATCHING_ALGO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case matchingAlgoConstants.MATCHING_ALGO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case matchingAlgoConstants.MATCHING_ALGO_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const multipleActionReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case multipleActionConstants.MULTIPLE_ACTION_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case multipleActionConstants.MULTIPLE_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case multipleActionConstants.MULTIPLE_ACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case multipleActionConstants.MULTIPLE_ACTION_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getJobAllAppliedCandidateReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getJobAllAppliedCandidateConstants.GET_JOB_ALL_APPLIED_CANDIDATE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getJobAllAppliedCandidateConstants.GET_JOB_ALL_APPLIED_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getJobAllAppliedCandidateConstants.GET_JOB_ALL_APPLIED_CANDIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    default:
      return state;
  }
};
export const getFeedbackListReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getFeedbackListConstant.GET_FEEDBACK_LIST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getFeedbackListConstant.GET_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getFeedbackListConstant.GET_FEEDBACK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    default:
      return state;
  }
};
export const updateFeedbackReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateFeedbackConstant.UPDATE_FEEDBACK_LIST_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateFeedbackConstant.UPDATE_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateFeedbackConstant.UPDATE_FEEDBACK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateFeedbackConstant.UPDATE_FEEDBACK_LIST_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const assignToRecruiterReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case assignToRecruiterConstants.ASSIGN_TO_RECRUITER_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case assignToRecruiterConstants.ASSIGN_TO_RECRUITER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case assignToRecruiterConstants.ASSIGN_TO_RECRUITER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case assignToRecruiterConstants.ASSIGN_TO_RECRUITER_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const emailSubscribeReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case emailSubscribeConstants.EMAIL_SUBSCRIBE_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case emailSubscribeConstants.EMAIL_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case emailSubscribeConstants.EMAIL_SUBSCRIBE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case emailSubscribeConstants.EMAIL_SUBSCRIBE_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getEmailTemplatesReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getEmailTemplatesConstants.GET_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getEmailTemplatesConstants.GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getEmailTemplatesConstants.GET_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getEmailTemplatesConstants.GET_EMAIL_TEMPLATES_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateEmailTemplatesReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateEmailTemplatesConstants.UPDATE_EMAIL_TEMPLATES_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
