import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button, message, Table } from "antd";
import {
  ApartmentOutlined,
  ClockCircleOutlined,
  ZhihuOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getTestDetailAction } from "../../../../../redux/actions/assessment";
import { TailSpin } from "react-loader-spinner";
import QuestionDrawer from "../../../Test/Modal/QuestionDrawer";

const DetailsModal = ({
  detailModal,
  setDetailModal,
  initialData,
  selectedTests,
  setSelectedTests,
}) => {
  const handleAddTest = (test) => {
    if (selectedTests.length >= 5) {
      message.error("You can only select up to 5 tests.");
      return;
    }
    const newSelectedTests = [
      ...selectedTests,
      {
        test_id: test?.test_id,
        title: test?.title,
        test_duration: test?.test_duration,
        totalQuestion: test?.questionIds?.length,
        type: test?.type,
      },
    ];
    setSelectedTests(newSelectedTests);
  };
  const handleRemoveTest = (indexToRemove) => {
    const newSelectedTests = selectedTests.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedTests(newSelectedTests);
  };

  const isTestSelected = (test_id) => {
    return selectedTests?.some((test) => test && test.test_id === test_id);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestDetailAction({ test_id: initialData?.test_id }));
  }, [dispatch, initialData]);

  const getTestDetailsReducer = useSelector(
    (state) => state.getTestDetailsReducer
  );

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { loading, data, error } = getTestDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.data);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);    }
  }, [getTestDetailsReducer]);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const showDrawer = (record) => {
    setSelectedQuestion(record);
    setDrawerVisible(true);
  };

  return (
    <Modal
      visible={detailModal}
      width={1800}
      onCancel={() => setDetailModal(false)}
      footer={null}
    >
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <Row span={24} style={{ margin: "20px" }}>
            <Col span={12} style={{ padding: "20px" }}>
              <div style={{ paddingBottom: "20px" }}>
                <Button type="default" className=" me-2 button">
                  Preview sample Questions
                </Button>

                {isTestSelected(initialData?.test_id) ? (
                  <Button
                    type="danger"
                    className="test-button-cards-2"
                    onClick={() =>
                      handleRemoveTest(
                        selectedTests.findIndex(
                          (t) => t && t.test_id === initialData?.test_id
                        )
                      )
                    }
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    className="test-button-cards-2"
                    onClick={() =>
                      handleAddTest({
                        test_id: initialData?.test_id,
                        title: initialData?.title,
                        test_duration: initialData?.test_duration,
                        questionIds: initialData?.questionIds,
                        type: initialData?.type,
                      })
                    }
                  >
                    Add
                  </Button>
                )}
              </div>
              <div>
                <h2>
                  <strong>{data?.title}</strong>
                </h2>
                <br />
                <p>{data?.description}</p> <br />
                <div>
                  <Row span={24}>
                    <Col span={6}>
                      <p>
                        <ApartmentOutlined />
                      </p>
                      <p>
                        <strong>Type</strong>
                      </p>
                      <p>{data?.type}</p>
                    </Col>
                    <Col span={6}>
                      <p>
                        <ClockCircleOutlined />
                      </p>
                      <p>
                        <strong>Time</strong>
                      </p>
                      <p>{data?.test_duration} mins</p>
                    </Col>
                    <Col span={6}>
                      <p>
                        <ZhihuOutlined />
                      </p>
                      <p>
                        <strong>Tags</strong>
                      </p>
                      <p>{data?.tags?.map((item) => item)}, </p>
                    </Col>
                    <Col span={6}>
                      <p>
                        <BarChartOutlined />
                      </p>
                      <p>
                        <strong>Level</strong>
                      </p>
                      <p>{data?.level}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col span={12} style={{ padding: "20px", marginTop: "50px" }}>
              <Table
              bordered
                columns={[
                  {
                    title: "S.No",
                    dataIndex: "sno",
                    key: "sno",
                    render: (text, record, index) => index + 1,
                  },
                  {
                    title: "Title",
                    dataIndex: "questionName",
                    key: "questionName",
                  },
                  {
                    title: "Type",
                    dataIndex: "questionType",
                    key: "questionType",
                  },
                  {
                    title: "Action",
                    key: "action",
                    render: (text, record) => (
                      <Button type="link" onClick={() => showDrawer(record)}>
                        View
                      </Button>
                    ),
                  },
                ]}
                dataSource={data?.questions || []}
                rowKey="question_id"
                pagination={false}
              />
            </Col>
          </Row>
        </>
      )}
      {drawerVisible && (
        <QuestionDrawer
          question_id={selectedQuestion?.question_id}
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
        />
      )}
    </Modal>
  );
};

export default DetailsModal;
