import React, { useEffect, useState } from "react";
import { Card, Button, Typography, Row, Col, Divider, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { convertMinutesToDuration } from "../../../Utils/Common";

const { Title, Paragraph } = Typography;

const AssessmentTips = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(false);
  const [asessmentData, setAssessmentData] = useState(false);
  const [inviteSummary, setInviteSummary] = useState(null);
  const getInviteAssessmentDetailsReducer = useSelector(
    (state) => state.getInviteAssessmentDetailsReducer
  );

  useEffect(() => {
    const { loading, data, error } = getInviteAssessmentDetailsReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        setAssessmentData(data?.assessment_details);
        setInviteSummary(data?.candidateDetails);
        setClient(data?.client);
      } else if (error) {
        console.warn("Error:", error);
        message.error(error);      }
    }
  }, [getInviteAssessmentDetailsReducer]);
  return (
    <>
      {loading ? (
        <div className="loading-spinner">
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <Row
          justify="center"
          align="middle"
          style={{
            minHeight: "100vh",
            padding: "20px",
            backgroundColor: "#f0f2f5"
          }}
        >
          <Col xs={24} sm={22} md={22} lg={22} xl={22}>
            <Card
              style={{
                borderRadius: "8px",
                padding: "2% 2%",
                textAlign: "left",
                boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",

                margin: "0 auto"
              }}
            >
              <Row gutter={[24, 24]}>
                <Col xs={24} md={12}>
                  <Title
                    level={2}
                    style={{ fontWeight: "bold", color: "#333" }}
                  >
                    Hello{" "}
                    <strong>
                      {`${inviteSummary?.firstName} ${inviteSummary?.lastName}`}
                      ,
                    </strong>
                  </Title>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    Thank you for applying to{" "}
                    <strong> {client?.company_name}</strong> and welcome to our
                    assessment.
                  </Paragraph>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    Completing it will give you a chance to show off your skills
                    and stand out from the crowd!
                  </Paragraph>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    Good luck!
                  </Paragraph>
                </Col>
                <Col xs={24} md={12}>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    <strong>A few things before you start:</strong>
                  </Paragraph>
                  <Divider />
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    • This assessment consists of{" "}
                    <strong>{asessmentData?.testIds?.length}</strong> tests . It
                    will take approximately{" "}
                    <strong>
                      {" "}
                      {convertMinutesToDuration(
                        asessmentData?.assessment_duration
                      )}
                    </strong>{" "}
                    to complete.
                  </Paragraph>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    • The assessment is timed. A timer is shown per test or per
                    question.
                  </Paragraph>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    • Before each test there may be preview questions.{" "}
                    <strong>
                      Be aware that the timer for the test will have already
                      started.
                    </strong>
                  </Paragraph>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    • Please allow the use of your camera/webcam and do not
                    leave full-screen mode. Snapshots will be taken of you
                    periodically during the assessment. These measures are taken
                    to ensure fairness for everyone.
                  </Paragraph>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    • Turn on your speakers or headphones (to play audio).
                  </Paragraph>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    • You are free to use a calculator, pen, and paper.
                  </Paragraph>
                  <Paragraph
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "16px"
                    }}
                  >
                    • We recommend completing the assessment in one go.
                  </Paragraph>
                  <Row justify="end">
                    <Col>
                      <Button
                        onClick={() => navigate("/dashboard/setup")}
                        type="primary"
                        icon={<ArrowRightOutlined />}
                        className="custom-button"
                      >
                        Get Started
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="https://static.mentorpal.ai/prod/static_assests/email_assets/logo-long.png"
                alt="MentorPal Logo"
                style={{
                  maxWidth: "300px",
                  filter: "grayscale(1) brightness(300%)"
                }}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AssessmentTips;
