import React, { useEffect, useRef, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  Steps,
  InputNumber,
  Switch,
  Divider,
  message
} from "antd";
import { questionConfig } from "../../data";
import { useDispatch, useSelector } from "react-redux";
import {
  createQuestionAction,
  getQuestionDetailAction,
  updateQuestionAction
} from "../../../../redux/actions/assessment";
import { QuillTextEditor } from "../../../../Utils/TextEditor";
import "./create-questions.css";
import QuestionersInput from "./Modals/questionare";
import TestCaseInput from "./Modals/testCase";
import MCQOptionsInput from "./Modals/mcq";
import AceEditorType from "./Modals/AceEditor";
const { Step } = Steps;
const { Option } = Select;

const CreateQuestion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const type = queryParams.get("type");
  const question_id = queryParams.get("question_id");
  const questionType = queryParams.get("questionType");

  useEffect(() => {
    if (question_id) {
      dispatch(getQuestionDetailAction({ question_id: question_id }));
    }
    return () => {
      dispatch({ type: "GET_QUESTION_RESET" });
    };
  }, [dispatch, question_id]);

  const getQuestionDetailsReducer = useSelector(
    (state) => state.getQuestionDetailsReducer
  );

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { loading, data, error } = getQuestionDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.data);
    }
    if (!loading && !data && error) {
      console.warn("error", error);
    }
  }, [getQuestionDetailsReducer]);

  return (
    <>
      <div className="main-header bg-white" data-ui="header">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                  Questions
                </h1>
              </a>
            </div>
            <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
              <Button
                type="button"
                className="btn btn--primary mt-m text-white pull-right main_border_radius"
                onClick={() => navigate("/dashboard/questions")}
              >
                Exit
              </Button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <DynamicQuestionForm
            questionType={type || questionType}
            setLoading={setLoading}
            data={question_id ? data : null}
            questionId={question_id}
          />
        </>
      )}
    </>
  );
};

const DynamicQuestionForm = ({ questionType, data, questionId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();

  const createQuestionReducer = useSelector(
    (state) => state.createQuestionReducer
  );
  const updateQuestionReducer = useSelector(
    (state) => state.updateQuestionReducer
  );

  // Initialize form values from props
  useEffect(() => {
    if (data && questionId) {
      form.setFieldsValue(data);
    }
  }, [data, form, questionId]);

  const config = questionConfig[questionType];
  const totalSteps = config?.steps?.length;
  const currentFields = config?.steps[currentStep]?.fields;

  const handleNext = async () => {
    try {
      const currentFields = config?.steps[currentStep]?.fields?.map(
        (field) => field.name
      );

      await form.validateFields(currentFields);

      const fieldValues = form.getFieldsValue(currentFields);
      const errors = [];

      const validationRules = {
        codeStubs: (value) => !value || value.trim() === "",
        mcqChoices: (value) => !value || value.length === 0,
        questionnaireChoices: (value) => !value || value.length === 0,
        testCases: (value) => !value || value.length === 0
      };

      currentFields.forEach((field) => {
        if (validationRules[field]?.(fieldValues[field])) {
          errors.push(`${field} is invalid.`);
        }
      });

      if (errors.length > 0) {
        message.error(errors.join(" "));
        return;
      }

      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      message.error("Please fill all the required fields");
    }
  };

  const handlePrev = () => setCurrentStep((prevStep) => prevStep - 1);

  const formatDataByType = (data, questionType) => {
    switch (questionType) {
      case "code":
        return {
          questionType,
          questionName: data.questionName,
          problemDescription: data.problemDescription,
          recommendedTime: data.recommendedTime,
          languages: data.languages,
          codeStubs: data.codeStubs,
          testCases: data.testCases,
          tags: data.tags
        };
      case "mcq":
        return {
          questionType,
          questionName: data.questionName,
          problemDescription: data.problemDescription,
          recommendedTime: data.recommendedTime,
          mcqChoices: data.mcqChoices,
          tags: data.tags
        };
      case "questionnaire":
        return {
          questionType,
          questionName: data.questionName,
          problemDescription: data.problemDescription,
          recommendedTime: data.recommendedTime,
          questionnaireChoices: data.questionnaireChoices,
          tags: data.tags
        };
      default:
        return data;
    }
  };

  const handleFinish = (values) => {
    const formattedData = formatDataByType(form.getFieldValue(), questionType);
    if (questionId) {
      dispatch(
        updateQuestionAction({ ...formattedData, question_id: questionId })
      );
    } else {
      dispatch(createQuestionAction({ ...formattedData }));
    }
  };

  useEffect(() => {
    const { loading, status, error, data } = createQuestionReducer;
    if (!loading) {
      if (data && !error && status === 201) {
        message.success(data.message);
        navigate("/dashboard/questions");
        form.resetFields();
      } else if (error && (status === 400 || status === 500)) {
        message.error(error);
      }
    }
  }, [createQuestionReducer]);

  useEffect(() => {
    const { loading, data, error } = updateQuestionReducer;
    if (!loading) {
      if (data && !error) {
        message.success(data.message);
        navigate("/dashboard/questions");
        form.resetFields();
      } else if (error) {
        message.error(error);
      }
    }
  }, [updateQuestionReducer]);

  const renderField = (field) => {
    switch (field.type) {
      case "text":
        return <Input size="large" style={{ width: "100%" }} />;
      case "textarea":
        return (
          <QuillTextEditor
            form={form}
            field={field}
            style={{ maxHeight: "10px" }}
          />
        );
      case "number":
        return (
          <InputNumber min={field?.min} size="large" style={{ width: "50%" }} />
        );
      case "tags":
        return (
          <Select mode="tags" style={{ width: "100%" }} size="large">
            {field?.options?.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );
      case "multi-select":
        return (
          <Select mode="multiple" style={{ width: "100%" }} size="large">
            {field?.options?.map((option) => (
              <Option key={option?.value} value={option?.value}>
                {option?.label}
              </Option>
            ))}
          </Select>
        );
      case "select":
        return (
          <Select style={{ width: "100%" }} size="large">
            {field?.options?.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );
      case "language":
        return (
          <Select style={{ width: "100%" }} size="large">
            {field?.options?.map((option) => (
              <Option key={option?.value} value={option?.value}>
                {`${option?.name}, version: ${option?.version}`}
              </Option>
            ))}
          </Select>
        );
      case "ace-editor":
        return <AceEditorType form={form} data={data} />;
      case "boolean":
        return <Switch size="large" />;
      case "mcq":
        return <MCQOptionsInput form={form} data={data} />;
      case "test_case":
        return <TestCaseInput form={form} data={data} />;
      case "questionnaire":
        return <QuestionersInput form={form} data={data} />;
      default:
        return null;
    }
  };

  return (
    <div className="question-form-container">
      <div className="question-steps-container">
        <Steps direction="vertical" current={currentStep}>
          {config?.steps.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>
      </div>
      <Divider type="vertical" className="cq-divider" />
      <Form
        className="question-form-content pb-4"
        form={form}
        layout="vertical"
      >
        {currentFields.map((field, index) => (
          <Form.Item
            key={index}
            name={field.name}
            label={field.label}
            rules={field.rules}
          >
            {renderField(field)}
          </Form.Item>
        ))}
      </Form>
      <footer className="footer">
        <div className="button-container left-buttons">
          {currentStep > 0 && (
            <Button onClick={handlePrev} className="custom-button">
              Previous
            </Button>
          )}
        </div>
        <div className="button-container right-buttons">
          {currentStep < totalSteps - 1 && (
            <Button
              type="primary"
              onClick={handleNext}
              className="custom-button"
            >
              Next
            </Button>
          )}
          {currentStep === totalSteps - 1 && (
            <Button
              type="primary"
              onClick={handleFinish}
              className="custom-button"
            >
              {questionId ? "Update" : "Submit"}
            </Button>
          )}
        </div>
      </footer>
    </div>
  );
};

export default CreateQuestion;
