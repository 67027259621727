import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Button,
  Form,
  Input,
  Layout,
  Collapse,
  Pagination,
  Select,
  Drawer,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import PublicJobCard from "../CarrierPage/publicJobCard";
import { debounce } from "lodash";
import { TailSpin } from "react-loader-spinner";
import PublicHeader from "../Header/publicHeader";
import { getJobAction } from "../../redux/actions/common";
import { Footer } from "./home";
import { education_level, job_type } from "../../Assets/data/Job";
import { isLogged, REACT_USER_TYPE } from "../Auth/tokenProvider";

const { Sider, Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const FilterForm = ({
  filters,
  handleFilterChange,
  debouncedHandleSearch,
  handleReset,
}) => (
  <Form
    layout="vertical"
    onValuesChange={handleFilterChange}
    initialValues={filters}
    style={{ paddingTop: "20px" }}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h2 className="m-0 ml-2">Filters</h2>
      <Button type="" onClick={handleReset}>
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "4", "5", "10", "11"]}
      expandIconPosition="right"
      style={{ background: "#fff", border: "none" }}
    >
      <Panel header="Search" key="1">
        <Input
          placeholder="Search jobs"
          onChange={(e) => debouncedHandleSearch(e.target.value)}
        />
      </Panel>
      <Panel header="Job Location" key="4">
        <Input
          placeholder="Enter location"
          value={filters.job_location}
          onChange={(e) => handleFilterChange({ job_location: e.target.value })}
        />
      </Panel>
      <Panel header="Job Type" key="5">
        <Select
          placeholder="Select Job Type"
          value={filters.job_type}
          onChange={(value) => handleFilterChange({ job_type: value })}
          style={{ width: "100%" }}
        >
          <Option value="">All</Option>
          {job_type.map((type) => (
            <Option key={type.value} value={type.value}>
              {type.label}
            </Option>
          ))}
        </Select>
      </Panel>
      <Panel header="Remote Options" key="11">
        <Select
          placeholder="Select Remote Option"
          value={filters.remote_options}
          onChange={(value) => handleFilterChange({ remote_options: value })}
          style={{ width: "100%" }}
        >
          <Option value="">All</Option>
          <Option value={true}>Remote</Option>
          <Option value={false}>Onsite</Option>
        </Select>
      </Panel>
    </Collapse>
  </Form>
);

const Home = () => {
  const dispatch = useDispatch();
  const getJobReducer = useSelector((state) => state.getJobReducer);

  const [isCandidateLogin, setIsCandidateLogin] = useState(
    isLogged && REACT_USER_TYPE() === "candidate-dashboard" ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalJobs, setTotalJobs] = useState(0);
  const [data, setData] = useState([]);
  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const [filters, setFilters] = useState({
    job_title: "",
    job_location: "",
    job_type: "",
    education_level: "",
    remote_options: "",
  });

  const debouncedHandleSearch = useMemo(
    () =>
      debounce(
        (value) => setFilters((prev) => ({ ...prev, job_title: value })),
        300
      ),
    []
  );

  useEffect(() => {
    dispatch(getJobAction({ pageSize, page: currentPage, ...filters }));
  }, [currentPage, pageSize, filters, dispatch]);

  useEffect(() => {
    const { loading, data, error } = getJobReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        setData(data.data);
        setCurrentPage(data.current);
        setPageSize(data.pageSize);
        setTotalJobs(data.total);
      } else if (error) {
        console.warn("error", error);
      }
    }
  }, [getJobReducer]);

  const handlePageChange = (page) => setCurrentPage(page);
  const handleFilterChange = (changedFields) =>
    setFilters((prev) => ({ ...prev, ...changedFields }));
  const handleReset = useCallback(() => {
    setFilters({
      job_title: "",
      job_location: "",
      job_type: "",
      education_level: "",
      remote_options: "",
    });
    dispatch(getJobAction({ page: 1, pageSize: 10 }));
  }, [dispatch]);

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      {!isCandidateLogin && <PublicHeader />}
      <Layout style={{ background: "#fff", minHeight: "100vh" }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background">
            <div className="px-3 bg-light" style={{ height: "100vh" }}>
              <FilterForm
                filters={filters}
                handleFilterChange={handleFilterChange}
                debouncedHandleSearch={debouncedHandleSearch}
                handleReset={handleReset}
              />
            </div>
          </Sider>
        )}
        <Layout>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <Content style={{ margin: 0, minHeight: 280 }}>
              <main role="main">
                <section
                  className="dashboard-container"
                  style={{ gap: "0px 16px" }}
                >
                  {data && data.length > 0 ? (
                    data.map((job, index) => (
                      <div key={index} className="job-card">
                        <PublicJobCard job={job?.job} />
                      </div>
                    ))
                  ) : (
                    <section>
                      <div className="container avilabilty___col">
                        <div className="text-center">
                          <h1 className="display-1 fw-bold">0 Jobs</h1>
                          <p className="fs-3">
                            <span className="text-danger">Oops!</span> No jobs
                            available.
                          </p>
                          <p className="lead">No jobs posted yet.</p>
                        </div>
                      </div>
                    </section>
                  )}
                </section>
              </main>
              <Pagination
                style={{
                  textAlign: "center",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
                current={currentPage}
                total={totalJobs}
                pageSize={pageSize}
                onChange={handlePageChange}
              />
            </Content>
          )}
        </Layout>
      </Layout>
      {!isCandidateLogin && <Footer />}
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="100%"
        title="Filters"
        className="filter-drawer"
      >
        <FilterForm
          filters={filters}
          handleFilterChange={handleFilterChange}
          debouncedHandleSearch={debouncedHandleSearch}
          handleReset={handleReset}
        />
      </Drawer>
      <style jsx>{`
        .dashboard-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
        }
        @media (max-width: 768px) {
          .site-layout-background {
            display: none !important;
          }
          .filter-toggle-button {
            display: block;
            margin: 10px;
          }
          .dashboard-container {
            grid-template-columns: 1fr;
          }
        }
        @media (min-width: 786px) {
          .dashboard-container {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        @media (min-width: 769px) {
          .filter-toggle-button {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default Home;
