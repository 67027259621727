import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Col,
  Divider,
  Drawer,
  Row,
  Form,
  Input,
  Button,
  message
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { applyJobAction, resumeParserAction } from "../../redux/actions/common";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import UserCustomSelect from "../../Utils/ClientSelect/userSelect";
import ImageUploadComponent from "../Upload";
import { isMentorpalTeamUser } from "../../Utils/Common";
import { useIsMobileView } from "../../Utils/CustomHooks/windowSize";

const AddResumeDrawer = ({
  visible,
  setVisible,
  job_id,
  user_id,
  client_id
}) => {
  const dispatch = useDispatch();
  const [resumeParserFileName, setResumeParserFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadFileData, setUploadFileData] = useState({
    resume: "",
    photo: "",
    fileData: {}
  });

  const [form] = Form.useForm();

  const {
    uploadImageReducer: resumeFileURLReqData,
    resumeParserReducer,
    applyJobReducer
  } = useSelector((state) => state);
  const uploadImageFileReducer = useSelector(
    (state) => state.uploadImageFileReducer
  );

  const handleError = (error) => {
    if (error.errorFields) {
      error.errorFields.forEach(({ name, errors }) => {
        form.setFields([{ name, errors }]);
      });
    } else {
      const errMessage = error.response?.data?.message || error.message;
      const errStatus = error.response?.status;
      const statusMessages = {
        400: `Bad request: ${errMessage}`,
        401: `Unauthorized: ${errMessage}`,
        404: `Resource not found: ${errMessage}`
      };
      message.error(
        statusMessages[errStatus] || `An error occurred: ${errMessage}`
      );
    }
  };

  const handleSuccess = () => {
    setVisible((prev) => ({ ...prev, show: false }));
    form.resetFields();
    message.success("Done");
  };

  const onClose = () => {
    setVisible((prev) => ({ ...prev, show: false }));
    form.resetFields();
  };

  useEffect(() => {
    const { loading, data, error, status } = resumeParserReducer;
    setLoading(loading);

    if (!loading) {
      setLoading(loading);
      if (data && !error && status === 201) {
        handleSuccess();
      } else if (error) {
        message.error(error);
      }
    }
  }, [resumeParserReducer]);

  useEffect(() => {
    const { loading, data, error } = applyJobReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        handleSuccess();
      }
    }
  }, [applyJobReducer]);

  useEffect(() => {
    const { request } = uploadImageFileReducer;

    if (request?.fileData) {
      setUploadFileData((prevState) => ({
        ...prevState,
        fileData: {
          ...prevState.fileData,
          name: request.fileData.name,
          size: request.fileData.size,
          type: request.fileData.type,
          uid: request.fileData.uid
        }
      }));
    }
  }, [uploadImageFileReducer]);

  const onFinish = async () => {
    try {
      const data = form.getFieldsValue();
      if (!data.assign_data || !data.assign_data.userIds.length) {
        form.setFields([
          { name: "assign_data", errors: ["Please Select a recruiter!"] }
        ]);
        return;
      }

      const filterUrl = resumeFileURLReqData?.data?.url.split("?")[0];
      const commonData = {
        stage: "sourced",
        ...(job_id && { job_id }),
        ...(user_id && { user_id }),
        ...(data.assign_data && { assign_data: data.assign_data })
      };

      switch (visible?.type) {
        case "apply_resume":
          await dispatch(
            resumeParserAction({
              parser_type: "application",
              pdfUrl: filterUrl,
              job_id,
              ...commonData,
              ...uploadFileData,
              ...(data.assign_data && { assign_data: data.assign_data })
            })
          );
          break;

        default:
          await dispatch(
            applyJobAction({ ...data, ...commonData, ...uploadFileData })
          );
          break;
      }
    } catch (error) {
      handleError(error);
    }
  };

  const showFooterButtons = visible?.type !== "apply_resume";
  const isResumeUploaded = uploadFileData.resume || resumeParserFileName;
  const filter = useMemo(
    () => ({
      selective: true,
      client_id: client_id
    }),
    [client_id]
  );

  return (
    <Drawer
      placement="right"
      closable={false}
      open={visible.show}
      width={useIsMobileView() ? "100%" : "40%"}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {visible?.data && (
            <h5 className="fw-bold" style={{ fontSize: "1rem" }}>
              <Avatar
                src={
                  visible?.data?.company_photo
                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${visible?.data?.company_photo}`
                    : undefined
                }
                icon={!visible?.data?.company_photo ? <UserOutlined /> : null}
                alt={visible?.data?.company_name || "User"}
                style={{ marginRight: 8 }}
              />
              <a
                className="text-decoration-underline cursor-pointer"
                style={{ color: "#005c53" }}
              >
                {visible?.data?.job_title}
              </a>
            </h5>
          )}
          <CloseOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
      }
      footer={
        showFooterButtons && (
          <Row justify="space-between">
            <Col>
              <Button className="custom-button" type="button" onClick={onClose}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="custom-button"
                loading={loading}
                onClick={() => form.submit()}
                type="submit"
              >
                Save
              </Button>
            </Col>
          </Row>
        )
      }
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <div className="candidate-db-3N7vW">
          <Form.Item
            label="Select a Recruiter"
            name="assign_data"
            rules={[{ required: true, message: "Please select a Recruiter!" }]}
          >
            <UserCustomSelect
              form={form}
              multiple={true}
              filter={isMentorpalTeamUser() ? filter : undefined}
            />
          </Form.Item>

          {visible?.type === "apply_resume" ? (
            <>
              <Form.Item
                label="Upload Resume"
                name="resume"
                className="candidate-db-1L916 candidate-db-ZvWLm candidate-db-uBZyw"
                rules={[
                  {
                    required: !resumeParserFileName,
                    message: "Please upload your resume"
                  }
                ]}
              >
                <ImageUploadComponent
                  uploadPath="hire_talent/resume/"
                  acceptedFileTypes="application/pdf, .pdf"
                  uploadType="PDF Resume"
                  onFileUpload={(profileImageUrl) => {
                    console.log(profileImageUrl);
                    setResumeParserFileName(profileImageUrl);
                  }}
                />
              </Form.Item>

              {isResumeUploaded && (
                <Form.Item>
                  <Button
                    type="primary"
                    className="button--2de5X button--14TuV button--FDW79 primary--25RCR main_border_radius"
                    style={{ marginRight: 8, backgroundColor: "#005c53" }}
                    loading={loading}
                    onClick={() => form.submit()}
                  >
                    Save
                  </Button>
                </Form.Item>
              )}
            </>
          ) : (
            <>
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input your first name!" }
                ]}
              >
                <Input className="text" size="large" />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your last name!" }
                ]}
              >
                <Input className="text" size="large" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  { type: "email", message: "Please enter a valid email" }
                ]}
              >
                <Input className="text" size="large" />
              </Form.Item>
              <Form.Item
                label="Phone number"
                name="phone"
                rules={[
                  { required: true, message: "Please input your Phone number!" }
                ]}
              >
                <Input className="number" size="large" />
              </Form.Item>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  { required: true, message: "Please enter your Address" }
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Divider>Photo</Divider>
              <Form.Item name="photo" label="Photo">
                <ImageUploadComponent
                  uploadPath="hire_talent/profile/"
                  acceptedFileTypes="image/*"
                  uploadType="Photo"
                  onFileUpload={(profileImageUrl) =>
                    setUploadFileData((prevState) => ({
                      ...prevState,
                      photo: profileImageUrl
                    }))
                  }
                />
              </Form.Item>
              <Divider>Resume</Divider>
              <Form.Item
                name="resume"
                label="Resume"
                rules={[
                  {
                    required: !uploadFileData?.resume,
                    message: "Please upload your Resume"
                  }
                ]}
              >
                <ImageUploadComponent
                  uploadPath="hire_talent/resume/"
                  acceptedFileTypes="application/pdf, .pdf"
                  uploadType="PDF Resume"
                  onFileUpload={(resumeURL) =>
                    setUploadFileData((prevState) => ({
                      ...prevState,
                      resume: resumeURL
                    }))
                  }
                />
              </Form.Item>
            </>
          )}
        </div>
      </Form>
    </Drawer>
  );
};

export default AddResumeDrawer;
