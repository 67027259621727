import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Form,
  Button,
  Modal,
  message,
  Input,
  Row,
  Col,
  Divider
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  InputNumber,
  Switch,
  DatePicker,
  TimePicker,
  Select,
  Checkbox,
  Tooltip
} from "antd";
import { updateAssessmentAction } from "../../../../redux/actions/assessment";
import moment from "moment";
import { experienced, job_type } from "../../../../Assets/data/Job";

const { Option } = Select;
const { Content, Sider } = Layout;

const SettingsComponent = ({ data }) => {
  const [form] = Form.useForm();
  const [selectedSection, setSelectedSection] = useState("general");

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const updateAssessmentReducer = useSelector(
    (state) => state.updateAssessmentReducer
  );

  useEffect(() => {
    const { loading, data, error, request } = updateAssessmentReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      message.success("Details updated successfully!");
    }
    if (!loading && !data && error) {
      console.warn("error", error);
      message.error(error);
    }
  }, [updateAssessmentReducer]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        assessment_id: data?.assessment_id,
        deadlinesAndExpiration: {
          candidateDeadline: {
            candidateDeadline_enabled:
              data.deadlinesAndExpiration?.candidateDeadline
                ?.candidateDeadline_enabled,
            deadlineDays: data.deadlinesAndExpiration?.candidateDeadline
              ?.deadlineDays
              ? data.deadlinesAndExpiration?.candidateDeadline?.deadlineDays
              : 7,
            sendRemindersBeforeDeadline:
              data.deadlinesAndExpiration?.candidateDeadline
                ?.sendRemindersBeforeDeadline || true
          },
          assessmentExpiration: {
            assessmentExpiration_enabled:
              data.deadlinesAndExpiration?.assessmentExpiration
                ?.assessmentExpiration_enabled,
            expirationDate: data.deadlinesAndExpiration?.assessmentExpiration
              ?.expirationDate
              ? moment(
                  data.deadlinesAndExpiration.assessmentExpiration
                    .expirationDate
                )
              : null,
            expirationTime: data.deadlinesAndExpiration?.assessmentExpiration
              ?.expirationTime
              ? moment(
                  data.deadlinesAndExpiration.assessmentExpiration
                    .expirationTime,
                  "HH:mm"
                )
              : null,
            timezone:
              data.deadlinesAndExpiration?.assessmentExpiration?.timezone || "",
            sendRemindersBeforeExpiration:
              data.deadlinesAndExpiration?.assessmentExpiration
                ?.sendRemindersBeforeExpiration || true
          }
        }
      });
    }
  }, [data, form]);

  const handleMenuClick = ({ key }) => {
    setSelectedSection(key);
  };

  const handleFinish = (values) => {
    const payload = {
      ...values,
      assessment_id: data?.assessment_id,
      assessment_administration: {
        ...values.assessment_administration,
        disclaimer: values.assessment_administration?.disclaimer || false,
        anti_cheating: values.assessment_administration?.anti_cheating || false
      },
      assessment_content: {
        allowed_languages: values.assessment_content?.allowed_languages,
        cutoff_score: values.assessment_content?.cutoff_score || 50
      },
      deadlinesAndExpiration: {
        candidateDeadline: {
          ...values.deadlinesAndExpiration?.candidateDeadline,
          candidateDeadline_enabled:
            values.deadlinesAndExpiration?.candidateDeadline
              ?.candidateDeadline_enabled || false
        },
        assessmentExpiration: {
          ...values.deadlinesAndExpiration?.assessmentExpiration,
          assessmentExpiration_enabled:
            values.deadlinesAndExpiration?.assessmentExpiration
              ?.assessmentExpiration_enabled || false
        }
      }
    };

    Modal.confirm({
      title: "Confirm Update",
      content: "Do you want to update the assessment with these changes?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(updateAssessmentAction(payload));
      },
      onCancel: () => {
        message.info("Update canceled.");
      }
    });
  };

  const renderSectionFields = (sectionKey) => {
    switch (sectionKey) {
      case "general":
        return <GeneralSettings />;
      case "administration-content":
        return <AdministrationContentSettings />;
      case "deadlines":
        return <DeadlinesAndExpiration form={form} />;
      default:
        return null;
    }
  };

  return (
    <Layout className="bg-white" style={{ minHeight: "100vh" }}>
      <Sider
        width={280}
        className="site-layout-background"
        breakpoint="lg"
        collapsedWidth="0"
        style={{ position: "sticky", top: 0 }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[selectedSection]}
          style={{ height: "100%", borderRight: 0 }}
          onClick={handleMenuClick}
        >
          <Menu.Item key="general">General</Menu.Item>
          <Menu.Item key="administration-content">
            Administration & Content
          </Menu.Item>
          <Menu.Item key="deadlines">Deadline and Expiration Date</Menu.Item>
        </Menu>
      </Sider>
      <Layout
        style={{
          padding: "0 24px",
          marginTop: 64,
          minHeight: 280
        }}
      >
        <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
          <Form form={form} onFinish={handleFinish} layout="vertical">
            {renderSectionFields(selectedSection)}
            <Form.Item>
              <Button
                className="custom-button"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ height: "40px", padding: "0px" }}
              >
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SettingsComponent;

const GeneralSettings = () => {
  return (
    <div>
      <Form.Item
        name="title"
        label="Assessment Name"
        rules={[
          { required: true, message: "Please input the assessment name!" }
        ]}
      >
        <Input
          size="large"
          placeholder="Sample Full-stack Developer Hiring Assessment"
        />
      </Form.Item>

      <Form.Item
        name="assessment_role"
        label="What role is this assessment for?"
      >
        <Input size="large" placeholder="Add a Role" />
      </Form.Item>
      <Form.Item name="work_experience" label="Work Experience">
        <Select
          mode="multiple"
          size="large"
          placeholder="Select experience level"
        >
          {experienced?.map((item) => (
            <Option key={item?.label} value={item?.label}>
              {item?.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="work_arrangement" label="Work arrangement">
        <Select size="large" placeholder="Select a work arrangement">
          {job_type?.map((item) => (
            <Option key={item.label} value={item?.label}>
              {item?.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="assessment_duration" label="Duration">
        <Input size="large" placeholder="Duration in minutes" disabled />
      </Form.Item>
    </div>
  );
};

const AdministrationContentSettings = () => {
  return (
    <div>
      <Form.Item
        name={["assessment_administration", "allowed_attempts"]}
        label="Allowed Attempts"
        rules={[
          {
            type: "number",
            min: 1,
            message: "At least one attempt is required"
          }
        ]}
      >
        <InputNumber
          size="large"
          min={1}
          defaultValue={1}
          disabled
          placeholder="Enter allowed attempts"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name={["assessment_content", "cutoff_score"]}
        label="Cutoff Score (Percentage)"
      >
        <InputNumber
          size="large"
          min={0}
          max={100}
          placeholder="Enter cutoff score"
          defaultValue={50}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </div>
  );
};

const DeadlinesAndExpiration = ({ form }) => {
  return (
    <>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Enable Candidate Deadline"
            name={[
              "deadlinesAndExpiration",
              "candidateDeadline",
              "candidateDeadline_enabled"
            ]}
            valuePropName="checked"
          >
            <Switch defaultChecked={false} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="Days Before Deadline"
            name={[
              "deadlinesAndExpiration",
              "candidateDeadline",
              "deadlineDays"
            ]}
          >
            <InputNumber min={0} max={30} defaultValue={7} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Enable Assessment Expiration"
            name={[
              "deadlinesAndExpiration",
              "assessmentExpiration",
              "assessmentExpiration_enabled"
            ]}
            valuePropName="checked"
          >
            <Switch defaultChecked={false} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="Expiration Date"
            name={[
              "deadlinesAndExpiration",
              "assessmentExpiration",
              "expirationDate"
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
