import React, { useEffect, useState } from "react";
import {
  Modal,
  Col,
  Row,
  Table,
  Tabs,
  Typography,
  Radio,
  Spin,
  Layout,
  Divider,
  message
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTestDetailAction } from "../../../../../redux/actions/assessment";
import CodeEditor from "../../../../Compiler/CodeEditor";
import logo from "../../../../../Assets/img/y3awvixq.png";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./steps.css";
import { QuillTextPreview } from "../../../../../Utils/TextEditor";

const { Title } = Typography;
const { TabPane } = Tabs;
const { Header } = Layout;

const PreviewModal = ({ previewModal, setPreviewModal, initialData }) => {
  const dispatch = useDispatch();
  const isMultipleTests = Array.isArray(initialData);
  const [currentTestId, setCurrentTestId] = useState(
    isMultipleTests ? initialData[0]?.test_id : initialData?.test_id
  );

  const [loading, setLoading] = useState(false);
  const getTestDetailsReducer = useSelector(
    (state) => state.getTestDetailsReducer
  );
  const [data, setData] = useState(null);

  useEffect(() => {
    if (currentTestId) {
      setLoading(true);
      dispatch(getTestDetailAction({ test_id: currentTestId }));
    }
    return () => {
      dispatch({ type: "GET_TEST_RESET" });
    };
  }, [dispatch, currentTestId]);

  useEffect(() => {
    const { loading, data, error } = getTestDetailsReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        setData(data.data);
      } else if (error) {
        console.warn("Error:", error);
        message.error(error);
      }
    }
  }, [getTestDetailsReducer]);

  const renderMCQ = (item) => (
    <>
      <div style={{ padding: "20px", fontSize: "16px" }}>
        <strong>Choose Only One</strong>
      </div>
      <Radio.Group value={item}>
        {item?.mcqChoices?.map((choice) => (
          <div className="radio-container" key={choice?.id}>
            <div className="radio-box">
              <Radio value={choice?.value}>{choice?.value}</Radio>
            </div>
            <br />
          </div>
        ))}
      </Radio.Group>
    </>
  );

  const renderCode = (item) => (
    <div className="editor-compiler-container">
      <CodeEditor
        code={item?.code}
        question_id={item?.question_id}
        onSave={(data) => {
          console.log("Saved code data:", data);
        }}
      />
    </div>
  );

  const renderQuestionnaire = (item) => (
    <>
      <div style={{ padding: "20px", fontSize: "16px" }}>
        <strong>Choose Only One</strong>
      </div>
      <Radio.Group>
        {item?.questionnaireChoices?.map((choice) => (
          <div className="radio-container" key={choice?.id}>
            <div className="radio-box">
              <Radio value={choice?.value}>{choice?.value}</Radio>
            </div>
            <br />
          </div>
        ))}
      </Radio.Group>
    </>
  );

  const renderQuestionCard = (item) => {
    switch (item?.questionType) {
      case "mcq":
        return renderMCQ(item);
      case "code":
        return renderCode(item);
      case "questionnaire":
        return renderQuestionnaire(item);
      default:
        return null;
    }
  };

  const renderContent = () => (
    <>
      {data?.questions?.slice(0, 3).map((item, index) => (
        <React.Fragment key={index}>
          <Row
            className="mx-40 my-4"
            style={{ borderRadius: "10px", border: "3px solid #e8e8e8" }}
          >
            <Col span={8} className="left-col" style={{ padding: "16px" }}>
              <div style={{ padding: "20px" }}>
                <strong>Question:</strong>
                <Title level={4}>{item?.questionName}</Title>
              </div>
              <div style={{ padding: "20px" }}>
                <strong>Description:</strong>
                <QuillTextPreview content={item?.problemDescription} />
              </div>
              {item?.questionType === "code" && (
                <div style={{ padding: "20px" }}>
                  <strong>Test Case:</strong>
                  <Table
                    bordered
                    dataSource={item?.testCases}
                    columns={[
                      {
                        title: "Input",
                        dataIndex: "input",
                        key: "input"
                      },
                      {
                        title: "Output",
                        dataIndex: "output",
                        key: "output"
                      }
                    ]}
                    pagination={false}
                    rowKey="input"
                  />
                </div>
              )}
            </Col>
            <Col span={16} className="right-col" style={{ padding: "16px" }}>
              {renderQuestionCard(item)}
            </Col>
          </Row>
          <Divider />
        </React.Fragment>
      ))}

      <div className="p-infobox">
        <InfoCircleOutlined
          style={{ color: "#1890ff", fontSize: "18px", marginRight: "8px" }}
        />
        <span>You have only permission to see preview of 3 tests.</span>
      </div>
    </>
  );

  return (
    <>
      <Modal
        className="custom-modal-preview"
        visible={previewModal}
        width="100%"
        onCancel={() => setPreviewModal(false)}
        footer={null}
        title={
          <Header className="main-header-test">
            <a className="main-header-test__logo-link">
              <img
                className="main-header-test__logo"
                src={logo}
                alt="Ankit Back-End Developer Hiring Test"
              />
              <h3 className="main-header-test__title">
                Mpal Solution Private Limited
              </h3>
            </a>
            <div className="main-header-test__test-info"></div>
          </Header>
        }
      >
        <div style={{ padding: 0 }}>
          {isMultipleTests ? (
            <Tabs
              defaultActiveKey={initialData[0]?.test_id}
              onChange={(key) => setCurrentTestId(key)}
              tabBarStyle={{ marginBottom: 20, marginLeft: 24 }}
            >
              {initialData?.map((test) => (
                <TabPane tab={test?.title} key={test?.test_id}>
                  <Spin spinning={loading}>{renderContent()}</Spin>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <Spin spinning={loading}>{renderContent()}</Spin>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PreviewModal;
