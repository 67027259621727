import React, { useEffect, useRef, useState } from "react";

import mainimg from "../../../src/Assets/img/bdk23qfm.png";
import fbLogo from "./img/fb.png";
import twitterLogo from "./img/twitter.png";
import linkedInLogo from "./img/linkedin.png";
import instaLogo from "./img/insta.png";
import youtubeLogo from "./img/youtube.png";
import { Form, Input, Checkbox, Button, message } from "antd";
import img1 from "./img/working_4727424.png";
import img2 from "./img/7339268.png";
import img3 from "./img/6171902.png";
import img5 from "./img/4153553.jpg";
import clock from "./img/clock.png";
import learning from "./img/learning.png";
import projects from "./img/projects.png";
import opport from "./img/opport.png";
import { useDispatch, useSelector } from "react-redux";
import "./custom.css";
import PublicHeader from "../Header/publicHeader";
import { Collapse } from "antd";
import { enquiryAction } from "../../redux/actions/common";
const { Panel } = Collapse;

export const hireTaletnFeatures = [
  {
    icon: clock,
    title: "Job Description Analysis",
    description:
      " Once you finalize the job description, we get to work, aligning our understanding of the required skills and your company's unique culture."
  },
  {
    icon: projects,
    title: "Algorithmic Shortlisting",
    description:
      "Our proprietary matching algorithm sifts through candidates, selecting only those who meet the stringent criteria set by you."
  },
  {
    icon: learning,
    title: "Human Touch",
    description:
      " Post-algorithmic shortlisting, candidates face a rigorous technical interview conducted by our elite panel. Only the best progress."
  },
  {
    icon: opport,
    title: "Feedback Loop",
    description:
      " Every candidate feedback, especially rejections, is a learning curve for us. We fine-tune our process continuously, ensuring better matches with each iteration."
  }
];
const HireTalentLanding = () => {
  const point__svg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#d96980"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z" />
    </svg>
  );
  const faq = [
    {
      question: "What sets Mentorpal apart in tech hiring?",
      answer:
        "Mentorpal stands out by conducting a comprehensive pre-interview process, ensuring only the most qualified candidates proceed. This not only enhances your hiring efficiency but also integrates seamlessly with your existing workflow, adding significant value."
    },
    {
      question: "What's the structure of your technical interviews?",
      answer:
        "Our technical interviews are meticulously designed to assess both fundamental and advanced skills, tailored to match the specific requirements of each role. We ensure alignment with your needs by closely collaborating with your hiring team."
    },
    {
      question: "What qualifications do your interviewers hold?",
      answer:
        "Our interviewers are seasoned professionals from top-tier tech companies, boasting extensive experience in various technical domains. We meticulously match interviewer expertise with candidate profiles to ensure a deep and relevant evaluation."
    },
    {
      question: " How do you ensure candidate quality and fit?",
      answer:
        "We evaluate candidates for both technical proficiency and cultural compatibility, ensuring they align with your company's values and team dynamics. Should a recommended candidate not meet your final expectations, we're committed to reassessing and refining our selection process."
    },
    {
      question: "How does Mentorpal handle client feedback?",
      answer:
        "Client feedback is integral to our process. We employ a structured feedback loop to continually refine our candidate selection and interview methodologies, ensuring constant improvement and alignment with your needs."
    },
    {
      question: " What's your pricing structure and ROI?",
      answer:
        "We offer transparent pricing tailored to your specific needs, ensuring clear ROI by significantly enhancing your hiring efficiency and quality. No long-term commitments are required to start; we believe in proving our value through results."
    },
    {
      question: " How does your service integrate with existing HR processes?",
      answer:
        "Mentorpal is designed to complement and enhance your current HR systems, offering flexible integration and dedicated support to ensure smooth operations and effective communication throughout the hiring cycle."
    },
    {
      question: " Do you have any success stories or case studies?",
      answer:
        "Yes, we have numerous success stories where we've dramatically streamlined the tech hiring process for our clients, leading to quicker hires and higher quality candidates. We're happy to share these upon request."
    },
    {
      question: " Can your service scale with our hiring needs?",
      answer:
        "Absolutely. Mentorpal is built for scalability, capable of accommodating everything from individual hires to large-scale recruitment drives, with an average turnaround time that sets industry standards."
    },
    {
      question: "How do you ensure legal compliance and data privacy?",
      answer:
        "We adhere strictly to legal and compliance standards, ensuring all operations respect data privacy and confidentiality laws. We're committed to maintaining the highest levels of integrity and security in all our processes."
    }
  ];
  const targetDivRef = useRef(null);
  const handleButtonClick = () => {
    if (targetDivRef.current) {
      try {
        targetDivRef.current.scrollIntoView({ behavior: "smooth" });
      } catch (error) {
        console.error("Error while scrolling:", error);
      }
    } else {
      console.warn("Target div ref is not assigned.");
    }
  };

  return (
    <>
      <PublicHeader />
      <div className="hero-section app-hero">
        <div className="container">
          <div className="hero-content app-hero-content text-center">
            <div className="row justify-content-md-center">
              <div className="col-md-12">
                <h1 style={{ color: "#11294f" }}>
                  Fast track your tech hiring with prescreened candidates
                </h1>
                <p
                  className=""
                  style={{
                    marginTop: "20px",
                    fontSize: "1.5rem",
                    lineHeight: "2.5rem",
                    color: "#11294f"
                  }}
                >
                  We provide potential hires post deep vetting tech interviews,{" "}
                  <br />
                  custom tailored to your company growth.
                </p>
                <br className="d-block d-md-none" />{" "}
                <div className=" get-started-cls">
                  <button type="button" className="find-btn btn btn-primary">
                    <a
                      href="#form"
                      style={{
                        listStyle: "none",
                        textDecoration: "none",
                        color: "white"
                      }}
                      onClick={handleButtonClick}
                    >
                      {" "}
                      Start Hiring
                    </a>
                  </button>
                </div>
                <div className="text-muted" style={{ marginTop: 10 }}>
                  we'll alleviate 80% of your screening workload.{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="services-section text-center" id="services">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-md-8" />
                <div className="col-md-12 text-center">
                  <div className="services">
                    <div className="row">
                      <div
                        className="col-sm-4 wow fadeInUp"
                        data-wow-delay="0.2s"
                      >
                        <div className="services-icon">
                          <img
                            alt="Service"
                            height={60}
                            src={img1}
                            width={60}
                          />
                        </div>
                        <div className="services-description">
                          <h1>Provide Your Job Description </h1>
                          <p>
                            Submit the specifics of the role you're looking to
                            fill; include skills, qualifications, and cultural
                            fit to help us understand your exact needs.
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-sm-4 wow fadeInUp"
                        data-wow-delay="0.3s"
                      >
                        <div className="services-icon">
                          <img
                            alt="Service"
                            className="icon-2"
                            height={60}
                            src={img2}
                            width={60}
                          />
                        </div>
                        <div className="services-description">
                          <h1>We conduct initial tech interview</h1>
                          <p>
                            Our experts handle the first round of technical
                            assessments, ensuring only the most qualified
                            candidates advance, saving you time and resources.
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-sm-4 wow fadeInUp"
                        data-wow-delay="0.4s"
                      >
                        <div className="services-icon">
                          <img
                            alt="Service"
                            className="icon-3"
                            height={60}
                            src={img3}
                            width={60}
                          />
                        </div>
                        <div className="services-description">
                          <h1>Proceed with the final stages</h1>
                          <p>
                            With the preliminary screening done, you can focus
                            on the final interviews to select the best fit for
                            your team from the shortlisted candidates.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {/* First Class Experience */}
        <div className="row padding____section">
          <div className="col-sm-4 col-md-6 d-flex justify-content-center">
            <div className="benefit_wrapper">
              <div className="benefit_header">
                <h2 className="header_head">Why Mentorpal?</h2>
              </div>
              <div className="benefits_sales benefit_counter  fce_pitch">
                <div className="benefits_pitch">
                  <h3 className="text">Efficiency at Every Step </h3>
                  <p className="para">
                    We understand that the conventional hiring process can be a
                    drain on your valuable resources, particularly the time of
                    your skilled engineers. With Mentorpal, you can say goodbye
                    to the endless cycle of screening and first-round technical
                    rejections.
                  </p>
                </div>
                <div className="benefits_pitch">
                  <h3 className="text">Beyond the Resume</h3>
                  <p className="para">
                    In today's market, a good resume isn't good enough. We delve
                    deeper, conducting initial technical interviews on your
                    behalf to ensure that only the most qualified candidates
                    make it to your doorstep.
                  </p>
                </div>
                <div className="benefits_pitch">
                  <h3 className="text">A Network of Excellence</h3>
                  <p className="para">
                    Our pool of interviewers isn't just experienced; they're
                    proven experts from Tier 1 tech companies, bringing insights
                    and evaluation standards that set us apart.
                  </p>
                </div>
                <div className="benefits_pitch">
                  <h3 className="text">Tailored Matches for Every Role</h3>
                  <p className="para">
                    We understand that every role is unique. Our matching
                    algorithm and expert insights ensure candidates align with
                    your specific technical and cultural requirements. Benefit
                    from our deep industry knowledge and a personalized approach
                    to every hiring challenge.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-6">
            <img
              className="benefit_desktop payment_img right_desktop "
              src={img5}
              alt="First Class Experience Desktop"
            />
          </div>
        </div>
      </div>
      {/* course features */}
      <section className="benefits">
        <ProcessCards />
      </section>
      <div className="container px-4">
        {/* Course Community */}
        <section className="container py-5">
          <div className="row">
            <div className="col-12 col-md-6 overflow-auto mb-4 mb-md-0">
              <div className="benefit_header">
                <h2 className="header_head">
                  Our Process: Streamlining Your Success
                </h2>
                <div className="benefits_para two_para ben_para">
                  <div className="benefits_division d-flex align-items-start mb-3">
                    <span className="tick me-2">{point__svg}</span>
                    <div>
                      <h3 className="h5">Understanding Your Needs</h3>
                      <p>
                        We kickstart the process with an in-depth discussion to
                        understand your specific technical requirements and
                        company culture.
                      </p>
                    </div>
                  </div>
                  <div className="benefits_division d-flex align-items-start mb-3">
                    <span className="tick me-2">{point__svg}</span>
                    <div>
                      <h3 className="h5">The Mentorpal Match</h3>
                      <p>
                        Leveraging our vast network, we identify candidates who
                        not only meet but exceed your expectations.
                      </p>
                    </div>
                  </div>
                  <div className="benefits_division d-flex align-items-start mb-3">
                    <span className="tick me-2">{point__svg}</span>
                    <div>
                      <h3 className="h5">Expert-Led Technical Interviews</h3>
                      <p>
                        Our tech professionals conduct thorough interviews,
                        assessing candidates on skills crucial for your role.
                      </p>
                    </div>
                  </div>
                  <div className="benefits_division d-flex align-items-start mb-3">
                    <span className="tick me-2">{point__svg}</span>
                    <div>
                      <h3 className="h5">Shortlist of the Best</h3>
                      <p>
                        Receive a curated list of top candidates, complete with
                        detailed interview feedback and recommendations.
                      </p>
                    </div>
                  </div>
                  <div className="benefits_division d-flex align-items-start mb-3">
                    <span className="tick me-2">{point__svg}</span>
                    <div>
                      <h3 className="h5">Seamless Integration</h3>
                      <p>
                        Once you've made your choice, we assist with the
                        onboarding process to ensure a seamless transition.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6" ref={targetDivRef}>
              <EnquiryForm />
            </div>
          </div>
        </section>
      </div>

      {/* <HowItWorks handleButtonClick={handleButtonClick} /> */}

      {/*  testimonial section */}
      <section className=" why_choose_ment">
        <div className="container">
          <div className="container">
            <center>
              <h2 className="my-4" style={{ color: "#b7308e" }}>
                Our Inspiring Mentors & Empowered Mentees
              </h2>
            </center>
            {/* <Testimonial /> */}
          </div>
        </div>
      </section>

      <section className="container padding____section ">
        <div className="container">
          <div className="frequently_asked ">
            <div className="row">
              <div className="col-md-6">
                <div className="mentoring-cont p-4">
                  <div className="faq__pricing">
                    <h3 className="text-[#b7308e] text-5xl font-semibold mb-6">
                      Frequently asked Questions
                    </h3>
                    <div className="inner-title pb-2">
                      <h2 className="text-gray-800 text-3xl font-semibold mb-0  text-start">
                        Can't find the answer you <span> are looking for?</span>
                      </h2>
                    </div>
                  </div>
                  <ul>
                    <li className="flex mb-4">
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path
                            fill="#d96980"
                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"
                          />
                        </svg>
                      </span>
                      <a onClick={handleButtonClick} className="ml-4">
                        <span className="cont-cls">Reach out to us</span>
                      </a>
                    </li>
                    <li className="flex mb-4">
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path
                            fill="#d96980"
                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"
                          />
                        </svg>
                      </span>
                      <a className="ml-4">
                        <span className="cont-cls">
                          Help & grow with MentroPal
                        </span>
                      </a>
                    </li>
                    <li className="flex mb-4">
                      <span className="tick">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path
                            fill="#d96980"
                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"
                          />
                        </svg>
                      </span>
                      <span className="cont-cls ml-4">
                        Want a detail demo call
                      </span>
                    </li>
                  </ul>
                  <div className="get-started-cls" id="get-started-cls">
                    <a
                      type="button"
                      className="btn btn-primary"
                      onClick={handleButtonClick}
                    >
                      Reach out to us
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 faq___overflow">
                <Collapse accordion>
                  {faq.map((faqItem, index) => (
                    <Panel
                      header={faqItem.question}
                      style={{ backgroundColor: "white" }}
                      key={index}
                    >
                      <p>{faqItem.answer}</p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

const EnquiryForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const enquiryReducer = useSelector((state) => state.enquiryReducer);
  const onFinish = (values) => {
    dispatch(enquiryAction({ ...values }));
  };

  useEffect(() => {
    const { data, loading, error, status } = enquiryReducer;
    setloading(loading);
    if (!loading && data && !error && status === 201) {
      message.success(
        "your enquiry is send ,our team will shortly call you back"
      );
      form.resetFields();
    }
    if (!loading && error && status === 500) {
      message.error(error);
      console.warn("error", error);
    }
  }, [enquiryReducer]);
  return (
    <div className=" registration-form">
      <Form form={form} onFinish={onFinish}>
        <h3 className="header_head text-center pb-3">Talk to our expert</h3>
        <Form.Item
          className="title-fields form-group"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input the name!"
            }
          ]}
        >
          <Input size="large" placeholder="Enter your name*" />
        </Form.Item>

        <Form.Item
          className="title-fields form-group"
          name="company_email"
          rules={[
            {
              required: true,
              message: "Please input the Work email!"
            }
          ]}
        >
          <Input size="large" placeholder="Enter your Work Email*" />
        </Form.Item>

        <Form.Item
          className="title-fields form-group"
          name="company_name"
          rules={[
            {
              required: true,
              message: "Please input the company name!"
            }
          ]}
        >
          <Input size="large" placeholder="Enter your Company Name*" />
        </Form.Item>

        <Form.Item
          className="title-fields form-group"
          name="contact"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please input mobile number!"
            }
          ]}
        >
          <Input size="large" placeholder="Enter your phone number*" />
        </Form.Item>
        <Form.Item
          className="title-fields form-group"
          name="message"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please input message!"
            }
          ]}
        >
          <Input.TextArea size="large" placeholder="Enter your message*" />
        </Form.Item>

        <Form.Item
          className="title-fields form-group"
          name="termsAndConditions"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please agree to the terms!"
            }
          ]}
        >
          <Checkbox size="large">I agree to the terms and conditions*</Checkbox>
        </Form.Item>

        <Form.Item className="title-fields form-group get-started-cls">
          <Button
            type="primary"
            htmlType="submit"
            className="find-btn btn btn-primary"
            loading={loading}
            disabled={loading}
          >
            Get a Callback
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export const Footer = () => {
  return (
    <>
      <footer className="course__footer p-4 bg-[#f9fafa]">
        <div className="container px-0 ">
          <div className="row">
            <div className="col-12 col-md-4 mb-4 d-flex justify-content-center align-items-center">
              <div className="met_fot1 " id="met_fot1">
                <img src={mainimg} alt="mentorpalLogo" />
              </div>
            </div>

            <div className="col-12 col-md-4 mb-4 d-flex flex-column justify-content-center align-items-center text-center">
              <h6 className="footer-title">Get in Touch</h6>
              <p>
                #D1004, Goyal Orchid Whitefield
                <br /> Bengaluru, Karnataka (560066)
                <br /> India
              </p>
              <p>
                <a href="mailto:help@mentorpal.ai">help@mentorpal.ai</a>
                <br />
                <a href="tel:9660367722">+91 8619825569</a>
              </p>
            </div>

            <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
              <ul className="list-inline text-center text-md-left">
                <li className="list-inline-item mx-2">
                  <a
                    href="https://www.facebook.com/profile.php?id=100090574942977"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src={fbLogo} />
                  </a>
                </li>
                <li className="list-inline-item mx-2">
                  <a href="https://twitter.com/mentorpal">
                    <img alt="" src={twitterLogo} />
                  </a>
                </li>
                <li className="list-inline-item mx-2">
                  <a
                    href="https://www.linkedin.com/company/mentorpal/mycompany/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src={linkedInLogo} />
                  </a>
                </li>
                <li className="list-inline-item mx-2">
                  <a
                    href="https://www.instagram.com/mentorpalai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src={instaLogo} />
                  </a>
                </li>
                <li className="list-inline-item mx-2">
                  <a
                    href="https://www.youtube.com/@mentorpal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src={youtubeLogo} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright why_choose_ment">
        <div className="container">
          <div className="copyright-cont text-center">
            <p>2022 All rights are reserved @Mpal Solution Pvt. Ltd.</p>
          </div>
        </div>
      </section>
    </>
  );
};

const ProcessCards = () => {
  return (
    <>
      <section className="container">
        <div className="w-layout-grid  container">
          {hireTaletnFeatures.map((milestone, index) => (
            <div
              key={index}
              className="milestone-box why-us"
              style={{ width: "100%" }}
            >
              <img
                src={milestone.icon}
                loading="lazy"
                alt=""
                className="why-us-icons"
              />
              <h1 className="heading-2 general">{milestone.title}</h1>
              <div className="copy-text _2">{milestone.description}</div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default HireTalentLanding;
