import {
  getAllScheduleInterviewListConstants,
  getInterviewCalendarConstants,
  getInterviewDetailsConstants,
  getInterviewerListConstants,
  getInterviewerReportConstants,
  interviewFeedbackConstants,
  scheduleInterviewConstants,
  updateInterviewDetailsConstants,
} from "../../constants";

export function getInterviewerListAction(data) {
  return {
    type: getInterviewerListConstants.GET_INTERVIEWER_LIST_REQUEST,
    data,
  };
}
export function getInterviewerReportAction(data) {
  return {
    type: getInterviewerReportConstants.GET_INTERVIEWER_REPORT_REQUEST,
    data,
  };
}
export function getInterviewDetailsAction(data) {
  return {
    type: getInterviewDetailsConstants.GET_INTERVIEW_DETAILS_REQUEST,
    data,
  };
}
export function getAllScheduleInterviewListAction(data) {
  return {
    type: getAllScheduleInterviewListConstants.GET_SCHEDULE_INTERVIEW_LIST_REQUEST,
    data,
  };
}
export function scheduleInterviewAction(data) {
  return {
    type: scheduleInterviewConstants.SCHEDULE_INTERVIEW_REQUEST,
    data,
  };
}
export function interviewFeedbackAction(data) {
  return {
    type: interviewFeedbackConstants.INTERVIEW_FEEDBACK_REQUEST,
    data,
  };
}
export function updateInterviewDetailsAction(data) {
  return {
    type: updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_REQUEST,
    data,
  };
}
export function getInterviewCalendarAction(data) {
  return {
    type: getInterviewCalendarConstants.GET_INTERVIEW_CALENDAR_REQUEST,
    data,
  };
}
