import {
  candidateLoginConstants,
  candidateStatsReportConstants,
  getCandidateDetailsDashboardConstants
} from "../../constants";

export const candidateLoginReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case candidateLoginConstants.CANDIDATE_LOGIN_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case candidateLoginConstants.CANDIDATE_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case candidateLoginConstants.CANDIDATE_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case candidateLoginConstants.CANDIDATE_LOGIN_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const candidateStatsReportReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case candidateStatsReportConstants.CANDIDATE_LOGIN_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case candidateStatsReportConstants.CANDIDATE_STATS_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case candidateStatsReportConstants.CANDIDATE_STATS_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case candidateStatsReportConstants.CANDIDATE_STATS_REPORT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getCandidateDetailsDashboardReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getCandidateDetailsDashboardConstants.GET_CANDIDATE_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getCandidateDetailsDashboardConstants.GET_CANDIDATE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getCandidateDetailsDashboardConstants.GET_CANDIDATE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getCandidateDetailsDashboardConstants.GET_CANDIDATE_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
