import React, { useRef, useEffect } from "react";
import { Editor } from "@monaco-editor/react";
import { Alert } from "antd";

const AceEditorType = ({ form ,data}) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const currentEditor = editorRef.current;
    if (currentEditor) {
      currentEditor.setValue(data?.codeStubs || "");
    }
  }, [form]);

  return (
    <>
      <Alert
        message="Important Instructions"
        description={
          <div>
            <p>
              Please ensure that the code snippets are properly formatted and
              contain valid syntax for the selected programming language.
            </p>
            <p>
              Remember to define the main function or entry point of the program
              as per the conventions of the selected language.
            </p>
            <ul>
              <li>
                In Python, include the <code>if __name__ == "__main__":</code>{" "}
                block.
              </li>
              <li>
                In Java, include the{" "}
                <code>public static void main(String[] args)</code> method.
              </li>
              <li>
                In C/C++, include the <code>int main()</code> function.
              </li>
              <li>
                For other languages, follow the respective conventions for the
                main function.
              </li>
            </ul>
          </div>
        }
        type="warning"
        showIcon
        style={{ marginBottom: "10px" }}
      />
      <Editor
        height="75vh"
        theme="vs-dark"
        language={data?.languages || "javascript"}
        onMount={(editor) => {
          editorRef.current = editor;
          editor.focus();
        }}
        value={form.getFieldValue("codeStubs") ||data?.codeStubs}
        name="codeStubs"
        onChange={(value) => {
          form.setFieldsValue({ codeStubs: value });
        }}
        options={{
          minimap: {
            enabled: false
          }
        }}
      />
    </>
  );
};

export default AceEditorType;
