import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Input,
  Typography, Rate,
  Form,
  message
} from "antd";
import { StepForwardOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { candidateAssessmentFeedbackAction } from "../../../redux/actions/assessment";
import { useLocation } from "react-router-dom";

const { TextArea } = Input;
const { Paragraph, Text, Title } = Typography;

const AssessmentFeedbackForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const assessment_invite_id = queryParams.get("assessment_invite_id");

  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);

  const candidateAssessmentFeedbackReducer = useSelector(
    (state) => state.candidateAssessmentFeedbackReducer
  );

  const handleSubmit = (values) => {
    dispatch(
      candidateAssessmentFeedbackAction({
        assessment_invite_id: assessment_invite_id,
        ...values
      })
    );
  };
  useEffect(() => {
    const { data, loading, error, status } = candidateAssessmentFeedbackReducer;
    setloading(loading);
    if (!loading && data && !error) {
      window.location.assign(
        `/assessment-completed?assessment_invite_id=${assessment_invite_id}`
      );
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [candidateAssessmentFeedbackReducer]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "20px",
        background: "#f0f2f5"
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: "900px",
          margin: "0 auto",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)"
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Title level={2}>Thank You</Title>
          <Paragraph>
            You've finished your assessment. How was your experience?
          </Paragraph>
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item className="py-1" name="rating">
              <Rate size="large" style={{ fontSize: "100px" }} />
            </Form.Item>
            <Form.Item className="py-2" name="explanation">
              <TextArea
                size="large"
                rows={7}
                placeholder="Please enter feedback..."
              />
            </Form.Item>
            <div
              style={{
                padding: "20px",
                borderRadius: "8px",
                backgroundColor: "#dbbd8f",
                color: "white",
                marginBottom: "20px"
              }}
            >
              <Text>
                To help make sure that our tests provide equal opportunities for
                everyone, we statistically analyze test results anonymously. By
                sharing this information about your background, you help us
                improve our tests for all candidates. This information will not
                be shared with your potential employer. Sharing this information
                with us is optional, and anything shared will be held in
                compliance with our{" "}
                <a href="/privacy-policy" style={{ color: "#AE1EA4" }}>
                  privacy policy
                </a>
                .
              </Text>
            </div>

            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                icon={<StepForwardOutlined />}
                className="custom-button"
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default AssessmentFeedbackForm;
