import React, { useEffect, useState } from "react";
import { message } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getJobDetailAction, applyJobAction } from "../../redux/actions/common";
import { useParams, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import ClientFooter from "../CarrierPage/ClientFooter";
import { getCurrecnySymbol } from "../../Utils/Common";
import { ApplyJobForm } from "./ApplyJobForm";
import { QuillTextPreview } from "../../Utils/TextEditor";
import {
  isLogged,
  REACT_USER_ID,
  REACT_USER_TYPE
} from "../Auth/tokenProvider";

const ApplyJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isCandidateLogin, setIsCandidateLogin] = useState(
    isLogged && REACT_USER_TYPE() === "candidate-dashboard" ? true : false
  );

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  const { getJobDetailReducer } = useSelector((state) => state);

  useEffect(() => {
    if (id) {
      dispatch(getJobDetailAction({ job_id: id, candidate: false }));
    }
    return () => {
      dispatch({ type: "GET_JOB_DETAIL_RESET" });
    };
  }, [dispatch, id]);

  useEffect(() => {
    const { data, loading, error } = getJobDetailReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        setData(data);
      }
      if (error) {
        message.error(error);
      }
    }
  }, [getJobDetailReducer]);

  const handleApplyJob = () => {
    if (isCandidateLogin) {
      const payload = {
        candidate_id: REACT_USER_ID(),
        job_id: id
      };
      dispatch(applyJobAction(payload));
    } else {
      setActiveTab(1);
    }
  };

  const applyJobReducer = useSelector((state) => state.applyJobReducer);

  useEffect(() => {
    const { data, loading, error, status } = applyJobReducer;

    setLoading(loading);

    if (!loading && data && !error) {
      message.success("Your application has been submitted!");
    }

    if (!loading && !data && error && (status === 409 || status === 400)) {
      message.error(error);
    }
  }, [applyJobReducer]);

  const renderComponent = () => {
    switch (activeTab) {
      case 0:
        return (
          <JobDetails
            data={data}
            setActive={setActiveTab}
            onApply={handleApplyJob}
          />
        );
      case 1:
        return <ApplyJobForm data={data} setActive={setActiveTab} />;
      default:
        return (
          <JobDetails
            data={data}
            setActive={setActiveTab}
            onApply={handleApplyJob}
          />
        );
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <div className="styles--2lk6v">
          <div className="styles--2Kv6p" data-ui="careers-page-content">
            <header className="styles--2MSa5" role="banner">
              <div className="styles--2vxXd">
                <h1 data-ui="job-title">{data?.job?.job_title}</h1>
                <p className="styles--11P0z styles--1HMvu">
                  <span
                    data-ui="job-workplace"
                    className="styles--2TdGW styles--QTMDv"
                  >
                    <strong className="styles--2kqW6">
                      {data?.job?.remote_options ? "Remote" : "On-site"}
                    </strong>
                  </span>
                  <span className="styles--3qswA" data-ui="job-location">
                    {data?.job?.job_location}
                  </span>
                  <span data-ui="job-type">{data?.job?.job_type}</span>
                </p>
              </div>
            </header>

            <div className="styles--2Vt9E">
              <nav className="styles--k_2aS" role="navigation">
                <a
                  href="#overview"
                  className={`styles--1kn9O ${
                    activeTab === 0 ? "styles--active styles--2VVDz" : ""
                  }`}
                  onClick={() => setActiveTab(0)}
                  aria-current={activeTab === 0 ? "page" : null}
                >
                  Overview
                </a>
                {!isCandidateLogin && (
                  <a
                    href="#application"
                    className={`styles--1kn9O ${
                      activeTab === 1 ? "styles--active styles--2VVDz" : ""
                    }`}
                    onClick={() => setActiveTab(1)}
                    aria-current={activeTab === 1 ? "page" : null}
                  >
                    Application
                  </a>
                )}
              </nav>
            </div>
            <main
              className="styles--2d3Fz"
              role="main"
              style={{ marginBottom: "20px" }}
            >
              {renderComponent()}
              <div style={{ paddingBottom: "10%" }} />
            </main>
          </div>
          <ClientFooter data={data?.client} />
        </div>
      )}
    </>
  );
};

const JobDetails = ({ data, setActive, onApply }) => {
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    message.success("URL copied to clipboard");
  };

  const renderSection = (title, content) =>
    content && (
      <section className="styles--3vx-H" aria-labelledby={`${title}-title`}>
        <h2 id={`${title}-title`}>{title}</h2>
        <div>{content}</div>
      </section>
    );

  return (
    <>
      <a
        className="button--2de5X button--14TuV tertiary--1JsWJ styles--2HTwh styles--eqtxl styles--1kn9O"
        onClick={handleCopyUrl}
      >
        Share this job&nbsp;
        <ShareAltOutlined />
      </a>
      {renderSection(
        "Description",
        <>
          {data?.job?.job_description && (
            <p>
              <QuillTextPreview content={data?.job?.job_description} />
            </p>
          )}
        </>
      )}

      {renderSection(
        "Vacancies",
        data?.job?.vacancies && <p>{data.job.vacancies}</p>
      )}
      {renderSection(
        "Experience Required",
        data?.job?.job_experience_range &&
          data.job.job_experience_range.map((item, index) => (
            <p key={index}>
              {index + 1}. {item}
            </p>
          ))
      )}

      {renderSection(
        "Salary",
        data?.job?.salary && (
          <>
            {data.job.salary?.min !== undefined &&
              data.job.salary?.max !== undefined && (
                <>
                  {data.job.salary?.currency && (
                    <>&nbsp;{getCurrecnySymbol(data.job.salary.currency)}</>
                  )}
                  {data.job.salary.min}
                  {data?.job.salary?.unit} -{" "}
                  {data.job.salary?.currency && (
                    <>&nbsp;{getCurrecnySymbol(data.job.salary.currency)}</>
                  )}
                  {data.job.salary.max}
                  {data?.job.salary?.unit}{" "}
                  {data.job.salary?.tenure && (
                    <>&nbsp;({data.job.salary.tenure})</>
                  )}
                </>
              )}
          </>
        )
      )}

      <div className="styles--2z_5t">
        <div className="styles--35QFH">
          <a
            tabIndex={0}
            role="button"
            className="button--2de5X button--14TuV button--FDW79 primary--25RCR main_border_radius"
            data-ui="apply-button"
            onClick={onApply}
          >
            Apply for this job
          </a>
        </div>
        <div className="styles--1E_FM" />
      </div>
    </>
  );
};

export default ApplyJob;
