import { call, delay, put } from "redux-saga/effects";
import {
  candidateLoginCall,
  candidateStatsReportCall,
  getCandidateProfileCall
} from "../../network";
import {
  candidateLoginConstants,
  candidateStatsReportConstants,
  getCandidateDetailsDashboardConstants
} from "../../constants";

export function* candidateLoginSaga(action) {
  try {
    const response = yield call(candidateLoginCall, action.data);
    yield put({
      type: candidateLoginConstants.CANDIDATE_LOGIN_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: candidateLoginConstants.CANDIDATE_LOGIN_RESET });
  } catch (e) {
    yield put({
      type: candidateLoginConstants.CANDIDATE_LOGIN_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: candidateLoginConstants.CANDIDATE_LOGIN_RESET });
  }
}

export function* candidateStatsReportSaga(action) {
  try {
    const response = yield call(candidateStatsReportCall, action.data);
    yield put({
      type: candidateStatsReportConstants.CANDIDATE_STATS_REPORT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: candidateStatsReportConstants.CANDIDATE_STATS_REPORT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: candidateStatsReportConstants.CANDIDATE_STATS_REPORT_RESET });

  }
}

export function* getCandidateDetailsDashboardSaga(action) {
  try {
    const response = yield call(getCandidateProfileCall, action.data);
    yield put({
      type: getCandidateDetailsDashboardConstants.GET_CANDIDATE_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getCandidateDetailsDashboardConstants.GET_CANDIDATE_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: getCandidateDetailsDashboardConstants.GET_CANDIDATE_DETAILS_RESET });

  }
}
