import { ReloadOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Layout,
  message,
  Pagination,
  Table
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getTimeLineReportsAction } from "../../redux/actions/reports&Activity";
import ClientCustomSelect from "../../Utils/ClientSelect/clientSelect";
import { REACT_USER_TYPE } from "../Auth/tokenProvider";
import { UserOutlined } from "@ant-design/icons";
import TimeLineDetails from "./TimelineDetailsModal";
import { isMentorpalTeamUser } from "../../Utils/Common";
import moment from "moment";
import ("../../Assets/css/filter.css");

const { Sider, Content } = Layout;
const { Panel } = Collapse;
const FilterForm = ({ filters, handleFilterChange, handleReset }) => (
  <Form
    layout="vertical"
    onValuesChange={(changedValues) => {
      Object.keys(changedValues).forEach((key) =>
        handleFilterChange(key, changedValues[key])
      );
    }}
    initialValues={filters}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className="d-flex align-items-center">
        <h2 className="m-0 ml-2 mt-3">Filters</h2>
      </span>
      <Button type="" className="cursor-pointer mt-3" onClick={handleReset}>
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3", "4", "5"]}
      expandIconPosition="right"
      style={{ background: "#fff", border: "none" }}
    >
      {isMentorpalTeamUser() && (
        <Panel header="Client" key="2"  style={{ background: "#ffff", border: "none" }}>
          <ClientCustomSelect
            type={"filter"}
            value={filters.client_id}
            onChange={(value) => handleFilterChange("client_id", value)}
          />
        </Panel>
      )}

      <Panel header="Start Date & Time" key="5"  style={{ background: "#ffff", border: "none" }}>
        <DatePicker
          style={{ width: "100%" }}
          value={filters.createdAt ? moment(filters.createdAt) : null}
          onChange={(date, dateString) =>
            handleFilterChange("createdAt", dateString)
          }
          format="YYYY-MM-DD"
          size="large"
        />
      </Panel>
    </Collapse>
  </Form>
);
const TimeLineLogs = () => {
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filters, setFilters] = useState({
    client_id: "",
    createdAt: null,
    user_id: "",
    timeline_type: "EMAIL"
  });

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const [openDrawer, setOpenDrawer] = useState({
    show: false,
    data: {}
  });

  const getTimeLineReportsReducer = useSelector(
    (state) => state.getTimeLineReportsReducer
  );
  useEffect(() => {
    const { loading, data, error } = getTimeLineReportsReducer;
    setloading(loading);
    if (!loading) {
      if (data && !error) {
        setData(data?.data);
        setTotalUsers(data?.totalLogs);
      } else if (error) {
        message.error(error);
      }
    }
  }, [getTimeLineReportsReducer]);

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    setCurrentPage(1);
  };

  useEffect(() => {
    dispatch(
      getTimeLineReportsAction({
        ...filters,
        page: currentPage,
        limit: pageSize
      })
    );
    return () => {
      dispatch({ type: "GET_TIMELINE_REPORTS_RESET" });
    };
  }, [dispatch, pageSize, currentPage, filters]);

  const user_type = useMemo(() => REACT_USER_TYPE(), []);

  const handleRefresh = () => {
    dispatch(
      getTimeLineReportsAction({
        ...filters,
        page: currentPage,
        limit: pageSize
      })
    );
  };

  const tableData = data?.map((item, index) => ({
    key: item._id,
    s_no: index + 1,
    actor: {
      name: item.actor_details.name,
      photo: item.actor_details.photo,
      company_photo: item.actor_details.company_photo,
      company_name: item.actor_details.company_name
    },
    created_at: new Date(item.createdAt).toLocaleString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit"
    }),
    timeline_type: item.timeline_type,
    timeline_data: item.timeline_data
  }));

  const columns = [
    {
      title: "S. No",
      dataIndex: "s_no",
      key: "s_no",
      width: 100
    },
    {
      title: "Actor",
      dataIndex: "actor",
      key: "actor",
      render: (actor) => (
        <div
          className="d-flex"
          style={{ display: "flex", alignItems: "center", maxWidth: 150 }}
        >
          <Avatar
            src={
              actor?.photo
                ? `${process.env.REACT_APP_IMAGES_BASE_URL}${actor?.photo}`
                : undefined
            }
            icon={!actor?.photo ? <UserOutlined /> : null}
            alt={actor?.name || "User"}
            style={{ marginRight: 8 }}
          />
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%"
            }}
          >
            {actor.name}
          </div>
        </div>
      )
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: 250,
      ellipsis: true
    },
    {
      title: "Timeline Type",
      dataIndex: "timeline_type",
      key: "timeline_type",
      ellipsis: true,
      width: 150
    },
    {
      title: "Client",
      dataIndex: "actor",
      key: "actor",
      render: (actor) => (
        <div style={{ display: "flex", alignItems: "center", maxWidth: 150 }}>
          <Avatar
            src={
              actor?.company_photo
                ? `${process.env.REACT_APP_IMAGES_BASE_URL}${actor?.company_photo}`
                : undefined
            }
            icon={!actor?.company_photo ? <UserOutlined /> : null}
            alt={actor?.company_name || "User"}
            style={{ marginRight: 8 }}
          />
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%"
            }}
          >
            {actor.company_name}
          </div>
        </div>
      )
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Button
          type="primary"
          style={{ backgroundColor: "#00756a" }}
          onClick={() => {
            setOpenDrawer((prev) => ({
              ...prev,
              show: true,
              data: record
            }));
          }}
        >
          View Details
        </Button>
      )
    }
  ];

  const handleReset = () => {
    setFilters({
      client_id: "",
      createdAt:null,
      user_id: "",
      timeline_type: "EMAIL"
    });
  };

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <div className="main-header" data-ui="header">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                  TimeLine Logs
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Layout style={{ background: "#fff", height: "100vh" }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background bg-light">
            <div className="px-3 bg-light" style={{ height: "100vh" }}>
              <FilterForm
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleReset={handleReset}
                handleChange={handleChange}
              />
            </div>
          </Sider>
        )}
        <Layout>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <Content className="px-4">
              <div className="cl-section-header row justify-content-between align-items-end py-3">
                <div className="col d-flex align-items-center"></div>
                <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
                  <Button
                    className="ms-2"
                    onClick={handleRefresh}
                    icon={<ReloadOutlined />}
                    type="primary"
                    style={{ backgroundColor: "#00756a" }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
              <Table
                bordered
                columns={columns}
                dataSource={tableData}
                pagination={false}
                rowKey="user_id"
                scroll={{
                  x: 1300
                }}
              />
              <Pagination
                className="text-center align-content-center mt-3"
                total={totalUsers}
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger
                pageSizeOptions={[5, 10, 20, 50, 100]}
                showTotal={(total) => `Total ${total} logs`}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                }}
              />
            </Content>
          )}
        </Layout>
      </Layout>
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="100%"
        className="filter-drawer"
        bodyStyle={{ paddingTop: 0 }} 
      >
        <FilterForm
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleReset={handleReset}
          handleChange={handleChange} // Pass handleChange here
        />
      </Drawer>

      {openDrawer.show && (
        <TimeLineDetails visible={openDrawer} setVisible={setOpenDrawer} />
      )}
    </>
  );
};

export default TimeLineLogs;
