import { Avatar, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../../../Assets/img/y3awvixq.png";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { UserOutlined } from "@ant-design/icons";

const { Header } = Layout;
const AssessmentCandidateMain = () => {
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(false);

  const getInviteAssessmentDetailsReducer = useSelector(
    (state) => state.getInviteAssessmentDetailsReducer
  );

  useEffect(() => {
    const { loading, data, error } = getInviteAssessmentDetailsReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        setClient(data?.client);
      } else if (error) {
        console.warn("Error:", error);
        message.error(error);      }
    }
  }, [getInviteAssessmentDetailsReducer]);
  return (
    <>
      {loading ? (
        <div className="loading-spinner">
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <Header className="main-header-test">
            <a className="main-header-test__logo-link">
              <Avatar
                size="large"
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "contain"
                }}
                icon={<UserOutlined />}
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}${client?.company_photo}`}
              />
              <h3 className="main-header-test__title">
                {client?.company_name}
              </h3>
            </a>
            <div className="main-header-test__test-info"></div>
          </Header>
          <Outlet />
        </>
      )}
    </>
  );
};

export default AssessmentCandidateMain;
