import {
  candidateLoginConstants,
  candidateStatsReportConstants,
  getCandidateDetailsDashboardConstants
} from "../../constants";

export function candidateLoginAction(data) {
  return {
    type: candidateLoginConstants.CANDIDATE_LOGIN_REQUEST,
    data
  };
}
export function candidateStatsReportAction(data) {
  return {
    type: candidateStatsReportConstants.CANDIDATE_STATS_REPORT_REQUEST,
    data
  };
}
export function getCandidateDetailsDashboardAction(data) {
  return {
    type: getCandidateDetailsDashboardConstants.GET_CANDIDATE_DETAILS_REQUEST,
    data
  };
}
