import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getAccessToken } from "./Components/Auth/tokenProvider";

let API_BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
let API_LOCAL_URL = `${process.env.REACT_APP_BASE_URL_2}`;
const headers2 = {
  "x-app-name": "mentorpal",
  "x-device-id": "deviceid1",
  "x-platform-id": "web",
  "x-correlation-id": uuidv4(),
  "x-app-version": "1.0",
  "x-test-otp": 12345,
  "Cache-Control": "no-cache",
  "Content-Type": "application/json"
};

const api = axios.create({
  baseURL: API_LOCAL_URL,
  headers: {
    "x-app-name": "mentorpal",
    "x-device-id": "deviceid1",
    "x-platform-id": "web",
    "x-correlation-id": uuidv4(),
    "x-app-version": "1.0",
    "x-test-otp": 12345,
    "Cache-Control": "no-cache",
    "Content-Type": "application/json"
  }
});
api.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
export async function uploadImageReq(data) {
  return await api
    .post(`${API_BASE_URL}platform-service/create/presigned/url/`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function uploadImageFileReq(data) {
  headers2["Content-Type"] = data?.contentType;
  return await axios
    .put(data.url, data.fileData, {
      headers: headers2
    })
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function resumeParserCall(data) {
  return await api
    .post(`${API_BASE_URL}third-party/v1/resume-parser`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function matchingAlgoCall(data) {
  return await api
    .post(`${API_BASE_URL}third-party/v1/matching-alog-2`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function assessmentLoginCall(data) {
  return await api
    .post(`${API_LOCAL_URL}assessment/assessment-login`, data)

    .then((res) => {
      localStorage.setItem("REACT_TOKEN_AUTH", res.data?.token);
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function createJobReq(data) {
  return await api
    .post(`${API_LOCAL_URL}admin/create-job`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getJobReq(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}admin/get-job?${params.toString()}`)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getJobApplicationCall(data) {
  return await api
    .get(
      `${API_LOCAL_URL}candidate/get-application-details?apply_id=${data?.apply_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function getJobDetailsCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}admin/job-details?${params.toString()}`)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getJobAllAppliedCandidateCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}admin/job-applied-candidate?${params.toString()}`)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function applyJobCall(data) {
  return await api
    .post(`${API_LOCAL_URL}candidate/apply-job`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function addClientCall(data) {
  return await api
    .post(`${API_LOCAL_URL}client/add-client`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function getClientListCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );

  return await api
    .get(`${API_LOCAL_URL}client/get-client-list?${params.toString()}`)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getClientDetailsCall(data) {
  return await api
    .get(`${API_LOCAL_URL}client/get-client-details?client_id=${data}`)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getClientJobCall(data) {
  return await api
    .get(`${API_LOCAL_URL}client/get-client-jobs?client_id=${data}`)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getAllCandidateCall(data) {
  return await api
    .get(
      `${API_LOCAL_URL}candidate/get-all-candidate?firstName=${data?.firstName}&page=${data?.page}&pageSize=${data?.pageSize}&sort=${data?.sort}`
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateClientDetailsCall(data) {
  return await api
    .put(`${API_LOCAL_URL}client/update-client`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function updateCandidateCall(data) {
  return await api
    .put(`${API_LOCAL_URL}candidate/update-candidate`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function updateJobDetailsCall(data) {
  return await api
    .put(
      `${API_LOCAL_URL}admin/update-job-details?job_id=${data?.job_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getCandidateProfileCall(data) {
  return await api
    .get(`${API_LOCAL_URL}candidate/get-candidate-details?candidate_id=${data}`)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function loginUserCall(data) {
  return await api.post(`${API_LOCAL_URL}auth/login`, data).then((res) => {
    localStorage.setItem("REACT_TOKEN_AUTH", res.data?.token);

    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  });
}
export async function singupUserCall(data) {
  return await api.post(`${API_LOCAL_URL}auth/signup`, data).then((res) => {
    localStorage.setItem("REACT_TOKEN_AUTH", res.data?.token);

    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  });
}
export async function addCandidateCall(data) {
  return await api
    .post(`${API_LOCAL_URL}candidate/add-candidate`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getUserDetailCall(data) {
  return await api
    .get(`${API_LOCAL_URL}auth/get-user-details?user_id=${data?.user_id}`)
    .then((res) => {
      localStorage.setItem("REACT_USER_PHOTO", res.data?.photo);
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getUserStatsCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}auth/get-user-stats?${params.toString()}`)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getSubUserDetailsCall(data) {
  return await api
    .get(`${API_LOCAL_URL}auth/get-user-details?user_id=${data?.user_id}`)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateUserDetailCall(data) {
  return await api
    .put(
      `${API_LOCAL_URL}auth/update-user-details?user_id=${data?.user_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateJobApplicationCall(data) {
  return await api
    .put(
      `${API_LOCAL_URL}candidate/update-applied-job?apply_id=${data?.apply_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function createUserCall(data) {
  return await api
    .post(`${API_LOCAL_URL}user-management/master-admin/create-user`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getUserCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(
      `${API_LOCAL_URL}user-management/master-admin/get-all-users?${params.toString()}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function postFeedbackCall(data) {
  return await api.post(`${API_LOCAL_URL}public/feedback`, data).then((res) => {
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  });
}
export async function getInterviewerListCall(data) {
  return await api
    .get(
      `${API_LOCAL_URL}interview/get-interviewer-list?api_type=${data?.api_type}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getInterviewDetailsCall(data) {
  return await api
    .get(
      `${API_LOCAL_URL}interview/schedule-interview-details?interview_id=${data?.interview_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getAllScheduleInterviewListCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(
      `${API_LOCAL_URL}interview/all-schedule-interview?${params.toString()}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function scheduleInterviewCall(data) {
  return await api
    .post(`${API_LOCAL_URL}interview/schedule-interview`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function interviewFeedbackCall(data) {
  return await api
    .put(
      `${API_LOCAL_URL}interview/feedback?interview_id=${data?.interview_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function updatePasswordCall(data) {
  return await api
    .put(`${API_LOCAL_URL}auth/update-password`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function logoutUserCall(data) {
  return await api.delete(`${API_LOCAL_URL}auth/logout`, data).then((res) => {
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  });
}
export async function forgetPasswordLinkCall(data) {
  return await api
    .post(`${API_LOCAL_URL}auth/forget-password-link`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function enquiryCall(data) {
  return await api.post(`${API_LOCAL_URL}public/enquiry`, data).then((res) => {
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  });
}
export async function getInterviewerReportCall(data) {
  return await api
    .get(`${API_LOCAL_URL}interview/get-interviewer-report`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getInterviewCalendarCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}interview/get-calenders?${params.toString()}`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateInterviewDetailsCall(data) {
  return await api
    .put(
      `${API_LOCAL_URL}interview/update-interview-details?interview_id=${data?.interview_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getEnquiryCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}public/enquiry-list?${params.toString()}`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateEnquiryCall(data) {
  return await api
    .put(
      `${API_LOCAL_URL}public/enquiry-update?enquiry_id=${data?.enquiry_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function sendOtpCall(data) {
  return await api
    .post(`${API_LOCAL_URL}verification/send-otp`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function verifyOtpCall(data) {
  return await api
    .post(`${API_LOCAL_URL}verification/otp-verify`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function verifyEmailCall(data) {
  return await api
    .post(
      `${API_LOCAL_URL}verification/verify-email?email=${data?.email}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getClientReportCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}admin/get-admin-report?${params.toString()}`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function getTeamReportCall(data) {
  return await api
    .get(
      `${API_LOCAL_URL}admin/get-team-report?user_id=${data?.user_id}&startDate=${data?.startDate}&endDate=${data?.endDate}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function createQuestionCall(data) {
  return await api
    .post(`${API_LOCAL_URL}assessment/add-question`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateQuestionCall(data) {
  return await api
    .put(
      `${API_LOCAL_URL}assessment/update-question?question_id=${data?.question_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getQuestionDetailsCall(data) {
  return await api
    .get(
      `${API_LOCAL_URL}assessment/get-question-details?question_id=${data?.question_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getAllQuestionCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );

  try {
    const res = await api.get(
      `${API_LOCAL_URL}assessment/get-all-question?${params.toString()}`,
      data
    );
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
}
export async function createTestCall(data) {
  return await api
    .post(`${API_LOCAL_URL}assessment/add-test`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateTestCall(data) {
  return await api
    .put(
      `${API_LOCAL_URL}assessment/update-test?test_id=${data?.test_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getTestDetailsCall(data) {
  return await api
    .get(
      `${API_LOCAL_URL}assessment/get-test-details?test_id=${data?.test_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getAllTestCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}assessment/get-all-test?${params.toString()}`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function createAssessmentCall(data) {
  return await api
    .post(`${API_LOCAL_URL}assessment/add-assessment`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateAssessmentCall(data) {
  return await api
    .put(`${API_LOCAL_URL}assessment/update-assessment`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getAssessmentDetailsCall(data) {
  return await api
    .get(
      `${API_LOCAL_URL}assessment/get-assessment-details?assessment_id=${data?.assessment_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function getAllAssessmentCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );

  try {
    const res = await api.get(
      `${API_LOCAL_URL}assessment/get-all-assessment?${params.toString()}`,
      data
    );
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
}
export async function getInviteAssessmentDetailsCall(data) {
  try {
    const res = await api.get(
      `${API_LOCAL_URL}assessment/get-invite-assessment-details?assessment_invite_id=${data?.assessment_invite_id}`,
      data
    );
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
}
export async function getInvitationDetailsCall(data) {
  try {
    const res = await api.get(
      `${API_LOCAL_URL}assessment/get-invite-assessment-details?assessment_invite_id=${data?.assessment_invite_id}`,
      data
    );
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
}
export async function updateInviteAssessmentDetailsCall(data) {
  try {
    const res = await api.put(
      `${API_LOCAL_URL}assessment/update-invite-assessment-details?assessment_invite_id=${data?.assessment_invite_id}`,
      data
    );
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
}

export async function getAllAssessmentCandidateCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );

  try {
    const res = await api.get(
      `${API_LOCAL_URL}assessment/get-assessment-candidate?${params.toString()}`,
      data
    );
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
}
export async function getAssessmentCandidateDetailsCall(data) {
  try {
    const res = await api.get(
      `${API_LOCAL_URL}assessment/get-assessment-candidate-details?assessment_invite_id=${data?.assessment_invite_id}`,
      data
    );
    return {
      data: res.data,
      status: res.status,
      headers: res.headers,
      message: res.message
    };
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
}

export async function inviteAssessmentCall(data) {
  return await api
    .post(`${API_LOCAL_URL}assessment/invite-assessment`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function candidateDetailsCall(data) {
  return await api
    .get(
      `${API_LOCAL_URL}assessment/get-assessment-candidate-details?assessment_invite_id=${data?.assessment_invite_id}`
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function candidateAssessmentSubmissionCall(data) {
  return await api
    .post(`${API_LOCAL_URL}assessment/assessment-submission`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function candidateAssessmentFeedbackCall(data) {
  return await api
    .post(
      `${API_LOCAL_URL}assessment/assessment-feedback?assessment_invite_id=${data?.assessment_invite_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function assessmentImageProctorCall(data) {
  return await api
    .post(`${API_LOCAL_URL}assessment/assessment-image-proctor`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function candidateLoginCall(data) {
  return await api
    .post(`${API_LOCAL_URL}candidate/candidate-login`, data)
    .then((res) => {
      if (
        res.data?.token &&
        res.data?.user_id &&
        res.data?.email &&
        res.data?.user_type
      ) {
        localStorage.setItem("REACT_TOKEN_AUTH", res.data?.token);
      }
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function candidateStatsReportCall(data) {
  return await api
    .get(`${API_LOCAL_URL}candidate/candidate-stats-report`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function multipleActionCall(data) {
  return await api
    .post(`${API_LOCAL_URL}action/multiple-action`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function createClientRoleUserCall(data) {
  return await api
    .post(`${API_LOCAL_URL}user-management/client-admin/create-user`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getAllClientRoleUserCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(
      `${API_LOCAL_URL}user-management/client-admin/get-all-users?${params.toString()}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getFeedbackListCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}public/feedback-list?${params.toString()}`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getTimeLineReportsCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(`${API_LOCAL_URL}admin/timeline-reports?${params.toString()}`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function getEmailTemplatesCall(data) {
  const params = new URLSearchParams(
    Object.entries(data).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
  return await api
    .get(
      `${API_LOCAL_URL}admin/get-all-email-templates?${params.toString()}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateEmailTemplatesCall(data) {
  return await api
    .put(`${API_LOCAL_URL}admin/update-email-template`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function updateFeedbackCall(data) {
  return await api
    .put(
      `${API_LOCAL_URL}public/feedback-update?feedback_id=${data?.feedback_id}`,
      data
    )
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
export async function assignToRecruiterCall(data) {
  return await api
    .post(`${API_LOCAL_URL}admin/create-assign`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}

export async function emailSubscribeCall(data) {
  return await api
    .post(`${API_LOCAL_URL}public/email-subscribe`, data)
    .then((res) => {
      return {
        data: res.data,
        status: res.status,
        headers: res.headers,
        message: res.message
      };
    });
}
