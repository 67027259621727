import { All_ROLES, MENTORPAL_OWNER_ROLES } from "./common";

export const experienced = [
  { label: "Less than 1 year", value: "Less than 1 year" },
  { label: "1-2 years", value: "1-2 years" },
  { label: "3-5 years", value: "3-5 years" },
  { label: "6-10 years", value: "6-10 years" },
  { label: "10+ years", value: "10+ years" }
];
export const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "non_binary", label: "Non-binary" },
  { value: "prefer_not_to_say", label: "Prefer not to say" }
];
export const jobExperienceRanges = [
  { label: "Less than 1 year", value: "Less than 1 year" },
  { label: "1-2 years", value: "1-2 years" },
  { label: "3-5 years", value: "3-5 years" },
  { label: "6-10 years", value: "6-10 years" },
  { label: "10+ years", value: "10+ years" }
];

export const ethnicityOptions = [
  { value: "asian", label: "Asian" },
  { value: "black", label: "Black or African American" },
  { value: "hispanic_latino", label: "Hispanic or Latino" },
  { value: "white", label: "White or Caucasian" },
  { value: "native_american", label: "Native American or Alaska Native" },
  { value: "middle_eastern", label: "Middle Eastern or North African" },
  {
    value: "pacific_islander",
    label: "Native Hawaiian or Other Pacific Islander"
  },
  { value: "mixed", label: "Mixed or Multi-Ethnic" },
  { value: "other", label: "Other" },
  { value: "prefer_not_to_say", label: "Prefer not to say" }
];
export const job_type = [
  { label: "Full-time", value: "Full-time" },
  { label: "Part-time", value: "Part-time" },
  { label: "Contract", value: "Contract" },
  { label: "Temporary", value: "Temporary" },
  { label: "Internship", value: "Internship" },
  { label: "Freelance", value: "Freelance" }
  // Add more options as needed
];
export const USER_TYPE = [
  { label: "Interviewer", value: "interviewer" },
  { label: "Admin", value: "mentorpal-admin" },
  { label: "Assessment Master", value: "assessment-master" },
  { label: "Candidate Dashboard", value: "candidate-dashboard" },
  { label: "Master Administrator", value: "master_administrator" }
  // Add more options as needed
];

export const salary_tenure = [
  { label: "Per Annum", value: "Per Annum" },
  { label: "Per Month", value: "Per Month" },
  { label: "Per Hour", value: "Per Hour" },
  { label: "Per Week", value: "Per Week" },
  { label: "Per Day", value: "Per Day" },
  { label: "Per Assignment", value: "Per Assignment" },
  { label: "Per Milestone", value: "Per Milestone" }
  // Add more options as needed
];
export const education_level = [
  { label: "High School", value: "High School" },
  { label: "Bachelor's Degree", value: "Bachelor's Degree" },
  { label: "Master's Degree", value: "Master's Degree" },
  { label: "Ph.D.", value: "Ph.D." },
  { label: "Associate Degree", value: "Associate Degree" },
  { label: "Diploma", value: "Diploma" },
  {
    label: "Professional Certification",
    value: "Professional Certification"
  },
  { label: "Vocational Training", value: "Vocational Training" },
  {
    label: "Postgraduate Certificate",
    value: "Postgraduate Certificate"
  }
  // Add more options as needed
];
export const Currency = [
  { label: "INR - Indian Rupee", value: "INR", symbol: "₹" },
  { label: "USD - United States Dollar", value: "USD", symbol: "$" },
  { label: "EUR - Euro", value: "EUR", symbol: "€" },
  { label: "GBP - British Pound Sterling", value: "GBP", symbol: "£" },
  { label: "JPY - Japanese Yen", value: "JPY", symbol: "¥" },
  { label: "AUD - Australian Dollar", value: "AUD", symbol: "A$" },
  { label: "CAD - Canadian Dollar", value: "CAD", symbol: "C$" },
  { label: "CHF - Swiss Franc", value: "CHF", symbol: "CHF" },
  { label: "CNY - Chinese Yuan", value: "CNY", symbol: "¥" },
  { label: "SGD - Singapore Dollar", value: "SGD", symbol: "S$" },
  { label: "NZD - New Zealand Dollar", value: "NZD", symbol: "NZ$" },
  { label: "HKD - Hong Kong Dollar", value: "HKD", symbol: "HK$" },
  { label: "SEK - Swedish Krona", value: "SEK", symbol: "kr" },
  { label: "KRW - South Korean Won", value: "KRW", symbol: "₩" },
  { label: "MXN - Mexican Peso", value: "MXN", symbol: "MX$" },
  { label: "NOK - Norwegian Krone", value: "NOK", symbol: "kr" },
  { label: "RUB - Russian Ruble", value: "RUB", symbol: "₽" },
  { label: "ZAR - South African Rand", value: "ZAR", symbol: "R" }
  // Add more currencies as needed
];

export const Skills = [
  { label: "JavaScript", value: "JavaScript" },
  { label: "HTML", value: "HTML" },
  { label: "CSS", value: "CSS" },
  { label: "React.js", value: "React.js" },
  { label: "Node.js", value: "Node.js" },
  { label: "Express.js", value: "Express.js" },
  { label: "Angular", value: "Angular" },
  { label: "Vue.js", value: "Vue.js" },
  { label: "Python", value: "Python" },
  { label: "Java", value: "Java" },
  { label: "C++", value: "C++" },
  { label: "C#", value: "C#" },
  { label: "PHP", value: "PHP" },
  { label: "Ruby", value: "Ruby" },
  { label: "Swift", value: "Swift" },
  { label: "Kotlin", value: "Kotlin" },
  { label: "TypeScript", value: "TypeScript" },
  { label: "SQL", value: "SQL" },
  { label: "MongoDB", value: "MongoDB" },
  { label: "Firebase", value: "Firebase" },
  { label: "Django", value: "Django" },
  { label: "Flask", value: "Flask" },
  { label: "Spring", value: "Spring" },
  { label: "ASP.NET", value: "ASP.NET" },
  { label: "Ruby on Rails", value: "Ruby on Rails" },
  { label: "RESTful APIs", value: "RESTful APIs" },
  { label: "GraphQL", value: "GraphQL" },
  { label: "AWS", value: "AWS" },
  { label: "Azure", value: "Azure" },
  { label: "Google Cloud Platform", value: "Google Cloud Platform" },
  { label: "Docker", value: "Docker" },
  { label: "Kubernetes", value: "Kubernetes" },
  { label: "Git", value: "Git" },
  { label: "CI/CD", value: "CI/CD" },
  { label: "Agile Methodologies", value: "Agile Methodologies" },
  { label: "Scrum", value: "Scrum" },
  { label: "Kanban", value: "Kanban" },
  { label: "Jenkins", value: "Jenkins" },
  { label: "Bash/Shell Scripting", value: "Bash/Shell Scripting" },
  { label: "Linux", value: "Linux" },
  { label: "Windows Server", value: "Windows Server" },
  { label: "Networking", value: "Networking" },
  { label: "Data Structures", value: "Data Structures" },
  { label: "Algorithms", value: "Algorithms" },
  { label: "Machine Learning", value: "Machine Learning" },
  { label: "Artificial Intelligence", value: "Artificial Intelligence" },
  { label: "Computer Vision", value: "Computer Vision" },
  {
    label: "Natural Language Processing",
    value: "Natural Language Processing"
  },
  { label: "Big Data", value: "Big Data" },
  { label: "Data Analytics", value: "Data Analytics" },
  { label: "Data Engineering", value: "Data Engineering" },
  { label: "Data Warehousing", value: "Data Warehousing" },
  { label: "Data Mining", value: "Data Mining" },
  { label: "ETL", value: "ETL" },
  { label: "Microservices", value: "Microservices" },
  { label: "Serverless Architecture", value: "Serverless Architecture" },
  { label: "Container Orchestration", value: "Container Orchestration" },
  { label: "Web Security", value: "Web Security" },
  { label: "Penetration Testing", value: "Penetration Testing" },
  { label: "Ethical Hacking", value: "Ethical Hacking" },
  { label: "Cybersecurity", value: "Cybersecurity" },
  { label: "Blockchain", value: "Blockchain" },
  { label: "Graphic Design", value: "Graphic Design" },
  { label: "UI/UX Design", value: "UI/UX Design" },
  { label: "Illustration", value: "Illustration" },
  { label: "Animation", value: "Animation" },
  { label: "Video Editing", value: "Video Editing" },
  { label: "3D Modeling", value: "3D Modeling" },
  { label: "Motion Graphics", value: "Motion Graphics" },
  { label: "Photography", value: "Photography" },
  { label: "Typography", value: "Typography" },
  { label: "Color Theory", value: "Color Theory" },
  { label: "Print Design", value: "Print Design" },
  { label: "Brand Identity", value: "Brand Identity" },
  { label: "User Research", value: "User Research" },
  { label: "Wireframing", value: "Wireframing" },
  { label: "Prototyping", value: "Prototyping" },
  { label: "User Testing", value: "User Testing" },
  { label: "Information Architecture", value: "Information Architecture" },
  { label: "Accessibility", value: "Accessibility" },
  { label: "Responsive Design", value: "Responsive Design" },
  { label: "Frontend Development", value: "Frontend Development" },
  { label: "Backend Development", value: "Backend Development" },
  { label: "Full Stack Development", value: "Full Stack Development" },
  { label: "Mobile App Development", value: "Mobile App Development" },
  { label: "Cross-platform Development", value: "Cross-platform Development" },
  { label: "AR/VR Development", value: "AR/VR Development" },
  { label: "Game Development", value: "Game Development" },
  { label: "UI Frameworks", value: "UI Frameworks" },
  { label: "Version Control", value: "Version Control" },
  { label: "Code Review", value: "Code Review" },
  { label: "Debugging", value: "Debugging" },
  { label: "Software Architecture", value: "Software Architecture" },
  { label: "API Design", value: "API Design" },
  { label: "Database Design", value: "Database Design" },
  { label: "Server Administration", value: "Server Administration" },
  { label: "Cloud Computing", value: "Cloud Computing" },
  { label: "Deployment", value: "Deployment" },
  { label: "DevOps", value: "DevOps" },
  { label: "Continuous Integration", value: "Continuous Integration" },
  { label: "Continuous Deployment", value: "Continuous Deployment" },
  { label: "Agile Development", value: "Agile Development" },
  { label: "Scrum", value: "Scrum" },
  { label: "Kanban", value: "Kanban" },
  { label: "Lean Methodology", value: "Lean Methodology" },
  { label: "Project Management", value: "Project Management" },
  { label: "Leadership", value: "Leadership" },
  { label: "Communication", value: "Communication" },
  { label: "Problem Solving", value: "Problem Solving" },
  { label: "Critical Thinking", value: "Critical Thinking" },
  { label: "Time Management", value: "Time Management" },
  { label: "Teamwork", value: "Teamwork" },
  { label: "Client Management", value: "Client Management" },
  { label: "Presentation Skills", value: "Presentation Skills" },
  { label: "Negotiation", value: "Negotiation" }
  // Add more skills as needed
];

export const other_requirements = [
  {
    label: "Proven Work Experience",
    value: "Proven work experience as a software developer or similar role"
  },
  {
    label: "Proficiency in Programming Languages",
    value:
      "Strong proficiency in one or more programming languages (e.g., Java, Python, JavaScript, C++)"
  },
  {
    label: "Experience with Frameworks and Libraries",
    value: "Experience with software development frameworks and libraries"
  },
  {
    label: "Understanding of SDLC",
    value: "Understanding of software development lifecycle and best practices"
  },
  {
    label: "Knowledge of Databases",
    value: "Knowledge of databases and data structures"
  },
  {
    label: "Experience with Version Control",
    value: "Experience with version control systems (e.g., Git)"
  },
  {
    label: "Problem-Solving Skills",
    value: "Excellent problem-solving and analytical skills"
  },
  {
    label: "Detail-Oriented Mindset",
    value:
      "Detail-oriented mindset with a commitment to delivering high-quality work"
  },
  {
    label: "Ability to Work Independently and in a Team",
    value: "Ability to work independently and in a team environment"
  },
  {
    label: "Communication and Collaboration Skills",
    value: "Effective communication and collaboration skills"
  },
  {
    label: "Education",
    value:
      "Bachelor's degree in Computer Science or relevant field is preferred, but not required"
  },
  {
    label: "Problem-Solving Skills",
    value:
      "Strong problem-solving skills with the ability to think creatively and analytically"
  },
  {
    label: "Continuous Learning",
    value:
      "Commitment to continuous learning and staying updated with the latest technologies and trends"
  },
  {
    label: "Quality Assurance",
    value:
      "Understanding of software quality assurance principles and practices"
  },
  {
    label: "Documentation",
    value: "Ability to write clear and concise technical documentation"
  },
  {
    label: "Testing",
    value: "Experience with software testing methodologies and tools"
  },
  {
    label: "Time Management",
    value:
      "Strong time management and organizational skills to prioritize tasks effectively"
  },
  {
    label: "Attention to Detail",
    value:
      "Keen attention to detail to ensure accuracy and precision in coding and development tasks"
  },
  {
    label: "Adaptability",
    value:
      "Flexibility and adaptability to work in a fast-paced and dynamic environment"
  },
  {
    label: "Team Player",
    value: "Ability to collaborate effectively with cross-functional teams"
  },
  {
    label: "Client Interaction",
    value: "Experience in client interaction and requirement gathering"
  },
  {
    label: "Version Control",
    value: "Experience with version control systems like Git"
  },
  {
    label: "Debugging",
    value: "Proficiency in debugging and troubleshooting code issues"
  },
  {
    label: "Security Awareness",
    value: "Awareness of security best practices and potential vulnerabilities"
  },
  {
    label: "Performance Optimization",
    value: "Ability to optimize application performance and resource usage"
  },
  {
    label: "Agile Methodologies",
    value:
      "Familiarity with Agile development methodologies like Scrum or Kanban"
  },
  {
    label: "Cross-Platform Development",
    value:
      "Experience in cross-platform development for web, mobile, and desktop applications"
  },
  {
    label: "User Experience (UX) Design",
    value:
      "Understanding of UX principles and ability to create user-friendly interfaces"
  },
  {
    label: "Software Architecture",
    value: "Knowledge of software architecture patterns and design principles"
  },
  {
    label: "Continuous Integration/Continuous Deployment (CI/CD)",
    value:
      "Experience with CI/CD pipelines for automated testing and deployment"
  },
  {
    label: "Customer Focus",
    value:
      "Ability to understand and prioritize customer needs and requirements"
  }
  // Add more options as needed
];
export const Responsibilities = [
  {
    label: "Perform assigned tasks efficiently and effectively",
    value: "Perform assigned tasks efficiently and effectively"
  },
  {
    label: "Communicate effectively with team members and stakeholders",
    value: "Communicate effectively with team members and stakeholders"
  },
  {
    label: "Adapt to changing priorities and requirements",
    value: "Adapt to changing priorities and requirements"
  },
  {
    label: "Follow company policies and procedures",
    value: "Follow company policies and procedures"
  },
  {
    label: "Collaborate with others to achieve team goals",
    value: "Collaborate with others to achieve team goals"
  },
  {
    label: "Continuously improve skills and knowledge",
    value: "Continuously improve skills and knowledge"
  },
  {
    label: "Maintain a positive attitude and professionalism",
    value: "Maintain a positive attitude and professionalism"
  },
  {
    label: "Take ownership of responsibilities and outcomes",
    value: "Take ownership of responsibilities and outcomes"
  },
  {
    label: "Meet deadlines consistently",
    value: "Meet deadlines consistently"
  },
  {
    label: "Provide support to team members when needed",
    value: "Provide support to team members when needed"
  },
  {
    label: "Attend and actively participate in team meetings",
    value: "Attend and actively participate in team meetings"
  },
  {
    label: "Document work processes and procedures",
    value: "Document work processes and procedures"
  },
  {
    label: "Seek feedback for continuous improvement",
    value: "Seek feedback for continuous improvement"
  },
  {
    label: "Handle confidential information with discretion",
    value: "Handle confidential information with discretion"
  },
  {
    label: "Identify and resolve issues in a timely manner",
    value: "Identify and resolve issues in a timely manner"
  },
  {
    label: "Stay updated on industry trends and best practices",
    value: "Stay updated on industry trends and best practices"
  },
  {
    label: "Contribute to team brainstorming sessions",
    value: "Contribute to team brainstorming sessions"
  },
  {
    label: "Provide constructive feedback to peers",
    value: "Provide constructive feedback to peers"
  },
  {
    label: "Assist in onboarding new team members",
    value: "Assist in onboarding new team members"
  },
  {
    label: "Adhere to industry regulations and compliance standards",
    value: "Adhere to industry regulations and compliance standards"
  },
  {
    label: "Participate in professional development opportunities",
    value: "Participate in professional development opportunities"
  },
  {
    label: "Conduct research to support project objectives",
    value: "Conduct research to support project objectives"
  },
  {
    label: "Maintain accurate and organized project documentation",
    value: "Maintain accurate and organized project documentation"
  },
  {
    label: "Proactively identify and propose solutions to challenges",
    value: "Proactively identify and propose solutions to challenges"
  }

  // Add more options as needed
];
export const SalaryRanges = [
  { label: "Negotiable", value: "Negotiable" },
  { label: "Less than 1 Lakh", value: "< 1 Lakh" },
  { label: "1 Lakh - 5 Lakhs", value: "1 Lakh - 5 Lakhs" },
  { label: "5 Lakhs - 10 Lakhs", value: "5 Lakhs - 10 Lakhs" },
  { label: "10 Lakhs - 20 Lakhs", value: "10 Lakhs - 20 Lakhs" },
  { label: "20 Lakhs - 30 Lakhs", value: "20 Lakhs - 30 Lakhs" },
  { label: "30 Lakhs - 50 Lakhs", value: "30 Lakhs - 50 Lakhs" },
  { label: "50 Lakhs - 1 Crore", value: "50 Lakhs - 1 Crore" },
  { label: "1 Crore - 2 Crores", value: "1 Crore - 2 Crores" },
  { label: "2 Crores - 5 Crores", value: "2 Crores - 5 Crores" },
  { label: "Above 5 Crores", value: "Above 5 Crores" },
  { label: "Above 10 Lakhs", value: "Above 10 Lakhs" },
  { label: "Above 20 Lakhs", value: "Above 20 Lakhs" },
  { label: "Above 30 Lakhs", value: "Above 30 Lakhs" },
  { label: "Above 50 Lakhs", value: "Above 50 Lakhs" },
  { label: "Above 1 Crore", value: "Above 1 Crore" },
  { label: "Above 2 Crores", value: "Above 2 Crores" },
  { label: "Above 3 Crores", value: "Above 3 Crores" },
  { label: "Above 4 Crores", value: "Above 4 Crores" },
  { label: "Above 10 Crores", value: "Above 10 Crores" }
];

export const CREATE_USER_STEPS = [
  {
    title: "User Info",
    fields: [
      {
        name: "first_name",
        label: "First Name",
        type: "text",
        rules: [{ required: true, message: "Please enter First Name" }]
      },
      {
        name: "last_name",
        label: "Last Name",
        type: "text",
        rules: [{ required: true, message: "Please enter Last Name" }]
      },
      {
        name: "email",
        label: "Email",
        type: "text",
        rules: [{ required: true, message: "Please enter email" }]
      },
      {
        name: "user_type",
        label: "Role",
        type: "select",
        mode: "single",
        rules: [
          {
            required: true,
            message: "Please select the role!"
          }
        ],
        options: MENTORPAL_OWNER_ROLES
      },
      {
        name: "email_send",
        label: "Do you want to send email notification?",
        type: "checkbox",
        options: MENTORPAL_OWNER_ROLES
      }
    ]
  }
];

export const APPLICATION_STAGE = [
  { label: "All", key: "candidatesCount" },
  { label: "Disqualified", key: "disqualified" },
  { label: "Sourced", key: "sourced" },
  { label: "Applied", key: "applied" },
  { label: "Phone Screen", key: "phone" },
  { label: "Assessment", key: "assessment" },
  { label: "Interview", key: "interview" },
  { label: "Offer", key: "offer" },
  { label: "Hired", key: "hired" },
  { label: "Reminder", key: "reminderCount" }
];

export const JOBCARD_STATUS = [
  { value: "assessment_completed", label: "Assessment Completed" },
  { value: "pending", label: "Pending " },
  { value: "invited", label: "Invited " },
  { value: "active", label: "Active" },
  { value: "hold", label: "Hold" }
  // other status options
];
