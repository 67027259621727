import React from "react";

import { Drawer } from "antd";
import PdfViewer from "../../../PdfViewer";
import { useIsMobileView } from "../../../../Utils/CustomHooks/windowSize";

export const ResumePreviewSection = ({ visible, setVisible }) => {


  return (
    <Drawer
      title="Resume Preview"
      placement="right"
      width={useIsMobileView() ? "100%" : "65%"}
      onClose={() =>
        setVisible((prev) => ({
          ...prev,
          show: false,
          resume_url: ""
        }))
      }
      visible={visible?.show}
      bodyStyle={{ padding: "20px" }}
    >
      <PdfViewer
        resumeSrc={`${process.env.REACT_APP_IMAGES_BASE_URL}${visible?.resume_url}`}
      />
    </Drawer>
  );
};
