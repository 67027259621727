import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Avatar,
  Row,
  Col,
  Select,
  Typography,
  Modal,
  message
} from "antd";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetailsAction } from "../../redux/actions/user";
import { All_ROLES, countries, languages } from "../../Assets/data/common";
import { REACT_USER_ID } from "../Auth/tokenProvider";
import { getRoleUSerDetailsAction } from "../../redux/actions/Roles&Management";
import { TailSpin } from "react-loader-spinner";
import ImageUploadComponent from "../Upload";
import LocationSelect from "../../Utils/locationSelector";

const { Option } = Select;
const { Title } = Typography;

const Profile = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const getUserDetails = useSelector(
    (state) => state.getRoleUSerDetailsReducer
  );
  const updateUserDetails = useSelector(
    (state) => state.updateUserDetailReducer
  );

  useEffect(() => {
    const userId = REACT_USER_ID();
    if (userId) {
      dispatch(getRoleUSerDetailsAction({ user_id: userId }));
    }
  }, [dispatch, updateUserDetails]);

  useEffect(() => {
    const { data, loading, error } = getUserDetails;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        form.setFieldsValue(data); // Set form values here
        setData(data); // Set form values here
      }
      if (error) {
        message.error(error);
      }
    }
  }, [getUserDetails]);

  useEffect(() => {
    const { data, loading, error } = updateUserDetails;
    if (!loading) {
      if (data && !error) {
        localStorage.setItem("REACT_USER_PHOTO", data?.updatedUser?.photo);
        message.success("Profile Updated");
      }
      if (error) {
        message.error(error);
      }
    }
  }, [updateUserDetails]);

  const onFinish = (values) => {
    dispatch(updateUserDetailsAction({ user_id: REACT_USER_ID(), ...values }));
    setModalVisible(false);
  };

  const showModal = () => setModalVisible(true);

  return (
    <>
      {updateUserDetails.loading || loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <div className="container mt-5">
          <div className="text-center mb-4" style={{ position: "relative" }}>
            <Avatar
              size={80}
              icon={<UserOutlined />}
              src={
                form.getFieldValue("photo")
                  ? `${
                      process.env.REACT_APP_IMAGES_BASE_URL
                    }${form.getFieldValue("photo")}`
                  : undefined
              }
              alt="Profile"
            />
            <Button
              icon={<EditOutlined />}
              size="small"
              shape="circle"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                backgroundColor: "#00756a",
                color: "white"
              }}
              onClick={showModal}
            />
          </div>
          <Title level={2} className="text-center mb-4">
            Profile
          </Title>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="first_name"
                  label="First Name"
                  rules={[
                    { required: true, message: "Please input your first name!" }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="last_name"
                  label="Last Name"
                  rules={[
                    { required: true, message: "Please input your last name!" }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="contact"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!"
                    }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="languagePreference"
                  label="Language Preference"
                  rules={[
                    {
                      required: true,
                      message: "Please select your language preference!"
                    }
                  ]}
                >
                  <Select size="large" allowClear showSearch>
                    {languages.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please select your country!" }
                  ]}
                >
                  <Select size="large" allowClear showSearch>
                    {countries.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="timezone"
                  label="Time Zone"
                  rules={[
                    { required: true, message: "Please select your time zone!" }
                  ]}
                >
                  <Select size="large" allowClear showSearch disabled>
                    {/* Timezone options go here */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                {[
                  "ats-admin",
                  "ats-recruiter",
                  "hiring-manager",
                  "ats-interviewer",
                  "assessment-admin",
                  "assessment-recruiter",
                  "mentorpal-admin",
                  "mentorpal-recruitment-manager",
                  "mentorpal-recruiter",
                  "mentorpal-executive"
                ].includes(form?.getFieldValue("user_type")) && (
                  <Form.Item label="Reports To">
                    <Select
                      size="large"
                      allowClear
                      showSearch
                      disabled
                      value={
                        data?.reportToDetails
                          ? data.reportToDetails.name
                          : undefined
                      }
                    >
                      {data?.reportToDetails ? (
                        <>
                          <Option
                            value={data?.reportToDetails?.name}
                            key={data?.reportToDetails?.name}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                style={{
                                  width: 32,
                                  height: 32,
                                  borderRadius: "50%",
                                  marginRight: 8
                                }}
                                src={
                                  data?.reportToDetails?.photo
                                    ? ` ${process.env.REACT_APP_IMAGES_BASE_URL}${data?.reportToDetails.photo}`
                                    : null
                                }
                                icon={
                                  !data?.reportToDetails?.photo && (
                                    <UserOutlined />
                                  )
                                }
                              />
                              <div style={{ flex: 1 }}>
                                <strong
                                  style={{ display: "block", fontSize: "14px" }}
                                >
                                  {data?.reportToDetails?.name}
                                </strong>
                              </div>
                            </div>
                          </Option>
                        </>
                      ) : (
                        <Option>No Reports To assign</Option>
                      )}
                    </Select>
                  </Form.Item>
                )}
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  name="user_type"
                  label="Role"
                  rules={[
                    { required: true, message: "Please select the role!" }
                  ]}
                >
                  <Select size="large" allowClear showSearch disabled>
                    {All_ROLES.map((role) => (
                      <Select.Option key={role.value} value={role.value}>
                        {role.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="address" label="Address">
              <LocationSelect
                field={{
                  name: "address",
                  placeholder: "Enter current address!"
                }}
                form={form}
              />
            </Form.Item>

            <Form.Item>
              <Button
                loading={updateUserDetails.loading}
                type="primary"
                htmlType="submit"
                size="large"
                style={{ backgroundColor: "#00756a", color: "white" }}
              >
                Save Changes
              </Button>
            </Form.Item>
          </Form>
          <ProfilePictureModal
            visible={modalVisible}
            setVisible={setModalVisible}
            form={form}
          />
        </div>
      )}
    </>
  );
};

const ProfilePictureModal = ({ visible, setVisible, form }) => {
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState(form?.getFieldValue("photo") || "");

  useEffect(() => {
    setPhoto(form?.getFieldValue("photo"));
  }, [form]);

  const onFinish = (values) => {
    dispatch(
      updateUserDetailsAction({
        user_id: REACT_USER_ID(),
        ...values,
        photo
      })
    );
    setVisible(false);
  };

  return (
    <Modal
      title="Update Profile Picture"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      centered
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ photo }}
        onFinish={onFinish}
      >
        <Form.Item
          name="photo"
          label="Profile Picture"
          valuePropName="file"
          getValueFromEvent={() => photo}
        >
          <ImageUploadComponent
            uploadPath="hire_talent/profile/"
            acceptedFileTypes="image/*"
            uploadType="Photo"
            onFileUpload={(profileImageUrl) => {
              if (profileImageUrl) {
                setPhoto(profileImageUrl);
              }
            }}
            existingFileUrl={photo}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => {
              onFinish();
            }}
            size="large"
            style={{ backgroundColor: "#00756a", color: "white" }}
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Profile;
