import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { candidateStatsReportAction } from "../../../redux/actions/candidate";
import { TailSpin } from "react-loader-spinner";
import { Col, Layout, Row, Typography, Card, message } from "antd";
import { useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import "../../../Assets/css/candidate.dashboard.css";
import { convertUTCtoLocal } from "../../../Utils/getDuration";
import { JOBCARD_STATUS } from "../../../Assets/data/Job";

const { Content } = Layout;
const { Title, Text } = Typography;

const enumOptions = [
  { label: "Invited", value: "invited" },
  { label: "Invitation Delivered", value: "invitation_delivered" },
  { label: "Invitation Clicked", value: "invitation_clicked" },
  { label: "Invitation Opened", value: "invitation_opened" },
  { label: "Assessment Started", value: "assessment_started" },
  { label: "Assessment In Progress", value: "assessment_progress" },
  { label: "Assessment Completed", value: "assessment_completed" },
  { label: "Disqualified", value: "disqualified" },
  { label: "Delete", value: "delete" },
  { label: "Sourced", value: "sourced" },
  { label: "Applied", value: "applied" },
  { label: "Phone", value: "phone" },
  { label: "Assessment", value: "assessment" },
  { label: "Interview", value: "interview" },
  { label: "Offer", value: "offer" },
  { label: "Hired", value: "hired" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Hold", value: "hold" },
  { label: "Scheduled", value: "scheduled" }
];

const CandidateHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const candidateStatsReportReducer = useSelector(
    (state) => state.candidateStatsReportReducer
  );

  const getStatusLabel = (status) => {
    const statusOption = enumOptions.find((option) => option.value === status);
    return statusOption ? statusOption.label : status.replace(/_/g, " ");
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "invited":
        return "#3498db"; // Blue
      case "invitation_delivered":
        return "#1abc9c"; // Green
      case "invitation_clicked":
        return "#f39c12"; // Orange
      case "invitation_opened":
        return "#9b59b6"; // Purple
      case "assessment_started":
        return "#2980b9"; // Dark Blue
      case "assessment_progress":
        return "#f1c40f"; // Yellow
      case "assessment_completed":
        return "#27ae60"; // Green (completed)
      case "disqualified":
        return "#e74c3c"; // Red
      case "delete":
        return "#7f8c8d"; // Gray
      case "sourced":
        return "#16a085"; // Teal
      case "applied":
        return "#8e44ad"; // Violet
      case "phone":
        return "#34495e"; // Dark Gray
      case "assessment":
        return "#d35400"; // Dark Orange
      case "interview":
        return "#2ecc71"; // Light Green
      case "offer":
        return "#e67e22"; // Orange
      case "hired":
        return "#2c3e50"; // Navy Blue
      case "active":
        return "#00b894"; // Bright Green
      case "inactive":
        return "#636e72"; // Dark Gray
      case "hold":
        return "#fdcb6e"; // Light Orange
      case "scheduled":
        return "#bf75c7"; //light purple
      default:
        return "#ccc"; // Default Gray for unknown status
    }
  };

  useEffect(() => {
    const { loading, data, error } = candidateStatsReportReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [candidateStatsReportReducer]);

  useEffect(() => {
    dispatch(candidateStatsReportAction());
    return () => {
      dispatch({ type: "CANDIDATE_STATS_REPORT_RESET" });
    };
  }, [dispatch]);

  const renderApplications = (title, applications, type) => (
    <div className="my-applications mt-5">
      <Title level={3} style={{ marginBottom: "20px" }}>
        {title}
      </Title>
      {applications.length === 0 ? (
        <Card
          className="no-data-card text-center shadow-sm"
          style={{ borderRadius: "12px", padding: "20px" }}
        >
          <Title level={5}>No applications found</Title>
        </Card>
      ) : (
        <Row gutter={[16, 16]}>
          {applications.map((item, index) => (
            <Col xs={24} sm={24} md={12} lg={12} key={index}>
              <div
                className="candidate-job-card"
                style={{
                  padding: "25px",
                  margin: "10px 0",
                  border: "1px solid #e0e0e0",
                  borderRadius: "18px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  backgroundColor: "#ffffff",
                  minHeight: "240px"
                }}
                role="button"
                aria-label={`Navigate to details of ${item.title}`}
              >
                <div
                  className="d-flex justify-content-between align-items-center mb-4"
                  style={{
                    borderBottom: "1px solid #f0f0f0",
                    paddingBottom: "15px"
                  }}
                >
                  <h2
                    className="h5 mb-0"
                    style={{ color: "#333", fontWeight: "bold" }}
                  >
                    {item?.title}
                  </h2>
                  <div className="d-flex align-items-center text-center">
                    <span
                      className={`candidate-status-badge ${
                        item.status === "assessment_completed"
                          ? "completed"
                          : "pending"
                      }`}
                      style={{
                        borderRadius: "18px",
                        minWidth: "170px",
                        padding: "5px 10px",
                        backgroundColor: getStatusColor(item.status),
                        color: "#fff",
                        fontWeight: "600"
                      }}
                    >
                      {getStatusLabel(item.status)}
                    </span>
                    <div
                      className="candidate-icon-container"
                      style={{ marginLeft: "10px" }}
                    >
                      <RightOutlined style={{ color: "#005c53" }} />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <strong
                    className="d-block"
                    style={{ fontSize: "1.1rem", color: "#006d62" }}
                  >
                    {type === "assessment"
                      ? "Mentorpal.ai"
                      : item?.company_name}
                  </strong>
                  <span className="text-muted" style={{ fontSize: "0.9rem" }}>
                    Company Name
                  </span>
                </div>

                <div
                  className="d-flex justify-content-between text-muted"
                  style={{
                    fontSize: "0.9rem",
                    borderTop: "1px solid #f0f0f0",
                    paddingTop: "15px"
                  }}
                >
                  <i className="bi bi-clock" style={{ marginRight: "5px" }}></i>
                  Invited on:{" "}
                  <strong>
                    {convertUTCtoLocal(item.apply_date || item.createdAt)}
                  </strong>
                  <i className="bi bi-clock" style={{ marginRight: "5px" }}></i>
                  Last activity: <strong>{item.updatedAt}</strong>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );

  const renderInterviews = (title, interviews) => (
    <div className="my-interviews mt-5">
      <Title level={3} style={{ marginBottom: "20px" }}>
        {title}
      </Title>
      {interviews.length === 0 ? (
        <Card
          className="no-data-card text-center shadow-sm"
          style={{ borderRadius: "12px", padding: "20px" }}
        >
          <Title level={5}>No interviews found</Title>
        </Card>
      ) : (
        <Row gutter={[16, 16]}>
          {interviews.map((item, index) => {
            const startDateTime = new Date(item.startDateTime);
            const endDateTime = new Date(item.endDateTime);
            const isSameDate =
              startDateTime.toDateString() === endDateTime.toDateString();

            const dateOptions = {
              year: "numeric",
              month: "long",
              day: "numeric"
            };
            const timeOptions = {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true
            };

            return (
              <Col xs={24} sm={24} md={12} lg={12} key={index}>
                <div
                  className="candidate-job-card"
                  style={{
                    padding: "25px",
                    margin: "10px 0",
                    border: "1px solid #e0e0e0",
                    borderRadius: "18px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    backgroundColor: "#ffffff",
                    minHeight: "240px"
                  }}
                  role="button"
                  aria-label={`Navigate to details of ${item.summary}`}
                >
                  <div
                    className="d-flex justify-content-between align-items-center mb-4"
                    style={{
                      borderBottom: "1px solid #f0f0f0",
                      paddingBottom: "15px"
                    }}
                  >
                    <h2
                      className="h5 mb-0"
                      style={{ color: "#333", fontWeight: "bold" }}
                    >
                      {item?.summary}
                    </h2>
                    <div className="d-flex align-items-center text-center">
                      <span
                        className="candidate-status-badge scheduled"
                        style={{
                          borderRadius: "18px",
                          minWidth: "170px",
                          padding: "5px 10px",
                          backgroundColor: getStatusColor(item.status),
                          color: "#fff",
                          fontWeight: "600"
                        }}
                      >
                        {getStatusLabel(item.status)}
                      </span>
                      <div
                        className="candidate-icon-container"
                        style={{ marginLeft: "10px" }}
                      >
                        <RightOutlined style={{ color: "#005c53" }} />
                      </div>
                    </div>
                  </div>

                  <div className=" mb-3">
                    <strong
                      className="d-block"
                      style={{ fontSize: "1.1rem", color: "#006d62" }}
                    >
                      {item.interviewRound}
                    </strong>
                    <span className="text-muted">Round</span>
                  </div>
                  <div className="mb-3">
                    <strong
                      className="d-block"
                      style={{ fontSize: "1.1rem", color: "#006d62" }}
                    >
                      {item.location}
                    </strong>
                    <span className="text-muted">Loaction</span>
                  </div>
                  <div className=" mb-3  ">
                    <strong
                      className="d-block"
                      style={{ fontSize: "1.1rem", color: "#006d62" }}
                    >
                      {isSameDate
                        ? `${startDateTime.toLocaleDateString(
                            undefined,
                            dateOptions
                          )} from ${startDateTime.toLocaleTimeString(
                            undefined,
                            timeOptions
                          )} to ${endDateTime.toLocaleTimeString(
                            undefined,
                            timeOptions
                          )}`
                        : `From ${startDateTime.toLocaleDateString(
                            undefined,
                            dateOptions
                          )} at ${startDateTime.toLocaleTimeString(
                            undefined,
                            timeOptions
                          )} to ${endDateTime.toLocaleDateString(
                            undefined,
                            dateOptions
                          )} at ${endDateTime.toLocaleTimeString(
                            undefined,
                            timeOptions
                          )}`}
                    </strong>
                    <span className="text-muted">Date & Time </span>
                  </div>

                  <div className="mt-2 mb-3  ">
                    <strong className="d-block">
                      <a
                        href={item.meeting_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#3498db", fontSize: "1.1rem" }}
                      >
                        {item.meeting_url}
                      </a>
                    </strong>
                    <span className="text-muted">Meeting Link</span>
                  </div>

                  <div
                    className="d-flex justify-content-between text-muted"
                    style={{
                      fontSize: "0.9rem",
                      borderTop: "1px solid #f0f0f0",
                      paddingTop: "15px"
                    }}
                  >
                    <i
                      className="bi bi-clock"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Invited on :{" "}
                    <strong>
                      {convertUTCtoLocal(item.apply_date || item.createdAt)}
                    </strong>
                    <i
                      className="bi bi-clock"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Last activity : <strong>{item.updatedAt}</strong>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );

  return (
    <Content className="container mt-4">
      {loading ? (
        <div className="spinner-container">
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          {renderApplications(
            "My Assessment Application",
            data?.assessment_invite_stats || [],
            "assessment"
          )}
          {renderApplications(
            "My Job Application",
            data?.job_application_stats || [],
            "job"
          )}
          {renderInterviews("My Interviews", data?.interviews || [])}
        </>
      )}
    </Content>
  );
};

export default CandidateHome;
