import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { Row, Col, Button, Typography, Layout, message, Spin } from "antd";
import { Editor } from "@monaco-editor/react";
import axios from "axios";

const { Text } = Typography;
const { Content } = Layout;

const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL_2}`,
});

const executeCode = async (language, sourceCode, testCases) => {
  try {
    const response = await API.post("public/compile", {
      code: sourceCode,
      language,
      testCases,
    });
    return response.data;
  } catch (error) {
    throw new Error("Code execution failed.");
  }
};

const CodeEditor = React.memo(({ data, onSave }) => {
  const editorRef = useRef(null);
  const [value, setValue] = useState(data?.codeStubs || "");
  const [output, setOutput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // Reset the editor content when new data is received
    setValue(data?.codeStubs || "");
    setOutput(null);
    setIsError(false);
  }, [data]);

  const onMount = useCallback((editor) => {
    editorRef.current = editor;
    editor.focus();
  }, []);

  const handleEditorChange = useCallback((newValue) => {
    setValue(newValue);
  }, []);

  const runCode = useCallback(async () => {
    const language = data?.languages;
    const sourceCode = value;
    if (!sourceCode) return;

    setIsLoading(true);
    setIsError(false);
    try {
      const result = await executeCode(language, sourceCode, data.testCases);
      setOutput(result.results);
      onSave({
        language,
        sourceCode,
        result: result.results,
      });
    } catch (error) {
      console.error(error);
      message.error("An error occurred. Unable to run code.");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [data, onSave, value]);

  const renderOutput = useMemo(() => {
    if (isLoading) {
      return (
        <>
          <Spin/>
        </>
      );
    }

    if (!output || output.length === 0) {
      return (
        <Text className="text-white" disabled>
          Click "Run Code" to see the output here
        </Text>
      );
    }

    return output.map((result, index) => (
      <div key={index} style={{ marginBottom: "24px" }}>
        <Text className="text-white" strong>
          Test Case {index + 1}:
        </Text>
        <br />
        <Text
          className={`text-white ${
            result?.result?.error ? "text-red" : "text-green"
          }`}
          strong
        >
          Status: {result?.result?.error ? "Fail" : "Pass"}
        </Text>
        <br />
        <div style={{ marginBottom: "8px" }}>
          <Text className="text-white">Input:</Text>
          <pre
            className="text-white"
            style={{
              backgroundColor: "#333",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            {result?.testCase?.input}
          </pre>
          <Text className="text-white">Expected Output:</Text>
          <pre
            className="text-white"
            style={{
              backgroundColor: "#333",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            {result?.testCase?.expectedOutput}
          </pre>
        </div>
        <div style={{ marginBottom: "8px" }}>
          <Text className="text-white">Your Output:</Text>
          <pre
            className="text-white"
            style={{
              backgroundColor: "#333",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            {result?.result.output || "No output"}
          </pre>
        </div>
        {result?.result?.error && (
          <div>
            <Text className="text-white">Error:</Text>
            <pre
              className="text-white"
              style={{
                backgroundColor: "#333",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              {result?.result?.error}
            </pre>
          </div>
        )}
      </div>
    ));
  }, [isLoading, output, isError]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#0f0a19",
        color: "gray.500",
      }}
    >
      <Content style={{ padding: "24px" }}>
        <div
          style={{
            backgroundColor: "#0f0a19",
            padding: "24px",
            marginTop: "24px",
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <div style={{ marginLeft: 8, marginBottom: 16 }}>
                <Text className="text-white">
                  Decode your snippets and modify as needed
                </Text>
              </div>
              <Editor
                height="75vh"
                theme="vs-dark"
                language={data?.languages}
                value={value}
                onMount={onMount}
                onChange={handleEditorChange}
                options={{ minimap: { enabled: false } }}
              />
            </Col>
            <Col span={12}>
              <Text style={{ marginBottom: 8 }} className="text-white" strong>
                Output
              </Text>
              <Button
                type="primary"
                style={{ marginBottom: 16 }}
                loading={isLoading}
                onClick={runCode}
                className="candidate-db-2de5X candidate-db-14TuV candidate-db-2ySVn mx-2 main_border_radius text-white"
              >
                {isLoading ? "Running..." : "Run Code"}
              </Button>
              <div
                style={{
                  height: "75vh",
                  padding: 8,
                  color: isError ? "#f5222d" : "",
                  border: isError ? "1px solid #f5222d" : "1px solid #333",
                  borderRadius: 4,
                  overflowY: "auto",
                }}
              >
                {renderOutput}
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
});

export default CodeEditor;
