export const SUPPORTED_LANGUAGE = [
    {
      value: "c",
      name: "C",
      version: "10.2.0",
      snippet:
        '#include <stdio.h>\nint main() {\n    char input[1000];\n    fgets(input, sizeof(input), stdin);\n    printf("%s", input);\n    return 0;\n}',
    },
    {
      value: "cpp",
      name: "C++",
      version: "10.2.0",
      snippet:
        "#include <iostream>\n#include <string>\nint main() {\n    std::string input;\n    std::getline(std::cin, input);\n    std::cout << input << std::endl;\n    return 0;\n}",
    },
    {
      value: "java",
      name: "Java",
      version: "15.0.2",
      snippet:
        "import java.util.Scanner;\npublic class Main {\n    public static void main(String[] args) {\n        Scanner scanner = new Scanner(System.in);\n        String input = scanner.nextLine();\n        System.out.println(input);\n    }\n}",
    },
    {
      value: "python",
      name: "Python",
      version: "3.10.0",
      snippet: "import sys\ninput = sys.stdin.read().strip()\nprint(input)",
    },
    {
      value: "javascript",
      name: "JavaScript",
      version: "Node.js 16.x",
      snippet:
        "process.stdin.on('data', (data) => {\n    console.log(data.toString().trim());\n});",
    },
    {
      value: "ruby",
      name: "Ruby",
      version: "3.1.0",
      snippet: "input = $stdin.gets.chomp\nputs input",
    },
    {
      value: "go",
      name: "Go",
      version: "1.18",
      snippet:
        'package main\nimport (\n    "bufio"\n    "fmt"\n    "os"\n)\nfunc main() {\n    reader := bufio.NewReader(os.Stdin)\n    input, _ := reader.ReadString(\'\\n\')\n    fmt.Print(input)\n}',
    },
    {
      value: "swift",
      name: "Swift",
      version: "5.7",
      snippet:
        "import Foundation\nif let input = readLine() {\n    print(input)\n}",
    },
    {
      value: "kotlin",
      name: "Kotlin",
      version: "1.7.0",
      snippet: "fun main() {\n    val input = readLine()\n    println(input)\n}",
    },
    {
      value: "rust",
      name: "Rust",
      version: "1.68.0",
      snippet:
        'use std::io;\nfn main() {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    print!("{}", input);\n}',
    },
    {
      value: "php",
      name: "PHP",
      version: "8.0",
      snippet: "<?php\n$input = trim(fgets(STDIN));\necho $input;\n?>",
    },
    {
      value: "typescript",
      name: "TypeScript",
      version: "4.5",
      snippet:
        "import * as readline from 'readline';\nconst rl = readline.createInterface({\n    input: process.stdin,\n    output: process.stdout\n});\nrl.on('line', (line) => {\n    console.log(line);\n});",
    },
    {
      value: "haskell",
      name: "Haskell",
      version: "8.10.7",
      snippet:
        "main :: IO ()\nmain = do\n    input <- getLine\n    putStrLn input",
    },
    {
      value: "scala",
      name: "Scala",
      version: "2.13.8",
      snippet:
        "import scala.io.StdIn._\nobject Main extends App {\n    val input = readLine()\n    println(input)\n}",
    },
    {
      value: "perl",
      name: "Perl",
      version: "5.32",
      snippet: "$input = <STDIN>;\nprint $input;",
    },
    {
      value: "r",
      name: "R",
      version: "4.2.2",
      snippet: 'input <- readLines("stdin")\ncat(input)',
    },
    {
      value: "objective-c",
      name: "Objective-C",
      version: "2.0",
      snippet:
        '#import <Foundation/Foundation.h>\nint main(int argc, const char * argv[]) {\n    @autoreleasepool {\n        char buffer[256];\n        fgets(buffer, sizeof(buffer), stdin);\n        printf("%s", buffer);\n    }\n    return 0;\n}',
    },
    {
      value: "lua",
      name: "Lua",
      version: "5.4",
      snippet: "local input = io.read()\nprint(input)",
    },
    {
      value: "d",
      name: "D",
      version: "2.104.2",
      snippet:
        "import std.stdio;\nvoid main() {\n    string input = stdin.readln();\n    writeln(input);\n}",
    },
    {
      value: "scheme",
      name: "Scheme",
      version: "9.5.1",
      snippet:
        "(define (main)\n  (let ((input (read)))\n    (display input)\n    (newline)))\n(main)",
    },
    {
      value: "fsharp",
      name: "F#",
      version: "5.0",
      snippet:
        'open System\n[<EntryPoint>]\nlet main argv =\n    let input = Console.ReadLine()\n    printfn "%s" input\n    0 // return an integer exit code',
    },
    {
      value: "elixir",
      name: "Elixir",
      version: "1.14",
      snippet: 'IO.gets("") |> IO.puts',
    },
    {
      value: "csharp",
      name: "C#",
      version: "10.0",
      snippet:
        "using System;\nclass Program {\n    static void Main() {\n        string input = Console.ReadLine();\n        Console.WriteLine(input);\n    }\n}",
    },
    {
      value: "bash",
      name: "Bash",
      version: "5.1",
      snippet: 'read input\necho "$input"',
    },
    {
      value: "sql",
      name: "SQL",
      version: "PostgreSQL 14",
      snippet: "SELECT :input;",
    },
  ];
  