import { message } from "antd";
import moment from "moment";
import * as XLSX from "xlsx";
export const downloadExcel = (filteredData, jobDetails) => {
  if (filteredData.length === 0) {
    message.error("No Reports Found!");
  }
  const downloadData = filteredData?.map((candidate, index) => {
    const candidateDetails = candidate?.candidate_details || {};
    const remarks = candidateDetails?.remarks || {};
    const resumeLink = candidateDetails?.resume
      ? `${process.env.REACT_APP_IMAGES_BASE_URL}${candidateDetails?.resume}`
      : null;

    return {
      "S.No": index + 1,
      Date: "-",
      Name: candidateDetails?.name || "-",
      Profile: jobDetails?.job?.job_title || "-",
      "Client Remarks": "-",
      Remarks: "-",
      "Resume Link": resumeLink || "-", // Store the link directly
      Email: candidateDetails?.email || "-",
      Contact: candidateDetails?.phone || "-",
      Education:
        candidateDetails?.education?.length > 0
          ? candidateDetails?.education
              .map(
                (edu) =>
                  `${edu?.degree || "-"} in ${edu?.fieldOfStudy || "-"} at ${
                    edu.school || "-"
                  } (${
                    moment(edu?.startDate).format("MMM DD, YYYY") || "-"
                  } - ${moment(edu?.endDate).format("MMM DD, YYYY") || "-"})`
              )
              .join("; ")
          : "-",
      Experience: remarks?.totalYearsOfExperience || "-",
      "Would like to work in early stage startup? why": "-",
      "Current CTC": remarks?.currentCTC?.amount
        ? `${remarks?.currentCTC?.amount} ${remarks?.currentCTC?.unit}`
        : "-",
      "Expected CTC": remarks.expectedCTC?.amount
        ? `${remarks.expectedCTC.amount} ${remarks.expectedCTC.unit}`
        : "-",
      "Notice Period": remarks?.noticePeriod || "-",
      "Holding an offer? (if yes, offered CTC)": "-",
      "Skillsets/ Technologies worked on": "-",
      "Current Location": remarks?.currentLocation || "-",
      "Sourcing Date":
        moment(candidate?.createdAt).format("MMM DD, YYYY hh:mm A") || "-",
      "Last Working Day": remarks?.lastWorkingDay
        ? moment(remarks?.lastWorkingDay).format("MMM DD, YYYY hh:mm A")
        : "-",
      Comments: "",
      Recruiter:
        candidate?.assign_to?.userIdsDetails?.length > 0
          ? candidate?.assign_to?.userIdsDetails
              .map((user) => user?.name)
              .join(", ")
          : "-"
    };
  });

  const getProperFilename = (title) =>
    title?.replace(/\s+/g, "-") || "candidates.xlsx";

  const getFormattedDate = () => {
    const date = new Date();
    return date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      })
      .replace(/\//g, "-");
  };

  const filename = `${getProperFilename(
    jobDetails?.job?.job_title
  )}-${getProperFilename(
    jobDetails?.client?.company_name
  )}-candidate-application_report-${getFormattedDate()}.xlsx`;

  const worksheet = XLSX.utils.json_to_sheet(downloadData);
  const columnWidths = [
    { wch: 5 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 30 },
    { wch: 25 },
    { wch: 15 },
    { wch: 50 },
    { wch: 20 },
    { wch: 50 },
    { wch: 15 },
    { wch: 15 },
    { wch: 20 },
    { wch: 50 },
    { wch: 50 },
    { wch: 25 },
    { wch: 25 },
    { wch: 50 },
    { wch: 25 }
  ];

  worksheet["!cols"] = columnWidths;
  worksheet["!freeze"] = {
    xSplit: 0,
    ySplit: 1,
    topLeftCell: "A2",
    activePane: "bottomRight",
    state: "frozen"
  };

  const headerCells = Object.keys(downloadData[0]);
  headerCells.forEach((header, colIndex) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
    worksheet[cellAddress].s = {
      font: { bold: true, sz: 14, color: { rgb: "FFFFFF" } },
      fill: { fgColor: { rgb: "4F81BD" } },
      alignment: { horizontal: "center", vertical: "center" },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } }
      }
    };
  });

  for (let rowIndex = 1; rowIndex <= downloadData.length; rowIndex++) {
    headerCells.forEach((header, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({
        r: rowIndex,
        c: colIndex
      });
      worksheet[cellAddress].s = {
        alignment: { horizontal: "left", vertical: "center" },
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } }
        }
      };

      if (
        header === "Resume Link" &&
        downloadData[rowIndex - 1][header] !== "-"
      ) {
        worksheet[cellAddress].l = {
          Target: downloadData[rowIndex - 1][header],
          Tooltip: "Click to view resume"
        };
        worksheet[cellAddress].v = "View Resume"; // Display text in the cell
      }
    });
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Candidates");

  XLSX.writeFile(workbook, filename);
  message.success("Report downloaded successfully!");
};
