import React, { useState, useEffect } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { message, Spin, Row, Col } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getInterviewCalendarAction } from "../../redux/actions/interviewer";
import InterviewDetailsDrawer from "./InterviewDetailsModal";

const localizer = momentLocalizer(moment);

const CustomDatePicker = ({ selectedDate, onDateSelect }) => {
  const [currentMonth, setCurrentMonth] = useState(moment(selectedDate).month());
  const [currentYear, setCurrentYear] = useState(moment(selectedDate).year());

  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  useEffect(() => {
    setCurrentMonth(moment(selectedDate).month());
    setCurrentYear(moment(selectedDate).year());
  }, [selectedDate]);

  const handleDateSelect = (day) => {
    const date = moment().year(currentYear).month(currentMonth).date(day);
    onDateSelect(date);
  };

  const handlePrevMonth = () => {
    const newMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const newYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const handleNextMonth = () => {
    const newMonth = (currentMonth + 1) % 12;
    const newYear = currentMonth === 11 ? currentYear + 1 : currentYear;
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const renderDays = () => {
    const startOfMonth = moment().year(currentYear).month(currentMonth).startOf("month");
    const endOfMonth = moment().year(currentYear).month(currentMonth).endOf("month");
    const daysInMonth = endOfMonth.date();
    const startDayOfWeek = startOfMonth.day();

    const daysArray = [];

    for (let i = 0; i < startDayOfWeek; i++) {
      daysArray.push(<div key={`empty-${i}`} />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = moment(startOfMonth).date(day);
      daysArray.push(
        <div
          key={day}
          onClick={() => handleDateSelect(day)}
          style={{
            padding: "10px",
            cursor: "pointer",
            background: date.isSame(selectedDate, "day")
              ? "#1890ff"
              : "transparent",
            color: date.isSame(selectedDate, "day") ? "#fff" : "#000",
            borderRadius: "4px",
            textAlign: "center",
            transition: "background-color 0.2s",
          }}
        >
          {day}
        </div>
      );
    }

    return daysArray;
  };

  return (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "8px",
        borderRadius: "8px",
        maxWidth: "400px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <LeftOutlined onClick={handlePrevMonth} style={{ cursor: "pointer" }} />
        <span style={{ fontSize: "16px", fontWeight: "500" }}>
          {moment().year(currentYear).month(currentMonth).format("MMMM YYYY")}
        </span>
        <RightOutlined onClick={handleNextMonth} style={{ cursor: "pointer" }} />
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: "5px",
          marginTop: "10px",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {dayNames.map((day) => (
          <div key={day} style={{ padding: "5px" }}>{day.slice(0, 3)}</div>
        ))}
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: "5px",
          marginTop: "10px",
        }}
      >
        {renderDays()}
      </div>
    </div>
  );
};

const InterviewCalendar = () => {
  const dispatch = useDispatch();
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Check if the screen is mobile
  const [filter, setFilter] = useState({
    range: "month",
    date: moment().format("YYYY-MM-DD")
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState({
    show: false,
    interview_id: ""
  });

  const getInterviewCalendarReducer = useSelector(
    (state) => state.getInterviewCalendarReducer
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getInterviewCalendarAction(filter));
    return () => {
      dispatch({ type: "GET_INTERVIEW_CALENDAR_RESET" });
    };
  }, [dispatch, filter]);

  useEffect(() => {
    const { loading, status, error, data } = getInterviewCalendarReducer;
    setLoading(loading);

    if (!loading && data && !error) {
      setData(data?.data);
    }

    if (!loading && !data && error) {
      message.error(error);
    }
  }, [getInterviewCalendarReducer]);

  const events = data.flatMap((item) =>
    item.events.map((event) => {
      const start = moment(event.start).toDate();
      const end = moment(event.end).toDate();

      return {
        title: event.title,
        start,
        end,
        allDay: false,
        interview_id: event.interview_id,
        type: event.type
      };
    })
  );

  const getEventColor = (event) => {
    switch (event.type) {
      case "meeting":
        return "#4285F4";
      case "interview":
        return "#34A853";
      case "call":
        return "#FBBC05";
      default:
        return "#EA4335";
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = getEventColor(event);
    return {
      style: {
        backgroundColor,
        color: "#fff",
        borderRadius: "4px",
        padding: "4px 8px",
        border: isSelected ? "2px solid #333" : "none",
        fontSize: "0.85rem",
        whiteSpace: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
        transition: "background-color 0.3s"
      }
    };
  };

  const handleDateSelect = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setFilter({
      range: "day",
      date: formattedDate
    });
  };

  const handleNavigate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setFilter({ ...filter, date: formattedDate });
  };

  return (
    <Row gutter={16} style={{ padding: "20px", margin: "auto" }}>
      {!isMobile && (
        <Col
          xs={24} 
          md={6} 
          style={{ borderRight: "1px solid #ccc", paddingRight: "10px" }}
        >
          <CustomDatePicker
            selectedDate={moment(filter.date)}
            onDateSelect={(date) => {
              handleDateSelect(date);
              handleNavigate(date);
            }}
          />
        </Col>
      )}
      <Col xs={24} md={isMobile ? 24 : 18}> 
        {loading && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999
            }}
          >
            <Spin tip="Loading..." size="large" />
          </div>
        )}
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="day"
          style={{ height: "100vh", maxHeight: "100vh", width: "100%" }} 
          eventPropGetter={eventStyleGetter}
          onSelectEvent={(event) =>
            setOpenDrawer({ show: true, interview_id: event.interview_id })
          }
          onNavigate={handleNavigate}
          date={moment(filter.date).toDate()}
          min={new Date(1970, 1, 1, 0, 0, 0)}
          max={new Date(1970, 1, 1, 23, 59, 0)}
          scrollToTime={new Date()}
          showCurrentTimeIndicator={true}
        />

        {openDrawer?.show && openDrawer?.interview_id && (
          <InterviewDetailsDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
          />
        )}
      </Col>
    </Row>
  );
};

export default InterviewCalendar;
