import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const SecurityMeasures = () => {
  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "white" }}>
      <Content
        className="container mt-5"
        style={{
          fontFamily: "'Inter', sans-serif",
          fontWeight: 400,
          color: "#262626",
          paddingLeft: "10%",
          paddingRight: "10%"
        }}
      >
        <div className="text-start">
          <h2
            className="fw-bold mb-3"
            style={{
              fontSize: "2rem",
              color: "#262626",
              fontWeight: 700
            }}
          >
            Information Security Measures
          </h2>
          <p style={{ fontSize: "1rem", color: "#888", fontWeight: 400 }}>
            Version v1 – August 2024
          </p>
        </div>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            1. Scope
          </h4>
          <p className="mb-4">
            This document outlines the technical and organizational measures
            implemented by Mpal Sol. Pvt. Ltd to secure Personal Data, End User
            Data, and Systems Data (collectively, “Data”) associated with any
            Mpal Sol. Pvt. Ltd Product (“Measures”).
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            2. Definitions
          </h4>
          <p className="mb-2">
            <strong>“Agreement”</strong> means any binding written or electronic
            agreement between Mpal Sol. Pvt. Ltd and Customer governing the
            provision of Products.
          </p>
          <p className="mb-2">
            <strong>“End User Data”</strong> means data provided by or on behalf
            of Customer to Mpal Sol. Pvt. Ltd during the relationship governed
            by the Agreement, excluding Systems Data.
          </p>
          <p className="mb-2">
            <strong>“Personal Data”</strong> refers to information processed on
            behalf of the Customer that relates to an identified or identifiable
            natural person, as defined under Data Protection Laws.
          </p>
          <p className="mb-2">
            <strong>“Product”</strong> means any Hardware, Software,
            Subscription, or combination thereof, regardless of procurement
            under an Enterprise Program.
          </p>
          <p className="mb-4">
            <strong>“Systems Data”</strong> means data generated or collected in
            connection with Customer’s use of the Products, including logs,
            telemetry data, and threat intelligence.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            3. Security Management
          </h4>
          <p className="mb-2">
            <strong>3.1. Security Program</strong>
          </p>
          <div className="mb-2 py-2 px-4">
            <ol style={{ listStyleType: "disc" }}>
              <li>
                The company maintains a written information security program
                managed by a senior employee.
              </li>
              <li>
                The program includes administrative, technical, and physical
                safeguards designed to protect Data.
              </li>
              <li>
                The program is appropriate to the nature, size, and complexity
                of Mpal Sol. Pvt. Ltd’s operations.
              </li>
            </ol>
          </div>
          <p className="mb-4">
            <strong>3.2. Personnel Security:</strong> Skills and competence of
            employees and contractors are assessed during hiring through various
            checks and evaluations.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            4. Due Diligence on Sub-contractors
          </h4>
          <p className="mb-2">
            <strong>4.1. Mpal Sol. Pvt. Ltd will:</strong>
          </p>
          <div className="mb-4 py-2 px-4">
            <ol style={{ listStyleType: "disc" }}>
              <li>
                Assess the security capabilities of subcontractors periodically
                to ensure compliance with these Measures.
              </li>
              <li>
                Apply written security requirements that align with Mpal Sol.
                Pvt. Ltd’s key information security policies.
              </li>
            </ol>
          </div>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            5. Physical Security
          </h4>
          <p className="mb-4">
            Mpal Sol. Pvt. Ltd does not operate physical office or data server
            locations.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            6. Logical Security
          </h4>
          <p className="mb-2">
            <strong>
              6.1. Systems Access Control and Network Access Control
            </strong>
          </p>
          <div className="mb-4 py-2 px-4">
            <ol style={{ listStyleType: "disc" }}>
              <li>
                Mpal Sol. Pvt. Ltd employs access controls to prevent
                unauthorized access and ensure least privilege access to Data.
              </li>
              <li>
                Individual accounts authenticate users, with password
                configurations following industry standards.
              </li>
              <li>
                Mpal Sol. Pvt. Ltd reviews access controls at least annually and
                requires multi-factor authentication for remote access.
              </li>
              <li>
                Access is promptly revoked upon the cessation of the need.
              </li>
            </ol>
          </div>

          <p className="mb-2">
            <strong>6.2. Telecommunication and Network Security</strong>
          </p>
          <div className="mb-4 py-2 px-4">
            <ol style={{ listStyleType: "disc" }}>
              <li>
                Mpal Sol. Pvt. Ltd uses firewalls and cryptographic technologies
                to protect and authenticate traffic.
              </li>
              <li>
                An intrusion detection system monitors and responds to potential
                network compromises.
              </li>
              <li>
                Network segmentation is implemented between corporate and
                hosting facilities with multiple access layers.
              </li>
            </ol>
          </div>
          <p className="mb-2">
            <strong>6.3. Malicious Code Protection</strong>
          </p>
          <div className="mb-4 py-2 px-4">
            <ol style={{ listStyleType: "disc" }}>
              <li>
                Mpal Sol. Pvt. Ltd runs industry-standard antivirus/anti-malware
                software on all workstations and servers, with updates applied
                promptly.
              </li>
              <li>
                Malicious code is scanned on all gateways to public networks.
              </li>
            </ol>
          </div>
          <p className="mb-2">
            <strong>6.4. Data Loss Prevention</strong>
          </p>
          <p className="mb-4">
            Mpal Sol. Pvt. Ltd employs a comprehensive system to prevent Data
            compromise.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            7. Software Development and Maintenance
          </h4>
          <p className="mb-2">
            <strong>7.1. Open Source</strong>
          </p>
          <p className="mb-4">
            Mpal Sol. Pvt. Ltd evaluates vulnerabilities of open-source software
            and performs code analysis, security verifications, and penetration
            testing.
          </p>
          <p className="mb-2">
            <strong>7.2. Change Management</strong>
          </p>
          <p className="mb-4">
            Mpal Sol. Pvt. Ltd uses a documented change management program for
            development and testing.
          </p>
          <p className="mb-2">
            <strong>
              7.3. Vulnerability Management and Application Security Assessments
            </strong>
          </p>
          <p className="mb-4">
            Mpal Sol. Pvt. Ltd conducts application security assessments through
            qualified third parties or directly.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            8. Storage, Handling, and Disposal
          </h4>
          <p className="mb-2">
            <strong>8.1. Data Segregation</strong>
          </p>
          <p className="mb-4">
            Mpal Sol. Pvt. Ltd segregates Personal Data and End User Data from
            other customer data.
          </p>
          <p className="mb-2">
            <strong>8.2. Encryption of Electronic Form Data</strong>
          </p>
          <p className="mb-4">
            Mpal Sol. Pvt. Ltd uses industry-standard encryption algorithms
            (e.g., AES 256-bit, TLS v1.2) for data in transit.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            9. Business Continuity and Disaster Recovery
          </h4>
          <p className="mb-2">
            <strong>9.1. Business Continuity Management Program</strong>
          </p>
          <div className="mb-4 py-2 px-4">
            <ol style={{ listStyleType: "disc" }}>
              <li>
                Includes Business Impact Analysis, Crisis Management, Business
                Continuity, and Disaster Recovery planning.
              </li>
              <li>Each plan is reviewed and exercised annually.</li>
            </ol>
          </div>

          <p className="mb-2">
            <strong>9.2. Plan Content</strong>
          </p>
          <p className="mb-4">
            Mpal Sol. Pvt. Ltd’s plans address continuous operation and recovery
            of functions required to deliver Products, including systems,
            hardware, software, and personnel.
          </p>
        </section>
      </Content>
    </Layout>
  );
};

export default SecurityMeasures;
