import { combineReducers } from "redux";
import {
  uploadImageReducer,
  uploadImageFileReducer,
  resumeParserReducer,
  getJobDetailReducer,
  applyJobReducer,
  addClientReducer,
  getClientListReducer,
  getClientDetailsReducer,
  updateClientDetailsReducer,
  getAllCandidateReducer,
  getClientJobReducer,
  updateJobDetailReducer,
  addCandidateReducer,
  updateJobApplicationReducer,
  postFeedbackReducer,
  candidateProfileReducer,
  createJobReducer,
  getJobReducer,
  getJobApplicationReducer,
  enquiryReducer,
  getEnquiryReducer,
  updateEnquiryReducer,
  verifyOtpReducer,
  sendOtpReducer,
  verifyEmailReducer,
  updateCandidateReducer,
  matchingAlgoReducer,
  multipleActionReducer,
  getJobAllAppliedCandidateReducer,
  updateFeedbackReducer,
  getFeedbackListReducer,
  assignToRecruiterReducer,
  emailSubscribeReducer,
  getEmailTemplatesReducer,
  updateEmailTemplatesReducer
} from "./common.js";
import {
  loginUserReducer,
  signupUserReducer,
  getUserDetailReducer,
  updateUserDetailReducer,
  forgetPasswordLinkReducer,
  updatePasswordReducer,
  logoutUserReducer,
  getUserStatsReducer
} from "./user.js";

import {
  getInterviewerListReducer,
  getInterviewDetailsReducer,
  getAllScheduleInterviewListReducer,
  scheduleInterviewReducer,
  interviewFeedbackReducer,
  getInterviewerReportReducer,
  updateInterviewDetailsReducer,
  getInterviewCalendarReducer
} from "./interviewer.js";
import {
  getClientReportReducer,
  getTeamReportReducer,
  getTimeLineReportsReducer
} from "./reports&Activity.js";
import {
  createQuestionReducer,
  getAllQuestionListReducer,
  getQuestionDetailsReducer,
  updateQuestionReducer,
  createTestReducer,
  updateTestReducer,
  getTestDetailsReducer,
  getAllTestListReducer,
  createAssessmentReducer,
  updateAssessmentReducer,
  getAssessmentDetailsReducer,
  getAllAssessmentListReducer,
  getInviteAssessmentDetailsReducer,
  updateInviteAssessmentDetailsReducer,
  assessmentLoginReducer,
  getAllAssessmentCandidateReducer,
  getAssessmentCandidateDetailsReducer,
  inviteAssessmentReducer,
  getCandidateDetailsReducer,
  candidateAssessmentSubmissionReducer,
  candidateAssessmentFeedbackReducer,
  assessmentImageProctorReducer,
  getInvitationDetailReducer
} from "./assessment.js";
import {
  candidateLoginReducer,
  candidateStatsReportReducer,
  getCandidateDetailsDashboardReducer
} from "./candidate.js";
import {
  createClientRoleUserReducer,
  createRoleUserReducer,
  getAllClientRoleUserReducer,
  getAllRoleUserReducer,
  getRoleUSerDetailsReducer
} from "./Roles&Management.js";
const allReducers = combineReducers({
  uploadImageReducer,
  uploadImageFileReducer,
  resumeParserReducer,
  createJobReducer,
  getJobReducer,
  getJobDetailReducer,
  applyJobReducer,
  addClientReducer,
  getClientListReducer,
  getClientDetailsReducer,
  updateClientDetailsReducer,
  getAllCandidateReducer,
  getClientJobReducer,
  updateJobDetailReducer,
  candidateProfileReducer,
  signupUserReducer,
  loginUserReducer,
  getUserDetailReducer,
  updateUserDetailReducer,
  addCandidateReducer,
  updateJobApplicationReducer,
  createRoleUserReducer,
  getAllRoleUserReducer,
  postFeedbackReducer,
  getInterviewerListReducer,
  getInterviewDetailsReducer,
  getAllScheduleInterviewListReducer,
  scheduleInterviewReducer,
  getJobApplicationReducer,
  interviewFeedbackReducer,
  forgetPasswordLinkReducer,
  updatePasswordReducer,
  enquiryReducer,
  getInterviewerReportReducer,
  updateInterviewDetailsReducer,
  getEnquiryReducer,
  updateEnquiryReducer,
  verifyOtpReducer,
  sendOtpReducer,
  verifyEmailReducer,
  getClientReportReducer,
  updateCandidateReducer,
  getTeamReportReducer,
  matchingAlgoReducer,
  createQuestionReducer,
  updateQuestionReducer,
  getQuestionDetailsReducer,
  getAllQuestionListReducer,
  createTestReducer,
  updateTestReducer,
  getTestDetailsReducer,
  getAllTestListReducer,
  createAssessmentReducer,
  updateAssessmentReducer,
  getAssessmentDetailsReducer,
  getAllAssessmentListReducer,
  assessmentLoginReducer,
  getInviteAssessmentDetailsReducer,
  updateInviteAssessmentDetailsReducer,
  getAllAssessmentCandidateReducer,
  getAssessmentCandidateDetailsReducer,
  inviteAssessmentReducer,
  getCandidateDetailsReducer,
  candidateAssessmentSubmissionReducer,
  candidateAssessmentFeedbackReducer,
  assessmentImageProctorReducer,
  candidateLoginReducer,
  candidateStatsReportReducer,
  multipleActionReducer,
  getRoleUSerDetailsReducer,
  createClientRoleUserReducer,
  getAllClientRoleUserReducer,
  getCandidateDetailsDashboardReducer,
  getInvitationDetailReducer,
  getJobAllAppliedCandidateReducer,
  updateFeedbackReducer,
  getFeedbackListReducer,
  assignToRecruiterReducer,
  emailSubscribeReducer,
  getTimeLineReportsReducer,
  getEmailTemplatesReducer,
  updateEmailTemplatesReducer,
  getInterviewCalendarReducer,
  logoutUserReducer,
  getUserStatsReducer,
});

const rootReducer = (state, action) => {
  return allReducers(state, action);
};

export default rootReducer;
