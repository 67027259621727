import React from "react";
import { useNavigate } from "react-router-dom";
import TimeAgo from '../../../Utils/TimeAgo'

import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

export const Cards = ({ candidates }) => {
  const navigate = useNavigate();
  const getJobDuration = (startDate, endDate, current) => {
    let duration = "";
    if (current) {
      duration = "Present";
    } else {
      const startYear = new Date(startDate).getFullYear();
      const endYear = endDate ? new Date(endDate).getFullYear() : "Present";
      duration = `${startYear} - ${endYear}`;
    }
    return duration;
  };
  return (
    <>
      {candidates && candidates.length > 0 ? (
        <>
          {candidates.map((item, index) => (
            <div className="candidate-db-2cL-8" key={index}>
              <a
                className="candidate-db-AN51q"
                onClick={() =>
                  navigate(`/dashboard/candidate-profile/${item?.candidate_id}`)
                }
              >
                <div className="candidate-db-1Y6Z5">
                  <div className="candidate-db-2vzSs">
                    <div className="candidate-db-1pcY0">
                      <div className="candidate-db-26Qlt">
                        <div className="candidate-db-SxGQz candidate-db-2xUF0">
                          <div
                            className="candidate-db-QXE0e"
                            style={{ width: 50, height: 50 }}
                          >
                            {item?.photo && item?.photo !== "" ? (
                              <Avatar
                                size={{
                                  md: 40,
                                  lg: 55,
                                  xl: 64,
                                  xxl: 64
                                }}
                                alt={`${item?.first_name}${item?.last_name} candidate Image`}
                                src={`${process.env.REACT_APP_IMAGES_BASE_URL}${item?.photo}`}
                              />
                            ) : (
                              <Avatar
                                size={{
                                  md: 40,
                                  lg: 55,
                                  xl: 64,
                                  xxl: 64
                                }}
                                icon={<UserOutlined />}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="candidate-db-278V9">
                      <div className="candidate-db-3J47Q">
                        <div className="candidate-db-2OjsT">
                          <h3 className="candidate-db-2DAow candidate-db-zhCrk  candidate-db-QTMDv">
                            <strong className="candidate-db-2kqW6">
                              {item?.firstName} {item?.lastName}
                            </strong>
                          </h3>
                        </div>
                      </div>
                      <div className="candidate-db-2SkHf">
                        {item?.experience && item?.experience.length > 0
                          ? item?.experience.map((exp, expIndex) => (
                              <div
                                className="candidate-db-1bUeK candidate-db-f-uLT"
                                key={expIndex}
                              >
                                <div className="candidate-db-z8GN7 candidate-db-f-uLT">
                                  {exp?.title} at {exp?.company}
                                </div>
                                <span className="candidate-db-3bO_2">
                                  {getJobDuration(
                                    exp?.startDate,
                                    exp?.endDate,
                                    exp?.currentlyWorking
                                  )}
                                </span>
                              </div>
                            ))
                          : item?.address && (
                              <div className="candidate-db-1bUeK candidate-db-f-uLT">
                                <div className="candidate-db-z8GN7 candidate-db-f-uLT">
                                  {item?.address}
                                </div>
                              </div>
                            )}

                        {item?.education && item?.education.length > 0
                          ? item?.education.map((edu, eduIndex) => (
                              <div
                                className="candidate-db-1bUeK candidate-db-f-uLT"
                                key={eduIndex}
                              >
                                <div className="candidate-db-z8GN7 candidate-db-f-uLT">
                                  {edu?.degree} at {edu?.school}
                                </div>
                                <span className="candidate-db-3bO_2">
                                  {getJobDuration(edu?.startDate, edu?.endDate)}
                                </span>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="candidate-db-3sKmM" data-ui="job-status">
                    <div className="candidate-db-31TM3">
                      <span className="candidate-db-sCkbE candidate-db-QTMDv">
                        <strong className="candidate-db-2kqW6">
                          {item?.headline}
                        </strong>
                      </span>
                    </div>
                    <div className="candidate-db-3M1FQ candidate-db-f-uLT">
                      <span
                        className="candidate-db-1wYTM"
                        data-ui="department"
                      />
                      <span className="candidate-db-w3_Qw" data-ui="location">
                        {item?.address}
                      </span>
                    </div>

                    <div className="candidate-db-3xiKa">
                      <div className="candidate-db-kaZLM">
                        <div className="candidate-db-3VVrr candidate-db-2w6DO candidate-db-f-uLT">
                          At&nbsp;
                          <span
                            className="candidate-db-3VVrr candidate-db-Iu6Xh candidate-db-QTMDv"
                            data-ui="stage"
                          >
                            <strong className="candidate-db-2kqW6">
                              {item?.job}
                            </strong>
                          </span>
                          &nbsp;{item?.status}
                        </div>
                      </div>

                      <div className="candidate-db-3VVrr candidate-db-aQjm0 candidate-db-f-uLT">
                        <span
                          className="candidate-db-3VVrr candidate-db-cJLj_ candidate-db-QTMDv"
                          data-ui="source"
                        >
                          <div>
                            via <strong>profile upload</strong>
                          </div>
                        </span>
                        &nbsp;·&nbsp;
                        <span
                          data-ui="created-at"
                          aria-describedby="cCBrd9k0hCVgB4FL"
                        >
                          <TimeAgo timestamp={item?.createdAt} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};
