import React, { useEffect, useState } from "react";
import {
  Layout,
  Select,
  Button,
  Input,
  Row,
  Col,
  Form,
  Card,
  Avatar,
  message,
  Table
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCandidateAction,
  getJobAction,
  matchingAlgoAction,
  updateJobDetailAction
} from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import { UserOutlined } from "@ant-design/icons";
import ImageUploadComponent from "../Upload";

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const MatchMasterAI = () => {
  const dispatch = useDispatch();
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jsonResult, setJsonResult] = useState(true);
  const [perform_task, setPerform_task] = useState(true);
  const [selectedJob, setSelectedJob] = useState(null);
  const [form] = Form.useForm();
  const [jobList, setJobList] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [promptValue, setPromptValue] = useState("");

  useEffect(() => {
    dispatch(getJobAction({ pageSize: 1000, page: 1 }));
    dispatch(
      getAllCandidateAction({ firstName: "all", pageSize: 1000, page: 1 })
    );
  }, [dispatch]);

  const getJobReducer = useSelector((state) => state.getJobReducer);
  const getAllCandidateReducer = useSelector(
    (state) => state.getAllCandidateReducer
  );
  const matchingAlgoReducer = useSelector((state) => state.matchingAlgoReducer);

  useEffect(() => {
    const { data, error, loading, status } = getAllCandidateReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setCandidateList(data?.data);
    }
    if (!data && !loading && error) {
      console.warn("Something went wrong while fetching candidates");
      setCandidateList([]);
    }
  }, [getAllCandidateReducer]);

  useEffect(() => {
    const { loading, status, error, data } = getJobReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setJobList(data?.data);
    }
    if (!loading && !data && error) {
      console.warn("Error fetching jobs", error);
    }
  }, [getJobReducer]);

  useEffect(() => {
    setSelectedJob(jobList.find((job) => job?.job?.job_id === selectedJobId));
    if (selectedJob && selectedJob?.job?.prompt) {
      setPromptValue(selectedJob.job.prompt);
    } else {
      setPromptValue("");
    }
  }, [selectedJobId, selectedJob]);

  const handleUpdatePrompt = () => {
    if (selectedJob && selectedJob.job && promptValue) {
      dispatch(
        updateJobDetailAction({
          job_id: selectedJob.job.job_id,
          prompts: promptValue
        })
      );
    }
  };

  const handleClearPrompt = () => {
    setPromptValue("");
  };

  const handleSubmit = (values) => {
    const data = {
      s3_url: values?.resume_src,
      prompt: promptValue,
      perform_task_1: perform_task
    };
    dispatch(
      matchingAlgoAction({
        s3_url: values?.resume_src,
        prompt: promptValue,
        perform_task_1: perform_task
      })
    );
  };

  const validateCandidateOrResume = (_, value) => {
    const resume_src = form.getFieldValue("resume_src");
    if (!resume_src) {
      return Promise.reject(
        new Error("Please select a candidate or upload a resume!")
      );
    }
    return Promise.resolve();
  };

  const updateJobDetailReducer = useSelector(
    (state) => state.updateJobDetailReducer
  );
  useEffect(() => {
    const { data, loading, status, error, request } = updateJobDetailReducer;
    setLoading(loading);
    if (!loading) {
      if (data && status == 200) {
        message.success("Prompt updated successfully");
      }
      if (error && [400, 404, 500].includes(status)) {
        message.error(error);
      }
    }
  }, [updateJobDetailReducer]);

  useEffect(() => {
    const { loading, status, error, data } = matchingAlgoReducer;
    if (!loading && data && !error) {
      setJsonResult(data);
      message.success("matchingAlgoReducer success");
    }
    if (!loading && !data && error) {
      message.error(error);
    }
  }, [matchingAlgoReducer]);

  const resultAction = (type) => {
    console.log(type);
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <div className="main-header" data-ui="header">
            <div className="container px-4">
              <div className="flag">
                <div className="flag__section">
                  <a className="external incognito-link inline-block mb-m mt-m">
                    <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                      Master Match AI
                    </h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Layout style={{ minHeight: "100vh" }}>
            <Content style={{ padding: "24px" }}>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <div className="match-master">
                    <Form form={form} layout="vertical" onFinish={handleSubmit}>
                      <Form.Item
                        label="Select Candidate or Upload Resume"
                        name="resume_src"
                        rules={[{ validator: validateCandidateOrResume }]}
                      >
                        {/* Candidate selection */}
                        <div className="highlight-box">
                          <Select
                            placeholder="Select Candidate"
                            style={{ width: "100%", marginBottom: 16 }}
                            optionLabelProp="label"
                            onChange={(value) => {
                              form.setFieldsValue({
                                resume_src: value
                              });
                              setPerform_task(false);
                            }}
                          >
                            {candidateList?.map((candidate) => (
                              <Option
                                key={candidate.candidate_id}
                                value={`${process.env.REACT_APP_BUCKET_URL}${candidate?.resume}`}
                                label={`${candidate.firstName} ${candidate.lastName}`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  {candidate?.photo &&
                                  candidate?.photo !== "" ? (
                                    <Avatar
                                      style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: "50%",
                                        marginRight: 10
                                      }}
                                      src={`${process.env.REACT_APP_IMAGES_BASE_URL}${candidate?.photo}`}
                                      alt={`${candidate?.photo} Profile Icon`}
                                    />
                                  ) : (
                                    <Avatar
                                      style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: "50%",
                                        marginRight: 10
                                      }}
                                      icon={<UserOutlined />}
                                    />
                                  )}
                                  <div>
                                    <strong>
                                      {candidate.firstName} {candidate.lastName}
                                    </strong>
                                    <div>{candidate.email}</div>
                                  </div>
                                </div>
                              </Option>
                            ))}
                          </Select>
                          <div
                            style={{ textAlign: "center", marginBottom: 16 }}
                          >
                            <p>or</p>
                            <ImageUploadComponent
                              uploadPath="hire_talent/resume/"
                              acceptedFileTypes="application/pdf, .pdf"
                              uploadType="PDF Resume"
                              onFileUpload={(profileImageUrl) => {
                                form.setFieldsValue({
                                  resume_src: `${process.env.REACT_APP_BUCKET_URL}${profileImageUrl}`
                                });
                                setPerform_task(true);
                              }}
                            />
                          </div>
                        </div>
                      </Form.Item>
                    </Form>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="right-content">
                    <Form form={form} layout="vertical" onFinish={handleSubmit}>
                      <Form.Item
                        label="Select Job"
                        name="job_id"
                        rules={[
                          { required: true, message: "Please select a job!" }
                        ]}
                      >
                        <Select
                          placeholder="Select Job"
                          style={{ width: "100%", marginBottom: 16 }}
                          onChange={(value) => setSelectedJobId(value)}
                        >
                          {jobList?.map((job) => (
                            <Option
                              key={job?.job?.job_id}
                              value={job?.job?.job_id}
                            >
                              {job?.job?.job_title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {selectedJob && (
                        <Card
                          className="mb-3"
                          key={selectedJob?.job?.job_id}
                          title={
                            <h5 className="text-primary">
                              {selectedJob?.job?.job_title}
                            </h5>
                          }
                          bordered={false}
                          style={{
                            borderRadius: "15px",
                            border: "1px solid #e0e0e0",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                          }}
                        >
                          <p className="mb-2">
                            <strong>Location:</strong>{" "}
                            {selectedJob?.job?.job_location}
                          </p>
                          <p className="mb-2">
                            <strong>Application Deadline:</strong>{" "}
                            {new Date(
                              selectedJob?.job?.application_deadline
                            ).toLocaleDateString()}
                          </p>
                          <p className="mb-2">
                            <strong>Salary:</strong>{" "}
                            {selectedJob?.job?.min_salary} -{" "}
                            {selectedJob?.job?.max_salary}{" "}
                            {selectedJob?.job?.currency}{" "}
                            {selectedJob?.job?.salary_tenure}
                          </p>
                          <p className="mb-2">
                            <strong>Experience:</strong>{" "}
                            {selectedJob?.job?.experienced}
                          </p>
                        </Card>
                      )}
                      <Form.Item
                        label="Prompts"
                        name="prompt"
                        rules={[
                          {
                            required: promptValue ? false : true,
                            message: "Please enter a prompt!"
                          }
                        ]}
                      >
                        <TextArea
                          onChange={(e) => setPromptValue(e.target.value)}
                          value={promptValue}
                          rows={4}
                          placeholder="Prompts"
                          style={{ marginBottom: 16, borderRadius: "15px" }}
                        />
                        <Button
                          type="primary"
                          onClick={handleUpdatePrompt}
                          style={{ marginRight: 8 }}
                          className="button--2de5X button--32xRL secondary--2ySVn main_border_radius "
                        >
                          Update
                        </Button>
                        <Button onClick={handleClearPrompt}>Clear</Button>
                      </Form.Item>
                      <Button
                        type="primary"
                        loading={matchingAlgoReducer?.loading}
                        htmlType="submit"
                        className="candidate-db-2de5X candidate-db-14TuV candidate-db-25RCR candidate-db-1VkQV main_border_radius"
                        block
                        size="large"
                        style={{ borderRadius: "15px" }}
                        disabled={!selectedJob || !promptValue}
                      >
                        Submit
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
              {jsonResult && (
                <Card
                  title={
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Result</span>
                      <div className="py-2">
                        <Button
                          type="button"
                          className="btn btn--primary mt-m text-white  main_border_radius me-2"
                          onClick={() => resultAction("apply_job")}
                        >
                          Apply for Job
                        </Button>
                        <Button
                          type="button"
                          className="btn btn--primary mt-m text-white  main_border_radius"
                          onClick={() => resultAction("add_candidate")}
                        >
                          Add Candidate
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <div className="match-result-model">
                    <div className="match-result-header">Header</div>
                    <div className="match-result-content">Content</div>
                    <div className="match-result-foot"></div>
                  </div>
                </Card>
              )}
            </Content>
          </Layout>
        </>
      )}
    </>
  );
};

export default MatchMasterAI;
