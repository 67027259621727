import React, { useEffect, useState } from "react";
import {
  Layout,
  Card,
  List,
  Button,
  Badge,
  Avatar,
  Row,
  Col,
  Typography,
  Grid,
  message,
  Tooltip
} from "antd";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  UserOutlined,
  EllipsisOutlined,
  RocketOutlined
} from "@ant-design/icons";
import {
  ClockCircleOutlined,
  HourglassOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserStatsAction } from "../../redux/actions/user";
import { TailSpin } from "react-loader-spinner";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import InterviewDetailsDrawer from "../InterviewScheduling/InterviewDetailsModal";
import { All_ROLES, statusColor } from "../../Assets/data/common";
import TrackingDrawer from "../InterviewScheduling/TrackingDrawer";
const { Content } = Layout;
const { useBreakpoint } = Grid;
const { Text } = Typography;
export const calculateRemainingTime = (todo) => {
  const date = moment.utc(todo?.date);
  const time = moment.utc(todo?.time);

  const targetMoment = date.clone().set({
    hour: time.hour(),
    minute: time.minute(),
    second: time.second(),
    millisecond: time.millisecond()
  });

  const now = moment.utc();

  if (!targetMoment.isValid() || targetMoment.isBefore(now)) {
    return (
      <Tooltip title="Time's up!">
        <Badge
          count="Time's up!"
          style={{ backgroundColor: "#f5222d", color: "#fff" }}
          icon={<ClockCircleOutlined />}
        />
      </Tooltip>
    );
  }

  // Calculate the remaining time
  const diffInMilliseconds = targetMoment.diff(now);
  const duration = moment.duration(diffInMilliseconds);

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let timeRemaining = "";
  let icon = <ClockCircleOutlined />;
  let color = "#52c41a";
  if (days > 0) {
    timeRemaining = `${days} day${days > 1 ? "s" : ""} remaining`;
    icon = <FieldTimeOutlined />;
    color = "#faad14";
  } else if (hours > 0) {
    timeRemaining = `${hours} hour${hours > 1 ? "s" : ""} remaining`;
    icon = <HourglassOutlined />;
    color = "#1890ff";
  } else if (minutes > 0) {
    timeRemaining = `${minutes} minute${minutes > 1 ? "s" : ""} remaining`;
    icon = <ClockCircleOutlined />;
    color = "#1890ff";
  } else {
    timeRemaining = `${seconds} second${seconds > 1 ? "s" : ""} remaining`;
    icon = <ClockCircleOutlined />;
    color = "#f5222d";
  }

  return (
    <Tooltip title={timeRemaining}>
      <Badge
        count={timeRemaining}
        style={{ backgroundColor: color, color: "#fff" }}
        icon={icon}
      />
    </Tooltip>
  );
};

const DashboardHome = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    date: moment().format("YYYY-MM-DD")
  });
  const [openDrawer, setOpenDrawer] = useState({
    show: false,
    interview_id: "",
    active: ""
  });
  const [openTackingModal, setOpenTackingModal] = useState({
    show: false,
    interview_id: ""
  });
  const UserType = (user_type) => {
    const role = All_ROLES.find((option) => option.value === user_type);
    return role ? role.label : user_type;
  };
  const getUserStatsReducer = useSelector((state) => state.getUserStatsReducer);
  const updateInterviewDetailsReducer = useSelector(
    (state) => state.updateInterviewDetailsReducer
  );
  useEffect(() => {
    dispatch(
      getUserStatsAction({
        ...filters
      })
    );
    return () => {
      dispatch({ type: "GET_USER_STATS_RESET" });
    };
  }, [dispatch, filters, updateInterviewDetailsReducer]);

  useEffect(() => {
    const { loading, status, error, data } = getUserStatsReducer;
    setloading(loading);
    if (!loading && data && !error) {
      setData(data);
    }
    if (!loading && !data && error) {
      message.error(error);
    }
  }, [getUserStatsReducer]);

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <Content style={{ padding: screens.xs ? "10px" : "20px 50px" }}>
          <div className="mb-2 bold">
            <h1>Hello {data?.userDetails?.name}!</h1>
          </div>
          <Row gutter={[16, 16]} justify="center">
            {data?.interviews && (
              <Col xs={24} >
                <Card
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
                    minHeight: "280px"
                  }}
                  title={
                    <div
                      className="py-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          icon={<CalendarOutlined />}
                          style={{
                            backgroundColor: "#f0f0f0",
                            marginRight: 8,
                            color: "#007bff"
                          }}
                        />
                        <h5 style={{ margin: 0 }}>
                          {moment(filters.date).format("dddd D MMMM YYYY")}
                        </h5>
                      </div>
                      <div>
                        <Button
                          type="text"
                          icon={<LeftOutlined />}
                          onClick={() => {
                            setFilters((prev) => ({
                              ...prev,
                              date: moment(prev.date)
                                .subtract(1, "days")
                                .format("YYYY-MM-DD")
                            }));
                          }}
                        />
                        <Button
                          type="text"
                          icon={<RightOutlined />}
                          onClick={() => {
                            setFilters((prev) => ({
                              ...prev,
                              date: moment(prev.date)
                                .add(1, "days")
                                .format("YYYY-MM-DD")
                            }));
                          }}
                        />
                      </div>
                    </div>
                  }
                >
                  {data?.interviews && data?.interviews.length > 0 ? (
                    <List
                      itemLayout="horizontal"
                      dataSource={data?.interviews.filter((interview) =>
                        moment(interview.startDateTime).isSame(
                          filters.date,
                          "day"
                        )
                      )}
                      style={{
                        maxHeight: "250px",
                        minHeight: "250px",
                        overflowY: "auto",
                       
                      }}
                      renderItem={(item) => (
                        <List.Item
                          className="px-2"
                          style={{
                            transition: "background-color 0.3s ease",
                            borderRadius: "10px"
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#f0f0f0";
                            e.currentTarget.style.cursor = "pointer";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "";
                          }}
                        >
                          <List.Item.Meta
                            onClick={() =>
                              setOpenDrawer({
                                show: true,
                                interview_id: item?.interview_id,
                                active: "tracking"
                              })
                            }
                            title={
                              <Row
                                style={{ width: "1500px", 
                                  display: "flex",
                                  flexWrap: "nowrap", }}
                                gutter={16}
                              >
                                <Col span={3}>
                                  <Text
                                    strong
                                    style={{
                                      fontSize: "1rem",
                                      cursor: "pointer"
                                    }}
                                    onClick={() =>
                                      setOpenDrawer({
                                        show: true,
                                        interview_id: item?.interview_id,
                                        active: "tracking"
                                      })
                                    }
                                  >
                                    {moment(item?.startDateTime)
                                      .local()
                                      .format("HH:mm")}
                                  </Text>
                                  <br />
                                  <Text type="secondary">
                                    {moment(item?.endDateTime)
                                      .local()
                                      .format("HH:mm")}
                                  </Text>
                                </Col>
                                <Col
                                  span={8}
                                  >
                                  
                                  <Text strong>Interview</Text>
                                  <br />
                                  <Text
                                    className="text-decoration-underline cursor-pointer"
                                    style={{
                                      cursor: "pointer",
                                      color: "#005c53"
                                    }}
                                  >
                                    {item?.summary}
                                  </Text>
                                </Col>
                                <Col
                                  span={11}
                                  style={{
                                    display: "flex",
                                    
                                    alignItems: "center"
                                  }}
                                >
                                  <div style={{ display: "flex", gap: "8px" }}>
                                    <Tooltip title="Interview Status">
                                      <Text strong>Status</Text>
                                      <br />
                                      <Badge
                                        count={
                                          <span
                                            style={{
                                              backgroundColor:
                                                statusColor[item.status]
                                                  ?.backgroundColor,
                                              color:
                                                statusColor[item.status]?.color,
                                              borderRadius: "18px",
                                              padding: "0 9px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {item?.status?.toUpperCase()}
                                          </span>
                                        }
                                      />
                                    </Tooltip>
                                    <span className="px-2" />
                                    <Tooltip title="On Track Morning Status">
                                      <Text strong>
                                        On Track Morning Status
                                      </Text>
                                      <br />
                                      <Badge
                                        count={
                                          <span
                                            style={{
                                              backgroundColor:
                                                statusColor[
                                                  item.tracking
                                                    ?.initialConfirmationStatus
                                                ]?.backgroundColor,
                                              color:
                                                statusColor[
                                                  item.tracking
                                                    ?.initialConfirmationStatus
                                                ]?.color,
                                              borderRadius: "18px",
                                              padding: "0 9px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {item.tracking?.initialConfirmationStatus?.toUpperCase()}
                                          </span>
                                        }
                                      />
                                    </Tooltip>
                                    <span className="px-2" />
                                    <Tooltip title="On Track status before 30 minutes">
                                      <Text strong>
                                        On Track status before 30 minutes
                                      </Text>
                                      <br />
                                      <Badge
                                        count={
                                          <span
                                            style={{
                                              backgroundColor:
                                                statusColor[
                                                  item.tracking
                                                    ?.finalConfirmationStatus
                                                ]?.backgroundColor,
                                              color:
                                                statusColor[
                                                  item.tracking
                                                    ?.finalConfirmationStatus
                                                ]?.color,
                                              borderRadius: "18px",
                                              padding: "0 9px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {item.tracking?.finalConfirmationStatus?.toUpperCase()}
                                          </span>
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                </Col>
                              </Row>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  ) : (
                    <div style={{ textAlign: "center", padding: "60px 0" }}>
                      <CheckCircleOutlined
                        style={{
                          fontSize: "48px",
                          color: "#52c41a",
                          marginBottom: "16px"
                        }}
                      />
                      <h3>No events</h3>
                    </div>
                  )}
                </Card>
              </Col>
            )}
            {data?.pastInterviews && (
              <InterviewList
                title="Past Interviews"
                icon={<CheckCircleOutlined />}
                data={data?.pastInterviews}
                setOpenDrawer={setOpenDrawer}
              />
            )}
            {data?.followUps && (
              <Col xs={24} md={12} lg={8} xl={7}>
                <Card 
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        icon={<CheckCircleOutlined />}
                        style={{
                          backgroundColor: "#f0f0f0",
                          marginRight: 8,
                          color: "#007bff"
                        }}
                      />
                      <h5 style={{ margin: 0 }}>To-dos</h5>
                    </div>
                  }
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
                    minHeight: "280px"
                  }}
                >
                  {data?.followUps?.length > 0 ? (
                    <List
                      style={{
                        maxHeight: "350px",
                        minHeight: "350px",
                        overflowY: "auto",
                        
                      }}
                      itemLayout="horizontal"
                      dataSource={data.followUps}
                      renderItem={(todo) => (
                        <List.Item
                          className="px-2"
                          style={{
                            padding: "12px 16px",
                            transition: "background-color 0.3s ease",
                            borderRadius: "10px",
                            marginBottom: "8px",
                            border: "1px solid #f0f0f0"
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#f9f9f9";
                            e.currentTarget.style.cursor = "pointer";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "";
                          }}
                        >
                          <List.Item.Meta
                            onClick={() => {
                              navigate(`/dashboard/job-detail/${todo?.job_id}`);
                            }}
                            title={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}
                              >
                                <div>
                                  <strong>{todo?.job_title}</strong>
                                  <Badge
                                    count={todo?.reminder_status?.toUpperCase()}
                                    style={{
                                      backgroundColor:
                                        statusColor[todo.status]
                                          ?.backgroundColor,
                                      color: statusColor[todo.status]?.color,
                                      borderRadius: "12px",
                                      padding: "0 9px",
                                      marginLeft: "8px"
                                    }}
                                  />
                                </div>
                                <span>{calculateRemainingTime(todo)}</span>
                              </div>
                            }
                            description={
                              <div className="d-flex align-items-center">
                                <Avatar
                                  src={
                                    todo?.photo
                                      ? `${process.env.REACT_APP_IMAGES_BASE_URL}${todo?.photo}`
                                      : undefined
                                  }
                                  icon={!todo?.photo ? <UserOutlined /> : null}
                                  alt={todo?.name || "User"}
                                  style={{ marginRight: 8 }}
                                />
                                <div>
                                  <a>{` ${todo?.name}`}</a>
                                  <p style={{ margin: 0 }}>
                                    {todo?.reminder_text}
                                  </p>
                                </div>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  ) : (
                    <div style={{ textAlign: "center", padding: "60px 0" }}>
                      <CheckCircleOutlined
                        style={{
                          fontSize: "48px",
                          color: "#52c41a",
                          marginBottom: "16px"
                        }}
                      />
                      <h3>No To-dos</h3>
                    </div>
                  )}
                </Card>
              </Col>
            )}

            {data?.jobs && (
              <Col xs={24}>
                <Card
                  title={
                    <h5>
                      <Avatar
                        icon={<UserOutlined />}
                        style={{
                          backgroundColor: "#f0f0f0",
                          marginRight: 8,
                          color: "#007bff"
                        }}
                      />{" "}
                      Jobs
                    </h5>
                  }
                  extra={
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        navigate("/dashboard/create-job");
                      }}
                      style={{
                        backgroundColor: "#005c53",
                        borderRadius: "5px"
                      }}
                    >
                      Create job
                    </Button>
                  }
                  style={{
                    borderRadius: "10px",
                    marginBottom: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)"
                  }}
                >
                  <List
                    style={{
                      maxHeight: "450px",
                      minHeight: "450px",
                      overflowY: "auto"
                    }}
                    itemLayout="horizontal"
                    dataSource={data?.jobs}
                    renderItem={(job) => (
                      <List.Item
                        className="px-2"
                        style={{
                          transition: "background-color 0.3s ease",
                          borderRadius: "10px"
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "#f0f0f0";
                          e.currentTarget.style.cursor = "pointer";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "";
                        }}
                      >
                        <List.Item.Meta
                          onClick={() => {
                            navigate(`/dashboard/job-detail/${job?.job_id}`);
                          }}
                          title={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                              }}
                            >
                              <span>{job.job_title}</span>
                              <div style={{ marginRight: "10%" }}>
                                <Badge
                                  style={{
                                    backgroundColor:
                                      statusColor[job.status]?.backgroundColor,
                                    color: statusColor[job.status]?.color,
                                    borderRadius: "18px",
                                    padding: "0 9px"
                                  }}
                                  count={job.status.toUpperCase()}
                                />
                              </div>
                            </div>
                          }
                          description={job.job_location}
                        />
                        <div>
                          <UserOutlined /> {job.totalCandidates} Candidates
                        </div>
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            )}

            {data?.clients && (
              <>
                <Col xs={24}>
                  <Card
                    title={
                      <h5>
                        <Avatar
                          icon={<RocketOutlined />}
                          style={{
                            backgroundColor: "#f0f0f0",
                            marginRight: 8,
                            color: "#007bff"
                          }}
                        />{" "}
                        Onboard new client
                      </h5>
                    }
                    extra={
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          navigate("/dashboard/create-client");
                        }}
                        style={{
                          backgroundColor: "#005c53",
                          borderRadius: "5px"
                        }}
                      >
                        Create Client
                      </Button>
                    }
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)"
                    }}
                  >
                    <List
                      style={{
                        maxHeight: "450px",
                        minHeight: "450px",
                        overflowY: "auto"
                      }}
                      itemLayout="horizontal"
                      dataSource={data?.clients}
                      header={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px 20px",
                            backgroundColor: "#f5f5f5",
                            borderRadius: "10px"
                          }}
                        >
                          <strong>Client</strong>
                          <strong style={{ paddingRight: "9%" }}>
                            Start date
                          </strong>
                        </div>
                      }
                      renderItem={(client) => (
                        <List.Item
                          className="px-2"
                          style={{
                            transition: "background-color 0.3s ease",
                            borderRadius: "10px"
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#f0f0f0";
                            e.currentTarget.style.cursor = "pointer";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "";
                          }}
                        >
                          <List.Item.Meta
                            onClick={() => {
                              navigate(
                                `/dashboard/client-dashboard/details?client_id=${client?.client_id}`
                              );
                            }}
                            avatar={
                              <Avatar
                                src={
                                  client?.company_photo
                                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${client?.company_photo}`
                                    : undefined
                                }
                                icon={
                                  !client?.company_photo ? (
                                    <UserOutlined />
                                  ) : null
                                }
                                alt={client?.company_name || "Clients"}
                                style={{ marginRight: 8 }}
                              />
                            }
                            title={client.company_name}
                            description={client.company_location}
                          />
                          <div>
                            {moment
                              .utc(client.createdAt)
                              .local()
                              .format("MMM D, YYYY, HH:mm")}
                          </div>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </>
            )}
            {data?.fetchHiredCandidate && (
              <>
                <Col xs={24}>
                  <Card
                    title={
                      <h5>
                        <Avatar
                          icon={<RocketOutlined />}
                          style={{
                            backgroundColor: "#f0f0f0",
                            marginRight: 8,
                            color: "#007bff"
                          }}
                        />{" "}
                        New Joinee
                      </h5>
                    }

                  >
                    <List
                      style={{
                        maxHeight: "450px",
                        minHeight: "450px",
                        overflowY: "auto"
                      }}
                      itemLayout="horizontal"
                      dataSource={data?.fetchHiredCandidate}

                      renderItem={(item) => (
                        <List.Item
                          className="px-2"
                          style={{
                            transition: "background-color 0.3s ease",
                            borderRadius: "10px"
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#f0f0f0";
                            e.currentTarget.style.cursor = "pointer";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "";
                          }}
                        >
                          <List.Item.Meta
                            onClick={() => {
                              navigate(
                                `/dashboard/job-detail/${item?.job_id}`
                              );
                            }}
                            avatar={
                              <Avatar
                                src={
                                  item?.photo
                                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${item?.photo}`
                                    : undefined
                                }
                                icon={
                                  !item?.photo ? (
                                    <UserOutlined />
                                  ) : null
                                }
                                alt={item?.name || "joine"}
                                style={{ marginRight: 8 }}
                              />
                            }
                            title={item.name}
                            description={item?.job_title}
                          />
                          <div>
                            {moment
                              .utc(item.updatedAt)
                              .local()
                              .format("MMM D, YYYY, HH:mm")}
                          </div>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </>
            )}
            <Col xs={24}>
              <Card
                title={
          <h5 style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              icon={<RocketOutlined />}
              style={{
                backgroundColor: "#f0f0f0",
                marginRight: 8,
                color: "#007bff"
              }}
            />
            Your Team Members
          </h5>
        }
        style={{
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)"
        }}
      >
        <List
          style={{
            maxHeight: "250px",
            minHeight: "250px",
            overflowY: "auto"
          }}
          itemLayout="horizontal"
          dataSource={data?.reportsTeam}
          renderItem={(team) => (
            <List.Item
              className="px-2"
              style={{
                transition: "background-color 0.3s ease",
                borderRadius: "10px",
                padding: "8px 16px"
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#f0f0f0";
                e.currentTarget.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "";
              }}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={
                      team?.photo
                        ? `${process.env.REACT_APP_IMAGES_BASE_URL}${team?.photo}`
                        : undefined
                    }
                    icon={!team?.photo ? <UserOutlined /> : null}
                    alt={team?.name || "Team"}
                    style={{ marginRight: 8 }}
                  />
                }
                title={team.name}
                description={team.email}
              />
              <div style={{width:"100px", paddingLeft:"5px"}}>{UserType(team?.user_type)}</div>
            </List.Item>
          )}
        />
      </Card>
    </Col>
          </Row>
        </Content>
      )}
      {openDrawer?.show && openDrawer?.interview_id && (
        <InterviewDetailsDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {openTackingModal?.show && openTackingModal?.interview_id && (
        <TrackingDrawer
          visible={openTackingModal}
          setVisible={setOpenTackingModal}
        />
      )}
    </Layout>
  );
};
const InterviewList = ({ title, icon, data, setOpenDrawer }) => (
  <Col xs={24} md={17}>
    <Card
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            icon={icon}
            style={{
              backgroundColor: "#f0f0f0",
              marginRight: 8,
              color: "#007bff",
            }}
          />
          <h5 style={{ margin: 0 }}>{title}</h5>
        </div>
      }
      style={{
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
        minHeight: "280px",
      }}
    >
      <List
        style={{ maxHeight: "350px", minHeight: "350px", overflowY: "auto" }}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            className="px-2"
            style={{
              transition: "background-color 0.3s ease",
              borderRadius: "10px",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#f0f0f0";
              e.currentTarget.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "";
            }}
          >
            <List.Item.Meta
              onClick={() =>
                setOpenDrawer({
                  show: true,
                  interview_id: item?.interview_id,
                  active: "feedback",
                })
              }
              title={
                <div >
                  <Row
                    style={{
                      width: "1024px", 
                      display: "flex",
                      flexWrap: "nowrap", 
                    }}
                    gutter={5}
                  >
                    <Col span={5}>
                      <Text strong style={{ fontSize: "1rem" }}>
                        {moment(item?.startDateTime).local().format("MMM D, YYYY")}
                      </Text>
                      <br />
                      <Text>
                        {moment(item?.startDateTime).local().format("HH:mm")}-
                        {moment(item?.endDateTime).local().format("HH:mm")}
                      </Text>
                    </Col>
                    <Col span={8}>
                      <Text strong>Interview</Text>
                      <br />
                      <Text
                        className="text-decoration-underline cursor-pointer"
                        style={{
                          cursor: "pointer",
                          color: "#005c53",
                        }}
                      >
                        {item?.summary}
                      </Text>
                    </Col>
                    <Col
                      span={11}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Interview Status">
                        <Text strong>Status</Text>
                        <br />
                        <Badge
                          count={
                            <span
                              style={{
                                backgroundColor: statusColor[item.status]?.backgroundColor,
                                color: statusColor[item.status]?.color,
                                borderRadius: "18px",
                                padding: "0 9px",
                                textAlign: "left",
                              }}
                            >
                              {item?.status?.toUpperCase()}
                            </span>
                          }
                        />
                      </Tooltip>
                      <span className="px-2" />
                      <Tooltip title="Recruiter Feedback Status">
                        <Text strong>Recruiter Feedback</Text>
                        <br />
                        <Badge
                          count={
                            <span
                              style={{
                                backgroundColor:
                                  statusColor[item.recruiterFeedbackStatus]?.backgroundColor,
                                color: statusColor[item.recruiterFeedbackStatus]?.color,
                                borderRadius: "18px",
                                padding: "0 9px",
                                textAlign: "left",
                              }}
                            >
                              {item?.recruiterFeedbackStatus?.toUpperCase()}
                            </span>
                          }
                        />
                      </Tooltip>
                      <span className="px-2" />
                      <Tooltip title="Candidate Feedback Status">
                        <Text strong>Candidate Feedback</Text>
                        <br />
                        <Badge
                          count={
                            <span
                              style={{
                                backgroundColor:
                                  statusColor[item.candidateFeedbackStatus]?.backgroundColor,
                                color: statusColor[item.candidateFeedbackStatus]?.color,
                                borderRadius: "18px",
                                padding: "0 9px",
                                textAlign: "left",
                              }}
                            >
                              {item?.candidateFeedbackStatus?.toUpperCase()}
                            </span>
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  </Col>
);

export default DashboardHome;
