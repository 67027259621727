import React, { useState } from "react";
import { Modal, Button } from "antd";

export default function AntiCheatingMeasuresModal({
  antiCheatingModal,
  setAntiCheatingModal
}) {
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setAntiCheatingModal(true);
        }}
      >
        Open Modal
      </Button>
      <Modal
        title="Anti-cheating measures"
        visible={antiCheatingModal}
        onOk={() => {
          setAntiCheatingModal(false);
        }}
        onCancel={() => {
          setAntiCheatingModal(false);
        }}
        footer={null}
        centered
        bodyStyle={{ padding: "20px", fontSize: "16px", lineHeight: "1.5" }}
        style={{ maxWidth: "600px" }}
      >
        <div style={{ fontWeight: "bold", marginBottom: "16px" }}>
          Device and location
        </div>
        <p>
          We register the candidate’s type of device used for the assessment, as
          well as the geographic location, based on their IP address.
        </p>

        <div style={{ fontWeight: "bold", marginBottom: "16px" }}>
          Filled out only once from IP address
        </div>
        <p>
          Using the IP address, we check if candidates fill out the assessment
          only once (with a public link to the assessment, candidates could use
          multiple email addresses to take repeated attempts at the assessment).
        </p>

        <div style={{ fontWeight: "bold", marginBottom: "16px" }}>
          Webcam/front camera enabled
        </div>
        <p>
          When candidates start their assessment, we ask them to activate their
          webcam/camera. This allows us to capture images of your candidates
          every 30 seconds. This way you can see if the same (and only one)
          person has worked on the assessment.
        </p>

        <div style={{ fontWeight: "bold", marginBottom: "16px" }}>
          Full-screen mode always active
        </div>
        <p>
          For candidates that use a desktop or laptop, we also activate
          full-screen mode to ensure candidates don’t browse the internet to
          look up answers. While we cannot prevent that candidates deactivate
          full-screen mode, we can detect if they did. It indicates a potential
          violation.
        </p>

        <div style={{ fontWeight: "bold", marginBottom: "16px" }}>
          Mouse always in assessment window
        </div>
        <p>
          We can detect if the mouse has always been on the test window.
          Candidates that have two screens could otherwise still have another
          window open to browse the internet.
        </p>
      </Modal>
    </>
  );
}
