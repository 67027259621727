import { masterKeywords, ProgrammingLanguage } from "../../Assets/data/common";
import { SUPPORTED_LANGUAGE } from "./Questions/CreateQuestions/data";

export const TagsAssessment = [
  ".NET",
  ".NET API",
  ".NET MVC",
  "AI-Intensive Data Centers",
  "API",
  "API Connectivity",
  "API Design",
  "API Development",
  "API Integration",
  "API Management",
  "API Publishing",
  "AUTOSAR Software Specification",
  "AWS",
  "AWS CLI",
  "AWS CloudFormation",
  "AWS Database Migration Service",
  "AWS Glue",
  "AWS KMS",
  "AWS Lambda",
  "AWS Security",
  "AWS Services",
  "Abstract Class",
  "Abstraction",
  "Accumulators",
  "ActiveStorage",
  "Advanced APIs",
  "Aggregation",
  "Aggregator Function",
  "Agile",
  "Algebra",
  "Algorithms",
  "Amazon S3",
  "Analytics Function",
  "Analytics for Group Reporting",
  "Android",
  "Android Libraries",
  "Angular",
  "Angular 10",
  "Angular Router",
  "AngularJS",
  "Annotations",
  "Ansible",
  "Apache",
  "Apex",
  "Apex Callouts",
  "Apex Triggers",
  "Apigee API Platform",
  "Appium",
  "Application Development",
  "Application Network",
  "Application Security",
  "Application security",
  "Apt",
  "Aptitude",
  "Arithmetic Operators",
  "Array",
  "Arrays",
  "Async Programming",
  "Asynchronous Apex",
  "Authentication",
  "Auto-scaling",
  "Automated Campaign Operations",
  "Automation",
  "Azure",
  "Azure Application Gateway",
  "Azure Blob Storage",
  "Azure CLI",
  "Azure Cosmos DB",
  "Azure Cost Management Service",
  "Azure Data Factory",
  "Azure Databricks",
  "Azure Front Door",
  "Azure Kubernetes Service",
  "Azure storage",
  "Back-End Development",
  "Background Processing",
  "Bar Graphs",
  "Bash",
  "Bash to Ansible",
  "Bigquery",
  "Binary Numbers",
  "Binary Search",
  "Binary Search Trees",
  "Binary Trees",
  "Bit Manipulation",
  "Bitwise Operations",
  "Blockchain",
  "Boomi Associate Master Data Hub",
  "Boomi AtomSphere platform",
  "Boomi Integration essentials",
  "Boundary Testing",
  "Box Model",
  "Breadth First Search",
  "Broadcast Variables",
  "Build Phase",
  "Build Sequence",
  "Burp suite",
  "C",
  "C#",
  "C++",
  "CNN",
  "CPCE Questions",
  "CRUD",
  "CSP",
  "CSS",
  "CSS colors",
  "CSS3",
  "CSS3 Selectors",
  "CSS3 Transforms",
  "CSV",
  "CTF",
  "Cache",
  "Calculations",
  "Change Data Capture",
  "Channels",
  "Chart Dependencies and Repositories",
  "Checkmarx",
  "Chef",
  "Cisco Security",
  "Class Components",
  "Classes",
  "Classification",
  "Closure",
  "Closures",
  "Cloud Compliance and Governance",
  "Cloud Dataflow",
  "Cloud Dataproc",
  "Cloud Load Balancer",
  "Cloud Run",
  "Cloud Security",
  "Cloud Storage Bucket",
  "Cloud-to-Device Messaging",
  "CloudFormation Template",
  "Coding",
  "Collections",
  "Combinatorics",
  "Combine Stage",
  "Comparison",
  "Complex Joins",
  "Component Interaction",
  "Component State Management",
  "Compression",
  "Compute Engine",
  "Computer Vision",
  "Concurrency",
  "Conditionals",
  "ConfigMap",
  "Constructors",
  "Container Platforms and Serverless Computing",
  "Content Filtering",
  "Control Flow",
  "Controlled Component",
  "Controller and Extensions",
  "Controls",
  "Core CS",
  "Costmanagement Export",
  "Couchbase",
  "Counta",
  "Cross Platform",
  "Cross-functional collaboration",
  "Cryptography",
  "Cryptology",
  "Cursor",
  "Cybersecurity",
  "DLT",
  "Dart",
  "Dashboards",
  "Data Analysis",
  "Data Binding",
  "Data Cleaning",
  "Data Cleaning and Preparation",
  "Data Compliance",
  "Data Engineering",
  "Data Frames",
  "Data Governance",
  "Data Integration",
  "Data Integration Logic",
  "Data Management",
  "Data Migration Service",
  "Data Modeling",
  "Data Optimization",
  "Data Patterns",
  "Data Pipeline",
  "Data Protection and Privacy",
  "Data Quality Dimensions",
  "Data Quality Processes",
  "Data Science",
  "Data Search",
  "Data Structures",
  "Data Validation",
  "Data Visualization",
  "Data Wrangling",
  "Data formatting",
  "Data transformation and orchestration",
  "Database",
  "Databricks",
  "Databricks Lakehouse Platform",
  "Datadog",
  "Dataverse",
  "Date",
  "Date math",
  "Date-time",
  "Decision table testing",
  "Deep Learning",
  "Delta Live Tables",
  "Delta Tables",
  "Dependency Injection",
  "Depth First Search",
  "Deserialization",
  "Design Patterns",
  "DevOps",
  "Device-to-Cloud Messaging",
  "Dialog",
  "Difference Array",
  "Disjoint Set Union",
  "Disjoint Sets",
  "Distributed Systems",
  "Django 3.0.6",
  "Django REST Framework",
  "Docker",
  "Docker CLI",
  "Docker Compose",
  "Docker Storage",
  "Dockerfile",
  "Dom Manipulation",
  "Dynamic",
  "Dynamic Memory Allocation",
  "Dynamic Programming",
  "Dynamics 365 F&O setup",
  "DynamoDB",
  "EC2",
  "ELK",
  "EMS Queues",
  "ES6",
  "ETL Operations",
  "Elastic Kubernetes Service",
  "Embedded Puppet",
  "Encryption",
  "English",
  "Entity Framework",
  "Enumeration",
  "Environment Variables",
  "Error Handling",
  "Ethereum",
  "Event Binding",
  "Event Hub",
  "Event System",
  "Event handling",
  "Excel",
  "Exception Handling",
  "Exec",
  "ExpressJS",
  "Expressroute Circuit",
  "Expressroute Gateway",
  "Facts",
  "Fallback Mechanism",
  "Fault Detection",
  "Fetch",
  "File",
  "File Handling",
  "File Manipulation",
  "File Validation",
  "File and Folder options",
  "FilteredRowSet",
  "Filtering",
  "Filtering Files",
  "Firmware",
  "Flask",
  "Flask 1.1.2",
  "Flood Fill",
  "Flowchart",
  "Flutter",
  "Forecasting",
  "Form Validation",
  "Forms",
  "Front end",
  "Front-End",
  "Front-End Development",
  "Front-End Frameworks",
  "Frontend",
  "Functional Interfaces",
  "Functions",
  "GCP",
  "GKE",
  "Game Theory",
  "Gateways",
  "Generative Adversarial Networks",
  "Generator Functions",
  "Generators",
  "Get_url",
  "Git",
  "Git Add",
  "Git Branch",
  "Git Commit",
  "Git Config",
  "Git Hook",
  "Git Push",
  "Go",
  "Google Kubernetes Engine",
  "Goroutines",
  "Grafana",
  "Graphs",
  "Greedy",
  "Greedy Algorithms",
  "HPA",
  "HTML",
  "HTML5",
  "HTTP",
  "HTTP Cookie",
  "Hadoop",
  "Handwriting",
  "Hash Map",
  "HashiCorp Configuration Language",
  "Hashing",
  "Healthcare Analytics",
  "Heaps",
  "Helm",
  "Hibernate",
  "High Availability",
  "Highstate",
  "Histogram",
  "Hooks",
  "Huffman Encoding",
  "Hyperledger",
  "Hyperledger Chaincode",
  "Hyperledger Channels",
  "IAM",
  "IP Protocols",
  "Identity and Access Management",
  "Implementation",
  "Inheritance",
  "Instance Groups",
  "Instance Management",
  "Integration Runtime",
  "Integrations and Agents",
  "Inter-Company Matching and Reconciliation",
  "Interfaces",
  "Intermediate",
  "Interviewer Guidelines",
  "Intruder",
  "IoT Hub",
  "Iterative Roadmap",
  "J2BD",
  "JDBC",
  "JSON",
  "JSP",
  "JUnit",
  "JWT",
  "Java",
  "Java 8",
  "JavaScript",
  "Javascript",
  "Javascript Aptitude",
  "Javascript Timers",
  "Jenkins",
  "Jenkins CI/CD",
  "Julia",
  "Kafka",
  "Kafka Topic",
  "Keras",
  "Keywords Scope",
  "Kotlin",
  "Kubernetes",
  "LDA Algorithm",
  "LFI",
  "LINQ",
  "LLMs",
  "LSTM",
  "Language Proficiency",
  "Laravel",
  "Lean principles",
  "Licensing Management",
  "Lightning",
  "Lightning Component",
  "Lightning Out",
  "Linear Search",
  "Linux",
  "Lists",
  "Logic",
  "Logs",
  "Loops",
  "MITRE ATT&CK Framework",
  "MVC Framework",
  "Machine Learning",
  "Manage instances",
  "Map",
  "Mapping and Transformations",
  "Maps",
  "Market analysis",
  "Marketing Process Automation",
  "Math",
  "Maven",
  "Media Queries",
  "Memory consumption",
  "Metadata",
  "Method overloading",
  "Methods",
  "Metrics and Tags",
  "Middleware",
  "Minions and Masters",
  "Mobile Development",
  "Mockito",
  "Modeling",
  "Modules",
  "MongoDB",
  "Multi-Cloud",
  "Multi-cluster architecture",
  "Multiclass Classification",
  "Multilabel Classification",
  "Multipart",
  "Multithreading",
  "MySQL",
  "N1QL",
  "NFS",
  "NLP",
  "NMAP",
  "Natural Language Understanding",
  "Nested For Loop",
  "Network Administration",
  "Network Analysis",
  "Network Policies",
  "Network Security",
  "Neural Networks",
  "Node ^12.18.3",
  "Node.js",
  "Number Theory",
  "OOPS",
  "OWASP Top 10",
  "Object Literal Manipulation",
  "Observables",
  "Operator Overloading",
  "Optimization",
  "Optimization of Spark",
  "Oracle",
  "Oracle APEX",
  "Orchestration",
  "Output",
  "Overloading",
  "PHP",
  "PL/SQL",
  "PThread",
  "Package",
  "Pandas",
  "Parallel Running",
  "Parser",
  "Pattern Recognition",
  "Payload",
  "Payments",
  "Penetration Testing",
  "Pivot",
  "Pointers",
  "Polymorphism",
  "Ports",
  "Power BI",
  "Power BI Desktop",
  "Power BI Embedded",
  "Power and Cooling",
  "PowerShell",
  "Prefix Sum",
  "Prefix Sums",
  "Preprocessing",
  "Priority Queues",
  "Probability",
  "Probability Distributions",
  "Problem Solving",
  "Problem-solving",
  "Process Monitoring and Management",
  "Programming",
  "PromQL",
  "Prometheus",
  "Promise",
  "Promises",
  "Props",
  "Protocols",
  "Puppet",
  "PyTorch",
  "Pyspark Operations",
  "Python",
  "Python 3",
  "Python 3.7",
  "Python 3.8",
  "QA",
  "Querying",
  "Queue",
  "R",
  "RBAC",
  "RDBMS",
  "REST API",
  "Rails",
  "Range",
  "Ratios",
  "React",
  "React Component",
  "React Component Lifecycle",
  "React Hooks",
  "React Native",
  "Reactors",
  "Real time Streaming",
  "Real-World",
  "Recursion",
  "References",
  "Reflection",
  "Regex",
  "Regression",
  "Render",
  "Rendering Elements",
  "Repeater",
  "Reporting",
  "Request",
  "Response",
  "Responsive Design",
  "Reverse Engineering",
  "Routing",
  "Ruby",
  "Ruby on Rails",
  "S3",
  "SAN",
  "SAP ABAP",
  "SAP HANA",
  "SAP SD",
  "SAP SuccessFactors",
  "SMB",
  "SOQL",
  "SQL",
  "SQL Injection",
  "SQLite",
  "SQS",
  "SSL Inspection",
  "Salesforce",
  "Salesforce AppExchange",
  "Salesforce Sales Cloud",
  "Salesforce Service Cloud",
  "Saltstack",
  "Scalability",
  "Scaling",
  "Scaling and Concurrency",
  "Schema Evolution",
  "Scikit-Learn",
  "Scripting",
  "Search",
  "Secret",
  "Secrets Manager",
  "Secure Coding Practices",
  "Secure Communications",
  "Secure Shell (SSH)",
  "Security",
  "Security Architecture",
  "Security Governance and Compliance",
  "Security Management",
  "Security Operations",
  "Segment Trees",
  "Selenium",
  "Selenium Webdriver",
  "Self-Hosted Integration Runtime",
  "Serialization",
  "Serializers",
  "Series",
  "Server-side Logic",
  "Serverless Computing",
  "Servlet",
  "Session hijacking",
  "Sets",
  "Setting up Elaticsearch and Kibana",
  "Shell Scripting",
  "Shortest Paths",
  "Simple Joins",
  "Sliding Window",
  "Solidity",
  "Sorting",
  "Spark",
  "Spark Optimization",
  "Spinner",
  "Spring",
  "Spring Boot",
  "Spring logging",
  "SqlAlchemy",
  "Stacks",
  "Starter Actuator",
  "State Management",
  "StatefulSet",
  "States and State Files",
  "Static Variables",
  "Statistics",
  "Steganography",
  "Storage",
  "Strategic leadership",
  "Strategic thinking",
  "Stream",
  "Streaming Data",
  "Strings",
  "Structures",
  "Sub-Queries",
  "Substitute",
  "Substitution Cypher",
  "Supply chain managment",
  "Swift",
  "SwiftUI",
  "Synchronous and Asynchronous Processing",
  "System Administration",
  "System Architecture",
  "System Design",
  "Systems Administration",
  "TDD",
  "TIBCO BW6",
  "TIBCO Deployment",
  "TIBCO EMS",
  "Tableau",
  "Talend CDC",
  "Talend Data Parallelization",
  "Template Validation",
  "Templates and Values",
  "TensorFlow",
  "Tensorflow",
  "Terraform",
  "Testing",
  "Testing Levels",
  "Text classification",
  "Theme: Automotive",
  "Theme: E-commerce",
  "Theme: Finance",
  "Theme: Healthcare",
  "Theme: Finance",
  "Throughput",
  "Time Complexity",
  "Time Series",
  "Topic modeling",
  "Traffic Inspection",
  "Traffic Management",
  "Transfer Acceleration",
  "Transfer Learning",
  "Transformation",
  "Trees",
  "Trigger",
  "Triggers",
  "Triggers and Integrations",
  "Two Pointers",
  "TypeORM",
  "TypeScript",
  "UIKit",
  "URL categorization",
  "Unified Modeling Language",
  "Uniform Resource Identifier",
  "Union",
  "Unit Testing",
  "User",
  "Using Cloud Run",
  "Variable creation",
  "Variables and Data Types",
  "Variables and Operators",
  "Views",
  "Virtual Nodes",
  "Virtual Private Cloud Connection",
  "Virtual Warehouse Extraction",
  "Virtualization",
  "Visualforce",
  "Visualforce Tags",
  "Vue.js",
  "Vulnerability Management",
  "Web Analytics",
  "Web Development",
  "Webservice in apex",
  "Window Function",
  "Windows PowerShell",
  "Wireless Security",
  "Word2Vec Algorithm",
  "Workflow Automation",
  "X11 Forwarding",
  "XCode",
  "XXE Attacks",
  "analytical skills",
  "analytics",
  "apply",
  "async await",
  "child process",
  "cmdlets",
  "custom validators",
  "decorators",
  "inclusion-exclusion",
  "ini File",
  "list-manipulation",
  "performance",
  "pipeline configuration",
  "product management",
  "pytorch",
  "variable-declaration",
  "virtual network",
  "vue"
];

export const skillsAssessment = [
  ".NET (Basic)",
  ".NET (Intermediate)",
  "Android (Advanced)",
  "Android (Basic)",
  "Android (Intermediate)",
  "Angular (Advanced)",
  "Angular (Basic)",
  "Angular (Intermediate)",
  "AngularJS (Advanced)",
  "AngularJS (Basic)",
  "AngularJS (Intermediate)",
  "Ansible (Advanced)",
  "Ansible (Basic)",
  "Ansible (Intermediate)",
  "Apache Spark (Basic)",
  "Apigee",
  "Appium",
  "Application Security",
  "Applied Math",
  "Aptitude",
  "AUTOSAR",
  "AWS (Advanced)",
  "AWS (Basic)",
  "AWS (Intermediate)",
  "AWS CloudFormation",
  "AWS Security",
  "Azure (Advanced)",
  "Azure (Basic)",
  "Azure (Intermediate)",
  "Bash (Basic)",
  "Bash (Intermediate)",
  "Boomi",
  "C (Advanced)",
  "C (Basic)",
  "C (Intermediate)",
  "C# (Advanced)",
  "C# (Basic)",
  "C# (Intermediate)",
  "C++ (Advanced)",
  "C++ (Basic)",
  "C++ (Intermediate)",
  "Chef (Basic)",
  "Cloud Computing",
  "Computer Vision (Advanced)",
  "Computer Vision (Basic)",
  "Computer Vision (Intermediate)",
  "Couchbase (Basic)",
  "Cryptography",
  "CSS",
  "Dart (Basic)",
  "Data Center",
  "Data Modeling",
  "Data Quality and Governance",
  "Data Visualization",
  "Data Wrangling",
  "Databricks (Advanced)",
  "Databricks (Basic)",
  "Databricks (Intermediate)",
  "Datadog",
  "DBMS (Basic)",
  "DBMS (Intermediate)",
  "Django (Advanced)",
  "Django (Basic)",
  "Django (Intermediate)",
  "Docker (Advanced)",
  "Docker (Basic)",
  "Docker (Intermediate)",
  "DynamoDB (Basic)",
  "DynamoDB (Intermediate)",
  "ELK",
  "Embedded C",
  "Excel",
  "Express.js (Intermediate)",
  "F&O",
  "Flask (Advanced)",
  "Flask (Basic)",
  "Flask (Intermediate)",
  "Flutter (Basic)",
  "Flutter (Intermediate)",
  "GCP (Advanced)",
  "GCP (Basic)",
  "GCP (Intermediate)",
  "Git (Advanced)",
  "Git (Basic)",
  "Git (Intermediate)",
  "Go (Advanced)",
  "Go (Basic)",
  "Go (Intermediate)",
  "Grafana (Basic)",
  "Grafana (Intermediate)",
  "Hadoop (Basic)",
  "Helm",
  "HTML/CSS/JS",
  "Hyperledger Fabric",
  "Informatica Intelligent Cloud Services",
  "Information Security",
  "iOS",
  "Java (Advanced)",
  "Java (Basic)",
  "Java (Intermediate)",
  "JavaScript (Advanced)",
  "JavaScript (Basic)",
  "JavaScript (Intermediate)",
  "Jenkins (Basic)",
  "Jenkins (Intermediate)",
  "JUnit",
  "Kafka (Basic)",
  "Kotlin (Basic)",
  "Kubernetes (Basic)",
  "Kubernetes (Intermediate)",
  "Kubernetes Security",
  "Language Proficiency",
  "Laravel (Advanced)",
  "Laravel (Basic)",
  "Laravel (Intermediate)",
  "Large Language Models",
  "Linux (Advanced)",
  "Linux (Basic)",
  "Linux (Intermediate)",
  "Machine Learning",
  "MongoDB (Basic)",
  "MongoDB (Intermediate)",
  "Mulesoft",
  "Natural Language Processing (Advanced)",
  "Natural Language Processing (Basic)",
  "Natural Language Processing (Intermediate)",
  "Network Security",
  "Node.js (Advanced)",
  "Node.js (Basic)",
  "Node.js (Intermediate)",
  "Offensive Security",
  "Oracle APEX",
  "Oracle Integration Cloud",
  "OWASP Top 10",
  "PHP (Advanced)",
  "PHP (Basic)",
  "PHP (Intermediate)",
  "PL/SQL (Advanced)",
  "PL/SQL (Basic)",
  "PL/SQL (Intermediate)",
  "Power BI",
  "PowerShell (Basic)",
  "PowerShell (Intermediate)",
  "Problem Solving (Advanced)",
  "Problem Solving (Basic)",
  "Problem Solving (Intermediate)",
  "Product Management Cross-functional Skills",
  "Product Management Job Knowledge",
  "Product Management Tools",
  "Prometheus (Intermediate)",
  "Puppet (Advanced)",
  "Puppet (Basic)",
  "Puppet (Intermediate)",
  "PySpark (Basic)",
  "Python (Advanced)",
  "Python (Basic)",
  "Python (Intermediate)",
  "PyTorch",
  "R (Advanced)",
  "R (Basic)",
  "R (Intermediate)",
  "React (Advanced)",
  "React (Basic)",
  "React (Intermediate)",
  "React Native (Basic)",
  "React Native (Intermediate)",
  "REST API (Advanced)",
  "REST API (Intermediate)",
  "RoR (Advanced)",
  "RoR (Basic)",
  "RoR (Intermediate)",
  "Ruby (Advanced)",
  "Ruby (Basic)",
  "Ruby (Intermediate)",
  "Salesforce Administration",
  "Salesforce Apex",
  "Salesforce Sales Cloud",
  "Salesforce Service Cloud",
  "Salt",
  "SAP",
  "Security Testing Tools",
  "Selenium (Advanced)",
  "Selenium (Basic)",
  "Selenium (Intermediate)",
  "Snowflake",
  "Software Development Methodologies",
  "Solidity (Basic)",
  "Spring Boot (Advanced)",
  "Spring Boot (Basic)",
  "Spring Boot (Intermediate)",
  "SQL (Advanced)",
  "SQL (Basic)",
  "SQL (Intermediate)",
  "Statistics",
  "Storage",
  "Swift (Advanced)",
  "System Design",
  "Tableau",
  "Talend",
  "TensorFlow",
  "Terraform (Basic)",
  "Terraform (Intermediate)",
  "Testing Techniques",
  "TIBCO",
  "TypeScript (Advanced)",
  "TypeScript (Basic)",
  "TypeScript (Intermediate)",
  "Virtualization",
  "Vue.js (Basic)",
  "Vue.js (Intermediate)"
];

export const questionConfig = {
  code: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          },
          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          }
        ]
      },
      {
        title: "Languages",
        fields: [
          {
            name: "languages",
            label: "Languages",
            type: "language",
            options: SUPPORTED_LANGUAGE,
            rules: [{ required: true, message: "Please select language!" }]
          }
        ]
      },
      {
        title: "Code Stubs",
        fields: [
          {
            name: "codeStubs",
            label: "Code Stubs",
            type: "ace-editor",
            rules: [{ required: false }]
          }
        ]
      },
      {
        title: "Test Case",
        fields: [
          {
            name: "testCase",
            label: "Test Case",
            type: "test_case",
            rules: [{ required: false }]
          }
        ]
      }
    ]
  },
  design: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem Description!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          },
          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          }
        ]
      },
      {
        title: "Code Stubs",
        fields: [
          { name: "html", label: "HTML", type: "ace-editor", required: false },
          { name: "css", label: "CSS", type: "ace-editor", required: false },
          {
            name: "js",
            label: "JavaScript",
            type: "ace-editor",
            rules: [{ required: false }]
          }
        ]
      }
    ]
  },
  database: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          },

          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          }
        ]
      },
      {
        title: "Database Setup",
        fields: [
          {
            name: "databaseType",
            label: "Database Type",
            type: "select",
            options: ["mysql", "mongo", "oracle", "other"],
            rules: [{ required: true, message: "Please enter database type " }]
          },
          {
            name: "tableIDE",
            label: "Table IDE",
            type: "textarea",
            rules: [{ required: false }]
          },
          {
            name: "expectedOutput",
            label: "Expected Output",
            type: "textarea",
            rules: [{ required: false }]
          }
        ]
      }
    ]
  },
  approx: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },

          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          }
        ]
      },
      {
        title: "Languages",
        fields: [
          {
            name: "languages",
            label: "Languages",
            type: "multi-select",
            options: masterKeywords,
            rules: [{ required: true, message: "Please select language! " }]
          }
        ]
      },
      {
        title: "Test Case",
        fields: [
          {
            name: "testCase",
            label: "Test Case",
            type: "test_case",
            rules: [{ required: false }]
          }
        ]
      },
      {
        title: "Custom Checker",
        fields: [
          {
            name: "customChecker",
            label: "Custom Checker",
            type: "textarea",
            rules: [{ required: false }]
          }
        ]
      }
    ]
  },
  mcq: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },

          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          }
        ]
      },
      {
        title: "Choices",
        fields: [
          { name: "choices", label: "Choices", type: "mcq", required: true }
        ]
      }
    ]
  },
  questionnaire: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          },
          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          }
        ]
      },
      {
        title: "Choices",
        fields: [
          {
            name: "choices",
            label: "Choices",
            type: "questionnaire",
            required: true
          }
        ]
      }
    ]
  },
  textAns: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          },
          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          }
        ]
      }
    ]
  },
  whiteboard: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          },
          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          }
        ]
      },
      {
        title: "Whiteboard",
        fields: [
          {
            name: "whiteboard",
            label: "Whiteboard",
            type: "textarea",
            rules: [{ required: false }]
          }
        ]
      }
    ]
  },
  diagram: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          },
          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          }
        ]
      },
      {
        title: "Diagram",
        fields: [
          {
            name: "diagram",
            label: "Diagram",
            type: "textarea",
            rules: [{ required: false }]
          }
        ]
      }
    ]
  },
  complete: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          },
          {
            name: "completionString",
            label: "Completion String with the blank",
            type: "textarea",
            rules: [{ required: true, message: "Please enter string! " }]
          },
          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            rules: [
              { required: true, message: "Please enter recommended time ! " }
            ]
          }
        ]
      }
    ]
  },
  file_upload: {
    steps: [
      {
        title: "Question Details",
        fields: [
          {
            name: "questionName",
            label: "Question Name",
            type: "text",
            rules: [{ required: true, message: "Please enter question title!" }]
          },
          {
            name: "problemDescription",
            label: "Problem Description",
            type: "textarea",
            rules: [
              { required: true, message: "Please enter problem description!" }
            ]
          },
          {
            name: "tags",
            label: "Tags",
            type: "tags",
            options: TagsAssessment
          },
          {
            name: "recommendedTime",
            label: "Recommended Time (mins)",
            type: "number",
            min: 0,
            rules: [
              { required: true, message: "Please enter recommended time!" }
            ]
          }
        ]
      },
      {
        title: "File Upload",
        fields: [
          {
            name: "file_upload",
            label: "File Upload",
            type: "boolean"
          }
        ]
      }
    ]
  }
};

export const questionType = [
  "code",
  //"design",
  //"database",
  //"approx",
  "mcq",
  "questionnaire"
  //"textAns",
  //"whiteboard",
  //"diagram",
  //"complete",
  //"file_upload",
];
export const QUESTION_TYPE = [
  { value: "code", label: "Code" },
  // { value: "design", label: "Design" },
  // { value: "database", label: "Database" },
  // { value: "approx", label: "Approx" },
  { value: "mcq", label: "Multiple Choice Questions (MCQ)" },
  { value: "questionnaire", label: "Questionnaire" }
  // { value: "textAns", label: "Text Answer" },
  // { value: "whiteboard", label: "Whiteboard" },
  // { value: "diagram", label: "Diagram" },
  // { value: "complete", label: "Complete" },
  // { value: "file_upload", label: "File Upload" },
];
export const TEST_LEVELS = [
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" }
];

export const createTestSteps = [
  {
    title: "Test Details",
    fields: [
      { name: "title", label: "Title", type: "input", required: true },
      {
        name: "description",
        label: "Description",
        type: "textarea",
        required: true
      },
      { name: "tags", label: "Tags", type: "tags", options: TagsAssessment },
      {
        name: "type",
        label: "Type",
        type: "select",
        options: QUESTION_TYPE,
        required: true
      },

      {
        name: "level",
        label: "Level",
        type: "select",
        options: TEST_LEVELS,
        required: true
      }
    ]
  },
  {
    title: "Question Selection",
    fields: [
      {
        name: "questionIds",
        label: "Select questions to create a Test",
        type: "custom",
        required: false
      }
    ]
  }
];
export const ASSESSMENT_STAGE_OPTION = [
  { value: "not_yet_evaluated", label: "Not Yet Evaluated" },
  { value: "evaluated", label: "Evaluated" },
  { value: "invited_for_interview", label: "Invited for Interview" },
  { value: "interviewed", label: "Interviewed" },
  { value: "invited_for_take_home_test", label: "Invited for Take-Home Test" },
  { value: "take_home_test_completed", label: "Take-Home Test Completed" },
  { value: "referenced_checked", label: "Referenced Checked" },
  { value: "offer_sent", label: "Offer Sent" },
  { value: "offer_declined", label: "Offer Declined" },
  { value: "candidate_withdrew", label: "Candidate Withdrew" },
  { value: "candidate_unresponsive", label: "Candidate Unresponsive" },
  { value: "rejected", label: "Rejected" },
  { value: "hired", label: "Hired" }
];
export const ASSESSMENT_STATUS_OPTION = [
  { value: "invited", label: "Invited" },
  { value: "started", label: "Started" },
  { value: "completed", label: "Completed" },
  { value: "disqualified", label: "Disqualified" },
  { value: "assessment_started", label: "Assessment Started" },
  { value: "assessment_completed", label: "Assessment Completed" },
  { value: "assessment_progress", label: "Assessment Progress" },
  { value: "invitation_clicked", label: "Invitation Clicked" },
  { value: "invitation_delivered", label: "Invitation Delivered" },
  { value: "invitation_opened", label: "Invitation Opened" }
];
export const DOCUMENT_STATUS = [
  { value: "draft", label: "Draft" },
  { value: "published", label: "Published" },
  { value: "unpublished", label: "Unpublished" }
  // { value: "delete", label: "Delete" },
];
