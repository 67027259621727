import React, { useEffect } from "react";
import {
  addClientAction,
  getClientDetailsAction,
  updateClientDetailsAction
} from "../../redux/actions/common";
import { useDispatch, useSelector } from "react-redux";
import {
  message,
  Button,
  Form,
  Input,
  Modal,
  Divider,
  Select,
  Row,
  Col
} from "antd";
import { TailSpin } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import LocationSelect from "../../Utils/locationSelector";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { COMPANY_TYPES } from "../../Assets/data/common";
import ImageUploadComponent from "../Upload";

const { Option } = Select;

const CreateClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientId = queryParams.get("client_id");
  const [form] = Form.useForm();

  const {
    loading: loadingAdd,
    error: errorAdd,
    data: dataAdd
  } = useSelector((state) => state.addClientReducer);
  const {
    loading: loadingGet,
    data: dataGet,
    error: errorGet
  } = useSelector((state) => state.getClientDetailsReducer);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    data: dataUpdate
  } = useSelector((state) => state.updateClientDetailsReducer);

  useEffect(() => {
    if (clientId) {
      dispatch(getClientDetailsAction(clientId));
    }

    return () => {
      dispatch({ type: "GET_CLIENT_DETAIL_RESET" });
    };
  }, [clientId, dispatch]);

  useEffect(() => {
    if (dataGet && !errorGet) {
      form.setFieldsValue(dataGet?.client);
    }
    if (errorGet) {
      message.error(errorGet);
    }
  }, [dataGet, errorGet, form]);

  useEffect(() => {
    if (dataAdd && !errorAdd) {
      message.success("Client created!");
      form.resetFields();
      navigate("/dashboard/client-dashboard");
    }
    if (errorAdd) {
      message.error(errorAdd);
    }
  }, [dataAdd, errorAdd, form, navigate]);

  useEffect(() => {
    if (dataUpdate && !errorUpdate) {
      message.success(
        dataUpdate.status !== "delete"
          ? "Client updated successfully"
          : "Client removed successfully"
      );
      form.resetFields();
      navigate("/dashboard/client-dashboard");
    }
    if (errorUpdate) {
      message.error(errorUpdate);
    }
  }, [dataUpdate, errorUpdate, form, navigate]);

  const handleFinish = async (values) => {
    try {
      await form.validateFields();
      if (clientId) {
        Modal.confirm({
          title: "Are you sure you want to update this client?",
          icon: <ExclamationCircleOutlined />,
          content: "This action will apply changes immediately.",
          okText: "Yes, Update",
          cancelText: "No, Cancel",
          onOk() {
            dispatch(
              updateClientDetailsAction({ client_id: clientId, ...values })
            );
          }
        });
      } else {
        dispatch(addClientAction(values));
      }
    } catch (error) {
      console.error("Validation Failed:", error);
      message.error("Please fix the errors in the form.");
    }
  };

  return (
    <>
      <div className="main-header bg-white" data-ui="header">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="main-header__title main-header__title--dashboard">
                  Client
                </h1>
              </a>
            </div>
            <div className="flag__section flag__section--top">
              <Button
                type="button"
                className="btn btn--primary mt-m text-white pull-right main_border_radius"
                onClick={() => {
                  dispatch({ type: "GET_CLIENT_DETAIL_RESET" });
                  navigate("/dashboard/client-dashboard");
                }}
              >
                Exit
              </Button>
            </div>
          </div>
        </div>
      </div>

      {loadingAdd || loadingGet || loadingUpdate ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <div className="container px-4 pt-3 withBorder" style={{paddingBottom:"100px"}}>
          <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  name="company_name"
                  label="Company Name"
                  rules={[
                    { required: true, message: "Please enter company name" }
                  ]}
                >
                  <Input size="large" placeholder="Enter Company Name"  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  name="company_email"
                  label="Company Email ID"
                  rules={[
                    { required: true, message: "Please enter office email ID" },
                    {
                      type: "email",
                      message: "Please enter a valid email address"
                    }
                  ]}
                >
                  <Input type="email" size="large" placeholder="Enter Company Email ID"  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  name="company_type"
                  label="Company Type"
                  rules={[
                    { required: true, message: "Please select industry type" }
                  ]}
                >
                  <Select placeholder="Select company type" size="large">
                    {COMPANY_TYPES.map((type) => (
                      <Option key={type.value} value={type.value}>
                        {type.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  name="company_size"
                  label="Company Size"
                  rules={[
                    { required: true, message: "Please enter company size" }
                  ]}
                >
                  <Select placeholder="Select company size" size="large">
                    <Option value="1-10">1-10 employees</Option>
                    <Option value="11-50">11-50 employees</Option>
                    <Option value="51-200">51-200 employees</Option>
                    <Option value="201-500">201-500 employees</Option>
                    <Option value="501-1000">501-1000 employees</Option>
                    <Option value="1001-5000">1001-5000 employees</Option>
                    <Option value="5001-10000">5001-10,000 employees</Option>
                    <Option value="10001+">10,001+ employees</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  name="company_location"
                  label="Company Location"
                  rules={[
                    { required: true, message: "Please enter company location" }
                  ]}
                >
                  <LocationSelect
                    form={form}
                    field={{ name: "company_location" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  name="company_website"
                  label="Company Website"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid company website URL"
                    },
                    { type: "url", message: "Please enter a valid URL" }
                  ]}
                >
                  <Input type="url" size="large" placeholder="Enter company Website"  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="company_description"
              label="Company Description"
              rules={[
                { required: true, message: "Please enter company description" },
                {
                  min: 100,
                  message: "Description must be at least 100 characters"
                },
                {
                  max: 5000,
                  message: "Description cannot exceed 5000 characters"
                }
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 5, maxRows: 7 }}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="company_photo"
              label="Company Photo/Logo"
              rules={[
                {
                  required: true,
                  message: "Please upload company photo or logo"
                }
              ]}
            >
              <ImageUploadComponent
                uploadPath="hire_talent/profile/"
                acceptedFileTypes="image/*"
                uploadType="Photo"
                onFileUpload={(profileImageUrl) =>
                  form.setFieldsValue({ company_photo: profileImageUrl })
                }
                existingFileUrl={dataGet?.client?.company_photo}
              />
            </Form.Item>
            <footer className="footer d-block">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className="custom-button"
                  loading={loadingAdd || loadingGet || loadingUpdate}
                  type="primary"
                  htmlType="submit"
                >
                  {clientId ? "Update" : "Submit"}
                </Button>
              </div>
            </footer>
          </Form>
        </div>
      )}
    </>
  );
};

export default CreateClient;
