// QuillTextEditor.js
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const toolbarOptions = [
  [{ header: "1" }, { header: "2" }, { font: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["link", "image"],
  ["clean"],
];

export const QuillTextEditor = ({ form, field }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    const initialValue = form.getFieldValue(`${field?.name}`);
    setContent(initialValue);
  }, [form, field]);

  const handleChange = (value) => {
    setContent(value);
    form.setFieldsValue({ [field.name]: value });
  };

  return (
    <ReactQuill
      className="quill-editor"
      value={content}
      onChange={handleChange}
      modules={{ toolbar: toolbarOptions }}
    />
  );
};

export const QuillTextPreview = ({ content }) => {
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};
