import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Collapse,
  Card,
  Badge,
  Button,
  Form,
  Input,
  Select,
  Divider,
  Row,
  Col,
  message,
  Radio,
  Pagination,
  Tooltip,
  Drawer,
} from "antd";
import {
  ClockCircleOutlined,
  QuestionCircleOutlined,
  AppstoreOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { TailSpin } from "react-loader-spinner";
import { getAllTestListAction } from "../../../../../redux/actions/assessment";
import { TagsAssessment } from "../../../data";
import PreviewModal from "./PreviewModal";
import DetailsModal from "./DetailsModal";
import { QuillTextPreview } from "../../../../../Utils/TextEditor";
import "../../../../../Assets/css/filter.css";

const { Panel } = Collapse;
const { Option } = Select;
const { Sider } = Layout;

const FilterForm = ({
  filters,
  handleFilterChange,
  handleClearFilters,
}) => (
  <Form
    layout="vertical"
    onValuesChange={handleFilterChange}
    initialValues={filters}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <Button type="ghost">
        <span className="d-flex align-items-center">
          <h2 className="m-0 ml-2">Filters</h2>
        </span>
      </Button>

      <Button type="" className="cursor-pointer" onClick={handleClearFilters}>
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3", "4"]}
      expandIconPosition="right"
      style={{ background: "#fff", border: "none" }}
    >
      <Panel header="Title" key="1">
        <Form.Item name="title">
          <Input placeholder="Search questions" />
        </Form.Item>

        <br />
      </Panel>
      <Panel header="Tags" key="2">
        <Form.Item name="tags">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Select tags"
          >
            {TagsAssessment?.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <br />
      </Panel>
      <Panel header="Select Test level" key="3">
        <Form.Item name="level" style={{ height: "5rem" }}>
          <Radio.Group
            onChange={(e) => handleFilterChange({ level: e.target.value })}
            value={filters.questionType}
          >
            <div className="d-flex justify-content-between mb-2">
              <Radio value="">All</Radio>
              <br />
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="high">High</Radio>
              <br />
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="medium">Medium</Radio>
              <br />
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="low">Low</Radio>
              <br />
            </div>
          </Radio.Group>
        </Form.Item>
        <br />
      </Panel>
      <Panel header="Select Test Type" key="4">
        <Form.Item name="questionType" style={{ height: "5rem" }}>
          <Radio.Group
            onChange={(e) =>
              handleFilterChange({ questionType: e.target.value })
            }
            value={filters.questionType}
          >
            <div className="d-flex justify-content-between mb-2">
              <Radio value="">All</Radio>
              <br />
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="mcq">Multiple Choice</Radio>
              <br />
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="code">Coding</Radio>
              <br />
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Radio value="questionnaire">Questionnaire</Radio>
              <br />
            </div>
          </Radio.Group>
        </Form.Item>
        <br />
      </Panel>
    </Collapse>
  </Form>
);
const ChooseTestStep = ({ form, initialData }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalTest, setTotalTest] = useState(0);
  const [detailModal, setDetailModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [selectedTestForPreview, setSelectedTestForPreview] = useState(null);

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const [selectedTests, setSelectedTests] = useState(
    initialData?.testIds?.map((item) => ({
      test_id: item?.test_id,
      title: item?.title,
      test_duration: item?.test_duration,
      totalQuestion: item?.totalQuestion,
      type: item?.type,
    })) || []
  );
  const [filters, setFilters] = useState({
    title: "",
    level: "",
    tags: [],
    field: [],
    skills: [],
    type: "",
    // status: "published"
  });

  const handleFilterChange = (changedValues, allValues) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, ...changedValues };
      handleFilterSubmit(newFilters);
      return newFilters;
    });
  };

  useEffect(() => {
    dispatch(
      getAllTestListAction({
        page: currentPage,
        limit: pageSize,
        ...filters,
      })
    );
    return () => {
      dispatch({ type: "GET_LIST_TEST_RESET" });
    };
  }, [dispatch, currentPage, pageSize]);

  const getAllTestListReducer = useSelector(
    (state) => state.getAllTestListReducer
  );

  useEffect(() => {
    const { loading, data, error } = getAllTestListReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.data);
      setTotalTest(data?.totalTest);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getAllTestListReducer]);

  const handleFilterSubmit = (newFilters) => {
    dispatch(
      getAllTestListAction({
        page: currentPage,
        limit: pageSize,
        ...newFilters,
      })
    );
  };

  const handleClearFilters = () => {
    const initialFilters = {
      title: "",
      level: "",
      tags: [],
      field: [],
      skills: [],
      type: "",
    };
    setFilters(initialFilters);
    handleFilterSubmit(initialFilters);
  };

  useEffect(() => {
    form.setFieldsValue({
      testIds: selectedTests,
    });
  }, [selectedTests, form]);

  const handleAddTest = (test) => {
    if (selectedTests.length >= 5) {
      message.error("You can only select up to 5 tests.");
      return;
    }
    const newSelectedTests = [
      ...selectedTests,
      {
        test_id: test?.test_id,
        title: test?.title,
        test_duration: test?.test_duration,
        totalQuestion: test?.questionIds?.length,
        type: test?.type,
      },
    ];
    setSelectedTests(newSelectedTests);
  };

  const handleRemoveTest = (indexToRemove) => {
    const newSelectedTests = selectedTests.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedTests(newSelectedTests);
  };

  const isTestSelected = (test_id) => {
    return selectedTests.some((test) => test && test.test_id === test_id);
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    return words.length > maxWords
      ? words.slice(0, maxWords).join(" ") + "..."
      : text;
  };

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <section className="mx-16" style={{ paddingBottom: "10%" }}>
        <h2>Step 2: Choose Test</h2>
        <Form form={form} layout="vertical">
          <Form.Item
            name="testIds"
            rules={[
              { required: true, message: "Please Select at least One test!" },
            ]}
          />
        </Form>
        <div style={{ margin: "10px 0" }}>
          <Row gutter={[16, 16]} justify="center">
            {Array.from({ length: 5 }).map((item, i) => (
              <Col
                key={i}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "12px 12px",
                    border: "1px groove #ccc",
                    borderRadius: "5px",
                    fontSize: "1rem",
                    fontWeight: 500,
                    backgroundColor: selectedTests[i] ? "#00756a" : "#f7f7f7",
                    color: selectedTests[i] ? "#fff" : "#000",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    width: "100%",
                    maxWidth: "200px",
                    textAlign: "center",
                  }}
                >
                  {selectedTests[i] ? (
                    <>
                      <Tooltip title={selectedTests[i].title}>
                        <span>
                          {selectedTests[i].title.length > 10
                            ? `${selectedTests[i].title.slice(0, 10)}...`
                            : selectedTests[i].title}
                        </span>
                      </Tooltip>
                      <button
                        onClick={() => handleRemoveTest(i)}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#d9534f",
                          fontWeight: "bold",
                          cursor: "pointer",
                          marginLeft: "8px",
                        }}
                      >
                        ✕
                      </button>
                    </>
                  ) : (
                    `${i + 1}${["st", "nd", "rd", "th", "th"][i]} Test`
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <Layout
          style={{
            background: "#fff",
            minHeight: "100vh",
            borderRadius: "18px",
          }}
        >
          {!isMobile && (
            <Sider width={300} style={{ background: "#fff" }}>
              <div
                className="p-3 bg-light"
                style={{ width: "100%", background: "#fff" }}
              >
                <FilterForm
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                  handleClearFilters={handleClearFilters}
                  form={form}
                />
              </div>
            </Sider>
          )}
          <Layout
            style={{
              padding: "0 5px 5px",
              minHeight: "100vh",
              background: "white",
            }}
          >
            <Button
              className="filter-toggle-button"
              onClick={toggleFilters}
              type="primary"
              style={{ backgroundColor: "#00756a" }}
            >
              Apply Filters
            </Button>
            {loading ? (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 9999,
                }}
              >
                <TailSpin ariaLabel="Loading..." color="#005c53" />
              </div>
            ) : (
              <>
                <div className="test-cards-container">
                  {data && data.length > 0 ? (
                    <>
                      <div
                        className="total-test"
                        style={{ paddingLeft: "1%", paddingBottom: "1%" }}
                      >
                        <FileOutlined
                          style={{ fontSize: "15px", marginRight: "8px" }}
                        />
                        <span style={{ fontSize: "15px" }}>{totalTest}</span>
                      </div>
                      <Row gutter={[16, 16]}>
                        {data.map((test, index) => (
                          <Col xs={24} sm={12} md={12} lg={12} key={index}>
                            <Card
                              className="test-card-ui-card"
                              style={{
                                width: "100%",
                                border: "1px solid #d9d9d9",
                                borderRadius: "8px",
                                padding: "16px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                              }}
                            >
                              <div className="test-card__content">
                                <div className="test-card__title-container mb-3">
                                  <h3 className="test-card__title fw-bold">
                                    {test?.title}
                                  </h3>
                                </div>
                                <div className="test-card__tags mb-3">
                                  {test?.tags &&
                                    test?.tags?.length > 0 &&
                                    test?.tags?.map((item, index) => (
                                      <Badge key={index}>
                                        <span className="assessment-badge mb-2">
                                          {item}
                                        </span>
                                      </Badge>
                                    ))}
                                </div>
                                <div className="test-card__description mb-3">
                                  <p className="test-card__description-content">
                                    <QuillTextPreview
                                      content={truncateText(
                                        test?.description,
                                        35
                                      )}
                                    />
                                  </p>
                                </div>
                                <div className="test-card__features d-flex justify-content-between mb-3">
                                  <div className="test-card__feature-item">
                                    <ClockCircleOutlined />
                                    <span className="ms-2">
                                      {test?.test_duration} mins
                                    </span>
                                  </div>
                                  <div className="test-card__feature-item">
                                    <QuestionCircleOutlined />
                                    <span className="ms-2">
                                      {test?.questionIds?.length} questions
                                    </span>
                                  </div>
                                  <div className="test-card__feature-item">
                                    <AppstoreOutlined />
                                    <span className="ms-2">{test?.type}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="test-card__actions d-flex justify-content-between mt-auto">
                                <div className="test-card__buttons-wrapper">
                                  <Button
                                    type="default"
                                    className="me-2 test-button-cards"
                                    onClick={() => {
                                      setSelectedTestForPreview(test);
                                      setPreviewModal(true);
                                    }}
                                  >
                                    Preview
                                  </Button>
                                  <Button
                                    type="default"
                                    className="test-button-cards"
                                    onClick={() => {
                                      setSelectedTestForPreview(test);
                                      setDetailModal(true);
                                    }}
                                  >
                                    Details
                                  </Button>
                                </div>
                                {isTestSelected(test.test_id) ? (
                                  <Button
                                    type="danger"
                                    className="test-button-cards-2"
                                    onClick={() =>
                                      handleRemoveTest(
                                        selectedTests.findIndex(
                                          (t) => t && t.test_id === test.test_id
                                        )
                                      )
                                    }
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  <Button
                                    type="primary"
                                    className="test-button-cards-2"
                                    onClick={() =>
                                      handleAddTest({
                                        test_id: test?.test_id,
                                        title: test?.title,
                                        test_duration: test?.test_duration,
                                        questionIds: test?.questionIds,
                                        type: test?.type,
                                      })
                                    }
                                  >
                                    Add
                                  </Button>
                                )}
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </>
                  ) : null}
                </div>
                <Pagination
                  className="text-center align-content-center mt-3"
                  current={currentPage}
                  total={totalTest}
                  pageSize={pageSize}
                  onChange={(page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={[5, 10, 20, 50, 100]}
                  showTotal={(total) => `Total ${total} tests`}
                />
              </>
            )}
          </Layout>
        </Layout>
      </section>
      {detailModal && selectedTestForPreview && (
        <DetailsModal
          detailModal={detailModal}
          setDetailModal={setDetailModal}
          initialData={selectedTestForPreview}
          selectedTests={selectedTests}
          setSelectedTests={setSelectedTests}
        />
      )}
      {previewModal && selectedTestForPreview && (
        <PreviewModal
          previewModal={previewModal}
          setPreviewModal={setPreviewModal}
          initialData={selectedTestForPreview}
        />
      )}
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="100%"
        className="filter-drawer"
      >
        <FilterForm
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          form={form}
        />
      </Drawer>
    </>
  );
};

export default ChooseTestStep;
