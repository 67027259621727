import React, { useEffect, useState, useMemo } from "react";
import { Avatar, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoleUserAction } from "../../redux/actions/Roles&Management";
import { UserOutlined } from "@ant-design/icons";
import { All_ROLES } from "../../Assets/data/common";
import { REACT_USER_ID } from "../../Components/Auth/tokenProvider";

const { Option } = Select;

export const UserTypeFind = (user_type) => {
  const role = All_ROLES.find((option) => option.value === user_type);
  return role ? role.label : user_type;
};

const UserCustomSelect = ({ form, multiple = false, filter }) => {
  const dispatch = useDispatch();
  const defaultUserId = useMemo(() => REACT_USER_ID(), []);

  const [page, setPage] = useState(1);
  const [allUsers, setAllUsers] = useState([]);
  const { data = [], loading } = useSelector(
    (state) => state.getAllRoleUserReducer || {}
  );
  const users = Array.isArray(data?.data) ? data.data : [];

  useEffect(() => {
    const { selective, client_id } = filter || {};

    dispatch(
      getAllRoleUserAction({
        page,
        limit: 50,
        ...(selective ? { client_id, selective } : { client_id })
      })
    );

    return () => {
      dispatch({ type: "GET_USER_RESET" });
    };
  }, [dispatch, page, filter?.client_id, filter?.selective]);

  useEffect(() => {
    setAllUsers(users);
  }, [users]);

  const handleChange = (value) => {
    const userIds = multiple ? value : [value].filter(Boolean);
    form?.setFieldsValue({
      assign_data: {
        userIds
      }
    });
  };

  const currentValue = form?.getFieldValue(["assign_data", "userIds"]) || [];
  const displayValue = multiple
    ? currentValue
    : currentValue[0] || defaultUserId;

  useEffect(() => {
    if (defaultUserId && !currentValue.includes(defaultUserId)) {
      handleChange(
        multiple ? [...currentValue, defaultUserId] : [defaultUserId]
      );
    }
  }, [form]);

  return (
    <Select
      size="large"
      className="w-100"
      value={displayValue}
      onChange={handleChange}
      placeholder="Select a Recruiter to assign"
      mode={multiple ? "multiple" : undefined}
      notFoundContent={loading ? <Spin size="small" /> : "No Recruiter found"}
      allowClear
      onClear={() => handleChange(multiple ? [] : [])}
      filterOption={(input, option) => {
        const user = allUsers.find((user) => user.user_id === option.value);
        if (user) {
          const fullName = `${user.first_name} ${user.last_name}`;
          return fullName.toLowerCase().includes(input.toLowerCase());
        }
        return false;
      }}
    >
      {allUsers.length > 0 ? (
        allUsers.map((user) => (
          <Option key={user.user_id} value={user.user_id}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: "50%",
                  marginRight: 8
                }}
                src={
                  user?.photo
                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${user?.photo}`
                    : null
                }
                icon={!user?.photo && <UserOutlined />}
              />
              <div style={{ flex: 1 }}>
                <strong style={{ display: "block", fontSize: "14px" }}>
                  {`${user.first_name} ${user.last_name} (${UserTypeFind(
                    user?.user_type
                  )})`}
                </strong>
              </div>
            </div>
          </Option>
        ))
      ) : (
        <Option disabled>No Recruiter available</Option>
      )}
    </Select>
  );
};

export default UserCustomSelect;
