import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getClientDetailsAction,
  getClientJobAction
} from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, message } from "antd";
import ClientFooter from "./ClientFooter";
import PublicJobCard from "./publicJobCard";
const CareersPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [JobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getClientDetailsAction(id));
    dispatch(getClientJobAction(id));

    return () => {
      dispatch({ type: "GET_CLIENT_DETAIL_RESET" });
      dispatch({ type: "GET_CLIENT_JOBS_RESET" });
    };
  }, [dispatch, id]);

  const getClientDetailsReducer = useSelector(
    (state) => state.getClientDetailsReducer
  );
  const getClientJobReducer = useSelector((state) => state.getClientJobReducer);

  useEffect(() => {
    const { data, loading, error, status } = getClientDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.client);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getClientDetailsReducer]);

  useEffect(() => {
    const { data, loading, error, status } = getClientJobReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setJobData(data);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getClientJobReducer]);

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <div>
          <div className="styles--2lk6v">
            <div className="styles--2Kv6p" data-ui="careers-page-content">
              <header className="styles--2MSa5" role="banner">
                <div className="styles--2vxXd ">
                  <div
                    className="candidate-db-SxGQz candidate-db-3IQFQ"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "4%",
                      marginLeft: "-3%"
                    }}
                  >
                    <div
                      className="candidate-db-QXE0e"
                      style={{
                        width: 50,
                        height: 50
                      }}
                    >
                      {data?.company_photo && data?.company_photo !== "" ? (
                        <Avatar
                          size={{
                            xs: 24,
                            sm: 32,
                            md: 40,
                            lg: 64,
                            xl: 80,
                            xxl: 100
                          }}
                          alt={`${data?.company_name} Compony Icon`}
                          src={`${process.env.REACT_APP_IMAGES_BASE_URL}${data?.company_photo}`}
                        />
                      ) : (
                        <Avatar
                          size={{
                            xs: 24,
                            sm: 32,
                            md: 40,
                            lg: 64,
                            xl: 80,
                            xxl: 100
                          }}
                          icon={<UserOutlined />}
                        />
                      )}
                    </div>
                  </div>
                  <h1 data-ui="job-title">{data?.company_name}</h1>
                  <p className="styles--11P0z styles--1HMvu">
                    <span className="styles--3qswA" data-ui="job-location">
                      {data?.company_location}
                    </span>
                  </p>
                </div>
              </header>

              <main className="styles--2d3Fz" role="main">
                <section className="dashboard-container" data-ui="container">
                  <div>
                    {JobData && JobData?.length > 0 ? (
                      <>
                        {JobData?.map((job, key) => (
                          <section data-ui="open_jobs_region">
                            <ul className="jobs">
                              <div data-ui="jobs_container">
                                <PublicJobCard job={job} key={key} />
                              </div>
                            </ul>
                          </section>
                        ))}
                      </>
                    ) : (
                      <section>
                        <div className="container avilabilty___col">
                          <div className="d-flex align-items-center justify-content-center ">
                            <div className="text-center">
                              <h1 className="display-1 fw-bold">
                                No Jobs Found!!
                              </h1>
                              <p className="fs-3">
                                <span className="text-danger">Opps!</span>{" "}
                                {data?.company_name} haven't added any job...
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </div>
                </section>
                <div style={{ paddingBottom: "10%" }} />
              </main>
            </div>
            <ClientFooter data={data} />
          </div>
        </div>
      )}
    </>
  );
};

export default CareersPage;
