import { calculateExperience, getDuration } from "../../../Utils/getDuration";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import PdfViewer from "../../PdfViewer";

export const CandidateProfile = ({ candidate_data }) => {
  return (
    <>
      <div className="candidate-db-2Z77S">
        <div className="candidate-db-1ff9- candidate-db-1re7F">
          <div className="candidate-db-Pnikk">
            <div className="candidate-db-UqpV2 candidate-db-3dLZO"></div>
          </div>
          <div className="candidate-db-Ur2L6">
            <div className="candidate-db-1oAnD">
              <div className="candidate-db-dKJUi">
                <div className="" data-ui="avatar-desktop" data-loaded="true">
                  <div
                    data-role="preview"
                    className="candidate-db-QXE0e"
                    style={{ width: 80, height: 80 }}
                  >
                    {candidate_data?.photo && candidate_data?.photo !== "" ? (
                      <Avatar style={{width:"90px", height:"90px"}}
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 80
                        }}
                        alt={`${candidate_data?.first_name}${candidate_data?.last_name} candidate Image`}
                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}${candidate_data?.photo}`}
                      />
                    ) : (
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 80,
                          xxl: 80
                        }}
                        icon={<UserOutlined />}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="candidate-db-2wEIW">
              <div className="candidate-db-1jl4u">
                <div className="candidate-db-3C8vu" />
                <div>
                  <div className="candidate-db-Yb_Fd">
                    <div className="candidate-db-11q6G">
                      <h2
                        data-ui="candidate-name"
                        data-ellipsis-element="true"
                        className="candidate-db-p4pBC candidate-db-QTMDv"
                      >
                        <strong className="candidate-db-2kqW6">
                          {candidate_data?.firstName} {candidate_data?.lastName}
                        </strong>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="candidate-db-1S4nn">
                  {candidate_data?.experience.length !== 0 && (
                    <div className="candidate-db-fO4No">
                      <span
                        className="candidate-db-3VVrr candidate-db-1_EWS candidate-db-f-uLT"
                        data-ui="experience"
                      >
                        <div className="candidate-db-11q6G">
                          <div
                            data-ellipsis-element="true"
                            className="candidate-db-3VVrr candidate-db-f-uLT"
                          >
                            {candidate_data?.experience[0]?.company}
                          </div>
                        </div>
                        <span className="candidate-db-291-V">
                          {getDuration(
                            candidate_data?.experience[0]?.startDate,
                            candidate_data?.experience[0]?.endDate
                          )}
                        </span>
                      </span>
                      <span
                        className="candidate-db-3VVrr candidate-db-2D6n3 candidate-db-f-uLT"
                        data-ui="education"
                      >
                        <div className="candidate-db-11q6G">
                          <div
                            data-ellipsis-element="true"
                            className="candidate-db-3VVrr candidate-db-f-uLT"
                          >
                            {candidate_data?.education[0]?.school}
                          </div>
                        </div>
                        <span className="candidate-db-291-V">
                          {getDuration(
                            candidate_data?.education[0]?.startDate,
                            candidate_data?.education[0]?.endDate
                          )}
                        </span>
                      </span>
                    </div>
                  )}
                  <div className="candidate-db-2Vbub">
                    <a>
                      <div
                        aria-disabled="false"
                        className="candidate-db-jQpn9 candidate-db-32lt- candidate-db-3R9IU candidate-db-Kg3TD"
                      >
                        <span>
                          <span data-ui="phone">{candidate_data?.phone}</span>
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="candidate-db-1Zj66">
          <div className="candidate-db-2iafX" data-ui="profile-sections">
            <div className="candidate-db-7rWLI candidate-db-18g4y">
              <div>
                <div
                  data-section="true"
                  data-ui="profile"
                  className="candidate-db-1dhMO"
                >
                  <div data-ui="section-content">
                    <div>
                      <div data-ui="details" className="candidate-db-2q6gn">
                        {candidate_data?.jobRole &&
                          candidate_data.headline !== "" && (
                            <div
                              data-profile-section="true"
                              data-ui="contact_details"
                              className="candidate-db-3Kg_h"
                            >
                              <div className="candidate-db-3Qvos">
                                <div className="candidate-db-3FK1t">
                                  <span className="candidate-db-13_IL candidate-db-QTMDv">
                                    <strong className="candidate-db-2kqW6">
                                      Job Title
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <div
                                data-ui="content"
                                className="candidate-db-1etXj"
                                style={{ maxWidth: 800 }}
                              >
                                <div
                                  className="candidate-db-31PhD"
                                  data-ui="phone-section"
                                >
                                  <div className="candidate-db-2he0O">
                                    <div
                                      data-ui="entry"
                                      className="candidate-db-2v38y"
                                    >
                                      <span
                                        className="candidate-db-2TdGW candidate-db-2GigO candidate-db-QTMDv"
                                        data-ui="item-content"
                                      >
                                        <div className="candidate-db-13sCt">
                                          {candidate_data?.jobRole}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {candidate_data?.summary &&
                          candidate_data?.summary !== "" && (
                            <div className="candidate-db-3Kg_h">
                              <div className="candidate-db-3Qvos">
                                <div className="candidate-db-3FK1t">
                                  <span className="candidate-db-13_IL candidate-db-QTMDv">
                                    <strong className="candidate-db-2kqW6">
                                      Introduction
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <div
                                data-ui="content"
                                className="candidate-db-1etXj"
                                style={{ maxWidth: 800 }}
                              >
                                <div
                                  className="candidate-db-31PhD"
                                  data-ui="phone-section"
                                >
                                  <div className="candidate-db-2he0O">
                                    <div
                                      data-ui="entry"
                                      className="candidate-db-2v38y"
                                    >
                                      <span
                                        className="candidate-db-2TdGW candidate-db-2GigO candidate-db-QTMDv"
                                        data-ui="item-content"
                                      >
                                        <div className="candidate-db-13sCt">
                                          {candidate_data?.summary}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {candidate_data?.experience.length !== 0 && (
                          <div className="candidate-db-3Kg_h">
                            <div className="candidate-db-3Qvos">
                              <div className="candidate-db-3FK1t">
                                <span className="candidate-db-13_IL candidate-db-QTMDv">
                                  <strong className="candidate-db-2kqW6">
                                    Work Experience
                                  </strong>
                                </span>
                              </div>
                            </div>
                            {/* WORK EXPERIENCE */}
                            {candidate_data?.experience?.map((value, index) => (
                              <div
                                className="candidate-db-1etXj"
                                style={{ maxWidth: 800 }}
                              >
                                <div className="candidate-db-2he0O candidate-db-lHLJd">
                                  <div className="candidate-db-2v38y">
                                    <span className="candidate-db-2TdGW candidate-db-2IoTy candidate-db-QInzZ candidate-db-QTMDv">
                                      {getDuration(
                                        value.startDate,
                                        value.endDate
                                      )}
                                    </span>
                                    <span
                                      className="candidate-db-2TdGW candidate-db-2GigO candidate-db-QTMDv"
                                      data-ui="item-content"
                                    >
                                      <div
                                        className="candidate-db-13sCt"
                                        id={index}
                                      >
                                        <div>
                                          <div className="candidate-db-2TdGW candidate-db-2drL1 candidate-db-QTMDv">
                                            {value.title + " "}
                                            at
                                            {" " + value.company}
                                            <span className="candidate-db-2TdGW candidate-db-QTMDv">
                                              (
                                              {calculateExperience(
                                                value.startDate,
                                                value.endDate
                                              )}
                                              )
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {/* EDUCATION DETAILS */}
                        {candidate_data?.education.length !== 0 && (
                          <div className="candidate-db-3Kg_h">
                            <div className="candidate-db-3Qvos">
                              <div className="candidate-db-3FK1t">
                                <span className="candidate-db-13_IL candidate-db-QTMDv">
                                  <strong className="candidate-db-2kqW6">
                                    Education
                                  </strong>
                                </span>
                              </div>
                            </div>
                            <div style={{ maxWidth: 800 }}>
                              {candidate_data?.education?.map(
                                (value, index) => (
                                  <div className="candidate-db-2he0O candidate-db-lHLJd">
                                    <div
                                      data-ui="entry"
                                      className="candidate-db-2v38y"
                                    >
                                      <span
                                        data-ui="item-label"
                                        className="candidate-db-2TdGW candidate-db-2IoTy candidate-db-QInzZ candidate-db-QTMDv"
                                      >
                                        {getDuration(
                                          value.startDate,
                                          value.endDate
                                        )}
                                      </span>
                                      <span
                                        className="candidate-db-2TdGW candidate-db-2GigO candidate-db-QTMDv"
                                        data-ui="item-content"
                                      >
                                        <div className="candidate-db-13sCt">
                                          {value.degree + " "}
                                          at
                                          {" " + value.school}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                        <div className="candidate-db-3Kg_h">
                          <div className="candidate-db-3Qvos">
                            <div className="candidate-db-3FK1t">
                              <span className="candidate-db-13_IL candidate-db-QTMDv">
                                <strong className="candidate-db-2kqW6">
                                  Contact Details
                                </strong>
                              </span>
                            </div>
                          </div>
                          <div
                            className="candidate-db-1etXj"
                            style={{ maxWidth: 800 }}
                          >
                            <div className="candidate-db-31PhD">
                              <div className="candidate-db-2he0O">
                                <div className="candidate-db-2v38y">
                                  <span className="candidate-db-2TdGW candidate-db-2IoTy candidate-db-QInzZ candidate-db-QTMDv">
                                    Phone
                                  </span>
                                  <span className="candidate-db-2TdGW candidate-db-2GigO candidate-db-QTMDv">
                                    <div className="candidate-db-13sCt">
                                      {candidate_data?.phone}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="candidate-db-2z7HO">
                              <div className="candidate-db-2he0O">
                                <div
                                  data-ui="entry"
                                  className="candidate-db-2v38y"
                                >
                                  <span
                                    data-ui="item-label"
                                    className="candidate-db-2TdGW candidate-db-2IoTy candidate-db-QInzZ candidate-db-QTMDv"
                                  >
                                    Email
                                  </span>
                                  <span
                                    className="candidate-db-2TdGW candidate-db-2GigO candidate-db-QTMDv"
                                    data-ui="item-content"
                                  >
                                    <div className="candidate-db-13sCt">
                                      {candidate_data?.email}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {candidate_data?.coverLetter &&
                          candidate_data?.coverLetter !== "" && (
                            <div className="candidate-db-3Kg_h">
                              <div className="candidate-db-3Qvos">
                                <div className="candidate-db-3FK1t">
                                  <span className="candidate-db-13_IL candidate-db-QTMDv">
                                    <strong className="candidate-db-2kqW6">
                                      Cover Letter
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <div
                                className="candidate-db-1etXj"
                                style={{ maxWidth: 800 }}
                              >
                                <div className="candidate-db-31PhD">
                                  <div className="candidate-db-2he0O">
                                    <div className="candidate-db-2v38y">
                                      <span className="candidate-db-2TdGW candidate-db-2GigO candidate-db-QTMDv">
                                        <div className="candidate-db-13sCt">
                                          {candidate_data?.coverLetter}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {candidate_data?.resume &&
                          candidate_data?.resume !== "" && (
                            <div className="candidate-db-3Kg_h">
                              <div className="candidate-db-3Qvos">
                                <div className="candidate-db-3FK1t">
                                  <span className="candidate-db-13_IL candidate-db-QTMDv">
                                    <strong className="candidate-db-2kqW6">
                                      Resume
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <PdfViewer
                                resumeSrc={`${process.env.REACT_APP_IMAGES_BASE_URL}${candidate_data?.resume}`}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
