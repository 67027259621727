import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
  message
} from "antd";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "../../../Assets/css/job.detail.css";
import { APPLICATION_STAGE } from "../../../Assets/data/Job";
import {
  getJobAllAppliedCandidateAction,
  getJobDetailAction,
  multipleAction,
  updateJobApplicationAction,
  updateJobDetailAction
} from "../../../redux/actions/common";
import {
  MoreOutlined,
  ReloadOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";

import moment from "moment";
import {
  JOB_APPLICATION_STAGE,
  JOB_APPLICATION_STATUS
} from "../../../Assets/data/common";
import DetailSection from "./DetailSection";
import AssignBadge from "../modal/AssignBadge";
import InterviewScheduling from "../../InterviewScheduling";
import AddCandidateRemarksModal from "../modal/AddCandidateRemarksModal";
import UpdateApplicationDetails from "../modal/UpdateApplicationDetailsModal";
import ReminderModal from "../modal/ReminderModal";
import { ResumePreviewSection } from "./Modal/ResumePreviewModal";
import GetAssignBadgeData from "./GetAssignBadgeData";
import JobDetailsDrawer from "./Modal/JobDetailsDrawer";
import { downloadExcel } from "./Modal/DownloadReport";
import AddResumeDrawer from "../../Dashboard/AddResumeDrawer";

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Option } = Select;

const JobDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState({
    job: false,
    candidate: false,
    updateJob: false,
    updateCandidate: false
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCandidate, setTotalCandidate] = useState(0);
  const [jobDetails, setJobDetails] = useState(null);
  const [jobAppliedCandidate, setJobAppliedCandidate] = useState(null);
  const [selectedTab, setSelectedTab] = useState("candidatesCount");

  const [viewJobDetailsModal, setViewJobDetailsModal] = useState({
    show: false,
    data: {}
  });
  const [openInterviewModal, setOpenInterviewModal] = useState({
    show: false,
    data: {}
  });
  const [reminderModal, setReminderModal] = useState({
    show: false,
    data: {}
  });
  const [addRemarksModal, setAddRemarksModal] = useState({
    show: false,
    data: {}
  });
  const [updateApplicantDetailsModal, setUpdateApplicantDetailsModal] =
    useState({
      show: false,
      data: {}
    });
  const [addCandidate, setAddCandidate] = useState({
    show: false,
    type: ""
  });
  const [resumePreviewModal, setResumePreviewModal] = useState({
    show: false,
    resume_url: ""
  });
  const [openDrawer, setOpenDrawer] = useState({
    show: false,
    apply_id: "",
    data: {}
  });
  const isMobile = window.innerWidth <= 768;

  const applyJobReducer = useSelector((state) => state.applyJobReducer);
  const getJobDetailReducer = useSelector((state) => state.getJobDetailReducer);
  const getJobAllAppliedCandidateReducer = useSelector(
    (state) => state.getJobAllAppliedCandidateReducer
  );
  const updateJobApplicationReducer = useSelector(
    (state) => state.updateJobApplicationReducer
  );
  const updateJobDetailReducer = useSelector(
    (state) => state.updateJobDetailReducer
  );
  const resumeParserReducer = useSelector((state) => state.resumeParserReducer);
  const multipleActionReducer = useSelector(
    (state) => state.multipleActionReducer
  );
  const updateCandidateReducer = useSelector(
    (state) => state.updateCandidateReducer
  );
  const { data } = useSelector((state) => state.assignToRecruiterReducer);
  useEffect(() => {
    dispatch(
      getJobDetailAction({
        job_id: id
      })
    );
    return () => {
      dispatch({ type: "GET_JOB_DETAIL_RESET" });
    };
  }, [dispatch, id, updateJobDetailReducer, data?.data?.assign_type === "job"]);

  useEffect(() => {
    dispatch(
      getJobAllAppliedCandidateAction({
        job_id: id,
        stage:
          selectedTab === "candidatesCount" || selectedTab === "reminderCount"
            ? ""
            : selectedTab,
        reminder: selectedTab === "reminderCount" ? true : "",
        pageSize,
        page: currentPage
      })
    );
  }, [
    dispatch,
    selectedTab,
    id,
    applyJobReducer,
    updateJobApplicationReducer,
    resumeParserReducer,
    data?.data?.assign_type === "job-application",
    currentPage,
    pageSize,
    updateCandidateReducer
  ]);

  useEffect(() => {
    const { loading, error, data } = applyJobReducer;
    setLoading((prev) => ({
      ...prev,
      candidate: loading
    }));
    if (!loading && data && !error) {
      message.success("Applied Job!");
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [applyJobReducer]);

  useEffect(() => {
    const { loading, data, error } = multipleActionReducer;
    if (!loading && data && !error) {
      message.success(data?.message);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [multipleActionReducer]);

  useEffect(() => {
    const { loading, error, data } = updateJobDetailReducer;
    setLoading((prev) => ({
      ...prev,
      updateJob: loading
    }));
    if (!loading && data && !error) {
      message.success(data?.message || "job updated successfully!");
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [updateJobDetailReducer]);
  useEffect(() => {
    const { loading, error, data } = updateJobApplicationReducer;
    setLoading((prev) => ({
      ...prev,
      updateCandidate: loading
    }));
    if (!loading && data && !error) {
      message.success("Application Update!");
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [updateJobApplicationReducer]);
  useEffect(() => {
    const { loading, error, data } = resumeParserReducer;

    if (!loading && data && !error) {
      message.success("Job Applied!");
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [resumeParserReducer]);
  useEffect(() => {
    const { loading, error, data } = getJobAllAppliedCandidateReducer;
    setLoading((prev) => ({
      ...prev,
      candidate: loading
    }));
    if (!loading && data && !error) {
      setJobAppliedCandidate(data);
      setTotalCandidate(data?.pagination?.totalCandidates);
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getJobAllAppliedCandidateReducer]);
  useEffect(() => {
    const { loading, error, data } = getJobDetailReducer;
    setLoading((prev) => ({
      ...prev,
      job: loading
    }));
    if (!loading && data && !error) {
      setJobDetails(data);
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getJobDetailReducer]);

  const handleMoreAction = (type) => {
    let data = {};
    switch (type) {
      case "publish":
        data = { job_id: id, publishStatus: "published" };
        break;
      case "unpublish":
        data = { job_id: id, publishStatus: "unpublish" };
        break;
      case "leave-job":
        data = { job_id: id, status: "delete" };
        break;
      case "active":
        data = { job_id: id, status: "active" };
        break;
      case "inactive":
        data = { job_id: id, status: "inactive" };
        break;
      case "onhold":
        data = { job_id: id, status: "on hold" };
        break;
      case "view-job":
        navigate(`/apply-job/${id}`);
        return;
      case "edit-job":
        navigate(`/dashboard/edit-job?job_id=${id}`);
        return;
      case "candidate-manually":
        setAddCandidate({
          show: true,
          type: "add_manually"
        });
        return;

      default:
        message.success("Feature Coming Soon");
        return;
    }
    dispatch(updateJobDetailAction(data));
  };

  const moreActionItems = [
    {
      key: "1",
      label: (
        <a onClick={() => handleMoreAction("view-job")}>View Job Details</a>
      )
    },
    {
      key: "2",
      label: (
        <a onClick={() => handleMoreAction("edit-job")}>Edit Job Information</a>
      )
    },
    {
      key: "3",
      label: (
        <a onClick={() => handleMoreAction("active")}>Activate Job Posting</a>
      )
    },
    {
      key: "4",
      label: (
        <a onClick={() => handleMoreAction("inactive")}>
          Deactivate Job Posting
        </a>
      )
    },
    {
      key: "5",
      label: <a onClick={() => handleMoreAction("onhold")}>Put Job on Hold</a>
    },
    {
      key: "6",
      label: (
        <a onClick={() => handleMoreAction("publish")}>
          Publish on Careers Page
        </a>
      )
    },
    {
      key: "7",
      label: (
        <a onClick={() => handleMoreAction("unpublish")}>
          Remove from Public View(From Careers Page)
        </a>
      )
    },
    {
      key: "8",
      label: (
        <a onClick={() => handleMoreAction("candidate-manually")}>
          Manually Add Candidate
        </a>
      )
    }
  ];

  const handleMenuClick = (record) => (e) => {
    const apply_id = record?.apply_id;
    const candidate_id = record?.candidate_id;
    const job_id = record?.job_id;
    const client_id = record?.client_id;

    const navigateToInterviewScheduling = () => {
      setOpenInterviewModal((prev) => ({
        ...prev,
        show: true,
        data: {
          apply_id: apply_id,
          candidate_id: candidate_id,
          client_id: client_id,
          job_id: job_id,
          name: record?.candidate_details?.name,
          job_title: jobDetails?.job?.job_title,
          company_name: jobDetails?.client?.company_name
        }
      }));
    };

    const deleteApplication = () => {
      confirm({
        title: "Are you sure you want to delete this application?",
        onOk() {
          const data = {
            apply_id: apply_id,
            status: "delete"
          };
          dispatch(updateJobApplicationAction(data));
        }
      });
    };
    const jobDetail = () => {
      confirm({
        title: "Are you sure you want to send job details by email?",
        onOk() {
          dispatch(
            multipleAction({
              action_type: "SEND_JOB_DETAILS",
              data: {
                apply_id: apply_id
              }
            })
          );
        }
      });
    };
    const sendAssessment = () => {
      confirm({
        title: "Are you sure you want to send assessments by email?",
        onOk() {
          dispatch(
            multipleAction({
              action_type: "SEND_JOB_ASSESSMENT",
              data: {
                apply_id: apply_id
              }
            })
          );
        }
      });
    };

    const handleDefaultAction = () => {
      confirm({
        title: `Are you sure you want to move this candidate to ${e.key.charAt(0).toUpperCase() + e.key.slice(1)
          }?`,
        onOk() {
          handleAction(e.key, record);
        }
      });
    };

    switch (e.key) {
      case "schedule_interview":
        navigateToInterviewScheduling();
        break;

      case "delete_application":
        deleteApplication();
        break;
      case "job_details":
        jobDetail();
        break;
      case "send_assessment":
        sendAssessment();
        break;
      case "update_applicant_details":
        setUpdateApplicantDetailsModal((prev) => ({
          ...prev,
          show: true,
          data: {
            candidate_id: record?.candidate_id,
            name: record?.candidate_details?.name,
            photo: record?.candidate_details?.photo,
            apply_id: record?.apply_id
          }
        }));
        break;
      case "add_remarks":
        setAddRemarksModal((prev) => ({
          ...prev,
          show: true,
          data: {
            candidate_id: candidate_id,
            name: record?.candidate_details?.name,
            photo: record?.candidate_details?.photo,
            apply_id: record?.apply_id
          }
        }));
        break;
      default:
        handleDefaultAction();
    }
  };

  const handleAction = (action, record) => {
    const data = {
      apply_id: record.apply_id,
      stage: action
    };
    dispatch(updateJobApplicationAction(data));
  };

  const actionMenu = (record) => (
    <Menu onClick={handleMenuClick(record)} className="actions-menu">
      {[
        "Sourced",
        "Applied",
        "Phone Screen",
        "Assessment",
        "Interview",
        "Offer",
        "Hired",
        "Disqualified"
      ].map((action) => (
        <Menu.Item key={action.toLowerCase().replace(/\s+/g, "_")}>
          Move to {action} Stage
        </Menu.Item>
      ))}
      <Menu.Item key="schedule_interview">Schedule an Interview</Menu.Item>
      <Menu.Item key="delete_application">Delete Application</Menu.Item>
      <Menu.Item key="job_details">Send Job Details</Menu.Item>
      <Menu.Item key="add_remarks">Add Remarks/Notes</Menu.Item>
      <Menu.Item key="update_applicant_details">
        Update Applicant Information
      </Menu.Item>
      <Menu.Item key="send_assessment">Send Job Assessment</Menu.Item>
    </Menu>
  );

  const renderValue = (value) => (value ? value : "-");

  const renderCTC = (ctc) => {
    return ctc?.amount && ctc?.unit ? `${ctc?.amount} ${ctc?.unit}` : "-";
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      fixed: !isMobile ? "left" : undefined,
      width: 70,
      render: (text, record, index) => index + 1,
      ellipsis: true
    },
    {
      title: "Assignee(Recruiter)",
      dataIndex: "assign_to",
      key: "assign_to",
      width: 200,
      fixed: !isMobile ? "left" : undefined,
      render: (text, record) => {
        const defaultData = {
          apply_id: record?.apply_id,

          assign_type: "job-application"
        };
        const isErrorResponse =
          typeof text === "object" &&
          text.success === false &&
          text.message &&
          text.statusCode === 404;

        const dataToPass = isErrorResponse ? defaultData : text;

        return <AssignBadge data={dataToPass} client_id={record?.client_id} />;
      }
    },
    {
      title: "Name",
      dataIndex: ["candidate_details", "name"],
      key: "name",
      fixed: !isMobile ? "left" : undefined,
      width: 300,
      sorter: (a, b) =>
        a.candidate_details.name.localeCompare(b.candidate_details.name),
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "70%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            <Avatar
              src={
                record.candidate_details.photo
                  ? `${process.env.REACT_APP_IMAGES_BASE_URL}${record.candidate_details.photo}`
                  : undefined
              }
              icon={!record.candidate_details.photo ? <UserOutlined /> : null}
              alt={text || "User"}
              style={{ marginRight: 8 }}
            />
            <a
              className="text-decoration-underline"
              style={{
                cursor: "pointer",
                color: "#005c53",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
              onClick={() => {
                setOpenDrawer((prev) => ({
                  ...prev,
                  show: true,
                  apply_id: record?.apply_id
                }));
              }}
              title={text}
            >
              {text}
            </a>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "8px"
            }}
          >
            <Button
              type="primary"
              style={{
                background: "#00756a",
                borderColor: "#00756a"
              }}
              icon={<EditOutlined />}
              onClick={() => {
                setAddRemarksModal((prev) => ({
                  ...prev,
                  show: true,
                  data: {
                    candidate_id: record?.candidate_id,
                    name: record?.candidate_details?.name,
                    photo: record?.candidate_details?.photo,
                    apply_id: record?.apply_id
                  }
                }));
              }}
            />
            <Button
              type="primary"
              style={{
                background: "#00756a",
                borderColor: "#00756a"
              }}
              icon={<ClockCircleOutlined />}
              onClick={() => {
                setReminderModal((prev) => ({
                  ...prev,
                  show: true,
                  data: {
                    candidate_id: record?.candidate_id,
                    name: record?.candidate_details?.name,
                    photo: record?.candidate_details?.photo,
                    apply_id: record?.apply_id
                  }
                }));
              }}
            />
          </div>
        </div>
      ),

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{
                width: 90,
                backgroundColor: "#00756a",
                borderColor: "#00756a"
              }}
            >
              OK
            </Button>
            <Button
              onClick={() => {
                clearFilters()
                confirm()
              }
              } size="small"
              style={{
                width: 90,
                backgroundColor: "#00756a",
                borderColor: "#00756a"
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.candidate_details.name
          .toLowerCase()
          .includes(value.toLowerCase())
    },
    ...(selectedTab === "reminderCount"
      ? [
        {
          title: "Reminder",
          dataIndex: ["reminder", "enabled"],
          key: "enabled",
          width: 350,
          render: (email, record) => {
            const data = record?.reminder;
            if (!data || !data.enabled) return null;

            const formattedDate = moment(data.date).format("MMMM Do YYYY");
            const formattedTime = moment(data.time, "HH:mm:ss").format(
              "h:mm A"
            );
            const isOverdue = moment().isAfter(
              moment(`${data.date} ${data.time}`)
            );
            const badgeColor = isOverdue
              ? "red"
              : data.status === "complete"
                ? "green"
                : "blue";
            const tooltipMessage = isOverdue
              ? "Time's up!"
              : "Reminder is active";

            return (
              <div
                className="p-2 border rounded"
                style={{ backgroundColor: "#f9f9f9" }}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <div className="mb-1">
                      <strong>Date:</strong>
                    </div>
                    <div>{formattedDate}</div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-1">
                      <strong>Time:</strong>
                    </div>
                    <div>{formattedTime}</div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-1">
                      <strong>Text:</strong>
                    </div>
                    <div>{data.reminder_text}</div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-1">
                      <strong>Status:</strong>
                    </div>
                    <Tooltip title={tooltipMessage}>
                      <Badge
                        color={badgeColor}
                        text={data.status}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                    <Select
                      defaultValue={data.status || "pending"}
                      style={{ width: 120, marginLeft: "5px" }}
                      onChange={(value) => {
                        confirm({
                          title: `Are you sure you want to ${value} this reminder?`,
                          onOk() {
                            const updateData = {
                              apply_id: record?.apply_id,
                              reminder: {
                                enabled: true,
                                status: value
                              }
                            };
                            dispatch(updateJobApplicationAction(updateData));
                          }
                        });
                      }}
                    >
                      <Option value="pending">Pending</Option>
                      <Option value="complete">Complete</Option>
                    </Select>
                  </Col>
                </Row>
              </div>
            );
          }
        }
      ]
      : []),

    {
      title: "Resume Link",
      dataIndex: ["candidate_details", "resume"],
      key: "resume",
      width: 250,
      render: (resume) =>
        resume ? (
          <a
            style={{ cursor: "pointer", color: "#005c53" }}
            onClick={() => {
              setResumePreviewModal((prev) => ({
                ...prev,
                show: true,
                resume_url: resume
              }));
            }}
          >
            View Resume
          </a>
        ) : (
          "-"
        )
    },
    {
      title: "Email",
      dataIndex: ["candidate_details", "email"],
      key: "email",
      width: 250,
      render: (email) => renderValue(email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Email"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{
                width: 90,
                backgroundColor: "#00756a",
                borderColor: "#00756a"
              }}
            >
              OK
            </Button>
            <Button
              onClick={() => {
                clearFilters()
                confirm()
              }
              }
              size="small"
              style={{
                width: 90,
                backgroundColor: "#00756a",
                borderColor: "#00756a"
              }}
            >
              Reset
            </Button>
          </Space>
        </div >
      ),
      onFilter: (value, record) => {
        return record.candidate_details.email.toLowerCase().includes(value.toLowerCase());
      }
    }
    ,
    {
      title: "Phone",
      dataIndex: ["candidate_details", "phone"],
      key: "phone",
      width: 150,
      render: (phone) => renderValue(phone)
    },
    ...(selectedTab !== "reminderCount"
      ? [
        {
          title: "Current Location",
          dataIndex: ["candidate_details", "remarks", "currentLocation"],
          key: "currentLocation",
          width: 150,
          render: (currentLocation) => renderValue(currentLocation)
        },
        {
          title: "Total Years of Experience",
          dataIndex: [
            "candidate_details",
            "remarks",
            "totalYearsOfExperience"
          ],
          key: "totalYearsOfExperience",
          width: 150,
          render: (totalYearsOfExperience) =>
            renderValue(totalYearsOfExperience)
        },
        {
          title: "Relevant Experience",
          dataIndex: ["candidate_details", "remarks", "relevantExperience"],
          key: "relevantExperience",
          width: 150,
          render: (relevantExperience) => renderValue(relevantExperience)
        },

        {
          title: "Applied On",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 250,
          sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
          render: (text) => moment(text).format("MMM DD, YYYY hh:mm A")
        },

        {
          title: "Notice Period",
          dataIndex: ["candidate_details", "remarks", "noticePeriod"],
          key: "noticePeriod",
          width: 150,
          render: (noticePeriod) => renderValue(noticePeriod)
        },
        {
          title: "Last Working Day",
          dataIndex: ["candidate_details", "remarks", "lastWorkingDay"],
          key: "lastWorkingDay",
          width: 150,
          render: (lastWorkingDay) => {
            if (lastWorkingDay && moment(lastWorkingDay).isValid()) {
              return moment(lastWorkingDay).format("MMM DD, YYYY hh:mm A");
            }
            return "-";
          }
        },
        {
          title: "Current CTC",
          dataIndex: ["candidate_details", "remarks", "currentCTC"],
          key: "currentCTC",
          width: 150,
          render: renderCTC
        },
        {
          title: "Expected CTC",
          dataIndex: ["candidate_details", "remarks", "expectedCTC"],
          key: "expectedCTC",
          width: 150,
          render: renderCTC
        },
        {
          title: "Offer In Hand",
          dataIndex: ["candidate_details", "remarks", "offerInHand"],
          key: "offerInHand",
          width: 150,
          render: renderCTC
        },
        {
          title: "Education",
          dataIndex: ["candidate_details", "education"],
          key: "education",
          width: 300,
          render: (education) => (
            <ul>
              {education && education.length > 0 ? (
                <li>
                  {`${renderValue(education[0]?.degree)} in ${renderValue(
                    education[0]?.fieldOfStudy
                  )} at ${renderValue(education[0]?.school)} (${renderValue(
                    moment(education[0]?.startDate).format("MMM DD, YYYY")
                  )} - ${renderValue(
                    moment(education[0]?.endDate).format("MMM DD, YYYY")
                  )})`}
                </li>
              ) : (
                <li>-</li>
              )}
            </ul>
          )
        }
      ]
      : []),
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      width: 200,
      filters: JOB_APPLICATION_STAGE,
      onFilter: (value, record) => record.stage === value,
      render: (stage, record) => {
        const stageLabel = JOB_APPLICATION_STAGE.find(
          (option) => option.value === stage
        );
        const color = stageLabel
          ? stage === "final"
            ? "green"
            : "blue"
          : "gray"; // Change colors as needed
        return (
          <span>
            <Badge color={color} />{" "}
            {stageLabel ? stageLabel.text : renderValue(stage)}
          </span>
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      filters: JOB_APPLICATION_STATUS,
      onFilter: (value, record) => record.status.includes(value),
      render: (status, record) => {
        const statusLabel = JOB_APPLICATION_STATUS.find(
          (option) => option.value === status
        );
        const color = statusLabel
          ? status === "active"
            ? "green"
            : "blue"
          : "gray"; // Change colors as needed
        return (
          <span>
            <Badge color={color} />{" "}
            {statusLabel ? statusLabel.text : renderValue(status)}
          </span>
        );
      }
    },

    {
      title: "Actions",
      key: "actions",
      fixed: !isMobile ? "right" : undefined,
      width: 90,
      render: (text, record) => (
        <Space>
          <Dropdown
            overlay={actionMenu(record)}
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button
              onClick={(e) => e.preventDefault()}
              shape="circle"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Space>
      )
    }
  ];

  return (
    <>
      {loading?.job || loading?.updateJob ? (
        <div className="loader-center">
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <section className="job-header px-4 py-2">
            {window.innerWidth <= 768 ? (
              <div className="job-detail-header">
                <Row gutter={[16, 16]} justify="center" align="middle">
                  <Col xs={24} className="d-flex">
                    <h1 className="fw-bold" style={{ fontSize: "2rem" }}>
                      <Avatar
                        src={
                          jobDetails?.client?.company_photo
                            ? `${process.env.REACT_APP_IMAGES_BASE_URL}${jobDetails?.client?.company_photo}`
                            : undefined
                        }
                        icon={
                          !jobDetails?.client?.company_photo ? (
                            <UserOutlined />
                          ) : null
                        }
                        alt={jobDetails?.client?.company_name || "User"}
                        style={{ marginRight: 8 }}
                        size="large"
                      />
                      <a
                        className="text-decoration-underline cursor-pointer"
                        style={{ cursor: "pointer", color: "#005c53" }}
                        onClick={() => {
                          setViewJobDetailsModal({
                            show: true,
                            data: jobDetails
                          });
                        }}
                      >
                        {jobDetails?.job?.job_title}{" "}
                      </a>
                    </h1>
                    <div className="p-3">
                      <GetAssignBadgeData jobDetails={jobDetails} />
                    </div>
                  </Col>

                  <Col xs={24} md={10} className="d-flex me-2 pb-3">
                    <div>
                      <Button
                        className="candidate-db-2de5X candidate-db-14TuV candidate-db-2ySVn  main_border_radius"
                        onClick={() =>
                          setAddCandidate({
                            show: true,
                            type: "apply_resume"
                          })
                        }
                        style={{ width: "80%" }}
                      >
                        Add Resume
                      </Button>
                    </div>

                    <div>
                      <Button
                        className="candidate-db-2de5X candidate-db-14TuV candidate-db-2ySVn main_border_radius"
                        onClick={() => {
                          if (
                            jobAppliedCandidate?.applied_candidates.length > 0
                          ) {
                            downloadExcel(
                              jobAppliedCandidate?.applied_candidates,
                              jobDetails
                            );
                          } else {
                            message.info("No Reports Found!");
                          }
                        }}
                        style={{ width: "80%" }}
                      >
                        Download Reports
                      </Button>
                    </div>

                    <div>
                      <Dropdown
                        size="large"
                        className="candidate-db-2de5X candidate-db-2ySVn  border-round"
                        trigger={["click"]}
                        placement="bottomRight"
                        overlay={
                          <Menu>
                            {moreActionItems.map((item) => (
                              <Menu.Item key={item.key}>{item.label}</Menu.Item>
                            ))}
                          </Menu>
                        }
                      >
                        <Button
                          size="large"
                          onClick={(e) => e.preventDefault()}
                          shape="circle"
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="job-detail-header">
                <h1 className="fw-bold" style={{ fontSize: "2rem" }}>
                  <Avatar
                    src={
                      jobDetails?.client?.company_photo
                        ? `${process.env.REACT_APP_IMAGES_BASE_URL}${jobDetails?.client?.company_photo}`
                        : undefined
                    }
                    icon={
                      !jobDetails?.client?.company_photo ? (
                        <UserOutlined />
                      ) : null
                    }
                    alt={jobDetails?.client?.company_name || "User"}
                    style={{ marginRight: 8 }}
                    size="large"
                  />
                  <a
                    className="text-decoration-underline cursor-pointer"
                    style={{ cursor: "pointer", color: "#005c53" }}
                    onClick={() => {
                      setViewJobDetailsModal({
                        show: true,
                        data: jobDetails
                      });
                    }}
                  >
                    {jobDetails?.job?.job_title}{" "}
                  </a>
                </h1>
                <GetAssignBadgeData jobDetails={jobDetails} />
                <Row gutter={[16, 16]} justify="center" align="middle">
                  <Col xs={24} md={10}>
                    <Button
                      className="candidate-db-2de5X candidate-db-14TuV candidate-db-2ySVn mx-2 main_border_radius"
                      onClick={() =>
                        setAddCandidate({
                          show: true,
                          type: "apply_resume"
                        })
                      }
                      style={{ width: "100%" }} // Full width on mobile
                    >
                      Add Resume
                    </Button>
                  </Col>
                  <Col xs={24} md={10}>
                    <Button
                      className="candidate-db-2de5X candidate-db-14TuV candidate-db-2ySVn mx-2 main_border_radius"
                      onClick={() => {
                        if (
                          jobAppliedCandidate?.applied_candidates.length > 0
                        ) {
                          downloadExcel(
                            jobAppliedCandidate?.applied_candidates,
                            jobDetails
                          );
                        } else {
                          message.info("No Reports Found!");
                        }
                      }}
                      style={{ width: "100%" }}
                    >
                      Download Reports
                    </Button>
                  </Col>
                  <Col xs={24} md={4}>
                    <Dropdown
                      size="large"
                      className="candidate-db-2de5X  candidate-db-2ySVn mx-3 p-3 border-round"
                      trigger={["click"]}
                      placement="bottomRight"
                      overlay={
                        <Menu>
                          {moreActionItems.map((item) => (
                            <Menu.Item key={item.key}>{item.label}</Menu.Item>
                          ))}
                        </Menu>
                      }
                    >
                      <Button
                        size="large"
                        onClick={(e) => e.preventDefault()}
                        shape="circle"
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              </div>
            )}
            <div className="job-detail-header">
              <p>
                {jobDetails?.job.remote_options ? "Remote" : "On-site"} ·{" "}
                {jobDetails?.job.job_location}
              </p>
            </div>
            <div className="stage-row py-2">
              <div className="d-flex justify-content-between align-items-center">
                {window.innerWidth <= 768 ? (
                  <Row gutter={[16, 16]} className="stage-row py-2">
                    <Col span={24}>
                      <Select
                        size="large"
                        value={selectedTab}
                        onChange={setSelectedTab}
                        className="stage-dropdown"
                        style={{ width: "200px", display: "inline-block" }}
                      >
                        {APPLICATION_STAGE.map((item) => (
                          <Option
                            key={item.key}
                            value={item.key}
                            disabled={
                              jobAppliedCandidate?.stats?.[item.key] <= 0
                            }
                          >
                            <Tooltip
                              title={
                                jobAppliedCandidate?.stats?.[item.key] > 0
                                  ? `${item.label}: ${jobAppliedCandidate?.stats?.[item.key]
                                  }`
                                  : "Disabled due to 0 value"
                              }
                            >
                              <span
                                className={`stage-tab-label ${jobAppliedCandidate?.stats?.[item.key] <= 0
                                  ? "disabled-tab"
                                  : ""
                                  }`}
                              >
                                {item.label} (
                                {jobAppliedCandidate?.stats?.[item.key]})
                              </span>
                            </Tooltip>
                          </Option>
                        ))}
                      </Select>
                      <Button
                        icon={<ReloadOutlined />}
                        type="primary"
                        style={{
                          backgroundColor: "#00756a",
                          marginLeft: "16px",
                          display: "inline-block"
                        }}
                        onClick={() => {
                          dispatch(
                            getJobAllAppliedCandidateAction({
                              job_id: id,
                              stage:
                                selectedTab === "candidatesCount" ||
                                  selectedTab === "reminderCount"
                                  ? ""
                                  : selectedTab,
                              reminder:
                                selectedTab === "reminderCount" ? true : "",
                              page: currentPage,
                              limit: pageSize
                            })
                          );
                        }}
                      >
                        Refresh
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Tabs
                      activeKey={selectedTab}
                      onChange={setSelectedTab}
                      className="stage-tabs"
                    >
                      {APPLICATION_STAGE.map((item) => (
                        <TabPane
                          tab={
                            <Tooltip
                              title={
                                jobAppliedCandidate?.stats?.[item.key] > 0
                                  ? `${item.label}: ${jobAppliedCandidate?.stats?.[item.key]
                                  }`
                                  : "Disabled due to 0 value"
                              }
                            >
                              <span
                                className={`stage-tab-label ${jobAppliedCandidate?.stats?.[item.key] <= 0
                                  ? "disabled-tab"
                                  : ""
                                  }`}
                              >
                                {item.label} (
                                {jobAppliedCandidate?.stats?.[item.key]})
                              </span>
                            </Tooltip>
                          }
                          key={item.key}
                          disabled={jobAppliedCandidate?.stats?.[item.key] <= 0}
                        />
                      ))}
                    </Tabs>
                    <Button
                      icon={<ReloadOutlined />}
                      type="primary"
                      style={{ backgroundColor: "#00756a", marginLeft: "16px" }}
                      onClick={() => {
                        dispatch(
                          getJobAllAppliedCandidateAction({
                            job_id: id,
                            stage:
                              selectedTab === "candidatesCount" ||
                                selectedTab === "reminderCount"
                                ? ""
                                : selectedTab,
                            reminder:
                              selectedTab === "reminderCount" ? true : "",
                            page: currentPage,
                            limit: pageSize
                          })
                        );
                      }}
                    >
                      Refresh
                    </Button>
                  </>
                )}
              </div>
            </div>
          </section>

          <Table
            className={`${isMobile ? "px-2" : "px-8"}`}
            columns={columns}
            dataSource={jobAppliedCandidate?.applied_candidates}
            rowKey="_id"
            loading={
              loading?.candidate ||
              loading?.updateCandidate ||
              multipleActionReducer?.loading
            }
            onChange={(pagination, filters) => {
              console.log(filters);
              dispatch(
                getJobAllAppliedCandidateAction({
                  job_id: id,
                  stage:
                    selectedTab === "candidatesCount" ||
                      selectedTab === "reminderCount"
                      ? ""
                      : selectedTab,
                  reminder: selectedTab === "reminderCount" ? true : "",
                  pageSize,
                  page: currentPage,
                  ...filters
                })
              );
            }}
            footer={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <a
                  href="#"
                  onClick={() =>
                    setAddCandidate({
                      show: true,
                      type: "apply_resume"
                    })
                  }
                  style={{
                    color: "#00756a",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    cursor: "pointer"
                  }}
                >
                  Add Candidate (Resume)
                </a>
                <Pagination
                  className="text-center align-content-center px-4"
                  current={currentPage}
                  total={totalCandidate}
                  pageSize={pageSize}
                  showSizeChanger
                  onChange={(page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                  }}
                  pageSizeOptions={[5, 10, 20, 50, 100]}
                  showTotal={(total) => `Total ${total} Candidate`}
                  style={{ margin: "0 1rem" }}
                />
              </div>
            )}
            bordered
            scroll={{ x: 1300 }}
            pagination={false}
          />

          {addCandidate.show && (
            <AddResumeDrawer
              job_id={jobDetails?.job?.job_id}
              client_id={jobDetails?.job?.client_id}
              user_id={jobDetails?.job?.user_id}
              visible={addCandidate}
              setVisible={setAddCandidate}
            />
          )}
          {openDrawer.show && (
            <DetailSection
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
            />
          )}
          {openInterviewModal.show && (
            <InterviewScheduling
              visible={openInterviewModal}
              setVisible={setOpenInterviewModal}
            />
          )}
          {addRemarksModal.show && (
            <AddCandidateRemarksModal
              visible={addRemarksModal}
              setVisible={setAddRemarksModal}
            />
          )}
          {updateApplicantDetailsModal.show && (
            <UpdateApplicationDetails
              visible={updateApplicantDetailsModal}
              setVisible={setUpdateApplicantDetailsModal}
            />
          )}
          {reminderModal.show && (
            <ReminderModal
              visible={reminderModal}
              setVisible={setReminderModal}
            />
          )}
          {resumePreviewModal.show && resumePreviewModal?.resume_url && (
            <ResumePreviewSection
              visible={resumePreviewModal}
              setVisible={setResumePreviewModal}
            />
          )}

          {viewJobDetailsModal.show && viewJobDetailsModal.data && (
            <JobDetailsDrawer
              visible={viewJobDetailsModal}
              setVisible={setViewJobDetailsModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default JobDetail;
