import { useState, useEffect } from "react";
import { Select } from "antd";
const { Option } = Select;

const CustomSelect = ({ field, form, initialValues }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);

  // Update selectedValues whenever the field value in the form or initialValues change
  useEffect(() => {
    const initialValue = initialValues?.[field.name];
    setSelectedValues(
      initialValue !== undefined && initialValue !== null
        ? Array.isArray(initialValue)
          ? initialValue
          : [initialValue]
        : []
    );
    form.setFieldsValue({ [field.name]: initialValue });
  }, [initialValues, field.name, form]);

  const handleChange = (value) => {
    setSelectedValues(value);
    form.setFieldsValue({ [field.name]: value });
  };

  const handleSearch = (value) => {
    setInputValue(value);
  };

  const handleBlur = () => {
    if (inputValue && field.mode === "multiple") {
      if (!selectedValues.includes(inputValue)) {
        const newValues = [...selectedValues, inputValue];
        setSelectedValues(newValues);
        form.setFieldsValue({ [field.name]: newValues });
      }
      setInputValue("");
    }
  };

  return (
    <Select
      size="large"
      mode={field.mode === "single" ? undefined : "multiple"}
      allowClear
      showSearch
      value={selectedValues}
      onChange={handleChange}
      onSearch={handleSearch}
      onBlur={handleBlur}
      placeholder={field.title}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...(field.mode === "multiple" ? { maxTagTextLength: 10 } : {})}
    >
      {field.options &&
        field.options.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      {field.mode === "multiple" &&
        inputValue &&
        !selectedValues.includes(inputValue) && (
          <Option key={inputValue} value={inputValue}>
            {inputValue}
          </Option>
        )}
    </Select>
  );
};

export default CustomSelect;
