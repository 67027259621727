import {
  forgetPasswordLinkConstants,
  getuserDetailsConstants,
  getUserStatsConstants,
  loginUserConstants,
  logoutConstants,
  signupUserConstants,
  updatePasswordConstants,
  updateUserDetailsConstants
} from "../../constants";

export const signupUserReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case signupUserConstants.SIGNUP_USER_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case signupUserConstants.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        message: action.message,
        error: action.error
      };
    case signupUserConstants.SIGNUP_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case signupUserConstants.SIGNUP_USER_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const loginUserReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case loginUserConstants.LOGIN_USER_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case loginUserConstants.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        message: action.message,
        error: action.error
      };
    case loginUserConstants.LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case loginUserConstants.LOGIN_USER_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getUserDetailReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getuserDetailsConstants.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getuserDetailsConstants.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getuserDetailsConstants.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getuserDetailsConstants.GET_USER_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updateUserDetailReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updateUserDetailsConstants.UPDATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updateUserDetailsConstants.UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updateUserDetailsConstants.UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updateUserDetailsConstants.UPDATE_USER_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const forgetPasswordLinkReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case forgetPasswordLinkConstants.FORGET_PASSWORD_LINK_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case forgetPasswordLinkConstants.FORGET_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case forgetPasswordLinkConstants.FORGET_PASSWORD_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case forgetPasswordLinkConstants.FORGET_PASSWORD_LINK_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const updatePasswordReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case updatePasswordConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case updatePasswordConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case updatePasswordConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case updatePasswordConstants.UPDATE_PASSWORD_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const logoutUserReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case logoutConstants.LOGOUT_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case logoutConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case logoutConstants.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case logoutConstants.LOGOUT_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getUserStatsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getUserStatsConstants.GET_USER_STATS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getUserStatsConstants.GET_USER_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getUserStatsConstants.GET_USER_STATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getUserStatsConstants.GET_USER_STATS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
