import React, { useState, useEffect } from "react";
import {
  Collapse,
  Button,
  Radio,
  Slider,
  Input,
  DatePicker,
  Table,
  Select,
  Layout,
  Divider,
  message,
  Tag,
  Space
} from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllAssessmentCandidateAction } from "../../../redux/actions/assessment";
import { TailSpin } from "react-loader-spinner";
import Breadcrumbs from "../../../Utils/BreadCrump";
import { Navigate, useNavigate } from "react-router-dom";
import { ASSESSMENT_STAGE_OPTION, ASSESSMENT_STATUS_OPTION } from "../data";

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Sider, Content } = Layout;

const AssessmentCandidates = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    status: "",
    totalScore: [],
    invitedOn: [],
    expiryDate: [],
    stage: ""
  });
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCandidates, setTotalCandidates] = useState(0);

  const getAllAssessmentCandidateReducer = useSelector(
    (state) => state.getAllAssessmentCandidateReducer
  );

  useEffect(() => {
    dispatch(
      getAllAssessmentCandidateAction({
        page: 1,
        limit: 10000,
        ...filters
      })
    );
    return () => {
      dispatch({ type: "GET_ALL_ASSESSMENT_CANDIDATE_RESET" });
    };
  }, [dispatch, filters]);

  useEffect(() => {
    const { loading, data, error } = getAllAssessmentCandidateReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setCandidateData(data.data);
      setTotalCandidates(data.totalInvites);
      setFilteredData(data.data);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getAllAssessmentCandidateReducer]);

  useEffect(() => {
    const filtered = candidateData.filter((item) => {
      return (
        item.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.email.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredData(filtered);
  }, [searchText, candidateData]);

  const navigate = useNavigate();
  const columns = [
    {
      title: "Candidate",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Candidate"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const fullName = `${record.firstName} ${record.lastName}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      },
      render: (text, record) => (
        <a>{`${record.firstName} ${record.lastName}`}</a>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Email"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ backgroundColor: "#00756a", color: "white" }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.email.toLowerCase().includes(value.toLowerCase())
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 250,
      filters: ASSESSMENT_STATUS_OPTION.map((option) => ({
        text: option.label,
        value: option.value
      })),
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        const statusOption = ASSESSMENT_STATUS_OPTION.find(
          (option) => option.value === status
        );
        return statusOption ? (
          <Tag color={statusOption.color}>{statusOption.label}</Tag>
        ) : (
          status
        );
      }
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      width: 250,
      filters: ASSESSMENT_STAGE_OPTION.map((option) => ({
        text: option.label,
        value: option.value
      })),
      onFilter: (value, record) => record.stage === value,
      render: (stage) => {
        const stageOption = ASSESSMENT_STAGE_OPTION.find(
          (option) => option.value === stage
        );
        return stageOption ? stageOption.label : stage;
      }
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (createdAt) => {
        const date = new Date(createdAt);
        return date
          .toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
          })
          .replace(",", "");
      },
      width: 200
    }
  ];
  const handleReset = () => {
    setFilters({
      status: "",
      totalScore: [],
      invitedOn: [],
      expiryDate: [],
      stage: ""
    });
    setSearchText("");
  };

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleRefresh = () => {
    setSearchText("");
    dispatch(
      getAllAssessmentCandidateAction({
        page: 1,
        limit: 10000,
        ...filters
      })
    );
  };

  return (
    <>
      <div className="main-header bg-white" data-ui="header">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                  Candidates
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Layout style={{ background: "#fff", minHeight: "100vh" }}>
        <Sider width={300} style={{ background: "#fff" }}>
          <div
            className="p-3 bg-light"
            style={{ width: "100%", background: "#fff" }}
          >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Button type="ghost">
                <span className="d-flex align-items-center">
                  <h2 className="m-0 ml-2">Filters</h2>
                </span>
              </Button>
              <Button type="" className="cursor-pointer" onClick={handleReset}>
                Reset all
              </Button>
            </div>
            <Collapse
              defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
              expandIconPosition="right"
              style={{ background: "#fff", border: "none" }}
            >
              <Panel header="Candidate status" key="1">
                <Radio.Group
                  value={filters?.status}
                  onChange={(e) => handleChange("status", e.target.value)}
                >
                  <div className="d-flex justify-content-between mb-2">
                    <Radio value="">All Candidates</Radio>
                    <span className="text-muted">{totalCandidates}</span>
                  </div>
                  {ASSESSMENT_STATUS_OPTION?.map((item) => (
                    <div
                      className="d-flex justify-content-between mb-2"
                      key={item.value}
                    >
                      <Radio value={item?.value}>{item?.label}</Radio>
                    </div>
                  ))}
                </Radio.Group>
              </Panel>
              <Panel header="Candidate stage" key="2">
                <Radio.Group
                  value={filters?.stage}
                  onChange={(e) => handleChange("stage", e.target.value)}
                >
                  <div className="d-flex justify-content-between mb-2">
                    <Radio value="">All </Radio>
                    <span className="text-muted">{totalCandidates}</span>
                  </div>
                  {ASSESSMENT_STAGE_OPTION?.map((item) => (
                    <div
                      className="d-flex justify-content-between mb-2"
                      key={item.value}
                    >
                      <Radio value={item?.value}>{item?.label}</Radio>
                    </div>
                  ))}
                </Radio.Group>
              </Panel>
              <Panel header="Total score" key="3">
                <Slider
                  range
                  marks={{ 0: "0", 135: "135" }}
                  defaultValue={[0, 135]}
                  value={filters?.totalScore}
                  max={135}
                  onChange={(value) => handleChange("totalScore", value)}
                />
                <div className="d-flex justify-content-between">
                  <Input
                    placeholder="From"
                    value={filters?.totalScore[0]}
                    style={{ width: "48%" }}
                    onChange={(e) =>
                      handleChange("totalScore", [
                        Number(e.target.value),
                        filters?.totalScore[1]
                      ])
                    }
                  />
                  <Input
                    placeholder="To"
                    value={filters?.totalScore[1]}
                    style={{ width: "48%" }}
                    onChange={(e) =>
                      handleChange("totalScore", [
                        filters?.totalScore[0],
                        Number(e.target.value)
                      ])
                    }
                  />
                </div>
              </Panel>

              <Panel header="Invited on" key="4">
                <RangePicker
                  style={{ width: "100%" }}
                  value={filters?.invitedOn}
                  onChange={(dates) => handleChange("invitedOn", dates)}
                />
              </Panel>
              <Panel header="Completed on" key="5">
                <RangePicker
                  style={{ width: "100%" }}
                  value={filters?.completedOn}
                  onChange={(dates) => handleChange("completedOn", dates)}
                />
              </Panel>
            </Collapse>
          </div>
        </Sider>

        <Divider type="vertical" style={{ height: "100vh" }} />

        <Layout>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <Content className="px-3" style={{ minHeight: 280 }}>
              <div className="data-preview">
                <section className="candidate-listing-grid__row-section ">
                  <div className="cl-section-header row justify-content-between align-items-end py-3">
                    <div className="col d-flex align-items-center">
                      <form className="candidate-search-input-form-theme-3">
                        <div className="form-group">
                          <label htmlFor="cl-search-input" className="d-none">
                            Search Candidate
                          </label>
                          <div className="input-group">
                            <Input
                              id="cl-search-input"
                              type="search"
                              placeholder="Search by candidate name or email"
                              value={searchText}
                              onChange={(e) => handleSearch(e.target.value)}
                              prefix={<SearchOutlined />}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
                      <Button
                        className="ms-2"
                        onClick={handleRefresh}
                        icon={<ReloadOutlined />}
                        type="primary"
                        style={{ backgroundColor: "#00756a" }}
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>
                  <div className="candidate-listing">
                    <div className="candidate-listing-table__container">
                      <Table
                        bordered
                        dataSource={filteredData}
                        columns={columns}
                        pagination={{ pageSize: 10 }}
                        // rowSelection={{ type: "checkbox" }}
                        scroll={{ x: "100%" }}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </Content>
          )}
        </Layout>
      </Layout>
    </>
  );
};

export default AssessmentCandidates;
