import React, { useEffect, useState, useMemo } from "react";
import { Avatar, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { REACT_USER_TYPE } from "../../Components/Auth/tokenProvider";
import { getClientListAction } from "../../redux/actions/common";
import { UserOutlined } from "@ant-design/icons";
import { isMentorpalTeamUser } from "../Common";

const { Option } = Select;

const ClientCustomSelect = ({ value, onChange, type }) => {
  const dispatch = useDispatch();
  const userType = useMemo(() => REACT_USER_TYPE(), []);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [clients, setClients] = useState([]);

  const { data = [], loading } = useSelector(
    (state) => state.getClientListReducer || {}
  );

  const fetchedClients = Array.isArray(data?.data) ? data.data : [];

  useEffect(() => {
    if (isMentorpalTeamUser()) {
      dispatch(getClientListAction({ page, limit: 50 }));
    }
    return () => {
      dispatch({ type: "GET_CLIENT_LIST_RESET" });
    };
  }, [userType, dispatch, page]);

  useEffect(() => {
    if (fetchedClients.length > 0) {
      setClients((prevClients) => {
        const existingClientIds = new Set(
          prevClients.map((client) => client.client_id)
        );
        const newClients = fetchedClients.filter(
          (client) => !existingClientIds.has(client.client_id)
        );
        const updatedClients = [...prevClients, ...newClients];

        if (fetchedClients.length < 50) {
          setHasMore(false);
        }

        return updatedClients;
      });
    } else if (page > 1 && fetchedClients.length === 0) {
      setHasMore(false);
    }
  }, [fetchedClients]);

  const handlePopupScroll = (event) => {
    const { target } = event;
    if (
      target.scrollTop + target.offsetHeight >= target.scrollHeight - 1 &&
      hasMore &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <Select
      size="large"
      className="w-100"
      showSearch

      value={value}
      onChange={handleChange}
      placeholder="Select a Client"
      onPopupScroll={handlePopupScroll}
      notFoundContent={loading ? <Spin size="small" /> : "No clients found"}
      filterOption={(input, option) => {
        const user = clients.find((user) => user.client_id === option.value);
        if (user) {
          const fullName = `${user.company_name}`;
          return fullName.toLowerCase().includes(input.toLowerCase());
        }
        return false;
      }}
    >
      {type === "filter" && <Option value="">All</Option>}
      {clients.length > 0 ? (
        clients.map((client) => (
          <Option key={client.client_id} value={client.client_id}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 10px" // Adjust padding for compactness
              }}
            >
              <Avatar
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: "50%",
                  marginRight: 8
                }}
                src={
                  client?.company_photo
                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${client?.company_photo}`
                    : null
                }
                icon={!client?.company_photo && <UserOutlined />}
              />
              <div style={{ flex: 1 }}>
                <strong style={{ display: "block", fontSize: "14px" }}>
                  {client.company_name}
                </strong>
              </div>
            </div>
          </Option>
        ))
      ) : (
        <Option disabled>No clients available</Option>
      )}
    </Select>
  );
};

export default ClientCustomSelect;
