import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Layout,
  Table,
  message,
  Select,
  Drawer
} from "antd";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getClientReportAction } from "../../redux/actions/reports&Activity";
import moment from "moment";
import { ReloadOutlined } from "@ant-design/icons";
import("../../Assets/css/filter.css");

const { Sider, Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterForm = ({ filters, handleFilterChange, handleClearFilters }) => (
  <Form
    layout="vertical"
    onValuesChange={(changedValues) => {
      Object.keys(changedValues).forEach((key) =>
        handleFilterChange(key, changedValues[key])
      );
    }}
    initialValues={filters}
  >
    <div className="d-flex justify-content-between align-items-center mb-3">
      <span className="d-flex align-items-center">
        <h2 className="m-0 ml-2 mt-3">Filters</h2>
      </span>
      <Button className="cursor-pointer mt-3" onClick={handleClearFilters}>
        Reset all
      </Button>
    </div>
    <Collapse
      defaultActiveKey={["1", "2", "3", "4"]}
      expandIconPosition="right"
      style={{ background: "#fff", border: "none" }}
    >
      <Panel
        header="Period"
        key="3"
        style={{ background: "#ffff", border: "none" }}
      >
        <Select
          value={filters.period}
          onChange={(value) => handleFilterChange("period", value)}
          style={{ width: "100%" }}
          size="large"
        >
          <Option value="day">Day</Option>
          <Option value="week">Week</Option>
          <Option value="month">Month</Option>
          <Option value="quarter">Quarter</Option>
          <Option value="year">Year</Option>
        </Select>
      </Panel>
      <Panel
        header="Date Range"
        key="4"
        style={{ background: "#ffff", border: "none" }}
      >
        <RangePicker
          size="large"
          value={[
            filters.startDate ? moment(filters.startDate) : null,
            filters.endDate ? moment(filters.endDate) : null
          ]}
          onChange={(dates) => {
            handleFilterChange(
              "startDate",
              dates ? dates[0].toISOString() : null
            );
            handleFilterChange(
              "endDate",
              dates ? dates[1].toISOString() : null
            );
          }}
          format="YYYY-MM-DD"
          style={{ width: "100%" }}
          disabledDate={(current) => {
            return current && current > moment().endOf("day");
          }}
        />
      </Panel>
    </Collapse>
  </Form>
);

const ClientReport = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    client_id: "",
    period: "day",
    startDate: null,
    endDate: null
  });

  useEffect(() => {
    dispatch(
      getClientReportAction({
        ...filters
      })
    );
    return () => {
      dispatch({ type: "GET_CLIENT_REPORT_RESET" });
    };
  }, [dispatch, filters]);

  const [isMobileFilterDrawerVisible, setIsMobileFilterDrawerVisible] =
    useState(false);

  const getClientReportReducer = useSelector(
    (state) => state.getClientReportReducer
  );

  useEffect(() => {
    const { data, loading, error } = getClientReportReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.data);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getClientReportReducer]);

  const handleFilterChange = (key, value) => {
    if (key === "startDate" || key === "endDate") {
      value = value ? moment(value).toISOString() : null;
    }
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const handleClearFilters = () => {
    setFilters({
      client_id: "",
      period: "day",
      startDate: null,
      endDate: null
    });
  };

  const toggleFilters = () =>
    setIsMobileFilterDrawerVisible(!isMobileFilterDrawerVisible);
  const closeDrawer = () => setIsMobileFilterDrawerVisible(false);
  const isMobile = window.innerWidth <= 768;

  const calculateRowSpan = (data) => {
    let rowSpanMap = {};
    let previousPeriod = null;
    let currentRowSpan = 0;

    data.forEach((row, index) => {
      if (row.period !== previousPeriod) {
        if (currentRowSpan > 0) {
          rowSpanMap[`${previousPeriod}-${index - currentRowSpan}`] =
            currentRowSpan;
        }
        previousPeriod = row.period;
        currentRowSpan = 1;
      } else {
        currentRowSpan += 1;
      }
    });

    if (currentRowSpan > 0) {
      rowSpanMap[`${previousPeriod}-${data.length - currentRowSpan}`] =
        currentRowSpan;
    }

    return rowSpanMap;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 150
    },
    {
      title: "Application Count",
      dataIndex: "applicationCount",
      key: "applicationCount",
      ellipsis: true,
      width: 150
    },
    {
      title: "Offer Count",
      dataIndex: "offerCount",
      key: "offerCount",
      ellipsis: true,
      width: 150
    },
    {
      title: "Hired Count",
      dataIndex: "hiredCount",
      key: "hiredCount",
      ellipsis: true,
      width: 150
    },
    {
      title: "Interview Count",
      dataIndex: "interviewCount",
      key: "interviewCount",
      ellipsis: true,
      width: 150
    },
    {
      title: "Interview Happened",
      dataIndex: "interviewHappenedCount",
      key: "interviewHappenedCount",
      ellipsis: true,
      width: 150
    }
  ];

  const getDataSource = () => {
    const rawData = Object.entries(data).flatMap(([period, records]) => {
      return records.map((record, index) => ({
        key: `${period}-${index}`,
        period,
        ...record
      }));
    });

    const sortedData = rawData.sort((a, b) => {
      const dateA = moment(a.period, "MMM D, YYYY");
      const dateB = moment(b.period, "MMM D, YYYY");

      return dateB - dateA;
    });

    const rowSpanMap = calculateRowSpan(sortedData);

    return { sortedData, rowSpanMap };
  };

  const { sortedData, rowSpanMap } = getDataSource();

  const handleRefresh = () => {
    dispatch(
      getClientReportAction({
        ...filters
      })
    );
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between ">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                  Report
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Layout style={{ background: "#fff", height: "100vh" }}>
        {!isMobile && (
          <Sider width={300} className="site-layout-background bg-light">
            <div className="px-3 bg-light" style={{ height: "100vh" }}>
              <FilterForm
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleClearFilters={handleClearFilters}
              />
            </div>
          </Sider>
        )}
        <Layout>
          <Button
            className="filter-toggle-button"
            onClick={toggleFilters}
            type="primary"
            style={{ backgroundColor: "#00756a" }}
          >
            Apply Filters
          </Button>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <Content className="px-4 ">
              <div className="cl-section-header row justify-content-between align-items-end py-3">
                <div className="col d-flex align-items-center"></div>
                <div className="cl-section-header__action-buttons-wrapper col-auto d-flex align-items-center">
                  <Button
                    className="ms-2"
                    onClick={handleRefresh}
                    icon={<ReloadOutlined />}
                    type="primary"
                    style={{ backgroundColor: "#00756a" }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>

              <Table
                columns={[
                  {
                    title: "Period",
                    dataIndex: "period",
                    key: "period",
                    width: isMobile ? 150 : 250,
                    render: (value, row, index) => {
                      const rowSpan = rowSpanMap[`${row.period}-${index}`] || 0;
                      const isCurrent =
                        moment().isSame(value, "day") ||
                        moment().isSame(value, "month") ||
                        moment().isSame(value, "year");
                      const periodStyle = isCurrent
                        ? { backgroundColor: "#e6f7ff" }
                        : {};
                      return {
                        children: <div style={periodStyle}>{value}</div>,
                        props: {
                          rowSpan
                        }
                      };
                    }
                  },
                  ...columns
                ]}
                dataSource={sortedData}
                pagination={false}
                rowKey="key"
                scroll={isMobile ? { x: 500 } : { x: "max-content" }}
              />
            </Content>
          )}
        </Layout>
      </Layout>
      <Drawer
        visible={isMobileFilterDrawerVisible}
        onClose={closeDrawer}
        placement="left"
        width="100%"
        className="filter-drawer"
        bodyStyle={{ paddingTop: 0 }}
      >
        <FilterForm
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
        />
      </Drawer>
    </>
  );
};

export default ClientReport;
