import {
  Col,
  Divider,
  Drawer,
  Input, message,
  Row, Table, Typography
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionDetailAction } from "../../../../redux/actions/assessment";
import { TailSpin } from "react-loader-spinner";
import { Editor } from "@monaco-editor/react";
import { QuillTextPreview } from "../../../../Utils/TextEditor";
import { QUESTION_TYPE } from "../../data";
import { useIsMobileView } from "../../../../Utils/CustomHooks/windowSize";
const { Title, Text } = Typography;
const { TextArea } = Input;

const QuestionDrawer = ({ drawerVisible, setDrawerVisible, question_id }) => {
  const dispatch = useDispatch();

  const editorRef = useRef();

  useEffect(() => {
    dispatch(getQuestionDetailAction({ question_id: question_id }));
  }, [dispatch, question_id]);

  const getQuestionDetailsReducer = useSelector(
    (state) => state.getQuestionDetailsReducer
  );

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { loading, data, error } = getQuestionDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data.data);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getQuestionDetailsReducer]);

  const QUESTION_TYPE_FORMAT = (type) => {
    const questionType = QUESTION_TYPE.find((option) => option.value === type);
    return questionType ? questionType.label : type;
  };
  const renderField = (type) => {
    switch (type) {
      case "code":
      case "approx":
        return (
          <>
            <Title level={4}>Languages</Title>
            <Text>{data?.languages}</Text>

            <Divider />
            <Title level={4}>Code Stubs</Title>

            <Editor
              height="75vh"
              theme="vs-dark"
              language={data?.languages}
              onMount={(editor) => {
                editorRef.current = editor;
                editor.focus();
              }}
              value={data?.codeStubs}
              options={{
                minimap: {
                  enabled: false
                }
              }}
            />
            <Divider />
          </>
        );
      case "design":
        return (
          <>
            <Title level={4}>HTML</Title>
            <Editor
              height="75vh"
              theme="vs-dark"
              language={data.language || "javascript"}
              onMount={(editor) => {
                editorRef.current = editor;
                editor.focus();
              }}
              options={{
                minimap: {
                  enabled: false
                }
              }}
              value={data?.codeStubs?.html}
            />
            <Divider />
            <Title level={4}>CSS</Title>
            <Editor
              height="75vh"
              theme="vs-dark"
              language={data.language || "javascript"}
              onMount={(editor) => {
                editorRef.current = editor;
                editor.focus();
              }}
              options={{
                minimap: {
                  enabled: false
                }
              }}
              value={data?.codeStubs?.css}
            />
            <Divider />
            <Title level={4}>JavaScript</Title>
            <Editor
              height="75vh"
              theme="vs-dark"
              language={data.language || "javascript"}
              onMount={(editor) => {
                editorRef.current = editor;
                editor.focus();
              }}
              options={{
                minimap: {
                  enabled: false
                }
              }}
              value={data?.codeStubs?.js}
            />
          </>
        );
      case "database":
        return (
          <>
            <Title level={4}>Database Setup</Title>
            <Text>
              <strong>Type:</strong> {data?.databaseSetup.databaseType}
            </Text>
            <Divider />
            <Text>
              <strong>Table IDE:</strong> {data?.databaseSetup.tableIDE}
            </Text>
            <Divider />
            <Text>
              <strong>Expected Output:</strong>{" "}
              {data?.databaseSetup.expectedOutput}
            </Text>
          </>
        );
      case "mcq":
        return (
          <>
            <Title level={4}>Choices</Title>
            <Table
              columns={[
                {
                  title: "Choices",
                  dataIndex: "index",
                  key: "index",
                  render: (text, record, index) => `Choice- ${index + 1}`
                },
                {
                  title: "Value",
                  dataIndex: "value",
                  key: "value"
                }
              ]}
              dataSource={data?.mcqChoices.map((choice, index) => ({
                ...choice,
                index
              }))}
              pagination={false}
              bordered
            />
          </>
        );
      case "questionnaire":
        return (
          <>
            <Title level={4}>Choices</Title>
            <Table
              columns={[
                {
                  title: "Choices",
                  dataIndex: "index",
                  key: "index",
                  render: (text, record, index) => `Choice- ${index}`
                },
                {
                  title: "Value",
                  dataIndex: "value",
                  key: "value"
                },
                {
                  title: "Marks",
                  dataIndex: "score",
                  key: "score"
                }
              ]}
              dataSource={data?.questionnaireChoices.map((choice, index) => ({
                ...choice,
                key: index
              }))}
              pagination={false}
              bordered
            />
          </>
        );
      case "textAns":
        return <TextArea rows={4} value={data?.problemDescription} readOnly />;
      case "whiteboard":
        return <TextArea rows={4} value={data?.whiteboard} readOnly />;
      case "diagram":
        return <TextArea rows={4} value={data?.diagram} readOnly />;
      case "complete":
        return <TextArea rows={4} value={data?.completionString} readOnly />;
      case "file_upload":
        return (
          <Text>
            {data?.file_upload
              ? "File upload required"
              : "No file upload required"}
          </Text>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Drawer
        title={null}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={useIsMobileView() ? "100%" : "60%"}

        closable={false}
        className="call-details-drawer bg-white"
        styles={{ body: { padding: 0 }, background: "white" }}
      >
        <div className="ant-body bg-white" style={{ background: "white" }}>
          <Row className="details-header">
            <Col xs={21} md={21}>
              <Text className="details-title">{`Question: ${data?.questionName}`}</Text>
            </Col>
            <Col xs={3} md={2} style={{ textAlign: "end" }}>
              <svg
                onClick={() => setDrawerVisible(false)}
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                className="drawer-close-icon"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z" />
              </svg>
            </Col>
          </Row>
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999
              }}
            >
              <TailSpin ariaLabel="Loading..." color="#005c53" />
            </div>
          ) : (
            <>
              <Row gutter={16} className="px-5 py-3">
                <Col span={12}>
                  <Title level={4}>Problem Description</Title>
                  <QuillTextPreview content={data?.problemDescription} />
                  <Divider />
                  <Title level={4}>Recommended Time</Title>
                  <Text>{data?.recommendedTime} mins</Text>
                  {data?.tags && data?.tags.length > 0 && (
                    <>
                      <Divider />
                      <Title level={4}>Tags</Title>
                      {data?.tags?.map((item) => (
                        <Text>{item}</Text>
                      ))}
                    </>
                  )}
                  <Divider />

                  <Title level={4}>Question Type</Title>
                  <Text>{QUESTION_TYPE_FORMAT(data?.questionType)}</Text>

                  {data?.questionType === "code" && (
                    <>
                      <Divider />
                      <Title level={4}>Test Cases</Title>

                      <Table
                        columns={[
                          {
                            title: "S.No",
                            dataIndex: "index",
                            key: "index",
                            render: (text, record, index) => index + 1
                          },
                          {
                            title: "Input",
                            dataIndex: "input",
                            key: "input"
                          },
                          {
                            title: "Output",
                            dataIndex: "output",
                            key: "output"
                          }
                        ]}
                        dataSource={data?.testCases.map((item, index) => ({
                          ...item,
                          index
                        }))}
                        pagination={false}
                        bordered
                      />
                    </>
                  )}
                </Col>
                <Col span={12}>{renderField(data?.questionType)}</Col>
              </Row>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default QuestionDrawer;
