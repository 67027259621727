import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import {
  Button,
  Form,
  Input,
  message,
  Checkbox,
  Select,
  Row,
  Modal,
  Col
} from "antd";
import { updateUserDetailsAction } from "../../redux/actions/user";
import {
  createRoleUserAction,
  getRoleUSerDetailsAction
} from "../../redux/actions/Roles&Management";
import {
  ASSESSMENT_OWNER_ROLES,
  ATS_OWNER_ROLES,
  MENTORPAL_OWNER_ROLES
} from "../../Assets/data/common";
import { REACT_USER_TYPE } from "../Auth/tokenProvider";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ImageUploadComponent from "../Upload";
import ReportToUserSelection from "../../Utils/ClientSelect/ReportToUserList";
import ClientCustomSelect from "../../Utils/ClientSelect/clientSelect";
const MasterAdminCreateNewUser = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [userType, setUserType] = useState(false);
  const user_type = REACT_USER_TYPE();
  const [roleOptions, setRoleOptions] = useState([]);
  const [clientId, setClientId] = useState(null);

  const getRoleUSerDetailsReducer = useSelector(
    (state) => state.getRoleUSerDetailsReducer
  );
  const createRoleUserReducer = useSelector(
    (state) => state.createRoleUserReducer
  );
  const updateUserDetailReducer = useSelector(
    (state) => state.updateUserDetailReducer
  );

  useEffect(() => {
    if (userId) {
      dispatch(getRoleUSerDetailsAction({ user_id: userId }));
    }
    return () => {
      dispatch({ type: "USER_DETAILS_RESET" });
      form.resetFields();
    };
  }, [userId, dispatch]);

  useEffect(() => {
    const { loading, data, error } = getRoleUSerDetailsReducer;
    setLoading(loading);
    if (!loading && userId) {
      if (data && !error) {
        form.setFieldsValue(data);
        setUserType(data?.user_type);
        setClientId(data?.client_id);
      } else if (error) {
        console.warn("Error:", error);
        message.error(error);
      }
    }
  }, [getRoleUSerDetailsReducer, form]);

  useEffect(() => {
    const { loading, data, error } = createRoleUserReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        message.success("User created!");
        navigate("/dashboard/user-management");
        form.resetFields();
      } else if (error) {
        console.warn("create user error:", error);
        message.error(error);
      }
    }
  }, [createRoleUserReducer, navigate]);

  useEffect(() => {
    const { loading, data, error } = updateUserDetailReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        message.success("Details updated!");
        navigate("/dashboard/user-management");
        form.resetFields();
      } else if (error) {
        console.warn("update user error:", error);
        message.error(error);
      }
    }
  }, [updateUserDetailReducer, navigate]);

  const handleCreate = async (values) => {
    try {
      dispatch(createRoleUserAction(values));
    } catch (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  };

  const handleUpdate = async (values) => {
    Modal.confirm({
      title: "Are you sure you want to update this user?",
      icon: <ExclamationCircleOutlined />,
      content: "This action will apply changes immediately.",
      okText: "Yes, Update",
      cancelText: "No, Cancel",
      onOk() {
        const updateData = {
          client_id: form.getFieldValue("client_id"),
          ...values,
          user_id: userId
        };

        try {
          dispatch(updateUserDetailsAction(updateData));
        } catch (error) {
          console.warn("Error:", error);
          message.error(error);
        }
      }
    });
  };

  const onFinish = async () => {
    try {
      const values = await form.validateFields();

      if (userId) {
        await handleUpdate({ ...values, email_send: isChecked });
      } else {
        await handleCreate({ ...values, email_send: isChecked });
      }
    } catch (error) {
      message.error("Failed to add/update user");
    }
  };
  useEffect(() => {
    switch (user_type) {
      case "mentorpal-owner":
      case "mentorpal-admin":
        setRoleOptions(MENTORPAL_OWNER_ROLES);
        break;
      case "ats-owner":
      case "ats-admin":
        setRoleOptions(ATS_OWNER_ROLES);
        break;
      case "assessment-owner":
      case "assessment-admin":
        setRoleOptions(ASSESSMENT_OWNER_ROLES);
        break;
      default:
        setRoleOptions([]);
        break;
    }
  }, [user_type]);
  return (
    <>
      <div className="main-header bg-white" data-ui="header">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                  Management
                </h1>
              </a>
            </div>
            <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
              <Button
                type="button"
                className="btn btn--primary mt-m text-white pull-right main_border_radius"
                onClick={() => navigate("/dashboard/user-management")}
              >
                Exit
              </Button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="container px-4 py-3 mb-40 s withBorder">
            <Form form={form} layout="vertical" onFinish={onFinish}>
              {user_type === "mentorpal-owner" ||
                user_type === "mentorpal-admin" ? (
                <>
                  <div className="container pt-3 p-0">
                    <div
                      className={`flex items-center p-4 mb-4 text-sm text-green-900 bg-green-500/20 rounded-lg`}
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 me-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Info</span>
                      <div>
                        <span className="font-medium">
                          You need to create a client first!{" "}
                          <Link to="/dashboard/create-client">
                            Create Client
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>

                  <Form.Item
                    name="client_id"
                    label="Select Client/Company"
                    rules={[
                      {
                        required: true,
                        message: "Please select a Client/Company"
                      }
                    ]}
                  >
                    <ClientCustomSelect
                      type={"form"}
                      value={form?.getFieldValue("client_id") || ""}
                      onChange={(value) => {
                        setClientId(value);
                        form.setFieldsValue({ client_id: value });
                      }}
                    />
                  </Form.Item>
                </>
              ) : null}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[
                      { required: true, message: "Please enter First Name" }
                    ]}
                  >
                    <Input placeholder="Enter first name" size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[
                      { required: true, message: "Please enter Last Name" }
                    ]}
                  >
                    <Input placeholder="Enter last name" size="large" />
                  </Form.Item>
                </Col>
              </Row>
              {!userId && (
                <>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: "Please enter email" }]}
                  >
                    <Input placeholder="Enter email" size="large" />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="password"
                label="Password (Optional)"
                rules={[
                  {
                    required: false,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value.length < 8) {
                        return Promise.reject(
                          new Error("Password must be at least 8 characters long.")
                        );
                      }
                      if (value && !/[a-z]/.test(value)) {
                        return Promise.reject(
                          new Error("Password must contain at least one lowercase letter.")
                        );
                      }
                      if (value && !/[A-Z]/.test(value)) {
                        return Promise.reject(
                          new Error("Password must contain at least one uppercase letter.")
                        );
                      }
                      if (value && !/\d/.test(value)) {
                        return Promise.reject(
                          new Error("Password must contain at least one number.")
                        );
                      }
                      if (value && !/[@$!%*?&]/.test(value)) {
                        return Promise.reject(
                          new Error("Password must contain at least one special character.")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Enter password" size="large" />
              </Form.Item>



              <Form.Item name="photo" label="Profile Photo(optional)">
                <ImageUploadComponent
                  uploadPath="hire_talent/profile/"
                  acceptedFileTypes="image/*"
                  uploadType="Photo"
                  onFileUpload={(profileImageUrl) =>
                    form.setFieldsValue({ ["photo"]: profileImageUrl })
                  }
                  existingFileUrl={form.getFieldValue("photo")}
                />
              </Form.Item>



              <Form.Item
                name="user_type"
                label="Role"
                rules={[{ required: true, message: "Please select the role!" }]}
              >
                <Select size="large" placeholder="Select a role" onChange={(value) => setUserType(value)}>
                  {roleOptions.map((role) => (
                    <Select.Option key={role.value} value={role.value}>
                      {role.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {!["assessment-owner", "ats-owner", "mentorpal-owner"].includes(
                userType
              ) && (
                  <Form.Item
                    key={clientId}
                    name="report_to"
                    label="Select a Manager (Reports to)"
                    rules={[
                      { required: true, message: "Please select a Manager!" }
                    ]}
                  >
                    <ReportToUserSelection
                      form={form}
                      fieldName={"report_to"}
                      filter={{
                        client_id: clientId,
                        selective: false
                      }}
                    />
                  </Form.Item>
                )}

              {!userId && (
                <Form.Item
                  name="email_send"
                  valuePropName="checked"
                  className="pb-10"
                >
                  <Checkbox
                    className="pb-10"
                    size="large"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  >
                    Do you want to send email notification?
                  </Checkbox>
                </Form.Item>
              )}
            </Form>
          </div>
          <footer className="footer d-block">
            <div
              className="button-container"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="custom-button"
                loading={loading}
                type="primary"
                onClick={onFinish}
              >
                {userId ? "Update User" : "Submit"}
              </Button>
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default MasterAdminCreateNewUser;
