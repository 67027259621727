import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Button,
  Form,
  Input,
  Modal,
  Row,
  Col,
  message,
  Avatar,
  Divider
} from "antd";
import LocationSelect from "../../../Utils/locationSelector";
import { CloseOutlined } from "@ant-design/icons";
import {
  candidateProfileAction,
  updateCandidateAction
} from "../../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import { UserOutlined } from "@ant-design/icons";
import ImageUploadComponent from "../../Upload";
import { useIsMobileView } from "../../../Utils/CustomHooks/windowSize";


const UpdateApplicationDetails = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const updateCandidateReducer = useSelector(
    (state) => state.updateCandidateReducer
  );
  const candidateProfileReducer = useSelector(
    (state) => state.candidateProfileReducer
  );

  useEffect(() => {
    if (visible?.data?.candidate_id) {
      dispatch(candidateProfileAction(visible?.data?.candidate_id));
    }
    return () => {
      dispatch({ type: "CANDIDATE_PROFILE_RESET" });
    };
  }, [visible?.data]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      Modal.confirm({
        title: "Confirm Remarks",
        content: "Are you sure you want to update these details?",
        onOk: () => {
          dispatch(
            updateCandidateAction({
              ...values,
              candidate_id: visible?.data?.candidate_id
            })
          );
        },
        onCancel() {
          console.log("Update canceled.");
        }
      });
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const onClose = useCallback(() => {
    form.resetFields();
    setVisible((prev) => ({ ...prev, show: false, data: {} }));
  }, [form, setVisible]);

  useEffect(() => {
    const { loading, data, error } = candidateProfileReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      form.setFieldsValue({
        ...data
      });
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [candidateProfileReducer, form]);

  useEffect(() => {
    const { loading, data, error } = updateCandidateReducer;
    if (!loading && data && !error) {
      message.success("Details Updated");
      onClose();
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [updateCandidateReducer]);
  return (
    <Drawer
      placement="right"
      closable={false}
      open={visible.show}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <span>
            {candidateProfileReducer?.data ? "Update" : "Add"} Details:
            <Avatar
              src={
                visible?.data?.photo
                  ? `${process.env.REACT_APP_IMAGES_BASE_URL}${visible?.data?.photo}`
                  : undefined
              }
              icon={!visible?.data?.photo ? <UserOutlined /> : null}
              alt={visible?.data?.name || "User"}
              style={{ marginRight: 8 }}
            />
            <a
              className="text-decoration-underline cursor-pointer"
              style={{ cursor: "pointer", color: "#005c53" }}
            >
              {visible?.data?.name}
            </a>
          </span>

          <CloseOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
      }
      footer={
        <Row justify="space-between">
          <Col>
            <Button className="custom-button" type="button" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              loading={updateCandidateReducer?.loading}
              className="custom-button"
              onClick={handleSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      }
      width={useIsMobileView() ? "100%" : "40%"}

    >
      <>
        {loading ? (
          <div className="loader-center">
            <TailSpin ariaLabel="Loading..." color="#005c53" />
          </div>
        ) : (
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              noticePeriod: null,
              currentCTC: { unit: "Lakh" },
              expectedCTC: { unit: "Lakh" },
              offerInHand: { unit: "Lakh" },
              lastWorkingDay: null
            }}
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="First name"
                  name="firstName"
                  rules={[
                    { required: true, message: "Please input your first name!" }
                  ]}
                >
                  <Input className="text" size="large" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last name"
                  name="lastName"
                  rules={[
                    { required: true, message: "Please input your last name!" }
                  ]}
                >
                  <Input className="text" size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" }
              ]}
            >
              <Input className="text" size="large" />
            </Form.Item>
            <Form.Item
              label="Phone number"
              name="phone"
              rules={[
                { required: true, message: "Please input your Phone number!" }
              ]}
            >
              <Input className="number" size="large" />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Please enter your Address" }]}
            >
              <LocationSelect
                field={{
                  name: "address",
                  placeholder: "Enter address"
                }}
                form={form}
              />
            </Form.Item>
            <Divider>Photo</Divider>
            <Form.Item name="photo" label="Photo">
              <ImageUploadComponent
                uploadPath="hire_talent/profile/"
                acceptedFileTypes="image/*"
                uploadType="Photo"
                onFileUpload={(profileImageUrl) => {
                  form.setFieldValue("photo", profileImageUrl);
                }}
                existingFileUrl={form.getFieldValue("photo")}
              />
            </Form.Item>
            <Divider>Resume</Divider>
            <Form.Item
              name="resume"
              label="Resume"
              rules={[
                {
                  message: "Please upload your Resume"
                }
              ]}
            >
              <ImageUploadComponent
                uploadPath="hire_talent/resume/"
                acceptedFileTypes="application/pdf, .pdf"
                uploadType="PDF Resume"
                onFileUpload={(profileImageUrl) =>
                  form.setFieldValue("resume", profileImageUrl)
                }
                existingFileUrl={form.getFieldValue("resume")}
              />
            </Form.Item>
          </Form>
        )}
      </>
    </Drawer>
  );
};

export default UpdateApplicationDetails;
