import React, { useEffect, useState } from "react";
import "../../../Assets/css/candidates.main.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllCandidateAction } from "../../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import { Cards } from "./CanidateCards";
import { Button, Input, Pagination, message } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import AddResumeDrawer from "../AddResumeDrawer";

const CandidatesComponent = () => {
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(8);
  const [totalJobs, settotalJobs] = useState(0);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState([]);
  const applyJobReducer = useSelector((state) => state.applyJobReducer);
  const resumeParserReducer = useSelector((state) => state.resumeParserReducer);
  const addCandidateReducer = useSelector((state) => state.addCandidateReducer);

  useEffect(() => {
    dispatch(
      getAllCandidateAction({
        firstName: "all",
        pageSize: pageSize,
        page: currentPage
      })
    );
  }, [dispatch, applyJobReducer, resumeParserReducer, addCandidateReducer]);

  const getAllCandidateReducer = useSelector(
    (state) => state.getAllCandidateReducer
  );

  useEffect(() => {
    const { data, error, loading, status } = getAllCandidateReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setdata(data?.data);
      setcurrentPage(data?.current);
      settotalJobs(data?.total);
    }
    if (!data && !loading && error) {
      console.warn("Error:", error);
      message.error(error);
      setdata([]);
    }
  }, [getAllCandidateReducer]);

  const handlePageChange = (page) => {
    setcurrentPage(page);
    dispatch(
      getAllCandidateAction({
        firstName: "all",
        pageSize: pageSize,
        page: page
      })
    );
  };
  const [searchKeyword, setSearchKeyword] = useState(null);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getAllCandidateAction({ firstName: searchKeyword }));
  };

  const [AddCandidate, setAddCandidate] = useState({
    show: false,
    type: ""
  });
  const showCandiadteDrawer = (type) => {
    setAddCandidate((prev) => ({
      ...prev,
      show: true,
      type: type
    }));
  };
  const items = [
    {
      key: "1",
      label: (
        <a onClick={() => showCandiadteDrawer("add_resume")}>Add by resume</a>
      ),
      description: "Upload your resume from your device.",
      icon: <SmileOutlined />
    },
    {
      key: "2",
      label: (
        <a onClick={() => showCandiadteDrawer("add_manually")}>Add manually</a>
      ),
      description: "Enter candidate details manually.",
      icon: <SmileOutlined />
    }
  ];

  return (
    <>
      <div id="candidate-db" className="candidate-db-1-7w- candidate-db-1Z2c1">
        <div className="candidate-db-3fH7O">
          <div className="candidate-db-1Xrqb candidate-db-2pVZ2 candidate-db-3Mw_O candidate-db-3v_A5">
            <div className="candidate-db-27bfL">
              <div className="candidate-db-3HUGc">
                <form className="candidate-db-cocIH" onSubmit={handleSearch}>
                  <div className="candidate-db-2VNFS">
                    <div className="candidate-db-h8sLy">
                      <div className="candidate-db-3o77X">
                        <div className="candidate-db-1tBNa">
                          <div className="candidate-db-3qHIU">
                            <Input
                              placeholder="Search all candidates using keywords"
                              value={searchKeyword}
                              onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    className="candidate-db-2de5X candidate-db-14TuV candidate-db-25RCR candidate-db-1VkQV main_border_radius"
                    data-ui="search-button"
                    type="primary"
                    htmlType="submit"
                    disabled={searchKeyword ? false : true}
                  >
                    Search
                  </Button>
                  {/* <Dropdown
                    className="candidate-db-2de5X candidate-db-14TuV candidate-db-2ySVn mx-2 main_border_radius"
                    menu={{
                      items
                    }}
                    size="large"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        Add Candidates
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown> */}
                </form>
              </div>
            </div>
          </div>
          <div className=" w-100 mb-3" style={{ marginTop: "40px" }}>
            {loading ? (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 9999
                }}
              >
                <TailSpin ariaLabel="Loading..." color="#005c53" />
              </div>
            ) : data && data.length > 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <main
                    className="d-flex"
                    style={{ width: "88%", flexDirection: "column" }}
                  >
                    <div className="candidate-db-1hE-x">
                      <div className="candidate-db-3JDve">
                        <span className="candidate-db-33WZ1  candidate-db-f-uLT">
                          {data?.length} candidates
                        </span>
                      </div>
                    </div>
                    <div className="candidate-db-30aIv">
                      <div className="candidate-db-Wthr7">
                        <div className="candidate-db-1IrH5">
                          <span className="candidate-db-2TdGW  candidate-db-QTMDv">
                            <strong className="candidate-db-2kqW6">
                              Candidate Information
                            </strong>
                          </span>
                        </div>
                        <div className="candidate-db-3vT1z">
                          <span className="candidate-db-2TdGW  candidate-db-QTMDv">
                            <strong className="candidate-db-2kqW6">
                              Job status
                            </strong>
                          </span>
                        </div>
                      </div>
                      <div>
                        <section>
                          <Cards candidates={data} />
                        </section>
                      </div>
                    </div>
                  </main>
                  {/*  */}
                  <Pagination
                    showSizeChanger
                    className="text-center align-content-center mt-3"
                    onChange={handlePageChange}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    showTotal={(total) => `Total ${total} Candidates`}
                    current={currentPage}
                    total={totalJobs}
                    pageSize={pageSize}
                  />
                </div>
              </>
            ) : (
              <section>
                <div className="container avilabilty___col">
                  <div className="d-flex align-items-center justify-content-center ">
                    <div className="text-center">
                      <h1 className="display-1 fw-bold">0 Candidates</h1>
                      <p className="fs-3">
                        <span className="text-danger">Oops!</span> No Applicants
                        Found!
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
      {AddCandidate && (
        <>
          <AddResumeDrawer
            visible={AddCandidate}
            setVisible={setAddCandidate}
          />
        </>
      )}
    </>
  );
};

export default CandidatesComponent;
