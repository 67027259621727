import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Button,
  Form,
  Input,
  DatePicker,
  Modal,
  Row,
  Col,
  Select,
  message,
  Avatar
} from "antd";
import LocationSelect from "../../../Utils/locationSelector";
import { CloseOutlined } from "@ant-design/icons";
import {
  candidateProfileAction,
  updateCandidateAction
} from "../../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import { jobExperienceRanges } from "../../../Assets/data/Job";
import { useIsMobileView } from "../../../Utils/CustomHooks/windowSize";

const { Option } = Select;

const AddCandidateRemarksModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isImmediate, setIsImmediate] = useState(false);
  const [form] = Form.useForm();
  const updateCandidateReducer = useSelector(
    (state) => state.updateCandidateReducer
  );
  const candidateProfileReducer = useSelector(
    (state) => state.candidateProfileReducer
  );

  useEffect(() => {
    if (visible?.data?.candidate_id) {
      dispatch(candidateProfileAction(visible?.data?.candidate_id));
    }
    return () => {
      dispatch({ type: "CANDIDATE_PROFILE_RESET" });
    };
  }, [visible?.data]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      Modal.confirm({
        title: "Confirm Remarks",
        content: "Are you sure you want to add this remarks data?",
        onOk: () => {
          dispatch(
            updateCandidateAction({
              remarks: {
                ...values,
                currentCTC: {
                  amount: values.currentCTC.amount,
                  unit: values.currentCTC.unit
                },
                expectedCTC: {
                  amount: values.expectedCTC.amount,
                  unit: values.expectedCTC.unit
                },
                offerInHand: {
                  amount: values.offerInHand.amount,
                  unit: values.offerInHand.unit
                }
              },
              candidate_id: visible?.data?.candidate_id
            })
          );
        },
        onCancel() {
          console.log("Remarks canceled.");
        }
      });
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const onClose = useCallback(() => {
    form.resetFields();
    setVisible((prev) => ({ ...prev, show: false, data: {} }));
  }, [form, setVisible]);

  useEffect(() => {
    const { loading, data, error } = candidateProfileReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      form.setFieldsValue({
        ...data?.remarks,
        lastWorkingDay: data?.remarks?.lastWorkingDay
          ? moment(data?.remarks?.lastWorkingDay)
          : null,
        currentCTC: { ...data?.remarks?.currentCTC },
        expectedCTC: { ...data?.remarks?.expectedCTC },
        offerInHand: { ...data?.remarks?.offerInHand }
      });
      setIsImmediate(data?.remarks?.noticePeriod === "Immediate");
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [candidateProfileReducer, form]);

  useEffect(() => {
    const { loading, data, error } = updateCandidateReducer;
    if (!loading && data && !error) {
      message.success("Remarks Updated");
      onClose();
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [updateCandidateReducer]);

  return (
    <Drawer
      placement="right"
      closable={false}
      open={visible.show}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <span>
            {candidateProfileReducer?.data?.remarks ? "Update" : "Add"} Remarks:
            <Avatar
              src={
                visible?.data?.photo
                  ? `${process.env.REACT_APP_IMAGES_BASE_URL}${visible?.data?.photo}`
                  : undefined
              }
              icon={!visible?.data?.photo ? <UserOutlined /> : null}
              alt={visible?.data?.name || "User"}
              style={{ marginRight: 8 }}
            />
            <a
              className="text-decoration-underline cursor-pointer"
              style={{ cursor: "pointer", color: "#005c53" }}
            >
              {visible?.data?.name}
            </a>
          </span>

          <CloseOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
      }
      footer={
        <Row justify="space-between">
          <Col>
            <Button className="custom-button" type="button" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              loading={updateCandidateReducer?.loading}
              className="custom-button"
              onClick={handleSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      }
      width={useIsMobileView() ? "100%" : "40%"}
    >
      <>
        {loading ? (
          <div className="loader-center">
            <TailSpin ariaLabel="Loading..." color="#005c53" />
          </div>
        ) : (
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              noticePeriod: null,
              currentCTC: { unit: "Lakh" },
              expectedCTC: { unit: "Lakh" },
              offerInHand: { unit: "Lakh" },
              lastWorkingDay: null
            }}
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name={["currentCTC", "amount"]}
                  label="Current CTC"
                  rules={[
                    { required: false, message: "Please enter current CTC!" }
                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    placeholder="Enter current CTC"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={["currentCTC", "unit"]}
                  label="Unit"
                  rules={[{ required: false, message: "Please select unit!" }]}
                >
                  <Select size="large" placeholder="Select unit">
                    <Option value="Thousand">Thousand</Option>
                    <Option value="Lakh">Lakh</Option>
                    <Option value="Crore">Crore</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name={["expectedCTC", "amount"]}
                  label="Expected CTC"
                  rules={[
                    { required: false, message: "Please enter expected CTC!" }
                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    placeholder="Enter expected CTC"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={["expectedCTC", "unit"]}
                  label="Unit"
                  rules={[{ required: false, message: "Please select unit!" }]}
                >
                  <Select size="large" placeholder="Select unit">
                    <Option value="Thousand">Thousand</Option>
                    <Option value="Lakh">Lakh</Option>
                    <Option value="Crore">Crore</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name={["offerInHand", "amount"]}
                  label="Offer In Hand"
                  rules={[
                    { required: false, message: "Please enter offer in hand!" }
                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    placeholder="Enter offer in hand"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={["offerInHand", "unit"]}
                  label="Unit"
                  rules={[{ required: false, message: "Please select unit!" }]}
                >
                  <Select size="large" placeholder="Select unit">
                    <Option value="Thousand">Thousand</Option>
                    <Option value="Lakh">Lakh</Option>
                    <Option value="Crore">Crore</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="noticePeriod"
              label="Notice Period"
              rules={[
                { required: false, message: "Please select notice period!" }
              ]}
            >
              <Select
                size="large"
                placeholder="Select notice period"
                onChange={(value) => {
                  form.setFieldsValue({ noticePeriod: value });
                  setIsImmediate(value === "Immediate");
                }}
              >
                <Option value="Immediate">Immediate</Option>
                <Option value="15 days">15 days</Option>
                <Option value="30 days">30 days</Option>
                <Option value="1 month">1 month</Option>
                <Option value="2 months">2 months</Option>
                <Option value="3 months">3 months</Option>
                <Option value="4 months">4 months</Option>
                <Option value="5 months">5 months</Option>
                <Option value="6 months">6 months</Option>
              </Select>
            </Form.Item>
            {isImmediate && (
              <Form.Item
                name="lastWorkingDay"
                label="Last Working Day"
                rules={[
                  {
                    required: false,
                    message: "Please select last working day!"
                  }
                ]}
              >
                <DatePicker className="w-100" size="large" />
              </Form.Item>
            )}

            <Form.Item
              name="currentLocation"
              label="Current Location"
              rules={[
                { required: false, message: "Please enter current location!" }
              ]}
            >
              <LocationSelect
                field={{
                  name: "currentLocation",
                  placeholder: "Enter job location"
                }}
                form={form}
              />
            </Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="totalYearsOfExperience"
                  label="Total Years of Experience"
                  rules={[
                    {
                      required: false,
                      message: "Please enter total years of experience!"
                    }
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select experience"
                    allowClear
                  >
                    {jobExperienceRanges.map((currency) => (
                      <Option key={currency.value} value={currency.value}>
                        {currency.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="relevantExperience"
                  label="Relevant Experience"
                  rules={[
                    {
                      required: false,
                      message: "Please enter relevant experience!"
                    }
                  ]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Select experience"
                    allowClear
                  >
                    {jobExperienceRanges.map((currency) => (
                      <Option key={currency.value} value={currency.value}>
                        {currency.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="otherRemarks" label="Other Remarks">
              <Input.TextArea
                size="large"
                placeholder="Enter other remarks"
                rows={4}
              />
            </Form.Item>
          </Form>
        )}
      </>
    </Drawer>
  );
};

export default AddCandidateRemarksModal;
