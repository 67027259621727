import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const CandidateTerms = () => {
  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "white" }}>
      <Content
        className="container mt-5 "
        style={{
          fontFamily: "'Inter', sans-serif",
          fontWeight: 400,
          color: "#262626",
          paddingLeft: "10%",
          paddingRight: "10%"
        }}
      >
        <div className="text-start">
          <h2
            className="fw-bold mb-3"
            style={{
              fontSize: "2rem",
              color: "#262626",
              fontWeight: 700
            }}
          >
            Candidate Terms of Use
          </h2>
          <p style={{ fontSize: "1rem", color: "#888", fontWeight: 400 }}>
            Version v1 – August 2024
          </p>
        </div>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#666666",
            lineHeight: "1.8"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontSize: "20px",
              fontWeight: 600
            }}
          >
            Introduction
          </h4>
          <p className="mb-4">
            These Terms of Service (“<strong>Terms</strong>”) outline the terms
            regarding your use of the Services and are an integral part of the
            agreement between you (“<strong>you</strong>” or “
            <strong>your</strong>”) and Mpal Sol. Pvt. Ltd (“
            <strong>Company</strong>”, “<strong>us</strong>”, “
            <strong>we</strong>”, or “<strong>our</strong>”). These Terms are a
            legally binding contract between you and the Company. If you do not
            agree with these Terms, do not use the Services or do not register
            for a Company account. By using or accessing the Services, or
            registering for a Company account, you are agreeing to be bound by
            these Terms, the Privacy Notice, and the Cookie Policy (together the
            “<strong>Agreement</strong>”) as available on our website (“
            <strong>Website</strong>”). The Company will be the controller of
            your personal data provided to, or collected by or for, or processed
            in connection with our Services. When you use the Services, you
            agree to all of these Terms, including our Privacy Notice and the
            Cookie Policy, including updates, which states how we collect, use,
            share, and store your personal information.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            1. The Services
          </h4>
          <p className="mb-4">
            <strong>1.1.</strong> The Company provides skills-based assessments
            and tests for our customers' hiring and recruitment processes. The
            “Services” include all products and services provided by the Company
            that are used or accessed by you.
          </p>
          <p className="mb-4">
            <strong>1.2.</strong> The Services are for personal and private
            individual use. We reserve the right to immediately terminate your
            access to the Services and your account upon violation by you of the
            prohibited use of the Services under these Terms.
          </p>
          <p className="mb-4">
            <strong>1.3.</strong> In order to use our Services, you must be at
            least 16 years of age. If applicable law requires that you must be
            older for the Company to lawfully provide the Services to you
            without parental consent (including the use of your personal data),
            then the minimum age to use the Services is such older age. Failure
            to do so constitutes a breach of the Terms, which may result in
            termination of your access to the Services or account.
          </p>
          <p className="mb-4">
            <strong>1.4.</strong> You agree that we may provide the Services to
            you through our affiliates.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            2. Contact
          </h4>
          <p className="mb-4">
            <strong>2.1.</strong> You agree that the Company can provide notices
            and messages to you either within the Services or via the contact
            information that you provided us (e.g., email, mobile number,
            physical address). You agree to keep your contact details up to
            date.
          </p>
          <p className="mb-4">
            <strong>2.2.</strong> In case you have any questions or inquiries or
            require help, please contact{" "}
            <a
              href="mailto:help@mentorpal.ai"
              style={{ color: "green", textDecoration: "underline" }}
            >
              <strong>help@mentorpal.ai</strong>
            </a>
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            3. Users and Members. Account Creation
          </h4>
          <p className="mb-4">
            <strong>3.1.</strong> When you register for the use of the Services,
            you may opt to create a Company member account and become a member
            of the Company (“Member”). If you have chosen not to register for an
            account, you may access and use the Services as a “User.”
          </p>
          <p className="mb-4">
            <strong>3.2.</strong> If you want to register as a Member, you need
            to create an account. To create an account, you must provide your
            email address and create a password.
          </p>
          <p className="mb-4">
            <strong>3.3.</strong> You are responsible for safeguarding your
            account details and password that you use to access the Service and
            for any activities or actions under your account. You agree not to
            share or transfer your account or disclose your password to any
            third party.
          </p>
          <p className="mb-4">
            <strong>3.4.</strong> You are responsible for preventing
            unauthorized access to or use of the Services through your account
            and will notify the Company immediately of any such unauthorized
            access or use. The Company is not liable for any loss or damage
            arising from unauthorized use of your account.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            4. Rights and Responsibilities
          </h4>
          <p className="mb-4">
            <strong>4.1.</strong> In using the Services, you agree to (a) use
            the Services only in accordance with these Terms and applicable
            laws; (b) use your real name on your profile; (c) use the Services
            in a professional manner; (d) provide the Company with information
            that is accurate, complete, and current during your use of the
            Services.
          </p>
          <p className="mb-4">
            <strong>4.2.</strong> You will be solely responsible for all use of
            the Services under your account.
          </p>
          <p className="mb-4">
            <strong>4.3.</strong> You are expressly prohibited from recording,
            disclosing, publishing, sharing, or making available assessment
            tests, individual questions, or assignments, either to other
            individuals or to the general public, online or otherwise.
          </p>
          <p className="mb-4">
            <strong>4.4.</strong> In addition, you shall not: (a) create a false
            identity, misrepresent your identity, use the Services or create an
            account for anyone other than yourself, or use or attempt to use
            another’s account; (b) duplicate any portion of the Services or any
            documentation (except for your internal use); (c) modify, translate,
            decompile, reverse engineer, disassemble, adapt the Services, or
            attempt to derive the source code of the software offered through
            the Services; (d) use the Services, or allow the transfer,
            transmission, export, or re-export of the Services or portion
            thereof in violation of any applicable law, regulation, or rule; (e)
            violate the intellectual property rights of others, including
            copyrights, patents, trademarks, trade secrets or other proprietary
            rights; (f) violate the intellectual property or other rights of the
            Company; (g) develop any software or service that is derived from
            the Services and materially similar in function to or competes with
            the Services; (h) bypass, hack or breach any security device or
            protection used by the Services or access or use the Services, with
            or without automated means (such as scrape, crawl or spider); (i)
            remove, modify or obscure any identification, marks, notices or
            disclaimers placed on the Services; (j) disrupt or interfere with
            the Services or servers or networks connected to the Services; (k)
            attempt to gain unauthorized access to the Services or related
            systems or networks; or (l) cause the Services to be unavailable to
            other users.
          </p>
          <p className="mb-4">
            <strong>4.5.</strong> You may not use the Services for any illegal
            purpose, or in any way that may infringe or violate the rights of
            others, including intellectual property rights.
          </p>
          <p className="mb-4">
            <strong>4.6.</strong> You agree to comply with all local laws and
            regulations in your use of the Services.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            5. Termination
          </h4>
          <p className="mb-4">
            <strong>5.1.</strong> We reserve the right to terminate or suspend
            your access to the Services at any time, with or without cause, with
            or without notice, effective immediately. Reasons for termination
            may include, but are not limited to, a violation of these Terms or
            any other agreements related to the Services, or if we believe that
            your use of the Services is harmful to the Company or other users.
          </p>
          <p className="mb-4">
            <strong>5.2.</strong> Upon termination, your right to access and use
            the Services will immediately cease. Any provision of these Terms
            that by its nature should survive termination shall survive,
            including but not limited to, sections related to intellectual
            property, disclaimers, and limitations of liability.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            6. Liability
          </h4>
          <p className="mb-4">
            <strong>6.1.</strong> To the fullest extent permitted by applicable
            law, the Company and its affiliates, partners, suppliers, or
            licensors shall not be liable for any indirect, incidental,
            consequential, special, or punitive damages arising out of or
            related to your use of the Services, whether based on warranty,
            contract, tort (including negligence), or any other legal theory,
            even if we have been advised of the possibility of such damages.
          </p>
          <p className="mb-4">
            <strong>6.2.</strong> In no event shall the Company’s total
            liability to you for all damages exceed the amount paid by you, if
            any, for accessing or using the Services.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            7. Governing Law and Dispute Resolution
          </h4>
          <p className="mb-4">
            <strong>7.1.</strong> These Terms and any disputes arising from or
            related to your use of the Services shall be governed by and
            construed in accordance with the laws of the jurisdiction in which
            the Company is incorporated, without regard to its conflict of law
            principles.
          </p>
          <p className="mb-4">
            <strong>7.2.</strong> Any legal action or proceeding arising out of
            or related to these Terms or the Services shall be brought
            exclusively in the courts located in the jurisdiction in which the
            Company is incorporated, and you consent to the jurisdiction and
            venue of such courts.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            8. Amendments
          </h4>
          <p className="mb-4">
            <strong>8.1.</strong> We may amend these Terms at any time by
            posting the updated version on our Website. The revised Terms will
            be effective immediately upon posting. Your continued use of the
            Services following the posting of the revised Terms constitutes your
            acceptance of the changes. If you do not agree to the revised Terms,
            you must stop using the Services.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            9. Customer Content
          </h4>
          <p className="mb-4">
            <strong>9.1.</strong> The Services are used by our customers as an
            assessment and hiring tool to test and select potential future
            employees. As such, our customers can customize assessment tests and
            upload their own content, information, questions, and tests. By
            using the Services, your use of our customer’s content and
            information posted via our Services is at your own risk.
          </p>
          <p className="mb-4">
            <strong>9.2.</strong> MentorPal generally does not review content
            provided by our customers. You agree that we are not responsible for
            content or information provided by our customers. We cannot always
            prevent this misuse of our Services, and you agree that we are not
            responsible for any such misuse.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            10. No Warranties
          </h4>
          <p className="mb-4">
            <strong>10.1.</strong> MentorPal and its affiliates make no
            representation or warranty about the Services, including any
            representation that the Services will be uninterrupted or
            error-free, and provide the Services (including content and
            information) on an “AS IS” and “AS AVAILABLE” basis. To the fullest
            extent permitted under applicable law, MentorPal hereby disclaims
            all warranties and conditions with respect to the Services, either
            express, implied, or statutory, including, but not limited to, the
            implied warranties and/or conditions of title, accuracy of data,
            non-infringement, merchantability, satisfactory quality, fitness for
            a particular purpose, or accuracy.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            11. Indemnification and Limitation of Liability
          </h4>
          <p className="mb-4">
            <strong>11.1.</strong> You agree, at your own expense, to indemnify,
            defend, and hold harmless MentorPal and its employees,
            representatives, advisors, and agents, against any claim, suit,
            action, or other proceeding, to the extent based on or arising in
            connection with your use of the Services, including, but not limited
            to: (i) any rejection of your job application in connection with
            which you used the Services irrespective of whether this rejection
            was based on your unsuitability based on your test results or other
            factors; (ii) a violation of the Terms by you or anyone using your
            account; (iii) a claim that any use of the Services by you infringes
            any intellectual property right of any third party; (iv) any
            unauthorized use of the Services by you or someone using your
            account and password; or (v) any misrepresentation or breach of
            representation, warranty, or covenant made by you contained herein.
          </p>
          <p className="mb-4">
            <strong>11.2.</strong> To the greatest extent permitted by
            applicable law, MentorPal, including its affiliates, will not be
            liable in connection with this Agreement for lost profits, lost
            business, lost or failed job opportunities, reputation (e.g.,
            offensive or defamatory statements), loss of data (e.g., downtime or
            loss, use of, or changes to, your information or content), or any
            indirect, incidental, consequential, special, or punitive damages.
          </p>
          <p className="mb-4">
            <strong>11.3.</strong> MentorPal and its affiliates will not be
            liable to you in connection with this Agreement for any amount that
            exceeds EUR 50.00 (fifty euros).
          </p>
          <p className="mb-4">
            <strong>11.4.</strong> The limitations of liability in this article
            shall apply to all claims of liability (e.g., warranty, tort,
            negligence, contract, and law) even if MentorPal or its affiliates
            have been told of the possibility of any such damage, and even if
            these remedies fail their essential purpose.
          </p>
          <p className="mb-4">
            <strong>11.5.</strong> These limitations of liability do not apply
            to liability for death or personal injury, gross negligence, or
            intent.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            12. Account Suspension
          </h4>
          <p className="mb-4">
            <strong>12.1.</strong> We shall have the right to remove any
            inappropriate content from the Services, limit, or suspend your
            access to your account and the Services with immediate effect and
            without prior notice in the event that, in our reasonable
            determination: (a) your use of the Services is for any unlawful,
            unauthorized, or fraudulent purpose; (b) you are in material breach
            of any provision of the Agreement; (c) your use of the Services is
            materially adversely threatening the security, availability, or
            integrity of the Services; (d) our provision or your use of the
            Services is not permitted by applicable laws or regulations; or (e)
            the account information you have provided is incorrect or
            incomplete.
          </p>
          <p className="mb-4">
            <strong>12.2.</strong> If your account or use of the Services is
            suspended by us as a result of your actions or omissions pursuant to
            this Section 12, MentorPal does not have any liability for damages
            or losses, or any other consequences that you may incur as a result.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            13. Term and Termination
          </h4>
          <p className="mb-4">
            <strong>13.1.</strong> Both you and MentorPal may terminate the
            Agreement at any time with notice to the other. On termination, you
            lose the right to access or use the Services.
          </p>
          <p className="mb-4">
            <strong>13.2.</strong> Regarding a material breach of the Agreement
            by you, MentorPal may, in addition to termination, suspend the
            provision of certain Services and close your accounts.
          </p>
          <p className="mb-4">
            <strong>13.3.</strong> In the event of any expiration or termination
            of the Agreement: (a) you shall immediately cease all use of the
            Services; (b) MentorPal will suspend your access to the Services and
            you shall no longer have access to MentorPal’s platform including
            your historical assessments; and (c) MentorPal will delete your
            data, unless (i) it is required to store such data longer as
            required by applicable law or (ii) as required to prosecute or
            defend a legal claim, in which case such information will only be
            retained for as long as required to resolve the claim. Such deletion
            shall in any case exclude specific Candidate Data that was shared
            with customers as part of the Service and these customers have
            copied, re-shared, or stored such Candidate Data.
          </p>
          <p className="mb-4">
            <strong>13.4.</strong> In case of termination, this shall not affect
            our rights to (continue to) use and disclose any anonymized or
            aggregated data (excluding personal data) based on your input and
            information you shared as part of your use of the Services,
            including use of such anonymized or aggregated data by our
            customers.
          </p>
          <p className="mb-4">
            <strong>13.5.</strong> The following sections and paragraphs shall
            survive the expiration or termination of this Agreement under
            Section 13 (Term and Termination), Section 7 (Intellectual Property
            and Data), Section 8 (License and Personal Data), Section 10
            (Warranties), Section 11 (Indemnification and Limitation of
            Liability), Section 14 (General), and Section 15 (Applicable Law).
          </p>
          <p className="mb-4">
            <strong>13.6.</strong> You can contact MentorPal support to aid you
            in closing your account.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626"
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600
            }}
          >
            14. General
          </h4>
          <p>
            <strong>14.1. Transferability and Subcontracting</strong>
          </p>
          <p className="mb-4">
            You are not allowed to assign or transfer your rights or obligations
            under this Agreement, nor your account, without the prior and
            explicit consent from MentorPal. Any attempt to do so shall be null
            and void. However, you agree that MentorPal may assign this
            Agreement or parts thereof to its affiliates or subcontractors.
          </p>
          <p>
            <strong>14.2. Changes</strong>
          </p>
          <p className="mb-4">
            MentorPal is entitled to change and amend these Terms from time to
            time. We will use reasonable efforts to notify you of any material
            changes by posting an announcement on the Website or by email. To
            the greatest extent permitted by applicable law, the new Terms will
            take immediate effect, and your continued use of the Services
            following our posting or notice of the changes will constitute your
            acceptance of the updated Terms.
          </p>
          <p>
            <strong>14.3. Entire Agreement</strong>
          </p>
          <p className="mb-4">
            The Agreement constitutes the entire agreement between you and us
            with respect to its subject matter and supersedes all prior oral and
            written understandings, communications, or agreements.
          </p>
          <p>
            <strong>14.4. Severability</strong>
          </p>
          <p className="mb-4">
            If any provision of the Agreement should be found to be void or
            unenforceable, you and MentorPal agree to modify such provision, but
            only to the extent necessary to comply with the law, and the
            remainder of the Agreement will remain in full force and effect and
            will not be terminated.
          </p>
        </section>
      </Content>
    </Layout>
  );
};

export default CandidateTerms;
