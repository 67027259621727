import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography, message } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailSubscribeAction } from "../../redux/actions/common";
import emailimg from "../../../src/Assets/img/dd.png";

const { Title } = Typography;

const Unsubscribe = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  const [form] = Form.useForm();

  useEffect(() => {
    if (email) {
      form.setFieldsValue({ email });
    }
  }, [email, form]);
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);

  const { data, loading, error, status } = useSelector(
    (state) => state.emailSubscribeReducer
  );

  const handleSubmit = (values) => {
    const { email, reason } = values;
    dispatch(
      emailSubscribeAction({
        email,
        reason,
        subscribe: false
      })
    );
  };

  useEffect(() => {
    if (!loading) {
      if (data && status) {
        let messageText = "";

        switch (data.message) {
          case "Email is already unsubscribed":
            messageText =
              "You have already unsubscribed from our mailing list.";
            message.error(messageText);
            break;
          case "Email unsubscribed successfully":
            messageText = "You have been successfully unsubscribed.";
            message.success(messageText);
            setSubmitted(true);
            break;
          case "Email is already subscribed":
            messageText = "You are already subscribed to our mailing list.";
            message.error(messageText);
            break;
          case "Email subscribed successfully":
            messageText =
              "You have successfully subscribed to our mailing list.";
            message.success(messageText);
            setSubmitted(true);
            break;
          default:
            messageText = "An unexpected status occurred.";
            message.error(messageText);
            break;
        }

        form.resetFields();
      }

      if (error) {
        message.error(error || "Something went wrong. Please try again.");
      }
    }
  }, [data, error, loading, status, form]);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 p-3">
      <div className="text-center" style={{ maxWidth: "500px", width: "100%" }}>
        <img
          src={emailimg}
          alt="Unsubscribe"
          className="img-fluid d-block mx-auto"
          style={{ height: "auto", width: "90%" }}
        />

        {submitted ? (
          <>
            <Title level={2} className="mb-2">
              Successfully Unsubscribed
            </Title>
            <p>You have been removed from our mailing list.</p>
            <p>
              If this is a mistake or if you want to receive updates again, you
              can easily subscribe again from your profile page.
            </p>
          </>
        ) : (
          <>
            <Title level={2} className="mb-2">
              Unsubscribe
            </Title>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Form.Item
                label="Email address:"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter a valid email!" }
                ]}
              >
                <Input size="large" placeholder="Enter your email" />
              </Form.Item>

              <Form.Item label="Reason (Optional):" name="reason">
                <Input.TextArea
                  placeholder="Leave your reason here (optional)..."
                  rows={4}
                />
              </Form.Item>

              <Button
                className="main_border_radius"
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#00756a" }}
                block
                loading={loading}
              >
                Submit
              </Button>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default Unsubscribe;
