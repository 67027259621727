import { Button, Form, message, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BasicDetails from "./BasicDetails";
import ChooseQuestionStep from "./ChooseQuestion";
import {
  createTestAction,
  getTestDetailAction,
  updateTestAction
} from "../../../../redux/actions/assessment";

const { Step } = Steps;

const CreateTestModules = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const test_id = new URLSearchParams(location.search).get("test_id");

  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState({
    next: false,
    done: false,
    exit: false,
    getData: false
  });

  const updateTestReducer = useSelector((state) => state.updateTestReducer);
  const createTestReducer = useSelector((state) => state.createTestReducer);
  const getTestDetailsReducer = useSelector(
    (state) => state.getTestDetailsReducer
  );

  useEffect(() => {
    if (test_id) {
      dispatch(getTestDetailAction({ test_id }));
    }
    return () => {
      dispatch({ type: "GET_TEST_RESET" });
    };
  }, [dispatch, test_id]);

  useEffect(() => {
    const { loading, data, error } = getTestDetailsReducer;
    setLoading((prev) => ({ ...prev, getData: loading }));
    if (!loading && data && !error && test_id) {
      setFormData(data?.data);
    } else if (!loading && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getTestDetailsReducer, test_id]);

  useEffect(() => {
    const { loading, error, data } = createTestReducer;
    setLoading((prev) => ({ ...prev, done: loading }));

    if (!loading && data && !error) {
      message.success(data?.message);
      navigate("/dashboard/tests");
    }
    if (!loading && error) {
      console.warn("Error creating test:", error);
      message.error(error);
    }
  }, [createTestReducer]);

  useEffect(() => {
    const { loading, error, data } = updateTestReducer;
    setLoading((prev) => ({ ...prev, done: loading }));

    if (!loading && data && !error) {
      message.success(data?.message);
      navigate("/dashboard/tests");
    }
    if (!loading && error) {
      console.warn("Error updating test:", error);
      message.error(error);
    }
  }, [updateTestReducer]);

  const steps = [
    {
      title: "Test Details",
      content: <BasicDetails form={form} initialData={formData} />
    },
    {
      title: "Choose Questions",
      content: <ChooseQuestionStep form={form} initialData={formData} />
    }
  ];

  const next = () => {
    setLoading((prev) => ({ ...prev, next: true }));
    form
      .validateFields()
      .then((values) => {
        setFormData({ ...formData, ...values });
        setCurrent(current + 1);
      })
      .catch((info) => console.log("Validation Failed:", info))
      .finally(() => setLoading((prev) => ({ ...prev, next: false })));
  };

  const prev = () => setCurrent(current - 1);

  const handleDone = () => {
    setLoading((prev) => ({ ...prev, done: true }));
    form
      .validateFields()
      .then((values) => {
        const completeData = { ...formData, ...values };
        if (test_id) {
          dispatch(updateTestAction(completeData));
        } else {
          dispatch(createTestAction(completeData));
        }
      })
      .catch((info) => console.log("Validation Failed:", info))
      .finally(() => setLoading((prev) => ({ ...prev, done: false })));
  };

  const handleExit = () => {
    setLoading((prev) => ({ ...prev, exit: true }));
    navigate("/dashboard/tests");
  };

  useEffect(() => {
    return () => {
      form.resetFields();
      setFormData({});
    };
  }, [form]);

  return (
    <>
      {loading.getData ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <div className="main-header bg-white" data-ui="header">
            <div className="px-4">
              <div className="flag">
                <div className="flag__section">
                  <a className="external incognito-link inline-block mb-m mt-m">
                    <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                      Test
                    </h1>
                  </a>
                </div>
                <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
                  <Button
                    type="button"
                    className="btn btn--primary mt-m text-white pull-right main_border_radius"
                    onClick={handleExit}
                    loading={loading.exit}
                  >
                    Exit
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="test-root-container d-block">
            <div className="container py-4">
              <Steps
                current={current}
                className="custom-stepper"
                style={{ marginBottom: 20 }}
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </div>
            <div className="steps-content w-100">
              <Form form={form} initialValues={formData}>
                {steps[current].content}
              </Form>
            </div>
            <div className="test-footer">
              {current > 0 && (
                <Button
                  className="custom-button"
                  style={{ margin: "0 8px" }}
                  onClick={prev}
                  loading={loading.prev}
                >
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  className="custom-button"
                  onClick={next}
                  loading={loading.next}
                  style={{ marginLeft: 8 }}
                >
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  loading={loading.done}
                  className="custom-button"
                  onClick={handleDone}
                >
                  {test_id ? "Update" : "Submit"}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreateTestModules;
