import React from "react";
import { Layout, Table } from "antd";

const { Content } = Layout;

const PrivacyPolicy = () => {
  const columns = [
    {
      title: "Personal data",
      dataIndex: "personalData",
      key: "personalData",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: () => <span>&#10003;</span>,
    },
  ];

  const data = [
    { key: "1", personalData: "Full name" },
    { key: "2", personalData: "Gender (optional)" },
    { key: "3", personalData: "E-mail address" },
    { key: "4", personalData: "Username and password (optional)" },
    {
      key: "5",
      personalData: "Video and webcam stills and recordings (optional)",
    },
    { key: "6", personalData: "Demographic data (optional)" },
    { key: "7", personalData: "IP address" },
    {
      key: "8",
      personalData:
        "Career-related information (e.g. education, job history, salary expectation) (optional)",
    },
    {
      key: "9",
      personalData: "Answers you provide to open-ended test questions",
    },
    { key: "10", personalData: "Communications between you and us" },
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "white" }}>
      <Content
        className="container mt-5 "
        style={{
          fontFamily: "'Inter', sans-serif",
          fontWeight: 400,
          color: "#262626",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <div className="text-start">
          <h2
            className="fw-bold mb-3"
            style={{
              fontSize: "2rem",
              color: "#262626",
              fontWeight: 700,
            }}
          >
            Privacy Policy
          </h2>
          <p style={{ fontSize: "1rem", color: "#888", fontWeight: 400 }}>
            Version v3 – January 2024
          </p>
        </div>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#666666",
            lineHeight: "1.8",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            Introduction
          </h4>
          <p className="mb-4">
            MPal Sol Pvt Ltd, a company registered in India, is committed to protecting and respecting your privacy. This privacy policy (“Privacy Policy”) explains how we collect, use, share, and protect data that identifies or is associated with you (“personal data”) in relation to our website www.example.com including the services offered on such website (the “Platform”), and your choices about the collection and use of your information. MPal Sol Pvt Ltd operates a testing platform that allows employers and customers (“customers”) to instruct candidates to take aptitude, personality, and skills tests through the Platform. This Privacy Policy applies to you as an individual accessing and using our Platform in order to take aptitude, personality, and skills tests (“Candidate” or “User”). In order to use our Platform, MPal Sol Pvt Ltd needs to process certain personal data of the Users. This Privacy Policy applies to all acts of processing of personal data where MPal Sol Pvt Ltd acts as a controller in the sense of the General Data Protection Regulation (“GDPR”). Before accessing or using the Platform, please ensure that you have read and understood our Privacy Policy.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            1. What kind of information do we collect
          </h4>
          <p>
            <strong>Information we collect when you use our platform</strong>
          </p>
          <p className="mb-4">
            Please find below the types of personal data we may require from you
            and which we process in order to provide our services on the
            Platform:
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <div className="card-body p-0">
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              bordered
              className="table-striped"
            />
          </div>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            2. For what purposes do we use your personal data?
          </h4>
          <p className="mb-2">
            <strong>2.1.</strong> We use your personal data to help us provide
            and support (the services on) our Platform. Here is how:
          </p>
          <p className="mb-2">
            <strong> Service provision</strong>
          </p>
          <p className="mb-3">
            As part of your application procedures with future employers of your
            choice, we use the information to carry out and administer
            assessment tests and share the results of such tests with such
            future employers. Alternatively, we use the information to carry out
            and administer assessment tests and share the results of such tests
            with selected future employers that match your skills and interests;
          </p>
          <p className="mb-2">
            <strong>Communication</strong>
          </p>
          <p className="mb-4">
            Sending emails, newsletters, and other messages to keep you informed
            of our services and the Platform including your test results
            generated by the Platform or contacting you in relation to potential
            job opportunities. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link. We
            also use the personal data to deal with inquiries and complaints
            made by you relating to the Platform and to address your questions,
            issues, and concerns;
          </p>
          <p className="mb-1">
            <strong>2.3. Website monitoring</strong>
          </p>
          <p className="mb-4">
            to check the Platform and our other technology services are being
            used appropriately and to optimize their functionality;
          </p>
          <p className="mb-1">
            <strong>2.4. Platform optimization</strong>
          </p>
          <p className="mb-4">
            improve, test, and monitor the effectiveness of our Platform and
            diagnose or fix technology problems;
          </p>
          <p className="mb-1">
            <strong>2.5. Managing suppliers:</strong>
          </p>
          <p className="mb-4">who deliver services to us;</p>
          <p className="mb-1">
            <strong>2.6. Easyaccess</strong>
          </p>
          <p className="mb-4">
            to help you efficiently access your information after you sign in
            and to remember information so you will not have to re-enter it
            during your visit or the next time you visit the Platform;
          </p>
          <p className="mb-1">
            <strong>2.7. Statistics</strong>
          </p>
          <p className="mb-4">
            monitor metrics such as total number of visitors, traffic,
            demographic patterns and patterns in our test results (on an
            anonymized and aggregated basis);
          </p>
          <p className="mb-1">
            <strong>2.8. Development</strong>
          </p>
          <p className="mb-4">
            develop and test new products and features. and improve our tests
            and Platform.
          </p>
          <p className="mb-1">
            <strong>2.9. Benchmarks</strong>
          </p>
          <p className="mb-4">
            use aggregated and anonymized test scores and aggregated
            demographics to provide benchmarks to our customers and improve our
            services.
          </p>
          <p className="mb-4">
            <strong>2.10.</strong> We only process your personal data for the
            purposes listed above and in accordance with this Privacy Policy,
            except where otherwise required or permitted by law. In the event
            that we use your personal data for any new purpose not listed
            above, we will inform you before we start processing it for such
            new purpose.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            3. How do we share your information?
          </h4>
          <p className="mb-2">
            <strong>3.1.</strong> We do not share, sell, or rent your
            personal data to third parties except as described in this Privacy
            Policy.
          </p>
          <p className="mb-2">
            <strong>3.2.</strong> We may share your personal data in the
            following circumstances:
          </p>
          <p className="mb-2">
            <strong>3.2.1.</strong> <strong>With Customers:</strong> We may
            share your personal data with our Customers for the purpose of
            carrying out assessments or providing feedback to you.
          </p>
          <p className="mb-2">
            <strong>3.2.2.</strong> <strong>With Service Providers:</strong> We
            may disclose your personal data to third parties who provide services
            to us (such as technical support, analytics, or payment processing)
            that help us operate our Platform or provide our services.
          </p>
          <p className="mb-2">
            <strong>3.2.3.</strong> <strong>For Legal Reasons:</strong> We may
            disclose your personal data if required to do so by law or in
            response to a valid request by public authorities (e.g., a subpoena
            or court order), or to enforce our Terms of Service, protect our
            rights, privacy, safety, or property, or respond to emergencies.
          </p>
          <p className="mb-2">
            <strong>3.2.4.</strong> <strong>Business Transfers:</strong> In the
            event of a merger, acquisition, or other reorganization of our
            business, we may transfer your personal data as part of that
            transaction. We will ensure that any acquiring entity agrees to
            respect the privacy of your personal data in a manner consistent
            with this Privacy Policy.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            4. Your rights and choices
          </h4>
          <p className="mb-4">
            You have certain rights and choices regarding your personal data.
            These include:
          </p>
          <p className="mb-2">
            <strong>4.1.</strong> <strong>Access and Correction:</strong> You
            can request access to or correction of your personal data held by
            us.
          </p>
          <p className="mb-2">
            <strong>4.2.</strong> <strong>Deletion:</strong> You may request
            the deletion of your personal data when it is no longer necessary
            for the purposes for which it was collected, or if you withdraw your
            consent where processing is based on consent.
          </p>
          <p className="mb-2">
            <strong>4.3.</strong> <strong>Objection to Processing:</strong> You
            may object to the processing of your personal data for certain
            purposes, such as direct marketing.
          </p>
          <p className="mb-2">
            <strong>4.4.</strong> <strong>Data Portability:</strong> You may
            request to receive your personal data in a structured, commonly used
            format, and to transfer it to another data controller.
          </p>
          <p className="mb-2">
            <strong>4.5.</strong> <strong>Withdrawal of Consent:</strong> Where
            processing is based on consent, you may withdraw your consent at any
            time.
          </p>
          <p className="mb-2">
            <strong>4.6.</strong> <strong>Exercising Your Rights:</strong> To
            exercise any of your rights, please contact us at
            help@mentorpal.ai.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            5. Security
          </h4>
          <p className="mb-4">
            We take reasonable measures to protect your personal data from loss,
            theft, misuse, unauthorized access, disclosure, alteration, and
            destruction. However, no method of transmission over the Internet or
            method of electronic storage is 100% secure, so we cannot guarantee
            its absolute security.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            6. Changes to this Privacy Policy
          </h4>
          <p className="mb-4">
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or applicable laws. When we do, we will
            post the revised policy on our Platform and update the "Version"
            date at the top. We encourage you to review this Privacy Policy
            periodically to stay informed about how we are protecting your
            personal data.
          </p>
        </section>

        <section
          className="mt-4"
          style={{
            fontSize: "clamp(16px, 1.06667vw, 18px)",
            color: "#262626",
          }}
        >
          <h4
            className="fw-bold mb-3"
            style={{
              fontWeight: 600,
            }}
          >
            7. Contact Us
          </h4>
          <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at help@mentorpal.ai.
          </p>
        </section>
      </Content>
    </Layout>
  );
};

export default PrivacyPolicy;
