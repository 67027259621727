import { REACT_USER_TYPE } from "../Components/Auth/tokenProvider";

export const calculateRemainingTime = (targetDate) => {
  // Check if the targetDate is a valid date string
  if (!isNaN(new Date(targetDate))) {
    const difference = +new Date(targetDate) - +new Date();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  } else {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }
};
export const validatePassword = (_, value) => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[^\s]).{8,}$/;
  if (!passwordRegex.test(value.trim())) {
    return Promise.reject(
      "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    );
  } else {
    return Promise.resolve();
  }
};
export const validateEmail = (_, value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value.trim())) {
    return Promise.reject("Please enter a valid email address");
  } else {
    return Promise.resolve();
  }
};

export function saveDataInLocalStorage(data) {
  const { email, token, user_id } = data;

  localStorage.setItem("REACT_TOKEN_AUTH", token);
}

export function saveTokenInSessionStorage(headers) {
  sessionStorage.setItem(
    "x-access-token",
    JSON.stringify(headers["x-access-token"])
  );
  sessionStorage.setItem(
    "x-refresh-token",
    JSON.stringify(headers["x-refresh-token"])
  );
}

export const getCurrecnySymbol = (currencyCode) => {
  // Define symbols for currencies
  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    INR: "₹",
    AUD: "A$",
    CAD: "C$",
    CHF: "CHF",
    CNY: "¥",
    JPY: "¥",
    NZD: "NZ$",
    SGD: "S$",
    HKD: "HK$",
    SEK: "kr",
    KRW: "₩",
    MXN: "Mex$",
    NOK: "kr",
    RUB: "₽",
    ZAR: "R"
  };

  return currencySymbols[currencyCode] || currencyCode; // If symbol not found, return the currency code itself
};
export const formatDate = (isoDateString) => {
  if (!isoDateString) {
    return (
      <>
        <span className="text-center">-</span>
      </>
    );
  }
  const date = new Date(isoDateString);

  const getMonthName = (monthIndex) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return months[monthIndex];
  };

  const monthName = getMonthName(date.getUTCMonth());
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${monthName} ${day}, ${year} ${hours}:${minutes}:${seconds} ${ampm}`;
};

export const convertMinutesToDuration = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let duration = "";

  if (hours > 0) {
    duration += `${hours} hour${hours > 1 ? "s" : ""}`;
  }

  if (remainingMinutes > 0) {
    if (hours > 0) {
      duration += " ";
    }
    duration += `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
  }

  return duration || "0 minutes";
};

export const isMentorpalTeamUser = () => {
  try {
    switch (REACT_USER_TYPE()) {
      case "mentorpal-owner":
      case "mentorpal-admin":
      case "mentorpal-recruitment-manager":
        return true;
      default:
        return false;
    }
  } catch (error) {
    console.error("Error checking user type:", error);
    return false;
  }
};
