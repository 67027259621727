import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import axios from "axios";

const { Option } = Select;

const LocationSelect = ({ field, form }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    const initialValue = form.getFieldValue(field.name) || [];
    setSearchValue(initialValue);
  }, [form, field.name]);

  useEffect(() => {
    if (searchValue) {
      fetchLocations(searchValue);
    } else {
      setOptions([]);
    }
  }, [searchValue]);

  const fetchLocations = async (query) => {
    setLoading(true);

    const apiKeys = [
      "de6a3c0603ed4c0192ee094819425ef8",
      "39154ac15ab24fc8aa6fe7a3e9541f96",
      "e1d9ad55067f45e0a5fe48d36f90cd7d"
    ];
    const shuffledKeys = apiKeys.sort(() => Math.random() - 0.5);
    const apiKey = shuffledKeys[0];

    const url = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
      query
    )}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const places = response.data.results.map((result) => ({
        value: result.formatted,
        key: result.geometry.lat + "," + result.geometry.lng
      }));
      setOptions(places);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }

    setLoading(false);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleChange = (value) => {
    form.setFieldsValue({ [field.name]: value });
  };

  return (
    <Select
      size="large"
      showSearch
      value={form.getFieldValue(field.name) || searchValue}
      placeholder={field.title || "Search for a location"}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onSearch={handleSearch}
      onChange={handleChange}
      style={{ width: "100%" }}
      filterOption={false}
    >
      {options.map((option) => (
        <Option key={option.key} value={option.value}>
          {option.value}
        </Option>
      ))}
    </Select>
  );
};

export default LocationSelect;
