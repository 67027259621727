import {
  createClientUserConstants,
  createUserConstants,
  getAllClientUserConstants,
  getSubUserDetailsConstants,
  getUserConstants
} from "../../constants";

export const createRoleUserReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case createUserConstants.ADD_USER_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case createUserConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case createUserConstants.ADD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case createUserConstants.ADD_USER_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};

export const getAllRoleUserReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getUserConstants.GET_USER_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getUserConstants.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getUserConstants.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getUserConstants.GET_USER_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getRoleUSerDetailsReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getSubUserDetailsConstants.USER_DETAILS_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getSubUserDetailsConstants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getSubUserDetailsConstants.USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getSubUserDetailsConstants.USER_DETAILS_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const createClientRoleUserReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case createClientUserConstants.ADD_CLIENT_USER_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case createClientUserConstants.ADD_CLIENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case createClientUserConstants.ADD_CLIENT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case createClientUserConstants.ADD_CLIENT_USER_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
export const getAllClientRoleUserReducer = (
  state = { loading: false, data: null, error: null },
  action
) => {
  switch (action.type) {
    case getAllClientUserConstants.GET_ALL_CLIENT_USER_REQUEST:
      return {
        ...state,
        request: action.data,
        loading: true
      };
    case getAllClientUserConstants.GET_ALL_CLIENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        status: action.status,
        error: action.error
      };
    case getAllClientUserConstants.GET_ALL_CLIENT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: action.status,
        headers: action.headers
      };
    case getAllClientUserConstants.GET_ALL_CLIENT_USER_RESET:
      return {
        loading: false,
        data: null,
        error: null
      };
    default:
      return state;
  }
};
