import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Modal,
  Switch,
  Checkbox,
} from "antd";
import {
  getJobDetailAction,
  createJobAction,
  updateJobDetailAction,
} from "../../redux/actions/common";
import CustomSelect from "../../Utils/CustomSelect";
import {
  Currency,
  job_type,
  jobExperienceRanges,
  salary_tenure,
} from "../../Assets/data/Job";
import moment from "moment";
import LocationSelect from "../../Utils/locationSelector";
import UserCustomSelect from "../../Utils/ClientSelect/userSelect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ImageUploadComponent from "../Upload";
import ReportToUserSelection from "../../Utils/ClientSelect/ReportToUserList";
import { isMentorpalTeamUser } from "../../Utils/Common";
import ClientCustomSelect from "../../Utils/ClientSelect/clientSelect";

const { Option } = Select;
const CreateJob = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jobId = queryParams.get("job_id");
  const client_id = queryParams.get("client_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState(null);
  const [loading, setLoading] = useState(false);

  const jobDetail = useSelector((state) => state.getJobDetailReducer);
  const createJob = useSelector((state) => state.createJobReducer);
  const updateJob = useSelector((state) => state.updateJobDetailReducer);

  useEffect(() => {
    if (jobId) {
      dispatch(
        getJobDetailAction({ job_id: jobId, candidate: true, status: "all" })
      );
    }
    return () => {
      dispatch({ type: "GET_JOB_DETAIL_RESET" });
    };
  }, [jobId, dispatch]);

  useEffect(() => {
    const { loading, data, error } = jobDetail;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        setFormValues({
          ...data.job,
          assign_data: data?.assign_to,
          hiring_manager: data?.hiring_manager?.userIds
            ? data?.hiring_manager?.userIds[0]
            : "",
          application_deadline: data?.job?.application_deadline
            ? moment(data?.job?.application_deadline)
            : null,
        });
      } else if (error) {
        setFormValues([]);
        console.warn("Error:", error);
        message.error(error);
      }
    }
  }, [jobDetail]);

  useEffect(() => {
    const { loading, data, status, error } = createJob;
    setLoading(loading);
    if (!loading) {
      if (data && !error && status === 201) {
        message.success("Successfully created job");
        form.resetFields();
        navigate("/dashboard/jobs");
      } else if (error && [400, 500].includes(status)) {
        message.error(error);
        console.warn("Create job error:", error);
      }
    }
  }, [createJob]);

  useEffect(() => {
    const { loading, data, status, error, request } = updateJob;
    setLoading(loading);
    if (!loading) {
      if (data?.data.job_id === formValues?.job_id && status === 200) {
        message.success("Successfully updated job");
        navigate("/dashboard/jobs");
        form.resetFields();
      }
      if (
        request?.job_id === formValues?.job_id &&
        error &&
        [400, 404, 500].includes(status)
      ) {
        message.error(error);
      }
    }
  }, [updateJob]);

  const handleCreate = async (values) => {
    try {
      dispatch(createJobAction(values));
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const handleUpdate = async (values) => {
    Modal.confirm({
      title: "Are you sure you want to update this job?",
      icon: <ExclamationCircleOutlined />,
      content: "This action will apply changes immediately.",
      okText: "Yes, Update",
      cancelText: "No, Cancel",
      onOk() {
        const updateData = { job_id: formValues?.job_id, ...values };
        dispatch(updateJobDetailAction(updateData));
      },
    });
  };

  return (
    <>
      <div className="main-header bg-white" data-ui="header">
        <div className="px-4">
          <div className="flag">
            <div className="flag__section">
              <a className="external incognito-link inline-block mb-m mt-m">
                <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                  Job
                </h1>
              </a>
            </div>
            <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
              <Button
                type="button"
                className="btn btn--primary mt-m text-white pull-right main_border_radius"
                onClick={() => navigate("/dashboard/jobs")}
              >
                Exit
              </Button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <FormModal
          client_id={client_id}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          initialValues={formValues && jobId ? formValues : undefined}
          form={form}
        />
      )}
    </>
  );
};

const FormModal = ({ onCreate, initialValues, onUpdate, client_id, form }) => {
  const [isAssessmentEnabled, setAssessmentEnabled] = useState(
    initialValues?.assessment?.enabled || false
  );
  const [isInterviewProcessEnabled, setInterviewProcessEnabled] = useState(
    initialValues?.interview_process?.enabled || false
  );
  const [clientId, setClientId] = useState(initialValues?.client_id || "");

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  useEffect(() => {
    if (client_id) {
      form.setFieldsValue({
        client_id: client_id,
      });
      setClientId(client_id); // Update local state on client_id change
    }
  }, [client_id]);

  const onFinish = async () => {
    try {
      const formData = form.getFieldsValue();

      formData.assessment = {
        enabled: isAssessmentEnabled,
        ...formData.assessment,
      };
      formData.interview_process = {
        enabled: isInterviewProcessEnabled,
        ...formData.interview_process,
      };
      if (initialValues) {
        await onUpdate(formData);
      } else {
        await onCreate(formData);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to add/update job");
    }
  };
  const validateMinSalary = (rule, value) => {
    if (!value) {
      return Promise.reject(new Error("Minimum salary is required"));
    }
    const maxSalary = form.getFieldValue(["salary", "max"]);
    const minSalary = parseFloat(value);
    const maxSalaryFloat = maxSalary ? parseFloat(maxSalary) : null;
    if (maxSalaryFloat !== null && minSalary >= maxSalaryFloat) {
      return Promise.reject(
        new Error("Minimum salary must be less than maximum salary")
      );
    }
    return Promise.resolve();
  };

  const validateMaxSalary = (rule, value) => {
    if (!value) {
      return Promise.reject(new Error("Maximum salary is required"));
    }
    const minSalary = form.getFieldValue(["salary", "min"]);
    const maxSalary = parseFloat(value);
    const minSalaryFloat = minSalary ? parseFloat(minSalary) : null;

    if (minSalaryFloat !== null && maxSalary <= minSalaryFloat) {
      return Promise.reject(
        new Error("Maximum salary must be greater than minimum salary")
      );
    }
    return Promise.resolve();
  };

  const handleValuesChange = (changedValues) => {
    if (changedValues.salary?.min !== undefined) {
      form.validateFields([["salary", "max"]]);
    }
    if (changedValues.salary?.max !== undefined) {
      form.validateFields([["salary", "min"]]);
    }
  };
  return (
    <div className="container">
      <Form
        onValuesChange={handleValuesChange}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="pt-4 pb-40"
        initialValues={{
          salary: { currency: "INR" },
          assessment: { time_period: 48 },
        }}
      >
        <Divider style={{ color: "#005f49" }} orientation="center">
          Assign Details
        </Divider>

        {isMentorpalTeamUser() && !initialValues && (
          <Form.Item
            name="client_id"
            label="Select a Client"
            rules={[{ required: true, message: "Please select a client!" }]}
          >
            <ClientCustomSelect
              type="form"
              value={form?.getFieldValue("client_id") || ""}
              onChange={(value) => {
                setClientId(value);
                form.setFieldsValue({ client_id: value });
              }}
            />
          </Form.Item>
        )}

        {clientId || !isMentorpalTeamUser() ? (
          <>
            <Form.Item
              name="assign_data"
              label="Select a Recruiter"
              rules={[
                { required: true, message: "Please select a Recruiter!" },
              ]}
            >
              <UserCustomSelect
                key={clientId}
                form={form}
                multiple
                placeholder="Select a Recruiter"
                filter={{ client_id: clientId, selective: true }}
              />
            </Form.Item>

            <Form.Item
              name="hiring_manager"
              label="Choose a hiring Manager"
              rules={[
                { required: true, message: "Please select a hiring manager!" },
              ]}
            >
              <ReportToUserSelection
                key={clientId}
                fieldName="hiring_manager"
                form={form}
                filter={{ client_id: clientId, selective: true }}
              />
            </Form.Item>
          </>
        ) : null}

        <Divider style={{ color: "#005f49" }} orientation="center">
          Job Details
        </Divider>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="job_title"
              label="Job Title"
              rules={[{ required: true, message: "Please enter job title" }]}
            >
              <Input type="text" size="large" placeholder="Enter job title" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="job_type"
              label="Choose Job Type"
              rules={[
                { required: true, message: "Please select the job type!" },
              ]}
            >
              <CustomSelect
                field={{
                  name: "job_type",
                  options: job_type,
                  placeholder: "Select job type",
                }}
                form={form}
                initialValues={initialValues}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="application_deadline"
              label="Application Deadline (For Public Portals Only not Internally!)"
              rules={[
                {
                  required: true,
                  message: "Please select the application deadline!",
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) =>
                  current && current < new Date().setHours(0, 0, 0, 0)
                }
                style={{ width: "100%" }}
                size="large"
                placeholder="Select application deadline"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="job_location"
              label="Job Location"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid job location!",
                },
              ]}
            >
              <LocationSelect
                field={{
                  name: "job_location",
                  placeholder: "Enter job location",
                }}
                form={form}
                initialValues={initialValues}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item name="remote_options" valuePropName="checked">
              <Checkbox size="large">Is this a remote job?</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="job_experience_range"
              label="Select Experience Range"
              rules={[
                {
                  required: true,
                  message: "Please select the experience range!",
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                size="large"
                placeholder="Select experience"
                allowClear
              >
                {jobExperienceRanges.map((currency) => (
                  <Option key={currency.value} value={currency.value}>
                    {currency.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ color: "#005f49" }} orientation="center">
          Salary
        </Divider>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={["salary", "tenure"]}
              label="Select Salary Tenure"
              rules={[
                { required: true, message: "Please select the salary tenure!" },
              ]}
            >
              <Select
                size="large"
                placeholder="Select salary tenure"
                allowClear
              >
                {salary_tenure.map((tenure) => (
                  <Option key={tenure.value} value={tenure.value}>
                    {tenure.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name={["salary", "currency"]}
              label="Select Currency"
              rules={[
                { required: true, message: "Please select the currency!" },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select currency"
                allowClear
              >
                {Currency.map((currency) => (
                  <Option key={currency.value} value={currency.value}>
                    {currency.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              name={["salary", "unit"]}
              label="Salary Unit"
              rules={[{ required: true, message: "Please select salary unit" }]}
            >
              <Select placeholder="Select salary unit" size="large">
                {[
                  { value: "thousands", label: "Thousands" },
                  { value: "lakhs", label: "Lakhs" },
                  { value: "crore", label: "Crore" },
                ].map((unit) => (
                  <Option key={unit.value} value={unit.value}>
                    {unit.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name={["salary", "min"]}
              label="Minimum Salary"
              rules={[
                { required: true, message: "Please enter the minimum salary" },
                { validator: validateMinSalary },
              ]}
            >
              <Input
                type="number"
                size="large"
                placeholder="Enter minimum salary"
                min={0}
                step="any"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name={["salary", "max"]}
              label="Maximum Salary"
              rules={[
                { required: true, message: "Please enter the maximum salary" },
                { validator: validateMaxSalary },
              ]}
            >
              <Input
                type="number"
                size="large"
                placeholder="Enter maximum salary"
                min={0}
                step="any"
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ color: "#005f49" }} orientation="center">
          Description
        </Divider>
        <Form.Item
          name="job_description"
          label="Job Description"
          rules={[
            { required: true, message: "Please enter job description" },
            {
              min: 100,
              message: "Description must be at least 100 characters",
            },
          ]}
        >
          <QuillTextEditor
            form={form}
            field={{
              name: "job_description",
              placeholder: "Please enter job description",
            }}
            onChange={(value) =>
              form.setFieldsValue({ job_description: value })
            }
            value={form.getFieldValue("job_description")}
          />
        </Form.Item>

        <Divider style={{ color: "#005f49" }} orientation="center">
          Assessment Details
        </Divider>
        <Form.Item>
          <Checkbox
            checked={isAssessmentEnabled}
            onChange={(e) => setAssessmentEnabled(e.target.checked)}
          >
            Do you want to add Assessment Details?
          </Checkbox>
        </Form.Item>

        {isAssessmentEnabled && (
          <>
            <Form.Item
              name={["assessment", "email_template"]}
              label="Assessment Email Template"
              rules={[
                {
                  required: isAssessmentEnabled,
                  message: "Please enter Template",
                },
                {
                  min: 100,
                  message: "Template must be at least 100 characters",
                },
              ]}
            >
              <QuillTextEditor
                form={form}
                field={{
                  name: ["assessment", "email_template"],
                  placeholder: "Please enter Template",
                }}
                onChange={(value) =>
                  form.setFieldsValue({ assessment: { email_template: value } })
                }
                value={form.getFieldValue(["assessment", "email_template"])}
                disabled={!isAssessmentEnabled}
              />
            </Form.Item>

            <Form.Item
              name={["assessment", "time_period"]}
              label="Assessment Time Period (In Hours)"
              rules={[
                { required: true, message: "Please enter the time period" },
              ]}
            >
              <Input
                type="number"
                size="large"
                placeholder="Enter time period"
                min={48}
                step="any"
              />
            </Form.Item>
            <Form.Item
              name={["assessment", "attachment"]}
              label="Upload Assessment Attachment"
              rules={[
                {
                  required: isAssessmentEnabled,
                  message: "Please upload attachment",
                },
              ]}
            >
              <ImageUploadComponent
                uploadPath="hire_talent/job/"
                acceptedFileTypes="pdf/*"
                uploadType="PDF Attachment"
                multiple={true}
                onFileUpload={(profileImageUrl) =>
                  form.setFieldsValue({
                    assessment: { attachment: profileImageUrl },
                  })
                }
                existingFileUrl={initialValues?.assessment?.attachment || []}
                disabled={!isAssessmentEnabled}
              />
            </Form.Item>
          </>
        )}

        {/* Interview Process */}
        <Divider style={{ color: "#005f49" }} orientation="center">
          Interview Process
        </Divider>
        <Form.Item>
          <Checkbox
            checked={isInterviewProcessEnabled}
            onChange={(e) => setInterviewProcessEnabled(e.target.checked)}
          >
            Do you want to add Interview Process?
          </Checkbox>
        </Form.Item>

        {isInterviewProcessEnabled && (
          <Form.Item
            name={["interview_process", "process"]}
            label="Interview Process"
            rules={[
              {
                required: isInterviewProcessEnabled,
                message: "Please enter interview process",
              },
              { min: 100, message: "Template must be at least 100 characters" },
            ]}
          >
            <QuillTextEditor
              form={form}
              field={{
                name: ["interview_process", "process"],
                placeholder: "Enter interview process",
              }}
              onChange={(value) =>
                form.setFieldsValue({ interview_process: { process: value } })
              }
              value={form.getFieldValue(["interview_process", "process"])}
              disabled={!isInterviewProcessEnabled}
            />
          </Form.Item>
        )}

        <footer className="footer d-block">
          <Button
            type="primary"
            htmlType="submit"
            className="custom-button"
            style={{ float: "right" }}
          >
            {initialValues ? "Update" : "Submit"}
          </Button>
        </footer>
      </Form>
    </div>
  );
};

const QuillTextEditor = ({ value = "", onChange, field }) => {
  const [content, setContent] = useState(value);

  useEffect(() => {
    setContent(value);
  }, [value]);

  const handleChange = (value) => {
    setContent(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <ReactQuill
      className="quill-editor w-100"
      value={content}
      onChange={handleChange}
      style={{
        maxHeight: "75vh",
        minHeight: "50vh",
        height: "70vh",
      }}
      modules={{
        toolbar: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"],
        ],
      }}
      placeholder={field.placeholder}
    />
  );
};

export default CreateJob;
