import React, { useEffect, useState } from "react";
import {
  Button,
  Layout,
  Typography,
  Row,
  Col,
  Space,
  Dropdown,
  Tabs,
  Input,
  message,
  Tag,
  Modal,
  Form,
  Tooltip,
} from "antd";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import DetailsTest from "./TestDetails";
import CandidatesComponent from "./CandidatesComponent";
import SettingsComponent from "./SettingsComponent";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import {
  createAssessmentAction,
  getAssessmentDetailaction,
  updateAssessmentAction,
} from "../../../../redux/actions/assessment";
import InviteForm from "./InviteForm";

const { Header, Content } = Layout;
const { Title } = Typography;

const AssessmentDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const assessment_id = queryParams.get("assessment_id");

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inviteFormModal, setInviteFormModal] = useState(false);
  const [title, setTitle] = useState("");
  const [tempTitle, setTempTitle] = useState("");
  const [isTitleModalVisible, setIsTitleModalVisible] = useState(false);

  const getAssessmentDetailsReducer = useSelector(
    (state) => state.getAssessmentDetailsReducer
  );
  const updateAssessmentReducer = useSelector(
    (state) => state.updateAssessmentReducer
  );

  useEffect(() => {
    dispatch(getAssessmentDetailaction({ assessment_id: assessment_id }));
    return () => {
      dispatch({ type: "GET_ASSESSMENT_RESET" });
    };
  }, [dispatch, assessment_id, updateAssessmentReducer]);

  useEffect(() => {
    const { loading, data, error } = getAssessmentDetailsReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      setData(data?.data);
      setTitle(data?.data?.title);
      setTempTitle(data?.data?.title);
    }
    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getAssessmentDetailsReducer]);

  const handleSuccessMessage = (actionType) => {
    switch (actionType) {
      case "PUBLISH_ASSESSMENT":
        message.success("Assessment published successfully!");
        break;
      case "TITLE_UPDATE":
        message.success("Assessment Title Updated successfully!");
        break;
      case "UNPUBLISH_ASSESSMENT":
        message.success("Assessment unpublished successfully!");
        break;
      case "LOCK_ASSESSMENT":
        message.success("Assessment locked successfully!");
        break;
      case "DELETE_ASSESSMENT":
        message.success("Assessment deleted successfully!");
        break;
      case "ARCHIVE_ASSESSMENT":
        message.success("Assessment archived/unarchived successfully!");
        break;
      case "CLONE_ASSESSMENT":
        message.success("Assessment cloned successfully!");
        break;
      case "DOWNLOAD_AS_PDF":
        message.success("Download as PDF coming soon!!");
        break;
      case "SHARE_ASSESSMENT":
        message.success("Share action coming soon!!");
        break;
      case "INVITE_USER":
        message.success("Invite action performed successfully!");
        break;
      case "TRY_ASSESSMENT":
        message.info("Try Assessment coming soon!");
        break;
      default:
        message.success("Action performed successfully!");
    }
  };

  useEffect(() => {
    const { loading, data, error, request } = updateAssessmentReducer;
    setLoading(loading);
    if (!loading && data && !error) {
      if (request && request?.action_type) {
        handleSuccessMessage(request?.action_type);
      } else {
        message.success("Action performed successfully!");
      }
    }
    if (!loading && !data && error) {
      console.warn("error", error);
      message.error(error);
    }
  }, [updateAssessmentReducer]);

  const handleAction = (type) => {
    const { __id, assessment_id, _id, ...extractData } = data;

    const actionMessages = {
      published: "After publishing, you cannot modify the assessment.",
      unpublished: " You can modify the assessment after unpublishing it.",
      lock: "Locking this assessment will prevent further changes.",
      delete: "Deleting this assessment is permanent and cannot be undone.",
      archive: data?.is_archived
        ? "Unarchive will restore this assessment to active status."
        : "Archiving will move this assessment to an archive.",
      clone: "Cloning will create a new assessment with the same details.",
      download_as_pdf: "You will be able to download this assessment as a PDF.",
      share: "You will be able to share this assessment with others.",
      invite:
        "You will be able to invite users to participate in this assessment.",
      try_assessment: "You will be able to try out this assessment yourself.",
    };

    const actions = {
      published: async () => {
        const actionType = "PUBLISH_ASSESSMENT";
        await dispatch(
          updateAssessmentAction({
            assessment_id: assessment_id,
            status: "published",
            action_type: actionType,
          })
        );
      },
      unpublished: async () => {
        const actionType = "UNPUBLISH_ASSESSMENT";
        await dispatch(
          updateAssessmentAction({
            assessment_id: assessment_id,
            status: "unpublished",
            action_type: actionType,
          })
        );
      },
      lock: async () => {
        const actionType = "LOCK_ASSESSMENT";
        await dispatch(
          updateAssessmentAction({
            assessment_id: assessment_id,
            status: "locked",
            action_type: actionType,
          })
        );
      },
      delete: async () => {
        const actionType = "DELETE_ASSESSMENT";
        await dispatch(
          updateAssessmentAction({
            assessment_id: assessment_id,
            status: "deleted",
            action_type: actionType,
          })
        );
      },
      archive: async () => {
        const actionType = "ARCHIVE_ASSESSMENT";
        await dispatch(
          updateAssessmentAction({
            assessment_id: assessment_id,
            is_archived: !data?.is_archived,
            action_type: actionType,
          })
        );
      },
      clone: async () => {
        const actionType = "CLONE_ASSESSMENT";
        await dispatch(
          createAssessmentAction({
            ...extractData,
            action_type: actionType,
          })
        );
      },

      invite: () => {
        setInviteFormModal(true);
      },
    };

    const messageContent =
      actionMessages[type] || "Are you sure you want to proceed?";

    if (type === "invite") {
      actions[type]();
    } else if (actions[type]) {
      Modal.confirm({
        title: `Confirm ${type.charAt(0).toUpperCase() + type.slice(1)}`,
        content: messageContent,
        okText: "Yes",
        cancelText: "No",
        onOk: async () => {
          await actions[type]();
        },
      });
    } else {
      message.error(`Unknown action: ${type}`);
    }
  };

  const showTitleModal = () => {
    setIsTitleModalVisible(true);
  };

  const handleTitleModalCancel = () => {
    setIsTitleModalVisible(false);
    setTempTitle(title);
  };

  const handleTitleModalOk = () => {
    setTitle(tempTitle);
    setIsTitleModalVisible(false);
    dispatch(
      updateAssessmentAction({
        action_type: "TITLE_UPDATE",
        assessment_id: assessment_id,
        title: tempTitle,
      })
    );
  };

  const getButtonLabel = () => {
    switch (data?.status) {
      case "published":
        return "Unpublish";
      case "unpublished":
        return "Publish";
      case "locked":
        return "Draft";
      default:
        return "Publish";
    }
  };

  const items = [
    {
      key: "1",
      label: <a onClick={() => handleAction("lock")}>Lock</a>
    },
    {
      key: "2",
      label: <a onClick={() => handleAction("clone")}>Clone</a>
    },
    {
      key: "3",
      label: (
        <a onClick={() => handleAction("archive")}>
          {data?.is_archived ? "UnArchive" : "Archive"}
        </a>
      )
    },
    {
      key: "4",
      label: <a onClick={() => handleAction("delete")}>Delete</a>
    },
    {
      key: "5",
      label: <a onClick={() => handleAction("unpublished")}>Unpublish</a>
    }
  ];
  const item2 = [
    {
      label: "Invite",
      value: "invite",
      disabled: data?.status !== "published",
      disabledMessage:
        "You can only invite users if the assessment is published.",
    },
    // {
    //   value: "try_assessment",
    //   label: "Try Assessment",
    //   disabled: data?.status !== "published",
    //   disabledMessage: "You can only try the assessment if it is published."
    // },
    ...(data?.status !== "published"
      ? [
          {
            label: getButtonLabel(),
            value: data?.status === "published" ? "unpublished" : "published",
          },
        ]
      : []),
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "published":
        return "green";
      case "archived":
        return "yellow";
      case "draft":
        return "orange";
      case "locked":
        return "red";
      default:
        return "blue";
    }
  };

  return (
    <>
      {updateAssessmentReducer?.loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : null}
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <div className="theme-light" data-theme-version={2}>
            <Header
              className="container-fluid d-flex flex-column single-assessment-container-header py-3"
              style={{ backgroundColor: "white", height: "90px" }}
            >
              <Row justify="space-between" align="middle">
                <Col xs={24} sm={24} md={16} lg={18} xl={20}>
                  <Space>
                    <Title
                      level={1}
                      className="single-assessment-container-header__title mb-0"
                    >
                      {title}
                    </Title>
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      onClick={showTitleModal}
                    />
                  </Space>
                  <Tag color={getStatusColor(data?.status)}>{data?.status}</Tag>
                </Col>
                <Col xs={24} sm={24} md={8} lg={6} xl={4}>
                  <Space>
                    {item2?.map((item) => (
                      <Tooltip
                        key={item.value}
                        title={
                          item?.disabled
                            ? `Action not allowed: ${item.disabledMessage}`
                            : ""
                        }
                        disabled={!item?.disabled}
                      >
                        <span>
                          <Button
                            className="btn text-white main_border_radius custom-button"
                            type="primary"
                            style={{ height: "auto" }}
                            onClick={() => handleAction(item?.value)}
                            disabled={item?.disabled}
                          >
                            {item?.label}
                          </Button>
                        </span>
                      </Tooltip>
                    ))}

                    <Dropdown menu={{ items }} placement="bottomRight">
                      <Button shape="circle" icon={<MoreOutlined />} />
                    </Dropdown>
                  </Space>
                </Col>
              </Row>
              <div className="mt-3">
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Test Details" key="1">
                    <DetailsTest data={data} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Candidates" key="2">
                    <CandidatesComponent data={data} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Settings" key="3">
                    <SettingsComponent data={data} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </Header>

            <Content />
          </div>

          {inviteFormModal && (
            <InviteForm
              open={inviteFormModal}
              setOpen={setInviteFormModal}
              assessment_id={assessment_id}
            />
          )}

          <Modal
            title="Edit Title"
            visible={isTitleModalVisible}
            onOk={handleTitleModalOk}
            onCancel={handleTitleModalCancel}
            okText="Save"
            cancelText="Cancel"
            okButtonProps={{
              style: {
                backgroundColor: "#00756a",
              },
            }}
          >
            <Form layout="vertical">
              <Form.Item
                label="Title"
                rules={[{ required: true, message: "Title is required" }]}
              >
                <Input
                  value={tempTitle}
                  onChange={(e) => setTempTitle(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};

export default AssessmentDetails;
