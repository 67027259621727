import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore } from "redux";
import rootSaga from "./redux/saga";
import rootReducer from "./redux/reducers";
import Auth from "./Components/Auth";
import MiniDrawer from "./Components/Upload/MiniDrawer";
const sagaMiddleware = createSagaMiddleware();
const Logger = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
});

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, Logger));

sagaMiddleware.run(rootSaga);


function App() {
  return (
    <>
      <Router>
        <Provider store={store}>
          <Auth />
          {/* <MiniDrawer /> */}
        </Provider>
      </Router>
    </>
  );
}

export default App;
