import {
  Button,
  Modal,
  Input,
  Table,
  message,
  Popconfirm,
  Tooltip,
  Row,
  Col
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inviteAssessmentAction } from "../../../../redux/actions/assessment";

const { TextArea } = Input;

const InviteForm = ({ open, setOpen, assessment_id }) => {
  const dispatch = useDispatch();
  const [candidates, setCandidates] = useState([
    { firstName: "", lastName: "", email: "", status: "" }
  ]);
  const [loading, setLoading] = useState(false);
  const [invitationResults, setInvitationResults] = useState([]);
  const [showStatusColumn, setShowStatusColumn] = useState(false);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newCandidates = [...candidates];
    newCandidates[index][name] = value;
    setCandidates(newCandidates);
  };

  const inviteAssessmentReducer = useSelector(
    (state) => state.inviteAssessmentReducer
  );

  useEffect(() => {
    const { loading, data, error } = inviteAssessmentReducer;
    setLoading(loading);

    if (!loading && data && !error) {
      const results = data.results.reduce((acc, result) => {
        acc[result.email] =
          result.error === "already_assigned"
            ? "Already Assigned"
            : result.status
            ? "Invitation Sent!"
            : result.message || "Error";
        return acc;
      }, {});

      const updatedCandidates = candidates.map((candidate) => ({
        ...candidate,
        status: results[candidate.email] || ""
      }));
      setCandidates(updatedCandidates);
      setInvitationResults(data.results);

      if (data.results.length > 0) {
        setShowStatusColumn(true);
      }
    }

    if (!loading && !data && error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [inviteAssessmentReducer]);

  const isEmailDuplicate = (email, index) => {
    return candidates
      .slice(0, -1)
      .some((candidate, i) => candidate.email === email && i !== index);
  };

  const handleAddRow = () => {
    const currentRow = candidates[candidates.length - 1];
    const emailRegex = /\S+@\S+\.\S+/;

    if (
      !currentRow.firstName ||
      !currentRow.lastName ||
      !currentRow.email ||
      !emailRegex.test(currentRow.email)
    ) {
      message.error(
        "Please ensure all fields are filled correctly in the current row!"
      );
      return;
    }

    if (isEmailDuplicate(currentRow.email, candidates.length - 1)) {
      message.error("This email has already been added!");
      return;
    }

    setCandidates([
      ...candidates,
      { firstName: "", lastName: "", email: "", status: "" }
    ]);
  };

  const handleRemoveRow = (index) => {
    if (candidates.length === 1) {
      message.error("At least one candidate row must remain.");
      return;
    }
    setCandidates(candidates.filter((_, i) => i !== index));
  };

  const handleInviteCandidates = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    const uniqueEmails = new Set();

    const validCandidates = candidates.slice(0, -1).filter((candidate) => {
      const isValid =
        candidate.firstName &&
        candidate.lastName &&
        candidate.email &&
        emailRegex.test(candidate.email) &&
        !uniqueEmails.has(candidate.email);

      if (isValid) {
        uniqueEmails.add(candidate.email);
      }

      return isValid;
    });

    if (validCandidates.length === 0) {
      message.error("Please add at least one valid candidate!");
      return;
    }

    const data = {
      assessment_id: assessment_id,
      candidates: validCandidates
    };
    dispatch(inviteAssessmentAction(data));
  };

  const columns = [
    {
      title: "S.No",
      render: (_, __, index) => index + 1,
      width: 60
    },
    {
      title: "First Name",
      render: (_, __, index) =>
        index === candidates.length - 1 ? (
          <Input
            name="firstName"
            value={candidates[index].firstName}
            onChange={(e) => handleInputChange(index, e)}
            placeholder="First Name"
            style={{ width: "100%" }}
          />
        ) : (
          candidates[index].firstName
        ),
      width: 200
    },
    {
      title: "Last Name",
      render: (_, __, index) =>
        index === candidates.length - 1 ? (
          <Input
            name="lastName"
            value={candidates[index].lastName}
            onChange={(e) => handleInputChange(index, e)}
            placeholder="Last Name"
            style={{ width: "100%" }}
          />
        ) : (
          candidates[index].lastName
        ),
      width: 200
    },
    {
      title: "Email",
      render: (_, __, index) =>
        index === candidates.length - 1 ? (
          <Input
            name="email"
            type="email"
            value={candidates[index].email}
            onChange={(e) => handleInputChange(index, e)}
            placeholder="Email"
            style={{ width: "100%" }}
          />
        ) : (
          candidates[index].email
        ),
      width: 250
    },
    ...(showStatusColumn
      ? [
          {
            title: "Invitation Status",
            render: (_, __, index) => candidates[index].status || "",
            width: 200
          }
        ]
      : []),
    {
      title: "Action",
      render: (_, __, index) =>
        index === candidates.length - 1 ? (
          <Button
            type="dashed"
            onClick={handleAddRow}
            style={{ marginRight: 8, width: "100%" }}
          >
            + Add
          </Button>
        ) : (
          <Popconfirm
            title="Are you sure you want to remove this candidate?"
            onConfirm={() => handleRemoveRow(index)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              style: { backgroundColor: "#00756a", borderColor: "#28a745" }
            }}
            cancelButtonProps={{ style: { color: "#d9534f" } }}
          >
            <Button type="link" danger>
              Remove
            </Button>
          </Popconfirm>
        ),
      width: 120
    }
  ];

  const canInvite = candidates
    .slice(0, -1)
    .some(
      (candidate) =>
        candidate.firstName &&
        candidate.lastName &&
        candidate.email &&
        /\S+@\S+\.\S+/.test(candidate.email)
    );

  return (
    <Modal
      open={open}
      title="Invite Candidates"
      onCancel={() => setOpen(false)}
      footer={[
        <Button
          key="back"
          onClick={() => setOpen(false)}
          style={{ marginRight: 8 }}
        >
          Cancel
        </Button>,
        <Tooltip
          title={
            canInvite
              ? ""
              : "Please add at least one valid candidate to enable invitation."
          }
          key="invite-tooltip"
        >
          <span>
            <Button
              style={{ backgroundColor: "#00756a" }}
              type="primary"
              loading={loading}
              onClick={handleInviteCandidates}
              disabled={!canInvite}
            >
              Invite Candidates
            </Button>
          </span>
        </Tooltip>
      ]}
      width={open ? "90%" : "100%"} // Responsive modal width
      style={{ maxHeight: "80vh" }}
      bodyStyle={{ padding: "0 24px", maxHeight: "80vh", overflowY: "auto" }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} md={24} lg={24}>
          <Table
            dataSource={candidates}
            columns={columns}
            rowKey={(record, index) => index}
            pagination={false}
            size="middle"
            bordered
            scroll={{ x: "100%", y: 400 }} // Horizontal scroll for small screens
            style={{ overflowX: "auto" }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default InviteForm;
