import React, { useEffect, useState } from "react";
import {
  Card,
  Image,
  Row,
  Col,
  Slider,
  Modal,
  Button,
  Tooltip,
  Tabs
} from "antd";
import {
  ClockCircleOutlined,
  LeftOutlined,
  RightOutlined,
  CloseOutlined
} from "@ant-design/icons";

const ImagePreviewAssessment = ({ data }) => {
  const [imageType, setImageType] = useState("webcam");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    const webcam = data?.proctoring_details?.images?.webcam;
    const screen = data?.proctoring_details?.images?.screen;
    if (imageType === "webcam") {
      setCurrentImage(webcam?.proctoringImagesWebcam[currentImageIndex]);
      setImageCount(webcam?.totalWebcamCaptures);
    } else {
      setCurrentImage(screen?.proctoringImagesScreen[currentImageIndex]);
      setImageCount(screen?.totalScreenCaptures);
    }
  }, [imageType, currentImageIndex, data]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(currentImageIndex);

  const handleImageClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handlePrevImage = () => {
    setModalImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextImage = () => {
    setModalImageIndex((prevIndex) => Math.min(prevIndex + 1, imageCount - 1));
  };

  const getCurrentImage = () => {
    return imageType === "webcam"
      ? data?.proctoring_details?.images?.webcam.proctoringImagesWebcam[
          modalImageIndex
        ]
      : data?.proctoring_details?.images?.screen.proctoringImagesScreen[
          modalImageIndex
        ];
  };

  return (
    <>
      <div className="image-switch-tabs">
        <Tabs
          centered
          defaultActiveKey="webcam"
          activeKey={imageType}
          onChange={(key) => {
            setImageType(key);
            setCurrentImageIndex(0);
          }}
          items={[
            {
              label: (
                <Tooltip title="View Webcam Captures">
                  <span>Webcam</span>
                </Tooltip>
              ),
              key: "webcam"
            },
            {
              label: (
                <Tooltip title="View Screen Captures">
                  <span>Screen</span>
                </Tooltip>
              ),
              key: "screen"
            }
          ]}
          className="report-tabs"
        />
      </div>
      {imageCount > 0 ? (
        <>
          <div className="report-image-container pt-3">
            {currentImage?.capture_image ? (
              <Image
                preview={false}
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}${currentImage?.capture_image}`}
                alt={`Proctoring Image ${currentImageIndex + 1}`}
                className="report-current-image"
                width="100%"
                fallback={<div className="report-image-fallback" />}
                onClick={handleImageClick}
              />
            ) : (
              <div className="report-image-fallback" />
            )}
          </div>
          <Row justify="center" align="middle" className="proctoring-slider">
            <Col span={19}>
              <Slider
                min={0}
                max={imageCount - 1}
                value={currentImageIndex}
                onChange={(value) => {
                  setCurrentImageIndex(value);
                }}
                tipFormatter={(value) =>
                  new Date(
                    imageType === "webcam"
                      ? data?.proctoring_details?.images?.webcam
                          .proctoringImagesWebcam[value]?.createdAt
                      : data?.proctoring_details?.images?.screen
                          .proctoringImagesScreen[value]?.createdAt
                  ).toLocaleString()
                }
                className="report-custom-slider"
              />
            </Col>
            <Col span={3} className="text-center">
              <div>
                {currentImageIndex + 1}/{imageCount}
              </div>
            </Col>
          </Row>

          <Modal
            visible={isModalVisible}
            footer={null}
            onCancel={handleModalClose}
            width="60%"
            className="custom-modal"
            style={{
              textAlign: "center",
              padding: 0,
              height: "60vh",
              backgroundColor: "transparent",
              boxShadow: "none" // Remove default shadow
            }}
            bodyStyle={{
              padding: 0,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              backgroundColor: "transparent" // Ensure body is transparent
            }}
            closeIcon={<CloseOutlined style={{ color: "black" }} />}
          >
            <div
              className="modal-image-container"
              style={{
                position: "relative",
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden"
              }}
            >
              <Button
                type="text"
                icon={<LeftOutlined />}
                onClick={handlePrevImage}
                disabled={modalImageIndex === 0}
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  borderRadius: "50%",
                  fontSize: "1rem",
                  width: "40px",
                  height: "40px"
                }}
              />
              <Image
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}${
                  getCurrentImage()?.capture_image
                }`}
                alt={`Proctoring Image ${modalImageIndex + 1}`}
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                  display: "block"
                }}
                preview={false}
              />
              <Button
                type="text"
                icon={<RightOutlined />}
                onClick={handleNextImage}
                disabled={modalImageIndex === imageCount - 1}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  borderRadius: "50%",
                  fontSize: "1rem",
                  width: "40px",
                  height: "40px"
                }}
              />
            </div>
          </Modal>
        </>
      ) : (
        <Card className="my-3 border-0 text-center bg-light">
          <div className="d-flex flex-column align-items-center">
            <ClockCircleOutlined
              style={{ fontSize: "3rem", color: "#6c757d" }}
            />
            <h6 className="mt-3">
              This candidate's assessment is either not started or there are no
              photos available at this moment.
            </h6>
          </div>
        </Card>
      )}
    </>
  );
};

export default ImagePreviewAssessment;
