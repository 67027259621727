import React from "react";
import { Modal, Row, Col } from "antd"; // Import Row and Col from Ant Design
import { CloseOutlined } from "@ant-design/icons";
import "../../../../Assets/css/assessment.main.css"; // Ensure you still include your CSS for global styles
import { useNavigate } from "react-router-dom";

const programmingQuestions = [
  {
    id: "Programming_0",
    label: "Coding",
    description: "General purpose programming in a variety of languages",
    note: "",
    href: "code",
  },
];

const generalQuestions = [
  {
    id: "General_0",
    label: "Multiple Choice",
    description: "Single or Multiple correct answers",
    note: "",
    href: "mcq",
  },
  {
    id: "General_2",
    label: "Questionnaire",
    description: "questionnaire",
    note: "",
    href: "questionnaire",
  },
];

const QuestionTypeModal = ({ QuestionModal, setQuestionModal }) => {
  return (
    <Modal
      title={
        <div className="ui-dialog-header">
          <h2 className="ui-dialog-title" id="heading-dialog-2">
            Select Question Type
          </h2>
        </div>
      }
      visible={QuestionModal}
      footer={null}
      closeIcon={<CloseOutlined />}
      centered
      width={640}
      style={{ maxWidth: "90%", margin: "auto" }} // Responsive modal styling
      onCancel={() => setQuestionModal(false)}
    >
      <div className="ui-dialog-body">
        <Row gutter={[16, 16]} justify="center"> {/* Use Ant Design Row with gutter for spacing */}
          <Col xs={24} md={11}> {/* Full width on extra small screens, about half on medium and above */}
            <QuestionTypeList
              title="Programming"
              questions={programmingQuestions}
              setQuestionModal={setQuestionModal}
            />
          </Col>
          <Col xs={24} md={11}> {/* Full width on extra small screens, about half on medium and above */}
            <QuestionTypeList
              title="General"
              questions={generalQuestions}
              setQuestionModal={setQuestionModal}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const QuestionTypeList = ({ title, questions, setQuestionModal }) => {
  const navigate = useNavigate();
  const onSelectItem = (type) => (e) => {
    e.preventDefault();
    navigate(`/dashboard/question?type=${type.href}`);
    setQuestionModal(false);
  };

  return (
    <div >
      <h3 className="hr-heading-06">{title}</h3>
      <ul className="modal-section-body" aria-label={title}>
        {questions.map((type) => (
          <li key={type.id}>
            <a
              className="qtype pane-3"
              onClick={onSelectItem(type)}
              style={{
                display: 'block',
                padding: '10px',
                border: '1px solid transparent',
                borderRadius: '5px',
                transition: 'background-color 0.2s',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
            >
              <span className="demi-bold">{type.label}</span>
              <div
                className="qtype__description font-sz-13 pmT"
                id={`quesCreationTileDesc_${type.id}`}
              >
                {type.description}
              </div>
              {type.note && (
                <div
                  className="note category-note-color font-sz-11 pmT"
                  id={`quesCreationTileNote_${type.id}`}
                >
                  {type.note}
                </div>
              )}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuestionTypeModal;
