import React, { useState } from "react";
import { Modal, Checkbox, Form } from "antd";

const InstructionsModal = ({
  instructionModalVisible,
  setInstructionModalVisible,
  onStartTest,
}) => {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [form] = Form.useForm();

  const handleOk = () => {
    if (checkboxChecked) {
      form
        .validateFields()
        .then((values) => {
          setInstructionModalVisible(false);
          onStartTest(values);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  return (
    <Modal
      visible={instructionModalVisible}
      width={"65%"}
      onOk={handleOk}
      onCancel={() => setInstructionModalVisible(false)}
      okText="Start Test"
      cancelText="Cancel"
      okButtonProps={{
        disabled: !checkboxChecked,
        className: "btn btn-success text-white rounded-pill px-4 py-2",
      }}
      cancelButtonProps={{
        className: "btn btn-secondary text-white rounded-pill px-4 py-2",
      }}
      className="instructions-modal"
    >
      <div className="text-center mb-4">
        <h1 className="fw-bold">Instructions</h1>
      </div>
      <div className="p-4">
        <h5 className="fw-semibold">1. Technical Requirements:</h5>
        <ul className="list-unstyled ms-4">
          <li>• Ensure your webcam and microphone are working and enabled.</li>
        </ul>

        <h5 className="fw-semibold mt-4">2. Environment Setup:</h5>
        <ul className="list-unstyled ms-4">
          <li>• No phones or additional devices are allowed.</li>
          <li>• Your workspace must be clear and well-lit.</li>
          <li>• No one else can be present in the room.</li>
        </ul>

        <h5 className="fw-semibold mt-4">3. Compliance:</h5>
        <ul className="list-unstyled ms-4">
          <li>• The test must be completed from a single IP address.</li>
          <li>
            • Failure to follow these guidelines may invalidate your test.
          </li>
        </ul>

        <h5 className="fw-semibold mt-4">4. Important Notes:</h5>
        <ul className="list-unstyled ms-4">
          <li>• Once started, you cannot change your answers.</li>
          <li>• You cannot exit the test until it is completed.</li>
        </ul>
        <Form form={form} className="mt-4">
          <Form.Item>
            <Checkbox
              checked={checkboxChecked}
              onChange={(e) => setCheckboxChecked(e.target.checked)}
              className="fs-5"
            >
              <strong>I have read and agree to the instructions</strong>
            </Checkbox>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default InstructionsModal;
