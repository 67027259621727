import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Feedback from "../PublicPage/Feedback";

const ClientFooter = ({ data }) => {
  const navigate = useNavigate();

  const [FeedbackModal, setFeedbackModal] = useState(false);

  return (
    <>
      <footer className="styles--2E7h8 my-auto fixed-bottom" >
        <div className="styles--I78yk">
          <div className="styles--3lnDx">
            <a
              data-ui="company-url"
              href={data?.company_website}
              target="_blank"
              rel="noopener"
            >
              View website
            </a>
          </div>
          <div className="styles--3_fLs styles--3lnDx">
            <a
              href="#"
              data-ui="view-jobs-url"
              onClick={() => navigate(`/carrier-page/${data?.client_id}`)}
            >
              View all jobs
            </a>
          </div>
          <div className="styles--3_fLs styles--3lnDx">
            <a
              href="#"
              data-ui="view-jobs-url"
              onClick={() => setFeedbackModal(true)}
            >
              Help
            </a>
          </div>
        </div>
        <div className="styles--1ekDx" data-ui="footer-powered-by">
          <span className="styles--XvqwK">
            Powered by
            <a target="_blank" rel="noopener">
              {" "}
              Mpal Sol. Pvt. Ltd.
            </a>
          </span>
        </div>
      </footer>
      {FeedbackModal && (
        <Feedback
          FeedbackModal={FeedbackModal}
          setFeedbackModal={setFeedbackModal}
        />
      )}
    </>
  );
};

export default ClientFooter;
