import React from "react";
import { Table, Button, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

const DetailsTest = ({ data }) => {
  const navigate = useNavigate();
  const processedData =
    data?.testDetails?.map((detail) => ({
      ...detail,
      total_questions: detail.questionData.length
    })) || [];

  const isButtonDisabled = ["published", "locked"].includes(data?.status);

  const handleEditTest = () => {
    navigate(
      `/dashboard/assessment/edit-assessment?assessment_id=${data?.assessment_id}`
    );
  };

  // Inline styles for responsiveness and layout
  const styles = {
    container: {
      padding: '16px',
      margin: '0 auto',
      width: '100%',
      boxSizing: 'border-box',
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'wrap', // Allows items to wrap on small screens
    },
    headerTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    button: {
      backgroundColor: '#00756a',
      whiteSpace: 'nowrap',
    },
    buttonFullWidth: {
      width: '100%',
    },
    tableScroll: {
      x: 800,
    },
  };

  
  const isMobile = window.innerWidth <= 768;

  return (
    <div style={styles.container}>
      <Table
        bordered
        dataSource={processedData}
        columns={[
          {
            title: "Serial No.",
            key: "serialNo",
            width:150,
            render: (text, record, index) => index + 1,
            ellipsis:true
          },
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width:200,
          },
          {
            title: "Duration",
            dataIndex: "test_duration",
            key: "test_duration",
            width:200,
          },
          {
            title: "No. of Questions",
            dataIndex: "total_questions",
            key: "total_questions",
            width:200,
          },
        ]}
        rowKey="title"
        scroll={styles.tableScroll} // Enable horizontal scroll for small screens
        title={() => (
          <div style={styles.tableHeader}>
            <div style={styles.headerTitle}>Include Test</div>
            <Tooltip
              title={
                isButtonDisabled
                  ? `Edit action is not allowed when the assessment is ${data?.status}`
                  : ""
              }
              disabled={!isButtonDisabled}
            >
              <span>
                <Button
                  type="primary"
                  onClick={handleEditTest}
                  disabled={isButtonDisabled}
                  style={{
                    ...styles.button,
                    ...(isMobile ? styles.buttonFullWidth : {}), // Full width button on small screens
                  }}
                >
                  Edit Test
                </Button>
              </span>
            </Tooltip>
          </div>
        )}
      />
    </div>
  );
};

export default DetailsTest;
