import React, { useEffect, useState } from "react";
import { Avatar, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoleUserAction } from "../../redux/actions/Roles&Management";
import { UserOutlined } from "@ant-design/icons";

const { Option } = Select;

const MultipleAttendeeSelect = ({ form, multiple = true, filter }) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [allUsers, setAllUsers] = useState([]);

  const { data = [], loading } = useSelector(
    (state) => state.getAllRoleUserReducer || {}
  );

  const users = Array.isArray(data?.data) ? data.data : [];

  useEffect(() => {
    dispatch(
      getAllRoleUserAction({
        page,
        limit: 50,
        ...(filter?.selective
          ? { client_id: filter?.client_id, selective: filter?.selective }
          : { client_id: filter?.client_id })
      })
    );

    return () => {
      dispatch({ type: "GET_USER_RESET" });
    };
  }, [dispatch, page, filter]);

  useEffect(() => {
    setAllUsers(users);
  }, [users]);

  const handleChange = (selectedUserIds) => {
    const selectedUsers = selectedUserIds
      .map((userId) => allUsers.find((u) => u.user_id === userId))
      .filter(Boolean);

    form.setFieldsValue({ attendees: selectedUsers });
  };

  const currentValue = form?.getFieldValue("attendees") || [];

  const displayValue = multiple
    ? currentValue.map((attendee) => attendee.user_id)
    : currentValue[0]?.user_id || undefined;

  return (
    <Select
      size="large"
      className="w-100"
      value={displayValue}
      onChange={handleChange}
      placeholder="Select a Recruiter to assign"
      mode={multiple ? "multiple" : undefined}
      notFoundContent={loading ? <Spin size="small" /> : "No Recruiter found"}
      allowClear
      onClear={() => {
        handleChange([]); // Clear all attendees
      }}
      filterOption={(input, option) => {
        const user = allUsers.find((user) => user.user_id === option.value);
        if (user) {
          const fullName = `${user.first_name} ${user.last_name}`;
          return fullName.toLowerCase().includes(input.toLowerCase());
        }
        return false;
      }}
    >
      {allUsers.length > 0 ? (
        allUsers.map((user) => (
          <Option key={user.user_id} value={user.user_id}>
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Avatar
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: "50%",
                  marginRight: 8
                }}
                src={
                  user?.photo
                    ? `${process.env.REACT_APP_IMAGES_BASE_URL}${user?.photo}`
                    : null
                }
                icon={!user?.photo && <UserOutlined />}
              />
              <div style={{ flex: 1 }}>
                <strong style={{ display: "block", fontSize: "14px" }}>
                  {user.first_name} {user.last_name}
                </strong>
              </div>
            </div>
          </Option>
        ))
      ) : (
        <Option disabled>No Recruiter available</Option>
      )}
    </Select>
  );
};

export default MultipleAttendeeSelect;
