import { message, Progress, Tooltip } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";

const Timer = ({ initialTime, onTimeUp }) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    setTime(initialTime > 0 ? initialTime : 0);
  }, [initialTime]);

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => setTime((prev) => prev - 1), 1000);
      return () => clearInterval(timerId);
    } else if (time === 0) {
      message.info("Question Time's Up!");
      onTimeUp();
    }
  }, [time, onTimeUp]);

  const formatTime = useCallback((seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  }, []);

  const tooltipContent = `Total Time: ${formatTime(
    initialTime
  )}, Remaining Time: ${formatTime(time)}`;

  return (
      <Tooltip title={tooltipContent}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ClockCircleOutlined
            style={{ fontSize: "1rem" }}
          />
          <span className="text-black">{formatTime(time)}</span>
          <Progress
            percent={(time / initialTime) * 100}
            strokeColor="#28a745"
            showInfo={false}
            style={{ width: "100%", maxWidth: "450px", marginLeft: 8 }}
            size={[450, 17]}
          />
        </div>
      </Tooltip>
  );
};

export default Timer;
