import React, { useState, useEffect } from "react";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadImageAction,
  uploadImageFileAction
} from "../../redux/actions/common";
import { TailSpin } from "react-loader-spinner";

const ImageUploadComponent = ({
  uploadPath,
  acceptedFileTypes,
  uploadType,
  onFileUpload,
  existingFileUrl,
  multiple = false
}) => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const uploadResponse = useSelector((state) => state.uploadImageReducer);
  const imageUploadStatus = useSelector(
    (state) => state.uploadImageFileReducer
  );

  useEffect(() => {
    if (existingFileUrl) {
      const existingFiles = Array.isArray(existingFileUrl)
        ? existingFileUrl
        : [existingFileUrl];
      const formattedFiles = existingFiles.map((url, index) => ({
        uid: `-existing-${index}`,
        name: url,
        status: "done",
        url
      }));
      setFileList(formattedFiles);
    }
  }, [existingFileUrl, multiple]);
  const generateUniqueIdentifier = (length) => {
    const characters = "abcdefghijklmnopqrstuvwxyz_";
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);

    return (
      "_" +
      Array.from(
        randomValues,
        (value) => characters[value % characters.length]
      ).join("")
    );
  };

  const sanitizeFileName = (name) => {
    const forbiddenWords = ["naukri"];
    let sanitized = name.replace(/[^\w]/g, "_");

    forbiddenWords.forEach((word) => {
      const regex = new RegExp(word, "gi");
      sanitized = sanitized.replace(regex, "");
    });

    return sanitized;
  };

  const handleBeforeUpload = (file) => {
    const contentType = file?.type;
    const fileExtension = contentType.split("/").pop().toLowerCase();
    const nameWithoutExtension = sanitizeFileName(
      file.name.slice(0, -fileExtension.length - 1)
    );

    const uniqueIdentifier = generateUniqueIdentifier(5);
    const newFileName = `${uploadPath}${nameWithoutExtension}${uniqueIdentifier}`;
    const uploadFileName = `${newFileName}.${fileExtension}`;

    dispatch(
      uploadImageAction({
        documentName: newFileName,
        format: fileExtension
      })
    );

    const tempFile = {
      uid: uniqueIdentifier,
      name: nameWithoutExtension,
      status: "uploading",
      url: uploadFileName,
      file,
      contentType
    };

    setFileList((prevList) =>
      multiple ? [...prevList, tempFile] : [tempFile]
    );

    return false;
  };

  useEffect(() => {
    const { data, loading } = uploadResponse;
    setIsLoading(loading);

    if (data && data.url) {
      const updatedFileList = fileList.map((fileItem) => {
        if (
          fileItem.status === "uploading" &&
          data.url.includes(fileItem.url)
        ) {
          dispatch(
            uploadImageFileAction({
              url: data.url,
              fileData: fileItem.file,
              contentType: fileItem?.contentType
            })
          );
          return { ...fileItem, status: "done" };
        }
        return fileItem;
      });

      setFileList(updatedFileList);

      if (typeof onFileUpload === "function") {
        const uploadedFiles = updatedFileList.map((item) => item.url);
        onFileUpload(multiple ? uploadedFiles : uploadedFiles[0]);
      }
    }
  }, [dispatch, uploadResponse]);

  const handleRemove = (file) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  return (
    <>
      {isLoading || imageUploadStatus?.loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : null}

      <Upload.Dragger
        multiple={multiple}
        beforeUpload={handleBeforeUpload}
        maxCount={multiple ? undefined : 1}
        accept={acceptedFileTypes}
        fileList={fileList?.map((item, index) => ({
          ...item,
          url: `${process.env.REACT_APP_IMAGES_BASE_URL}${item.url}`,
          name: `${item?.name}`
        }))}
        onRemove={handleRemove}
        showUploadList={true}
        listType="picture"
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <label>
          <span className="candidate-db-2de5X candidate-db-14TuV candidate-db-1JsWJ">
            Upload {uploadType}
          </span>{" "}
          <span className="candidate-db-2TdGW candidate-db-f-uLT">
            or drag and drop here
          </span>
        </label>
      </Upload.Dragger>
    </>
  );
};

export default ImageUploadComponent;
