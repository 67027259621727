import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Table,
  Typography
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../Assets/css/assessment.login.css";
import {
  assessmentLoginAction,
  getInvitationDetailAction,
  getInviteAssessmentDetailAction
} from "../../../redux/actions/assessment";
import { convertMinutesToDuration } from "../../../Utils/Common";
import mentorpalLogo from "../../../Assets/img/bdk23qfm.png";

const { Title, Text } = Typography;

const AssessmentLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [inviteSummary, setInviteSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const assessmentInviteIdParams = queryParams.get("assessment_invite_id");
  const [form] = Form.useForm();
  const email_params = queryParams.get("email");
  const firstName = queryParams.get("firstName");
  const lastName = queryParams.get("lastName");
  const token = queryParams.get("token");

  const assessmentLoginReducer = useSelector(
    (state) => state.assessmentLoginReducer
  );
  const [assessmentInviteId, setAssessmentInviteId] = useState(
    assessmentInviteIdParams
  );

  const getInvitationDetailReducer = useSelector(
    (state) => state.getInvitationDetailReducer
  );

  useEffect(() => {
    if (assessmentInviteId) {
      dispatch(
        getInvitationDetailAction({
          assessment_invite_id: assessmentInviteId
        })
      );
    }
  }, [dispatch, assessmentInviteId]);

  useEffect(() => {
    const { loading, data, error } = getInvitationDetailReducer;
    setLoading(loading);
    if (!loading) {
      if (data && !error) {
        setData(data.assessment_details);
        setInviteSummary(data.invite_summary);
        setClient(data.client);
      } else if (error) {
        console.warn("Error:", error);
        message.error(error);      }
    }
  }, [getInvitationDetailReducer]);
  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => index + 1
    },
    { title: "Section", dataIndex: "test_title", key: "test_title" },
    {
      title: "Total Questions",
      dataIndex: "total_questions",
      key: "total_questions"
    },
    {
      title: "Duration(mins)",
      dataIndex: "total_duration",
      key: "total_duration"
    }
  ];

  const handleLogin = () => {
    form
      .validateFields()
      .then((values) => {
        const trimmedValues = {
          email: values?.email.trim(),
          firstName: values?.firstName.trim(),
          lastName: values?.lastName.trim(),
          terms_condition: {
            terms: values.terms,
            shareProfile: values.shareProfile,
            declaration: values.shareProfile
          }
        };

        dispatch(
          assessmentLoginAction({
            email: trimmedValues.email,
            firstName: trimmedValues.firstName,
            lastName: trimmedValues.lastName,
            terms_condition: trimmedValues.terms_condition,
            token: `Bearer ${token}`
          })
        );
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    const { loading, data, error } = assessmentLoginReducer;
    if (!loading) {
      if (data && !error) {
        message.success("Login success!");
      } else if (error) {
        console.warn("Error:", error);
        message.error(error);
      }
    }
  }, [assessmentLoginReducer]);
  return (
    <>
      {loading ? (
        <div className="loading-spinner">
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : inviteSummary?.status === "invited" ||
        inviteSummary?.status === "invitation_delivered" ||
        inviteSummary?.status === "invitation_clicked" ||
        inviteSummary?.status === "invitation_opened" ||
        inviteSummary?.status === "assessment_started" ||
        inviteSummary?.status === "assessment_progress" ? (
        <>
          <div className="page-container">
            <Row className="full-height">
              <Col
                xs={24}
                lg={12}
                className="grey-background full-height-container"
              >
                {" "}
                <div className="instructions-padding">
                  <Title level={3} className="title-padding">
                    <Avatar
                      icon={<UserOutlined />}
                      src={`${process.env.REACT_APP_IMAGES_BASE_URL}${client?.company_photo}`}
                      style={{
                        width: 50,
                        height: 50,
                        objectFit: "contain"
                      }}
                    />
                  </Title>
                  <div className="title-padding">
                    <Title level={1}>Welcome to {data?.title}</Title>
                  </div>
                  <div className="title-padding info-section">
                    <div>
                      <Text>Assessment Duration</Text>
                      <Title level={5}>
                        {convertMinutesToDuration(data?.assessment_duration)}
                      </Title>
                    </div>
                    <div className="info-item">
                      <Text>No. of Questions</Text>
                      <Title level={5}>{data?.total_questions}</Title>
                    </div>
                    <div className="info-item">
                      <Text>Job Role</Text>
                      <Title level={5}>{data?.assessment_role}</Title>
                    </div>
                  </div>
                  <div style={{ marginTop: "10%" }}>
                    {" "}
                    <Title level={1}>Instructions</Title>
                    <ol className="instructions-list">
                      {[
                        "This is a timed assessment. Please make sure you are not interrupted during the assessment, as the timer cannot be paused once started.",
                        "Please ensure you have a stable internet connection.",
                        "We recommend you try the sample assessment for a couple of minutes, before taking the main assessment.",
                        "Before taking the assessment, please go through the FAQs to resolve your queries related to the assessment or the Mentorpal.ai platform."
                      ].map((instruction, index) => (
                        <li key={index}>
                          {index + 1}. {instruction}
                        </li>
                      ))}
                    </ol>
                  </div>

                  <div
                    id="assessment-details"
                    style={{ marginTop: "10%" }}
                    className="pb-20"
                  >
                    <Title level={1}>Sections</Title>
                    <Text>
                      There are {data?.tests?.length} sections that are part of
                      this assessment.
                    </Text>
                    <Table
                    bordered
                      dataSource={data?.tests}
                      columns={columns}
                      className="table-padding"
                      pagination={false}
                    />
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                lg={12}
                className="white-background full-height-container "
              >
                <>
                  {loading ? (
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 9999
                      }}
                    >
                      <TailSpin ariaLabel="Loading..." color="#005c53" />
                    </div>
                  ) : (
                    <div className="instructions-padding">
                      <div className="login-padding">
                        <Title level={1}>Login to Assessment</Title>
                        <Form
                          form={form}
                          layout="vertical"
                          className="padding-bottom-20"
                          initialValues={{
                            email: email_params || "",
                            firstName: firstName || "",
                            lastName: lastName || ""
                          }}
                        >
                          <Form.Item
                            name="firstName"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your first name!"
                              }
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter your first name"
                            />
                          </Form.Item>
                          <Form.Item
                            name="lastName"
                            label="Last Name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your last name!"
                              }
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter your last name"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                              {
                                required: true,
                                type: "email",
                                message: "Please enter a valid email address!"
                              }
                            ]}
                          >
                            <Input
                              prefix={<UserOutlined />}
                              placeholder="Enter your email address"
                              size="large"
                              style={{ borderRadius: "15px" }}
                            />
                          </Form.Item>
                          <Form.Item
                            name="shareProfile"
                            valuePropName="checked"
                          >
                            <Checkbox size="large">
                              Share my profile with Mentorpal.ai
                            </Checkbox>
                          </Form.Item>
                          <Form.Item
                            name="declaration"
                            valuePropName="checked"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please agree to the declaration statement!"
                              }
                            ]}
                          >
                            <Checkbox size="large">
                              I agree not to copy code from any source,
                              including websites, books, or colleagues. I may
                              refer to language documentation or an IDE of my
                              choice. I agree not to copy or share Mentorpal’s
                              copyrighted assessment content or questions on any
                              website or forum.
                            </Checkbox>
                          </Form.Item>
                          <Form.Item
                            name="terms"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        "You must agree to the terms and conditions!"
                                      )
                              }
                            ]}
                          >
                            <Checkbox size="large">
                              I agree to Mentorpal’s{" "}
                              <a href="/terms" style={{ color: "#00756a" }} target="_blank" >
                                Terms of Service
                              </a>{" "}
                              and{" "}
                              <a href="/privacy-policy" style={{ color: "#00756a" }} target="_blank">
                                Privacy Policy
                              </a>
                              .
                            </Checkbox>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              onClick={handleLogin}
                              loading={assessmentLoginReducer?.loading}
                              className="custom-button"
                            >
                              Login
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  )}
                </>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <div style={{ padding: 40 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh"
            }}
          >
            <Card
              style={{
                width: 500,
                padding: 30,
                textAlign: "center",
                borderRadius: "15px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
              }}
            >
              <img
                src="https://static.mentorpal.ai/prod/static_assests/email_assets/logo-long.png"
                alt="Company Logo"
                style={{ width: 200, marginBottom: 30 }}
              />

              <Text strong style={{ fontSize: 20 }}>
                Welcome back!
              </Text>
              <Text style={{ fontSize: 16, display: "block", marginTop: 15 }}>
                It seems like you've already finished your assessment. The
                results are available to{" "}
                <strong>Mpal Solution Private Limited</strong>.
              </Text>

              {!inviteSummary?.feedback_submit ? null : (
                <>
                  <Text
                    className="pt-10"
                    style={{ fontSize: 16, display: "block", marginBottom: 20 }}
                  >
                    We'd love to hear your thoughts on your experience. Please
                    take a moment to provide your feedback.
                  </Text>
                  <Button
                    onClick={() =>
                      navigate(
                        `/finalEvaluation?assessment_invite_id=${assessmentInviteIdParams}`
                      )
                    }
                    className="custom-button"
                  >
                    Give a Feedback
                  </Button>
                  <Text
                    style={{
                      display: "block",
                      marginTop: 20,
                      fontSize: 14,
                      color: "#8c8c8c"
                    }}
                  >
                    Your feedback helps us improve and ensure the best
                    experience for all our users.
                  </Text>
                </>
              )}
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default AssessmentLogin;
