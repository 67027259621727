import { call, delay, put } from "redux-saga/effects";
import {
  createClientUserConstants,
  createUserConstants,
  getAllClientUserConstants,
  getSubUserDetailsConstants,
  getUserConstants
} from "../../constants";
import {
  createClientRoleUserCall,
  createUserCall,
  getAllClientRoleUserCall,
  getSubUserDetailsCall,
  getUserCall
} from "../../network";

export function* createRoleUserSaga(action) {
  try {
    const response = yield call(createUserCall, action.data);
    yield put({
      type: createUserConstants.ADD_USER_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createUserConstants.ADD_USER_RESET });
  } catch (e) {
    yield put({
      type: createUserConstants.ADD_USER_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createUserConstants.ADD_USER_RESET });
  }
}

export function* getAllRoleUserSaga(action) {
  try {
    const response = yield call(getUserCall, action.data);
    yield put({
      type: getUserConstants.GET_USER_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getUserConstants.GET_USER_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getUserConstants.GET_USER_RESET
    });
  }
}
export function* getRoleUSerDetailsSaga(action) {
  try {
    const response = yield call(getSubUserDetailsCall, action.data);
    yield put({
      type: getSubUserDetailsConstants.USER_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getSubUserDetailsConstants.USER_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getSubUserDetailsConstants.USER_DETAILS_RESET
    });
  }
}
export function* createClientRoleUserSaga(action) {
  try {
    const response = yield call(createClientRoleUserCall, action.data);
    yield put({
      type: createClientUserConstants.ADD_CLIENT_USER_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createClientUserConstants.ADD_CLIENT_USER_RESET });
  } catch (e) {
    yield put({
      type: createClientUserConstants.ADD_CLIENT_USER_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: createClientUserConstants.ADD_CLIENT_USER_RESET });
  }
}
export function* getAllClientRoleUserSaga(action) {
  try {
    const response = yield call(getAllClientRoleUserCall, action.data);
    yield put({
      type: getAllClientUserConstants.GET_ALL_CLIENT_USER_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getAllClientUserConstants.GET_ALL_CLIENT_USER_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getAllClientUserConstants.GET_ALL_CLIENT_USER_RESET
    });
  }
}
