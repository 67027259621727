import { call, delay, put } from "redux-saga/effects";
import {
  getAllScheduleInterviewListConstants,
  getInterviewCalendarConstants,
  getInterviewDetailsConstants,
  getInterviewerListConstants,
  getInterviewerReportConstants,
  interviewFeedbackConstants,
  scheduleInterviewConstants,
  updateInterviewDetailsConstants
} from "../../constants";
import {
  getAllScheduleInterviewListCall,
  getInterviewCalendarCall,
  getInterviewDetailsCall,
  getInterviewerListCall,
  getInterviewerReportCall,
  interviewFeedbackCall,
  scheduleInterviewCall,
  updateInterviewDetailsCall
} from "../../network";

export function* getInterviewerListSaga(action) {
  try {
    const response = yield call(getInterviewerListCall, action.data);
    yield put({
      type: getInterviewerListConstants.GET_INTERVIEWER_LIST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getInterviewerListConstants.GET_INTERVIEWER_LIST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getInterviewerListConstants.GET_INTERVIEWER_LIST_RESET
    });
  }
}
export function* getInterviewDetailsSaga(action) {
  try {
    const response = yield call(getInterviewDetailsCall, action.data);
    yield put({
      type: getInterviewDetailsConstants.GET_INTERVIEW_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getInterviewDetailsConstants.GET_INTERVIEW_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getInterviewDetailsConstants.GET_INTERVIEW_DETAILS_RESET
    });
  }
}
export function* getAllScheduleInterviewListSaga(action) {
  try {
    const response = yield call(getAllScheduleInterviewListCall, action.data);
    yield put({
      type: getAllScheduleInterviewListConstants.GET_SCHEDULE_INTERVIEW_LIST_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getAllScheduleInterviewListConstants.GET_SCHEDULE_INTERVIEW_LIST_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getAllScheduleInterviewListConstants.GET_SCHEDULE_INTERVIEW_LIST_RESET
    });
  }
}
export function* scheduleInterviewSaga(action) {
  try {
    const response = yield call(scheduleInterviewCall, action.data);
    yield put({
      type: scheduleInterviewConstants.SCHEDULE_INTERVIEW_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: scheduleInterviewConstants.SCHEDULE_INTERVIEW_RESET });
  } catch (e) {
    yield put({
      type: scheduleInterviewConstants.SCHEDULE_INTERVIEW_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: scheduleInterviewConstants.SCHEDULE_INTERVIEW_RESET });
  }
}
export function* interviewFeedbackSaga(action) {
  try {
    const response = yield call(interviewFeedbackCall, action.data);
    yield put({
      type: interviewFeedbackConstants.INTERVIEW_FEEDBACK_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: interviewFeedbackConstants.INTERVIEW_FEEDBACK_RESET });
  } catch (e) {
    yield put({
      type: interviewFeedbackConstants.INTERVIEW_FEEDBACK_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({ type: interviewFeedbackConstants.INTERVIEW_FEEDBACK_RESET });
  }
}
export function* getInterviewerReportSaga(action) {
  try {
    const response = yield call(getInterviewerReportCall, action.data);
    yield put({
      type: getInterviewerReportConstants.GET_INTERVIEWER_REPORT_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getInterviewerReportConstants.GET_INTERVIEWER_REPORT_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getInterviewerReportConstants.GET_INTERVIEWER_REPORT_RESET
    });
  }
}
export function* getInterviewCalendarSaga(action) {
  try {
    const response = yield call(getInterviewCalendarCall, action.data);
    yield put({
      type: getInterviewCalendarConstants.GET_INTERVIEW_CALENDAR_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
  } catch (e) {
    yield put({
      type: getInterviewCalendarConstants.GET_INTERVIEW_CALENDAR_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: getInterviewCalendarConstants.GET_INTERVIEW_CALENDAR_RESET
    });
  }
}
export function* updateInterviewDetailsSaga(action) {
  try {
    const response = yield call(updateInterviewDetailsCall, action.data);
    yield put({
      type: updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_SUCCESS,
      data: response.data,
      status: response.status,
      error: response.error
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_RESET
    });
  } catch (e) {
    yield put({
      type: updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_FAILURE,
      error: e?.response?.data?.error,
      status: e?.response?.data?.status,
      headers: e?.response?.data
    });
    yield delay(process.env.REACT_APP_REDUCER_RESET_TIME);
    yield put({
      type: updateInterviewDetailsConstants.UPDATE_INTERVIEW_DETAILS_RESET
    });
  }
}
