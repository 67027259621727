import React, { useEffect, useState } from "react";
import { Button, Form, Steps, message } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import ChooseTestStep from "./Steps/ChooseTestStep";
import RoleDetailsStep from "./Steps/RoleDetailsStep";
import TitleUpdateModal from "./Steps/TitleUpdateModal";
import FinalizeStep from "./Steps/FinalizeStep";
import "./create.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createAssessmentAction,
  getAssessmentDetailaction,
  updateAssessmentAction
} from "../../../../redux/actions/assessment";
import { useLocation, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import PreviewModal from "./Steps/PreviewModal";
import moment from "moment";

const { Step } = Steps;

const CreateAssessmentModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const assessment_id = queryParams.get("assessment_id");

  const [previewModal, setPreviewModal] = useState(false);
  const [selectedTestForPreview, setSelectedTestForPreview] = useState(null);

  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [titleEditModal, setTitleEditModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState({
    next: false,
    prev: false,
    done: false,
    exit: false,
    getData: false
  });

  const setButtonLoading = (button, state) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [button]: state
    }));
  };

  useEffect(() => {
    if (assessment_id) {
      dispatch(getAssessmentDetailaction({ assessment_id }));
    } else {
      form.setFieldsValue({
        assessment_content: {
          allowed_languages: [],
          cutoff_score: 50
        },
        assessment_administration: {
          disclaimer: false,
          instruction: "",
          allowed_attempts: 1,
          anti_cheating: true
        },
        deadlinesAndExpiration: {
          candidateDeadline: {
            candidateDeadline_enabled: true,
            deadlineDays: 7,
            sendRemindersBeforeDeadline: true
          },
          assessmentExpiration: {
            assessmentExpiration_enabled: false,
            expirationDate: null,
            expirationTime: null,
            timezone: "",
            sendRemindersBeforeExpiration: true
          }
        }
      });
    }

    return () => {
      dispatch({ type: "GET_ASSESSMENT_RESET" });
    };
  }, [assessment_id, dispatch]);

  const getAssessmentDetailsReducer = useSelector(
    (state) => state.getAssessmentDetailsReducer
  );

  useEffect(() => {
    const { loading, data, error } = getAssessmentDetailsReducer;
    setButtonLoading("getData", loading);
    if (!loading && data && !error && assessment_id) {
      setFormData(data.data);
      form.setFieldsValue({
        ...data.data,
        labels: data.data.labels || [],
        assessment_content: {
          allowed_languages:
            data.data.assessment_content?.allowed_languages || [],
          cutoff_score: data.data.assessment_content?.cutoff_score || 50
        },
        assessment_administration: {
          disclaimer: data.data.assessment_administration?.disclaimer || false,
          instruction: data.data.assessment_administration?.instruction || "",
          allowed_attempts:
            data.data.assessment_administration?.allowed_attempts || 1,
          anti_cheating:
            data.data.assessment_administration?.anti_cheating || true
        },
        deadlinesAndExpiration: {
          candidateDeadline: {
            candidateDeadline_enabled:
              data.data.deadlinesAndExpiration?.candidateDeadline
                ?.candidateDeadline_enabled,
            deadlineDays: data.data.deadlinesAndExpiration?.candidateDeadline
              ?.deadlineDays
              ? data.data.deadlinesAndExpiration?.candidateDeadline
                  ?.deadlineDays
              : 7,
            sendRemindersBeforeDeadline:
              data.data.deadlinesAndExpiration?.candidateDeadline
                ?.sendRemindersBeforeDeadline || true
          },
          assessmentExpiration: {
            assessmentExpiration_enabled:
              data.data.deadlinesAndExpiration?.assessmentExpiration
                ?.assessmentExpiration_enabled,
            expirationDate: data.data.deadlinesAndExpiration
              ?.assessmentExpiration?.expirationDate
              ? moment(
                  data.data.deadlinesAndExpiration.assessmentExpiration
                    .expirationDate
                )
              : null,
            expirationTime: data.data.deadlinesAndExpiration
              ?.assessmentExpiration?.expirationTime
              ? moment(
                  data.data.deadlinesAndExpiration.assessmentExpiration
                    .expirationTime,
                  "HH:mm"
                )
              : null,
            timezone:
              data.data.deadlinesAndExpiration?.assessmentExpiration
                ?.timezone || "",
            sendRemindersBeforeExpiration:
              data.data.deadlinesAndExpiration?.assessmentExpiration
                ?.sendRemindersBeforeExpiration || true
          }
        }
      });
    } else if (!loading && error && assessment_id) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [getAssessmentDetailsReducer, form]);

  const steps = [
    {
      title: "Role Details",
      content: <RoleDetailsStep form={form} initialData={formData} />
    },
    {
      title: "Choose Test",
      content: <ChooseTestStep form={form} initialData={formData} />
    },
    {
      title: "Finalize",
      content: <FinalizeStep form={form} initialData={formData} />
    }
  ];
  const next = () => {
    setButtonLoading("next", true);
    form
      .validateFields()
      .then((values) => {
        setFormData({ ...formData, ...values });
        setCurrent(current + 1);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      })
      .finally(() => {
        setButtonLoading("next", false);
      });
  };

  const prev = () => {
    setButtonLoading("prev", true);
    setCurrent(current - 1);
    setButtonLoading("prev", false);
  };

  const handleDone = () => {
    setButtonLoading("done", true);
    form
      .validateFields()
      .then((values) => {
        const completeData = { ...formData, ...values };
        if (assessment_id) {
          dispatch(updateAssessmentAction({ assessment_id, ...completeData }));
        } else {
          dispatch(createAssessmentAction(completeData));
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setButtonLoading("done", false);
      });
  };

  const handleExit = () => {
    setButtonLoading("exit", true);
    navigate("/dashboard/assessment");
  };

  const handlePreview = () => {
    const testIds = form.getFieldValue("testIds");
    if (testIds?.length > 0 && testIds.length <= 5) {
      setSelectedTestForPreview(testIds);
      setPreviewModal(true);
    } else if (!testIds || testIds.length === 0) {
      message.error("Please select at least one test to preview!");
    } else if (testIds.length > 5) {
      message.error("You can select up to 5 tests to preview!");
    }
  };

  const createAssessmentReducer = useSelector(
    (state) => state.createAssessmentReducer
  );
  const updateAssessmentReducer = useSelector(
    (state) => state.updateAssessmentReducer
  );
  useEffect(() => {
    const { loading, data, error } = updateAssessmentReducer;
    if (!loading) {
      setButtonLoading("done", false);
    }
    if (data) {
      message.success("Assessment details updated!");
      navigate("/dashboard/assessment");
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [updateAssessmentReducer]);

  useEffect(() => {
    const { loading, data, error } = createAssessmentReducer;
    if (!loading) {
      setButtonLoading("done", false);
    }
    if (data) {
      message.success("Assessment created!");
      navigate("/dashboard/assessment");
    } else if (error) {
      console.warn("Error:", error);
      message.error(error);
    }
  }, [createAssessmentReducer]);
  useEffect(() => {
    return () => {
      form.resetFields();
      setFormData({});
    };
  }, [form]);

  return (
    <>
      {loading.getData ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999
          }}
        >
          <TailSpin ariaLabel="Loading..." color="#005c53" />
        </div>
      ) : (
        <>
          <div className="main-header bg-white" data-ui="header">
            <div className="px-4">
              <div className="flag">
                <div className="flag__section">
                  <a className="external incognito-link inline-block mb-m mt-m">
                    <h1 className="js-main-header-title main-header__title main-header__title--dashboard">
                      {form.getFieldValue("title") ? (
                        <>
                          {form.getFieldValue("title")}
                          <Button
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => setTitleEditModal(true)}
                          />
                        </>
                      ) : (
                        "Assessment"
                      )}
                    </h1>
                  </a>
                </div>
                {current > 0 && current <= 2 && (
                  <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
                    <Button
                      type="button"
                      className="btn btn--primary mt-m text-white pull-right main_border_radius me-2"
                      onClick={handlePreview}
                      icon={<EyeOutlined />}
                    >
                      Preview
                    </Button>
                  </div>
                )}
                <div className="flag__section flag__section--tight flag__section--top js-main-header-actions">
                  <Button
                    type="button"
                    className="btn btn--primary mt-m text-white pull-right main_border_radius"
                    onClick={handleExit}
                    loading={loading.exit}
                  >
                    Exit
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="test-root-container d-block"
            style={{ backgroundColor: "#f5f5f5" }}
          >
            <div className="container py-4">
              <Steps
                current={current}
                className="custom-stepper"
                style={{ marginBottom: 20 }}
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </div>
            <div className="steps-content  w-100">
              <Form form={form} initialValues={formData}>
                {steps[current].content}
              </Form>
            </div>
            <div className="test-footer">
              {current > 0 && (
                <Button
                  className="custom-button"
                  style={{ margin: "0 8px" }}
                  onClick={prev}
                  loading={loading.prev}
                >
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  className="custom-button"
                  onClick={next}
                  loading={loading.next}
                  style={{ marginLeft: 8 }}
                >
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  loading={loading.done}
                  className="custom-button"
                  onClick={handleDone}
                >
                  {assessment_id ? "Update" : "Submit"}
                </Button>
              )}
            </div>
          </div>
          {titleEditModal && (
            <TitleUpdateModal
              titleEditModal={titleEditModal}
              setTitleEditModal={setTitleEditModal}
              form={form}
            />
          )}
          {previewModal && selectedTestForPreview && (
            <PreviewModal
              previewModal={previewModal}
              setPreviewModal={setPreviewModal}
              initialData={selectedTestForPreview}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreateAssessmentModule;
