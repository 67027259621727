import { Tooltip, Progress } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const TestProgress = ({
  currentQuestionIndex,
  currentTest,
  questionProgress,
}) => {
  const totalQuestions = currentTest?.questionData?.length || 0;
  const currentQuestionNumber = currentQuestionIndex + 1;
  const remainingQuestions = totalQuestions - currentQuestionNumber;

  const tooltipContent = `Current Question: ${currentQuestionNumber}, Remaining Questions: ${remainingQuestions}, Total Questions: ${totalQuestions}`;

  return (
      <Tooltip title={tooltipContent}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CheckCircleOutlined
            style={{ fontSize: "1rem" }}
          />
          <span className="text-black">
            {currentQuestionNumber} / {totalQuestions}
          </span>
          <Progress
            strokeColor="#28a745"
            percent={questionProgress}
            size={[450, 17]}
            showInfo={false}
            style={{ width: "100%", maxWidth: "450px", marginLeft: 8 }}
          />
        </div>
      </Tooltip>
  );
};

export default TestProgress;
