import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Space, message, Input } from "antd";
import {
  DownloadOutlined,
  PlusOutlined,
  MinusOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "./pdfview.css";
import useWindowSize from "../../Utils/CustomHooks/windowSize";
import { Row, Col } from 'antd';
import { Layout } from 'antd';

const { Content } = Layout;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ resumeSrc }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [zoomPercentage, setZoomPercentage] = useState("100");

  const windowSize = useWindowSize();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const goToPreviousPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  const handleZoomIn = () => {
    if (zoom < 3) {
      const newZoom = zoom + 0.3;
      setZoom(newZoom);
      setZoomPercentage((newZoom * 100).toFixed(0)); 
    }
  };

  const handleZoomOut = () => {
    if (zoom > 0.5) {
      const newZoom = zoom - 0.3;
      setZoom(newZoom);
      setZoomPercentage((newZoom * 100).toFixed(0));
    }
  };

  const handleZoomChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 50 && value <= 300) {
      const newZoom = parseFloat(value) / 100;
      setZoom(newZoom);
      setZoomPercentage(value);
    }
  };

  const downloadPdf = () => {
    message.info("Downloading...");
    window.open(resumeSrc, "_blank");
  };

  const pdfWidth = Math.min(windowSize.width * 0.9, 600) * zoom; 

  return (
    // <div className="pdf-viewer-container">
    //   <div className="pdf-header">
    //     <div className="pdf-download  p-3">
    //       <Button
    //         className="candidate-db-2de5X  candidate-db-2ySVn   main_border_radius"
    //         type="primary"
    //         shape="circle"
    //         icon={<DownloadOutlined />}
    //         onClick={downloadPdf}
    //       />
    //     </div>
    //     <div className="pdf-controls">
    //       <Space size="small" wrap>
    //         <Button
    //           className="candidate-db-2de5X  candidate-db-2ySVn   main_border_radius"
    //           type="primary"
    //           icon={<LeftOutlined />}
    //           onClick={goToPreviousPage}
    //           disabled={pageNumber <= 1 || !numPages}
    //         />
    //         <span>
    //           Page {pageNumber} / {numPages}
    //         </span>
    //         <Button
    //           className="candidate-db-2de5X  candidate-db-2ySVn   main_border_radius"
    //           type="primary"
    //           icon={<RightOutlined />}
    //           onClick={goToNextPage}
    //           disabled={pageNumber >= numPages || !numPages}
    //         />
    //         <Button
    //           className="candidate-db-2de5X  candidate-db-2ySVn   main_border_radius"
    //           type="primary"
    //           icon={<PlusOutlined />}
    //           onClick={handleZoomIn}
    //           disabled={zoom >= 3}
    //         />
    //         <Button
    //           className="candidate-db-2de5X  candidate-db-2ySVn   main_border_radius"
    //           type="primary"
    //           icon={<MinusOutlined />}
    //           onClick={handleZoomOut}
    //           disabled={zoom <= 0.5}
    //         />
    //         <Input
    //           className="zoom-input"
    //           value={zoomPercentage}
    //           onChange={handleZoomChange}
    //           addonAfter="%"
    //           maxLength={3}
    //         />
    //       </Space>
    //     </div>
    //   </div>
    //   <div className="pdf-content">
    //     <Document file={resumeSrc} onLoadSuccess={onDocumentLoadSuccess}>
    //       <Page
    //         pageNumber={pageNumber}
    //         width={pdfWidth} // Use dynamic width based on screen size
    //         renderTextLayer={false}
    //         renderAnnotationLayer={false}
    //       />
    //     </Document>
    //   </div>
    // </div>
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ padding: '20px', height: '100%' }}>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col
            xs={24} sm={24} md={24} lg={24} xl={24}
            style={{ padding: '0', height: 'calc(100vh - 40px)' }}
          >
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              {/* Embedded PDF only for desktop view */}
              <embed
                src={resumeSrc}
                width="100%"
                height="100%"
                type="application/pdf"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  border: 'none',
                  display: window.innerWidth >= 768 ? 'block' : 'none'  // Show only on md and larger screens
                }}
              />

              {window.innerWidth < 768 && (
                <a
                  href={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(resumeSrc)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'block',
                    textAlign: 'center',
                    marginTop: '20px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#007bff',
                    textDecoration: 'underline'
                  }}
                >
                  
                  View Resume
                </a>
              )}
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default PdfViewer;
