import React, { useCallback, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  DatePicker,
  TimePicker,
  Input,
  Avatar
} from "antd";
import moment from "moment";
import { UserOutlined, CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { updateJobApplicationAction } from "../../../redux/actions/common";

const ReminderModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onClose = useCallback(() => {
    form.resetFields();
    setVisible((prev) => ({ ...prev, show: false, data: {} }));
  }, [form, setVisible]);

  const handleSubmit = (values) => {
    const data = {
      apply_id: visible?.data.apply_id,
      reminder: {
        enabled: true,
        status: "pending",
        ...values
      }
    };
    dispatch(updateJobApplicationAction(data));
    onClose();
  };

  useEffect(() => {
    if (visible?.show) {
      const initialDate = moment();
      const initialTime = moment().add(2, "hours").minute(Math.ceil(moment().minute() / 5) * 5);
      form.setFieldsValue({
        date: initialDate,
        time: initialTime,
        reminder_text: "Follow up"
      });
    }
  }, [visible, form]);

  return (
    <Modal
      visible={visible?.show}
      onCancel={onClose}
      closable={false}
      footer={null}
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>
            Schedule Follow-Up
            <Avatar
              src={
                visible?.data?.photo
                  ? `${process.env.REACT_APP_IMAGES_BASE_URL}${visible?.data?.photo}`
                  : undefined
              }
              icon={!visible?.data?.photo ? <UserOutlined /> : null}
              alt={visible?.data?.name || "User"}
              style={{ marginRight: 8 }}
            />
            <a
              className="text-decoration-underline cursor-pointer"
              style={{ cursor: "pointer", color: "#005c53" }}
            >
              {visible?.data?.name}
            </a>
          </span>
          <CloseOutlined style={{ fontSize: "20px", cursor: "pointer" }} onClick={onClose} />
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="date"
          label="Select Date"
          rules={[{ required: true, message: "Please select a date!" }]}
        >
          <DatePicker 
            disabledDate={(current) => current && current < moment().startOf('day')}
          />
        </Form.Item>

        <Form.Item
          name="time"
          label="Select Time"
          rules={[{ required: true, message: "Please select a time!" }]}
        >
          <TimePicker 
            format="HH:mm"
            minuteStep={5}
            disabledTime={(current) => ({
              disabledHours: () => [],
              disabledMinutes: () => {
                const minutes = [];
                const hour = current.hour();
                for (let i = 0; i < 60; i += 5) {
                  if (moment().hour() === hour && i < moment().minute()) {
                    minutes.push(i);
                  }
                }
                return minutes;
              },
            })}
          />
        </Form.Item>

        <Form.Item
          name="reminder_text"
          label="Reminder"
          rules={[{ required: true, message: "Please enter a reminder!" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <div style={{ textAlign: "right" }}>
          <Button onClick={onClose} style={{ marginRight: "8px" }}>
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: "#00756a" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ReminderModal;
